import {
  Box,
  Button,
  createMuiTheme,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { blue, blueGrey } from "@material-ui/core/colors";
import { BookSharp } from "@material-ui/icons";
import { useState } from "react";
import {
  Dispatch,
  useAppState,
} from "../../components/AppProvider/AppProvider";
import { fetchInitialData } from "../../helpers/fetchers";
import { blueTheme } from "../../helpers/themes";

const LoginPage = () => {
  const [ctx, dispatch] = useAppState() ?? [];

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: blueGrey[600] }}
        >
          <Paper elevation={10} style={{ backgroundColor: "white" }}>
            <form
              onSubmit={async (e) => {
                e.preventDefault();

                const allowedAdminUsernames = ["admin", "administrator"];
                if (!allowedAdminUsernames.find((u) => u === username)) {
                  alert("Must be admin to login to user management!");
                  return;
                }

                if (dispatch) {
                  try {
                    const resp = await fetch(`${ctx?.baseUrl}/login`, {
                      method: "post",
                      headers: {
                        "content-type": "application/json",
                      },
                      body: JSON.stringify({
                        username: username,
                        password: password,
                      }),
                    });

                    if (resp.status !== 200) throw await resp.text();

                    const apiKey = await resp.text();

                    localStorage.setItem("apiKey", apiKey);

                    const { departments, roles } = await fetchInitialData(
                      ctx?.baseUrl ?? "",
                      apiKey
                    );

                    dispatch({ type: "apiKey", data: apiKey });

                    dispatch({ type: "loggedIn", data: true });
                    dispatch({ type: "departments", data: departments });
                    dispatch({ type: "roles", data: roles });
                  } catch (e) {
                    console.error(e);
                    alert(e);
                  }
                }
              }}
            >
              <Box
                m={3}
                justifyContent="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h6">
                  IOT User Management Console
                </Typography>

                <Box my={1}>
                  <TextField
                    label="Username..."
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { textAlign: "center" } }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Box>
                <Box my={1}>
                  <TextField
                    type="password"
                    label="Password..."
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { textAlign: "center" } }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
                <Box my={1}>
                  <ThemeProvider theme={blueTheme}>
                    <Button type="submit" variant="contained" color="primary">
                      Login
                    </Button>
                  </ThemeProvider>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default LoginPage;
