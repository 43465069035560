import {
  Box,
  Button,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { fetchApps, fetchRoles } from "../../helpers/fetchers";
import filterNotHiddenAndSort from "../../helpers/filterNotHiddenAndSort";
import filterNotHiddenAndSortDesc from "../../helpers/filterNotHiddenAndSortDesc";
import { initialRole } from "../../helpers/modelinitials";
import { RequestStatus } from "../../helpers/RequestStatus";
import { blueTheme } from "../../helpers/themes";
import { Role } from "../../model";

const Roles = () => {
  const [ctx, dispatch] = useAppState() ?? [];
  const history = useHistory();
  const [roles, setRoles] = useState<Role[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("NotAsked");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setRequestStatus("Loading");

    const [roles] = await Promise.all([
      fetchRoles(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
    ]);

    setRoles(roles);
    setRequestStatus("Success");
  };

  const handleSave = async () => {
    await Promise.all(
      roles.map(async (role) => {
        try {
          await fetch(`${ctx?.baseUrl}/roles`, {
            method: "post",
            headers: {
              authorization: ctx?.apiKey ?? "",
              "content-type": "application/json",
            },
            body: JSON.stringify(role),
          });

          alert("Roles saved successfully.");

          window.location.reload();
        } catch (e) {
          console.error(e);
        }
      })
    );
  };

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box m={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">Roles</Typography>
            <Box mx={2}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
            <Box mx={2}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setRoles([...roles, { ...initialRole }])}
              >
                Insert
              </Button>
            </Box>
          </Box>
          <Box overflow="auto" style={{ height: "75vh" }} my={2} boxShadow={15}>
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {["Name"].map((headName) => (
                      <TableCell
                        align="center"
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                          backgroundColor: blueGrey[600],
                          color: "white",
                        }}
                      >
                        {headName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {filterNotHiddenAndSort(roles).map((role, i) => (
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        inputProps={{ style: { textAlign: "center" } }}
                        value={role.name}
                        onChange={(e) => {
                          const newRoles = [...roles];

                          const foundRole = filterNotHiddenAndSort(
                            newRoles
                          ).find((_, ix) => i === ix);

                          if (foundRole) {
                            foundRole.name = e.target.value;
                            setRoles(newRoles);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Roles;
