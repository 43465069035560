import {
  Box,
  Button,
  CircularProgress,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { fetchApp, fetchApps, fetchUsers } from "../../helpers/fetchers";
import { initialApp } from "../../helpers/modelinitials";
import { RequestStatus } from "../../helpers/RequestStatus";
import { blueTheme } from "../../helpers/themes";
import { App, User } from "../../model";

const AppsDetail = () => {
  const { id } = useParams() as { id: string };
  const history = useHistory();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("NotAsked");
  const [app, setApp] = useState<App>({ ...initialApp });
  const [users, setUsers] = useState<User[]>([]);
  const [ctx, dispatch] = useAppState() ?? [];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRequestStatus("Loading");

      const [appRes, users] = await Promise.all([
        !isNaN(parseInt(id))
          ? fetchApp(parseInt(id), ctx?.baseUrl ?? "", ctx?.apiKey ?? "")
          : null,
        fetchUsers(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
      ]);

      setRequestStatus("Success");
      setApp(appRes ?? app);
      setUsers(users);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${ctx?.baseUrl}/apps`, {
        method: "post",
        headers: {
          authorization: ctx?.apiKey ?? "",
          "content-type": "application/json",
        },
        body: JSON.stringify({ ...app }),
      });

      if (response.status !== 201) throw await response.text();
      history.push("/apps");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box m={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">Apps Detail {id}</Typography>
            <Box mx={1}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Box>

            {requestStatus === "Loading" ? (
              <CircularProgress disableShrink size={25} />
            ) : (
              <></>
            )}
          </Box>

          <Box my={2} display="flex" flexWrap="wrap ">
            <Box flexGrow={1} mx={1}>
              <TextField
                value={app.name}
                onChange={(e) => setApp({ ...app, name: e.target.value })}
                label="Name"
                fullWidth
              />
            </Box>
            <Box flexGrow={1} mx={1}>
              <TextField
                value={app.url}
                onChange={(e) => setApp({ ...app, url: e.target.value })}
                label="URL"
                fullWidth
              />
            </Box>
            <Box display="flex" alignItems="center" flexGrow={1} mx={1}>
              {/* {JSON.stringify(app.author)} */}
              <Autocomplete
                size="small"
                options={users}
                getOptionLabel={(user) => user.name}
                fullWidth
                value={app.author}
                onChange={(e, user) => {
                  console.log(user);
                  setApp({ ...app, author: user });
                }}
                renderInput={(params) => (
                  <TextField
                    // size="small"
                    variant="outlined"
                    label="Author"
                    {...params}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AppsDetail;
