import {
  Box,
  Button,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { parseJsonSourceFileConfigFileContent } from "typescript";
import { useAppState } from "../../components/AppProvider/AppProvider";
import {
  fetchApps,
  fetchDepartments,
  fetchRoles,
} from "../../helpers/fetchers";
import filterNotHiddenAndSort from "../../helpers/filterNotHiddenAndSort";
import filterNotHiddenAndSortDesc from "../../helpers/filterNotHiddenAndSortDesc";
import { initialDepartment, initialRole } from "../../helpers/modelinitials";
import { RequestStatus } from "../../helpers/RequestStatus";
import { blueTheme } from "../../helpers/themes";
import {
  PpicJob_PpicJobDeptCategory,
  TaskListDepartmentCategory,
  taskListDepartmentCategoryFromJSON,
  taskListDepartmentCategoryToJSON,
} from "../../masterbigsystem";
import { Department, Role } from "../../model";

const Departments = () => {
  const [ctx, dispatch] = useAppState() ?? [];
  const history = useHistory();
  const [departments, setDepartments] = useState<Department[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("NotAsked");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setRequestStatus("Loading");

    const [departments] = await Promise.all([
      fetchDepartments(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
    ]);

    setDepartments(departments);
    setRequestStatus("Success");
  };

  const handleSave = async () => {
    try {
      await fetch(`${ctx?.baseUrl}/departments-bulk`, {
        method: "post",
        headers: {
          authorization: ctx?.apiKey ?? "",
          "content-type": "application/json",
        },
        body: JSON.stringify(departments),
      });

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
    alert("Departments saved successfully.");
  };

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box m={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">Roles</Typography>
            <Box mx={2}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
            <Box mx={2}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() =>
                  setDepartments([...departments, { ...initialDepartment }])
                }
              >
                Insert
              </Button>
            </Box>
          </Box>
          <Box overflow="auto" style={{ height: "75vh" }} my={2} boxShadow={15}>
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {[
                      "Name",
                      "Category",
                      "2 Digit Code",
                      "Document Deletion Ability",
                    ].map((headName) => (
                      <TableCell
                        align="center"
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                          backgroundColor: blueGrey[600],
                          color: "white",
                        }}
                      >
                        {headName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {filterNotHiddenAndSort(departments).map((department, i) => (
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        inputProps={{ style: { textAlign: "center" } }}
                        value={department.name}
                        onChange={(e) => {
                          const newDepartments = [...departments];

                          const foundDepartment = filterNotHiddenAndSort(
                            newDepartments
                          ).find((_, ix) => i === ix);

                          if (foundDepartment) {
                            foundDepartment.name = e.target.value;
                            setDepartments(newDepartments);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        onChange={(v) => {
                          const val = (
                            v as {
                              value: TaskListDepartmentCategory | null;
                              label: string;
                            }
                          ).value;

                          setDepartments(
                            filterNotHiddenAndSort(departments).map((dx, ix) =>
                              ix === i && dx
                                ? {
                                    ...dx,
                                    deptCategory: val
                                      ? (taskListDepartmentCategoryToJSON(
                                          val as any
                                        ) as any)
                                      : undefined,
                                  }
                                : dx
                            )
                          );
                        }}
                        options={[
                          {
                            value: null,
                            label: "None",
                          },
                          {
                            value: TaskListDepartmentCategory.SALES,
                            label: taskListDepartmentCategoryToJSON(
                              TaskListDepartmentCategory.SALES
                            ),
                          },
                          {
                            value: TaskListDepartmentCategory.PPC,
                            label: taskListDepartmentCategoryToJSON(
                              TaskListDepartmentCategory.PPC
                            ),
                          },
                          {
                            value: TaskListDepartmentCategory.PROJECT,
                            label: taskListDepartmentCategoryToJSON(
                              TaskListDepartmentCategory.PROJECT
                            ),
                          },
                        ]}
                      />
                      {department?.deptCategory
                        ? JSON.stringify(department?.deptCategory)
                        : ""}
                      {/* {department?.name} */}
                    </TableCell>
                    <TableCell>
                      <input
                        // style={{ width: 50 }}
                        className="form-control form-control-sm"
                        placeholder="Code.."
                        key={`code-${i}`}
                        defaultValue={department.code ?? ""}
                        onBlur={(e) => {
                          setDepartments(
                            filterNotHiddenAndSort(departments).map((dx, ix) =>
                              ix === i ? { ...dx, code: e.target.value } : dx
                            )
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        checked={department.ableToDeleteDoc ? true : false}
                        key={`doc-${i}`}
                        onClick={(e) => {
                          setDepartments(
                            filterNotHiddenAndSort(departments).map((dx, ix) =>
                              ix === i
                                ? {
                                    ...dx,
                                    ableToDeleteDoc: !dx.ableToDeleteDoc,
                                  }
                                : dx
                            )
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Departments;
