import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add,
  ArrowDropUp,
  ArrowRight,
  ArrowUpward,
  ChevronRight,
  Delete,
  ExpandLess,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { StateChangeTypes } from "downshift";
import { useEffect, useState } from "react";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { fetchAllUserApps, fetchUsers } from "../../helpers/fetchers";
import {
  initialDepartmentHierarchy,
  initialDepartmentHierarchyMember,
  initialDepartmentHierarchyMemberView,
  initialDepartmentHierarchyView,
  initialDirectorat,
  initialDirectoratMember,
  initialDirectoratMemberView,
  initialDirectoratView,
  initialDivision,
  initialDivisionMember,
  initialDivisionMemberView,
  initialDivisionView,
  initialSection,
  initialSectionMember,
  initialSectionMemberView,
  initialSectionView,
} from "../../helpers/modelinitials";
import { RequestStatus } from "../../helpers/RequestStatus";
import { DirectoratView, HierarchyPostBody, User, UserApp } from "../../model";

const HierarchyV2Page = () => {
  const [ctx, dispatch] = useAppState() ?? [];
  const [hierarchyView, setHierarchyView] = useState<DirectoratView[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("NotAsked");
  const [directorateExpands, setdirectorateExpands] = useState<number[]>([]);
  const [divisionExpands, setDivisionExpands] = useState<number[][]>([]);
  const [departmentExpands, setDepartmentExpands] = useState<number[][]>([]);
  const [sectionExpands, setSectionExpands] = useState<number[][]>([]);

  const [users, setUsers] = useState<User[]>([]);
  const [userApps, setUserApps] = useState<UserApp[]>([]);

  const [directorateDeleteIds, setDirectorateDeleteIds] = useState<number[]>(
    []
  );
  const [directoratMemberDeleteIds, setDirectoratMemberDeleteIds] = useState<
    number[]
  >([]);

  const [divisionDeleteIds, setDivisionDeleteIds] = useState<number[]>([]);
  const [divisionMemberDeleteIds, setDivisionMemberDeleteIds] = useState<
    number[]
  >([]);

  const [departmentHierarchyDeleteIds, setDepartmentHierarchyDeleteIds] =
    useState<number[]>([]);
  const [
    departmentHierarchyMemberDeleteIds,
    setDepartmentHierarchyMemberDeleteIds,
  ] = useState<number[]>([]);

  const [sectionDeleteIds, setSectionDeleteIds] = useState<number[]>([]);
  const [sectionMemberDeleteIds, setSectionMemberDeleteIds] = useState<
    number[]
  >([]);

  useEffect(() => {
    fetchInitialHierarchy();
  }, []);

  const fetchInitialHierarchy = async () => {
    try {
      setRequestStatus("Loading");

      const [users, userApps, resp] = await Promise.all([
        fetchUsers(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
        fetchAllUserApps(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
        fetch(`${ctx?.baseUrl}/hierarchy-view`, {
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
        }),
      ]);

      if (resp.status !== 200) throw await resp.text();

      const directorats = (await resp.json()) as DirectoratView[];

      setHierarchyView(directorats);
      setdirectorateExpands(directorats.map((_, i) => i));
      setDivisionExpands(
        directorats
          .map(
            (directorate, i) =>
              directorate.divisions?.map((_, j) => [i ?? 0, j ?? 0]) ?? []
          )
          .flat()
      );

      setDepartmentExpands(
        directorats
          .map((directorate, i) =>
            (
              directorate.divisions?.map(
                (division, j) =>
                  division.departmentHierarchies?.map((_, k) => [
                    i ?? 0,
                    j ?? 0,
                    k ?? 0,
                  ]) ?? []
              ) ?? []
            ).flat()
          )
          .flat() ?? []
      );

      setSectionExpands(
        directorats
          .map((directorate, i) =>
            (
              directorate.divisions?.map((division, j) =>
                (
                  division.departmentHierarchies?.map(
                    (department, k) =>
                      department.sections?.map((_, l) => [
                        i ?? 0,
                        j ?? 0,
                        k ?? 0,
                        l ?? 0,
                      ]) ?? []
                  ) ?? []
                ).flat()
              ) ?? []
            ).flat()
          )
          .flat()
      );

      // Users
      setUsers(users);
      setUserApps(userApps);

      setRequestStatus("Success");
    } catch (e) {
      console.log("[hierarchy-view] error", e);
      setRequestStatus("Error");
    }
  };

  const handleSave = async () => {
    try {
      setRequestStatus("Loading");

      const resp = await fetch(`${ctx?.baseUrl}/hierarchy-save`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: ctx?.apiKey ?? "",
        },
        body: JSON.stringify({
          directorats: hierarchyView,
          directoratDeleteIds: directorateDeleteIds,
          directoratMemberDeleteIds: directoratMemberDeleteIds,
          divisionDeleteIds: divisionDeleteIds,
          divisionMemberDeleteIds: divisionMemberDeleteIds,
          departmentHierarchyDeleteIds: departmentHierarchyDeleteIds,
          departmentHierarchyMemberDeleteIds:
            departmentHierarchyMemberDeleteIds,
          sectionDeleteIds: sectionDeleteIds,
          sectionMemberDeleteIds: sectionMemberDeleteIds,
        } as HierarchyPostBody),
      });

      if (resp.status !== 201) throw await resp.text();

      setRequestStatus("Success");
      window.location.reload();
    } catch (e) {
      console.log("[handleSave] eror", e);
      setRequestStatus("Error");
    }
  };

  return (
    <Box m={3}>
      <Box mt={2}>
        <Typography variant="h5">Hierarchy v2</Typography>

        <Box my={2}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>

        {requestStatus === "Loading" ? (
          <CircularProgress disableShrink />
        ) : (
          <Box mt={3}>
            <Button
              onClick={() => {
                setHierarchyView([
                  ...hierarchyView,
                  {
                    ...initialDirectoratView,
                    directorat: { ...initialDirectorat },
                  },
                ]);
              }}
              color="primary"
            >
              <Add /> Add Directorate
            </Button>
            <Typography>
              {hierarchyView.map((directorat, i) => {
                return (
                  <>
                    <Box display="flex" alignItems="center">
                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (directorateExpands.includes(i)) {
                            setdirectorateExpands(
                              directorateExpands.filter(
                                (directorateExpand) => directorateExpand !== i
                              )
                            );
                          } else {
                            setdirectorateExpands([...directorateExpands, i]);
                          }
                        }}
                      >
                        {directorateExpands.includes(i) ? (
                          <ExpandLess />
                        ) : (
                          <ChevronRight />
                        )}
                      </Box>
                      <Button
                        size="small"
                        color="secondary"
                        // variant="contained"
                        onClick={() => {
                          // Delete directorate
                          setHierarchyView(
                            hierarchyView.filter((_, ix) => ix !== i)
                          );

                          setDirectorateDeleteIds([
                            ...directorateDeleteIds,
                            directorat.directorat?.id ?? 0,
                          ]);
                        }}
                      >
                        <Delete fontSize="inherit" />
                      </Button>{" "}
                      <Box mx={1}>{i + 1}. Directorate: </Box>
                      <Box ml={2}>
                        <TextField
                          onChange={(e) =>
                            setHierarchyView(
                              hierarchyView.map((hierarchyViewX, ix) =>
                                i === ix
                                  ? hierarchyViewX.directorat
                                    ? {
                                        ...hierarchyViewX,
                                        directorat: {
                                          ...hierarchyViewX.directorat,
                                          name: e.target.value,
                                        },
                                      }
                                    : hierarchyViewX
                                  : hierarchyViewX
                              )
                            )
                          }
                          value={directorat.directorat?.name ?? ""}
                        />
                      </Box>
                      <Button
                        onClick={() => {
                          setHierarchyView(
                            hierarchyView.map((hierarchyViewX, ix) =>
                              i === ix
                                ? {
                                    ...hierarchyViewX,
                                    divisions: hierarchyViewX.divisions
                                      ? [
                                          ...hierarchyViewX.divisions,
                                          {
                                            ...initialDivisionView,
                                            division: {
                                              ...initialDivision,
                                              name: "New Division",
                                            },
                                          },
                                        ]
                                      : hierarchyViewX.divisions,
                                  }
                                : hierarchyViewX
                            )
                          );
                        }}
                        size="small"
                      >
                        <Add /> Add Division
                      </Button>
                    </Box>

                    <Box ml={15}>
                      <Box display="flex" alignItems="center">
                        Members:{" "}
                        <Box>
                          <Autocomplete
                            options={users}
                            getOptionLabel={(user) =>
                              `${user.name} (${
                                ctx?.departments.find(
                                  (department) =>
                                    department.id === user.departmentId
                                )?.name ?? "No Dept"
                              })`
                            }
                            onChange={(_, val) => {
                              if (val)
                                setHierarchyView(
                                  hierarchyView.map((hierarchyViewX, ix) =>
                                    i === ix
                                      ? hierarchyViewX.members
                                        ? {
                                            ...hierarchyViewX,
                                            members: [
                                              ...hierarchyViewX.members,
                                              {
                                                ...initialDirectoratMemberView,
                                                member: {
                                                  ...initialDirectoratMember,
                                                  user: val,
                                                },
                                              },
                                            ],
                                          }
                                        : hierarchyViewX
                                      : hierarchyViewX
                                  )
                                );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>

                      {directorat.members?.map((member) => {
                        return (
                          <Box display="flex" alignItems="center">
                            <Box display="flex" alignItems="center">
                              <Button
                                size="small"
                                onClick={() => {
                                  setHierarchyView(
                                    hierarchyView.map((hierarchyViewX, ix) =>
                                      i === ix
                                        ? hierarchyViewX.members
                                          ? {
                                              ...hierarchyViewX,
                                              members: [
                                                ...hierarchyViewX.members.filter(
                                                  (memberX) =>
                                                    memberX.member?.user?.id !==
                                                    member.member?.user?.id
                                                ),
                                              ],
                                            }
                                          : hierarchyViewX
                                        : hierarchyViewX
                                    )
                                  );

                                  setDirectoratMemberDeleteIds([
                                    ...directoratMemberDeleteIds,
                                    member.member?.id ?? 0,
                                  ]);
                                }}
                              >
                                <Delete fontSize="inherit" color="secondary" />
                              </Button>
                            </Box>
                            <Box mx={1}>
                              {(() => {
                                return `${member.member?.user?.name} (${
                                  ctx?.departments.find(
                                    (department) =>
                                      department.id ===
                                      member.member?.user?.departmentId
                                  )?.name ?? "No Dept"
                                })`;
                              })()}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>

                    <Box ml={2}>
                      <strong>
                        Divisions: ({directorat?.divisions?.length ?? 0})
                      </strong>
                    </Box>

                    <Divider />

                    {directorateExpands.includes(i) ? (
                      <Box ml={2}>
                        {directorat?.divisions?.map((division, j) => {
                          return (
                            <>
                              <Box>
                                <Box display="flex" alignItems="center">
                                  <Box
                                    onClick={() => {
                                      if (
                                        divisionExpands.find(
                                          ([ix, jx]) => ix === i && jx === j
                                        )
                                      ) {
                                        setDivisionExpands(
                                          divisionExpands.filter(
                                            ([ix, jx]) => ix !== i || jx !== j
                                          )
                                        );
                                      } else {
                                        setDivisionExpands([
                                          ...divisionExpands,
                                          [i, j],
                                        ]);
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {divisionExpands.find(
                                      ([ix, jx]) => ix === i && jx === j
                                    ) ? (
                                      <ExpandLess />
                                    ) : (
                                      <ChevronRight />
                                    )}
                                  </Box>
                                  <Button
                                    size="small"
                                    color="secondary"
                                    // variant="contained"
                                    onClick={() => {
                                      // Delete directorate
                                      setHierarchyView(
                                        hierarchyView.map(
                                          (hierarchyViewX, ix) =>
                                            i === ix
                                              ? {
                                                  ...hierarchyViewX,
                                                  divisions:
                                                    hierarchyViewX.divisions
                                                      ? hierarchyViewX.divisions.filter(
                                                          (_, jx) => j !== jx
                                                        )
                                                      : hierarchyViewX.divisions,
                                                }
                                              : hierarchyViewX
                                        )
                                      );

                                      setDivisionDeleteIds([
                                        ...divisionDeleteIds,
                                        division.division?.id ?? 0,
                                      ]);
                                    }}
                                  >
                                    <Delete fontSize="inherit" />
                                  </Button>{" "}
                                  <Box mx={1}>
                                    {i + 1}.{j + 1}. Division:
                                  </Box>{" "}
                                  <Box>
                                    <TextField
                                      onChange={(e) => {
                                        setHierarchyView(
                                          hierarchyView.map(
                                            (hierarchyViewX, ix) =>
                                              i === ix
                                                ? {
                                                    ...hierarchyViewX,
                                                    divisions:
                                                      hierarchyViewX.divisions
                                                        ? hierarchyViewX.divisions.map(
                                                            (divisionX, jx) =>
                                                              j === jx
                                                                ? divisionX.division
                                                                  ? {
                                                                      ...divisionX,
                                                                      division:
                                                                        {
                                                                          ...divisionX.division,
                                                                          name: e
                                                                            .target
                                                                            .value,
                                                                        },
                                                                    }
                                                                  : divisionX
                                                                : divisionX
                                                          )
                                                        : hierarchyViewX.divisions,
                                                  }
                                                : hierarchyViewX
                                          )
                                        );
                                      }}
                                      value={division.division?.name}
                                    />
                                    <Button
                                      onClick={() => {
                                        setHierarchyView(
                                          hierarchyView.map(
                                            (hierarchyViewX, ix) =>
                                              i === ix
                                                ? {
                                                    ...hierarchyViewX,
                                                    divisions:
                                                      hierarchyViewX.divisions
                                                        ? hierarchyViewX.divisions.map(
                                                            (divisionX, jx) =>
                                                              j === jx
                                                                ? divisionX.departmentHierarchies
                                                                  ? {
                                                                      ...divisionX,
                                                                      departmentHierarchies:
                                                                        divisionX.departmentHierarchies
                                                                          ? [
                                                                              ...divisionX.departmentHierarchies,
                                                                              {
                                                                                ...initialDepartmentHierarchyView,
                                                                                departmentHierarchy:
                                                                                  {
                                                                                    ...initialDepartmentHierarchy,
                                                                                    name: "New Department",
                                                                                  },
                                                                              },
                                                                            ]
                                                                          : divisionX.departmentHierarchies,
                                                                    }
                                                                  : divisionX
                                                                : divisionX
                                                          )
                                                        : hierarchyViewX.divisions,
                                                  }
                                                : hierarchyViewX
                                          )
                                        );
                                      }}
                                      size="small"
                                    >
                                      <Add /> Add Department
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>

                              <Box ml={15} display="flex" alignItems="center">
                                Members:{" "}
                                <Box>
                                  <Autocomplete
                                    options={users}
                                    getOptionLabel={(user) =>
                                      `${user.name} (${
                                        ctx?.departments.find(
                                          (department) =>
                                            department.id === user.departmentId
                                        )?.name ?? "No Dept"
                                      })`
                                    }
                                    onChange={(_, val) => {
                                      if (val)
                                        setHierarchyView(
                                          hierarchyView.map(
                                            (hierarchyViewX, ix) =>
                                              i === ix
                                                ? {
                                                    ...hierarchyViewX,
                                                    divisions:
                                                      hierarchyViewX.divisions
                                                        ? hierarchyViewX.divisions.map(
                                                            (divisionX, jx) =>
                                                              j === jx
                                                                ? divisionX.members
                                                                  ? {
                                                                      ...divisionX,
                                                                      members: [
                                                                        ...divisionX.members,
                                                                        {
                                                                          ...initialDivisionMemberView,
                                                                          member:
                                                                            {
                                                                              ...initialDivisionMember,
                                                                              user: val,
                                                                            },
                                                                        },
                                                                      ],
                                                                    }
                                                                  : divisionX
                                                                : divisionX
                                                          )
                                                        : hierarchyViewX.divisions,
                                                  }
                                                : hierarchyViewX
                                          )
                                        );
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </Box>
                              </Box>

                              <Box ml={15}>
                                {division.members?.map((member) => {
                                  return (
                                    <Box display="flex" alignItems="center">
                                      <Box display="flex" alignItems="center">
                                        <Button
                                          size="small"
                                          onClick={() => {
                                            setHierarchyView(
                                              hierarchyView.map(
                                                (hierarchyViewX, ix) =>
                                                  i === ix
                                                    ? {
                                                        ...hierarchyViewX,
                                                        divisions:
                                                          hierarchyViewX.divisions
                                                            ? hierarchyViewX.divisions.map(
                                                                (
                                                                  divisionX,
                                                                  jx
                                                                ) =>
                                                                  j === jx
                                                                    ? divisionX.members
                                                                      ? {
                                                                          ...divisionX,
                                                                          members:
                                                                            divisionX.members.filter(
                                                                              (
                                                                                memberX
                                                                              ) =>
                                                                                memberX
                                                                                  .member
                                                                                  ?.user
                                                                                  ?.id !==
                                                                                member
                                                                                  .member
                                                                                  ?.user
                                                                                  ?.id
                                                                            ),
                                                                        }
                                                                      : divisionX
                                                                    : divisionX
                                                              )
                                                            : hierarchyViewX.divisions,
                                                      }
                                                    : hierarchyViewX
                                              )
                                            );

                                            setDivisionMemberDeleteIds([
                                              ...divisionMemberDeleteIds,
                                              member.member?.id ?? 0,
                                            ]);
                                          }}
                                        >
                                          <Delete
                                            fontSize="inherit"
                                            color="secondary"
                                          />
                                        </Button>
                                      </Box>
                                      <Box mx={1}>
                                        {(() => {
                                          return `${
                                            member.member?.user?.name
                                          } (${
                                            ctx?.departments.find(
                                              (department) =>
                                                department.id ===
                                                member.member?.user
                                                  ?.departmentId
                                            )?.name ?? "No Dept"
                                          })`;
                                        })()}
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box>

                              <Box ml={4}>
                                <strong>
                                  Departments: (
                                  {division?.departmentHierarchies?.length ?? 0}
                                  )
                                </strong>
                              </Box>

                              <Divider />

                              {divisionExpands.find(
                                ([ix, jx]) => ix === i && jx === j
                              ) ? (
                                <Box ml={4}>
                                  {division?.departmentHierarchies?.map(
                                    (departmentHierarchy, k) => {
                                      return (
                                        <Box>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Box
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                if (
                                                  departmentExpands.find(
                                                    ([ix, jx, kx]) =>
                                                      ix === i &&
                                                      jx === j &&
                                                      kx === k
                                                  )
                                                ) {
                                                  setDepartmentExpands(
                                                    departmentExpands.filter(
                                                      ([ix, jx, kx]) =>
                                                        ix !== i ||
                                                        jx !== j ||
                                                        kx !== k
                                                    )
                                                  );
                                                } else {
                                                  setDepartmentExpands([
                                                    ...departmentExpands,
                                                    [i, j, k],
                                                  ]);
                                                }
                                              }}
                                            >
                                              {departmentExpands.find(
                                                ([ix, jx, kx]) =>
                                                  ix === i &&
                                                  jx === j &&
                                                  kx === k
                                              ) ? (
                                                <ExpandLess />
                                              ) : (
                                                <ChevronRight />
                                              )}
                                            </Box>
                                            <Button
                                              size="small"
                                              color="secondary"
                                              // variant="contained"
                                              onClick={() => {
                                                // Delete directorate
                                                setHierarchyView(
                                                  hierarchyView.map(
                                                    (hierarchyViewX, ix) =>
                                                      i === ix
                                                        ? {
                                                            ...hierarchyViewX,
                                                            divisions:
                                                              hierarchyViewX.divisions
                                                                ? hierarchyViewX.divisions.map(
                                                                    (
                                                                      divisionX,
                                                                      jx
                                                                    ) =>
                                                                      j === jx
                                                                        ? divisionX.departmentHierarchies
                                                                          ? {
                                                                              ...divisionX,
                                                                              departmentHierarchies:
                                                                                divisionX.departmentHierarchies.filter(
                                                                                  (
                                                                                    _,
                                                                                    kx
                                                                                  ) =>
                                                                                    k !==
                                                                                    kx
                                                                                ),
                                                                            }
                                                                          : divisionX
                                                                        : divisionX
                                                                  )
                                                                : hierarchyViewX.divisions,
                                                          }
                                                        : hierarchyViewX
                                                  )
                                                );

                                                setDepartmentHierarchyDeleteIds(
                                                  [
                                                    ...departmentHierarchyDeleteIds,
                                                    departmentHierarchy
                                                      .departmentHierarchy
                                                      ?.id ?? 0,
                                                  ]
                                                );
                                              }}
                                            >
                                              <Delete fontSize="inherit" />
                                            </Button>{" "}
                                            <Box mx={1}>
                                              {i + 1}.{j + 1}.{k + 1}.
                                              Department:{" "}
                                            </Box>
                                            <Box mx={1}>
                                              <TextField
                                                onChange={(e) => {
                                                  setHierarchyView(
                                                    hierarchyView.map(
                                                      (hierarchyViewX, ix) =>
                                                        i === ix
                                                          ? {
                                                              ...hierarchyViewX,
                                                              divisions:
                                                                hierarchyViewX.divisions
                                                                  ? hierarchyViewX.divisions.map(
                                                                      (
                                                                        divisionX,
                                                                        jx
                                                                      ) =>
                                                                        j === jx
                                                                          ? divisionX.division
                                                                            ? {
                                                                                ...divisionX,
                                                                                departmentHierarchies:
                                                                                  divisionX.departmentHierarchies
                                                                                    ? divisionX.departmentHierarchies.map(
                                                                                        (
                                                                                          departmentHierarchyX,
                                                                                          kx
                                                                                        ) =>
                                                                                          k ===
                                                                                          kx
                                                                                            ? departmentHierarchyX.departmentHierarchy
                                                                                              ? {
                                                                                                  ...departmentHierarchyX,
                                                                                                  departmentHierarchy:
                                                                                                    {
                                                                                                      ...departmentHierarchyX.departmentHierarchy,
                                                                                                      name: e
                                                                                                        .target
                                                                                                        .value,
                                                                                                    },
                                                                                                }
                                                                                              : departmentHierarchyX
                                                                                            : departmentHierarchyX
                                                                                      )
                                                                                    : divisionX.departmentHierarchies,
                                                                              }
                                                                            : divisionX
                                                                          : divisionX
                                                                    )
                                                                  : hierarchyViewX.divisions,
                                                            }
                                                          : hierarchyViewX
                                                    )
                                                  );
                                                }}
                                                value={
                                                  departmentHierarchy
                                                    .departmentHierarchy?.name
                                                }
                                              />
                                              <Button
                                                onClick={() => {
                                                  setHierarchyView(
                                                    hierarchyView.map(
                                                      (hierarchyViewX, ix) =>
                                                        i === ix
                                                          ? {
                                                              ...hierarchyViewX,
                                                              divisions:
                                                                hierarchyViewX.divisions
                                                                  ? hierarchyViewX.divisions.map(
                                                                      (
                                                                        divisionX,
                                                                        jx
                                                                      ) =>
                                                                        j === jx
                                                                          ? divisionX.departmentHierarchies
                                                                            ? {
                                                                                ...divisionX,
                                                                                departmentHierarchies:
                                                                                  divisionX.departmentHierarchies.map(
                                                                                    (
                                                                                      departmentHierarchyX,
                                                                                      kx
                                                                                    ) =>
                                                                                      k ===
                                                                                      kx
                                                                                        ? departmentHierarchyX.sections
                                                                                          ? {
                                                                                              ...departmentHierarchyX,
                                                                                              sections:
                                                                                                departmentHierarchyX.sections
                                                                                                  ? [
                                                                                                      ...departmentHierarchyX.sections,
                                                                                                      {
                                                                                                        ...initialSectionView,
                                                                                                        section:
                                                                                                          {
                                                                                                            ...initialSection,
                                                                                                            name: "New Section",
                                                                                                          },
                                                                                                      },
                                                                                                    ]
                                                                                                  : departmentHierarchyX.sections,
                                                                                            }
                                                                                          : departmentHierarchyX
                                                                                        : departmentHierarchyX
                                                                                  ),
                                                                              }
                                                                            : divisionX
                                                                          : divisionX
                                                                    )
                                                                  : hierarchyViewX.divisions,
                                                            }
                                                          : hierarchyViewX
                                                    )
                                                  );
                                                }}
                                                size="small"
                                              >
                                                <Add /> Add Section
                                              </Button>
                                            </Box>
                                          </Box>

                                          <Box
                                            ml={17}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            Members:{" "}
                                            <Box>
                                              <Autocomplete
                                                options={users}
                                                getOptionLabel={(user) =>
                                                  `${user.name} (${
                                                    ctx?.departments.find(
                                                      (department) =>
                                                        department.id ===
                                                        user.departmentId
                                                    )?.name ?? "No Dept"
                                                  })`
                                                }
                                                onChange={(_, val) => {
                                                  if (val)
                                                    setHierarchyView(
                                                      hierarchyView.map(
                                                        (hierarchyViewX, ix) =>
                                                          i === ix
                                                            ? {
                                                                ...hierarchyViewX,
                                                                divisions:
                                                                  hierarchyViewX.divisions
                                                                    ? hierarchyViewX.divisions.map(
                                                                        (
                                                                          divisionX,
                                                                          jx
                                                                        ) =>
                                                                          j ===
                                                                          jx
                                                                            ? divisionX.division
                                                                              ? {
                                                                                  ...divisionX,
                                                                                  departmentHierarchies:
                                                                                    divisionX.departmentHierarchies
                                                                                      ? divisionX.departmentHierarchies.map(
                                                                                          (
                                                                                            departmentHierarchyX,
                                                                                            kx
                                                                                          ) =>
                                                                                            k ===
                                                                                            kx
                                                                                              ? departmentHierarchyX.members
                                                                                                ? {
                                                                                                    ...departmentHierarchyX,
                                                                                                    members:
                                                                                                      [
                                                                                                        ...departmentHierarchyX.members,
                                                                                                        {
                                                                                                          ...initialDepartmentHierarchyMemberView,
                                                                                                          member:
                                                                                                            {
                                                                                                              ...initialDepartmentHierarchyMember,
                                                                                                              user: val,
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  }
                                                                                                : departmentHierarchyX
                                                                                              : departmentHierarchyX
                                                                                        )
                                                                                      : divisionX.departmentHierarchies,
                                                                                }
                                                                              : divisionX
                                                                            : divisionX
                                                                      )
                                                                    : hierarchyViewX.divisions,
                                                              }
                                                            : hierarchyViewX
                                                      )
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </Box>
                                          </Box>

                                          <Box ml={17}>
                                            {departmentHierarchy.members?.map(
                                              (member) => {
                                                return (
                                                  <Box
                                                    display="flex"
                                                    alignItems="center"
                                                  >
                                                    <Box
                                                      display="flex"
                                                      alignItems="center"
                                                    >
                                                      <Button
                                                        size="small"
                                                        onClick={() => {
                                                          setHierarchyView(
                                                            hierarchyView.map(
                                                              (
                                                                hierarchyViewX,
                                                                ix
                                                              ) =>
                                                                i === ix
                                                                  ? {
                                                                      ...hierarchyViewX,
                                                                      divisions:
                                                                        hierarchyViewX.divisions
                                                                          ? hierarchyViewX.divisions.map(
                                                                              (
                                                                                divisionX,
                                                                                jx
                                                                              ) =>
                                                                                j ===
                                                                                jx
                                                                                  ? divisionX.division
                                                                                    ? {
                                                                                        ...divisionX,
                                                                                        departmentHierarchies:
                                                                                          divisionX.departmentHierarchies
                                                                                            ? divisionX.departmentHierarchies.map(
                                                                                                (
                                                                                                  departmentHierarchyX,
                                                                                                  kx
                                                                                                ) =>
                                                                                                  k ===
                                                                                                  kx
                                                                                                    ? departmentHierarchyX.members
                                                                                                      ? {
                                                                                                          ...departmentHierarchyX,
                                                                                                          members:
                                                                                                            departmentHierarchyX.members.filter(
                                                                                                              (
                                                                                                                memberX
                                                                                                              ) =>
                                                                                                                memberX
                                                                                                                  .member
                                                                                                                  ?.user
                                                                                                                  ?.id !==
                                                                                                                member
                                                                                                                  .member
                                                                                                                  ?.user
                                                                                                                  ?.id
                                                                                                            ),
                                                                                                        }
                                                                                                      : departmentHierarchyX
                                                                                                    : departmentHierarchyX
                                                                                              )
                                                                                            : divisionX.departmentHierarchies,
                                                                                      }
                                                                                    : divisionX
                                                                                  : divisionX
                                                                            )
                                                                          : hierarchyViewX.divisions,
                                                                    }
                                                                  : hierarchyViewX
                                                            )
                                                          );

                                                          setDepartmentHierarchyMemberDeleteIds(
                                                            [
                                                              ...departmentHierarchyMemberDeleteIds,
                                                              member.member
                                                                ?.id ?? 0,
                                                            ]
                                                          );
                                                        }}
                                                      >
                                                        <Delete
                                                          fontSize="inherit"
                                                          color="secondary"
                                                        />
                                                      </Button>
                                                    </Box>
                                                    <Box mx={1}>
                                                      {(() => {
                                                        return `${
                                                          member.member?.user
                                                            ?.name
                                                        } (${
                                                          ctx?.departments.find(
                                                            (department) =>
                                                              department.id ===
                                                              member.member
                                                                ?.user
                                                                ?.departmentId
                                                          )?.name ?? "No Dept"
                                                        })`;
                                                      })()}
                                                    </Box>
                                                  </Box>
                                                );
                                              }
                                            )}
                                          </Box>

                                          <Box ml={6}>
                                            <strong>
                                              Sections: (
                                              {departmentHierarchy?.sections
                                                ?.length ?? 0}
                                              )
                                            </strong>
                                          </Box>

                                          <Divider />

                                          {departmentExpands.find(
                                            ([ix, jx, kx]) =>
                                              ix === i && jx === j && kx === k
                                          ) ? (
                                            <Box ml={6}>
                                              {departmentHierarchy?.sections?.map(
                                                (section, l) => {
                                                  return (
                                                    <Box>
                                                      <Box
                                                        display="flex"
                                                        alignItems="center"
                                                      >
                                                        {/* <ChevronRight /> */}
                                                        <Button
                                                          size="small"
                                                          color="secondary"
                                                          // variant="contained"
                                                          onClick={() => {
                                                            // Delete directorate
                                                            setHierarchyView(
                                                              hierarchyView.map(
                                                                (
                                                                  hierarchyViewX,
                                                                  ix
                                                                ) =>
                                                                  i === ix
                                                                    ? {
                                                                        ...hierarchyViewX,
                                                                        divisions:
                                                                          hierarchyViewX.divisions
                                                                            ? hierarchyViewX.divisions.map(
                                                                                (
                                                                                  divisionX,
                                                                                  jx
                                                                                ) =>
                                                                                  j ===
                                                                                  jx
                                                                                    ? divisionX.departmentHierarchies
                                                                                      ? {
                                                                                          ...divisionX,
                                                                                          departmentHierarchies:
                                                                                            divisionX.departmentHierarchies.map(
                                                                                              (
                                                                                                departmentHierarchyX,
                                                                                                kx
                                                                                              ) =>
                                                                                                k ===
                                                                                                kx
                                                                                                  ? departmentHierarchyX.sections
                                                                                                    ? {
                                                                                                        ...departmentHierarchyX,
                                                                                                        sections:
                                                                                                          departmentHierarchyX.sections.filter(
                                                                                                            (
                                                                                                              _,
                                                                                                              lx
                                                                                                            ) =>
                                                                                                              l !==
                                                                                                              lx
                                                                                                          ),
                                                                                                      }
                                                                                                    : departmentHierarchyX
                                                                                                  : departmentHierarchyX
                                                                                            ),
                                                                                        }
                                                                                      : divisionX
                                                                                    : divisionX
                                                                              )
                                                                            : hierarchyViewX.divisions,
                                                                      }
                                                                    : hierarchyViewX
                                                              )
                                                            );

                                                            setSectionDeleteIds(
                                                              [
                                                                ...sectionDeleteIds,
                                                                section.section
                                                                  ?.id ?? 0,
                                                              ]
                                                            );
                                                          }}
                                                        >
                                                          <Delete fontSize="inherit" />
                                                        </Button>{" "}
                                                        <Box mx={1}>
                                                          {i + 1}.{j + 1}.
                                                          {k + 1}.{l + 1}.
                                                          Section:{" "}
                                                        </Box>
                                                        <Box mx={1}>
                                                          <TextField
                                                            onChange={(e) => {
                                                              setHierarchyView(
                                                                hierarchyView.map(
                                                                  (
                                                                    hierarchyViewX,
                                                                    ix
                                                                  ) =>
                                                                    i === ix
                                                                      ? {
                                                                          ...hierarchyViewX,
                                                                          divisions:
                                                                            hierarchyViewX.divisions
                                                                              ? hierarchyViewX.divisions.map(
                                                                                  (
                                                                                    divisionX,
                                                                                    jx
                                                                                  ) =>
                                                                                    j ===
                                                                                    jx
                                                                                      ? divisionX.division
                                                                                        ? {
                                                                                            ...divisionX,
                                                                                            departmentHierarchies:
                                                                                              divisionX.departmentHierarchies
                                                                                                ? divisionX.departmentHierarchies.map(
                                                                                                    (
                                                                                                      departmentHierarchyX,
                                                                                                      kx
                                                                                                    ) =>
                                                                                                      k ===
                                                                                                      kx
                                                                                                        ? departmentHierarchyX.sections
                                                                                                          ? {
                                                                                                              ...departmentHierarchyX,
                                                                                                              sections:
                                                                                                                departmentHierarchyX.sections.map(
                                                                                                                  (
                                                                                                                    sectionX,
                                                                                                                    lx
                                                                                                                  ) =>
                                                                                                                    l ===
                                                                                                                    lx
                                                                                                                      ? sectionX.section
                                                                                                                        ? {
                                                                                                                            ...sectionX,
                                                                                                                            section:
                                                                                                                              {
                                                                                                                                ...sectionX.section,
                                                                                                                                name: e
                                                                                                                                  .target
                                                                                                                                  .value,
                                                                                                                              },
                                                                                                                          }
                                                                                                                        : sectionX
                                                                                                                      : sectionX
                                                                                                                ),
                                                                                                            }
                                                                                                          : departmentHierarchyX
                                                                                                        : departmentHierarchyX
                                                                                                  )
                                                                                                : divisionX.departmentHierarchies,
                                                                                          }
                                                                                        : divisionX
                                                                                      : divisionX
                                                                                )
                                                                              : hierarchyViewX.divisions,
                                                                        }
                                                                      : hierarchyViewX
                                                                )
                                                              );
                                                            }}
                                                            value={
                                                              section.section
                                                                ?.name ?? ""
                                                            }
                                                          />
                                                          <Button
                                                            onClick={() => {
                                                              setHierarchyView(
                                                                hierarchyView.map(
                                                                  (
                                                                    hierarchyViewX,
                                                                    ix
                                                                  ) =>
                                                                    i === ix
                                                                      ? {
                                                                          ...hierarchyViewX,
                                                                          divisions:
                                                                            hierarchyViewX.divisions
                                                                              ? hierarchyViewX.divisions.map(
                                                                                  (
                                                                                    divisionX,
                                                                                    jx
                                                                                  ) =>
                                                                                    j ===
                                                                                    jx
                                                                                      ? divisionX.division
                                                                                        ? {
                                                                                            ...divisionX,
                                                                                            departmentHierarchies:
                                                                                              divisionX.departmentHierarchies
                                                                                                ? divisionX.departmentHierarchies.map(
                                                                                                    (
                                                                                                      departmentHierarchyX,
                                                                                                      kx
                                                                                                    ) =>
                                                                                                      k ===
                                                                                                      kx
                                                                                                        ? departmentHierarchyX.sections
                                                                                                          ? {
                                                                                                              ...departmentHierarchyX,
                                                                                                              sections:
                                                                                                                departmentHierarchyX.sections.map(
                                                                                                                  (
                                                                                                                    sectionX,
                                                                                                                    lx
                                                                                                                  ) =>
                                                                                                                    l ===
                                                                                                                    lx
                                                                                                                      ? sectionX.section
                                                                                                                        ? {
                                                                                                                            ...sectionX,
                                                                                                                            members:
                                                                                                                              sectionX.members
                                                                                                                                ? [
                                                                                                                                    ...sectionX.members,
                                                                                                                                    {
                                                                                                                                      ...initialSectionMemberView,
                                                                                                                                      member:
                                                                                                                                        {
                                                                                                                                          ...initialSectionMember,
                                                                                                                                        },
                                                                                                                                    },
                                                                                                                                  ]
                                                                                                                                : sectionX.members,
                                                                                                                          }
                                                                                                                        : sectionX
                                                                                                                      : sectionX
                                                                                                                ),
                                                                                                            }
                                                                                                          : departmentHierarchyX
                                                                                                        : departmentHierarchyX
                                                                                                  )
                                                                                                : divisionX.departmentHierarchies,
                                                                                          }
                                                                                        : divisionX
                                                                                      : divisionX
                                                                                )
                                                                              : hierarchyViewX.divisions,
                                                                        }
                                                                      : hierarchyViewX
                                                                )
                                                              );
                                                            }}
                                                            size="small"
                                                          >
                                                            <Add /> Add Member
                                                          </Button>
                                                        </Box>
                                                      </Box>

                                                      <Box ml={15}>
                                                        Members: (
                                                        {section?.members
                                                          ?.length ?? 0}
                                                        )
                                                      </Box>

                                                      <Divider />

                                                      <Box>
                                                        {" "}
                                                        {section?.members?.map(
                                                          (member, m) => {
                                                            return (
                                                              <Box
                                                                ml={14}
                                                                display="flex"
                                                                alignItems="center"
                                                              >
                                                                {/* <ChevronRight /> */}
                                                                <Button
                                                                  size="small"
                                                                  color="secondary"
                                                                  // variant="contained"
                                                                  onClick={() => {
                                                                    // Delete directorate
                                                                    setHierarchyView(
                                                                      hierarchyView.map(
                                                                        (
                                                                          hierarchyViewX,
                                                                          ix
                                                                        ) =>
                                                                          i ===
                                                                          ix
                                                                            ? {
                                                                                ...hierarchyViewX,
                                                                                divisions:
                                                                                  hierarchyViewX.divisions
                                                                                    ? hierarchyViewX.divisions.map(
                                                                                        (
                                                                                          divisionX,
                                                                                          jx
                                                                                        ) =>
                                                                                          j ===
                                                                                          jx
                                                                                            ? divisionX.departmentHierarchies
                                                                                              ? {
                                                                                                  ...divisionX,
                                                                                                  departmentHierarchies:
                                                                                                    divisionX.departmentHierarchies.map(
                                                                                                      (
                                                                                                        departmentHierarchyX,
                                                                                                        kx
                                                                                                      ) =>
                                                                                                        k ===
                                                                                                        kx
                                                                                                          ? departmentHierarchyX.sections
                                                                                                            ? {
                                                                                                                ...departmentHierarchyX,
                                                                                                                sections:
                                                                                                                  departmentHierarchyX.sections.map(
                                                                                                                    (
                                                                                                                      sectionX,
                                                                                                                      lx
                                                                                                                    ) =>
                                                                                                                      lx ===
                                                                                                                      l
                                                                                                                        ? sectionX.members
                                                                                                                          ? {
                                                                                                                              ...sectionX,
                                                                                                                              members:
                                                                                                                                sectionX.members.filter(
                                                                                                                                  (
                                                                                                                                    _,
                                                                                                                                    mx
                                                                                                                                  ) =>
                                                                                                                                    mx !==
                                                                                                                                    m
                                                                                                                                ),
                                                                                                                            }
                                                                                                                          : sectionX
                                                                                                                        : sectionX
                                                                                                                  ),
                                                                                                              }
                                                                                                            : departmentHierarchyX
                                                                                                          : departmentHierarchyX
                                                                                                    ),
                                                                                                }
                                                                                              : divisionX
                                                                                            : divisionX
                                                                                      )
                                                                                    : hierarchyViewX.divisions,
                                                                              }
                                                                            : hierarchyViewX
                                                                      )
                                                                    );

                                                                    setSectionMemberDeleteIds(
                                                                      [
                                                                        ...sectionMemberDeleteIds,
                                                                        member
                                                                          .member
                                                                          ?.id ??
                                                                          0,
                                                                      ]
                                                                    );
                                                                  }}
                                                                >
                                                                  <Delete fontSize="inherit" />
                                                                </Button>{" "}
                                                                <Box mx={1}>
                                                                  {i + 1}.
                                                                  {j + 1}.
                                                                  {k + 1}.
                                                                  {l + 1}.
                                                                  {m + 1}. User:{" "}
                                                                </Box>
                                                                <Box
                                                                  mx={1}
                                                                  width={300}
                                                                >
                                                                  {" "}
                                                                  <Autocomplete
                                                                    fullWidth
                                                                    value={
                                                                      member
                                                                        .member
                                                                        ?.user
                                                                    }
                                                                    options={
                                                                      users
                                                                    }
                                                                    getOptionLabel={(
                                                                      user
                                                                    ) =>
                                                                      `${
                                                                        user.name
                                                                      } (${
                                                                        ctx?.departments.find(
                                                                          (
                                                                            department
                                                                          ) =>
                                                                            department.id ===
                                                                            user.departmentId
                                                                        )
                                                                          ?.name ??
                                                                        "No Dept"
                                                                      })`
                                                                    }
                                                                    onChange={(
                                                                      _,
                                                                      val
                                                                    ) => {
                                                                      setHierarchyView(
                                                                        hierarchyView.map(
                                                                          (
                                                                            hierarchyViewX,
                                                                            ix
                                                                          ) =>
                                                                            i ===
                                                                            ix
                                                                              ? {
                                                                                  ...hierarchyViewX,
                                                                                  divisions:
                                                                                    hierarchyViewX.divisions
                                                                                      ? hierarchyViewX.divisions.map(
                                                                                          (
                                                                                            divisionX,
                                                                                            jx
                                                                                          ) =>
                                                                                            j ===
                                                                                            jx
                                                                                              ? divisionX.division
                                                                                                ? {
                                                                                                    ...divisionX,
                                                                                                    departmentHierarchies:
                                                                                                      divisionX.departmentHierarchies
                                                                                                        ? divisionX.departmentHierarchies.map(
                                                                                                            (
                                                                                                              departmentHierarchyX,
                                                                                                              kx
                                                                                                            ) =>
                                                                                                              k ===
                                                                                                              kx
                                                                                                                ? departmentHierarchyX.sections
                                                                                                                  ? {
                                                                                                                      ...departmentHierarchyX,
                                                                                                                      sections:
                                                                                                                        departmentHierarchyX.sections.map(
                                                                                                                          (
                                                                                                                            sectionX,
                                                                                                                            lx
                                                                                                                          ) =>
                                                                                                                            l ===
                                                                                                                            lx
                                                                                                                              ? sectionX.section
                                                                                                                                ? {
                                                                                                                                    ...sectionX,
                                                                                                                                    members:
                                                                                                                                      sectionX.members
                                                                                                                                        ? sectionX.members.map(
                                                                                                                                            (
                                                                                                                                              memberX,
                                                                                                                                              mx
                                                                                                                                            ) =>
                                                                                                                                              m ===
                                                                                                                                              mx
                                                                                                                                                ? memberX.member
                                                                                                                                                  ? {
                                                                                                                                                      ...memberX,
                                                                                                                                                      member:
                                                                                                                                                        {
                                                                                                                                                          ...memberX.member,
                                                                                                                                                          user: val,
                                                                                                                                                        },
                                                                                                                                                    }
                                                                                                                                                  : memberX
                                                                                                                                                : memberX
                                                                                                                                          )
                                                                                                                                        : sectionX.members,
                                                                                                                                  }
                                                                                                                                : sectionX
                                                                                                                              : sectionX
                                                                                                                        ),
                                                                                                                    }
                                                                                                                  : departmentHierarchyX
                                                                                                                : departmentHierarchyX
                                                                                                          )
                                                                                                        : divisionX.departmentHierarchies,
                                                                                                  }
                                                                                                : divisionX
                                                                                              : divisionX
                                                                                        )
                                                                                      : hierarchyViewX.divisions,
                                                                                }
                                                                              : hierarchyViewX
                                                                        )
                                                                      );
                                                                    }}
                                                                    renderInput={(
                                                                      params
                                                                    ) => (
                                                                      <TextField
                                                                        {...params}
                                                                      />
                                                                    )}
                                                                  />{" "}
                                                                </Box>
                                                              </Box>
                                                            );
                                                          }
                                                        )}
                                                      </Box>
                                                    </Box>
                                                  );
                                                }
                                              )}
                                            </Box>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </Typography>
          </Box>
        )}

        <pre>
          <small>{JSON.stringify(divisionExpands)}</small>
        </pre>

        <pre>
          <small>{JSON.stringify(departmentExpands)}</small>
        </pre>

        <pre>
          <small>{JSON.stringify(sectionExpands)}</small>
        </pre>

        {/* <pre>{JSON.stringify(hierarchyView, null, 2)}</pre> */}
      </Box>
    </Box>
  );
};

export default HierarchyV2Page;
