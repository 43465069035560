import { useEffect, useRef, useState } from "react";
import {
  AuthserverPph21MarriageCategories,
  AuthserverPph21MarriageCategory,
  MasterJavaBaseModel,
} from "../../masterbigsystem";
import { fetchPph21MarriageCategories } from "../../helpers/fetchers";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { useForkRef } from "@material-ui/core";
import { v4 } from "uuid";
import { error } from "console";

const Pph21CategoryPage = () => {
  const [ctx, dispatch] = useAppState() ?? [];
  const [, refresh] = useState(false);

  const pph21Settings = useRef(
    AuthserverPph21MarriageCategories.fromPartial({ categories: [] })
  );

  const fetchPphData = async () => {
    const d = await fetchPph21MarriageCategories(ctx?.apiKey ?? "");

    if (d) {
      pph21Settings.current = d;

      render();
    }
  };

  const render = () => {
    refresh((n) => !n);
  };

  const handleInit = () => {
    fetchPphData();
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/pph21marriagecategories-proto-save-bulk`,
        {
          method: "post",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(pph21Settings.current),
        }
      );

      if (resp.status !== 200) {
        throw await resp.text();
      }

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    handleInit();
  }, []);

  return (
    <>
      <div className="m-3">
        <div>
          <div className="d-flex">
            <h4>PPH 21 Category Settings</h4>
            <div>
              <button
                onClick={() => {
                  handleSave();
                }}
                className="btn btn-sm btn-primary"
              >
                Save
              </button>
            </div>
          </div>
          <hr className="border border-dark" />
          <div>
            <button
              onClick={() => {
                pph21Settings.current.categories.push(
                  AuthserverPph21MarriageCategory.fromPartial({
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: v4(),
                    }),
                  })
                );

                render();
              }}
              className="btn btn-sm btn-primary"
            >
              Add
            </button>
          </div>
          <div
            className="border border-dark overflow-auto"
            style={{ resize: "vertical", height: "75vh" }}
          >
            <table
              className="table table-sm"
              style={{
                borderCollapse: "separate",
              }}
            >
              <tr>
                {["#", "Name", "TER Category"].map((h) => (
                  <>
                    <th className="bg-dark text-light">{h}</th>
                  </>
                ))}
              </tr>
              {pph21Settings.current.categories.map((c, i) => {
                return (
                  <>
                    <tr>
                      <td className="border border-dark p-0 m-0">{i + 1}</td>
                      <td className="border border-dark p-0 m-0">
                        <input
                          className="form-control form-control-sm"
                          placeholder="Name..."
                          defaultValue={c.name}
                          key={c.masterJavaBaseModel?.uuid}
                          onBlur={(e) => {
                            c.name = e.target.value;
                            render();
                          }}
                        />
                      </td>
                      <td className="border border-dark p-0 m-0">
                        <input
                          className="form-control form-control-sm"
                          placeholder="Category..."
                          defaultValue={c.terCategory}
                          key={c.masterJavaBaseModel?.uuid}
                          onBlur={(e) => {
                            c.terCategory = e.target.value;
                            render();
                          }}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pph21CategoryPage;
