/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum OKRType {
  OKR_TYPE_UNRECOGNIZED = 0,
  COMPANY = 1,
  DEPARTMENTAL = 2,
  TEAM = 3,
  INDIVIDUAL = 4,
  UNRECOGNIZED = -1,
}

export function oKRTypeFromJSON(object: any): OKRType {
  switch (object) {
    case 0:
    case "OKR_TYPE_UNRECOGNIZED":
      return OKRType.OKR_TYPE_UNRECOGNIZED;
    case 1:
    case "COMPANY":
      return OKRType.COMPANY;
    case 2:
    case "DEPARTMENTAL":
      return OKRType.DEPARTMENTAL;
    case 3:
    case "TEAM":
      return OKRType.TEAM;
    case 4:
    case "INDIVIDUAL":
      return OKRType.INDIVIDUAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OKRType.UNRECOGNIZED;
  }
}

export function oKRTypeToJSON(object: OKRType): string {
  switch (object) {
    case OKRType.OKR_TYPE_UNRECOGNIZED:
      return "OKR_TYPE_UNRECOGNIZED";
    case OKRType.COMPANY:
      return "COMPANY";
    case OKRType.DEPARTMENTAL:
      return "DEPARTMENTAL";
    case OKRType.TEAM:
      return "TEAM";
    case OKRType.INDIVIDUAL:
      return "INDIVIDUAL";
    case OKRType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum OKRTimeRange {
  OKR_TIME_RANGE_UNRECOGNIZED = 0,
  LONG_TERM = 1,
  ANNUAL = 2,
  QUARTERLY = 3,
  UNRECOGNIZED = -1,
}

export function oKRTimeRangeFromJSON(object: any): OKRTimeRange {
  switch (object) {
    case 0:
    case "OKR_TIME_RANGE_UNRECOGNIZED":
      return OKRTimeRange.OKR_TIME_RANGE_UNRECOGNIZED;
    case 1:
    case "LONG_TERM":
      return OKRTimeRange.LONG_TERM;
    case 2:
    case "ANNUAL":
      return OKRTimeRange.ANNUAL;
    case 3:
    case "QUARTERLY":
      return OKRTimeRange.QUARTERLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OKRTimeRange.UNRECOGNIZED;
  }
}

export function oKRTimeRangeToJSON(object: OKRTimeRange): string {
  switch (object) {
    case OKRTimeRange.OKR_TIME_RANGE_UNRECOGNIZED:
      return "OKR_TIME_RANGE_UNRECOGNIZED";
    case OKRTimeRange.LONG_TERM:
      return "LONG_TERM";
    case OKRTimeRange.ANNUAL:
      return "ANNUAL";
    case OKRTimeRange.QUARTERLY:
      return "QUARTERLY";
    case OKRTimeRange.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AppSource {
  APP_TYPE_UNRECOGNIZED = 0,
  CRM = 1,
  WAREHOUSE = 2,
  LOGISTIC = 3,
  PURCHASE = 4,
  WORKSHOP = 5,
  ACCOUNTING = 6,
  PPC_APP = 7,
  DELIVERY = 8,
  UNRECOGNIZED = -1,
}

export function appSourceFromJSON(object: any): AppSource {
  switch (object) {
    case 0:
    case "APP_TYPE_UNRECOGNIZED":
      return AppSource.APP_TYPE_UNRECOGNIZED;
    case 1:
    case "CRM":
      return AppSource.CRM;
    case 2:
    case "WAREHOUSE":
      return AppSource.WAREHOUSE;
    case 3:
    case "LOGISTIC":
      return AppSource.LOGISTIC;
    case 4:
    case "PURCHASE":
      return AppSource.PURCHASE;
    case 5:
    case "WORKSHOP":
      return AppSource.WORKSHOP;
    case 6:
    case "ACCOUNTING":
      return AppSource.ACCOUNTING;
    case 7:
    case "PPC_APP":
      return AppSource.PPC_APP;
    case 8:
    case "DELIVERY":
      return AppSource.DELIVERY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AppSource.UNRECOGNIZED;
  }
}

export function appSourceToJSON(object: AppSource): string {
  switch (object) {
    case AppSource.APP_TYPE_UNRECOGNIZED:
      return "APP_TYPE_UNRECOGNIZED";
    case AppSource.CRM:
      return "CRM";
    case AppSource.WAREHOUSE:
      return "WAREHOUSE";
    case AppSource.LOGISTIC:
      return "LOGISTIC";
    case AppSource.PURCHASE:
      return "PURCHASE";
    case AppSource.WORKSHOP:
      return "WORKSHOP";
    case AppSource.ACCOUNTING:
      return "ACCOUNTING";
    case AppSource.PPC_APP:
      return "PPC_APP";
    case AppSource.DELIVERY:
      return "DELIVERY";
    case AppSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MiddlewarePredefinedActionType {
  ACTION_SEND_UNRECOGNIZED = 0,
  ACTION_SEND_WHATSAPP = 1,
  ACTION_CREATE_WO = 2,
  UNRECOGNIZED = -1,
}

export function middlewarePredefinedActionTypeFromJSON(object: any): MiddlewarePredefinedActionType {
  switch (object) {
    case 0:
    case "ACTION_SEND_UNRECOGNIZED":
      return MiddlewarePredefinedActionType.ACTION_SEND_UNRECOGNIZED;
    case 1:
    case "ACTION_SEND_WHATSAPP":
      return MiddlewarePredefinedActionType.ACTION_SEND_WHATSAPP;
    case 2:
    case "ACTION_CREATE_WO":
      return MiddlewarePredefinedActionType.ACTION_CREATE_WO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MiddlewarePredefinedActionType.UNRECOGNIZED;
  }
}

export function middlewarePredefinedActionTypeToJSON(object: MiddlewarePredefinedActionType): string {
  switch (object) {
    case MiddlewarePredefinedActionType.ACTION_SEND_UNRECOGNIZED:
      return "ACTION_SEND_UNRECOGNIZED";
    case MiddlewarePredefinedActionType.ACTION_SEND_WHATSAPP:
      return "ACTION_SEND_WHATSAPP";
    case MiddlewarePredefinedActionType.ACTION_CREATE_WO:
      return "ACTION_CREATE_WO";
    case MiddlewarePredefinedActionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MiddlewareWorkflowSummaryType {
  WORKFLOW_SUM_UNRECOGNIZED = 0,
  WORKFLOW_SUM_CHAIN_WO = 1,
  WORKFLOW_SUM_ESCALATION = 2,
  UNRECOGNIZED = -1,
}

export function middlewareWorkflowSummaryTypeFromJSON(object: any): MiddlewareWorkflowSummaryType {
  switch (object) {
    case 0:
    case "WORKFLOW_SUM_UNRECOGNIZED":
      return MiddlewareWorkflowSummaryType.WORKFLOW_SUM_UNRECOGNIZED;
    case 1:
    case "WORKFLOW_SUM_CHAIN_WO":
      return MiddlewareWorkflowSummaryType.WORKFLOW_SUM_CHAIN_WO;
    case 2:
    case "WORKFLOW_SUM_ESCALATION":
      return MiddlewareWorkflowSummaryType.WORKFLOW_SUM_ESCALATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MiddlewareWorkflowSummaryType.UNRECOGNIZED;
  }
}

export function middlewareWorkflowSummaryTypeToJSON(object: MiddlewareWorkflowSummaryType): string {
  switch (object) {
    case MiddlewareWorkflowSummaryType.WORKFLOW_SUM_UNRECOGNIZED:
      return "WORKFLOW_SUM_UNRECOGNIZED";
    case MiddlewareWorkflowSummaryType.WORKFLOW_SUM_CHAIN_WO:
      return "WORKFLOW_SUM_CHAIN_WO";
    case MiddlewareWorkflowSummaryType.WORKFLOW_SUM_ESCALATION:
      return "WORKFLOW_SUM_ESCALATION";
    case MiddlewareWorkflowSummaryType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TaskListDeptProblemType {
  TL_PROBLEM_UNRECOGNIZED = 0,
  TL_PROBLEM_INTERNAL = 1,
  TL_PROBLEM_EXTERNAL = 2,
  UNRECOGNIZED = -1,
}

export function taskListDeptProblemTypeFromJSON(object: any): TaskListDeptProblemType {
  switch (object) {
    case 0:
    case "TL_PROBLEM_UNRECOGNIZED":
      return TaskListDeptProblemType.TL_PROBLEM_UNRECOGNIZED;
    case 1:
    case "TL_PROBLEM_INTERNAL":
      return TaskListDeptProblemType.TL_PROBLEM_INTERNAL;
    case 2:
    case "TL_PROBLEM_EXTERNAL":
      return TaskListDeptProblemType.TL_PROBLEM_EXTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TaskListDeptProblemType.UNRECOGNIZED;
  }
}

export function taskListDeptProblemTypeToJSON(object: TaskListDeptProblemType): string {
  switch (object) {
    case TaskListDeptProblemType.TL_PROBLEM_UNRECOGNIZED:
      return "TL_PROBLEM_UNRECOGNIZED";
    case TaskListDeptProblemType.TL_PROBLEM_INTERNAL:
      return "TL_PROBLEM_INTERNAL";
    case TaskListDeptProblemType.TL_PROBLEM_EXTERNAL:
      return "TL_PROBLEM_EXTERNAL";
    case TaskListDeptProblemType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TaskListReferenceType {
  TASK_LIST_REFERENCE_TYPE_UNRECOGNIZED = 0,
  PO_CUSTOMER = 1,
  INQUIRY_CUSTOMER = 2,
  QUOTATION_CUSTOMER = 3,
  RFS_CUSTOMER = 4,
  BOQ_CUSTOMER = 5,
  OPPORTUNITY_CUSTOMER = 6,
  RR = 7,
  DO_CUSTOMER = 8,
  SPK = 9,
  PO_VENDOR = 10,
  INQUIRY_VENDOR = 11,
  QUOTATION_VENDOR = 12,
  BPO_CUSTOMER = 13,
  RMA_CUSTOMER = 14,
  PROD_WORKSHOP = 15,
  JOB = 16,
  MODULE = 17,
  SUBMODULE = 18,
  SET = 19,
  PROGRAM = 20,
  PANEL_CODE = 21,
  SUPPLIER = 22,
  MATERIAL_REQUEST = 23,
  PROCESS_TYPE = 24,
  MACHINE = 25,
  INVOICE_CUSTOMER = 26,
  INVOICE_VENDOR = 27,
  PAYMENT_CUSTOMER = 28,
  PAYMENT_VENDOR = 29,
  GENERAL_KASBON = 30,
  DRIVER_KASBON = 31,
  INSTALLER_KASBON = 32,
  ASSET = 33,
  SERIAL_NUMBER_STRING = 34,
  SALES_PLAN = 35,
  UNRECOGNIZED = -1,
}

export function taskListReferenceTypeFromJSON(object: any): TaskListReferenceType {
  switch (object) {
    case 0:
    case "TASK_LIST_REFERENCE_TYPE_UNRECOGNIZED":
      return TaskListReferenceType.TASK_LIST_REFERENCE_TYPE_UNRECOGNIZED;
    case 1:
    case "PO_CUSTOMER":
      return TaskListReferenceType.PO_CUSTOMER;
    case 2:
    case "INQUIRY_CUSTOMER":
      return TaskListReferenceType.INQUIRY_CUSTOMER;
    case 3:
    case "QUOTATION_CUSTOMER":
      return TaskListReferenceType.QUOTATION_CUSTOMER;
    case 4:
    case "RFS_CUSTOMER":
      return TaskListReferenceType.RFS_CUSTOMER;
    case 5:
    case "BOQ_CUSTOMER":
      return TaskListReferenceType.BOQ_CUSTOMER;
    case 6:
    case "OPPORTUNITY_CUSTOMER":
      return TaskListReferenceType.OPPORTUNITY_CUSTOMER;
    case 7:
    case "RR":
      return TaskListReferenceType.RR;
    case 8:
    case "DO_CUSTOMER":
      return TaskListReferenceType.DO_CUSTOMER;
    case 9:
    case "SPK":
      return TaskListReferenceType.SPK;
    case 10:
    case "PO_VENDOR":
      return TaskListReferenceType.PO_VENDOR;
    case 11:
    case "INQUIRY_VENDOR":
      return TaskListReferenceType.INQUIRY_VENDOR;
    case 12:
    case "QUOTATION_VENDOR":
      return TaskListReferenceType.QUOTATION_VENDOR;
    case 13:
    case "BPO_CUSTOMER":
      return TaskListReferenceType.BPO_CUSTOMER;
    case 14:
    case "RMA_CUSTOMER":
      return TaskListReferenceType.RMA_CUSTOMER;
    case 15:
    case "PROD_WORKSHOP":
      return TaskListReferenceType.PROD_WORKSHOP;
    case 16:
    case "JOB":
      return TaskListReferenceType.JOB;
    case 17:
    case "MODULE":
      return TaskListReferenceType.MODULE;
    case 18:
    case "SUBMODULE":
      return TaskListReferenceType.SUBMODULE;
    case 19:
    case "SET":
      return TaskListReferenceType.SET;
    case 20:
    case "PROGRAM":
      return TaskListReferenceType.PROGRAM;
    case 21:
    case "PANEL_CODE":
      return TaskListReferenceType.PANEL_CODE;
    case 22:
    case "SUPPLIER":
      return TaskListReferenceType.SUPPLIER;
    case 23:
    case "MATERIAL_REQUEST":
      return TaskListReferenceType.MATERIAL_REQUEST;
    case 24:
    case "PROCESS_TYPE":
      return TaskListReferenceType.PROCESS_TYPE;
    case 25:
    case "MACHINE":
      return TaskListReferenceType.MACHINE;
    case 26:
    case "INVOICE_CUSTOMER":
      return TaskListReferenceType.INVOICE_CUSTOMER;
    case 27:
    case "INVOICE_VENDOR":
      return TaskListReferenceType.INVOICE_VENDOR;
    case 28:
    case "PAYMENT_CUSTOMER":
      return TaskListReferenceType.PAYMENT_CUSTOMER;
    case 29:
    case "PAYMENT_VENDOR":
      return TaskListReferenceType.PAYMENT_VENDOR;
    case 30:
    case "GENERAL_KASBON":
      return TaskListReferenceType.GENERAL_KASBON;
    case 31:
    case "DRIVER_KASBON":
      return TaskListReferenceType.DRIVER_KASBON;
    case 32:
    case "INSTALLER_KASBON":
      return TaskListReferenceType.INSTALLER_KASBON;
    case 33:
    case "ASSET":
      return TaskListReferenceType.ASSET;
    case 34:
    case "SERIAL_NUMBER_STRING":
      return TaskListReferenceType.SERIAL_NUMBER_STRING;
    case 35:
    case "SALES_PLAN":
      return TaskListReferenceType.SALES_PLAN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TaskListReferenceType.UNRECOGNIZED;
  }
}

export function taskListReferenceTypeToJSON(object: TaskListReferenceType): string {
  switch (object) {
    case TaskListReferenceType.TASK_LIST_REFERENCE_TYPE_UNRECOGNIZED:
      return "TASK_LIST_REFERENCE_TYPE_UNRECOGNIZED";
    case TaskListReferenceType.PO_CUSTOMER:
      return "PO_CUSTOMER";
    case TaskListReferenceType.INQUIRY_CUSTOMER:
      return "INQUIRY_CUSTOMER";
    case TaskListReferenceType.QUOTATION_CUSTOMER:
      return "QUOTATION_CUSTOMER";
    case TaskListReferenceType.RFS_CUSTOMER:
      return "RFS_CUSTOMER";
    case TaskListReferenceType.BOQ_CUSTOMER:
      return "BOQ_CUSTOMER";
    case TaskListReferenceType.OPPORTUNITY_CUSTOMER:
      return "OPPORTUNITY_CUSTOMER";
    case TaskListReferenceType.RR:
      return "RR";
    case TaskListReferenceType.DO_CUSTOMER:
      return "DO_CUSTOMER";
    case TaskListReferenceType.SPK:
      return "SPK";
    case TaskListReferenceType.PO_VENDOR:
      return "PO_VENDOR";
    case TaskListReferenceType.INQUIRY_VENDOR:
      return "INQUIRY_VENDOR";
    case TaskListReferenceType.QUOTATION_VENDOR:
      return "QUOTATION_VENDOR";
    case TaskListReferenceType.BPO_CUSTOMER:
      return "BPO_CUSTOMER";
    case TaskListReferenceType.RMA_CUSTOMER:
      return "RMA_CUSTOMER";
    case TaskListReferenceType.PROD_WORKSHOP:
      return "PROD_WORKSHOP";
    case TaskListReferenceType.JOB:
      return "JOB";
    case TaskListReferenceType.MODULE:
      return "MODULE";
    case TaskListReferenceType.SUBMODULE:
      return "SUBMODULE";
    case TaskListReferenceType.SET:
      return "SET";
    case TaskListReferenceType.PROGRAM:
      return "PROGRAM";
    case TaskListReferenceType.PANEL_CODE:
      return "PANEL_CODE";
    case TaskListReferenceType.SUPPLIER:
      return "SUPPLIER";
    case TaskListReferenceType.MATERIAL_REQUEST:
      return "MATERIAL_REQUEST";
    case TaskListReferenceType.PROCESS_TYPE:
      return "PROCESS_TYPE";
    case TaskListReferenceType.MACHINE:
      return "MACHINE";
    case TaskListReferenceType.INVOICE_CUSTOMER:
      return "INVOICE_CUSTOMER";
    case TaskListReferenceType.INVOICE_VENDOR:
      return "INVOICE_VENDOR";
    case TaskListReferenceType.PAYMENT_CUSTOMER:
      return "PAYMENT_CUSTOMER";
    case TaskListReferenceType.PAYMENT_VENDOR:
      return "PAYMENT_VENDOR";
    case TaskListReferenceType.GENERAL_KASBON:
      return "GENERAL_KASBON";
    case TaskListReferenceType.DRIVER_KASBON:
      return "DRIVER_KASBON";
    case TaskListReferenceType.INSTALLER_KASBON:
      return "INSTALLER_KASBON";
    case TaskListReferenceType.ASSET:
      return "ASSET";
    case TaskListReferenceType.SERIAL_NUMBER_STRING:
      return "SERIAL_NUMBER_STRING";
    case TaskListReferenceType.SALES_PLAN:
      return "SALES_PLAN";
    case TaskListReferenceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TaskListDepartmentCategory {
  TASK_LIST_DEPARTMENT_CATEGORY_UNRECOGNISED = 0,
  SALES = 1,
  PPC = 2,
  ELEC = 3,
  MECH = 4,
  PURCH = 5,
  WH1 = 6,
  PROD = 7,
  QCQA = 8,
  WH2 = 9,
  LOGISTICS = 10,
  WH3 = 11,
  SERVICE1 = 12,
  SERVICE2 = 13,
  SERVICE3 = 14,
  WH4 = 15,
  FI = 16,
  PROJECT = 17,
  UNRECOGNIZED = -1,
}

export function taskListDepartmentCategoryFromJSON(object: any): TaskListDepartmentCategory {
  switch (object) {
    case 0:
    case "TASK_LIST_DEPARTMENT_CATEGORY_UNRECOGNISED":
      return TaskListDepartmentCategory.TASK_LIST_DEPARTMENT_CATEGORY_UNRECOGNISED;
    case 1:
    case "SALES":
      return TaskListDepartmentCategory.SALES;
    case 2:
    case "PPC":
      return TaskListDepartmentCategory.PPC;
    case 3:
    case "ELEC":
      return TaskListDepartmentCategory.ELEC;
    case 4:
    case "MECH":
      return TaskListDepartmentCategory.MECH;
    case 5:
    case "PURCH":
      return TaskListDepartmentCategory.PURCH;
    case 6:
    case "WH1":
      return TaskListDepartmentCategory.WH1;
    case 7:
    case "PROD":
      return TaskListDepartmentCategory.PROD;
    case 8:
    case "QCQA":
      return TaskListDepartmentCategory.QCQA;
    case 9:
    case "WH2":
      return TaskListDepartmentCategory.WH2;
    case 10:
    case "LOGISTICS":
      return TaskListDepartmentCategory.LOGISTICS;
    case 11:
    case "WH3":
      return TaskListDepartmentCategory.WH3;
    case 12:
    case "SERVICE1":
      return TaskListDepartmentCategory.SERVICE1;
    case 13:
    case "SERVICE2":
      return TaskListDepartmentCategory.SERVICE2;
    case 14:
    case "SERVICE3":
      return TaskListDepartmentCategory.SERVICE3;
    case 15:
    case "WH4":
      return TaskListDepartmentCategory.WH4;
    case 16:
    case "FI":
      return TaskListDepartmentCategory.FI;
    case 17:
    case "PROJECT":
      return TaskListDepartmentCategory.PROJECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TaskListDepartmentCategory.UNRECOGNIZED;
  }
}

export function taskListDepartmentCategoryToJSON(object: TaskListDepartmentCategory): string {
  switch (object) {
    case TaskListDepartmentCategory.TASK_LIST_DEPARTMENT_CATEGORY_UNRECOGNISED:
      return "TASK_LIST_DEPARTMENT_CATEGORY_UNRECOGNISED";
    case TaskListDepartmentCategory.SALES:
      return "SALES";
    case TaskListDepartmentCategory.PPC:
      return "PPC";
    case TaskListDepartmentCategory.ELEC:
      return "ELEC";
    case TaskListDepartmentCategory.MECH:
      return "MECH";
    case TaskListDepartmentCategory.PURCH:
      return "PURCH";
    case TaskListDepartmentCategory.WH1:
      return "WH1";
    case TaskListDepartmentCategory.PROD:
      return "PROD";
    case TaskListDepartmentCategory.QCQA:
      return "QCQA";
    case TaskListDepartmentCategory.WH2:
      return "WH2";
    case TaskListDepartmentCategory.LOGISTICS:
      return "LOGISTICS";
    case TaskListDepartmentCategory.WH3:
      return "WH3";
    case TaskListDepartmentCategory.SERVICE1:
      return "SERVICE1";
    case TaskListDepartmentCategory.SERVICE2:
      return "SERVICE2";
    case TaskListDepartmentCategory.SERVICE3:
      return "SERVICE3";
    case TaskListDepartmentCategory.WH4:
      return "WH4";
    case TaskListDepartmentCategory.FI:
      return "FI";
    case TaskListDepartmentCategory.PROJECT:
      return "PROJECT";
    case TaskListDepartmentCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProjectDocumentType {
  DOCTYPE_UNRECOGNISED = 0,
  TECH_DRAWING = 1,
  DOK_KELENGKAPAN = 2,
  UNRECOGNIZED = -1,
}

export function projectDocumentTypeFromJSON(object: any): ProjectDocumentType {
  switch (object) {
    case 0:
    case "DOCTYPE_UNRECOGNISED":
      return ProjectDocumentType.DOCTYPE_UNRECOGNISED;
    case 1:
    case "TECH_DRAWING":
      return ProjectDocumentType.TECH_DRAWING;
    case 2:
    case "DOK_KELENGKAPAN":
      return ProjectDocumentType.DOK_KELENGKAPAN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProjectDocumentType.UNRECOGNIZED;
  }
}

export function projectDocumentTypeToJSON(object: ProjectDocumentType): string {
  switch (object) {
    case ProjectDocumentType.DOCTYPE_UNRECOGNISED:
      return "DOCTYPE_UNRECOGNISED";
    case ProjectDocumentType.TECH_DRAWING:
      return "TECH_DRAWING";
    case ProjectDocumentType.DOK_KELENGKAPAN:
      return "DOK_KELENGKAPAN";
    case ProjectDocumentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AuthserverUserWhatsappSendMessageType {
  AuthserverUserWhatsappSendMessageType_UNRECOGNIZED = 0,
  MESSAGE = 1,
  TEMPLATE = 2,
  UNRECOGNIZED = -1,
}

export function authserverUserWhatsappSendMessageTypeFromJSON(object: any): AuthserverUserWhatsappSendMessageType {
  switch (object) {
    case 0:
    case "AuthserverUserWhatsappSendMessageType_UNRECOGNIZED":
      return AuthserverUserWhatsappSendMessageType.AuthserverUserWhatsappSendMessageType_UNRECOGNIZED;
    case 1:
    case "MESSAGE":
      return AuthserverUserWhatsappSendMessageType.MESSAGE;
    case 2:
    case "TEMPLATE":
      return AuthserverUserWhatsappSendMessageType.TEMPLATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AuthserverUserWhatsappSendMessageType.UNRECOGNIZED;
  }
}

export function authserverUserWhatsappSendMessageTypeToJSON(object: AuthserverUserWhatsappSendMessageType): string {
  switch (object) {
    case AuthserverUserWhatsappSendMessageType.AuthserverUserWhatsappSendMessageType_UNRECOGNIZED:
      return "AuthserverUserWhatsappSendMessageType_UNRECOGNIZED";
    case AuthserverUserWhatsappSendMessageType.MESSAGE:
      return "MESSAGE";
    case AuthserverUserWhatsappSendMessageType.TEMPLATE:
      return "TEMPLATE";
    case AuthserverUserWhatsappSendMessageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum KpiDeptCacheCategoryTimeEnum {
  CAT_TIME_UNRECOGNIZED = 0,
  CAT_TIME_PAST = 1,
  CAT_TIME_FUTURE = 2,
  UNRECOGNIZED = -1,
}

export function kpiDeptCacheCategoryTimeEnumFromJSON(object: any): KpiDeptCacheCategoryTimeEnum {
  switch (object) {
    case 0:
    case "CAT_TIME_UNRECOGNIZED":
      return KpiDeptCacheCategoryTimeEnum.CAT_TIME_UNRECOGNIZED;
    case 1:
    case "CAT_TIME_PAST":
      return KpiDeptCacheCategoryTimeEnum.CAT_TIME_PAST;
    case 2:
    case "CAT_TIME_FUTURE":
      return KpiDeptCacheCategoryTimeEnum.CAT_TIME_FUTURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KpiDeptCacheCategoryTimeEnum.UNRECOGNIZED;
  }
}

export function kpiDeptCacheCategoryTimeEnumToJSON(object: KpiDeptCacheCategoryTimeEnum): string {
  switch (object) {
    case KpiDeptCacheCategoryTimeEnum.CAT_TIME_UNRECOGNIZED:
      return "CAT_TIME_UNRECOGNIZED";
    case KpiDeptCacheCategoryTimeEnum.CAT_TIME_PAST:
      return "CAT_TIME_PAST";
    case KpiDeptCacheCategoryTimeEnum.CAT_TIME_FUTURE:
      return "CAT_TIME_FUTURE";
    case KpiDeptCacheCategoryTimeEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BomItemType {
  BOM_MECH = 0,
  BOM_ELEC = 1,
  BOM_MAT_INST = 2,
  UNRECOGNIZED = -1,
}

export function bomItemTypeFromJSON(object: any): BomItemType {
  switch (object) {
    case 0:
    case "BOM_MECH":
      return BomItemType.BOM_MECH;
    case 1:
    case "BOM_ELEC":
      return BomItemType.BOM_ELEC;
    case 2:
    case "BOM_MAT_INST":
      return BomItemType.BOM_MAT_INST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BomItemType.UNRECOGNIZED;
  }
}

export function bomItemTypeToJSON(object: BomItemType): string {
  switch (object) {
    case BomItemType.BOM_MECH:
      return "BOM_MECH";
    case BomItemType.BOM_ELEC:
      return "BOM_ELEC";
    case BomItemType.BOM_MAT_INST:
      return "BOM_MAT_INST";
    case BomItemType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum KpiDeptCacheCategoryRangeEnum {
  RANGE_UNRECOGNIZED = 0,
  RANGE_TODAY = 1,
  RANGE_LAST_DAY = 2,
  RANGE_LAST_WEEK = 3,
  RANGE_LAST_MONGH = 4,
  RANGE_LAST_QUARTER = 5,
  RANGE_NEXT_DAY = 6,
  RANGE_CURRENT_WEEK = 7,
  RANGE_NEXT_WEEK = 8,
  RANGE_CURRENT_MONTH = 9,
  RANGE_NEXT_MONTH = 10,
  RANGE_NEXT_MONTH_PLUS_ONE = 11,
  RANGE_EXPAND = 12,
  UNRECOGNIZED = -1,
}

export function kpiDeptCacheCategoryRangeEnumFromJSON(object: any): KpiDeptCacheCategoryRangeEnum {
  switch (object) {
    case 0:
    case "RANGE_UNRECOGNIZED":
      return KpiDeptCacheCategoryRangeEnum.RANGE_UNRECOGNIZED;
    case 1:
    case "RANGE_TODAY":
      return KpiDeptCacheCategoryRangeEnum.RANGE_TODAY;
    case 2:
    case "RANGE_LAST_DAY":
      return KpiDeptCacheCategoryRangeEnum.RANGE_LAST_DAY;
    case 3:
    case "RANGE_LAST_WEEK":
      return KpiDeptCacheCategoryRangeEnum.RANGE_LAST_WEEK;
    case 4:
    case "RANGE_LAST_MONGH":
      return KpiDeptCacheCategoryRangeEnum.RANGE_LAST_MONGH;
    case 5:
    case "RANGE_LAST_QUARTER":
      return KpiDeptCacheCategoryRangeEnum.RANGE_LAST_QUARTER;
    case 6:
    case "RANGE_NEXT_DAY":
      return KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_DAY;
    case 7:
    case "RANGE_CURRENT_WEEK":
      return KpiDeptCacheCategoryRangeEnum.RANGE_CURRENT_WEEK;
    case 8:
    case "RANGE_NEXT_WEEK":
      return KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_WEEK;
    case 9:
    case "RANGE_CURRENT_MONTH":
      return KpiDeptCacheCategoryRangeEnum.RANGE_CURRENT_MONTH;
    case 10:
    case "RANGE_NEXT_MONTH":
      return KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_MONTH;
    case 11:
    case "RANGE_NEXT_MONTH_PLUS_ONE":
      return KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_MONTH_PLUS_ONE;
    case 12:
    case "RANGE_EXPAND":
      return KpiDeptCacheCategoryRangeEnum.RANGE_EXPAND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KpiDeptCacheCategoryRangeEnum.UNRECOGNIZED;
  }
}

export function kpiDeptCacheCategoryRangeEnumToJSON(object: KpiDeptCacheCategoryRangeEnum): string {
  switch (object) {
    case KpiDeptCacheCategoryRangeEnum.RANGE_UNRECOGNIZED:
      return "RANGE_UNRECOGNIZED";
    case KpiDeptCacheCategoryRangeEnum.RANGE_TODAY:
      return "RANGE_TODAY";
    case KpiDeptCacheCategoryRangeEnum.RANGE_LAST_DAY:
      return "RANGE_LAST_DAY";
    case KpiDeptCacheCategoryRangeEnum.RANGE_LAST_WEEK:
      return "RANGE_LAST_WEEK";
    case KpiDeptCacheCategoryRangeEnum.RANGE_LAST_MONGH:
      return "RANGE_LAST_MONGH";
    case KpiDeptCacheCategoryRangeEnum.RANGE_LAST_QUARTER:
      return "RANGE_LAST_QUARTER";
    case KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_DAY:
      return "RANGE_NEXT_DAY";
    case KpiDeptCacheCategoryRangeEnum.RANGE_CURRENT_WEEK:
      return "RANGE_CURRENT_WEEK";
    case KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_WEEK:
      return "RANGE_NEXT_WEEK";
    case KpiDeptCacheCategoryRangeEnum.RANGE_CURRENT_MONTH:
      return "RANGE_CURRENT_MONTH";
    case KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_MONTH:
      return "RANGE_NEXT_MONTH";
    case KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_MONTH_PLUS_ONE:
      return "RANGE_NEXT_MONTH_PLUS_ONE";
    case KpiDeptCacheCategoryRangeEnum.RANGE_EXPAND:
      return "RANGE_EXPAND";
    case KpiDeptCacheCategoryRangeEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum KpiDeptCacheCategoryEnum {
  CATEG_UNRECOGNIZED = 0,
  CATEG_MANPOWER_ASSIST = 1,
  CATEG_ALPHA = 2,
  CATEG_AVAIL_MP = 3,
  CATEG_WO_HISTORY = 4,
  CATEG_CAP_ACTUAL = 5,
  CATEG_OVERDUE = 6,
  CATEG_QUALITY_PROB = 7,
  CATEG_COST_PROB = 8,
  CATEG_ATTENDANCE_NOTE = 9,
  CATEG_CAP_PLAN = 10,
  CATEG_JOB_OUTS = 11,
  CATEG_WO_OUTS = 12,
  CATEG_INT_ISSUES = 13,
  CATEG_EXT_ISSUES = 14,
  CATEG_LAST_PLAN = 15,
  CATEG_JOB_TODAY = 16,
  CATEG_JOB_TOMORROW = 17,
  CATEG_READY_TO_ASSIGN = 18,
  UNRECOGNIZED = -1,
}

export function kpiDeptCacheCategoryEnumFromJSON(object: any): KpiDeptCacheCategoryEnum {
  switch (object) {
    case 0:
    case "CATEG_UNRECOGNIZED":
      return KpiDeptCacheCategoryEnum.CATEG_UNRECOGNIZED;
    case 1:
    case "CATEG_MANPOWER_ASSIST":
      return KpiDeptCacheCategoryEnum.CATEG_MANPOWER_ASSIST;
    case 2:
    case "CATEG_ALPHA":
      return KpiDeptCacheCategoryEnum.CATEG_ALPHA;
    case 3:
    case "CATEG_AVAIL_MP":
      return KpiDeptCacheCategoryEnum.CATEG_AVAIL_MP;
    case 4:
    case "CATEG_WO_HISTORY":
      return KpiDeptCacheCategoryEnum.CATEG_WO_HISTORY;
    case 5:
    case "CATEG_CAP_ACTUAL":
      return KpiDeptCacheCategoryEnum.CATEG_CAP_ACTUAL;
    case 6:
    case "CATEG_OVERDUE":
      return KpiDeptCacheCategoryEnum.CATEG_OVERDUE;
    case 7:
    case "CATEG_QUALITY_PROB":
      return KpiDeptCacheCategoryEnum.CATEG_QUALITY_PROB;
    case 8:
    case "CATEG_COST_PROB":
      return KpiDeptCacheCategoryEnum.CATEG_COST_PROB;
    case 9:
    case "CATEG_ATTENDANCE_NOTE":
      return KpiDeptCacheCategoryEnum.CATEG_ATTENDANCE_NOTE;
    case 10:
    case "CATEG_CAP_PLAN":
      return KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN;
    case 11:
    case "CATEG_JOB_OUTS":
      return KpiDeptCacheCategoryEnum.CATEG_JOB_OUTS;
    case 12:
    case "CATEG_WO_OUTS":
      return KpiDeptCacheCategoryEnum.CATEG_WO_OUTS;
    case 13:
    case "CATEG_INT_ISSUES":
      return KpiDeptCacheCategoryEnum.CATEG_INT_ISSUES;
    case 14:
    case "CATEG_EXT_ISSUES":
      return KpiDeptCacheCategoryEnum.CATEG_EXT_ISSUES;
    case 15:
    case "CATEG_LAST_PLAN":
      return KpiDeptCacheCategoryEnum.CATEG_LAST_PLAN;
    case 16:
    case "CATEG_JOB_TODAY":
      return KpiDeptCacheCategoryEnum.CATEG_JOB_TODAY;
    case 17:
    case "CATEG_JOB_TOMORROW":
      return KpiDeptCacheCategoryEnum.CATEG_JOB_TOMORROW;
    case 18:
    case "CATEG_READY_TO_ASSIGN":
      return KpiDeptCacheCategoryEnum.CATEG_READY_TO_ASSIGN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KpiDeptCacheCategoryEnum.UNRECOGNIZED;
  }
}

export function kpiDeptCacheCategoryEnumToJSON(object: KpiDeptCacheCategoryEnum): string {
  switch (object) {
    case KpiDeptCacheCategoryEnum.CATEG_UNRECOGNIZED:
      return "CATEG_UNRECOGNIZED";
    case KpiDeptCacheCategoryEnum.CATEG_MANPOWER_ASSIST:
      return "CATEG_MANPOWER_ASSIST";
    case KpiDeptCacheCategoryEnum.CATEG_ALPHA:
      return "CATEG_ALPHA";
    case KpiDeptCacheCategoryEnum.CATEG_AVAIL_MP:
      return "CATEG_AVAIL_MP";
    case KpiDeptCacheCategoryEnum.CATEG_WO_HISTORY:
      return "CATEG_WO_HISTORY";
    case KpiDeptCacheCategoryEnum.CATEG_CAP_ACTUAL:
      return "CATEG_CAP_ACTUAL";
    case KpiDeptCacheCategoryEnum.CATEG_OVERDUE:
      return "CATEG_OVERDUE";
    case KpiDeptCacheCategoryEnum.CATEG_QUALITY_PROB:
      return "CATEG_QUALITY_PROB";
    case KpiDeptCacheCategoryEnum.CATEG_COST_PROB:
      return "CATEG_COST_PROB";
    case KpiDeptCacheCategoryEnum.CATEG_ATTENDANCE_NOTE:
      return "CATEG_ATTENDANCE_NOTE";
    case KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN:
      return "CATEG_CAP_PLAN";
    case KpiDeptCacheCategoryEnum.CATEG_JOB_OUTS:
      return "CATEG_JOB_OUTS";
    case KpiDeptCacheCategoryEnum.CATEG_WO_OUTS:
      return "CATEG_WO_OUTS";
    case KpiDeptCacheCategoryEnum.CATEG_INT_ISSUES:
      return "CATEG_INT_ISSUES";
    case KpiDeptCacheCategoryEnum.CATEG_EXT_ISSUES:
      return "CATEG_EXT_ISSUES";
    case KpiDeptCacheCategoryEnum.CATEG_LAST_PLAN:
      return "CATEG_LAST_PLAN";
    case KpiDeptCacheCategoryEnum.CATEG_JOB_TODAY:
      return "CATEG_JOB_TODAY";
    case KpiDeptCacheCategoryEnum.CATEG_JOB_TOMORROW:
      return "CATEG_JOB_TOMORROW";
    case KpiDeptCacheCategoryEnum.CATEG_READY_TO_ASSIGN:
      return "CATEG_READY_TO_ASSIGN";
    case KpiDeptCacheCategoryEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MeetingTaskProblemCatalogStatus {
  PROBLEM_STATUS_UNRECOGNIZED = 0,
  PROBLEM_STATUS_OPEN = 1,
  PROBLEM_STATUS_CLOSED = 2,
  UNRECOGNIZED = -1,
}

export function meetingTaskProblemCatalogStatusFromJSON(object: any): MeetingTaskProblemCatalogStatus {
  switch (object) {
    case 0:
    case "PROBLEM_STATUS_UNRECOGNIZED":
      return MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_UNRECOGNIZED;
    case 1:
    case "PROBLEM_STATUS_OPEN":
      return MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_OPEN;
    case 2:
    case "PROBLEM_STATUS_CLOSED":
      return MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_CLOSED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MeetingTaskProblemCatalogStatus.UNRECOGNIZED;
  }
}

export function meetingTaskProblemCatalogStatusToJSON(object: MeetingTaskProblemCatalogStatus): string {
  switch (object) {
    case MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_UNRECOGNIZED:
      return "PROBLEM_STATUS_UNRECOGNIZED";
    case MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_OPEN:
      return "PROBLEM_STATUS_OPEN";
    case MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_CLOSED:
      return "PROBLEM_STATUS_CLOSED";
    case MeetingTaskProblemCatalogStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MeetingProblemType {
  PROBLEM_TYPE_UNRECOGNISED = 0,
  PROBLEM_TYPE_INTERNAL = 1,
  PROBLEM_TYPE_EXTERNAL = 2,
  UNRECOGNIZED = -1,
}

export function meetingProblemTypeFromJSON(object: any): MeetingProblemType {
  switch (object) {
    case 0:
    case "PROBLEM_TYPE_UNRECOGNISED":
      return MeetingProblemType.PROBLEM_TYPE_UNRECOGNISED;
    case 1:
    case "PROBLEM_TYPE_INTERNAL":
      return MeetingProblemType.PROBLEM_TYPE_INTERNAL;
    case 2:
    case "PROBLEM_TYPE_EXTERNAL":
      return MeetingProblemType.PROBLEM_TYPE_EXTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MeetingProblemType.UNRECOGNIZED;
  }
}

export function meetingProblemTypeToJSON(object: MeetingProblemType): string {
  switch (object) {
    case MeetingProblemType.PROBLEM_TYPE_UNRECOGNISED:
      return "PROBLEM_TYPE_UNRECOGNISED";
    case MeetingProblemType.PROBLEM_TYPE_INTERNAL:
      return "PROBLEM_TYPE_INTERNAL";
    case MeetingProblemType.PROBLEM_TYPE_EXTERNAL:
      return "PROBLEM_TYPE_EXTERNAL";
    case MeetingProblemType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ATPSubDocumentType {
  ATP_SUB_DOCUMENT_UNRECOGNISED = 0,
  ATP_SUB_DOCUMENT_ACPDB = 1,
  ATP_SUB_DOCUMENT_RECTIFIER = 2,
  ATP_SUB_DOCUMENT_BATTERY = 3,
  UNRECOGNIZED = -1,
}

export function aTPSubDocumentTypeFromJSON(object: any): ATPSubDocumentType {
  switch (object) {
    case 0:
    case "ATP_SUB_DOCUMENT_UNRECOGNISED":
      return ATPSubDocumentType.ATP_SUB_DOCUMENT_UNRECOGNISED;
    case 1:
    case "ATP_SUB_DOCUMENT_ACPDB":
      return ATPSubDocumentType.ATP_SUB_DOCUMENT_ACPDB;
    case 2:
    case "ATP_SUB_DOCUMENT_RECTIFIER":
      return ATPSubDocumentType.ATP_SUB_DOCUMENT_RECTIFIER;
    case 3:
    case "ATP_SUB_DOCUMENT_BATTERY":
      return ATPSubDocumentType.ATP_SUB_DOCUMENT_BATTERY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ATPSubDocumentType.UNRECOGNIZED;
  }
}

export function aTPSubDocumentTypeToJSON(object: ATPSubDocumentType): string {
  switch (object) {
    case ATPSubDocumentType.ATP_SUB_DOCUMENT_UNRECOGNISED:
      return "ATP_SUB_DOCUMENT_UNRECOGNISED";
    case ATPSubDocumentType.ATP_SUB_DOCUMENT_ACPDB:
      return "ATP_SUB_DOCUMENT_ACPDB";
    case ATPSubDocumentType.ATP_SUB_DOCUMENT_RECTIFIER:
      return "ATP_SUB_DOCUMENT_RECTIFIER";
    case ATPSubDocumentType.ATP_SUB_DOCUMENT_BATTERY:
      return "ATP_SUB_DOCUMENT_BATTERY";
    case ATPSubDocumentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MeetingATPDocumentFormPhotoType {
  ATP_PHOTO_UNRECOGNIZED = 0,
  ATP_PHOTO_BATTERY_BANK = 1,
  ATP_PHOTO_TITLED = 2,
  UNRECOGNIZED = -1,
}

export function meetingATPDocumentFormPhotoTypeFromJSON(object: any): MeetingATPDocumentFormPhotoType {
  switch (object) {
    case 0:
    case "ATP_PHOTO_UNRECOGNIZED":
      return MeetingATPDocumentFormPhotoType.ATP_PHOTO_UNRECOGNIZED;
    case 1:
    case "ATP_PHOTO_BATTERY_BANK":
      return MeetingATPDocumentFormPhotoType.ATP_PHOTO_BATTERY_BANK;
    case 2:
    case "ATP_PHOTO_TITLED":
      return MeetingATPDocumentFormPhotoType.ATP_PHOTO_TITLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MeetingATPDocumentFormPhotoType.UNRECOGNIZED;
  }
}

export function meetingATPDocumentFormPhotoTypeToJSON(object: MeetingATPDocumentFormPhotoType): string {
  switch (object) {
    case MeetingATPDocumentFormPhotoType.ATP_PHOTO_UNRECOGNIZED:
      return "ATP_PHOTO_UNRECOGNIZED";
    case MeetingATPDocumentFormPhotoType.ATP_PHOTO_BATTERY_BANK:
      return "ATP_PHOTO_BATTERY_BANK";
    case MeetingATPDocumentFormPhotoType.ATP_PHOTO_TITLED:
      return "ATP_PHOTO_TITLED";
    case MeetingATPDocumentFormPhotoType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MeetingBASTDocumentGRReportValueType {
  GR_UNRECOGNISED = 0,
  GR_PENGAJUAN = 1,
  GR_SUDAH_ATP = 2,
  GR_SUDAH_INSTALL = 3,
  GR_BELUM_INSTALL = 4,
  GR_BELUM_DO = 5,
  UNRECOGNIZED = -1,
}

export function meetingBASTDocumentGRReportValueTypeFromJSON(object: any): MeetingBASTDocumentGRReportValueType {
  switch (object) {
    case 0:
    case "GR_UNRECOGNISED":
      return MeetingBASTDocumentGRReportValueType.GR_UNRECOGNISED;
    case 1:
    case "GR_PENGAJUAN":
      return MeetingBASTDocumentGRReportValueType.GR_PENGAJUAN;
    case 2:
    case "GR_SUDAH_ATP":
      return MeetingBASTDocumentGRReportValueType.GR_SUDAH_ATP;
    case 3:
    case "GR_SUDAH_INSTALL":
      return MeetingBASTDocumentGRReportValueType.GR_SUDAH_INSTALL;
    case 4:
    case "GR_BELUM_INSTALL":
      return MeetingBASTDocumentGRReportValueType.GR_BELUM_INSTALL;
    case 5:
    case "GR_BELUM_DO":
      return MeetingBASTDocumentGRReportValueType.GR_BELUM_DO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MeetingBASTDocumentGRReportValueType.UNRECOGNIZED;
  }
}

export function meetingBASTDocumentGRReportValueTypeToJSON(object: MeetingBASTDocumentGRReportValueType): string {
  switch (object) {
    case MeetingBASTDocumentGRReportValueType.GR_UNRECOGNISED:
      return "GR_UNRECOGNISED";
    case MeetingBASTDocumentGRReportValueType.GR_PENGAJUAN:
      return "GR_PENGAJUAN";
    case MeetingBASTDocumentGRReportValueType.GR_SUDAH_ATP:
      return "GR_SUDAH_ATP";
    case MeetingBASTDocumentGRReportValueType.GR_SUDAH_INSTALL:
      return "GR_SUDAH_INSTALL";
    case MeetingBASTDocumentGRReportValueType.GR_BELUM_INSTALL:
      return "GR_BELUM_INSTALL";
    case MeetingBASTDocumentGRReportValueType.GR_BELUM_DO:
      return "GR_BELUM_DO";
    case MeetingBASTDocumentGRReportValueType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PPIC app */
export enum WorkOrderType {
  WO_TYPE_UNRECOGNISED = 0,
  WO_TYPE_JOB = 1,
  WO_TYPE_GENERIC = 2,
  WO_TYPE_MACHINE = 3,
  WO_TYPE_DETAILS = 4,
  WO_TYPE_JOB_OVERVIEW = 5,
  WO_TYPE_CALENDAR = 6,
  WO_TYPE_DEPT_TEMPLATE = 7,
  WO_TYPE_JOURNEY_MAP = 8,
  WO_TYPE_MEETING = 9,
  WO_TYPE_SALES_PLAN = 10,
  WO_TYPE_QUAD_SCREEN = 11,
  WO_TYPE_SALES_FUNNEL = 12,
  WO_TYPE_PRE_SALES = 13,
  WO_TYPE_SALES_TARGET_VS_ACTUAL = 14,
  WO_TYPE_JDP = 15,
  WO_TYPE_PROBLEM_DETAILS = 16,
  WO_TYPE_GLOBAL_ATTENDANCE = 17,
  COMPANY_SALES = 18,
  COMPANY_SALES_2 = 19,
  WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK = 20,
  WO_TYPE_READY_TO_ASSIGN = 21,
  UNRECOGNIZED = -1,
}

export function workOrderTypeFromJSON(object: any): WorkOrderType {
  switch (object) {
    case 0:
    case "WO_TYPE_UNRECOGNISED":
      return WorkOrderType.WO_TYPE_UNRECOGNISED;
    case 1:
    case "WO_TYPE_JOB":
      return WorkOrderType.WO_TYPE_JOB;
    case 2:
    case "WO_TYPE_GENERIC":
      return WorkOrderType.WO_TYPE_GENERIC;
    case 3:
    case "WO_TYPE_MACHINE":
      return WorkOrderType.WO_TYPE_MACHINE;
    case 4:
    case "WO_TYPE_DETAILS":
      return WorkOrderType.WO_TYPE_DETAILS;
    case 5:
    case "WO_TYPE_JOB_OVERVIEW":
      return WorkOrderType.WO_TYPE_JOB_OVERVIEW;
    case 6:
    case "WO_TYPE_CALENDAR":
      return WorkOrderType.WO_TYPE_CALENDAR;
    case 7:
    case "WO_TYPE_DEPT_TEMPLATE":
      return WorkOrderType.WO_TYPE_DEPT_TEMPLATE;
    case 8:
    case "WO_TYPE_JOURNEY_MAP":
      return WorkOrderType.WO_TYPE_JOURNEY_MAP;
    case 9:
    case "WO_TYPE_MEETING":
      return WorkOrderType.WO_TYPE_MEETING;
    case 10:
    case "WO_TYPE_SALES_PLAN":
      return WorkOrderType.WO_TYPE_SALES_PLAN;
    case 11:
    case "WO_TYPE_QUAD_SCREEN":
      return WorkOrderType.WO_TYPE_QUAD_SCREEN;
    case 12:
    case "WO_TYPE_SALES_FUNNEL":
      return WorkOrderType.WO_TYPE_SALES_FUNNEL;
    case 13:
    case "WO_TYPE_PRE_SALES":
      return WorkOrderType.WO_TYPE_PRE_SALES;
    case 14:
    case "WO_TYPE_SALES_TARGET_VS_ACTUAL":
      return WorkOrderType.WO_TYPE_SALES_TARGET_VS_ACTUAL;
    case 15:
    case "WO_TYPE_JDP":
      return WorkOrderType.WO_TYPE_JDP;
    case 16:
    case "WO_TYPE_PROBLEM_DETAILS":
      return WorkOrderType.WO_TYPE_PROBLEM_DETAILS;
    case 17:
    case "WO_TYPE_GLOBAL_ATTENDANCE":
      return WorkOrderType.WO_TYPE_GLOBAL_ATTENDANCE;
    case 18:
    case "COMPANY_SALES":
      return WorkOrderType.COMPANY_SALES;
    case 19:
    case "COMPANY_SALES_2":
      return WorkOrderType.COMPANY_SALES_2;
    case 20:
    case "WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK":
      return WorkOrderType.WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK;
    case 21:
    case "WO_TYPE_READY_TO_ASSIGN":
      return WorkOrderType.WO_TYPE_READY_TO_ASSIGN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WorkOrderType.UNRECOGNIZED;
  }
}

export function workOrderTypeToJSON(object: WorkOrderType): string {
  switch (object) {
    case WorkOrderType.WO_TYPE_UNRECOGNISED:
      return "WO_TYPE_UNRECOGNISED";
    case WorkOrderType.WO_TYPE_JOB:
      return "WO_TYPE_JOB";
    case WorkOrderType.WO_TYPE_GENERIC:
      return "WO_TYPE_GENERIC";
    case WorkOrderType.WO_TYPE_MACHINE:
      return "WO_TYPE_MACHINE";
    case WorkOrderType.WO_TYPE_DETAILS:
      return "WO_TYPE_DETAILS";
    case WorkOrderType.WO_TYPE_JOB_OVERVIEW:
      return "WO_TYPE_JOB_OVERVIEW";
    case WorkOrderType.WO_TYPE_CALENDAR:
      return "WO_TYPE_CALENDAR";
    case WorkOrderType.WO_TYPE_DEPT_TEMPLATE:
      return "WO_TYPE_DEPT_TEMPLATE";
    case WorkOrderType.WO_TYPE_JOURNEY_MAP:
      return "WO_TYPE_JOURNEY_MAP";
    case WorkOrderType.WO_TYPE_MEETING:
      return "WO_TYPE_MEETING";
    case WorkOrderType.WO_TYPE_SALES_PLAN:
      return "WO_TYPE_SALES_PLAN";
    case WorkOrderType.WO_TYPE_QUAD_SCREEN:
      return "WO_TYPE_QUAD_SCREEN";
    case WorkOrderType.WO_TYPE_SALES_FUNNEL:
      return "WO_TYPE_SALES_FUNNEL";
    case WorkOrderType.WO_TYPE_PRE_SALES:
      return "WO_TYPE_PRE_SALES";
    case WorkOrderType.WO_TYPE_SALES_TARGET_VS_ACTUAL:
      return "WO_TYPE_SALES_TARGET_VS_ACTUAL";
    case WorkOrderType.WO_TYPE_JDP:
      return "WO_TYPE_JDP";
    case WorkOrderType.WO_TYPE_PROBLEM_DETAILS:
      return "WO_TYPE_PROBLEM_DETAILS";
    case WorkOrderType.WO_TYPE_GLOBAL_ATTENDANCE:
      return "WO_TYPE_GLOBAL_ATTENDANCE";
    case WorkOrderType.COMPANY_SALES:
      return "COMPANY_SALES";
    case WorkOrderType.COMPANY_SALES_2:
      return "COMPANY_SALES_2";
    case WorkOrderType.WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK:
      return "WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK";
    case WorkOrderType.WO_TYPE_READY_TO_ASSIGN:
      return "WO_TYPE_READY_TO_ASSIGN";
    case WorkOrderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MeetingOvertimeApprovalPicType {
  APPR_UNDEFINED = 0,
  APPR_SPV = 1,
  APPR_MANAGER = 2,
  APPR_HRD = 3,
  UNRECOGNIZED = -1,
}

export function meetingOvertimeApprovalPicTypeFromJSON(object: any): MeetingOvertimeApprovalPicType {
  switch (object) {
    case 0:
    case "APPR_UNDEFINED":
      return MeetingOvertimeApprovalPicType.APPR_UNDEFINED;
    case 1:
    case "APPR_SPV":
      return MeetingOvertimeApprovalPicType.APPR_SPV;
    case 2:
    case "APPR_MANAGER":
      return MeetingOvertimeApprovalPicType.APPR_MANAGER;
    case 3:
    case "APPR_HRD":
      return MeetingOvertimeApprovalPicType.APPR_HRD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MeetingOvertimeApprovalPicType.UNRECOGNIZED;
  }
}

export function meetingOvertimeApprovalPicTypeToJSON(object: MeetingOvertimeApprovalPicType): string {
  switch (object) {
    case MeetingOvertimeApprovalPicType.APPR_UNDEFINED:
      return "APPR_UNDEFINED";
    case MeetingOvertimeApprovalPicType.APPR_SPV:
      return "APPR_SPV";
    case MeetingOvertimeApprovalPicType.APPR_MANAGER:
      return "APPR_MANAGER";
    case MeetingOvertimeApprovalPicType.APPR_HRD:
      return "APPR_HRD";
    case MeetingOvertimeApprovalPicType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HandoverQAStatus {
  QA_UNRECOGNIZED = 0,
  QA_PASSED = 1,
  QA_NOT_PASSED = 2,
  QA_OUTSTANDING = 3,
  UNRECOGNIZED = -1,
}

export function handoverQAStatusFromJSON(object: any): HandoverQAStatus {
  switch (object) {
    case 0:
    case "QA_UNRECOGNIZED":
      return HandoverQAStatus.QA_UNRECOGNIZED;
    case 1:
    case "QA_PASSED":
      return HandoverQAStatus.QA_PASSED;
    case 2:
    case "QA_NOT_PASSED":
      return HandoverQAStatus.QA_NOT_PASSED;
    case 3:
    case "QA_OUTSTANDING":
      return HandoverQAStatus.QA_OUTSTANDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HandoverQAStatus.UNRECOGNIZED;
  }
}

export function handoverQAStatusToJSON(object: HandoverQAStatus): string {
  switch (object) {
    case HandoverQAStatus.QA_UNRECOGNIZED:
      return "QA_UNRECOGNIZED";
    case HandoverQAStatus.QA_PASSED:
      return "QA_PASSED";
    case HandoverQAStatus.QA_NOT_PASSED:
      return "QA_NOT_PASSED";
    case HandoverQAStatus.QA_OUTSTANDING:
      return "QA_OUTSTANDING";
    case HandoverQAStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PpicHandoverType {
  HANDOVER_TYPE_UNRECOGNISED = 0,
  FINISHED_GOODS = 1,
  MR_LEFTOVERS = 2,
  MAT_INSTALLATION = 3,
  UNRECOGNIZED = -1,
}

export function ppicHandoverTypeFromJSON(object: any): PpicHandoverType {
  switch (object) {
    case 0:
    case "HANDOVER_TYPE_UNRECOGNISED":
      return PpicHandoverType.HANDOVER_TYPE_UNRECOGNISED;
    case 1:
    case "FINISHED_GOODS":
      return PpicHandoverType.FINISHED_GOODS;
    case 2:
    case "MR_LEFTOVERS":
      return PpicHandoverType.MR_LEFTOVERS;
    case 3:
    case "MAT_INSTALLATION":
      return PpicHandoverType.MAT_INSTALLATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicHandoverType.UNRECOGNIZED;
  }
}

export function ppicHandoverTypeToJSON(object: PpicHandoverType): string {
  switch (object) {
    case PpicHandoverType.HANDOVER_TYPE_UNRECOGNISED:
      return "HANDOVER_TYPE_UNRECOGNISED";
    case PpicHandoverType.FINISHED_GOODS:
      return "FINISHED_GOODS";
    case PpicHandoverType.MR_LEFTOVERS:
      return "MR_LEFTOVERS";
    case PpicHandoverType.MAT_INSTALLATION:
      return "MAT_INSTALLATION";
    case PpicHandoverType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PpicIntegrationModuleType {
  TREE_UNAUTHORIZED = 0,
  TREE_WIRING = 1,
  TREE_ASSY = 2,
  UNRECOGNIZED = -1,
}

export function ppicIntegrationModuleTypeFromJSON(object: any): PpicIntegrationModuleType {
  switch (object) {
    case 0:
    case "TREE_UNAUTHORIZED":
      return PpicIntegrationModuleType.TREE_UNAUTHORIZED;
    case 1:
    case "TREE_WIRING":
      return PpicIntegrationModuleType.TREE_WIRING;
    case 2:
    case "TREE_ASSY":
      return PpicIntegrationModuleType.TREE_ASSY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicIntegrationModuleType.UNRECOGNIZED;
  }
}

export function ppicIntegrationModuleTypeToJSON(object: PpicIntegrationModuleType): string {
  switch (object) {
    case PpicIntegrationModuleType.TREE_UNAUTHORIZED:
      return "TREE_UNAUTHORIZED";
    case PpicIntegrationModuleType.TREE_WIRING:
      return "TREE_WIRING";
    case PpicIntegrationModuleType.TREE_ASSY:
      return "TREE_ASSY";
    case PpicIntegrationModuleType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum WarehouseItemCategoryPartNum {
  CPN_SERVICE_UNCATEGORIZED = 0,
  CPN_MATERIAL = 1,
  CPN_FINISHED_GOODS = 2,
  CPN_CONSUMABLE = 3,
  UNRECOGNIZED = -1,
}

export function warehouseItemCategoryPartNumFromJSON(object: any): WarehouseItemCategoryPartNum {
  switch (object) {
    case 0:
    case "CPN_SERVICE_UNCATEGORIZED":
      return WarehouseItemCategoryPartNum.CPN_SERVICE_UNCATEGORIZED;
    case 1:
    case "CPN_MATERIAL":
      return WarehouseItemCategoryPartNum.CPN_MATERIAL;
    case 2:
    case "CPN_FINISHED_GOODS":
      return WarehouseItemCategoryPartNum.CPN_FINISHED_GOODS;
    case 3:
    case "CPN_CONSUMABLE":
      return WarehouseItemCategoryPartNum.CPN_CONSUMABLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WarehouseItemCategoryPartNum.UNRECOGNIZED;
  }
}

export function warehouseItemCategoryPartNumToJSON(object: WarehouseItemCategoryPartNum): string {
  switch (object) {
    case WarehouseItemCategoryPartNum.CPN_SERVICE_UNCATEGORIZED:
      return "CPN_SERVICE_UNCATEGORIZED";
    case WarehouseItemCategoryPartNum.CPN_MATERIAL:
      return "CPN_MATERIAL";
    case WarehouseItemCategoryPartNum.CPN_FINISHED_GOODS:
      return "CPN_FINISHED_GOODS";
    case WarehouseItemCategoryPartNum.CPN_CONSUMABLE:
      return "CPN_CONSUMABLE";
    case WarehouseItemCategoryPartNum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TaskListDeliveryPoType {
  DELIVERY_UNRECOGNIZED = 0,
  DELIVERY_PO = 1,
  DELIVERY_BPO = 2,
  UNRECOGNIZED = -1,
}

export function taskListDeliveryPoTypeFromJSON(object: any): TaskListDeliveryPoType {
  switch (object) {
    case 0:
    case "DELIVERY_UNRECOGNIZED":
      return TaskListDeliveryPoType.DELIVERY_UNRECOGNIZED;
    case 1:
    case "DELIVERY_PO":
      return TaskListDeliveryPoType.DELIVERY_PO;
    case 2:
    case "DELIVERY_BPO":
      return TaskListDeliveryPoType.DELIVERY_BPO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TaskListDeliveryPoType.UNRECOGNIZED;
  }
}

export function taskListDeliveryPoTypeToJSON(object: TaskListDeliveryPoType): string {
  switch (object) {
    case TaskListDeliveryPoType.DELIVERY_UNRECOGNIZED:
      return "DELIVERY_UNRECOGNIZED";
    case TaskListDeliveryPoType.DELIVERY_PO:
      return "DELIVERY_PO";
    case TaskListDeliveryPoType.DELIVERY_BPO:
      return "DELIVERY_BPO";
    case TaskListDeliveryPoType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Authserver */
export interface AuthserverGoUserLogin {
  username?: string | undefined;
  password?: string | undefined;
}

export interface AuthserverGoTokenReturn {
  token?: string | undefined;
  error?: boolean | undefined;
  errorMessage?: string | undefined;
}

export interface AuthserverJwt {
  token?: string | undefined;
}

export interface AuthserverEmpty {
}

export interface MasterEmpty {
}

export interface AuthserverJwtReq {
  authserverJwt?: AuthserverJwt | undefined;
}

export interface MasterJavaBaseModel {
  id?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  hidden?: boolean | undefined;
  extCreatedById?: string | undefined;
  ordering?: string | undefined;
  uuid?: string | undefined;
  changeable?: boolean | undefined;
  unchangeable?: boolean | undefined;
}

export interface MasterJavaBaseModelWrapper {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface AuthserverGormModel {
  id?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  deletedAt?: string | undefined;
}

export interface AuthserverBaseModel {
  uuid?: string | undefined;
  ordering?: string | undefined;
  hidden?: boolean | undefined;
  extCreatedById?: string | undefined;
  changeable?: boolean | undefined;
  unchangeable?: boolean | undefined;
}

export interface AuthserverDepartment {
  baseModel?: AuthserverBaseModel | undefined;
  gormModel?: AuthserverGormModel | undefined;
  name?: string | undefined;
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface AuthserverDepartmentGroup {
  baseModel?: AuthserverBaseModel | undefined;
  gormModel?: AuthserverGormModel | undefined;
  name?: string | undefined;
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  departments: AuthserverDepartment[];
}

export interface AuthserverDepartmentGroups {
  groups: AuthserverDepartmentGroup[];
}

export interface AuthserverDivisionGrouping {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  code?: string | undefined;
}

export interface AuthserverDivisionGroupings {
  groupings: AuthserverDivisionGrouping[];
}

export interface AuthserverUser {
  baseModel?: AuthserverBaseModel | undefined;
  gormModel?: AuthserverGormModel | undefined;
  address?: string | undefined;
  countryCode?: string | undefined;
  email?: string | undefined;
  name?: string | undefined;
  password?: string | undefined;
  phone?: string | undefined;
  username?: string | undefined;
  departmentId?: string | undefined;
  department?: AuthserverDepartment | undefined;
  fcmToken?: string | undefined;
  addressLong?: string | undefined;
  iosUdid?: string | undefined;
  iosUser?: boolean | undefined;
  reportToId?: string | undefined;
  reportTo?: AuthserverUser | undefined;
  deviceUniqueId?: string | undefined;
  isHead?: boolean | undefined;
  vaccine1?: string | undefined;
  vaccine1Done?: boolean | undefined;
  vaccine2?: string | undefined;
  vaccine2Done?: boolean | undefined;
  companyId?: string | undefined;
  bankAccountNumber?: string | undefined;
  bankId?: string | undefined;
  deviceUniqueIdApproval?: string | undefined;
  hrId?: string | undefined;
  roles: AuthserverUserRole[];
  departmentName?: string | undefined;
  authorizationLevel?: AuthserverUser_AuthorizationLevel | undefined;
}

export enum AuthserverUser_AuthorizationLevel {
  UNRECOGNISED = 0,
  DIRECTOR = 1,
  MANAGER = 2,
  SUPERVISOR = 3,
  TEAM_LEAD = 4,
  OPERATOR = 5,
  STAFF = 6,
  UNRECOGNIZED = -1,
}

export function authserverUser_AuthorizationLevelFromJSON(object: any): AuthserverUser_AuthorizationLevel {
  switch (object) {
    case 0:
    case "UNRECOGNISED":
      return AuthserverUser_AuthorizationLevel.UNRECOGNISED;
    case 1:
    case "DIRECTOR":
      return AuthserverUser_AuthorizationLevel.DIRECTOR;
    case 2:
    case "MANAGER":
      return AuthserverUser_AuthorizationLevel.MANAGER;
    case 3:
    case "SUPERVISOR":
      return AuthserverUser_AuthorizationLevel.SUPERVISOR;
    case 4:
    case "TEAM_LEAD":
      return AuthserverUser_AuthorizationLevel.TEAM_LEAD;
    case 5:
    case "OPERATOR":
      return AuthserverUser_AuthorizationLevel.OPERATOR;
    case 6:
    case "STAFF":
      return AuthserverUser_AuthorizationLevel.STAFF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AuthserverUser_AuthorizationLevel.UNRECOGNIZED;
  }
}

export function authserverUser_AuthorizationLevelToJSON(object: AuthserverUser_AuthorizationLevel): string {
  switch (object) {
    case AuthserverUser_AuthorizationLevel.UNRECOGNISED:
      return "UNRECOGNISED";
    case AuthserverUser_AuthorizationLevel.DIRECTOR:
      return "DIRECTOR";
    case AuthserverUser_AuthorizationLevel.MANAGER:
      return "MANAGER";
    case AuthserverUser_AuthorizationLevel.SUPERVISOR:
      return "SUPERVISOR";
    case AuthserverUser_AuthorizationLevel.TEAM_LEAD:
      return "TEAM_LEAD";
    case AuthserverUser_AuthorizationLevel.OPERATOR:
      return "OPERATOR";
    case AuthserverUser_AuthorizationLevel.STAFF:
      return "STAFF";
    case AuthserverUser_AuthorizationLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AuthserverUserWhatsappSendMessage {
  userIds: string[];
  message?: string | undefined;
  type?: AuthserverUserWhatsappSendMessageType | undefined;
  templateName?: string | undefined;
}

export interface AuthserverRole {
  baseModel?: AuthserverBaseModel | undefined;
  gormModel?: AuthserverGormModel | undefined;
  name?: string | undefined;
}

export interface AuthserverUserRole {
  baseModel?: AuthserverBaseModel | undefined;
  gormModel?: AuthserverGormModel | undefined;
  userId?: string | undefined;
  user?: AuthserverUser | undefined;
  roleId?: string | undefined;
  role?: AuthserverRole | undefined;
}

export interface AuthserverUsers {
  users: AuthserverUser[];
}

export interface AuthserverDepartments {
  departments: AuthserverDepartment[];
}

export interface AuthserverRoles {
  roles: AuthserverRole[];
}

export interface UpdateFcmTokenBody {
  fcmToken?: string | undefined;
  jwt?: string | undefined;
}

export interface GetUserPostBody {
  jwt?: string | undefined;
}

/** Meeting */
export interface MeetingKpi {
  param?: MeetingKpiParam | undefined;
  users: MeetingKpiUser[];
  problemCatalogs: MeetingKpiUserWorkOrderProblem[];
  deptTotalWos?: number | undefined;
  deptTotalWosArray: MeetingKpiDeptWos[];
}

export interface MeetingKpiDeptWos {
  deptId?: string | undefined;
  totalWorkOrders?: number | undefined;
  totalWorkOrdersDone?: number | undefined;
  totalWorkOrdersNoProblem?: number | undefined;
  totalWorkOrdersOverdue?: number | undefined;
  totalWorkOrdersProblemCost?: number | undefined;
  totalWorkOrdersProblemQuality?: number | undefined;
}

export interface MeetingLastPlanDate {
  extDepartmentId?: string | undefined;
  date?: string | undefined;
  earliestDate?: string | undefined;
  extUserId?: string | undefined;
}

export interface MeetingLastPlanDates {
  dates: MeetingLastPlanDate[];
}

export interface MeetingKpiParam {
  from?: string | undefined;
  to?: string | undefined;
  daysCount?: number | undefined;
  lateReferenceHour?: number | undefined;
  lateReferenceMinute?: number | undefined;
  extUserId?: string | undefined;
}

export interface MeetingKpiUser {
  user?: AuthserverUser | undefined;
  workOrder?: MeetingKpiUserWorkOrder | undefined;
  attendance?: MeetingKpiUserAttendance | undefined;
}

export interface MeetingKpiUserWorkOrder {
  totalWorkOrders?: number | undefined;
  completed?: number | undefined;
  completedOnTime?: number | undefined;
  averageDailyWorkingHours?: number | undefined;
  workingMins?: number | undefined;
  workingHours?: number | undefined;
  integrationModuleHours?: number | undefined;
  programTimeHours?: number | undefined;
  rcemTimeHours?: number | undefined;
  durationHours?: number | undefined;
  panelCodeDepartmentTemplateItemTime?: number | undefined;
  numberOfTasksWithProblem?: number | undefined;
  averageDailyWorkingHoursDone?: number | undefined;
}

export interface MeetingKpiUserWorkOrderProblem {
  identifier?: string | undefined;
  numberOfProblems?: number | undefined;
}

export interface MeetingKpiUserAttendance {
  late?: number | undefined;
  attendanceNotes: MeetingKpiUserAttendanceNote[];
  checkIns?: number | undefined;
}

export interface MeetingKpiUserAttendanceNote {
  id?: string | undefined;
  name?: string | undefined;
  count?: number | undefined;
}

export interface MeetingEngineeringDocumentRequirementTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface MeetingEngineeringDocumentRequirementTemplates {
  templates: MeetingEngineeringDocumentRequirementTemplate[];
}

export interface MeetingEngineeringDocument {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extJobId?: string | undefined;
  name?: string | undefined;
  extOwnerId?: string | undefined;
  revisions: MeetingEngineeringDocumentRevision[];
  toNotifies: MeetingEngineeringDocumentToNotify[];
  templateId?:
    | string
    | undefined;
  /** repeated MeetingEngineeringDocumentJobRequirement requirements=8; */
  extPanelCodeId?: string | undefined;
}

export interface MeetingEngineeringDocumentJobRequirement {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extJobId?: string | undefined;
  templateId?: string | undefined;
  required?: boolean | undefined;
}

export interface MeetingEngineeringDocumentJobRequirements {
  requirements: MeetingEngineeringDocumentJobRequirement[];
}

export interface MeetingEngineeringDocuments {
  documents: MeetingEngineeringDocument[];
}

export interface MeetingEngineeringDocumentRevision {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  engineeringDocumentId?: string | undefined;
  extRevisionOwnerId?: string | undefined;
  fileBase64?: string | undefined;
  fileName?: string | undefined;
}

export interface MeetingEngineeringDocumentRevisions {
  revisions: MeetingEngineeringDocumentRevision[];
}

export interface MeetingEngineeringDocumentToNotify {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  engineeringDocumentId?: string | undefined;
}

export interface MeetingEngineeringDocumentToNotifies {
  toNotifies: MeetingEngineeringDocumentToNotify[];
}

export interface MeetingDepartmentProblem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extDepartmentId?: string | undefined;
  type?: TaskListDeptProblemType | undefined;
  alias?: string | undefined;
}

export interface MeetingDepartmentProblems {
  problems: MeetingDepartmentProblem[];
}

export interface MeetingDepartmentProblemMatch {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  sourceId?: string | undefined;
  destinationId?: string | undefined;
}

export interface MeetingDepartmentProblemMatches {
  matches: MeetingDepartmentProblemMatch[];
}

export interface MeetingTaskList {
  name?: string | undefined;
  extInChargeId?: string | undefined;
  start?: string | undefined;
  deadline?:
    | string
    | undefined;
  /** transient */
  projectName?: string | undefined;
  projectId?: string | undefined;
  subProject?: MeetingSubProject | undefined;
  subProjectId?: string | undefined;
  meetingTasks: MeetingMeetingTask[];
  taskListTargetDates: MeetingTaskListTargetDate[];
  status?: string | undefined;
  taskListInCharges: MeetingTaskListInCharge[];
  taskListSites: MeetingTaskListSites[];
  isWoInstaller?: boolean | undefined;
  extCustomerId?: string | undefined;
  taskListTemplate?: TaskListTemplate | undefined;
  taskListTemplateId?: string | undefined;
  taskListCashAdvancements: MeetingTaskListCashAdvancement[];
  taskListCashSettlements: MeetingTaskListCashSettlement[];
  taskListCashAdvancement?: MeetingTaskListCashAdvancement | undefined;
  taskListCashAdvancementId?: string | undefined;
  rateStars?: number | undefined;
  completedDate?: string | undefined;
  projectPo?: MeetingTaskProjectPo | undefined;
  projectPoId?: string | undefined;
  taskListCashAdvancementWorkOrders: MeetingTaskListCashAdvancementWorkOrder[];
  purpose?: string | undefined;
  extJobId?: string | undefined;
  extSerialNumber?: string | undefined;
  extModuleId?: string | undefined;
  extSubModuleId?: string | undefined;
  extSetId?: string | undefined;
  extProgramId?: string | undefined;
  extPanelCodeId?: string | undefined;
  extSupplierId?: string | undefined;
  extPurchaseOrderId?: string | undefined;
  extMrId?: string | undefined;
  extSubProcessId?: string | undefined;
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extIntegrationModuleQty?: number | undefined;
  extSubProcessCommonId?: string | undefined;
  extIntegrationModuleQtyActual?: number | undefined;
  extProgramRunQty?: number | undefined;
  extProcessTypeId?: string | undefined;
  extRcemSubProcessId?: string | undefined;
  extMachineId?: string | undefined;
  extWoInChargeCompletedDate?: string | undefined;
  workOrderBeginTimestamp?: string | undefined;
  workOrderStopTimestamp?: string | undefined;
  extOperatorWorkOrderBeginId?: string | undefined;
  extOperatorWorkOrderStopId?: string | undefined;
  extWorkingTimeMins?: number | undefined;
  extProgramRunQtyActual?: number | undefined;
  references: MeetingTaskListReference[];
  source?: AppSource | undefined;
  extDepartmentId?: string | undefined;
  deptCategory?: TaskListDepartmentCategory | undefined;
  extPanelCodeSerialNumberId?: string | undefined;
  taskStart?: string | undefined;
  taskEnd?: string | undefined;
  meetingId?: string | undefined;
  meeting?: MeetingMeeting | undefined;
  extManufacturingCustomerId?: string | undefined;
  totalTimeHours?: number | undefined;
  miscSettlements: MeetingTaskListMiscellaneousSettlement[];
  isSurvey?: boolean | undefined;
  logs: MeetingTaskListChangeLog[];
  extMiddlewareTransactionTypeId?:
    | string
    | undefined;
  /** Delivery start */
  deliveryAddress?: string | undefined;
  deliveryPic?: string | undefined;
  docDoPo?: boolean | undefined;
  docDoPenambahan?: boolean | undefined;
  docDoPeminjaman?: boolean | undefined;
  docDoTitipan?: boolean | undefined;
  docDoReplacement?: boolean | undefined;
  serialNumberOk?: boolean | undefined;
  deliveryStatusOk?: boolean | undefined;
  workDescString?: string | undefined;
  deliveryByLand?: boolean | undefined;
  deliveryBySea?: boolean | undefined;
  deliveryByAir?: boolean | undefined;
  deliveryByJneJnt?: boolean | undefined;
  deliveryExtItemId?: string | undefined;
  deliveryQty?: number | undefined;
  deliveryInvoiceIsNeeded?: boolean | undefined;
  deliveryRemark?:
    | string
    | undefined;
  /** Delivery end */
  extDeliveryItems: MeetingTaskListExtDeliveryItem[];
  reminderDays?: number | undefined;
  deliveryTypePoBpo?: TaskListDeliveryPoType | undefined;
  extBpoId?: string | undefined;
  isOkrKpi?: boolean | undefined;
  weight?: number | undefined;
  okrMeetingTaskParentId?: string | undefined;
  extMaterialRequestId?: string | undefined;
  extBomLeveledId?: string | undefined;
  abcReferenceId?: string | undefined;
}

export interface MeetingTaskListExtDeliveryItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  taskListId?: string | undefined;
  extItemId?: string | undefined;
  qty?: number | undefined;
}

export interface MeetingTaskListChangeLog {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  type?: MeetingTaskListChangeLog_MeetingTaskListChangeLogType | undefined;
  before?: string | undefined;
  after?: string | undefined;
  extUserId?: string | undefined;
}

export enum MeetingTaskListChangeLog_MeetingTaskListChangeLogType {
  LOG_UNRECOGNIZED = 0,
  LOG_CHANGE_TARGET = 1,
  LOG_UPDATE_COMPLETED_DATE = 2,
  LOG_UPDATE_PIC_COMPLETED_DATE = 3,
  UNRECOGNIZED = -1,
}

export function meetingTaskListChangeLog_MeetingTaskListChangeLogTypeFromJSON(
  object: any,
): MeetingTaskListChangeLog_MeetingTaskListChangeLogType {
  switch (object) {
    case 0:
    case "LOG_UNRECOGNIZED":
      return MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_UNRECOGNIZED;
    case 1:
    case "LOG_CHANGE_TARGET":
      return MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_CHANGE_TARGET;
    case 2:
    case "LOG_UPDATE_COMPLETED_DATE":
      return MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_UPDATE_COMPLETED_DATE;
    case 3:
    case "LOG_UPDATE_PIC_COMPLETED_DATE":
      return MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_UPDATE_PIC_COMPLETED_DATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MeetingTaskListChangeLog_MeetingTaskListChangeLogType.UNRECOGNIZED;
  }
}

export function meetingTaskListChangeLog_MeetingTaskListChangeLogTypeToJSON(
  object: MeetingTaskListChangeLog_MeetingTaskListChangeLogType,
): string {
  switch (object) {
    case MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_UNRECOGNIZED:
      return "LOG_UNRECOGNIZED";
    case MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_CHANGE_TARGET:
      return "LOG_CHANGE_TARGET";
    case MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_UPDATE_COMPLETED_DATE:
      return "LOG_UPDATE_COMPLETED_DATE";
    case MeetingTaskListChangeLog_MeetingTaskListChangeLogType.LOG_UPDATE_PIC_COMPLETED_DATE:
      return "LOG_UPDATE_PIC_COMPLETED_DATE";
    case MeetingTaskListChangeLog_MeetingTaskListChangeLogType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MeetingTaskListReference {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  type?: TaskListReferenceType | undefined;
  id?: string | undefined;
  taskListId?: string | undefined;
  stringData?: string | undefined;
}

export interface MeetingTaskListExternalSource {
  id?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  references: MeetingTaskListExternalSourceReference[];
  tasks: MeetingTaskListExternalSourceTask[];
  supervisor?: string | undefined;
  appSource?: AppSource | undefined;
  supervisorName?: string | undefined;
  start?: string | undefined;
  deadline?: string | undefined;
}

export interface MeetingTaskListExternalSources {
  workOrders: MeetingTaskListExternalSource[];
}

export interface MeetingTaskListExternalSourceReference {
  id?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  type?: TaskListReferenceType | undefined;
  referenceId?: string | undefined;
  taskListId?: string | undefined;
  stringData?: string | undefined;
}

export interface MeetingTaskListExternalSourceTask {
  id?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  description?: string | undefined;
  date?: string | undefined;
  peopleInCharge: MeetingTaskListExternalSourceTaskInCharge[];
  taskListId?: string | undefined;
  subProcessId?: string | undefined;
  status?: string | undefined;
  picCompletedDate?: string | undefined;
  spvCompletedDate?: string | undefined;
  durationMins?: number | undefined;
  qty?: number | undefined;
  serialNumbers: MeetingTaskListExternalSourceTaskSerialNumber[];
  trips: MeetingTaskListExternalSourceTaskJourney[];
}

export interface MeetingTaskListExternalSourceTaskJourney {
  id?: string | undefined;
  lat?: number | undefined;
  lon?: number | undefined;
  siteName?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
}

export interface MeetingTaskListExternalSourceTaskSerialNumber {
  id?: string | undefined;
  serialNumber?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
}

export interface MeetingTaskListExternalSourceTaskInCharge {
  id?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  extEmployeeId?: string | undefined;
  extEmployeeName?: string | undefined;
}

export interface MeetingAutoAssignParams {
  from?: string | undefined;
  to?: string | undefined;
  pcdti: PpicPanelCodeDepartmentTemplateItem[];
  panelCodeId?: string | undefined;
  jobId?: string | undefined;
}

export interface KpiDeptCache {
  deptId: string;
  lastCreated: string;
  times: KpiDeptCacheTime[];
  deptPersonnel: number;
  attendanceNotes: KpiDeptCacheAttendanceNote[];
  lastPlan?: string | undefined;
  jobToday: KpiDeptCacheJob[];
  jobTomorrow: KpiDeptCacheJob[];
  userId?: string | undefined;
}

export interface KpiDeptCacheJob {
  id?: string | undefined;
  name?: string | undefined;
}

export interface KpiDeptCacheAttendanceNote {
  attendanceNoteTypeId?: string | undefined;
  types: KpiDeptCacheAttendanceNoteTypeRange[];
}

export interface KpiDeptCacheAttendanceNoteTypeRange {
  range: KpiDeptCacheCategoryRangeEnum;
  value?: number | undefined;
  users: KpiDeptCacheAttendanceNoteTypeRangeUser[];
}

export interface KpiDeptCacheAttendanceNoteTypeRangeUser {
  extUserId?: string | undefined;
}

export interface KpiDeptCacheTime {
  time: KpiDeptCacheCategoryTimeEnum;
  categories: KpiDeptCategory[];
}

export interface KpiDeptCategory {
  category: KpiDeptCacheCategoryEnum;
  dates: KpiDeptCategoryRangeValue[];
}

export interface KpiDeptCategoryRangeValue {
  identifier?: number | undefined;
  customStart?: string | undefined;
  customEnd?: string | undefined;
  value?: number | undefined;
  range: KpiDeptCacheCategoryRangeEnum;
  finalStart?: string | undefined;
  finalEnd?: string | undefined;
  totalWorkOrders?: number | undefined;
  totalWorkOrdersDone?: number | undefined;
  numDays: number;
  totalCapacity: number;
  capacityDividedByDaysNum: number;
  capacityDividedByDaysNumAndPersonnel: number;
  stringValue?: string | undefined;
  totalCapacityDone: number;
  capacityDividedByDaysNumDone: number;
  capacityDividedByDaysNumAndPersonnelDone: number;
  usersCountedInWorkingHour?: number | undefined;
}

export interface MeetingAttendanceNote {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  note?: string | undefined;
  date?: string | undefined;
  hrApproved?: string | undefined;
  extHrApprovedById?: string | undefined;
  lat?: number | undefined;
  lon?: number | undefined;
  attendanceNoteTypeId?: string | undefined;
  extUserId?: string | undefined;
  isAffectingLeaveBalance?: boolean | undefined;
  fromBulkAssignment?: boolean | undefined;
}

export interface MeetingAttendanceNotes {
  notes: MeetingAttendanceNote[];
}

export interface MeetingAttendanceNoteType {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  attendanceNoteId?: string | undefined;
  name?: string | undefined;
  alias?: string | undefined;
  uniqIdentifier?: number | undefined;
  isAffectingLeaveBalance?: boolean | undefined;
  doesNotReduceAttendance?: boolean | undefined;
}

export interface MeetingAttendanceNoteTypes {
  types: MeetingAttendanceNoteType[];
}

export interface MeetingSubProject {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskListTargetDate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface TaskListTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskListInCharge {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
}

export interface MeetingTaskListSites {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  siteId?: string | undefined;
  site?: MeetingSite | undefined;
}

export interface MeetingProjectMasterTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  extDepartmentTemplateId?: string | undefined;
}

export interface MeetingProjectMasterTemplates {
  templates: MeetingProjectMasterTemplate[];
}

export interface MeetingSite {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  lat?: number | undefined;
  lon?: number | undefined;
  name?: string | undefined;
  areaId?: string | undefined;
  extPurchaseOrderId?: string | undefined;
}

export interface MeetingSites {
  sites: MeetingSite[];
}

export interface TaskListCashAdvancements {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface TaskListCashSettlement {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskProjectPo {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface TaskListCashAdvancementWorkOrder {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskListCashAdvancement {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskListCashSettlement {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskListCashAdvancementWorkOrder {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskListView {
  taskList?: MeetingTaskList | undefined;
  meetingTaskDeleteIds: string[];
  meetingTaskInChargeDeleteIds: string[];
  meetingTaskProblemCatalogDeleteIds: string[];
}

export interface MeetingJourneyCostView {
  taskList?: MeetingTaskList | undefined;
  detail?: MeetingJourneyCostViewDetail | undefined;
}

export interface MeetingJourneyCostViewDetail {
  totalRange?: number | undefined;
  items: MeetingJourneyCostViewDetailItem[];
  totalCostIdr?: number | undefined;
}

export interface MeetingJourneyCostViewDetailItem {
  journeyFrom?: Journey | undefined;
  journeyTo?: Journey | undefined;
  range?: number | undefined;
}

export interface MeetingTaskListsView {
  taskLists: MeetingTaskListView[];
}

export interface MeetingMeetingTaskView {
  meetingTask?: MeetingMeetingTask | undefined;
}

export interface MeetingMeetingTasksView {
  meetingTasks: MeetingMeetingTaskView[];
}

export interface MeetingMeetingTaskProblemCatalogs {
  meetingTaskProblemCatalogs: MeetingMeetingTaskProblemCatalog[];
}

export interface MeetingTaskListCashAdvancementArea {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface MeetingTaskListCashAdvancementAreas {
  areas: MeetingTaskListCashAdvancementArea[];
}

export interface MeetingMeetingTaskProblemCatalog {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  meetingTaskId?: string | undefined;
  problemCatalogId?: string | undefined;
  remark?: string | undefined;
  extInternalDeptId?: string | undefined;
  extExternalDeptId?: string | undefined;
  targetDate?: string | undefined;
  extUserId?: string | undefined;
  status?: MeetingTaskProblemCatalogStatus | undefined;
}

export interface MeetingProblemCatalog {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extDepartmentId?:
    | string
    | undefined;
  /** optional string problem_type  = 3; */
  alias?: string | undefined;
  problemType?: MeetingProblemType | undefined;
  problemIntIdentifier?: number | undefined;
  extDepartmentVersusId?: string | undefined;
  isQuality?: boolean | undefined;
  isCost?: boolean | undefined;
}

export interface MeetingProblemCatalogs {
  catalogs: MeetingProblemCatalog[];
}

export interface MeetingMeetingTaskTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  items: MeetingMeetingTaskTemplateItem[];
}

export interface MeetingMeetingTaskTemplateItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  templateId?: string | undefined;
  template?: MeetingMeetingTaskTemplate | undefined;
}

export interface MeetingMeetingTaskListTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  items: MeetingMeetingTaskListTemplateItem[];
  workingMins?: number | undefined;
}

export interface MeetingMeetingTaskListTemplates {
  templates: MeetingMeetingTaskListTemplate[];
}

export interface MeetingMeetingTaskListTemplateItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  templateId?: string | undefined;
  template?: MeetingMeetingTaskListTemplate | undefined;
  documentsNeeded?: number | undefined;
  remark?: string | undefined;
  workingMins?: number | undefined;
}

export interface MeetingMeetingTask {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  meeting?: MeetingMeeting | undefined;
  meetingId?: string | undefined;
  sequenceNumber?: number | undefined;
  description?: string | undefined;
  pic?: string | undefined;
  start?: string | undefined;
  deadline?: string | undefined;
  target?: string | undefined;
  completedDate?: string | undefined;
  remark?: string | undefined;
  problemRemark?: string | undefined;
  durationDays?: number | undefined;
  durationMins?: number | undefined;
  durationMinsActual?: number | undefined;
  status?: string | undefined;
  budget?: string | undefined;
  priority?: string | undefined;
  dependencyUuid?: string | undefined;
  hourStart?: string | undefined;
  hourEnd?: string | undefined;
  allDay?: boolean | undefined;
  taskPlaceString?: string | undefined;
  dependency?: MeetingMeetingTask | undefined;
  dependencyId?: string | undefined;
  children: MeetingMeetingTask[];
  meetingTaskNotes: MeetingTaskNote[];
  meetingTaskIssues: MeetingTaskIssue[];
  peopleInCharge: MeetingUser[];
  meetingTaskTargetDates: MeetingMeetingTaskTargetDate[];
  meetingTaskInCharges: MeetingMeetingTaskInCharge[];
  project?: MeetingProject | undefined;
  meetingName?: string | undefined;
  meetingLink?: string | undefined;
  meetingOnline?: boolean | undefined;
  meetingPlace?: MeetingPlace | undefined;
  meetingRoom?: MeetingRoom | undefined;
  meetingPlatform?: MeetingPlatform | undefined;
  meetingSummary?: string | undefined;
  projectName?: string | undefined;
  projectIdLong?: string | undefined;
  subApp?: MeetingSubApp | undefined;
  subAppId?: string | undefined;
  note?: string | undefined;
  hasAlert?: boolean | undefined;
  secondsToTaskAlert?: string | undefined;
  travelTimeSecs?: number | undefined;
  todoType?: string | undefined;
  travelTimeMins?: number | undefined;
  meetingTaskDepartments: MeetingTaskDepartment[];
  statusSubType?: string | undefined;
  taskList?: MeetingTaskList | undefined;
  taskListId?: string | undefined;
  taskListIdNo?: string | undefined;
  rateStars?: number | undefined;
  lat?: number | undefined;
  lon?: number | undefined;
  extLocationById?: string | undefined;
  meetingTaskRemarks: MeetingTaskRemark[];
  meetingTaskAttachment: MeeetingTaskAttachment[];
  extSubProcessId?: string | undefined;
  extSubProcessCommonId?: string | undefined;
  meetingTaskComments: MeetingMeetingTaskComment[];
  meetingTaskProblemCatalogs: MeetingMeetingTaskProblemCatalog[];
  journeys: Journey[];
  fromMins?: number | undefined;
  toMins?: number | undefined;
  fromHour?: number | undefined;
  toHour?: number | undefined;
  extPicCompletedDate?: string | undefined;
  isNotClear?: boolean | undefined;
  qty?: number | undefined;
  qcPassedCompletedDate?: string | undefined;
  qaPassedCompletedDate?: string | undefined;
  extPicCompletedTimestamp?: string | undefined;
  extRcemSubProcessId?: string | undefined;
  extPanelCodeDepartmentTemplateItemId?: string | undefined;
  meetingTaskSerialNumbers: MeetingMeetingTaskSerialNumber[];
  weight?: number | undefined;
  okrChildrenTaskList: MeetingTaskList[];
  abcReferenceId?: string | undefined;
}

export interface MeetingMeetingTaskSerialNumber {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  serialNumber?: string | undefined;
  meetingTaskId?: string | undefined;
}

export interface Journey {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  lat?: number | undefined;
  lon?: number | undefined;
  taskId?: string | undefined;
  siteName?: string | undefined;
}

export interface MeetingTaskNote {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskIssue {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingUser {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingProject {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  products: MeetingProjectProduct[];
  contracts: MeetingProjectContract[];
  managerId?: string | undefined;
  deadline?: string | undefined;
  closing?: string | undefined;
  extPurchaseOrderId?: string | undefined;
  extLeaderId?: string | undefined;
  pos: MeetingProjectPurchaseOrder[];
}

export interface MeetingProjectPurchaseOrder {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extPurchaseOrderId?: string | undefined;
}

export interface MeetingATPDocumentForm {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extCustomerId?: string | undefined;
  extPurchaseOrderId?: string | undefined;
  formSubDocuments: MeetingATPDocumentFormSubDocument[];
  formTestUnits: MeetingATPDocumentFormTestUnit[];
  photos: MeetingATPDocumentFormPhoto[];
}

export interface MeetingATPDocumentFormPhoto {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  bankNumber?: number | undefined;
  serialNumber?: string | undefined;
  originalFileName?: string | undefined;
  base64File?: string | undefined;
  name?: string | undefined;
  atpFormId?: string | undefined;
  batteryNumber?: number | undefined;
  type?: MeetingATPDocumentFormPhotoType | undefined;
}

export interface MeetingATPDocumentForms {
  forms: MeetingATPDocumentForm[];
}

export interface MeetingATPDocumentFormSubDocument {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  atpDocumentFormId?: string | undefined;
  subDocumentId?: string | undefined;
  batteries: MeetingATPDocumentFormSubDocumentBattery[];
}

export interface MeetingATPDocumentFormSubDocumentBattery {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  atpDocumentFormSubDocumentId?: string | undefined;
  serialNo?: string | undefined;
  open?: string | undefined;
  testValues: MeetingATPDocumentFormSubDocumentBatteryTestValue[];
}

export interface MeetingATPDocumentFormSubDocumentBatteryTestValue {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  atpDocumentFormSubDocumentBatteryId?: string | undefined;
  durationMins?: number | undefined;
  value?: number | undefined;
}

export interface MeetingATPDocumentFormTestUnit {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  atpDocumentFormId?: string | undefined;
  testUnitId?: string | undefined;
  value?: number | undefined;
  status?: boolean | undefined;
  valueActual?: number | undefined;
}

export interface MeetingATPDocument {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  subDocuments: MeetingATPSubDocument[];
}

export interface MeetingATPDocuments {
  atpDocuments: MeetingATPDocument[];
}

export interface MeetingATPSubDocument {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  testUnits: MeetingATPTestUnit[];
  type?: ATPSubDocumentType | undefined;
  name?: string | undefined;
}

export interface MeetingATPSubDocuments {
  atpSubDocuments: MeetingATPSubDocument[];
}

export interface MeetingATPTestUnit {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  positiveDesc?: string | undefined;
  negativeDesc?: string | undefined;
  subDocumentId?: string | undefined;
  unitOfMeasurement?: string | undefined;
}

export interface MeetingATPTestUnits {
  testUnits: MeetingATPTestUnit[];
}

export interface MeetingBASTDocument {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  date?: string | undefined;
  gspePics: MeetingBASTDocumentGSPEPIC[];
  custPics: MeetingBASTDocumentCustomerPIC[];
  projectId?: string | undefined;
  bastNumber?: string | undefined;
  customerId?: string | undefined;
  purchaseOrderId?: string | undefined;
  siteId?: string | undefined;
  templateType?: string | undefined;
  projectCostIdr?: number | undefined;
  customerReferenceName?: string | undefined;
  grReports: MeetingBASTDocumentGRReport[];
  projectIsManual?: boolean | undefined;
  projectManualName?: string | undefined;
  siteIsManual?: boolean | undefined;
  siteManualName?: string | undefined;
  poIsManual?: boolean | undefined;
  poManualName?: string | undefined;
  customTemplate?: string | undefined;
  customTemplateId?: string | undefined;
  variables: MeetingBASTDocumentCustomTemplateVariable[];
}

export interface MeetingBASTDocumentCustomTemplateVariable {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  value?: number | undefined;
  text?: string | undefined;
  bastDocumentId?: string | undefined;
}

export interface MeetingBASTDocumentCustomTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  customTemplate?: string | undefined;
  name?: string | undefined;
}

export interface MeetingBASTDocumentCustomTemplates {
  templates: MeetingBASTDocumentCustomTemplate[];
}

export interface MeetingBASTDocumentGRReport {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  bastDocumentId?: string | undefined;
  items: MeetingBASTDocumentGRReportItem[];
  date?: string | undefined;
}

export interface MeetingBASTDocumentGRReportItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extCustomerId?: string | undefined;
  remark?: string | undefined;
  bastDocumentGrReportId?: string | undefined;
  values: MeetingBASTDocumentGRValue[];
}

export interface MeetingBASTDocumentGRValue {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  type?: MeetingBASTDocumentGRReportValueType | undefined;
  value?: number | undefined;
  bastDocumentGrReportItemId?: string | undefined;
  identifier?: number | undefined;
}

export interface MeetingBASTDocumentGRReports {
  reports: MeetingBASTDocumentGRReport[];
}

export interface MeetingBASTDocuments {
  bastDocuments: MeetingBASTDocument[];
}

export interface MeetingBASTDocumentGSPEPIC {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  position?: string | undefined;
  bastDocumentId?: string | undefined;
}

export interface MeetingBASTDocumentCustomerPIC {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  position?: string | undefined;
  bastDocumentId?: string | undefined;
}

export interface MeetingProjects {
  projects: MeetingProject[];
}

export interface MeetingProjectProduct {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extCrmPurchaseOrderItemId?: string | undefined;
  name?: string | undefined;
  qty?: number | undefined;
  masterTemplates: MeetingProjectProductMasterTemplate[];
  projectTemplatePresetId?: string | undefined;
}

export interface MeetingProjectProductMasterTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  masterTemplateId?: string | undefined;
  date?: string | undefined;
  completed?: string | undefined;
  projectProductId?: string | undefined;
  excluded?: boolean | undefined;
}

export interface MeetingMasterTemplatePreset {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  items: MeetingMasterTemplatePresetItem[];
}

export interface MeetingMasterTemplatePresets {
  presets: MeetingMasterTemplatePreset[];
}

export interface MeetingMasterTemplatePresetItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  masterTemplatePresetId?: string | undefined;
  masterTemplateId?: string | undefined;
  excluded?: boolean | undefined;
}

export interface MeetingMasterTemplatePresetItems {
  items: MeetingMasterTemplatePresetItem[];
}

export interface MeetingProjectContract {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingPlace {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingRoom {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingPlatform {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingTaskDepartment {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extDepartmentId?: string | undefined;
  meetingTask?: MeetingMeetingTask | undefined;
  meetingTaskId?: string | undefined;
}

export interface MeetingTaskRemark {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  meetingTask?: MeetingMeetingTask | undefined;
  meetingTaskId?: string | undefined;
}

export interface MeeetingTaskAttachment {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  meetingTask?: MeetingMeetingTask | undefined;
  meetingTaskId?: string | undefined;
}

export interface MeetingMeetingTaskPostBody {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  description?: string | undefined;
  date?: string | undefined;
  inCharges: MeetingMeetingTaskPostBodyInCharge[];
  extCreatedById?: string | undefined;
  subApp?: string | undefined;
  remark?: string | undefined;
  problemRemark?: string | undefined;
  meetingTaskInChargeDeleteIds: string[];
  ppicWorkOrderRevId?: string | undefined;
}

export interface MeetingMeetingTaskPostBodyInCharge {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  employeeId?: string | undefined;
}

export interface MeetingSubApp {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface MeetingMeetings {
  meetings: MeetingMeeting[];
}

export interface MeetingMeeting {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  date?: string | undefined;
  start?: number | undefined;
  end?: number | undefined;
  approved?: boolean | undefined;
  status?: string | undefined;
  online?: boolean | undefined;
  meetingLink?: string | undefined;
  meetingSummary?: string | undefined;
  participants: MeetingMeetingParticipant[];
  agendas: MeetingMeetingAgenda[];
  tasks: MeetingMeetingTask[];
  place?: MeetingPlace | undefined;
  placeId?: string | undefined;
  room?: MeetingRoom | undefined;
  roomId?: string | undefined;
  createdBy?: MeetingUser | undefined;
  createdById?: string | undefined;
  platform?: MeetingPlatform | undefined;
  platformId?: string | undefined;
  taskLists: MeetingTaskList[];
}

export interface MeetingMeetingParticipant {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
}

export interface MeetingMeetingAgenda {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface MeetingMeetingTaskList {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  meetingId?: string | undefined;
  taskList?: MeetingTaskList | undefined;
  taskListId?: string | undefined;
}

export interface MeetingMeetingTaskInCharge {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  rateStars?: number | undefined;
  creator?: boolean | undefined;
  meetingTask?: MeetingMeetingTask | undefined;
  meetingTaskId?: string | undefined;
  isQc?: boolean | undefined;
  isQa?: boolean | undefined;
  totalTimeHours?: number | undefined;
  totalTimeHoursTask?: number | undefined;
}

export interface MeetingMeetingTaskComment {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  comment?: string | undefined;
  extPanelCodeId?: string | undefined;
  extSubProcessId?: string | undefined;
  readBySpvTimestamp?: string | undefined;
}

export interface MeetingMeetingTaskComments {
  comments: MeetingMeetingTaskComment[];
}

export interface MeetingMeetingTaskTargetDate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  date?: string | undefined;
  meetingTask?: MeetingMeetingTask | undefined;
  meetingTaskId?: string | undefined;
}

export interface MeetingMeetingTasks {
  meetingTasks: MeetingMeetingTask[];
}

export interface PpicJobInfo {
  job?: PpicJob | undefined;
  timeMins?: number | undefined;
  productTreeTime?: number | undefined;
  programTreeTime?: number | undefined;
  timeWoAssigned?: number | undefined;
  timeWoDone?: number | undefined;
  deptTemplateTime?: number | undefined;
}

export interface PpicJobInfoList {
  info: PpicJobInfo[];
}

export interface PpicMacroCategory {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  items: PpicMacroCategoryItem[];
}

export interface PpicOKRObjective {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  details: PpicOKRObjectiveDetail[];
}

export interface PpicOKRObjectiveDetail {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  weight?: number | undefined;
}

export interface PpicOKR {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  type?: OKRType | undefined;
  details: PpicOKRDetail[];
  departmentId?: string | undefined;
  teamId?: string | undefined;
  extUserId?: string | undefined;
  timeRange?: OKRTimeRange | undefined;
}

export interface PpicOKRDetail {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  weight?: number | undefined;
  progressPercentage?: number | undefined;
}

export interface PpicOKRObjectives {
  objectives: PpicOKRObjective[];
}

export interface PpicOKRs {
  okrs: PpicOKR[];
}

export interface PpicMacroCategories {
  categories: PpicMacroCategory[];
}

export interface PpicDeliveryNote {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  to?: string | undefined;
  address?: string | undefined;
  effectiveDate?: string | undefined;
  date?: string | undefined;
  purpose?: string | undefined;
  extPurchaseOrderId?: string | undefined;
  deliveryNoteItems: PpicDeliveryNoteItem[];
  revision?: number | undefined;
  dnNumber?: string | undefined;
}

export interface PpicDeliveryNotes {
  notes: PpicDeliveryNote[];
}

export interface PpicDeliveryNoteItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extItemId?: string | undefined;
  description?: string | undefined;
  deliveryNoteId?: string | undefined;
  qty?: number | undefined;
}

export interface PpicMacroCategoryItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  macroCategoryId?: string | undefined;
}

export interface PpicMacroCategoryItems {
  items: PpicMacroCategoryItem[];
}

export interface PpicRemoteControlPayload {
  screen?: string | undefined;
  deptId?: string | undefined;
  workOrderType?: WorkOrderType | undefined;
  jobId?: string | undefined;
  extUserId?: string | undefined;
  skipDate?: number | undefined;
  presetRecursive: PpicRemoteControlPreset[];
  scheduled?: boolean | undefined;
  picOnly?: boolean | undefined;
  quadScreen?: boolean | undefined;
  jdpPresetId?: string | undefined;
  extDeptVersusId?: string | undefined;
  extProblemCatalogId?: string | undefined;
  quadScreenV2?: boolean | undefined;
  refresh?: boolean | undefined;
  filterTaskDateOnly?: boolean | undefined;
  woGraph?: boolean | undefined;
}

export interface MeetingOvertimeRequest {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extRequestedById?: string | undefined;
  extNotifyToApproveId?: string | undefined;
  remark?: string | undefined;
  extJobId?: string | undefined;
  from?: string | undefined;
  to?: string | undefined;
  approvalDate?: string | undefined;
  extApprovedById?: string | undefined;
  cancelled?: boolean | undefined;
  cancellationReason?: string | undefined;
  pics: MeetingOvertimePic[];
  approvalPics: MeetingOvertimeApprovalPic[];
  taskListId?: string | undefined;
}

export interface MeetingOvertimePic {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  overrideHrs?: number | undefined;
  approvalRealizationDate?: string | undefined;
  actualWorkingHoursFromAttendance?: number | undefined;
  remark?: string | undefined;
}

export interface MeetingOvertimeApprovalTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  items: MeetingOvertimeApprovalTemplateItem[];
  extDepartmentId?: string | undefined;
}

export interface MeetingOvertimeApprovalTemplateItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  overtimeApprovalTemplateId?: string | undefined;
  extUserId?: string | undefined;
  type?: MeetingOvertimeApprovalPicType | undefined;
}

export interface AuthserverPph21MarriageCategory {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  terCategory?: string | undefined;
}

export interface AuthserverPph21MarriageCategories {
  categories: AuthserverPph21MarriageCategory[];
}

export interface MeetingOvertimeApprovalTemplates {
  templates: MeetingOvertimeApprovalTemplate[];
}

export interface MeetingOvertimeApprovalPic {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  type?:
    | MeetingOvertimeApprovalPicType
    | undefined;
  /** optional double override_hrs=6; */
  approvalDate?: string | undefined;
}

export interface MeetingOvertimeRequests {
  overtimeRequests: MeetingOvertimeRequest[];
}

export interface PpicRemoteControlPreset {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  userId?: string | undefined;
  extDepartmentId?: string | undefined;
  screen?: string | undefined;
  name?: string | undefined;
  jobId?: string | undefined;
  extUserId?: string | undefined;
  workOrderType?: WorkOrderType | undefined;
  skipDate?: number | undefined;
  presetRecursive: PpicRemoteControlPreset[];
  presetId?: string | undefined;
  scheduled?: boolean | undefined;
  manualCode?: string | undefined;
  picOnly?: boolean | undefined;
  quadScreen?: boolean | undefined;
  jdpPresetId?: string | undefined;
  extDeptVersusId?: string | undefined;
  extProblemCatalogId?: string | undefined;
  quadScreenV2?: boolean | undefined;
  refresh?: boolean | undefined;
  filterTaskDateOnly?: boolean | undefined;
  woGraph?: boolean | undefined;
}

export interface PpicRemoteControlPresets {
  presets: PpicRemoteControlPreset[];
}

export interface MeetingTaskListMiscellaneousSettlement {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  value?: number | undefined;
  taskListId?: string | undefined;
}

export interface PpicDepartmentTemplateRule {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  type?: PpicDepartmentTemplateRule_PpicDepartmentRuleType | undefined;
  departmentTemplateRule?: PpicDepartmentTemplateRule | undefined;
  departmentTemplateRuleId?: string | undefined;
  departmentTemplateRules: PpicDepartmentTemplateRule[];
  departmentTemplateItem?: PpicDepartmentTemplateItem | undefined;
  departmentTemplateItemId?: string | undefined;
}

export enum PpicDepartmentTemplateRule_PpicDepartmentRuleType {
  AND = 0,
  OR = 1,
  UNRECOGNIZED = -1,
}

export function ppicDepartmentTemplateRule_PpicDepartmentRuleTypeFromJSON(
  object: any,
): PpicDepartmentTemplateRule_PpicDepartmentRuleType {
  switch (object) {
    case 0:
    case "AND":
      return PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND;
    case 1:
    case "OR":
      return PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicDepartmentTemplateRule_PpicDepartmentRuleType.UNRECOGNIZED;
  }
}

export function ppicDepartmentTemplateRule_PpicDepartmentRuleTypeToJSON(
  object: PpicDepartmentTemplateRule_PpicDepartmentRuleType,
): string {
  switch (object) {
    case PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND:
      return "AND";
    case PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR:
      return "OR";
    case PpicDepartmentTemplateRule_PpicDepartmentRuleType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PpicDepartmentTemplateRules {
  rules: PpicDepartmentTemplateRule[];
}

export interface PpicWorkingHour {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  start?: number | undefined;
  end?: number | undefined;
  label?: string | undefined;
  breakStart?: number | undefined;
  breakEnd?: number | undefined;
}

export interface PpicWorkingHours {
  workingHours: PpicWorkingHour[];
}

export interface PpicWarehouseReportQty {
  inventoryId?: string | undefined;
  qtySeen?: number | undefined;
}

export interface PpicIndividualEntity {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface PpicIndividualEntities {
  entities: PpicIndividualEntity[];
}

export interface PpicPartNumberMatchGroup {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extItemId?: string | undefined;
  extPurchaseOrderId?: string | undefined;
}

export interface PpicPartNumberMatchGroups {
  groups: PpicPartNumberMatchGroup[];
}

export interface PpicPartNumberMatch {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  customerPartNumber?: string | undefined;
  gspePartNumber?: string | undefined;
  source?: string | undefined;
  destination?: string | undefined;
  qty?: number | undefined;
  extPurchaseOrderId?: string | undefined;
  sourcePartNumber?: string | undefined;
  destinationPartNumber?: string | undefined;
  extPurchaseOrderItemId?: string | undefined;
  groupQty?: number | undefined;
  isGroup?: boolean | undefined;
  sequenceNumber?: number | undefined;
}

export interface PpicPartNumberCustomerMatch {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  source?: string | undefined;
  destination?: string | undefined;
  qty?: number | undefined;
  extCustomerId?: string | undefined;
}

export interface PpicPartNumberMatches {
  matches: PpicPartNumberMatch[];
}

export interface PpicPartNumberCustomerMatches {
  matches: PpicPartNumberCustomerMatch[];
}

export interface PpicRcemSkill {
  rcem?: PpicRcem | undefined;
  rcemId?: string | undefined;
  skill?: PpicSkill | undefined;
  skillId?: string | undefined;
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  rcemSkillCategoryId?: string | undefined;
}

export interface PpicRcemSkills {
  rcemSkills: PpicRcemSkill[];
}

export interface PpicRcemProcessSkill {
  rcemProcess?: PpicRcemProcess | undefined;
  rcemProcessId?: string | undefined;
  skill?: PpicSkill | undefined;
  skillId?: string | undefined;
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface PpicRcemProcessSkills {
  rcemProcessSkills: PpicRcemProcessSkill[];
}

export interface PpicRcemSkillCategory {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface PpicSkillWorker {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  skill?: PpicSkill | undefined;
  skillId?: string | undefined;
  extUserId?: string | undefined;
  skillLevel?: PpicSkillWorker_SkillLevel | undefined;
  isPriority?: boolean | undefined;
}

export enum PpicSkillWorker_SkillLevel {
  BASIC = 0,
  INTERMEDIATE = 1,
  ADVANCED = 2,
  EXPERT = 3,
  UNRECOGNIZED = -1,
}

export function ppicSkillWorker_SkillLevelFromJSON(object: any): PpicSkillWorker_SkillLevel {
  switch (object) {
    case 0:
    case "BASIC":
      return PpicSkillWorker_SkillLevel.BASIC;
    case 1:
    case "INTERMEDIATE":
      return PpicSkillWorker_SkillLevel.INTERMEDIATE;
    case 2:
    case "ADVANCED":
      return PpicSkillWorker_SkillLevel.ADVANCED;
    case 3:
    case "EXPERT":
      return PpicSkillWorker_SkillLevel.EXPERT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicSkillWorker_SkillLevel.UNRECOGNIZED;
  }
}

export function ppicSkillWorker_SkillLevelToJSON(object: PpicSkillWorker_SkillLevel): string {
  switch (object) {
    case PpicSkillWorker_SkillLevel.BASIC:
      return "BASIC";
    case PpicSkillWorker_SkillLevel.INTERMEDIATE:
      return "INTERMEDIATE";
    case PpicSkillWorker_SkillLevel.ADVANCED:
      return "ADVANCED";
    case PpicSkillWorker_SkillLevel.EXPERT:
      return "EXPERT";
    case PpicSkillWorker_SkillLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PpicSkillWorkers {
  workers: PpicSkillWorkers[];
}

export interface PpicRcemSubProcessSkill {
  rcemSubProcess?: PpicRcemSubProcess | undefined;
  rcemSubProcessId?: string | undefined;
  skill?: PpicSkill | undefined;
  skillId?: string | undefined;
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface PpicRcemSubProcessSkills {
  rcemSubProcessSkills: PpicRcemSubProcessSkill[];
}

export interface PpicRcemDepartment {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  processes: PpicRcemProcess[];
}

export interface PpicRcemDepartments {
  departments: PpicRcemDepartment[];
}

export interface PpicRcemProcess {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  subProcesses: PpicRcemSubProcess[];
  extDepartmentId?: string | undefined;
  department?: PpicRcemDepartment | undefined;
}

export interface PpicRcemProcesses {
  processes: PpicRcemProcess[];
}

export interface PpicRcemSubProcess {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  description?: string | undefined;
  ppicRcemProcess?: PpicRcemProcess | undefined;
  ppicRcemProcessId?: string | undefined;
  rcems: PpicRcem[];
  impact?: PpicRcemSubProcess_Impact | undefined;
  likelihood?: PpicRcemSubProcess_Likelihood | undefined;
  qualitativeRisk?: number | undefined;
  supplierStr?: string | undefined;
  inputStr?: string | undefined;
  processStr?: string | undefined;
  outputStr?: string | undefined;
  customerStr?: string | undefined;
  controlResponsibilityStr?: string | undefined;
  frequencyOfControlApplicationStr?: string | undefined;
  amStr?: string | undefined;
  pdStr?: string | undefined;
  rNrEStr?: string | undefined;
  controlComponentStr?: string | undefined;
  timeProcess?: number | undefined;
  skill?: PpicSkill | undefined;
  skillId?: string | undefined;
}

export enum PpicRcemSubProcess_Impact {
  INSIGNIFICANT = 0,
  SIGNIFICANT = 1,
  CRITICAL = 2,
  CATASTROPHIC = 3,
  UNRECOGNIZED = -1,
}

export function ppicRcemSubProcess_ImpactFromJSON(object: any): PpicRcemSubProcess_Impact {
  switch (object) {
    case 0:
    case "INSIGNIFICANT":
      return PpicRcemSubProcess_Impact.INSIGNIFICANT;
    case 1:
    case "SIGNIFICANT":
      return PpicRcemSubProcess_Impact.SIGNIFICANT;
    case 2:
    case "CRITICAL":
      return PpicRcemSubProcess_Impact.CRITICAL;
    case 3:
    case "CATASTROPHIC":
      return PpicRcemSubProcess_Impact.CATASTROPHIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicRcemSubProcess_Impact.UNRECOGNIZED;
  }
}

export function ppicRcemSubProcess_ImpactToJSON(object: PpicRcemSubProcess_Impact): string {
  switch (object) {
    case PpicRcemSubProcess_Impact.INSIGNIFICANT:
      return "INSIGNIFICANT";
    case PpicRcemSubProcess_Impact.SIGNIFICANT:
      return "SIGNIFICANT";
    case PpicRcemSubProcess_Impact.CRITICAL:
      return "CRITICAL";
    case PpicRcemSubProcess_Impact.CATASTROPHIC:
      return "CATASTROPHIC";
    case PpicRcemSubProcess_Impact.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PpicRcemSubProcess_Likelihood {
  RARE = 0,
  OCCASSIONAL = 1,
  LIKELY = 2,
  FREQUENT = 3,
  UNRECOGNIZED = -1,
}

export function ppicRcemSubProcess_LikelihoodFromJSON(object: any): PpicRcemSubProcess_Likelihood {
  switch (object) {
    case 0:
    case "RARE":
      return PpicRcemSubProcess_Likelihood.RARE;
    case 1:
    case "OCCASSIONAL":
      return PpicRcemSubProcess_Likelihood.OCCASSIONAL;
    case 2:
    case "LIKELY":
      return PpicRcemSubProcess_Likelihood.LIKELY;
    case 3:
    case "FREQUENT":
      return PpicRcemSubProcess_Likelihood.FREQUENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicRcemSubProcess_Likelihood.UNRECOGNIZED;
  }
}

export function ppicRcemSubProcess_LikelihoodToJSON(object: PpicRcemSubProcess_Likelihood): string {
  switch (object) {
    case PpicRcemSubProcess_Likelihood.RARE:
      return "RARE";
    case PpicRcemSubProcess_Likelihood.OCCASSIONAL:
      return "OCCASSIONAL";
    case PpicRcemSubProcess_Likelihood.LIKELY:
      return "LIKELY";
    case PpicRcemSubProcess_Likelihood.FREQUENT:
      return "FREQUENT";
    case PpicRcemSubProcess_Likelihood.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PpicSkill {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  skillWorkers: PpicSkillWorker[];
  pics?: number | undefined;
  extDepartmentId?: string | undefined;
}

export interface PpicSkills {
  skills: PpicSkill[];
}

export interface PpicRcemSubProcesses {
  subProcesses: PpicRcemSubProcess[];
}

export interface PpicRcem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  subProcess?: PpicRcemSubProcess | undefined;
  subProcessId?: string | undefined;
  extDepartmentId?: string | undefined;
}

export interface PpicRcems {
  rcems: PpicRcem[];
}

export interface PpicSimplifiedProcessType {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  machineId?: string | undefined;
  extSpvId?: string | undefined;
  extPicId?: string | undefined;
  pics: PpicSimplifiedProcessTypePic[];
}

export interface PpicSimplifiedProcessTypePic {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  simplifiedProcessTypeId?: string | undefined;
}

export interface PpicAutoMRWarehouseFilters {
  filters: PpicAutoMRWarehouseFilter[];
}

export interface PpicAutoMRWarehouseFilter {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  warehouseId?: string | undefined;
}

export interface PpicSimplifiedProcessTypes {
  processTypes: PpicSimplifiedProcessType[];
}

export interface PpicMachineProgram {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  timeProcessMins?: number | undefined;
  panelCode?: PpicPanelCode | undefined;
  panelCodeId?: string | undefined;
  qtyRun?: number | undefined;
  machineProgramPics: PpicMachineProgramPic[];
  machineProgramMachines: PpicMachineProgramMachine[];
  integrationModuleId?: string | undefined;
}

export interface PpicMachineProgramMachine {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  machineId?: string | undefined;
  machineProgramId?: string | undefined;
  simplifiedProcessTypeId?: string | undefined;
  extSupervisorId?: string | undefined;
}

export interface PpicMachineProgramMachines {
  machineProgramMachines: PpicMachineProgramMachine[];
}

export interface PpicMachinePrograms {
  machinePrograms: PpicMachineProgram[];
}

export interface PpicMachineProgramPic {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  machineProgramId?: string | undefined;
}

export interface PpicMachineProgramPics {
  machineProgramPics: PpicMachineProgramPic[];
}

export interface PpicIntegrationModule {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  integrationModule?: PpicIntegrationModule | undefined;
  integrationModuleId?: string | undefined;
  integrationModules: PpicIntegrationModule[];
  qty?: number | undefined;
  integrationModuleType?: string | undefined;
  tProcess?: number | undefined;
  timeProcessMins?: number | undefined;
  integration?: PpicIntegration | undefined;
  integrationId?: string | undefined;
  type?: string | undefined;
  itemId?: string | undefined;
}

export interface PpicHoliday {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  date?: string | undefined;
  name?: string | undefined;
  templateName?: string | undefined;
}

export interface PpicHolidays {
  holidays: PpicHoliday[];
}

export interface PpicIntegration {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface PpicHandover {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  items: PpicHandoverItem[];
  date?: string | undefined;
  status?: PpicHandover_Status | undefined;
  type?: PpicHandoverType | undefined;
  handoverByHrId?: string | undefined;
  handoverBy?: string | undefined;
  remark?: string | undefined;
  panelCodeSerialNumberId?: string | undefined;
  extJobId?: string | undefined;
  handoverNotifyUsers: PpicHandoverNotifyUser[];
  materialInstallationItems: PpicHandoverMaterialInstallationItem[];
  isMaterialInstallation?: boolean | undefined;
  extHandoverBomId?: string | undefined;
  materialInstallationItemId?: string | undefined;
  jobName?: string | undefined;
  extPurchaseOrderId?: string | undefined;
}

export enum PpicHandover_Status {
  OUTSTANDING = 0,
  APPROVED = 1,
  CANCELLED = 2,
  UNRECOGNIZED = -1,
}

export function ppicHandover_StatusFromJSON(object: any): PpicHandover_Status {
  switch (object) {
    case 0:
    case "OUTSTANDING":
      return PpicHandover_Status.OUTSTANDING;
    case 1:
    case "APPROVED":
      return PpicHandover_Status.APPROVED;
    case 2:
    case "CANCELLED":
      return PpicHandover_Status.CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicHandover_Status.UNRECOGNIZED;
  }
}

export function ppicHandover_StatusToJSON(object: PpicHandover_Status): string {
  switch (object) {
    case PpicHandover_Status.OUTSTANDING:
      return "OUTSTANDING";
    case PpicHandover_Status.APPROVED:
      return "APPROVED";
    case PpicHandover_Status.CANCELLED:
      return "CANCELLED";
    case PpicHandover_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PpicHandoverMaterialInstallationItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extItemId?: string | undefined;
  qty?: number | undefined;
  handoverId?: string | undefined;
}

export interface PpicHandoverNotifyUser {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  handoverId?: string | undefined;
}

export interface PpicHandoverStatusBody {
  handoverId?: string | undefined;
  status?: PpicHandover_Status | undefined;
}

export interface PpicHandovers {
  handovers: PpicHandover[];
}

export interface PpicHandoverUpdateStatusBody {
  id?: string | undefined;
  qaStatus?: HandoverQAStatus | undefined;
  qaUserId?: string | undefined;
  qaUserCheckedTimestamp?: string | undefined;
  qaId?: string | undefined;
  qcProgress?: boolean | undefined;
  whStatus?: HandoverQAStatus | undefined;
  whUserId?: string | undefined;
  whUserCheckedTimestamp?: string | undefined;
}

export interface PpicHandoverItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  panelCodeId?: string | undefined;
  qty?: number | undefined;
  extItemId?: string | undefined;
  productId?: string | undefined;
  remark?: string | undefined;
  panelCodeSerialNumberId?: string | undefined;
  jobId?: string | undefined;
  poId?: string | undefined;
  materialRequestId?: string | undefined;
  panelCodeSerialNumberFullName?: string | undefined;
  panelCodeSerialNumberManualId?: string | undefined;
  qaStatus?: HandoverQAStatus | undefined;
  qaUserId?: string | undefined;
  qaUserCheckedTimestamp?: string | undefined;
  qaId?: string | undefined;
  qaSendTimestamp?: string | undefined;
  qaRemark?: string | undefined;
  qcProgress?: boolean | undefined;
  whUserId?: string | undefined;
  whUserCheckedTimestamp?: string | undefined;
  whStatus?: HandoverQAStatus | undefined;
  whSendTimestamp?: string | undefined;
  whRemark?: string | undefined;
  pics: PpicHandoverItemPic[];
  productionRemark?: string | undefined;
  panelCodePartNumber?: string | undefined;
  panelCodeExtItemId?: string | undefined;
  panelCodeExtItemPrice?: number | undefined;
  partNum?: string | undefined;
  partName?: string | undefined;
  partDesc?: string | undefined;
  mfr?: string | undefined;
  defaultUm?: string | undefined;
  snapshotPriceIdr?: number | undefined;
  extPurchaseOrderId?: string | undefined;
}

export interface PpicPanelCodeOutstandings {
  products: PpicPanelCodeOutstanding[];
}

export interface PpicPanelCodeOutstanding {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  type?: string | undefined;
  code?: string | undefined;
  qty?: number | undefined;
  extItemId?: string | undefined;
  mfr?: string | undefined;
  partNum?: string | undefined;
  partName?: string | undefined;
  partDesc?: string | undefined;
  defaultUm?: string | undefined;
  qtyDone?: number | undefined;
  lastStep?: string | undefined;
  percentage?: number | undefined;
  bomList?: boolean | undefined;
  jobId?: string | undefined;
  jobName?: string | undefined;
}

export interface PpicHandoverItemPic {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  handoverItemId?: string | undefined;
}

export interface PpicHandoverItems {
  items: PpicHandoverItem[];
}

export interface PpicIntegrationModules {
  modules: PpicIntegrationModule[];
}

export interface PpicMachine {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  purchasedDate?: string | undefined;
  status?: string | undefined;
  processTypeId?: string | undefined;
  processType?: PpicProcessType | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  simpleProcessType?: PpicSimplifiedProcessType | undefined;
  simpleProcessTypeId?: string | undefined;
  pics: MachinePic[];
}

export interface PpicMachines {
  machines: PpicMachine[];
}

export interface MachinePic {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
  machineId?: string | undefined;
}

export interface PpicProcessType {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface PpicBomDocumentHierarchyXls {
  data?: string | undefined;
}

export interface PpicForProcess {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface PpicBomLeveledGroup {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  items: PpicBomLeveledGroupItem[];
  quoteId?: string | undefined;
}

export interface PpicBomLeveledGroups {
  groups: PpicBomLeveledGroup[];
}

export interface PpicBomLeveledGroupItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  bomLeveledGroupId?: string | undefined;
  bomLeveledId?: string | undefined;
}

export interface PpicBomLeveledSubGroup {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
}

export interface PpicBomLeveledRecursive {
  bomLeveled?: PpicBomLeveled | undefined;
  children: PpicBomLeveled[];
}

export interface PpicBomLeveled {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  type?: string | undefined;
  bomLeveled?: PpicBomLeveled | undefined;
  bomLeveledId?: string | undefined;
  qty?: number | undefined;
  children: PpicBomLeveled[];
  forProcess?: PpicForProcess | undefined;
  forProcessId?: string | undefined;
  bomLeveledGroup?: PpicBomLeveledGroup | undefined;
  bomLeveledGroupId?: string | undefined;
  bomLeveledSubGroup?: PpicBomLeveledSubGroup | undefined;
  bomLeveledSubGroupId?: string | undefined;
  extItemId?: string | undefined;
  itemsCount?: number | undefined;
  inMr?: number | undefined;
  priceOriginalCurrency?: string | undefined;
  priceOriginalPrice?: number | undefined;
  priceIdr?:
    | number
    | undefined;
  /** optional double price_calculated_price=20; */
  extItemToCreateId?: string | undefined;
  mech?: boolean | undefined;
  elec?: boolean | undefined;
  extRequestItemId?: string | undefined;
  materialType?: BomItemType | undefined;
  mfr?: string | undefined;
  partNum?: string | undefined;
  partName?: string | undefined;
  partDesc?: string | undefined;
  um?: string | undefined;
  snapshotPriceIdr?: number | undefined;
  snapshotPriceLast?: string | undefined;
  entities: PpicBomLeveledIndividualEntity[];
  deactivationDate?: string | undefined;
  revision?: number | undefined;
}

export interface PpicBomLeveledIndividualEntity {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  individualEntityId?: string | undefined;
}

export interface PpicBomLeveledWithPrice {
  bomLeveled?: PpicBomLeveled | undefined;
  price?: number | undefined;
}

export interface PpicBomLeveledsWithPrice {
  boms: PpicBomLeveledWithPrice[];
}

export interface PpicBomLeveleds {
  bomLeveleds: PpicBomLeveled[];
}

export interface PpicManpowerAssistance {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extDeptFromId?: string | undefined;
  extDeptToId?: string | undefined;
  forDate?: string | undefined;
  extUserId?: string | undefined;
}

export interface PpicManpowerAssistances {
  assistances: PpicManpowerAssistance[];
}

export interface PpicSimplifiedProcessTypeMultipliers {
  multipliers: PpicSimplifiedProcessTypeMultiplier[];
}

export interface PpicSimplifiedProcessTypeMultiplier {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  simplifiedProcessTypeId?: string | undefined;
  multiplier?: number | undefined;
}

export interface PpicJobBomLeveled {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  job?: PpicJob | undefined;
  jobId?: string | undefined;
  bomLeveled?: PpicBomLeveled | undefined;
  bomLeveledId?: string | undefined;
  qty?: number | undefined;
  bomLeveledName?: string | undefined;
  extPanelCodeId?: string | undefined;
}

export interface PpicNewJobNotifications {
  notifications: PpicNewJobNotification[];
}

export interface PpicNewJobNotification {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extUserId?: string | undefined;
}

export interface PpicJob {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  qty?: string | undefined;
  priority?: string | undefined;
  qtyDone?: string | undefined;
  productionDeadline?: string | undefined;
  deliveryDeadline?: string | undefined;
  deadline?: string | undefined;
  closed?: boolean | undefined;
  closingDate?: string | undefined;
  jobDate?: string | undefined;
  referenceType?: string | undefined;
  poNumber?: string | undefined;
  workOrders: PpicWorkOrder[];
  workOrderRevs: PpicWorkOrderRev[];
  wiringWorkOrders: PpicWiringWorkOrder[];
  panelCodes: PpicPanelCode[];
  extPurchaseOrderId?: string | undefined;
  individualEntityId?: string | undefined;
  category?: PpicJob_PpicJobDeptCategory | undefined;
  jobBomLeveleds: PpicJobBomLeveled[];
  serialNumberDones: PpicJobPanelCodeSerialNumberDone[];
  projectId?: string | undefined;
  extJdpPresetId?: string | undefined;
  shortName?: string | undefined;
  batchCount?: number | undefined;
  jobPurchaseOrders: PpicJobPurchaseorder[];
}

export enum PpicJob_PpicJobDeptCategory {
  PPC = 0,
  OTHERS = 1,
  INTERNAL = 2,
  PRODUCT_ONLY = 3,
  UNRECOGNIZED = -1,
}

export function ppicJob_PpicJobDeptCategoryFromJSON(object: any): PpicJob_PpicJobDeptCategory {
  switch (object) {
    case 0:
    case "PPC":
      return PpicJob_PpicJobDeptCategory.PPC;
    case 1:
    case "OTHERS":
      return PpicJob_PpicJobDeptCategory.OTHERS;
    case 2:
    case "INTERNAL":
      return PpicJob_PpicJobDeptCategory.INTERNAL;
    case 3:
    case "PRODUCT_ONLY":
      return PpicJob_PpicJobDeptCategory.PRODUCT_ONLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicJob_PpicJobDeptCategory.UNRECOGNIZED;
  }
}

export function ppicJob_PpicJobDeptCategoryToJSON(object: PpicJob_PpicJobDeptCategory): string {
  switch (object) {
    case PpicJob_PpicJobDeptCategory.PPC:
      return "PPC";
    case PpicJob_PpicJobDeptCategory.OTHERS:
      return "OTHERS";
    case PpicJob_PpicJobDeptCategory.INTERNAL:
      return "INTERNAL";
    case PpicJob_PpicJobDeptCategory.PRODUCT_ONLY:
      return "PRODUCT_ONLY";
    case PpicJob_PpicJobDeptCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PpicJobPurchaseorder {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extPurchaseOrderId?: string | undefined;
}

export interface PpicJobPanelCodeDepartmentTemplateItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  departmentTemplateItemId?: string | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  completedDate?: string | undefined;
  excluded?: string | undefined;
}

export interface PpicJobPanelCodeDepartmentTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  departmentTemplateId?: string | undefined;
}

export interface PpicPanelCodeSerialNumber {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  panelCodeId?: string | undefined;
  fullName?: string | undefined;
  jobId?: string | undefined;
  productId?: string | undefined;
  panelCodeType?: string | undefined;
  sequentialSerialNumber?: string | undefined;
  crmPoItemId?: string | undefined;
  done?: boolean | undefined;
  serialNumberRects: PpicPanelCodeSerialNumberRect[];
}

export interface PpicPanelCodeSerialNumbers {
  serialNumbers: PpicPanelCodeSerialNumber[];
}

export interface PpicPanelCodeSerialNumberManual {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  panelCodeId?: string | undefined;
  serialNumber?: string | undefined;
  serialNumberRects: PpicPanelCodeSerialNumberRect[];
}

export interface PpicPanelCodeSerialNumberRect {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  panelCodeId?: string | undefined;
  name?: string | undefined;
  serialNumberId?: string | undefined;
  serialNumberManualId?: string | undefined;
}

export interface PpicPanelCodeSerialNumberManuals {
  serialNumbers: PpicPanelCodeSerialNumberManual[];
}

export interface PpicJobPanelCodeSerialNumberDone {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  serialNumber?: PpicPanelCodeSerialNumber | undefined;
  completedDate?: string | undefined;
  jobId?: string | undefined;
  serialNumberId?: string | undefined;
}

export interface PpicWorkOrder {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  jobId?: string | undefined;
}

export interface PpicWorkOrderRev {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  jobId?: string | undefined;
}

export interface PpicWiringWorkOrder {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  jobName?: string | undefined;
  panelCodeName?: string | undefined;
  panelCodeType?: string | undefined;
  panelCodeSerialNumber?: string | undefined;
  date?: string | undefined;
  wiringProcessType?: string | undefined;
  wiringDescription?: string | undefined;
  wiringRemark?: string | undefined;
  isCustom?: boolean | undefined;
  isDone?: boolean | undefined;
  isCancelled?: boolean | undefined;
  doneStatus?: string | undefined;
  wiringProcessTypeFix?: PpicPanelCodeWiringProcessType | undefined;
  wiringProcessTypeFixId?: string | undefined;
  deadlineDetail?: PpicDeadlineDetail | undefined;
  deadlineDetailId?: string | undefined;
  job?: PpicJob | undefined;
  jobId?: string | undefined;
  worker?: PpicUser | undefined;
  workerId?: string | undefined;
  createdBy?: PpicUser | undefined;
  createdById?: string | undefined;
  extMeetingTaskId?: string | undefined;
  line?: string | undefined;
  zone?: string | undefined;
}

export interface PpicPanelCodeWiringProcessType {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  panelCodeId?: string | undefined;
}

export interface PpicUser {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
}

export interface PpicPanelCode {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  type?: string | undefined;
  serialNumber?: string | undefined;
  departmentTemplates: PpicPanelCodeDepartmentTemplate[];
  departmentItemStatuses: PpicDepartmentTemplateItemStatus[];
  jobId?: string | undefined;
  connections?: string | undefined;
  closed?: boolean | undefined;
  deadline?: string | undefined;
  job?: PpicJob | undefined;
  departmentTemplateItems: PpicPanelCodeDepartmentTemplateItem[];
  qty?: number | undefined;
  extItemId?: string | undefined;
  machinePrograms: PpicMachineProgram[];
  serialNumbers: PpicPanelCodeSerialNumber[];
  panelCodeType?: string | undefined;
  programTreeTime?: number | undefined;
  productTreeTime?: number | undefined;
  deptTemplateTime?: number | undefined;
  firstPanelCodeWorkOrderDate?: string | undefined;
  firstPanelCodeWorkOrderId?: string | undefined;
  timeWoAssigned?: number | undefined;
  timeWoDone?: number | undefined;
  serialNumberManuals: PpicPanelCodeSerialNumberManual[];
  remarks: PpicPanelCodeRemark[];
  extDefaultQcId?: string | undefined;
  extDefaultQaId?: string | undefined;
  extCrmPurchaseOrderItemId?: string | undefined;
  serialNumberRects: PpicPanelCodeSerialNumberRect[];
  panelCodeDepartmentTemplateItems: PpicPanelCodeDepartmentTemplateItem[];
  panelCodeDepartmentTemplates: PpicPanelCodeDepartmentTemplate[];
  extJdpPresetId?: string | undefined;
  batch?: number | undefined;
  productTreeCount?: number | undefined;
  productTreeDone?: number | undefined;
  programProgress: PpicSimplifiedProcessTypeProgramProgress[];
  extMaterialInstallationItemId?: string | undefined;
  totalDone?: number | undefined;
  totalCount?: number | undefined;
  totalPercent?: number | undefined;
  panelCodeMachineProgramExclusions: PpicMachineProgramExclusion[];
  lastStep?: string | undefined;
}

export interface PpicMachineProgramExclusion {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  simplifiedProcessTypeId?: string | undefined;
  excluded?: boolean | undefined;
}

export interface MeetingExtProblematicPurchaseOrder {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extPurchaseOrderId?: string | undefined;
  isProblematic?: boolean | undefined;
}

export interface MeetingExtProblematicPurchaseOrders {
  purchaseOrders: MeetingExtProblematicPurchaseOrder[];
}

export interface PpicSimplifiedProcessTypeProgramProgress {
  simplifiedProcessTypeId?: string | undefined;
  programTreeCount?: number | undefined;
  programTreeDone?: number | undefined;
}

export interface PpicPanelCodes {
  panelCodes: PpicPanelCode[];
}

export interface CrmDJOs {
  djos: CrmDJO[];
}

export interface CrmDJO {
  value?: string | undefined;
  text?: string | undefined;
  accountId?: string | undefined;
  accountName?: string | undefined;
}

export interface PpicPanelCodeRemark {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  panelCodeId?: string | undefined;
  remark?: string | undefined;
  done?: boolean | undefined;
}

export interface PpicPanelCodeDepartmentTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  panelCode?: PpicPanelCode | undefined;
  panelCodeId?: string | undefined;
  departmentTemplate?: PpicDepartmentTemplate | undefined;
  departmentTemplateId?:
    | string
    | undefined;
  /** repeated PpicDepartmentTemplateItem items=7; */
  departmentTemplateName?: string | undefined;
}

export interface PpicPanelCodeDepartmentTemplateItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  panelCode?: PpicPanelCode | undefined;
  panelCodeId?: string | undefined;
  departmentTemplateItem?: PpicDepartmentTemplateItem | undefined;
  departmentTemplateItemId?: string | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  completedDate?: string | undefined;
  timeProcessMins?: number | undefined;
  excluded?: boolean | undefined;
  numComments?: number | undefined;
  departmentTemplateItemName?: string | undefined;
}

export interface PpicPanelCodeDepartmentTemplateItems {
  departmentTemplateItems: PpicPanelCodeDepartmentTemplateItem[];
}

export interface PpicPanelCodeDepartmentTemplateItemUpdateId {
  panelCodeId?: string | undefined;
  departmentTemplateItemId?: string | undefined;
  status?: PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus | undefined;
  date?:
    | string
    | undefined;
  /** optional uint64 panel_code_department_template_item_id=5; */
  panelCodeDepartmentTemplateItemId?: string | undefined;
}

export enum PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus {
  OUTSTANDING = 0,
  COMPLETED = 1,
  UNRECOGNIZED = -1,
}

export function ppicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatusFromJSON(
  object: any,
): PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus {
  switch (object) {
    case 0:
    case "OUTSTANDING":
      return PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus.OUTSTANDING;
    case 1:
    case "COMPLETED":
      return PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus.COMPLETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus.UNRECOGNIZED;
  }
}

export function ppicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatusToJSON(
  object: PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus,
): string {
  switch (object) {
    case PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus.OUTSTANDING:
      return "OUTSTANDING";
    case PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus.COMPLETED:
      return "COMPLETED";
    case PpicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PpicDeadlineDetail {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  date?: string | undefined;
}

export interface JobExtPanelCodeDepartmentTemplateUpdate {
  outputId?: string | undefined;
  completedDate?: string | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  handleClearCompletedDate?: boolean | undefined;
}

export interface JobExtPanelCodeDepartmentTemplateUpdates {
  updates: JobExtPanelCodeDepartmentTemplateUpdate[];
}

export interface JobExtDepartmentTemplateJobWrapper {
  job?: PpicJob | undefined;
  panelCodes: JobExtDepartmentTemplatePanelCodeWrapper[];
}

export interface JobExtDepartmentTemplatePanelCodeWrapper {
  panelCode?: PpicPanelCode | undefined;
  deptTemplates: PpicPanelCodeDepartmentTemplate[];
  deptTemplateItems: PpicPanelCodeDepartmentTemplateItem[];
}

export interface JobExtDepartmentTemplate {
  data?: JobExtDepartmentTemplateJobWrapper | undefined;
}

export interface JobExtDepartmentTemplates {
  jobs: JobExtDepartmentTemplate[];
}

export interface PpicMaterialRequestSimple {
  id?: string | undefined;
  name?: string | undefined;
  jobName?: string | undefined;
  date?: string | undefined;
  status?: string | undefined;
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  jobId?: string | undefined;
  items: PpicMaterialRequestSimpleItem[];
  type?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  remark?: string | undefined;
}

export interface PpicMaterialRequestSimpleItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extItemId?: string | undefined;
  extInventoryId?: string | undefined;
  qty?: number | undefined;
  extReserveListId?: string | undefined;
}

export interface PpicMaterialRequestsSimple {
  materialRequests: PpicMaterialRequestSimple[];
}

export interface PpicJobs {
  jobs: PpicJob[];
}

export interface PpicDepartmentTemplates {
  templates: PpicDepartmentTemplate[];
}

export interface AuthserverUserShift {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  userId?: string | undefined;
  shift?: boolean | undefined;
  date?: string | undefined;
  shiftTemplateId?: string | undefined;
}

export interface AuthserverUserShifts {
  shifts: AuthserverUserShift[];
}

export interface AuthserverShiftTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  fromHour?: number | undefined;
  fromMins?: number | undefined;
  toHour?: number | undefined;
  toMins?: number | undefined;
  name?: string | undefined;
}

export interface AuthserverShiftTemplates {
  templates: AuthserverShiftTemplate[];
}

export interface PpicDepartmentTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  items: PpicDepartmentTemplateItem[];
  name?: string | undefined;
  panelCode?: PpicPanelCode | undefined;
  panelCodeId?: string | undefined;
  tProcessMins?: number | undefined;
  variable?: boolean | undefined;
  processDays?: number | undefined;
  commonId?: string | undefined;
  extHeadId?: string | undefined;
  extDepartmentId?: string | undefined;
  projectEditable?: boolean | undefined;
  isProject?: boolean | undefined;
  extAppId?: string | undefined;
}

export interface PpicDepartmentTemplateItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  departmentTemplate?: PpicDepartmentTemplate | undefined;
  departmentTemplateId?: string | undefined;
  timeProcessMins?: number | undefined;
  commonId?: string | undefined;
  extRcemSubProcessId?: string | undefined;
}

export interface PpicDepartmentTemplateItemStatus {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  item?: PpicDepartmentTemplateItem | undefined;
  panelCode?: PpicPanelCode | undefined;
  completedDate?: string | undefined;
  departmentTemplateItemId?: string | undefined;
}

export interface PpicDepartmentTemplateVariant {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  departmentTemplateVariantTemplates: PpicDepartmentTemplateVariantTemplate[];
}

export interface PpicDepartmentTemplateVariantTemplate {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  departmentTemplateVariant?: PpicDepartmentTemplateVariant | undefined;
  departmentTemplateVariantId?: string | undefined;
  departmentTemplate?: PpicDepartmentTemplate | undefined;
  departmentTemplateId?: string | undefined;
  departmentTemplateVariantTemplateItems: PpicDepartmentTemplateVariantTemplateItem[];
}

export interface PpicDepartmentTemplateVariantTemplateItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  departmentTemplateVariantTemplate?: PpicDepartmentTemplateVariantTemplate | undefined;
  departmentTemplateVariantTemplateId?: string | undefined;
  departmentTemplateItem?: PpicDepartmentTemplateItem | undefined;
  departmentTemplateItemId?: string | undefined;
}

export interface PpicJobOverview {
  job?: PpicJob | undefined;
  wos?: PpicOverviewElem | undefined;
  taskLists?: MeetingTaskListsView | undefined;
  tasks?: PpicOverviewElem | undefined;
}

export interface PpicOverviewElem {
  total?: number | undefined;
  done?: number | undefined;
}

export interface PpicJobOverviews {
  jobs: PpicJobOverview[];
}

export interface PpicExtDepartmentHiddenJobOverviewCol {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  extDepartmentId?: string | undefined;
}

export interface PpicExtDepartmentHiddenJobOverviewCols {
  hiddenCols: PpicExtDepartmentHiddenJobOverviewCol[];
}

export interface PpicJobBomFlattened {
  job?: PpicJob | undefined;
  bomItems: PpicJobBomFlattenedBomItem[];
  uniqItems: PpicJobBomFlattenedUnique[];
}

export interface PpicJobBomFlattenedBomItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  extItemId?: string | undefined;
  qty?: number | undefined;
}

export interface PpicJobBomFlattenedUnique {
  extItemId?: string | undefined;
  qty?: number | undefined;
}

export interface PpicPanelCodeSerialNumberBodyExternal {
  productId?: string | undefined;
  serialNumbers: PpicPanelCodeSerialNumberExternal[];
}

export interface PpicPanelCodeSerialNumberExternal {
  id?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  jobId?: string | undefined;
  fullName?: string | undefined;
  panelCodeType?: string | undefined;
}

export interface PpicPanelCodeDepartmentTemplatePreset {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  name?: string | undefined;
  depts: PpicPanelCodeDepartmentTemplatePresetDept[];
  items: PpicPanelCodeDepartmentTemplatePresetDeptItem[];
}

export interface PpicPanelCodeDepartmentTemplatePresets {
  presets: PpicPanelCodeDepartmentTemplatePreset[];
}

export interface PpicPanelCodeDepartmentTemplatePresetDept {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  presetId?: string | undefined;
  panelCodeDepartmentTemplateId?: string | undefined;
  deptItems: PpicPanelCodeDepartmentTemplatePresetDeptItem[];
}

export interface PpicPanelCodeDepartmentTemplatePresetDepts {
  depts: PpicPanelCodeDepartmentTemplatePresetDept[];
}

export interface PpicPanelCodeDepartmentTemplatePresetDeptItem {
  masterJavaBaseModel?: MasterJavaBaseModel | undefined;
  presetDeptId?: string | undefined;
  panelCodeDepartmentTemplateItemId?: string | undefined;
  presetId?: string | undefined;
  departmentTemplateItemId?: string | undefined;
}

export interface PpicPanelCodeDepartmentTemplatePresetDeptItems {
  items: PpicPanelCodeDepartmentTemplatePresetDeptItem[];
}

/** Warehouse app */
export interface WarehouseItem {
  id?: string | undefined;
  mfr?: string | undefined;
  partNum?: string | undefined;
  partName?: string | undefined;
  partDesc?: string | undefined;
  defaultUm?: string | undefined;
  type?: WarehouseItem_WarehouseItemType | undefined;
  categoryPartNum?: WarehouseItemCategoryPartNum | undefined;
}

export enum WarehouseItem_WarehouseItemType {
  GSPE = 0,
  CUSTOMER = 2,
  UNRECOGNIZED = -1,
}

export function warehouseItem_WarehouseItemTypeFromJSON(object: any): WarehouseItem_WarehouseItemType {
  switch (object) {
    case 0:
    case "GSPE":
      return WarehouseItem_WarehouseItemType.GSPE;
    case 2:
    case "CUSTOMER":
      return WarehouseItem_WarehouseItemType.CUSTOMER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WarehouseItem_WarehouseItemType.UNRECOGNIZED;
  }
}

export function warehouseItem_WarehouseItemTypeToJSON(object: WarehouseItem_WarehouseItemType): string {
  switch (object) {
    case WarehouseItem_WarehouseItemType.GSPE:
      return "GSPE";
    case WarehouseItem_WarehouseItemType.CUSTOMER:
      return "CUSTOMER";
    case WarehouseItem_WarehouseItemType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WarehouseMaterialRequestSendPostBody {
  jobId?: string | undefined;
  items?: WarehouseMaterialRequestSendPostBodyItem | undefined;
}

export interface WarehouseMaterialRequestSendPostBodyItem {
  id?: string | undefined;
  transactionType?: string | undefined;
  productId?: string | undefined;
  qtyReserve?: string | undefined;
}

export interface WarehouseMaterialRequestPostBody {
  jobId?: string | undefined;
  items?: WarehouseMaterialRequestPostBodyItem | undefined;
}

export interface WarehouseMaterialRequestPostBodyItem {
  id?:
    | string
    | undefined;
  /** mr | rsv */
  transactionType?: string | undefined;
  productId?: string | undefined;
  qtyReserve?: string | undefined;
}

export interface WarehouseMaterialRequestPostBodyItemResponse {
  id?: string | undefined;
  productId?: string | undefined;
  transactionType?: string | undefined;
  jobId?: string | undefined;
  qtyReserve?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
}

export interface WarehouseMaterialRequestPostBodyResponse {
  items: WarehouseMaterialRequestPostBodyItemResponse[];
}

export interface WarehouseReserveList {
  id?:
    | string
    | undefined;
  /** mr | rsv */
  transactionType?: string | undefined;
  jobId?: string | undefined;
  qtyReserve?: string | undefined;
  productId?: string | undefined;
  products?: WarehouseItem | undefined;
}

export interface WarehouseReserveLists {
  reserveLists: WarehouseReserveList[];
}

export interface PurchasingSupplierPurchaseOrder {
  id?: string | undefined;
  productId?: string | undefined;
  products?: MasterEmpty | undefined;
  qtyDelivered?: number | undefined;
  qtyPos?: number | undefined;
}

export interface PurchasingSupplierPurchaseOrders {
  purchaseOrders: PurchasingSupplierPurchaseOrder[];
}

export interface PurchasingPurchaseRequestPostBody {
  prNumber?: string | undefined;
  requestFrom?: string | undefined;
  purpose?: string | undefined;
  purposeRemark?: string | undefined;
  requestMode?: string | undefined;
  prDate?: string | undefined;
  prTarget?: string | undefined;
  createdBy?: string | undefined;
  products: PurchasingPurchaseRequestPostBodyProduct[];
}

export interface PurchasingPurchaseRequestPostBodyProduct {
  productId?: string | undefined;
  mfr?: string | undefined;
  partNum?: string | undefined;
  partName?: string | undefined;
  partDesc?: string | undefined;
  curr?: string | undefined;
  unitCost?: string | undefined;
  qtyPr?: string | undefined;
  umPr?: string | undefined;
}

export interface WarehouseItems {
  items: WarehouseItem[];
}

export interface CrmBoqs {
  boqs: CrmBoq[];
}

export interface CrmBoq {
  id?: number | undefined;
  boqId?: number | undefined;
  boqProductName?: string | undefined;
  type?: string | undefined;
  poProductId?: number | undefined;
  poProductName?: string | undefined;
  poProductNumber?: string | undefined;
  bomProductId?: number | undefined;
  bomProductName?: string | undefined;
  bomProductNumber?: string | undefined;
  createdBy?: number | undefined;
  updatedBy?: number | undefined;
  poId?:
    | number
    | undefined;
  /** Assuming Item is another message that needs to be defined */
  items: CrmBoqItemItem[];
}

export interface CrmBoqItems {
  items: CrmBoqItem[];
}

export interface CrmBoqItem {
  /** Assuming IDs are strings, use int32 or int64 if they are numeric */
  id?: string | undefined;
  requirementId?: string | undefined;
  productId?: string | undefined;
  partNum?: string | undefined;
  partNumGspe?: string | undefined;
  partDesc?: string | undefined;
  mfr?:
    | string
    | undefined;
  /** Unit of Measure */
  um?: string | undefined;
  partName?: string | undefined;
  qty?: number | undefined;
  type?: string | undefined;
  unitPrice?: number | undefined;
  price?: number | undefined;
  netPrice?: number | undefined;
  sellPrice?: number | undefined;
  version?: number | undefined;
  margin?: number | undefined;
  inventory?: boolean | undefined;
  updatedBy?: string | undefined;
  createdBy?:
    | string
    | undefined;
  /** Assuming Item is another message that needs to be defined */
  items: CrmBoqItemItem[];
  equalProductId?: string | undefined;
}

export interface CrmBoqItemItem {
}

/** Define the Item message if items are complex, otherwise change the type accordingly. */
export interface Item {
}

/** CRM */
export interface CrmCustomer {
  value?: string | undefined;
  text?: string | undefined;
}

export interface CrmPurchaseOrder {
  id?: string | undefined;
  purchaseOrderNumber?: string | undefined;
  account?: CrmAccount | undefined;
  products: CrmProduct[];
  date?: string | undefined;
  createdAt?: string | undefined;
  quoteNumber?: string | undefined;
  quoteId?: string | undefined;
  quoteAmount?: number | undefined;
}

export interface CrmBPO {
  id?: string | undefined;
  bpoNumber?: string | undefined;
  account?: CrmAccount | undefined;
  products: CrmProduct[];
  date?: string | undefined;
}

export interface CrmProduct {
  mfr?: string | undefined;
  partDesc?: string | undefined;
  partName?: string | undefined;
  partNumber?: string | undefined;
  partNumberCustomer?: string | undefined;
  productCustomerId?: string | undefined;
  productGspeId?: string | undefined;
  qty?: number | undefined;
  unitMeasure?: string | undefined;
  productId?: string | undefined;
  unitPrice?: number | undefined;
  sequence?: number | undefined;
  siteName?: string | undefined;
  siteId?: string | undefined;
  type?: string | undefined;
}

export interface CrmPurchaseOrders {
  purchaseOrders: CrmPurchaseOrder[];
}

export interface CrmBPOs {
  purchaseOrders: CrmBPO[];
}

export interface CrmPurchaseOrderIdRequest {
  id: string;
}

export interface CrmAccount {
  id?: string | undefined;
  name?: string | undefined;
}

export interface CrmCustomers {
  customers: CrmCustomer[];
}

/** HR */
export interface HrEmployee {
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  hierarchyId?: string | undefined;
  id?: string | undefined;
  contact?: HrContact | undefined;
}

export interface HrContact {
  id?: string | undefined;
  empId?: string | undefined;
  address1?: string | undefined;
  address2?: string | undefined;
  city?: string | undefined;
  province?: string | undefined;
  zipcode?: string | undefined;
  hp?: string | undefined;
  wa?: string | undefined;
  homeTlp?: string | undefined;
  email?: string | undefined;
  workEmail?: string | undefined;
  emcContactName?: string | undefined;
  emcContactPhone?: string | undefined;
  relationship?: string | undefined;
  status?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
}

export interface HrEmployees {
  employees: HrEmployee[];
}

/** Purchasing */
export interface PurchasingPurchaseRequest {
  id?: string | undefined;
  prNumber?: string | undefined;
  prNumberSeq?: string | undefined;
  qsId?: string | undefined;
  requestFrom?: string | undefined;
  requestMode?: string | undefined;
  prDept?: string | undefined;
  prReferenceType?: string | undefined;
  prRequesterId?: string | undefined;
  purpose?: string | undefined;
  purposeRemark?: string | undefined;
  status?: string | undefined;
  prDate?: string | undefined;
  prTarget?: string | undefined;
  rejected?: string | undefined;
  rejectReason?: string | undefined;
  approved?: string | undefined;
  approvedBy?: string | undefined;
  approvedDate?: string | undefined;
  createdBy?: string | undefined;
  modifiedBy?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  null?: string | undefined;
}

export interface PurchasingPurchaseRequests {
  purchaseRequests: PurchasingPurchaseRequest[];
}

export interface PurchasingPurchaseRequestItemOutstanding {
  jobId?: string | undefined;
  mfr?: string | undefined;
  partDesc?: string | undefined;
  partName?: string | undefined;
  partNum?: string | undefined;
  pr?: PurchasingPurchaseRequest | undefined;
  prId?: string | undefined;
  productId?: string | undefined;
  qtyPr?: number | undefined;
  umPr?: string | undefined;
}

export interface PurchasingPurchaseRequestItemsOutstanding {
  purchaseRequestItems: PurchasingPurchaseRequestItemOutstanding[];
}

/** SSO */
export interface SsoUser {
  id?: string | undefined;
  name?: string | undefined;
  username?: string | undefined;
  password?: string | undefined;
  confirmPassword?: string | undefined;
  department?: SsoDepartment | undefined;
  roles: SsoRole[];
}

export interface SsoDepartment {
  id?: string | undefined;
  name?: string | undefined;
}

export interface SsoRole {
  id?: string | undefined;
  name?: string | undefined;
}

export interface SsoAppUsers {
  appName?: string | undefined;
  users: SsoUser[];
}

export interface SsoAppsData {
  app: SsoAppUsers[];
}

export interface SsoAppEntityProto {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  name?: string | undefined;
  ssoUserAppEntities: SsoUserAppEntityProto[];
}

export interface SsoAppEntitiesData {
  ssoAppEntities: SsoAppEntityProto[];
}

export interface SsoUserEntityProto {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  name?: string | undefined;
  username?: string | undefined;
  password?: string | undefined;
  ssoAppEntityId?: string | undefined;
  ssoUserAppEntity: SsoUserAppEntityProto[];
}

export interface SsoUserEntitiesData {
  ssoUserEntities: SsoUserEntityProto[];
}

export interface SsoUserAppEntityProto {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  ssoUserEntityId?: string | undefined;
  ssoUserEntity?: SsoUserEntityProto | undefined;
  ssoAppEntityId?: string | undefined;
  ssoAppEntity?: SsoAppEntityProto | undefined;
  extUserId?: string | undefined;
}

export interface SsoUserAppEntitiesData {
  ssoUserAppEntities: SsoUserAppEntityProto[];
}

export interface SsoUserAppAddBody {
  extUserId?: string | undefined;
  appName?: string | undefined;
  ssoUserId?: string | undefined;
}

export interface SsoUserAppAddHrBody {
  extUserId?: string | undefined;
}

/** Issue tracker */
export interface IssueTrackerIssue {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  issueDescription?: string | undefined;
  foundDate?: string | undefined;
  foundBys: IssueTrackerIssueFoundBy[];
  assignedTos: IssueTrackerIssueAssignedTo[];
  status?: IssueTrackerIssue_IssueTrackerStatus | undefined;
  tags: IssueTrackerIssueTag[];
  tasks: IssueTrackerIssueTask[];
  comments: IssueTrackerIssueComment[];
  issueTags: IssueTrackerIssueIssueTag[];
}

export enum IssueTrackerIssue_IssueTrackerStatus {
  OPEN = 0,
  CLOSED = 1,
  UNRECOGNIZED = -1,
}

export function issueTrackerIssue_IssueTrackerStatusFromJSON(object: any): IssueTrackerIssue_IssueTrackerStatus {
  switch (object) {
    case 0:
    case "OPEN":
      return IssueTrackerIssue_IssueTrackerStatus.OPEN;
    case 1:
    case "CLOSED":
      return IssueTrackerIssue_IssueTrackerStatus.CLOSED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IssueTrackerIssue_IssueTrackerStatus.UNRECOGNIZED;
  }
}

export function issueTrackerIssue_IssueTrackerStatusToJSON(object: IssueTrackerIssue_IssueTrackerStatus): string {
  switch (object) {
    case IssueTrackerIssue_IssueTrackerStatus.OPEN:
      return "OPEN";
    case IssueTrackerIssue_IssueTrackerStatus.CLOSED:
      return "CLOSED";
    case IssueTrackerIssue_IssueTrackerStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface IssueTrackerIssues {
  issues: IssueTrackerIssue[];
}

export interface IssueTrackerIssueFoundBy {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  extGspeUserAppId?: string | undefined;
  remark?: string | undefined;
  issue?: IssueTrackerIssue | undefined;
  issueId?: string | undefined;
}

export interface IssueTrackerIssueFoundBys {
  foundBys: IssueTrackerIssueFoundBy[];
}

export interface IssueTrackerIssueAssignedTo {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  extGspeUserAppId?: string | undefined;
  remark?: string | undefined;
  issue?: IssueTrackerIssue | undefined;
  issueId?: string | undefined;
}

export interface IssueTrackerIssueAssignedTos {
  assignedTos: IssueTrackerIssueAssignedTo[];
}

export interface IssueTrackerIssueTag {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  name?: string | undefined;
  issue?: IssueTrackerIssue | undefined;
  issueId?: string | undefined;
}

export interface IssueTrackerIssueTags {
  tags: IssueTrackerIssueTag[];
}

export interface IssueTrackerIssueTask {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  extMeetingTaskId?: string | undefined;
  issue?: IssueTrackerIssue | undefined;
  issueId?: string | undefined;
  remark?: string | undefined;
}

export interface IssueTrackerIssueTasks {
  tasks: IssueTrackerIssueTask[];
}

export interface IssueTrackerIssueComment {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  extGspeUserAppId?: string | undefined;
  issue?: IssueTrackerIssue | undefined;
  issueId?: string | undefined;
  remark?: string | undefined;
}

export interface IssueTrackerIssueComments {
  comments: IssueTrackerIssueComment[];
}

export interface IssueTrackerIssueIssueTag {
  gormModel?: AuthserverGormModel | undefined;
  baseModel?: AuthserverBaseModel | undefined;
  extGspeUserAppId?: string | undefined;
  issue?: IssueTrackerIssue | undefined;
  issueId?: string | undefined;
  tag?: IssueTrackerIssueTag | undefined;
  tagId?: string | undefined;
}

export interface IssueTrackerIssueIssueTags {
  issueTags: IssueTrackerIssueIssueTag[];
}

export interface IssueTrackerIssuePostBody {
  issue?: IssueTrackerIssue | undefined;
  foundByDeleteIds: string[];
  assignedToDeleteIds: string[];
  tagDeleteIds: string[];
  taskDeleteIds: string[];
  commentDeleteIds: string[];
}

/** Accounting */
export interface AccountingChartOfAccount {
  id?: string | undefined;
  accountNumber?: string | undefined;
  accountName?: string | undefined;
}

export interface AccountingChartOfAccounts {
  chartOfAccounts: AccountingChartOfAccount[];
}

/** Dynaform */
export interface DynaformBaseModel {
  baseModel?: AuthserverBaseModel | undefined;
  gormModel?: AuthserverGormModel | undefined;
}

export interface DynaformForm {
  base?: DynaformBaseModel | undefined;
  name?: string | undefined;
  versions: DynaformFormVersion[];
}

export interface DynaformForms {
  forms: DynaformForm[];
}

export interface DynaformFormVersion {
  base?: DynaformBaseModel | undefined;
  name?: string | undefined;
  form?: DynaformForm | undefined;
  formId?: string | undefined;
  fields: DynaformFormVersionField[];
  documents: DynaformFormDocument[];
}

export interface DynaformFormVersionField {
  base?: DynaformBaseModel | undefined;
  name?: string | undefined;
  fieldName?: string | undefined;
  version?: DynaformFormVersion | undefined;
  versionId?: string | undefined;
  dataType?: DynaformFormVersionField_DataType | undefined;
  checks: DynaformFormVersionFieldCheck[];
}

export enum DynaformFormVersionField_DataType {
  STRING = 0,
  NUMERIC = 1,
  DATE = 2,
  ATTACHMENT = 3,
  TABLE = 4,
  CHECK = 5,
  RADIO = 6,
  UNRECOGNIZED = -1,
}

export function dynaformFormVersionField_DataTypeFromJSON(object: any): DynaformFormVersionField_DataType {
  switch (object) {
    case 0:
    case "STRING":
      return DynaformFormVersionField_DataType.STRING;
    case 1:
    case "NUMERIC":
      return DynaformFormVersionField_DataType.NUMERIC;
    case 2:
    case "DATE":
      return DynaformFormVersionField_DataType.DATE;
    case 3:
    case "ATTACHMENT":
      return DynaformFormVersionField_DataType.ATTACHMENT;
    case 4:
    case "TABLE":
      return DynaformFormVersionField_DataType.TABLE;
    case 5:
    case "CHECK":
      return DynaformFormVersionField_DataType.CHECK;
    case 6:
    case "RADIO":
      return DynaformFormVersionField_DataType.RADIO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DynaformFormVersionField_DataType.UNRECOGNIZED;
  }
}

export function dynaformFormVersionField_DataTypeToJSON(object: DynaformFormVersionField_DataType): string {
  switch (object) {
    case DynaformFormVersionField_DataType.STRING:
      return "STRING";
    case DynaformFormVersionField_DataType.NUMERIC:
      return "NUMERIC";
    case DynaformFormVersionField_DataType.DATE:
      return "DATE";
    case DynaformFormVersionField_DataType.ATTACHMENT:
      return "ATTACHMENT";
    case DynaformFormVersionField_DataType.TABLE:
      return "TABLE";
    case DynaformFormVersionField_DataType.CHECK:
      return "CHECK";
    case DynaformFormVersionField_DataType.RADIO:
      return "RADIO";
    case DynaformFormVersionField_DataType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DynaformFormVersionFieldCheck {
  base?: DynaformBaseModel | undefined;
  name?: string | undefined;
  field?: DynaformFormVersionField | undefined;
  fieldId?: string | undefined;
}

export interface DynaformFormDocument {
  base?: DynaformBaseModel | undefined;
  name?: string | undefined;
  version?: DynaformFormVersion | undefined;
  versionId?: string | undefined;
  values: DynaformFormDocumentValue[];
}

export interface DynaformFormDocumentValue {
  base?: DynaformBaseModel | undefined;
  document?: DynaformFormDocument | undefined;
  documentId?: string | undefined;
  field?: DynaformFormVersionField | undefined;
  fieldId?: string | undefined;
  numericValue?: number | undefined;
  stringValue?: string | undefined;
  name?: string | undefined;
  checks: DynaformFormDocumentValueCheck[];
}

export interface DynaformFormDocumentValueCheck {
  base?: DynaformBaseModel | undefined;
  formDocumentValue?: DynaformFormDocumentValue | undefined;
  formDocumentValueId?: string | undefined;
  name?: string | undefined;
  value?: boolean | undefined;
}

export interface CrmPOOutstandingInvoice {
  id: string;
  invoiceNumber: string;
  percentage: number;
  invoiceDate: number[];
  type: string;
}

export interface CrmPOOutstanding {
  id: string;
  poNumber: string;
  receivedDate: string;
  poDate: string;
  poDueDate: string;
  sites: string[];
  customerName: string;
  poType: string;
  invoices: CrmPOOutstandingInvoice[];
  dos: CrmPOOutstandingDO[];
  /** repeated string dos = 10; // Assuming 'dos' is an array of strings, modify as needed */
  paymentTerm?: string | undefined;
}

export interface CrmPOOutstandings {
  pos: CrmPOOutstanding[];
}

export interface CrmPOOutstandingDO {
  id?: string | undefined;
  doName?: string | undefined;
  refDo?: string | undefined;
  date?: string | undefined;
  products: CrmPOOutstandingDOProduct[];
}

export interface CrmPOOutstandingDOProduct {
  productId?: string | undefined;
  qty?: number | undefined;
}

function createBaseAuthserverGoUserLogin(): AuthserverGoUserLogin {
  return { username: undefined, password: undefined };
}

export const AuthserverGoUserLogin = {
  encode(message: AuthserverGoUserLogin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== undefined) {
      writer.uint32(10).string(message.username);
    }
    if (message.password !== undefined) {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverGoUserLogin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverGoUserLogin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverGoUserLogin {
    return {
      username: isSet(object.username) ? String(object.username) : undefined,
      password: isSet(object.password) ? String(object.password) : undefined,
    };
  },

  toJSON(message: AuthserverGoUserLogin): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverGoUserLogin>, I>>(base?: I): AuthserverGoUserLogin {
    return AuthserverGoUserLogin.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverGoUserLogin>, I>>(object: I): AuthserverGoUserLogin {
    const message = createBaseAuthserverGoUserLogin();
    message.username = object.username ?? undefined;
    message.password = object.password ?? undefined;
    return message;
  },
};

function createBaseAuthserverGoTokenReturn(): AuthserverGoTokenReturn {
  return { token: undefined, error: undefined, errorMessage: undefined };
}

export const AuthserverGoTokenReturn = {
  encode(message: AuthserverGoTokenReturn, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== undefined) {
      writer.uint32(10).string(message.token);
    }
    if (message.error !== undefined) {
      writer.uint32(16).bool(message.error);
    }
    if (message.errorMessage !== undefined) {
      writer.uint32(26).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverGoTokenReturn {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverGoTokenReturn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.error = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverGoTokenReturn {
    return {
      token: isSet(object.token) ? String(object.token) : undefined,
      error: isSet(object.error) ? Boolean(object.error) : undefined,
      errorMessage: isSet(object.errorMessage) ? String(object.errorMessage) : undefined,
    };
  },

  toJSON(message: AuthserverGoTokenReturn): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    message.error !== undefined && (obj.error = message.error);
    message.errorMessage !== undefined && (obj.errorMessage = message.errorMessage);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverGoTokenReturn>, I>>(base?: I): AuthserverGoTokenReturn {
    return AuthserverGoTokenReturn.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverGoTokenReturn>, I>>(object: I): AuthserverGoTokenReturn {
    const message = createBaseAuthserverGoTokenReturn();
    message.token = object.token ?? undefined;
    message.error = object.error ?? undefined;
    message.errorMessage = object.errorMessage ?? undefined;
    return message;
  },
};

function createBaseAuthserverJwt(): AuthserverJwt {
  return { token: undefined };
}

export const AuthserverJwt = {
  encode(message: AuthserverJwt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== undefined) {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverJwt {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverJwt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverJwt {
    return { token: isSet(object.token) ? String(object.token) : undefined };
  },

  toJSON(message: AuthserverJwt): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverJwt>, I>>(base?: I): AuthserverJwt {
    return AuthserverJwt.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverJwt>, I>>(object: I): AuthserverJwt {
    const message = createBaseAuthserverJwt();
    message.token = object.token ?? undefined;
    return message;
  },
};

function createBaseAuthserverEmpty(): AuthserverEmpty {
  return {};
}

export const AuthserverEmpty = {
  encode(_: AuthserverEmpty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverEmpty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AuthserverEmpty {
    return {};
  },

  toJSON(_: AuthserverEmpty): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverEmpty>, I>>(base?: I): AuthserverEmpty {
    return AuthserverEmpty.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverEmpty>, I>>(_: I): AuthserverEmpty {
    const message = createBaseAuthserverEmpty();
    return message;
  },
};

function createBaseMasterEmpty(): MasterEmpty {
  return {};
}

export const MasterEmpty = {
  encode(_: MasterEmpty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MasterEmpty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMasterEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MasterEmpty {
    return {};
  },

  toJSON(_: MasterEmpty): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MasterEmpty>, I>>(base?: I): MasterEmpty {
    return MasterEmpty.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MasterEmpty>, I>>(_: I): MasterEmpty {
    const message = createBaseMasterEmpty();
    return message;
  },
};

function createBaseAuthserverJwtReq(): AuthserverJwtReq {
  return { authserverJwt: undefined };
}

export const AuthserverJwtReq = {
  encode(message: AuthserverJwtReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authserverJwt !== undefined) {
      AuthserverJwt.encode(message.authserverJwt, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverJwtReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverJwtReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authserverJwt = AuthserverJwt.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverJwtReq {
    return { authserverJwt: isSet(object.authserverJwt) ? AuthserverJwt.fromJSON(object.authserverJwt) : undefined };
  },

  toJSON(message: AuthserverJwtReq): unknown {
    const obj: any = {};
    message.authserverJwt !== undefined &&
      (obj.authserverJwt = message.authserverJwt ? AuthserverJwt.toJSON(message.authserverJwt) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverJwtReq>, I>>(base?: I): AuthserverJwtReq {
    return AuthserverJwtReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverJwtReq>, I>>(object: I): AuthserverJwtReq {
    const message = createBaseAuthserverJwtReq();
    message.authserverJwt = (object.authserverJwt !== undefined && object.authserverJwt !== null)
      ? AuthserverJwt.fromPartial(object.authserverJwt)
      : undefined;
    return message;
  },
};

function createBaseMasterJavaBaseModel(): MasterJavaBaseModel {
  return {
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    hidden: undefined,
    extCreatedById: undefined,
    ordering: undefined,
    uuid: undefined,
    changeable: undefined,
    unchangeable: undefined,
  };
}

export const MasterJavaBaseModel = {
  encode(message: MasterJavaBaseModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(18).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(26).string(message.updatedAt);
    }
    if (message.hidden !== undefined) {
      writer.uint32(32).bool(message.hidden);
    }
    if (message.extCreatedById !== undefined) {
      writer.uint32(40).uint64(message.extCreatedById);
    }
    if (message.ordering !== undefined) {
      writer.uint32(48).uint64(message.ordering);
    }
    if (message.uuid !== undefined) {
      writer.uint32(58).string(message.uuid);
    }
    if (message.changeable !== undefined) {
      writer.uint32(64).bool(message.changeable);
    }
    if (message.unchangeable !== undefined) {
      writer.uint32(72).bool(message.unchangeable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MasterJavaBaseModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMasterJavaBaseModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hidden = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extCreatedById = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ordering = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.changeable = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.unchangeable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MasterJavaBaseModel {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      hidden: isSet(object.hidden) ? Boolean(object.hidden) : undefined,
      extCreatedById: isSet(object.extCreatedById) ? String(object.extCreatedById) : undefined,
      ordering: isSet(object.ordering) ? String(object.ordering) : undefined,
      uuid: isSet(object.uuid) ? String(object.uuid) : undefined,
      changeable: isSet(object.changeable) ? Boolean(object.changeable) : undefined,
      unchangeable: isSet(object.unchangeable) ? Boolean(object.unchangeable) : undefined,
    };
  },

  toJSON(message: MasterJavaBaseModel): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.hidden !== undefined && (obj.hidden = message.hidden);
    message.extCreatedById !== undefined && (obj.extCreatedById = message.extCreatedById);
    message.ordering !== undefined && (obj.ordering = message.ordering);
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.changeable !== undefined && (obj.changeable = message.changeable);
    message.unchangeable !== undefined && (obj.unchangeable = message.unchangeable);
    return obj;
  },

  create<I extends Exact<DeepPartial<MasterJavaBaseModel>, I>>(base?: I): MasterJavaBaseModel {
    return MasterJavaBaseModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MasterJavaBaseModel>, I>>(object: I): MasterJavaBaseModel {
    const message = createBaseMasterJavaBaseModel();
    message.id = object.id ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.hidden = object.hidden ?? undefined;
    message.extCreatedById = object.extCreatedById ?? undefined;
    message.ordering = object.ordering ?? undefined;
    message.uuid = object.uuid ?? undefined;
    message.changeable = object.changeable ?? undefined;
    message.unchangeable = object.unchangeable ?? undefined;
    return message;
  },
};

function createBaseMasterJavaBaseModelWrapper(): MasterJavaBaseModelWrapper {
  return { masterJavaBaseModel: undefined };
}

export const MasterJavaBaseModelWrapper = {
  encode(message: MasterJavaBaseModelWrapper, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MasterJavaBaseModelWrapper {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMasterJavaBaseModelWrapper();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MasterJavaBaseModelWrapper {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MasterJavaBaseModelWrapper): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MasterJavaBaseModelWrapper>, I>>(base?: I): MasterJavaBaseModelWrapper {
    return MasterJavaBaseModelWrapper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MasterJavaBaseModelWrapper>, I>>(object: I): MasterJavaBaseModelWrapper {
    const message = createBaseMasterJavaBaseModelWrapper();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseAuthserverGormModel(): AuthserverGormModel {
  return { id: undefined, createdAt: undefined, updatedAt: undefined, deletedAt: undefined };
}

export const AuthserverGormModel = {
  encode(message: AuthserverGormModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(18).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(26).string(message.updatedAt);
    }
    if (message.deletedAt !== undefined) {
      writer.uint32(34).string(message.deletedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverGormModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverGormModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deletedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverGormModel {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      deletedAt: isSet(object.deletedAt) ? String(object.deletedAt) : undefined,
    };
  },

  toJSON(message: AuthserverGormModel): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.deletedAt !== undefined && (obj.deletedAt = message.deletedAt);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverGormModel>, I>>(base?: I): AuthserverGormModel {
    return AuthserverGormModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverGormModel>, I>>(object: I): AuthserverGormModel {
    const message = createBaseAuthserverGormModel();
    message.id = object.id ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deletedAt = object.deletedAt ?? undefined;
    return message;
  },
};

function createBaseAuthserverBaseModel(): AuthserverBaseModel {
  return {
    uuid: undefined,
    ordering: undefined,
    hidden: undefined,
    extCreatedById: undefined,
    changeable: undefined,
    unchangeable: undefined,
  };
}

export const AuthserverBaseModel = {
  encode(message: AuthserverBaseModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== undefined) {
      writer.uint32(10).string(message.uuid);
    }
    if (message.ordering !== undefined) {
      writer.uint32(16).uint64(message.ordering);
    }
    if (message.hidden !== undefined) {
      writer.uint32(24).bool(message.hidden);
    }
    if (message.extCreatedById !== undefined) {
      writer.uint32(32).uint64(message.extCreatedById);
    }
    if (message.changeable !== undefined) {
      writer.uint32(40).bool(message.changeable);
    }
    if (message.unchangeable !== undefined) {
      writer.uint32(48).bool(message.unchangeable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverBaseModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverBaseModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ordering = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hidden = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extCreatedById = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.changeable = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.unchangeable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverBaseModel {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : undefined,
      ordering: isSet(object.ordering) ? String(object.ordering) : undefined,
      hidden: isSet(object.hidden) ? Boolean(object.hidden) : undefined,
      extCreatedById: isSet(object.extCreatedById) ? String(object.extCreatedById) : undefined,
      changeable: isSet(object.changeable) ? Boolean(object.changeable) : undefined,
      unchangeable: isSet(object.unchangeable) ? Boolean(object.unchangeable) : undefined,
    };
  },

  toJSON(message: AuthserverBaseModel): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.ordering !== undefined && (obj.ordering = message.ordering);
    message.hidden !== undefined && (obj.hidden = message.hidden);
    message.extCreatedById !== undefined && (obj.extCreatedById = message.extCreatedById);
    message.changeable !== undefined && (obj.changeable = message.changeable);
    message.unchangeable !== undefined && (obj.unchangeable = message.unchangeable);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverBaseModel>, I>>(base?: I): AuthserverBaseModel {
    return AuthserverBaseModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverBaseModel>, I>>(object: I): AuthserverBaseModel {
    const message = createBaseAuthserverBaseModel();
    message.uuid = object.uuid ?? undefined;
    message.ordering = object.ordering ?? undefined;
    message.hidden = object.hidden ?? undefined;
    message.extCreatedById = object.extCreatedById ?? undefined;
    message.changeable = object.changeable ?? undefined;
    message.unchangeable = object.unchangeable ?? undefined;
    return message;
  },
};

function createBaseAuthserverDepartment(): AuthserverDepartment {
  return { baseModel: undefined, gormModel: undefined, name: undefined, masterJavaBaseModel: undefined };
}

export const AuthserverDepartment = {
  encode(message: AuthserverDepartment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverDepartment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverDepartment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverDepartment {
    return {
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: AuthserverDepartment): unknown {
    const obj: any = {};
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverDepartment>, I>>(base?: I): AuthserverDepartment {
    return AuthserverDepartment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverDepartment>, I>>(object: I): AuthserverDepartment {
    const message = createBaseAuthserverDepartment();
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseAuthserverDepartmentGroup(): AuthserverDepartmentGroup {
  return {
    baseModel: undefined,
    gormModel: undefined,
    name: undefined,
    masterJavaBaseModel: undefined,
    departments: [],
  };
}

export const AuthserverDepartmentGroup = {
  encode(message: AuthserverDepartmentGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.departments) {
      AuthserverDepartment.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverDepartmentGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverDepartmentGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.departments.push(AuthserverDepartment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverDepartmentGroup {
    return {
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      departments: Array.isArray(object?.departments)
        ? object.departments.map((e: any) => AuthserverDepartment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AuthserverDepartmentGroup): unknown {
    const obj: any = {};
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    if (message.departments) {
      obj.departments = message.departments.map((e) => e ? AuthserverDepartment.toJSON(e) : undefined);
    } else {
      obj.departments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverDepartmentGroup>, I>>(base?: I): AuthserverDepartmentGroup {
    return AuthserverDepartmentGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverDepartmentGroup>, I>>(object: I): AuthserverDepartmentGroup {
    const message = createBaseAuthserverDepartmentGroup();
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.departments = object.departments?.map((e) => AuthserverDepartment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAuthserverDepartmentGroups(): AuthserverDepartmentGroups {
  return { groups: [] };
}

export const AuthserverDepartmentGroups = {
  encode(message: AuthserverDepartmentGroups, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      AuthserverDepartmentGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverDepartmentGroups {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverDepartmentGroups();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(AuthserverDepartmentGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverDepartmentGroups {
    return {
      groups: Array.isArray(object?.groups) ? object.groups.map((e: any) => AuthserverDepartmentGroup.fromJSON(e)) : [],
    };
  },

  toJSON(message: AuthserverDepartmentGroups): unknown {
    const obj: any = {};
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? AuthserverDepartmentGroup.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverDepartmentGroups>, I>>(base?: I): AuthserverDepartmentGroups {
    return AuthserverDepartmentGroups.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverDepartmentGroups>, I>>(object: I): AuthserverDepartmentGroups {
    const message = createBaseAuthserverDepartmentGroups();
    message.groups = object.groups?.map((e) => AuthserverDepartmentGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAuthserverDivisionGrouping(): AuthserverDivisionGrouping {
  return { masterJavaBaseModel: undefined, code: undefined };
}

export const AuthserverDivisionGrouping = {
  encode(message: AuthserverDivisionGrouping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.code !== undefined) {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverDivisionGrouping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverDivisionGrouping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverDivisionGrouping {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      code: isSet(object.code) ? String(object.code) : undefined,
    };
  },

  toJSON(message: AuthserverDivisionGrouping): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverDivisionGrouping>, I>>(base?: I): AuthserverDivisionGrouping {
    return AuthserverDivisionGrouping.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverDivisionGrouping>, I>>(object: I): AuthserverDivisionGrouping {
    const message = createBaseAuthserverDivisionGrouping();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.code = object.code ?? undefined;
    return message;
  },
};

function createBaseAuthserverDivisionGroupings(): AuthserverDivisionGroupings {
  return { groupings: [] };
}

export const AuthserverDivisionGroupings = {
  encode(message: AuthserverDivisionGroupings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groupings) {
      AuthserverDivisionGrouping.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverDivisionGroupings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverDivisionGroupings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupings.push(AuthserverDivisionGrouping.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverDivisionGroupings {
    return {
      groupings: Array.isArray(object?.groupings)
        ? object.groupings.map((e: any) => AuthserverDivisionGrouping.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AuthserverDivisionGroupings): unknown {
    const obj: any = {};
    if (message.groupings) {
      obj.groupings = message.groupings.map((e) => e ? AuthserverDivisionGrouping.toJSON(e) : undefined);
    } else {
      obj.groupings = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverDivisionGroupings>, I>>(base?: I): AuthserverDivisionGroupings {
    return AuthserverDivisionGroupings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverDivisionGroupings>, I>>(object: I): AuthserverDivisionGroupings {
    const message = createBaseAuthserverDivisionGroupings();
    message.groupings = object.groupings?.map((e) => AuthserverDivisionGrouping.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAuthserverUser(): AuthserverUser {
  return {
    baseModel: undefined,
    gormModel: undefined,
    address: undefined,
    countryCode: undefined,
    email: undefined,
    name: undefined,
    password: undefined,
    phone: undefined,
    username: undefined,
    departmentId: undefined,
    department: undefined,
    fcmToken: undefined,
    addressLong: undefined,
    iosUdid: undefined,
    iosUser: undefined,
    reportToId: undefined,
    reportTo: undefined,
    deviceUniqueId: undefined,
    isHead: undefined,
    vaccine1: undefined,
    vaccine1Done: undefined,
    vaccine2: undefined,
    vaccine2Done: undefined,
    companyId: undefined,
    bankAccountNumber: undefined,
    bankId: undefined,
    deviceUniqueIdApproval: undefined,
    hrId: undefined,
    roles: [],
    departmentName: undefined,
    authorizationLevel: undefined,
  };
}

export const AuthserverUser = {
  encode(message: AuthserverUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.address !== undefined) {
      writer.uint32(26).string(message.address);
    }
    if (message.countryCode !== undefined) {
      writer.uint32(34).string(message.countryCode);
    }
    if (message.email !== undefined) {
      writer.uint32(42).string(message.email);
    }
    if (message.name !== undefined) {
      writer.uint32(50).string(message.name);
    }
    if (message.password !== undefined) {
      writer.uint32(58).string(message.password);
    }
    if (message.phone !== undefined) {
      writer.uint32(66).string(message.phone);
    }
    if (message.username !== undefined) {
      writer.uint32(74).string(message.username);
    }
    if (message.departmentId !== undefined) {
      writer.uint32(80).uint64(message.departmentId);
    }
    if (message.department !== undefined) {
      AuthserverDepartment.encode(message.department, writer.uint32(90).fork()).ldelim();
    }
    if (message.fcmToken !== undefined) {
      writer.uint32(98).string(message.fcmToken);
    }
    if (message.addressLong !== undefined) {
      writer.uint32(106).string(message.addressLong);
    }
    if (message.iosUdid !== undefined) {
      writer.uint32(114).string(message.iosUdid);
    }
    if (message.iosUser !== undefined) {
      writer.uint32(120).bool(message.iosUser);
    }
    if (message.reportToId !== undefined) {
      writer.uint32(128).uint64(message.reportToId);
    }
    if (message.reportTo !== undefined) {
      AuthserverUser.encode(message.reportTo, writer.uint32(138).fork()).ldelim();
    }
    if (message.deviceUniqueId !== undefined) {
      writer.uint32(146).string(message.deviceUniqueId);
    }
    if (message.isHead !== undefined) {
      writer.uint32(152).bool(message.isHead);
    }
    if (message.vaccine1 !== undefined) {
      writer.uint32(162).string(message.vaccine1);
    }
    if (message.vaccine1Done !== undefined) {
      writer.uint32(168).bool(message.vaccine1Done);
    }
    if (message.vaccine2 !== undefined) {
      writer.uint32(178).string(message.vaccine2);
    }
    if (message.vaccine2Done !== undefined) {
      writer.uint32(184).bool(message.vaccine2Done);
    }
    if (message.companyId !== undefined) {
      writer.uint32(192).uint64(message.companyId);
    }
    if (message.bankAccountNumber !== undefined) {
      writer.uint32(202).string(message.bankAccountNumber);
    }
    if (message.bankId !== undefined) {
      writer.uint32(208).uint64(message.bankId);
    }
    if (message.deviceUniqueIdApproval !== undefined) {
      writer.uint32(218).string(message.deviceUniqueIdApproval);
    }
    if (message.hrId !== undefined) {
      writer.uint32(224).uint64(message.hrId);
    }
    for (const v of message.roles) {
      AuthserverUserRole.encode(v!, writer.uint32(234).fork()).ldelim();
    }
    if (message.departmentName !== undefined) {
      writer.uint32(242).string(message.departmentName);
    }
    if (message.authorizationLevel !== undefined) {
      writer.uint32(248).int32(message.authorizationLevel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.countryCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.password = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.username = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.departmentId = longToString(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.department = AuthserverDepartment.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.fcmToken = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.addressLong = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.iosUdid = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.iosUser = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.reportToId = longToString(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.reportTo = AuthserverUser.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.deviceUniqueId = reader.string();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.isHead = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.vaccine1 = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.vaccine1Done = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.vaccine2 = reader.string();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.vaccine2Done = reader.bool();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.companyId = longToString(reader.uint64() as Long);
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.bankAccountNumber = reader.string();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.bankId = longToString(reader.uint64() as Long);
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.deviceUniqueIdApproval = reader.string();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.hrId = longToString(reader.uint64() as Long);
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.roles.push(AuthserverUserRole.decode(reader, reader.uint32()));
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.departmentName = reader.string();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.authorizationLevel = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverUser {
    return {
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      address: isSet(object.address) ? String(object.address) : undefined,
      countryCode: isSet(object.countryCode) ? String(object.countryCode) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      password: isSet(object.password) ? String(object.password) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      username: isSet(object.username) ? String(object.username) : undefined,
      departmentId: isSet(object.departmentId) ? String(object.departmentId) : undefined,
      department: isSet(object.department) ? AuthserverDepartment.fromJSON(object.department) : undefined,
      fcmToken: isSet(object.fcmToken) ? String(object.fcmToken) : undefined,
      addressLong: isSet(object.addressLong) ? String(object.addressLong) : undefined,
      iosUdid: isSet(object.iosUdid) ? String(object.iosUdid) : undefined,
      iosUser: isSet(object.iosUser) ? Boolean(object.iosUser) : undefined,
      reportToId: isSet(object.reportToId) ? String(object.reportToId) : undefined,
      reportTo: isSet(object.reportTo) ? AuthserverUser.fromJSON(object.reportTo) : undefined,
      deviceUniqueId: isSet(object.deviceUniqueId) ? String(object.deviceUniqueId) : undefined,
      isHead: isSet(object.isHead) ? Boolean(object.isHead) : undefined,
      vaccine1: isSet(object.vaccine1) ? String(object.vaccine1) : undefined,
      vaccine1Done: isSet(object.vaccine1Done) ? Boolean(object.vaccine1Done) : undefined,
      vaccine2: isSet(object.vaccine2) ? String(object.vaccine2) : undefined,
      vaccine2Done: isSet(object.vaccine2Done) ? Boolean(object.vaccine2Done) : undefined,
      companyId: isSet(object.companyId) ? String(object.companyId) : undefined,
      bankAccountNumber: isSet(object.bankAccountNumber) ? String(object.bankAccountNumber) : undefined,
      bankId: isSet(object.bankId) ? String(object.bankId) : undefined,
      deviceUniqueIdApproval: isSet(object.deviceUniqueIdApproval) ? String(object.deviceUniqueIdApproval) : undefined,
      hrId: isSet(object.hrId) ? String(object.hrId) : undefined,
      roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => AuthserverUserRole.fromJSON(e)) : [],
      departmentName: isSet(object.departmentName) ? String(object.departmentName) : undefined,
      authorizationLevel: isSet(object.authorizationLevel)
        ? authserverUser_AuthorizationLevelFromJSON(object.authorizationLevel)
        : undefined,
    };
  },

  toJSON(message: AuthserverUser): unknown {
    const obj: any = {};
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.address !== undefined && (obj.address = message.address);
    message.countryCode !== undefined && (obj.countryCode = message.countryCode);
    message.email !== undefined && (obj.email = message.email);
    message.name !== undefined && (obj.name = message.name);
    message.password !== undefined && (obj.password = message.password);
    message.phone !== undefined && (obj.phone = message.phone);
    message.username !== undefined && (obj.username = message.username);
    message.departmentId !== undefined && (obj.departmentId = message.departmentId);
    message.department !== undefined &&
      (obj.department = message.department ? AuthserverDepartment.toJSON(message.department) : undefined);
    message.fcmToken !== undefined && (obj.fcmToken = message.fcmToken);
    message.addressLong !== undefined && (obj.addressLong = message.addressLong);
    message.iosUdid !== undefined && (obj.iosUdid = message.iosUdid);
    message.iosUser !== undefined && (obj.iosUser = message.iosUser);
    message.reportToId !== undefined && (obj.reportToId = message.reportToId);
    message.reportTo !== undefined &&
      (obj.reportTo = message.reportTo ? AuthserverUser.toJSON(message.reportTo) : undefined);
    message.deviceUniqueId !== undefined && (obj.deviceUniqueId = message.deviceUniqueId);
    message.isHead !== undefined && (obj.isHead = message.isHead);
    message.vaccine1 !== undefined && (obj.vaccine1 = message.vaccine1);
    message.vaccine1Done !== undefined && (obj.vaccine1Done = message.vaccine1Done);
    message.vaccine2 !== undefined && (obj.vaccine2 = message.vaccine2);
    message.vaccine2Done !== undefined && (obj.vaccine2Done = message.vaccine2Done);
    message.companyId !== undefined && (obj.companyId = message.companyId);
    message.bankAccountNumber !== undefined && (obj.bankAccountNumber = message.bankAccountNumber);
    message.bankId !== undefined && (obj.bankId = message.bankId);
    message.deviceUniqueIdApproval !== undefined && (obj.deviceUniqueIdApproval = message.deviceUniqueIdApproval);
    message.hrId !== undefined && (obj.hrId = message.hrId);
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? AuthserverUserRole.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    message.departmentName !== undefined && (obj.departmentName = message.departmentName);
    message.authorizationLevel !== undefined && (obj.authorizationLevel = message.authorizationLevel !== undefined
      ? authserverUser_AuthorizationLevelToJSON(message.authorizationLevel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverUser>, I>>(base?: I): AuthserverUser {
    return AuthserverUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverUser>, I>>(object: I): AuthserverUser {
    const message = createBaseAuthserverUser();
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.address = object.address ?? undefined;
    message.countryCode = object.countryCode ?? undefined;
    message.email = object.email ?? undefined;
    message.name = object.name ?? undefined;
    message.password = object.password ?? undefined;
    message.phone = object.phone ?? undefined;
    message.username = object.username ?? undefined;
    message.departmentId = object.departmentId ?? undefined;
    message.department = (object.department !== undefined && object.department !== null)
      ? AuthserverDepartment.fromPartial(object.department)
      : undefined;
    message.fcmToken = object.fcmToken ?? undefined;
    message.addressLong = object.addressLong ?? undefined;
    message.iosUdid = object.iosUdid ?? undefined;
    message.iosUser = object.iosUser ?? undefined;
    message.reportToId = object.reportToId ?? undefined;
    message.reportTo = (object.reportTo !== undefined && object.reportTo !== null)
      ? AuthserverUser.fromPartial(object.reportTo)
      : undefined;
    message.deviceUniqueId = object.deviceUniqueId ?? undefined;
    message.isHead = object.isHead ?? undefined;
    message.vaccine1 = object.vaccine1 ?? undefined;
    message.vaccine1Done = object.vaccine1Done ?? undefined;
    message.vaccine2 = object.vaccine2 ?? undefined;
    message.vaccine2Done = object.vaccine2Done ?? undefined;
    message.companyId = object.companyId ?? undefined;
    message.bankAccountNumber = object.bankAccountNumber ?? undefined;
    message.bankId = object.bankId ?? undefined;
    message.deviceUniqueIdApproval = object.deviceUniqueIdApproval ?? undefined;
    message.hrId = object.hrId ?? undefined;
    message.roles = object.roles?.map((e) => AuthserverUserRole.fromPartial(e)) || [];
    message.departmentName = object.departmentName ?? undefined;
    message.authorizationLevel = object.authorizationLevel ?? undefined;
    return message;
  },
};

function createBaseAuthserverUserWhatsappSendMessage(): AuthserverUserWhatsappSendMessage {
  return { userIds: [], message: undefined, type: undefined, templateName: undefined };
}

export const AuthserverUserWhatsappSendMessage = {
  encode(message: AuthserverUserWhatsappSendMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.userIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.templateName !== undefined) {
      writer.uint32(34).string(message.templateName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverUserWhatsappSendMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverUserWhatsappSendMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.userIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.userIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.templateName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverUserWhatsappSendMessage {
    return {
      userIds: Array.isArray(object?.userIds) ? object.userIds.map((e: any) => String(e)) : [],
      message: isSet(object.message) ? String(object.message) : undefined,
      type: isSet(object.type) ? authserverUserWhatsappSendMessageTypeFromJSON(object.type) : undefined,
      templateName: isSet(object.templateName) ? String(object.templateName) : undefined,
    };
  },

  toJSON(message: AuthserverUserWhatsappSendMessage): unknown {
    const obj: any = {};
    if (message.userIds) {
      obj.userIds = message.userIds.map((e) => e);
    } else {
      obj.userIds = [];
    }
    message.message !== undefined && (obj.message = message.message);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? authserverUserWhatsappSendMessageTypeToJSON(message.type) : undefined);
    message.templateName !== undefined && (obj.templateName = message.templateName);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverUserWhatsappSendMessage>, I>>(
    base?: I,
  ): AuthserverUserWhatsappSendMessage {
    return AuthserverUserWhatsappSendMessage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverUserWhatsappSendMessage>, I>>(
    object: I,
  ): AuthserverUserWhatsappSendMessage {
    const message = createBaseAuthserverUserWhatsappSendMessage();
    message.userIds = object.userIds?.map((e) => e) || [];
    message.message = object.message ?? undefined;
    message.type = object.type ?? undefined;
    message.templateName = object.templateName ?? undefined;
    return message;
  },
};

function createBaseAuthserverRole(): AuthserverRole {
  return { baseModel: undefined, gormModel: undefined, name: undefined };
}

export const AuthserverRole = {
  encode(message: AuthserverRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverRole {
    return {
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: AuthserverRole): unknown {
    const obj: any = {};
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverRole>, I>>(base?: I): AuthserverRole {
    return AuthserverRole.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverRole>, I>>(object: I): AuthserverRole {
    const message = createBaseAuthserverRole();
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseAuthserverUserRole(): AuthserverUserRole {
  return {
    baseModel: undefined,
    gormModel: undefined,
    userId: undefined,
    user: undefined,
    roleId: undefined,
    role: undefined,
  };
}

export const AuthserverUserRole = {
  encode(message: AuthserverUserRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.userId !== undefined) {
      writer.uint32(24).uint64(message.userId);
    }
    if (message.user !== undefined) {
      AuthserverUser.encode(message.user, writer.uint32(34).fork()).ldelim();
    }
    if (message.roleId !== undefined) {
      writer.uint32(40).uint64(message.roleId);
    }
    if (message.role !== undefined) {
      AuthserverRole.encode(message.role, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverUserRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverUserRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.user = AuthserverUser.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.roleId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.role = AuthserverRole.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverUserRole {
    return {
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      user: isSet(object.user) ? AuthserverUser.fromJSON(object.user) : undefined,
      roleId: isSet(object.roleId) ? String(object.roleId) : undefined,
      role: isSet(object.role) ? AuthserverRole.fromJSON(object.role) : undefined,
    };
  },

  toJSON(message: AuthserverUserRole): unknown {
    const obj: any = {};
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.user !== undefined && (obj.user = message.user ? AuthserverUser.toJSON(message.user) : undefined);
    message.roleId !== undefined && (obj.roleId = message.roleId);
    message.role !== undefined && (obj.role = message.role ? AuthserverRole.toJSON(message.role) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverUserRole>, I>>(base?: I): AuthserverUserRole {
    return AuthserverUserRole.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverUserRole>, I>>(object: I): AuthserverUserRole {
    const message = createBaseAuthserverUserRole();
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.userId = object.userId ?? undefined;
    message.user = (object.user !== undefined && object.user !== null)
      ? AuthserverUser.fromPartial(object.user)
      : undefined;
    message.roleId = object.roleId ?? undefined;
    message.role = (object.role !== undefined && object.role !== null)
      ? AuthserverRole.fromPartial(object.role)
      : undefined;
    return message;
  },
};

function createBaseAuthserverUsers(): AuthserverUsers {
  return { users: [] };
}

export const AuthserverUsers = {
  encode(message: AuthserverUsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      AuthserverUser.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverUsers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverUsers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(AuthserverUser.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverUsers {
    return { users: Array.isArray(object?.users) ? object.users.map((e: any) => AuthserverUser.fromJSON(e)) : [] };
  },

  toJSON(message: AuthserverUsers): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => e ? AuthserverUser.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverUsers>, I>>(base?: I): AuthserverUsers {
    return AuthserverUsers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverUsers>, I>>(object: I): AuthserverUsers {
    const message = createBaseAuthserverUsers();
    message.users = object.users?.map((e) => AuthserverUser.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAuthserverDepartments(): AuthserverDepartments {
  return { departments: [] };
}

export const AuthserverDepartments = {
  encode(message: AuthserverDepartments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.departments) {
      AuthserverDepartment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverDepartments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverDepartments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.departments.push(AuthserverDepartment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverDepartments {
    return {
      departments: Array.isArray(object?.departments)
        ? object.departments.map((e: any) => AuthserverDepartment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AuthserverDepartments): unknown {
    const obj: any = {};
    if (message.departments) {
      obj.departments = message.departments.map((e) => e ? AuthserverDepartment.toJSON(e) : undefined);
    } else {
      obj.departments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverDepartments>, I>>(base?: I): AuthserverDepartments {
    return AuthserverDepartments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverDepartments>, I>>(object: I): AuthserverDepartments {
    const message = createBaseAuthserverDepartments();
    message.departments = object.departments?.map((e) => AuthserverDepartment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAuthserverRoles(): AuthserverRoles {
  return { roles: [] };
}

export const AuthserverRoles = {
  encode(message: AuthserverRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      AuthserverRole.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(AuthserverRole.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverRoles {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => AuthserverRole.fromJSON(e)) : [] };
  },

  toJSON(message: AuthserverRoles): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? AuthserverRole.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverRoles>, I>>(base?: I): AuthserverRoles {
    return AuthserverRoles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverRoles>, I>>(object: I): AuthserverRoles {
    const message = createBaseAuthserverRoles();
    message.roles = object.roles?.map((e) => AuthserverRole.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFcmTokenBody(): UpdateFcmTokenBody {
  return { fcmToken: undefined, jwt: undefined };
}

export const UpdateFcmTokenBody = {
  encode(message: UpdateFcmTokenBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fcmToken !== undefined) {
      writer.uint32(10).string(message.fcmToken);
    }
    if (message.jwt !== undefined) {
      writer.uint32(18).string(message.jwt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFcmTokenBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFcmTokenBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fcmToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.jwt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFcmTokenBody {
    return {
      fcmToken: isSet(object.fcmToken) ? String(object.fcmToken) : undefined,
      jwt: isSet(object.jwt) ? String(object.jwt) : undefined,
    };
  },

  toJSON(message: UpdateFcmTokenBody): unknown {
    const obj: any = {};
    message.fcmToken !== undefined && (obj.fcmToken = message.fcmToken);
    message.jwt !== undefined && (obj.jwt = message.jwt);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFcmTokenBody>, I>>(base?: I): UpdateFcmTokenBody {
    return UpdateFcmTokenBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateFcmTokenBody>, I>>(object: I): UpdateFcmTokenBody {
    const message = createBaseUpdateFcmTokenBody();
    message.fcmToken = object.fcmToken ?? undefined;
    message.jwt = object.jwt ?? undefined;
    return message;
  },
};

function createBaseGetUserPostBody(): GetUserPostBody {
  return { jwt: undefined };
}

export const GetUserPostBody = {
  encode(message: GetUserPostBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jwt !== undefined) {
      writer.uint32(10).string(message.jwt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserPostBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserPostBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jwt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserPostBody {
    return { jwt: isSet(object.jwt) ? String(object.jwt) : undefined };
  },

  toJSON(message: GetUserPostBody): unknown {
    const obj: any = {};
    message.jwt !== undefined && (obj.jwt = message.jwt);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserPostBody>, I>>(base?: I): GetUserPostBody {
    return GetUserPostBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserPostBody>, I>>(object: I): GetUserPostBody {
    const message = createBaseGetUserPostBody();
    message.jwt = object.jwt ?? undefined;
    return message;
  },
};

function createBaseMeetingKpi(): MeetingKpi {
  return { param: undefined, users: [], problemCatalogs: [], deptTotalWos: undefined, deptTotalWosArray: [] };
}

export const MeetingKpi = {
  encode(message: MeetingKpi, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.param !== undefined) {
      MeetingKpiParam.encode(message.param, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.users) {
      MeetingKpiUser.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.problemCatalogs) {
      MeetingKpiUserWorkOrderProblem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.deptTotalWos !== undefined) {
      writer.uint32(32).int32(message.deptTotalWos);
    }
    for (const v of message.deptTotalWosArray) {
      MeetingKpiDeptWos.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpi {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpi();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.param = MeetingKpiParam.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.users.push(MeetingKpiUser.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.problemCatalogs.push(MeetingKpiUserWorkOrderProblem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deptTotalWos = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deptTotalWosArray.push(MeetingKpiDeptWos.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpi {
    return {
      param: isSet(object.param) ? MeetingKpiParam.fromJSON(object.param) : undefined,
      users: Array.isArray(object?.users) ? object.users.map((e: any) => MeetingKpiUser.fromJSON(e)) : [],
      problemCatalogs: Array.isArray(object?.problemCatalogs)
        ? object.problemCatalogs.map((e: any) => MeetingKpiUserWorkOrderProblem.fromJSON(e))
        : [],
      deptTotalWos: isSet(object.deptTotalWos) ? Number(object.deptTotalWos) : undefined,
      deptTotalWosArray: Array.isArray(object?.deptTotalWosArray)
        ? object.deptTotalWosArray.map((e: any) => MeetingKpiDeptWos.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingKpi): unknown {
    const obj: any = {};
    message.param !== undefined && (obj.param = message.param ? MeetingKpiParam.toJSON(message.param) : undefined);
    if (message.users) {
      obj.users = message.users.map((e) => e ? MeetingKpiUser.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    if (message.problemCatalogs) {
      obj.problemCatalogs = message.problemCatalogs.map((e) =>
        e ? MeetingKpiUserWorkOrderProblem.toJSON(e) : undefined
      );
    } else {
      obj.problemCatalogs = [];
    }
    message.deptTotalWos !== undefined && (obj.deptTotalWos = Math.round(message.deptTotalWos));
    if (message.deptTotalWosArray) {
      obj.deptTotalWosArray = message.deptTotalWosArray.map((e) => e ? MeetingKpiDeptWos.toJSON(e) : undefined);
    } else {
      obj.deptTotalWosArray = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpi>, I>>(base?: I): MeetingKpi {
    return MeetingKpi.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpi>, I>>(object: I): MeetingKpi {
    const message = createBaseMeetingKpi();
    message.param = (object.param !== undefined && object.param !== null)
      ? MeetingKpiParam.fromPartial(object.param)
      : undefined;
    message.users = object.users?.map((e) => MeetingKpiUser.fromPartial(e)) || [];
    message.problemCatalogs = object.problemCatalogs?.map((e) => MeetingKpiUserWorkOrderProblem.fromPartial(e)) || [];
    message.deptTotalWos = object.deptTotalWos ?? undefined;
    message.deptTotalWosArray = object.deptTotalWosArray?.map((e) => MeetingKpiDeptWos.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingKpiDeptWos(): MeetingKpiDeptWos {
  return {
    deptId: undefined,
    totalWorkOrders: undefined,
    totalWorkOrdersDone: undefined,
    totalWorkOrdersNoProblem: undefined,
    totalWorkOrdersOverdue: undefined,
    totalWorkOrdersProblemCost: undefined,
    totalWorkOrdersProblemQuality: undefined,
  };
}

export const MeetingKpiDeptWos = {
  encode(message: MeetingKpiDeptWos, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deptId !== undefined) {
      writer.uint32(40).uint64(message.deptId);
    }
    if (message.totalWorkOrders !== undefined) {
      writer.uint32(48).int32(message.totalWorkOrders);
    }
    if (message.totalWorkOrdersDone !== undefined) {
      writer.uint32(56).int32(message.totalWorkOrdersDone);
    }
    if (message.totalWorkOrdersNoProblem !== undefined) {
      writer.uint32(64).int32(message.totalWorkOrdersNoProblem);
    }
    if (message.totalWorkOrdersOverdue !== undefined) {
      writer.uint32(72).int32(message.totalWorkOrdersOverdue);
    }
    if (message.totalWorkOrdersProblemCost !== undefined) {
      writer.uint32(80).int32(message.totalWorkOrdersProblemCost);
    }
    if (message.totalWorkOrdersProblemQuality !== undefined) {
      writer.uint32(88).int32(message.totalWorkOrdersProblemQuality);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpiDeptWos {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpiDeptWos();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 40) {
            break;
          }

          message.deptId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.totalWorkOrders = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.totalWorkOrdersDone = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.totalWorkOrdersNoProblem = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.totalWorkOrdersOverdue = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.totalWorkOrdersProblemCost = reader.int32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.totalWorkOrdersProblemQuality = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpiDeptWos {
    return {
      deptId: isSet(object.deptId) ? String(object.deptId) : undefined,
      totalWorkOrders: isSet(object.totalWorkOrders) ? Number(object.totalWorkOrders) : undefined,
      totalWorkOrdersDone: isSet(object.totalWorkOrdersDone) ? Number(object.totalWorkOrdersDone) : undefined,
      totalWorkOrdersNoProblem: isSet(object.totalWorkOrdersNoProblem)
        ? Number(object.totalWorkOrdersNoProblem)
        : undefined,
      totalWorkOrdersOverdue: isSet(object.totalWorkOrdersOverdue) ? Number(object.totalWorkOrdersOverdue) : undefined,
      totalWorkOrdersProblemCost: isSet(object.totalWorkOrdersProblemCost)
        ? Number(object.totalWorkOrdersProblemCost)
        : undefined,
      totalWorkOrdersProblemQuality: isSet(object.totalWorkOrdersProblemQuality)
        ? Number(object.totalWorkOrdersProblemQuality)
        : undefined,
    };
  },

  toJSON(message: MeetingKpiDeptWos): unknown {
    const obj: any = {};
    message.deptId !== undefined && (obj.deptId = message.deptId);
    message.totalWorkOrders !== undefined && (obj.totalWorkOrders = Math.round(message.totalWorkOrders));
    message.totalWorkOrdersDone !== undefined && (obj.totalWorkOrdersDone = Math.round(message.totalWorkOrdersDone));
    message.totalWorkOrdersNoProblem !== undefined &&
      (obj.totalWorkOrdersNoProblem = Math.round(message.totalWorkOrdersNoProblem));
    message.totalWorkOrdersOverdue !== undefined &&
      (obj.totalWorkOrdersOverdue = Math.round(message.totalWorkOrdersOverdue));
    message.totalWorkOrdersProblemCost !== undefined &&
      (obj.totalWorkOrdersProblemCost = Math.round(message.totalWorkOrdersProblemCost));
    message.totalWorkOrdersProblemQuality !== undefined &&
      (obj.totalWorkOrdersProblemQuality = Math.round(message.totalWorkOrdersProblemQuality));
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpiDeptWos>, I>>(base?: I): MeetingKpiDeptWos {
    return MeetingKpiDeptWos.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpiDeptWos>, I>>(object: I): MeetingKpiDeptWos {
    const message = createBaseMeetingKpiDeptWos();
    message.deptId = object.deptId ?? undefined;
    message.totalWorkOrders = object.totalWorkOrders ?? undefined;
    message.totalWorkOrdersDone = object.totalWorkOrdersDone ?? undefined;
    message.totalWorkOrdersNoProblem = object.totalWorkOrdersNoProblem ?? undefined;
    message.totalWorkOrdersOverdue = object.totalWorkOrdersOverdue ?? undefined;
    message.totalWorkOrdersProblemCost = object.totalWorkOrdersProblemCost ?? undefined;
    message.totalWorkOrdersProblemQuality = object.totalWorkOrdersProblemQuality ?? undefined;
    return message;
  },
};

function createBaseMeetingLastPlanDate(): MeetingLastPlanDate {
  return { extDepartmentId: undefined, date: undefined, earliestDate: undefined, extUserId: undefined };
}

export const MeetingLastPlanDate = {
  encode(message: MeetingLastPlanDate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extDepartmentId !== undefined) {
      writer.uint32(8).uint64(message.extDepartmentId);
    }
    if (message.date !== undefined) {
      writer.uint32(18).string(message.date);
    }
    if (message.earliestDate !== undefined) {
      writer.uint32(26).string(message.earliestDate);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(32).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingLastPlanDate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingLastPlanDate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.date = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.earliestDate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingLastPlanDate {
    return {
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      earliestDate: isSet(object.earliestDate) ? String(object.earliestDate) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: MeetingLastPlanDate): unknown {
    const obj: any = {};
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.date !== undefined && (obj.date = message.date);
    message.earliestDate !== undefined && (obj.earliestDate = message.earliestDate);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingLastPlanDate>, I>>(base?: I): MeetingLastPlanDate {
    return MeetingLastPlanDate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingLastPlanDate>, I>>(object: I): MeetingLastPlanDate {
    const message = createBaseMeetingLastPlanDate();
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.date = object.date ?? undefined;
    message.earliestDate = object.earliestDate ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseMeetingLastPlanDates(): MeetingLastPlanDates {
  return { dates: [] };
}

export const MeetingLastPlanDates = {
  encode(message: MeetingLastPlanDates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dates) {
      MeetingLastPlanDate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingLastPlanDates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingLastPlanDates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dates.push(MeetingLastPlanDate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingLastPlanDates {
    return { dates: Array.isArray(object?.dates) ? object.dates.map((e: any) => MeetingLastPlanDate.fromJSON(e)) : [] };
  },

  toJSON(message: MeetingLastPlanDates): unknown {
    const obj: any = {};
    if (message.dates) {
      obj.dates = message.dates.map((e) => e ? MeetingLastPlanDate.toJSON(e) : undefined);
    } else {
      obj.dates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingLastPlanDates>, I>>(base?: I): MeetingLastPlanDates {
    return MeetingLastPlanDates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingLastPlanDates>, I>>(object: I): MeetingLastPlanDates {
    const message = createBaseMeetingLastPlanDates();
    message.dates = object.dates?.map((e) => MeetingLastPlanDate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingKpiParam(): MeetingKpiParam {
  return {
    from: undefined,
    to: undefined,
    daysCount: undefined,
    lateReferenceHour: undefined,
    lateReferenceMinute: undefined,
    extUserId: undefined,
  };
}

export const MeetingKpiParam = {
  encode(message: MeetingKpiParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.from !== undefined) {
      writer.uint32(10).string(message.from);
    }
    if (message.to !== undefined) {
      writer.uint32(18).string(message.to);
    }
    if (message.daysCount !== undefined) {
      writer.uint32(24).int32(message.daysCount);
    }
    if (message.lateReferenceHour !== undefined) {
      writer.uint32(32).int32(message.lateReferenceHour);
    }
    if (message.lateReferenceMinute !== undefined) {
      writer.uint32(40).int32(message.lateReferenceMinute);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(48).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpiParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpiParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.from = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.daysCount = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lateReferenceHour = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lateReferenceMinute = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpiParam {
    return {
      from: isSet(object.from) ? String(object.from) : undefined,
      to: isSet(object.to) ? String(object.to) : undefined,
      daysCount: isSet(object.daysCount) ? Number(object.daysCount) : undefined,
      lateReferenceHour: isSet(object.lateReferenceHour) ? Number(object.lateReferenceHour) : undefined,
      lateReferenceMinute: isSet(object.lateReferenceMinute) ? Number(object.lateReferenceMinute) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: MeetingKpiParam): unknown {
    const obj: any = {};
    message.from !== undefined && (obj.from = message.from);
    message.to !== undefined && (obj.to = message.to);
    message.daysCount !== undefined && (obj.daysCount = Math.round(message.daysCount));
    message.lateReferenceHour !== undefined && (obj.lateReferenceHour = Math.round(message.lateReferenceHour));
    message.lateReferenceMinute !== undefined && (obj.lateReferenceMinute = Math.round(message.lateReferenceMinute));
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpiParam>, I>>(base?: I): MeetingKpiParam {
    return MeetingKpiParam.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpiParam>, I>>(object: I): MeetingKpiParam {
    const message = createBaseMeetingKpiParam();
    message.from = object.from ?? undefined;
    message.to = object.to ?? undefined;
    message.daysCount = object.daysCount ?? undefined;
    message.lateReferenceHour = object.lateReferenceHour ?? undefined;
    message.lateReferenceMinute = object.lateReferenceMinute ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseMeetingKpiUser(): MeetingKpiUser {
  return { user: undefined, workOrder: undefined, attendance: undefined };
}

export const MeetingKpiUser = {
  encode(message: MeetingKpiUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      AuthserverUser.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.workOrder !== undefined) {
      MeetingKpiUserWorkOrder.encode(message.workOrder, writer.uint32(18).fork()).ldelim();
    }
    if (message.attendance !== undefined) {
      MeetingKpiUserAttendance.encode(message.attendance, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpiUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpiUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = AuthserverUser.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.workOrder = MeetingKpiUserWorkOrder.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.attendance = MeetingKpiUserAttendance.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpiUser {
    return {
      user: isSet(object.user) ? AuthserverUser.fromJSON(object.user) : undefined,
      workOrder: isSet(object.workOrder) ? MeetingKpiUserWorkOrder.fromJSON(object.workOrder) : undefined,
      attendance: isSet(object.attendance) ? MeetingKpiUserAttendance.fromJSON(object.attendance) : undefined,
    };
  },

  toJSON(message: MeetingKpiUser): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user ? AuthserverUser.toJSON(message.user) : undefined);
    message.workOrder !== undefined &&
      (obj.workOrder = message.workOrder ? MeetingKpiUserWorkOrder.toJSON(message.workOrder) : undefined);
    message.attendance !== undefined &&
      (obj.attendance = message.attendance ? MeetingKpiUserAttendance.toJSON(message.attendance) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpiUser>, I>>(base?: I): MeetingKpiUser {
    return MeetingKpiUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpiUser>, I>>(object: I): MeetingKpiUser {
    const message = createBaseMeetingKpiUser();
    message.user = (object.user !== undefined && object.user !== null)
      ? AuthserverUser.fromPartial(object.user)
      : undefined;
    message.workOrder = (object.workOrder !== undefined && object.workOrder !== null)
      ? MeetingKpiUserWorkOrder.fromPartial(object.workOrder)
      : undefined;
    message.attendance = (object.attendance !== undefined && object.attendance !== null)
      ? MeetingKpiUserAttendance.fromPartial(object.attendance)
      : undefined;
    return message;
  },
};

function createBaseMeetingKpiUserWorkOrder(): MeetingKpiUserWorkOrder {
  return {
    totalWorkOrders: undefined,
    completed: undefined,
    completedOnTime: undefined,
    averageDailyWorkingHours: undefined,
    workingMins: undefined,
    workingHours: undefined,
    integrationModuleHours: undefined,
    programTimeHours: undefined,
    rcemTimeHours: undefined,
    durationHours: undefined,
    panelCodeDepartmentTemplateItemTime: undefined,
    numberOfTasksWithProblem: undefined,
    averageDailyWorkingHoursDone: undefined,
  };
}

export const MeetingKpiUserWorkOrder = {
  encode(message: MeetingKpiUserWorkOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.totalWorkOrders !== undefined) {
      writer.uint32(8).int32(message.totalWorkOrders);
    }
    if (message.completed !== undefined) {
      writer.uint32(16).int32(message.completed);
    }
    if (message.completedOnTime !== undefined) {
      writer.uint32(24).int32(message.completedOnTime);
    }
    if (message.averageDailyWorkingHours !== undefined) {
      writer.uint32(33).double(message.averageDailyWorkingHours);
    }
    if (message.workingMins !== undefined) {
      writer.uint32(41).double(message.workingMins);
    }
    if (message.workingHours !== undefined) {
      writer.uint32(49).double(message.workingHours);
    }
    if (message.integrationModuleHours !== undefined) {
      writer.uint32(57).double(message.integrationModuleHours);
    }
    if (message.programTimeHours !== undefined) {
      writer.uint32(65).double(message.programTimeHours);
    }
    if (message.rcemTimeHours !== undefined) {
      writer.uint32(73).double(message.rcemTimeHours);
    }
    if (message.durationHours !== undefined) {
      writer.uint32(81).double(message.durationHours);
    }
    if (message.panelCodeDepartmentTemplateItemTime !== undefined) {
      writer.uint32(89).double(message.panelCodeDepartmentTemplateItemTime);
    }
    if (message.numberOfTasksWithProblem !== undefined) {
      writer.uint32(97).double(message.numberOfTasksWithProblem);
    }
    if (message.averageDailyWorkingHoursDone !== undefined) {
      writer.uint32(105).double(message.averageDailyWorkingHoursDone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpiUserWorkOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpiUserWorkOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.totalWorkOrders = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.completed = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.completedOnTime = reader.int32();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.averageDailyWorkingHours = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.workingMins = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.workingHours = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.integrationModuleHours = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.programTimeHours = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.rcemTimeHours = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.durationHours = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.panelCodeDepartmentTemplateItemTime = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.numberOfTasksWithProblem = reader.double();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.averageDailyWorkingHoursDone = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpiUserWorkOrder {
    return {
      totalWorkOrders: isSet(object.totalWorkOrders) ? Number(object.totalWorkOrders) : undefined,
      completed: isSet(object.completed) ? Number(object.completed) : undefined,
      completedOnTime: isSet(object.completedOnTime) ? Number(object.completedOnTime) : undefined,
      averageDailyWorkingHours: isSet(object.averageDailyWorkingHours)
        ? Number(object.averageDailyWorkingHours)
        : undefined,
      workingMins: isSet(object.workingMins) ? Number(object.workingMins) : undefined,
      workingHours: isSet(object.workingHours) ? Number(object.workingHours) : undefined,
      integrationModuleHours: isSet(object.integrationModuleHours) ? Number(object.integrationModuleHours) : undefined,
      programTimeHours: isSet(object.programTimeHours) ? Number(object.programTimeHours) : undefined,
      rcemTimeHours: isSet(object.rcemTimeHours) ? Number(object.rcemTimeHours) : undefined,
      durationHours: isSet(object.durationHours) ? Number(object.durationHours) : undefined,
      panelCodeDepartmentTemplateItemTime: isSet(object.panelCodeDepartmentTemplateItemTime)
        ? Number(object.panelCodeDepartmentTemplateItemTime)
        : undefined,
      numberOfTasksWithProblem: isSet(object.numberOfTasksWithProblem)
        ? Number(object.numberOfTasksWithProblem)
        : undefined,
      averageDailyWorkingHoursDone: isSet(object.averageDailyWorkingHoursDone)
        ? Number(object.averageDailyWorkingHoursDone)
        : undefined,
    };
  },

  toJSON(message: MeetingKpiUserWorkOrder): unknown {
    const obj: any = {};
    message.totalWorkOrders !== undefined && (obj.totalWorkOrders = Math.round(message.totalWorkOrders));
    message.completed !== undefined && (obj.completed = Math.round(message.completed));
    message.completedOnTime !== undefined && (obj.completedOnTime = Math.round(message.completedOnTime));
    message.averageDailyWorkingHours !== undefined && (obj.averageDailyWorkingHours = message.averageDailyWorkingHours);
    message.workingMins !== undefined && (obj.workingMins = message.workingMins);
    message.workingHours !== undefined && (obj.workingHours = message.workingHours);
    message.integrationModuleHours !== undefined && (obj.integrationModuleHours = message.integrationModuleHours);
    message.programTimeHours !== undefined && (obj.programTimeHours = message.programTimeHours);
    message.rcemTimeHours !== undefined && (obj.rcemTimeHours = message.rcemTimeHours);
    message.durationHours !== undefined && (obj.durationHours = message.durationHours);
    message.panelCodeDepartmentTemplateItemTime !== undefined &&
      (obj.panelCodeDepartmentTemplateItemTime = message.panelCodeDepartmentTemplateItemTime);
    message.numberOfTasksWithProblem !== undefined && (obj.numberOfTasksWithProblem = message.numberOfTasksWithProblem);
    message.averageDailyWorkingHoursDone !== undefined &&
      (obj.averageDailyWorkingHoursDone = message.averageDailyWorkingHoursDone);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpiUserWorkOrder>, I>>(base?: I): MeetingKpiUserWorkOrder {
    return MeetingKpiUserWorkOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpiUserWorkOrder>, I>>(object: I): MeetingKpiUserWorkOrder {
    const message = createBaseMeetingKpiUserWorkOrder();
    message.totalWorkOrders = object.totalWorkOrders ?? undefined;
    message.completed = object.completed ?? undefined;
    message.completedOnTime = object.completedOnTime ?? undefined;
    message.averageDailyWorkingHours = object.averageDailyWorkingHours ?? undefined;
    message.workingMins = object.workingMins ?? undefined;
    message.workingHours = object.workingHours ?? undefined;
    message.integrationModuleHours = object.integrationModuleHours ?? undefined;
    message.programTimeHours = object.programTimeHours ?? undefined;
    message.rcemTimeHours = object.rcemTimeHours ?? undefined;
    message.durationHours = object.durationHours ?? undefined;
    message.panelCodeDepartmentTemplateItemTime = object.panelCodeDepartmentTemplateItemTime ?? undefined;
    message.numberOfTasksWithProblem = object.numberOfTasksWithProblem ?? undefined;
    message.averageDailyWorkingHoursDone = object.averageDailyWorkingHoursDone ?? undefined;
    return message;
  },
};

function createBaseMeetingKpiUserWorkOrderProblem(): MeetingKpiUserWorkOrderProblem {
  return { identifier: undefined, numberOfProblems: undefined };
}

export const MeetingKpiUserWorkOrderProblem = {
  encode(message: MeetingKpiUserWorkOrderProblem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== undefined) {
      writer.uint32(8).uint64(message.identifier);
    }
    if (message.numberOfProblems !== undefined) {
      writer.uint32(16).int32(message.numberOfProblems);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpiUserWorkOrderProblem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpiUserWorkOrderProblem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.identifier = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numberOfProblems = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpiUserWorkOrderProblem {
    return {
      identifier: isSet(object.identifier) ? String(object.identifier) : undefined,
      numberOfProblems: isSet(object.numberOfProblems) ? Number(object.numberOfProblems) : undefined,
    };
  },

  toJSON(message: MeetingKpiUserWorkOrderProblem): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    message.numberOfProblems !== undefined && (obj.numberOfProblems = Math.round(message.numberOfProblems));
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpiUserWorkOrderProblem>, I>>(base?: I): MeetingKpiUserWorkOrderProblem {
    return MeetingKpiUserWorkOrderProblem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpiUserWorkOrderProblem>, I>>(
    object: I,
  ): MeetingKpiUserWorkOrderProblem {
    const message = createBaseMeetingKpiUserWorkOrderProblem();
    message.identifier = object.identifier ?? undefined;
    message.numberOfProblems = object.numberOfProblems ?? undefined;
    return message;
  },
};

function createBaseMeetingKpiUserAttendance(): MeetingKpiUserAttendance {
  return { late: undefined, attendanceNotes: [], checkIns: undefined };
}

export const MeetingKpiUserAttendance = {
  encode(message: MeetingKpiUserAttendance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.late !== undefined) {
      writer.uint32(8).int32(message.late);
    }
    for (const v of message.attendanceNotes) {
      MeetingKpiUserAttendanceNote.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.checkIns !== undefined) {
      writer.uint32(24).int32(message.checkIns);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpiUserAttendance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpiUserAttendance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.late = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.attendanceNotes.push(MeetingKpiUserAttendanceNote.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.checkIns = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpiUserAttendance {
    return {
      late: isSet(object.late) ? Number(object.late) : undefined,
      attendanceNotes: Array.isArray(object?.attendanceNotes)
        ? object.attendanceNotes.map((e: any) => MeetingKpiUserAttendanceNote.fromJSON(e))
        : [],
      checkIns: isSet(object.checkIns) ? Number(object.checkIns) : undefined,
    };
  },

  toJSON(message: MeetingKpiUserAttendance): unknown {
    const obj: any = {};
    message.late !== undefined && (obj.late = Math.round(message.late));
    if (message.attendanceNotes) {
      obj.attendanceNotes = message.attendanceNotes.map((e) => e ? MeetingKpiUserAttendanceNote.toJSON(e) : undefined);
    } else {
      obj.attendanceNotes = [];
    }
    message.checkIns !== undefined && (obj.checkIns = Math.round(message.checkIns));
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpiUserAttendance>, I>>(base?: I): MeetingKpiUserAttendance {
    return MeetingKpiUserAttendance.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpiUserAttendance>, I>>(object: I): MeetingKpiUserAttendance {
    const message = createBaseMeetingKpiUserAttendance();
    message.late = object.late ?? undefined;
    message.attendanceNotes = object.attendanceNotes?.map((e) => MeetingKpiUserAttendanceNote.fromPartial(e)) || [];
    message.checkIns = object.checkIns ?? undefined;
    return message;
  },
};

function createBaseMeetingKpiUserAttendanceNote(): MeetingKpiUserAttendanceNote {
  return { id: undefined, name: undefined, count: undefined };
}

export const MeetingKpiUserAttendanceNote = {
  encode(message: MeetingKpiUserAttendanceNote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.count !== undefined) {
      writer.uint32(24).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingKpiUserAttendanceNote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingKpiUserAttendanceNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingKpiUserAttendanceNote {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      count: isSet(object.count) ? Number(object.count) : undefined,
    };
  },

  toJSON(message: MeetingKpiUserAttendanceNote): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingKpiUserAttendanceNote>, I>>(base?: I): MeetingKpiUserAttendanceNote {
    return MeetingKpiUserAttendanceNote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingKpiUserAttendanceNote>, I>>(object: I): MeetingKpiUserAttendanceNote {
    const message = createBaseMeetingKpiUserAttendanceNote();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.count = object.count ?? undefined;
    return message;
  },
};

function createBaseMeetingEngineeringDocumentRequirementTemplate(): MeetingEngineeringDocumentRequirementTemplate {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const MeetingEngineeringDocumentRequirementTemplate = {
  encode(message: MeetingEngineeringDocumentRequirementTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentRequirementTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentRequirementTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentRequirementTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: MeetingEngineeringDocumentRequirementTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentRequirementTemplate>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentRequirementTemplate {
    return MeetingEngineeringDocumentRequirementTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentRequirementTemplate>, I>>(
    object: I,
  ): MeetingEngineeringDocumentRequirementTemplate {
    const message = createBaseMeetingEngineeringDocumentRequirementTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseMeetingEngineeringDocumentRequirementTemplates(): MeetingEngineeringDocumentRequirementTemplates {
  return { templates: [] };
}

export const MeetingEngineeringDocumentRequirementTemplates = {
  encode(
    message: MeetingEngineeringDocumentRequirementTemplates,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.templates) {
      MeetingEngineeringDocumentRequirementTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentRequirementTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentRequirementTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(MeetingEngineeringDocumentRequirementTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentRequirementTemplates {
    return {
      templates: Array.isArray(object?.templates)
        ? object.templates.map((e: any) => MeetingEngineeringDocumentRequirementTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingEngineeringDocumentRequirementTemplates): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) =>
        e ? MeetingEngineeringDocumentRequirementTemplate.toJSON(e) : undefined
      );
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentRequirementTemplates>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentRequirementTemplates {
    return MeetingEngineeringDocumentRequirementTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentRequirementTemplates>, I>>(
    object: I,
  ): MeetingEngineeringDocumentRequirementTemplates {
    const message = createBaseMeetingEngineeringDocumentRequirementTemplates();
    message.templates = object.templates?.map((e) => MeetingEngineeringDocumentRequirementTemplate.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseMeetingEngineeringDocument(): MeetingEngineeringDocument {
  return {
    masterJavaBaseModel: undefined,
    extJobId: undefined,
    name: undefined,
    extOwnerId: undefined,
    revisions: [],
    toNotifies: [],
    templateId: undefined,
    extPanelCodeId: undefined,
  };
}

export const MeetingEngineeringDocument = {
  encode(message: MeetingEngineeringDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extJobId !== undefined) {
      writer.uint32(16).uint64(message.extJobId);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.extOwnerId !== undefined) {
      writer.uint32(32).uint64(message.extOwnerId);
    }
    for (const v of message.revisions) {
      MeetingEngineeringDocumentRevision.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.toNotifies) {
      MeetingEngineeringDocumentToNotify.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.templateId !== undefined) {
      writer.uint32(56).uint64(message.templateId);
    }
    if (message.extPanelCodeId !== undefined) {
      writer.uint32(64).uint64(message.extPanelCodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extJobId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extOwnerId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.revisions.push(MeetingEngineeringDocumentRevision.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.toNotifies.push(MeetingEngineeringDocumentToNotify.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.templateId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.extPanelCodeId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocument {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extJobId: isSet(object.extJobId) ? String(object.extJobId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      extOwnerId: isSet(object.extOwnerId) ? String(object.extOwnerId) : undefined,
      revisions: Array.isArray(object?.revisions)
        ? object.revisions.map((e: any) => MeetingEngineeringDocumentRevision.fromJSON(e))
        : [],
      toNotifies: Array.isArray(object?.toNotifies)
        ? object.toNotifies.map((e: any) => MeetingEngineeringDocumentToNotify.fromJSON(e))
        : [],
      templateId: isSet(object.templateId) ? String(object.templateId) : undefined,
      extPanelCodeId: isSet(object.extPanelCodeId) ? String(object.extPanelCodeId) : undefined,
    };
  },

  toJSON(message: MeetingEngineeringDocument): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extJobId !== undefined && (obj.extJobId = message.extJobId);
    message.name !== undefined && (obj.name = message.name);
    message.extOwnerId !== undefined && (obj.extOwnerId = message.extOwnerId);
    if (message.revisions) {
      obj.revisions = message.revisions.map((e) => e ? MeetingEngineeringDocumentRevision.toJSON(e) : undefined);
    } else {
      obj.revisions = [];
    }
    if (message.toNotifies) {
      obj.toNotifies = message.toNotifies.map((e) => e ? MeetingEngineeringDocumentToNotify.toJSON(e) : undefined);
    } else {
      obj.toNotifies = [];
    }
    message.templateId !== undefined && (obj.templateId = message.templateId);
    message.extPanelCodeId !== undefined && (obj.extPanelCodeId = message.extPanelCodeId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocument>, I>>(base?: I): MeetingEngineeringDocument {
    return MeetingEngineeringDocument.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocument>, I>>(object: I): MeetingEngineeringDocument {
    const message = createBaseMeetingEngineeringDocument();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extJobId = object.extJobId ?? undefined;
    message.name = object.name ?? undefined;
    message.extOwnerId = object.extOwnerId ?? undefined;
    message.revisions = object.revisions?.map((e) => MeetingEngineeringDocumentRevision.fromPartial(e)) || [];
    message.toNotifies = object.toNotifies?.map((e) => MeetingEngineeringDocumentToNotify.fromPartial(e)) || [];
    message.templateId = object.templateId ?? undefined;
    message.extPanelCodeId = object.extPanelCodeId ?? undefined;
    return message;
  },
};

function createBaseMeetingEngineeringDocumentJobRequirement(): MeetingEngineeringDocumentJobRequirement {
  return { masterJavaBaseModel: undefined, extJobId: undefined, templateId: undefined, required: undefined };
}

export const MeetingEngineeringDocumentJobRequirement = {
  encode(message: MeetingEngineeringDocumentJobRequirement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extJobId !== undefined) {
      writer.uint32(16).uint64(message.extJobId);
    }
    if (message.templateId !== undefined) {
      writer.uint32(32).uint64(message.templateId);
    }
    if (message.required !== undefined) {
      writer.uint32(24).bool(message.required);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentJobRequirement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentJobRequirement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extJobId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.templateId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.required = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentJobRequirement {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extJobId: isSet(object.extJobId) ? String(object.extJobId) : undefined,
      templateId: isSet(object.templateId) ? String(object.templateId) : undefined,
      required: isSet(object.required) ? Boolean(object.required) : undefined,
    };
  },

  toJSON(message: MeetingEngineeringDocumentJobRequirement): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extJobId !== undefined && (obj.extJobId = message.extJobId);
    message.templateId !== undefined && (obj.templateId = message.templateId);
    message.required !== undefined && (obj.required = message.required);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentJobRequirement>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentJobRequirement {
    return MeetingEngineeringDocumentJobRequirement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentJobRequirement>, I>>(
    object: I,
  ): MeetingEngineeringDocumentJobRequirement {
    const message = createBaseMeetingEngineeringDocumentJobRequirement();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extJobId = object.extJobId ?? undefined;
    message.templateId = object.templateId ?? undefined;
    message.required = object.required ?? undefined;
    return message;
  },
};

function createBaseMeetingEngineeringDocumentJobRequirements(): MeetingEngineeringDocumentJobRequirements {
  return { requirements: [] };
}

export const MeetingEngineeringDocumentJobRequirements = {
  encode(message: MeetingEngineeringDocumentJobRequirements, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.requirements) {
      MeetingEngineeringDocumentJobRequirement.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentJobRequirements {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentJobRequirements();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.requirements.push(MeetingEngineeringDocumentJobRequirement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentJobRequirements {
    return {
      requirements: Array.isArray(object?.requirements)
        ? object.requirements.map((e: any) => MeetingEngineeringDocumentJobRequirement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingEngineeringDocumentJobRequirements): unknown {
    const obj: any = {};
    if (message.requirements) {
      obj.requirements = message.requirements.map((e) =>
        e ? MeetingEngineeringDocumentJobRequirement.toJSON(e) : undefined
      );
    } else {
      obj.requirements = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentJobRequirements>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentJobRequirements {
    return MeetingEngineeringDocumentJobRequirements.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentJobRequirements>, I>>(
    object: I,
  ): MeetingEngineeringDocumentJobRequirements {
    const message = createBaseMeetingEngineeringDocumentJobRequirements();
    message.requirements = object.requirements?.map((e) => MeetingEngineeringDocumentJobRequirement.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseMeetingEngineeringDocuments(): MeetingEngineeringDocuments {
  return { documents: [] };
}

export const MeetingEngineeringDocuments = {
  encode(message: MeetingEngineeringDocuments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.documents) {
      MeetingEngineeringDocument.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocuments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocuments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.documents.push(MeetingEngineeringDocument.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocuments {
    return {
      documents: Array.isArray(object?.documents)
        ? object.documents.map((e: any) => MeetingEngineeringDocument.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingEngineeringDocuments): unknown {
    const obj: any = {};
    if (message.documents) {
      obj.documents = message.documents.map((e) => e ? MeetingEngineeringDocument.toJSON(e) : undefined);
    } else {
      obj.documents = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocuments>, I>>(base?: I): MeetingEngineeringDocuments {
    return MeetingEngineeringDocuments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocuments>, I>>(object: I): MeetingEngineeringDocuments {
    const message = createBaseMeetingEngineeringDocuments();
    message.documents = object.documents?.map((e) => MeetingEngineeringDocument.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingEngineeringDocumentRevision(): MeetingEngineeringDocumentRevision {
  return {
    masterJavaBaseModel: undefined,
    engineeringDocumentId: undefined,
    extRevisionOwnerId: undefined,
    fileBase64: undefined,
    fileName: undefined,
  };
}

export const MeetingEngineeringDocumentRevision = {
  encode(message: MeetingEngineeringDocumentRevision, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.engineeringDocumentId !== undefined) {
      writer.uint32(16).uint64(message.engineeringDocumentId);
    }
    if (message.extRevisionOwnerId !== undefined) {
      writer.uint32(24).uint64(message.extRevisionOwnerId);
    }
    if (message.fileBase64 !== undefined) {
      writer.uint32(34).string(message.fileBase64);
    }
    if (message.fileName !== undefined) {
      writer.uint32(42).string(message.fileName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentRevision {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentRevision();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.engineeringDocumentId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extRevisionOwnerId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fileBase64 = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fileName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentRevision {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      engineeringDocumentId: isSet(object.engineeringDocumentId) ? String(object.engineeringDocumentId) : undefined,
      extRevisionOwnerId: isSet(object.extRevisionOwnerId) ? String(object.extRevisionOwnerId) : undefined,
      fileBase64: isSet(object.fileBase64) ? String(object.fileBase64) : undefined,
      fileName: isSet(object.fileName) ? String(object.fileName) : undefined,
    };
  },

  toJSON(message: MeetingEngineeringDocumentRevision): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.engineeringDocumentId !== undefined && (obj.engineeringDocumentId = message.engineeringDocumentId);
    message.extRevisionOwnerId !== undefined && (obj.extRevisionOwnerId = message.extRevisionOwnerId);
    message.fileBase64 !== undefined && (obj.fileBase64 = message.fileBase64);
    message.fileName !== undefined && (obj.fileName = message.fileName);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentRevision>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentRevision {
    return MeetingEngineeringDocumentRevision.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentRevision>, I>>(
    object: I,
  ): MeetingEngineeringDocumentRevision {
    const message = createBaseMeetingEngineeringDocumentRevision();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.engineeringDocumentId = object.engineeringDocumentId ?? undefined;
    message.extRevisionOwnerId = object.extRevisionOwnerId ?? undefined;
    message.fileBase64 = object.fileBase64 ?? undefined;
    message.fileName = object.fileName ?? undefined;
    return message;
  },
};

function createBaseMeetingEngineeringDocumentRevisions(): MeetingEngineeringDocumentRevisions {
  return { revisions: [] };
}

export const MeetingEngineeringDocumentRevisions = {
  encode(message: MeetingEngineeringDocumentRevisions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.revisions) {
      MeetingEngineeringDocumentRevision.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentRevisions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentRevisions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.revisions.push(MeetingEngineeringDocumentRevision.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentRevisions {
    return {
      revisions: Array.isArray(object?.revisions)
        ? object.revisions.map((e: any) => MeetingEngineeringDocumentRevision.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingEngineeringDocumentRevisions): unknown {
    const obj: any = {};
    if (message.revisions) {
      obj.revisions = message.revisions.map((e) => e ? MeetingEngineeringDocumentRevision.toJSON(e) : undefined);
    } else {
      obj.revisions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentRevisions>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentRevisions {
    return MeetingEngineeringDocumentRevisions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentRevisions>, I>>(
    object: I,
  ): MeetingEngineeringDocumentRevisions {
    const message = createBaseMeetingEngineeringDocumentRevisions();
    message.revisions = object.revisions?.map((e) => MeetingEngineeringDocumentRevision.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingEngineeringDocumentToNotify(): MeetingEngineeringDocumentToNotify {
  return { masterJavaBaseModel: undefined, extUserId: undefined, engineeringDocumentId: undefined };
}

export const MeetingEngineeringDocumentToNotify = {
  encode(message: MeetingEngineeringDocumentToNotify, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.engineeringDocumentId !== undefined) {
      writer.uint32(24).uint64(message.engineeringDocumentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentToNotify {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentToNotify();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.engineeringDocumentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentToNotify {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      engineeringDocumentId: isSet(object.engineeringDocumentId) ? String(object.engineeringDocumentId) : undefined,
    };
  },

  toJSON(message: MeetingEngineeringDocumentToNotify): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.engineeringDocumentId !== undefined && (obj.engineeringDocumentId = message.engineeringDocumentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentToNotify>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentToNotify {
    return MeetingEngineeringDocumentToNotify.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentToNotify>, I>>(
    object: I,
  ): MeetingEngineeringDocumentToNotify {
    const message = createBaseMeetingEngineeringDocumentToNotify();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.engineeringDocumentId = object.engineeringDocumentId ?? undefined;
    return message;
  },
};

function createBaseMeetingEngineeringDocumentToNotifies(): MeetingEngineeringDocumentToNotifies {
  return { toNotifies: [] };
}

export const MeetingEngineeringDocumentToNotifies = {
  encode(message: MeetingEngineeringDocumentToNotifies, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.toNotifies) {
      MeetingEngineeringDocumentToNotify.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingEngineeringDocumentToNotifies {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingEngineeringDocumentToNotifies();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.toNotifies.push(MeetingEngineeringDocumentToNotify.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingEngineeringDocumentToNotifies {
    return {
      toNotifies: Array.isArray(object?.toNotifies)
        ? object.toNotifies.map((e: any) => MeetingEngineeringDocumentToNotify.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingEngineeringDocumentToNotifies): unknown {
    const obj: any = {};
    if (message.toNotifies) {
      obj.toNotifies = message.toNotifies.map((e) => e ? MeetingEngineeringDocumentToNotify.toJSON(e) : undefined);
    } else {
      obj.toNotifies = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingEngineeringDocumentToNotifies>, I>>(
    base?: I,
  ): MeetingEngineeringDocumentToNotifies {
    return MeetingEngineeringDocumentToNotifies.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingEngineeringDocumentToNotifies>, I>>(
    object: I,
  ): MeetingEngineeringDocumentToNotifies {
    const message = createBaseMeetingEngineeringDocumentToNotifies();
    message.toNotifies = object.toNotifies?.map((e) => MeetingEngineeringDocumentToNotify.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingDepartmentProblem(): MeetingDepartmentProblem {
  return { masterJavaBaseModel: undefined, extDepartmentId: undefined, type: undefined, alias: undefined };
}

export const MeetingDepartmentProblem = {
  encode(message: MeetingDepartmentProblem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(16).uint64(message.extDepartmentId);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.alias !== undefined) {
      writer.uint32(34).string(message.alias);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingDepartmentProblem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingDepartmentProblem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.alias = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingDepartmentProblem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      type: isSet(object.type) ? taskListDeptProblemTypeFromJSON(object.type) : undefined,
      alias: isSet(object.alias) ? String(object.alias) : undefined,
    };
  },

  toJSON(message: MeetingDepartmentProblem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? taskListDeptProblemTypeToJSON(message.type) : undefined);
    message.alias !== undefined && (obj.alias = message.alias);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingDepartmentProblem>, I>>(base?: I): MeetingDepartmentProblem {
    return MeetingDepartmentProblem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingDepartmentProblem>, I>>(object: I): MeetingDepartmentProblem {
    const message = createBaseMeetingDepartmentProblem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.type = object.type ?? undefined;
    message.alias = object.alias ?? undefined;
    return message;
  },
};

function createBaseMeetingDepartmentProblems(): MeetingDepartmentProblems {
  return { problems: [] };
}

export const MeetingDepartmentProblems = {
  encode(message: MeetingDepartmentProblems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.problems) {
      MeetingDepartmentProblem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingDepartmentProblems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingDepartmentProblems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.problems.push(MeetingDepartmentProblem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingDepartmentProblems {
    return {
      problems: Array.isArray(object?.problems)
        ? object.problems.map((e: any) => MeetingDepartmentProblem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingDepartmentProblems): unknown {
    const obj: any = {};
    if (message.problems) {
      obj.problems = message.problems.map((e) => e ? MeetingDepartmentProblem.toJSON(e) : undefined);
    } else {
      obj.problems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingDepartmentProblems>, I>>(base?: I): MeetingDepartmentProblems {
    return MeetingDepartmentProblems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingDepartmentProblems>, I>>(object: I): MeetingDepartmentProblems {
    const message = createBaseMeetingDepartmentProblems();
    message.problems = object.problems?.map((e) => MeetingDepartmentProblem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingDepartmentProblemMatch(): MeetingDepartmentProblemMatch {
  return { masterJavaBaseModel: undefined, sourceId: undefined, destinationId: undefined };
}

export const MeetingDepartmentProblemMatch = {
  encode(message: MeetingDepartmentProblemMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.sourceId !== undefined) {
      writer.uint32(16).uint64(message.sourceId);
    }
    if (message.destinationId !== undefined) {
      writer.uint32(24).uint64(message.destinationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingDepartmentProblemMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingDepartmentProblemMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sourceId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.destinationId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingDepartmentProblemMatch {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      sourceId: isSet(object.sourceId) ? String(object.sourceId) : undefined,
      destinationId: isSet(object.destinationId) ? String(object.destinationId) : undefined,
    };
  },

  toJSON(message: MeetingDepartmentProblemMatch): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.sourceId !== undefined && (obj.sourceId = message.sourceId);
    message.destinationId !== undefined && (obj.destinationId = message.destinationId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingDepartmentProblemMatch>, I>>(base?: I): MeetingDepartmentProblemMatch {
    return MeetingDepartmentProblemMatch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingDepartmentProblemMatch>, I>>(
    object: I,
  ): MeetingDepartmentProblemMatch {
    const message = createBaseMeetingDepartmentProblemMatch();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.sourceId = object.sourceId ?? undefined;
    message.destinationId = object.destinationId ?? undefined;
    return message;
  },
};

function createBaseMeetingDepartmentProblemMatches(): MeetingDepartmentProblemMatches {
  return { matches: [] };
}

export const MeetingDepartmentProblemMatches = {
  encode(message: MeetingDepartmentProblemMatches, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.matches) {
      MeetingDepartmentProblemMatch.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingDepartmentProblemMatches {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingDepartmentProblemMatches();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.matches.push(MeetingDepartmentProblemMatch.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingDepartmentProblemMatches {
    return {
      matches: Array.isArray(object?.matches)
        ? object.matches.map((e: any) => MeetingDepartmentProblemMatch.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingDepartmentProblemMatches): unknown {
    const obj: any = {};
    if (message.matches) {
      obj.matches = message.matches.map((e) => e ? MeetingDepartmentProblemMatch.toJSON(e) : undefined);
    } else {
      obj.matches = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingDepartmentProblemMatches>, I>>(base?: I): MeetingDepartmentProblemMatches {
    return MeetingDepartmentProblemMatches.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingDepartmentProblemMatches>, I>>(
    object: I,
  ): MeetingDepartmentProblemMatches {
    const message = createBaseMeetingDepartmentProblemMatches();
    message.matches = object.matches?.map((e) => MeetingDepartmentProblemMatch.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingTaskList(): MeetingTaskList {
  return {
    name: undefined,
    extInChargeId: undefined,
    start: undefined,
    deadline: undefined,
    projectName: undefined,
    projectId: undefined,
    subProject: undefined,
    subProjectId: undefined,
    meetingTasks: [],
    taskListTargetDates: [],
    status: undefined,
    taskListInCharges: [],
    taskListSites: [],
    isWoInstaller: undefined,
    extCustomerId: undefined,
    taskListTemplate: undefined,
    taskListTemplateId: undefined,
    taskListCashAdvancements: [],
    taskListCashSettlements: [],
    taskListCashAdvancement: undefined,
    taskListCashAdvancementId: undefined,
    rateStars: undefined,
    completedDate: undefined,
    projectPo: undefined,
    projectPoId: undefined,
    taskListCashAdvancementWorkOrders: [],
    purpose: undefined,
    extJobId: undefined,
    extSerialNumber: undefined,
    extModuleId: undefined,
    extSubModuleId: undefined,
    extSetId: undefined,
    extProgramId: undefined,
    extPanelCodeId: undefined,
    extSupplierId: undefined,
    extPurchaseOrderId: undefined,
    extMrId: undefined,
    extSubProcessId: undefined,
    masterJavaBaseModel: undefined,
    extIntegrationModuleQty: undefined,
    extSubProcessCommonId: undefined,
    extIntegrationModuleQtyActual: undefined,
    extProgramRunQty: undefined,
    extProcessTypeId: undefined,
    extRcemSubProcessId: undefined,
    extMachineId: undefined,
    extWoInChargeCompletedDate: undefined,
    workOrderBeginTimestamp: undefined,
    workOrderStopTimestamp: undefined,
    extOperatorWorkOrderBeginId: undefined,
    extOperatorWorkOrderStopId: undefined,
    extWorkingTimeMins: undefined,
    extProgramRunQtyActual: undefined,
    references: [],
    source: undefined,
    extDepartmentId: undefined,
    deptCategory: undefined,
    extPanelCodeSerialNumberId: undefined,
    taskStart: undefined,
    taskEnd: undefined,
    meetingId: undefined,
    meeting: undefined,
    extManufacturingCustomerId: undefined,
    totalTimeHours: undefined,
    miscSettlements: [],
    isSurvey: undefined,
    logs: [],
    extMiddlewareTransactionTypeId: undefined,
    deliveryAddress: undefined,
    deliveryPic: undefined,
    docDoPo: undefined,
    docDoPenambahan: undefined,
    docDoPeminjaman: undefined,
    docDoTitipan: undefined,
    docDoReplacement: undefined,
    serialNumberOk: undefined,
    deliveryStatusOk: undefined,
    workDescString: undefined,
    deliveryByLand: undefined,
    deliveryBySea: undefined,
    deliveryByAir: undefined,
    deliveryByJneJnt: undefined,
    deliveryExtItemId: undefined,
    deliveryQty: undefined,
    deliveryInvoiceIsNeeded: undefined,
    deliveryRemark: undefined,
    extDeliveryItems: [],
    reminderDays: undefined,
    deliveryTypePoBpo: undefined,
    extBpoId: undefined,
    isOkrKpi: undefined,
    weight: undefined,
    okrMeetingTaskParentId: undefined,
    extMaterialRequestId: undefined,
    extBomLeveledId: undefined,
    abcReferenceId: undefined,
  };
}

export const MeetingTaskList = {
  encode(message: MeetingTaskList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.extInChargeId !== undefined) {
      writer.uint32(16).uint64(message.extInChargeId);
    }
    if (message.start !== undefined) {
      writer.uint32(26).string(message.start);
    }
    if (message.deadline !== undefined) {
      writer.uint32(34).string(message.deadline);
    }
    if (message.projectName !== undefined) {
      writer.uint32(42).string(message.projectName);
    }
    if (message.projectId !== undefined) {
      writer.uint32(48).uint64(message.projectId);
    }
    if (message.subProject !== undefined) {
      MeetingSubProject.encode(message.subProject, writer.uint32(66).fork()).ldelim();
    }
    if (message.subProjectId !== undefined) {
      writer.uint32(72).uint64(message.subProjectId);
    }
    for (const v of message.meetingTasks) {
      MeetingMeetingTask.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.taskListTargetDates) {
      MeetingTaskListTargetDate.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(98).string(message.status);
    }
    for (const v of message.taskListInCharges) {
      MeetingTaskListInCharge.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.taskListSites) {
      MeetingTaskListSites.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.isWoInstaller !== undefined) {
      writer.uint32(208).bool(message.isWoInstaller);
    }
    if (message.extCustomerId !== undefined) {
      writer.uint32(120).uint64(message.extCustomerId);
    }
    if (message.taskListTemplate !== undefined) {
      TaskListTemplate.encode(message.taskListTemplate, writer.uint32(130).fork()).ldelim();
    }
    if (message.taskListTemplateId !== undefined) {
      writer.uint32(136).uint64(message.taskListTemplateId);
    }
    for (const v of message.taskListCashAdvancements) {
      MeetingTaskListCashAdvancement.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.taskListCashSettlements) {
      MeetingTaskListCashSettlement.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    if (message.taskListCashAdvancement !== undefined) {
      MeetingTaskListCashAdvancement.encode(message.taskListCashAdvancement, writer.uint32(218).fork()).ldelim();
    }
    if (message.taskListCashAdvancementId !== undefined) {
      writer.uint32(224).uint64(message.taskListCashAdvancementId);
    }
    if (message.rateStars !== undefined) {
      writer.uint32(160).int32(message.rateStars);
    }
    if (message.completedDate !== undefined) {
      writer.uint32(170).string(message.completedDate);
    }
    if (message.projectPo !== undefined) {
      MeetingTaskProjectPo.encode(message.projectPo, writer.uint32(178).fork()).ldelim();
    }
    if (message.projectPoId !== undefined) {
      writer.uint32(184).uint64(message.projectPoId);
    }
    for (const v of message.taskListCashAdvancementWorkOrders) {
      MeetingTaskListCashAdvancementWorkOrder.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    if (message.purpose !== undefined) {
      writer.uint32(202).string(message.purpose);
    }
    if (message.extJobId !== undefined) {
      writer.uint32(232).uint64(message.extJobId);
    }
    if (message.extSerialNumber !== undefined) {
      writer.uint32(242).string(message.extSerialNumber);
    }
    if (message.extModuleId !== undefined) {
      writer.uint32(248).uint64(message.extModuleId);
    }
    if (message.extSubModuleId !== undefined) {
      writer.uint32(256).uint64(message.extSubModuleId);
    }
    if (message.extSetId !== undefined) {
      writer.uint32(264).uint64(message.extSetId);
    }
    if (message.extProgramId !== undefined) {
      writer.uint32(272).uint64(message.extProgramId);
    }
    if (message.extPanelCodeId !== undefined) {
      writer.uint32(280).uint64(message.extPanelCodeId);
    }
    if (message.extSupplierId !== undefined) {
      writer.uint32(288).uint64(message.extSupplierId);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(296).uint64(message.extPurchaseOrderId);
    }
    if (message.extMrId !== undefined) {
      writer.uint32(304).uint64(message.extMrId);
    }
    if (message.extSubProcessId !== undefined) {
      writer.uint32(312).uint64(message.extSubProcessId);
    }
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(322).fork()).ldelim();
    }
    if (message.extIntegrationModuleQty !== undefined) {
      writer.uint32(328).int32(message.extIntegrationModuleQty);
    }
    if (message.extSubProcessCommonId !== undefined) {
      writer.uint32(338).string(message.extSubProcessCommonId);
    }
    if (message.extIntegrationModuleQtyActual !== undefined) {
      writer.uint32(344).int32(message.extIntegrationModuleQtyActual);
    }
    if (message.extProgramRunQty !== undefined) {
      writer.uint32(352).int32(message.extProgramRunQty);
    }
    if (message.extProcessTypeId !== undefined) {
      writer.uint32(360).uint64(message.extProcessTypeId);
    }
    if (message.extRcemSubProcessId !== undefined) {
      writer.uint32(368).uint64(message.extRcemSubProcessId);
    }
    if (message.extMachineId !== undefined) {
      writer.uint32(376).uint64(message.extMachineId);
    }
    if (message.extWoInChargeCompletedDate !== undefined) {
      writer.uint32(386).string(message.extWoInChargeCompletedDate);
    }
    if (message.workOrderBeginTimestamp !== undefined) {
      writer.uint32(394).string(message.workOrderBeginTimestamp);
    }
    if (message.workOrderStopTimestamp !== undefined) {
      writer.uint32(402).string(message.workOrderStopTimestamp);
    }
    if (message.extOperatorWorkOrderBeginId !== undefined) {
      writer.uint32(408).uint64(message.extOperatorWorkOrderBeginId);
    }
    if (message.extOperatorWorkOrderStopId !== undefined) {
      writer.uint32(416).uint64(message.extOperatorWorkOrderStopId);
    }
    if (message.extWorkingTimeMins !== undefined) {
      writer.uint32(424).int32(message.extWorkingTimeMins);
    }
    if (message.extProgramRunQtyActual !== undefined) {
      writer.uint32(432).int32(message.extProgramRunQtyActual);
    }
    for (const v of message.references) {
      MeetingTaskListReference.encode(v!, writer.uint32(442).fork()).ldelim();
    }
    if (message.source !== undefined) {
      writer.uint32(448).int32(message.source);
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(456).uint64(message.extDepartmentId);
    }
    if (message.deptCategory !== undefined) {
      writer.uint32(464).int32(message.deptCategory);
    }
    if (message.extPanelCodeSerialNumberId !== undefined) {
      writer.uint32(472).uint64(message.extPanelCodeSerialNumberId);
    }
    if (message.taskStart !== undefined) {
      writer.uint32(482).string(message.taskStart);
    }
    if (message.taskEnd !== undefined) {
      writer.uint32(490).string(message.taskEnd);
    }
    if (message.meetingId !== undefined) {
      writer.uint32(496).uint64(message.meetingId);
    }
    if (message.meeting !== undefined) {
      MeetingMeeting.encode(message.meeting, writer.uint32(506).fork()).ldelim();
    }
    if (message.extManufacturingCustomerId !== undefined) {
      writer.uint32(512).uint64(message.extManufacturingCustomerId);
    }
    if (message.totalTimeHours !== undefined) {
      writer.uint32(521).double(message.totalTimeHours);
    }
    for (const v of message.miscSettlements) {
      MeetingTaskListMiscellaneousSettlement.encode(v!, writer.uint32(530).fork()).ldelim();
    }
    if (message.isSurvey !== undefined) {
      writer.uint32(536).bool(message.isSurvey);
    }
    for (const v of message.logs) {
      MeetingTaskListChangeLog.encode(v!, writer.uint32(546).fork()).ldelim();
    }
    if (message.extMiddlewareTransactionTypeId !== undefined) {
      writer.uint32(552).uint64(message.extMiddlewareTransactionTypeId);
    }
    if (message.deliveryAddress !== undefined) {
      writer.uint32(562).string(message.deliveryAddress);
    }
    if (message.deliveryPic !== undefined) {
      writer.uint32(570).string(message.deliveryPic);
    }
    if (message.docDoPo !== undefined) {
      writer.uint32(576).bool(message.docDoPo);
    }
    if (message.docDoPenambahan !== undefined) {
      writer.uint32(584).bool(message.docDoPenambahan);
    }
    if (message.docDoPeminjaman !== undefined) {
      writer.uint32(592).bool(message.docDoPeminjaman);
    }
    if (message.docDoTitipan !== undefined) {
      writer.uint32(600).bool(message.docDoTitipan);
    }
    if (message.docDoReplacement !== undefined) {
      writer.uint32(608).bool(message.docDoReplacement);
    }
    if (message.serialNumberOk !== undefined) {
      writer.uint32(616).bool(message.serialNumberOk);
    }
    if (message.deliveryStatusOk !== undefined) {
      writer.uint32(624).bool(message.deliveryStatusOk);
    }
    if (message.workDescString !== undefined) {
      writer.uint32(634).string(message.workDescString);
    }
    if (message.deliveryByLand !== undefined) {
      writer.uint32(640).bool(message.deliveryByLand);
    }
    if (message.deliveryBySea !== undefined) {
      writer.uint32(648).bool(message.deliveryBySea);
    }
    if (message.deliveryByAir !== undefined) {
      writer.uint32(656).bool(message.deliveryByAir);
    }
    if (message.deliveryByJneJnt !== undefined) {
      writer.uint32(664).bool(message.deliveryByJneJnt);
    }
    if (message.deliveryExtItemId !== undefined) {
      writer.uint32(672).int64(message.deliveryExtItemId);
    }
    if (message.deliveryQty !== undefined) {
      writer.uint32(681).double(message.deliveryQty);
    }
    if (message.deliveryInvoiceIsNeeded !== undefined) {
      writer.uint32(688).bool(message.deliveryInvoiceIsNeeded);
    }
    if (message.deliveryRemark !== undefined) {
      writer.uint32(698).string(message.deliveryRemark);
    }
    for (const v of message.extDeliveryItems) {
      MeetingTaskListExtDeliveryItem.encode(v!, writer.uint32(706).fork()).ldelim();
    }
    if (message.reminderDays !== undefined) {
      writer.uint32(712).int32(message.reminderDays);
    }
    if (message.deliveryTypePoBpo !== undefined) {
      writer.uint32(720).int32(message.deliveryTypePoBpo);
    }
    if (message.extBpoId !== undefined) {
      writer.uint32(728).uint64(message.extBpoId);
    }
    if (message.isOkrKpi !== undefined) {
      writer.uint32(736).bool(message.isOkrKpi);
    }
    if (message.weight !== undefined) {
      writer.uint32(744).int32(message.weight);
    }
    if (message.okrMeetingTaskParentId !== undefined) {
      writer.uint32(752).uint64(message.okrMeetingTaskParentId);
    }
    if (message.extMaterialRequestId !== undefined) {
      writer.uint32(760).uint64(message.extMaterialRequestId);
    }
    if (message.extBomLeveledId !== undefined) {
      writer.uint32(768).uint64(message.extBomLeveledId);
    }
    if (message.abcReferenceId !== undefined) {
      writer.uint32(776).uint64(message.abcReferenceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extInChargeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.start = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deadline = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.projectName = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.projectId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.subProject = MeetingSubProject.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.subProjectId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.meetingTasks.push(MeetingMeetingTask.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.taskListTargetDates.push(MeetingTaskListTargetDate.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.status = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.taskListInCharges.push(MeetingTaskListInCharge.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.taskListSites.push(MeetingTaskListSites.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.isWoInstaller = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.extCustomerId = longToString(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.taskListTemplate = TaskListTemplate.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.taskListTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.taskListCashAdvancements.push(MeetingTaskListCashAdvancement.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.taskListCashSettlements.push(MeetingTaskListCashSettlement.decode(reader, reader.uint32()));
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.taskListCashAdvancement = MeetingTaskListCashAdvancement.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.taskListCashAdvancementId = longToString(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.rateStars = reader.int32();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.completedDate = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.projectPo = MeetingTaskProjectPo.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.projectPoId = longToString(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.taskListCashAdvancementWorkOrders.push(
            MeetingTaskListCashAdvancementWorkOrder.decode(reader, reader.uint32()),
          );
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.purpose = reader.string();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.extJobId = longToString(reader.uint64() as Long);
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.extSerialNumber = reader.string();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.extModuleId = longToString(reader.uint64() as Long);
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.extSubModuleId = longToString(reader.uint64() as Long);
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.extSetId = longToString(reader.uint64() as Long);
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.extProgramId = longToString(reader.uint64() as Long);
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.extPanelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.extSupplierId = longToString(reader.uint64() as Long);
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.extMrId = longToString(reader.uint64() as Long);
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.extSubProcessId = longToString(reader.uint64() as Long);
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 328) {
            break;
          }

          message.extIntegrationModuleQty = reader.int32();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.extSubProcessCommonId = reader.string();
          continue;
        case 43:
          if (tag !== 344) {
            break;
          }

          message.extIntegrationModuleQtyActual = reader.int32();
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.extProgramRunQty = reader.int32();
          continue;
        case 45:
          if (tag !== 360) {
            break;
          }

          message.extProcessTypeId = longToString(reader.uint64() as Long);
          continue;
        case 46:
          if (tag !== 368) {
            break;
          }

          message.extRcemSubProcessId = longToString(reader.uint64() as Long);
          continue;
        case 47:
          if (tag !== 376) {
            break;
          }

          message.extMachineId = longToString(reader.uint64() as Long);
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.extWoInChargeCompletedDate = reader.string();
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.workOrderBeginTimestamp = reader.string();
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.workOrderStopTimestamp = reader.string();
          continue;
        case 51:
          if (tag !== 408) {
            break;
          }

          message.extOperatorWorkOrderBeginId = longToString(reader.uint64() as Long);
          continue;
        case 52:
          if (tag !== 416) {
            break;
          }

          message.extOperatorWorkOrderStopId = longToString(reader.uint64() as Long);
          continue;
        case 53:
          if (tag !== 424) {
            break;
          }

          message.extWorkingTimeMins = reader.int32();
          continue;
        case 54:
          if (tag !== 432) {
            break;
          }

          message.extProgramRunQtyActual = reader.int32();
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.references.push(MeetingTaskListReference.decode(reader, reader.uint32()));
          continue;
        case 56:
          if (tag !== 448) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 57:
          if (tag !== 456) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 58:
          if (tag !== 464) {
            break;
          }

          message.deptCategory = reader.int32() as any;
          continue;
        case 59:
          if (tag !== 472) {
            break;
          }

          message.extPanelCodeSerialNumberId = longToString(reader.uint64() as Long);
          continue;
        case 60:
          if (tag !== 482) {
            break;
          }

          message.taskStart = reader.string();
          continue;
        case 61:
          if (tag !== 490) {
            break;
          }

          message.taskEnd = reader.string();
          continue;
        case 62:
          if (tag !== 496) {
            break;
          }

          message.meetingId = longToString(reader.uint64() as Long);
          continue;
        case 63:
          if (tag !== 506) {
            break;
          }

          message.meeting = MeetingMeeting.decode(reader, reader.uint32());
          continue;
        case 64:
          if (tag !== 512) {
            break;
          }

          message.extManufacturingCustomerId = longToString(reader.uint64() as Long);
          continue;
        case 65:
          if (tag !== 521) {
            break;
          }

          message.totalTimeHours = reader.double();
          continue;
        case 66:
          if (tag !== 530) {
            break;
          }

          message.miscSettlements.push(MeetingTaskListMiscellaneousSettlement.decode(reader, reader.uint32()));
          continue;
        case 67:
          if (tag !== 536) {
            break;
          }

          message.isSurvey = reader.bool();
          continue;
        case 68:
          if (tag !== 546) {
            break;
          }

          message.logs.push(MeetingTaskListChangeLog.decode(reader, reader.uint32()));
          continue;
        case 69:
          if (tag !== 552) {
            break;
          }

          message.extMiddlewareTransactionTypeId = longToString(reader.uint64() as Long);
          continue;
        case 70:
          if (tag !== 562) {
            break;
          }

          message.deliveryAddress = reader.string();
          continue;
        case 71:
          if (tag !== 570) {
            break;
          }

          message.deliveryPic = reader.string();
          continue;
        case 72:
          if (tag !== 576) {
            break;
          }

          message.docDoPo = reader.bool();
          continue;
        case 73:
          if (tag !== 584) {
            break;
          }

          message.docDoPenambahan = reader.bool();
          continue;
        case 74:
          if (tag !== 592) {
            break;
          }

          message.docDoPeminjaman = reader.bool();
          continue;
        case 75:
          if (tag !== 600) {
            break;
          }

          message.docDoTitipan = reader.bool();
          continue;
        case 76:
          if (tag !== 608) {
            break;
          }

          message.docDoReplacement = reader.bool();
          continue;
        case 77:
          if (tag !== 616) {
            break;
          }

          message.serialNumberOk = reader.bool();
          continue;
        case 78:
          if (tag !== 624) {
            break;
          }

          message.deliveryStatusOk = reader.bool();
          continue;
        case 79:
          if (tag !== 634) {
            break;
          }

          message.workDescString = reader.string();
          continue;
        case 80:
          if (tag !== 640) {
            break;
          }

          message.deliveryByLand = reader.bool();
          continue;
        case 81:
          if (tag !== 648) {
            break;
          }

          message.deliveryBySea = reader.bool();
          continue;
        case 82:
          if (tag !== 656) {
            break;
          }

          message.deliveryByAir = reader.bool();
          continue;
        case 83:
          if (tag !== 664) {
            break;
          }

          message.deliveryByJneJnt = reader.bool();
          continue;
        case 84:
          if (tag !== 672) {
            break;
          }

          message.deliveryExtItemId = longToString(reader.int64() as Long);
          continue;
        case 85:
          if (tag !== 681) {
            break;
          }

          message.deliveryQty = reader.double();
          continue;
        case 86:
          if (tag !== 688) {
            break;
          }

          message.deliveryInvoiceIsNeeded = reader.bool();
          continue;
        case 87:
          if (tag !== 698) {
            break;
          }

          message.deliveryRemark = reader.string();
          continue;
        case 88:
          if (tag !== 706) {
            break;
          }

          message.extDeliveryItems.push(MeetingTaskListExtDeliveryItem.decode(reader, reader.uint32()));
          continue;
        case 89:
          if (tag !== 712) {
            break;
          }

          message.reminderDays = reader.int32();
          continue;
        case 90:
          if (tag !== 720) {
            break;
          }

          message.deliveryTypePoBpo = reader.int32() as any;
          continue;
        case 91:
          if (tag !== 728) {
            break;
          }

          message.extBpoId = longToString(reader.uint64() as Long);
          continue;
        case 92:
          if (tag !== 736) {
            break;
          }

          message.isOkrKpi = reader.bool();
          continue;
        case 93:
          if (tag !== 744) {
            break;
          }

          message.weight = reader.int32();
          continue;
        case 94:
          if (tag !== 752) {
            break;
          }

          message.okrMeetingTaskParentId = longToString(reader.uint64() as Long);
          continue;
        case 95:
          if (tag !== 760) {
            break;
          }

          message.extMaterialRequestId = longToString(reader.uint64() as Long);
          continue;
        case 96:
          if (tag !== 768) {
            break;
          }

          message.extBomLeveledId = longToString(reader.uint64() as Long);
          continue;
        case 97:
          if (tag !== 776) {
            break;
          }

          message.abcReferenceId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskList {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      extInChargeId: isSet(object.extInChargeId) ? String(object.extInChargeId) : undefined,
      start: isSet(object.start) ? String(object.start) : undefined,
      deadline: isSet(object.deadline) ? String(object.deadline) : undefined,
      projectName: isSet(object.projectName) ? String(object.projectName) : undefined,
      projectId: isSet(object.projectId) ? String(object.projectId) : undefined,
      subProject: isSet(object.subProject) ? MeetingSubProject.fromJSON(object.subProject) : undefined,
      subProjectId: isSet(object.subProjectId) ? String(object.subProjectId) : undefined,
      meetingTasks: Array.isArray(object?.meetingTasks)
        ? object.meetingTasks.map((e: any) => MeetingMeetingTask.fromJSON(e))
        : [],
      taskListTargetDates: Array.isArray(object?.taskListTargetDates)
        ? object.taskListTargetDates.map((e: any) => MeetingTaskListTargetDate.fromJSON(e))
        : [],
      status: isSet(object.status) ? String(object.status) : undefined,
      taskListInCharges: Array.isArray(object?.taskListInCharges)
        ? object.taskListInCharges.map((e: any) => MeetingTaskListInCharge.fromJSON(e))
        : [],
      taskListSites: Array.isArray(object?.taskListSites)
        ? object.taskListSites.map((e: any) => MeetingTaskListSites.fromJSON(e))
        : [],
      isWoInstaller: isSet(object.isWoInstaller) ? Boolean(object.isWoInstaller) : undefined,
      extCustomerId: isSet(object.extCustomerId) ? String(object.extCustomerId) : undefined,
      taskListTemplate: isSet(object.taskListTemplate) ? TaskListTemplate.fromJSON(object.taskListTemplate) : undefined,
      taskListTemplateId: isSet(object.taskListTemplateId) ? String(object.taskListTemplateId) : undefined,
      taskListCashAdvancements: Array.isArray(object?.taskListCashAdvancements)
        ? object.taskListCashAdvancements.map((e: any) => MeetingTaskListCashAdvancement.fromJSON(e))
        : [],
      taskListCashSettlements: Array.isArray(object?.taskListCashSettlements)
        ? object.taskListCashSettlements.map((e: any) => MeetingTaskListCashSettlement.fromJSON(e))
        : [],
      taskListCashAdvancement: isSet(object.taskListCashAdvancement)
        ? MeetingTaskListCashAdvancement.fromJSON(object.taskListCashAdvancement)
        : undefined,
      taskListCashAdvancementId: isSet(object.taskListCashAdvancementId)
        ? String(object.taskListCashAdvancementId)
        : undefined,
      rateStars: isSet(object.rateStars) ? Number(object.rateStars) : undefined,
      completedDate: isSet(object.completedDate) ? String(object.completedDate) : undefined,
      projectPo: isSet(object.projectPo) ? MeetingTaskProjectPo.fromJSON(object.projectPo) : undefined,
      projectPoId: isSet(object.projectPoId) ? String(object.projectPoId) : undefined,
      taskListCashAdvancementWorkOrders: Array.isArray(object?.taskListCashAdvancementWorkOrders)
        ? object.taskListCashAdvancementWorkOrders.map((e: any) => MeetingTaskListCashAdvancementWorkOrder.fromJSON(e))
        : [],
      purpose: isSet(object.purpose) ? String(object.purpose) : undefined,
      extJobId: isSet(object.extJobId) ? String(object.extJobId) : undefined,
      extSerialNumber: isSet(object.extSerialNumber) ? String(object.extSerialNumber) : undefined,
      extModuleId: isSet(object.extModuleId) ? String(object.extModuleId) : undefined,
      extSubModuleId: isSet(object.extSubModuleId) ? String(object.extSubModuleId) : undefined,
      extSetId: isSet(object.extSetId) ? String(object.extSetId) : undefined,
      extProgramId: isSet(object.extProgramId) ? String(object.extProgramId) : undefined,
      extPanelCodeId: isSet(object.extPanelCodeId) ? String(object.extPanelCodeId) : undefined,
      extSupplierId: isSet(object.extSupplierId) ? String(object.extSupplierId) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
      extMrId: isSet(object.extMrId) ? String(object.extMrId) : undefined,
      extSubProcessId: isSet(object.extSubProcessId) ? String(object.extSubProcessId) : undefined,
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extIntegrationModuleQty: isSet(object.extIntegrationModuleQty)
        ? Number(object.extIntegrationModuleQty)
        : undefined,
      extSubProcessCommonId: isSet(object.extSubProcessCommonId) ? String(object.extSubProcessCommonId) : undefined,
      extIntegrationModuleQtyActual: isSet(object.extIntegrationModuleQtyActual)
        ? Number(object.extIntegrationModuleQtyActual)
        : undefined,
      extProgramRunQty: isSet(object.extProgramRunQty) ? Number(object.extProgramRunQty) : undefined,
      extProcessTypeId: isSet(object.extProcessTypeId) ? String(object.extProcessTypeId) : undefined,
      extRcemSubProcessId: isSet(object.extRcemSubProcessId) ? String(object.extRcemSubProcessId) : undefined,
      extMachineId: isSet(object.extMachineId) ? String(object.extMachineId) : undefined,
      extWoInChargeCompletedDate: isSet(object.extWoInChargeCompletedDate)
        ? String(object.extWoInChargeCompletedDate)
        : undefined,
      workOrderBeginTimestamp: isSet(object.workOrderBeginTimestamp)
        ? String(object.workOrderBeginTimestamp)
        : undefined,
      workOrderStopTimestamp: isSet(object.workOrderStopTimestamp) ? String(object.workOrderStopTimestamp) : undefined,
      extOperatorWorkOrderBeginId: isSet(object.extOperatorWorkOrderBeginId)
        ? String(object.extOperatorWorkOrderBeginId)
        : undefined,
      extOperatorWorkOrderStopId: isSet(object.extOperatorWorkOrderStopId)
        ? String(object.extOperatorWorkOrderStopId)
        : undefined,
      extWorkingTimeMins: isSet(object.extWorkingTimeMins) ? Number(object.extWorkingTimeMins) : undefined,
      extProgramRunQtyActual: isSet(object.extProgramRunQtyActual) ? Number(object.extProgramRunQtyActual) : undefined,
      references: Array.isArray(object?.references)
        ? object.references.map((e: any) => MeetingTaskListReference.fromJSON(e))
        : [],
      source: isSet(object.source) ? appSourceFromJSON(object.source) : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      deptCategory: isSet(object.deptCategory) ? taskListDepartmentCategoryFromJSON(object.deptCategory) : undefined,
      extPanelCodeSerialNumberId: isSet(object.extPanelCodeSerialNumberId)
        ? String(object.extPanelCodeSerialNumberId)
        : undefined,
      taskStart: isSet(object.taskStart) ? String(object.taskStart) : undefined,
      taskEnd: isSet(object.taskEnd) ? String(object.taskEnd) : undefined,
      meetingId: isSet(object.meetingId) ? String(object.meetingId) : undefined,
      meeting: isSet(object.meeting) ? MeetingMeeting.fromJSON(object.meeting) : undefined,
      extManufacturingCustomerId: isSet(object.extManufacturingCustomerId)
        ? String(object.extManufacturingCustomerId)
        : undefined,
      totalTimeHours: isSet(object.totalTimeHours) ? Number(object.totalTimeHours) : undefined,
      miscSettlements: Array.isArray(object?.miscSettlements)
        ? object.miscSettlements.map((e: any) => MeetingTaskListMiscellaneousSettlement.fromJSON(e))
        : [],
      isSurvey: isSet(object.isSurvey) ? Boolean(object.isSurvey) : undefined,
      logs: Array.isArray(object?.logs)
        ? object.logs.map((e: any) => MeetingTaskListChangeLog.fromJSON(e))
        : [],
      extMiddlewareTransactionTypeId: isSet(object.extMiddlewareTransactionTypeId)
        ? String(object.extMiddlewareTransactionTypeId)
        : undefined,
      deliveryAddress: isSet(object.deliveryAddress) ? String(object.deliveryAddress) : undefined,
      deliveryPic: isSet(object.deliveryPic) ? String(object.deliveryPic) : undefined,
      docDoPo: isSet(object.docDoPo) ? Boolean(object.docDoPo) : undefined,
      docDoPenambahan: isSet(object.docDoPenambahan) ? Boolean(object.docDoPenambahan) : undefined,
      docDoPeminjaman: isSet(object.docDoPeminjaman) ? Boolean(object.docDoPeminjaman) : undefined,
      docDoTitipan: isSet(object.docDoTitipan) ? Boolean(object.docDoTitipan) : undefined,
      docDoReplacement: isSet(object.docDoReplacement) ? Boolean(object.docDoReplacement) : undefined,
      serialNumberOk: isSet(object.serialNumberOk) ? Boolean(object.serialNumberOk) : undefined,
      deliveryStatusOk: isSet(object.deliveryStatusOk) ? Boolean(object.deliveryStatusOk) : undefined,
      workDescString: isSet(object.workDescString) ? String(object.workDescString) : undefined,
      deliveryByLand: isSet(object.deliveryByLand) ? Boolean(object.deliveryByLand) : undefined,
      deliveryBySea: isSet(object.deliveryBySea) ? Boolean(object.deliveryBySea) : undefined,
      deliveryByAir: isSet(object.deliveryByAir) ? Boolean(object.deliveryByAir) : undefined,
      deliveryByJneJnt: isSet(object.deliveryByJneJnt) ? Boolean(object.deliveryByJneJnt) : undefined,
      deliveryExtItemId: isSet(object.deliveryExtItemId) ? String(object.deliveryExtItemId) : undefined,
      deliveryQty: isSet(object.deliveryQty) ? Number(object.deliveryQty) : undefined,
      deliveryInvoiceIsNeeded: isSet(object.deliveryInvoiceIsNeeded)
        ? Boolean(object.deliveryInvoiceIsNeeded)
        : undefined,
      deliveryRemark: isSet(object.deliveryRemark) ? String(object.deliveryRemark) : undefined,
      extDeliveryItems: Array.isArray(object?.extDeliveryItems)
        ? object.extDeliveryItems.map((e: any) => MeetingTaskListExtDeliveryItem.fromJSON(e))
        : [],
      reminderDays: isSet(object.reminderDays) ? Number(object.reminderDays) : undefined,
      deliveryTypePoBpo: isSet(object.deliveryTypePoBpo)
        ? taskListDeliveryPoTypeFromJSON(object.deliveryTypePoBpo)
        : undefined,
      extBpoId: isSet(object.extBpoId) ? String(object.extBpoId) : undefined,
      isOkrKpi: isSet(object.isOkrKpi) ? Boolean(object.isOkrKpi) : undefined,
      weight: isSet(object.weight) ? Number(object.weight) : undefined,
      okrMeetingTaskParentId: isSet(object.okrMeetingTaskParentId) ? String(object.okrMeetingTaskParentId) : undefined,
      extMaterialRequestId: isSet(object.extMaterialRequestId) ? String(object.extMaterialRequestId) : undefined,
      extBomLeveledId: isSet(object.extBomLeveledId) ? String(object.extBomLeveledId) : undefined,
      abcReferenceId: isSet(object.abcReferenceId) ? String(object.abcReferenceId) : undefined,
    };
  },

  toJSON(message: MeetingTaskList): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.extInChargeId !== undefined && (obj.extInChargeId = message.extInChargeId);
    message.start !== undefined && (obj.start = message.start);
    message.deadline !== undefined && (obj.deadline = message.deadline);
    message.projectName !== undefined && (obj.projectName = message.projectName);
    message.projectId !== undefined && (obj.projectId = message.projectId);
    message.subProject !== undefined &&
      (obj.subProject = message.subProject ? MeetingSubProject.toJSON(message.subProject) : undefined);
    message.subProjectId !== undefined && (obj.subProjectId = message.subProjectId);
    if (message.meetingTasks) {
      obj.meetingTasks = message.meetingTasks.map((e) => e ? MeetingMeetingTask.toJSON(e) : undefined);
    } else {
      obj.meetingTasks = [];
    }
    if (message.taskListTargetDates) {
      obj.taskListTargetDates = message.taskListTargetDates.map((e) =>
        e ? MeetingTaskListTargetDate.toJSON(e) : undefined
      );
    } else {
      obj.taskListTargetDates = [];
    }
    message.status !== undefined && (obj.status = message.status);
    if (message.taskListInCharges) {
      obj.taskListInCharges = message.taskListInCharges.map((e) => e ? MeetingTaskListInCharge.toJSON(e) : undefined);
    } else {
      obj.taskListInCharges = [];
    }
    if (message.taskListSites) {
      obj.taskListSites = message.taskListSites.map((e) => e ? MeetingTaskListSites.toJSON(e) : undefined);
    } else {
      obj.taskListSites = [];
    }
    message.isWoInstaller !== undefined && (obj.isWoInstaller = message.isWoInstaller);
    message.extCustomerId !== undefined && (obj.extCustomerId = message.extCustomerId);
    message.taskListTemplate !== undefined &&
      (obj.taskListTemplate = message.taskListTemplate ? TaskListTemplate.toJSON(message.taskListTemplate) : undefined);
    message.taskListTemplateId !== undefined && (obj.taskListTemplateId = message.taskListTemplateId);
    if (message.taskListCashAdvancements) {
      obj.taskListCashAdvancements = message.taskListCashAdvancements.map((e) =>
        e ? MeetingTaskListCashAdvancement.toJSON(e) : undefined
      );
    } else {
      obj.taskListCashAdvancements = [];
    }
    if (message.taskListCashSettlements) {
      obj.taskListCashSettlements = message.taskListCashSettlements.map((e) =>
        e ? MeetingTaskListCashSettlement.toJSON(e) : undefined
      );
    } else {
      obj.taskListCashSettlements = [];
    }
    message.taskListCashAdvancement !== undefined && (obj.taskListCashAdvancement = message.taskListCashAdvancement
      ? MeetingTaskListCashAdvancement.toJSON(message.taskListCashAdvancement)
      : undefined);
    message.taskListCashAdvancementId !== undefined &&
      (obj.taskListCashAdvancementId = message.taskListCashAdvancementId);
    message.rateStars !== undefined && (obj.rateStars = Math.round(message.rateStars));
    message.completedDate !== undefined && (obj.completedDate = message.completedDate);
    message.projectPo !== undefined &&
      (obj.projectPo = message.projectPo ? MeetingTaskProjectPo.toJSON(message.projectPo) : undefined);
    message.projectPoId !== undefined && (obj.projectPoId = message.projectPoId);
    if (message.taskListCashAdvancementWorkOrders) {
      obj.taskListCashAdvancementWorkOrders = message.taskListCashAdvancementWorkOrders.map((e) =>
        e ? MeetingTaskListCashAdvancementWorkOrder.toJSON(e) : undefined
      );
    } else {
      obj.taskListCashAdvancementWorkOrders = [];
    }
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.extJobId !== undefined && (obj.extJobId = message.extJobId);
    message.extSerialNumber !== undefined && (obj.extSerialNumber = message.extSerialNumber);
    message.extModuleId !== undefined && (obj.extModuleId = message.extModuleId);
    message.extSubModuleId !== undefined && (obj.extSubModuleId = message.extSubModuleId);
    message.extSetId !== undefined && (obj.extSetId = message.extSetId);
    message.extProgramId !== undefined && (obj.extProgramId = message.extProgramId);
    message.extPanelCodeId !== undefined && (obj.extPanelCodeId = message.extPanelCodeId);
    message.extSupplierId !== undefined && (obj.extSupplierId = message.extSupplierId);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    message.extMrId !== undefined && (obj.extMrId = message.extMrId);
    message.extSubProcessId !== undefined && (obj.extSubProcessId = message.extSubProcessId);
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extIntegrationModuleQty !== undefined &&
      (obj.extIntegrationModuleQty = Math.round(message.extIntegrationModuleQty));
    message.extSubProcessCommonId !== undefined && (obj.extSubProcessCommonId = message.extSubProcessCommonId);
    message.extIntegrationModuleQtyActual !== undefined &&
      (obj.extIntegrationModuleQtyActual = Math.round(message.extIntegrationModuleQtyActual));
    message.extProgramRunQty !== undefined && (obj.extProgramRunQty = Math.round(message.extProgramRunQty));
    message.extProcessTypeId !== undefined && (obj.extProcessTypeId = message.extProcessTypeId);
    message.extRcemSubProcessId !== undefined && (obj.extRcemSubProcessId = message.extRcemSubProcessId);
    message.extMachineId !== undefined && (obj.extMachineId = message.extMachineId);
    message.extWoInChargeCompletedDate !== undefined &&
      (obj.extWoInChargeCompletedDate = message.extWoInChargeCompletedDate);
    message.workOrderBeginTimestamp !== undefined && (obj.workOrderBeginTimestamp = message.workOrderBeginTimestamp);
    message.workOrderStopTimestamp !== undefined && (obj.workOrderStopTimestamp = message.workOrderStopTimestamp);
    message.extOperatorWorkOrderBeginId !== undefined &&
      (obj.extOperatorWorkOrderBeginId = message.extOperatorWorkOrderBeginId);
    message.extOperatorWorkOrderStopId !== undefined &&
      (obj.extOperatorWorkOrderStopId = message.extOperatorWorkOrderStopId);
    message.extWorkingTimeMins !== undefined && (obj.extWorkingTimeMins = Math.round(message.extWorkingTimeMins));
    message.extProgramRunQtyActual !== undefined &&
      (obj.extProgramRunQtyActual = Math.round(message.extProgramRunQtyActual));
    if (message.references) {
      obj.references = message.references.map((e) => e ? MeetingTaskListReference.toJSON(e) : undefined);
    } else {
      obj.references = [];
    }
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? appSourceToJSON(message.source) : undefined);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.deptCategory !== undefined && (obj.deptCategory = message.deptCategory !== undefined
      ? taskListDepartmentCategoryToJSON(message.deptCategory)
      : undefined);
    message.extPanelCodeSerialNumberId !== undefined &&
      (obj.extPanelCodeSerialNumberId = message.extPanelCodeSerialNumberId);
    message.taskStart !== undefined && (obj.taskStart = message.taskStart);
    message.taskEnd !== undefined && (obj.taskEnd = message.taskEnd);
    message.meetingId !== undefined && (obj.meetingId = message.meetingId);
    message.meeting !== undefined &&
      (obj.meeting = message.meeting ? MeetingMeeting.toJSON(message.meeting) : undefined);
    message.extManufacturingCustomerId !== undefined &&
      (obj.extManufacturingCustomerId = message.extManufacturingCustomerId);
    message.totalTimeHours !== undefined && (obj.totalTimeHours = message.totalTimeHours);
    if (message.miscSettlements) {
      obj.miscSettlements = message.miscSettlements.map((e) =>
        e ? MeetingTaskListMiscellaneousSettlement.toJSON(e) : undefined
      );
    } else {
      obj.miscSettlements = [];
    }
    message.isSurvey !== undefined && (obj.isSurvey = message.isSurvey);
    if (message.logs) {
      obj.logs = message.logs.map((e) => e ? MeetingTaskListChangeLog.toJSON(e) : undefined);
    } else {
      obj.logs = [];
    }
    message.extMiddlewareTransactionTypeId !== undefined &&
      (obj.extMiddlewareTransactionTypeId = message.extMiddlewareTransactionTypeId);
    message.deliveryAddress !== undefined && (obj.deliveryAddress = message.deliveryAddress);
    message.deliveryPic !== undefined && (obj.deliveryPic = message.deliveryPic);
    message.docDoPo !== undefined && (obj.docDoPo = message.docDoPo);
    message.docDoPenambahan !== undefined && (obj.docDoPenambahan = message.docDoPenambahan);
    message.docDoPeminjaman !== undefined && (obj.docDoPeminjaman = message.docDoPeminjaman);
    message.docDoTitipan !== undefined && (obj.docDoTitipan = message.docDoTitipan);
    message.docDoReplacement !== undefined && (obj.docDoReplacement = message.docDoReplacement);
    message.serialNumberOk !== undefined && (obj.serialNumberOk = message.serialNumberOk);
    message.deliveryStatusOk !== undefined && (obj.deliveryStatusOk = message.deliveryStatusOk);
    message.workDescString !== undefined && (obj.workDescString = message.workDescString);
    message.deliveryByLand !== undefined && (obj.deliveryByLand = message.deliveryByLand);
    message.deliveryBySea !== undefined && (obj.deliveryBySea = message.deliveryBySea);
    message.deliveryByAir !== undefined && (obj.deliveryByAir = message.deliveryByAir);
    message.deliveryByJneJnt !== undefined && (obj.deliveryByJneJnt = message.deliveryByJneJnt);
    message.deliveryExtItemId !== undefined && (obj.deliveryExtItemId = message.deliveryExtItemId);
    message.deliveryQty !== undefined && (obj.deliveryQty = message.deliveryQty);
    message.deliveryInvoiceIsNeeded !== undefined && (obj.deliveryInvoiceIsNeeded = message.deliveryInvoiceIsNeeded);
    message.deliveryRemark !== undefined && (obj.deliveryRemark = message.deliveryRemark);
    if (message.extDeliveryItems) {
      obj.extDeliveryItems = message.extDeliveryItems.map((e) =>
        e ? MeetingTaskListExtDeliveryItem.toJSON(e) : undefined
      );
    } else {
      obj.extDeliveryItems = [];
    }
    message.reminderDays !== undefined && (obj.reminderDays = Math.round(message.reminderDays));
    message.deliveryTypePoBpo !== undefined && (obj.deliveryTypePoBpo = message.deliveryTypePoBpo !== undefined
      ? taskListDeliveryPoTypeToJSON(message.deliveryTypePoBpo)
      : undefined);
    message.extBpoId !== undefined && (obj.extBpoId = message.extBpoId);
    message.isOkrKpi !== undefined && (obj.isOkrKpi = message.isOkrKpi);
    message.weight !== undefined && (obj.weight = Math.round(message.weight));
    message.okrMeetingTaskParentId !== undefined && (obj.okrMeetingTaskParentId = message.okrMeetingTaskParentId);
    message.extMaterialRequestId !== undefined && (obj.extMaterialRequestId = message.extMaterialRequestId);
    message.extBomLeveledId !== undefined && (obj.extBomLeveledId = message.extBomLeveledId);
    message.abcReferenceId !== undefined && (obj.abcReferenceId = message.abcReferenceId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskList>, I>>(base?: I): MeetingTaskList {
    return MeetingTaskList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskList>, I>>(object: I): MeetingTaskList {
    const message = createBaseMeetingTaskList();
    message.name = object.name ?? undefined;
    message.extInChargeId = object.extInChargeId ?? undefined;
    message.start = object.start ?? undefined;
    message.deadline = object.deadline ?? undefined;
    message.projectName = object.projectName ?? undefined;
    message.projectId = object.projectId ?? undefined;
    message.subProject = (object.subProject !== undefined && object.subProject !== null)
      ? MeetingSubProject.fromPartial(object.subProject)
      : undefined;
    message.subProjectId = object.subProjectId ?? undefined;
    message.meetingTasks = object.meetingTasks?.map((e) => MeetingMeetingTask.fromPartial(e)) || [];
    message.taskListTargetDates = object.taskListTargetDates?.map((e) => MeetingTaskListTargetDate.fromPartial(e)) ||
      [];
    message.status = object.status ?? undefined;
    message.taskListInCharges = object.taskListInCharges?.map((e) => MeetingTaskListInCharge.fromPartial(e)) || [];
    message.taskListSites = object.taskListSites?.map((e) => MeetingTaskListSites.fromPartial(e)) || [];
    message.isWoInstaller = object.isWoInstaller ?? undefined;
    message.extCustomerId = object.extCustomerId ?? undefined;
    message.taskListTemplate = (object.taskListTemplate !== undefined && object.taskListTemplate !== null)
      ? TaskListTemplate.fromPartial(object.taskListTemplate)
      : undefined;
    message.taskListTemplateId = object.taskListTemplateId ?? undefined;
    message.taskListCashAdvancements =
      object.taskListCashAdvancements?.map((e) => MeetingTaskListCashAdvancement.fromPartial(e)) || [];
    message.taskListCashSettlements =
      object.taskListCashSettlements?.map((e) => MeetingTaskListCashSettlement.fromPartial(e)) || [];
    message.taskListCashAdvancement =
      (object.taskListCashAdvancement !== undefined && object.taskListCashAdvancement !== null)
        ? MeetingTaskListCashAdvancement.fromPartial(object.taskListCashAdvancement)
        : undefined;
    message.taskListCashAdvancementId = object.taskListCashAdvancementId ?? undefined;
    message.rateStars = object.rateStars ?? undefined;
    message.completedDate = object.completedDate ?? undefined;
    message.projectPo = (object.projectPo !== undefined && object.projectPo !== null)
      ? MeetingTaskProjectPo.fromPartial(object.projectPo)
      : undefined;
    message.projectPoId = object.projectPoId ?? undefined;
    message.taskListCashAdvancementWorkOrders =
      object.taskListCashAdvancementWorkOrders?.map((e) => MeetingTaskListCashAdvancementWorkOrder.fromPartial(e)) ||
      [];
    message.purpose = object.purpose ?? undefined;
    message.extJobId = object.extJobId ?? undefined;
    message.extSerialNumber = object.extSerialNumber ?? undefined;
    message.extModuleId = object.extModuleId ?? undefined;
    message.extSubModuleId = object.extSubModuleId ?? undefined;
    message.extSetId = object.extSetId ?? undefined;
    message.extProgramId = object.extProgramId ?? undefined;
    message.extPanelCodeId = object.extPanelCodeId ?? undefined;
    message.extSupplierId = object.extSupplierId ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    message.extMrId = object.extMrId ?? undefined;
    message.extSubProcessId = object.extSubProcessId ?? undefined;
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extIntegrationModuleQty = object.extIntegrationModuleQty ?? undefined;
    message.extSubProcessCommonId = object.extSubProcessCommonId ?? undefined;
    message.extIntegrationModuleQtyActual = object.extIntegrationModuleQtyActual ?? undefined;
    message.extProgramRunQty = object.extProgramRunQty ?? undefined;
    message.extProcessTypeId = object.extProcessTypeId ?? undefined;
    message.extRcemSubProcessId = object.extRcemSubProcessId ?? undefined;
    message.extMachineId = object.extMachineId ?? undefined;
    message.extWoInChargeCompletedDate = object.extWoInChargeCompletedDate ?? undefined;
    message.workOrderBeginTimestamp = object.workOrderBeginTimestamp ?? undefined;
    message.workOrderStopTimestamp = object.workOrderStopTimestamp ?? undefined;
    message.extOperatorWorkOrderBeginId = object.extOperatorWorkOrderBeginId ?? undefined;
    message.extOperatorWorkOrderStopId = object.extOperatorWorkOrderStopId ?? undefined;
    message.extWorkingTimeMins = object.extWorkingTimeMins ?? undefined;
    message.extProgramRunQtyActual = object.extProgramRunQtyActual ?? undefined;
    message.references = object.references?.map((e) => MeetingTaskListReference.fromPartial(e)) || [];
    message.source = object.source ?? undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.deptCategory = object.deptCategory ?? undefined;
    message.extPanelCodeSerialNumberId = object.extPanelCodeSerialNumberId ?? undefined;
    message.taskStart = object.taskStart ?? undefined;
    message.taskEnd = object.taskEnd ?? undefined;
    message.meetingId = object.meetingId ?? undefined;
    message.meeting = (object.meeting !== undefined && object.meeting !== null)
      ? MeetingMeeting.fromPartial(object.meeting)
      : undefined;
    message.extManufacturingCustomerId = object.extManufacturingCustomerId ?? undefined;
    message.totalTimeHours = object.totalTimeHours ?? undefined;
    message.miscSettlements =
      object.miscSettlements?.map((e) => MeetingTaskListMiscellaneousSettlement.fromPartial(e)) || [];
    message.isSurvey = object.isSurvey ?? undefined;
    message.logs = object.logs?.map((e) => MeetingTaskListChangeLog.fromPartial(e)) || [];
    message.extMiddlewareTransactionTypeId = object.extMiddlewareTransactionTypeId ?? undefined;
    message.deliveryAddress = object.deliveryAddress ?? undefined;
    message.deliveryPic = object.deliveryPic ?? undefined;
    message.docDoPo = object.docDoPo ?? undefined;
    message.docDoPenambahan = object.docDoPenambahan ?? undefined;
    message.docDoPeminjaman = object.docDoPeminjaman ?? undefined;
    message.docDoTitipan = object.docDoTitipan ?? undefined;
    message.docDoReplacement = object.docDoReplacement ?? undefined;
    message.serialNumberOk = object.serialNumberOk ?? undefined;
    message.deliveryStatusOk = object.deliveryStatusOk ?? undefined;
    message.workDescString = object.workDescString ?? undefined;
    message.deliveryByLand = object.deliveryByLand ?? undefined;
    message.deliveryBySea = object.deliveryBySea ?? undefined;
    message.deliveryByAir = object.deliveryByAir ?? undefined;
    message.deliveryByJneJnt = object.deliveryByJneJnt ?? undefined;
    message.deliveryExtItemId = object.deliveryExtItemId ?? undefined;
    message.deliveryQty = object.deliveryQty ?? undefined;
    message.deliveryInvoiceIsNeeded = object.deliveryInvoiceIsNeeded ?? undefined;
    message.deliveryRemark = object.deliveryRemark ?? undefined;
    message.extDeliveryItems = object.extDeliveryItems?.map((e) => MeetingTaskListExtDeliveryItem.fromPartial(e)) || [];
    message.reminderDays = object.reminderDays ?? undefined;
    message.deliveryTypePoBpo = object.deliveryTypePoBpo ?? undefined;
    message.extBpoId = object.extBpoId ?? undefined;
    message.isOkrKpi = object.isOkrKpi ?? undefined;
    message.weight = object.weight ?? undefined;
    message.okrMeetingTaskParentId = object.okrMeetingTaskParentId ?? undefined;
    message.extMaterialRequestId = object.extMaterialRequestId ?? undefined;
    message.extBomLeveledId = object.extBomLeveledId ?? undefined;
    message.abcReferenceId = object.abcReferenceId ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListExtDeliveryItem(): MeetingTaskListExtDeliveryItem {
  return { masterJavaBaseModel: undefined, taskListId: undefined, extItemId: undefined, qty: undefined };
}

export const MeetingTaskListExtDeliveryItem = {
  encode(message: MeetingTaskListExtDeliveryItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.taskListId !== undefined) {
      writer.uint32(16).uint64(message.taskListId);
    }
    if (message.extItemId !== undefined) {
      writer.uint32(24).uint64(message.extItemId);
    }
    if (message.qty !== undefined) {
      writer.uint32(33).double(message.qty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExtDeliveryItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExtDeliveryItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.qty = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExtDeliveryItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
    };
  },

  toJSON(message: MeetingTaskListExtDeliveryItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.qty !== undefined && (obj.qty = message.qty);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExtDeliveryItem>, I>>(base?: I): MeetingTaskListExtDeliveryItem {
    return MeetingTaskListExtDeliveryItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExtDeliveryItem>, I>>(
    object: I,
  ): MeetingTaskListExtDeliveryItem {
    const message = createBaseMeetingTaskListExtDeliveryItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.taskListId = object.taskListId ?? undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.qty = object.qty ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListChangeLog(): MeetingTaskListChangeLog {
  return { masterJavaBaseModel: undefined, type: undefined, before: undefined, after: undefined, extUserId: undefined };
}

export const MeetingTaskListChangeLog = {
  encode(message: MeetingTaskListChangeLog, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    if (message.before !== undefined) {
      writer.uint32(26).string(message.before);
    }
    if (message.after !== undefined) {
      writer.uint32(34).string(message.after);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(40).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListChangeLog {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListChangeLog();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.before = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.after = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListChangeLog {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      type: isSet(object.type) ? meetingTaskListChangeLog_MeetingTaskListChangeLogTypeFromJSON(object.type) : undefined,
      before: isSet(object.before) ? String(object.before) : undefined,
      after: isSet(object.after) ? String(object.after) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: MeetingTaskListChangeLog): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.type !== undefined && (obj.type = message.type !== undefined
      ? meetingTaskListChangeLog_MeetingTaskListChangeLogTypeToJSON(message.type)
      : undefined);
    message.before !== undefined && (obj.before = message.before);
    message.after !== undefined && (obj.after = message.after);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListChangeLog>, I>>(base?: I): MeetingTaskListChangeLog {
    return MeetingTaskListChangeLog.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListChangeLog>, I>>(object: I): MeetingTaskListChangeLog {
    const message = createBaseMeetingTaskListChangeLog();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.type = object.type ?? undefined;
    message.before = object.before ?? undefined;
    message.after = object.after ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListReference(): MeetingTaskListReference {
  return {
    masterJavaBaseModel: undefined,
    type: undefined,
    id: undefined,
    taskListId: undefined,
    stringData: undefined,
  };
}

export const MeetingTaskListReference = {
  encode(message: MeetingTaskListReference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    if (message.id !== undefined) {
      writer.uint32(24).uint64(message.id);
    }
    if (message.taskListId !== undefined) {
      writer.uint32(32).uint64(message.taskListId);
    }
    if (message.stringData !== undefined) {
      writer.uint32(42).string(message.stringData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListReference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListReference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.stringData = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListReference {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      type: isSet(object.type) ? taskListReferenceTypeFromJSON(object.type) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
      stringData: isSet(object.stringData) ? String(object.stringData) : undefined,
    };
  },

  toJSON(message: MeetingTaskListReference): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? taskListReferenceTypeToJSON(message.type) : undefined);
    message.id !== undefined && (obj.id = message.id);
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    message.stringData !== undefined && (obj.stringData = message.stringData);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListReference>, I>>(base?: I): MeetingTaskListReference {
    return MeetingTaskListReference.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListReference>, I>>(object: I): MeetingTaskListReference {
    const message = createBaseMeetingTaskListReference();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.type = object.type ?? undefined;
    message.id = object.id ?? undefined;
    message.taskListId = object.taskListId ?? undefined;
    message.stringData = object.stringData ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListExternalSource(): MeetingTaskListExternalSource {
  return {
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    references: [],
    tasks: [],
    supervisor: undefined,
    appSource: undefined,
    supervisorName: undefined,
    start: undefined,
    deadline: undefined,
  };
}

export const MeetingTaskListExternalSource = {
  encode(message: MeetingTaskListExternalSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(18).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(26).string(message.updatedAt);
    }
    for (const v of message.references) {
      MeetingTaskListExternalSourceReference.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tasks) {
      MeetingTaskListExternalSourceTask.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.supervisor !== undefined) {
      writer.uint32(48).uint64(message.supervisor);
    }
    if (message.appSource !== undefined) {
      writer.uint32(56).int32(message.appSource);
    }
    if (message.supervisorName !== undefined) {
      writer.uint32(66).string(message.supervisorName);
    }
    if (message.start !== undefined) {
      writer.uint32(74).string(message.start);
    }
    if (message.deadline !== undefined) {
      writer.uint32(82).string(message.deadline);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExternalSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExternalSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.references.push(MeetingTaskListExternalSourceReference.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tasks.push(MeetingTaskListExternalSourceTask.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.supervisor = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.appSource = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.supervisorName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.start = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deadline = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExternalSource {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      references: Array.isArray(object?.references)
        ? object.references.map((e: any) => MeetingTaskListExternalSourceReference.fromJSON(e))
        : [],
      tasks: Array.isArray(object?.tasks)
        ? object.tasks.map((e: any) => MeetingTaskListExternalSourceTask.fromJSON(e))
        : [],
      supervisor: isSet(object.supervisor) ? String(object.supervisor) : undefined,
      appSource: isSet(object.appSource) ? appSourceFromJSON(object.appSource) : undefined,
      supervisorName: isSet(object.supervisorName) ? String(object.supervisorName) : undefined,
      start: isSet(object.start) ? String(object.start) : undefined,
      deadline: isSet(object.deadline) ? String(object.deadline) : undefined,
    };
  },

  toJSON(message: MeetingTaskListExternalSource): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    if (message.references) {
      obj.references = message.references.map((e) => e ? MeetingTaskListExternalSourceReference.toJSON(e) : undefined);
    } else {
      obj.references = [];
    }
    if (message.tasks) {
      obj.tasks = message.tasks.map((e) => e ? MeetingTaskListExternalSourceTask.toJSON(e) : undefined);
    } else {
      obj.tasks = [];
    }
    message.supervisor !== undefined && (obj.supervisor = message.supervisor);
    message.appSource !== undefined &&
      (obj.appSource = message.appSource !== undefined ? appSourceToJSON(message.appSource) : undefined);
    message.supervisorName !== undefined && (obj.supervisorName = message.supervisorName);
    message.start !== undefined && (obj.start = message.start);
    message.deadline !== undefined && (obj.deadline = message.deadline);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExternalSource>, I>>(base?: I): MeetingTaskListExternalSource {
    return MeetingTaskListExternalSource.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExternalSource>, I>>(
    object: I,
  ): MeetingTaskListExternalSource {
    const message = createBaseMeetingTaskListExternalSource();
    message.id = object.id ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.references = object.references?.map((e) => MeetingTaskListExternalSourceReference.fromPartial(e)) || [];
    message.tasks = object.tasks?.map((e) => MeetingTaskListExternalSourceTask.fromPartial(e)) || [];
    message.supervisor = object.supervisor ?? undefined;
    message.appSource = object.appSource ?? undefined;
    message.supervisorName = object.supervisorName ?? undefined;
    message.start = object.start ?? undefined;
    message.deadline = object.deadline ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListExternalSources(): MeetingTaskListExternalSources {
  return { workOrders: [] };
}

export const MeetingTaskListExternalSources = {
  encode(message: MeetingTaskListExternalSources, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.workOrders) {
      MeetingTaskListExternalSource.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExternalSources {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExternalSources();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workOrders.push(MeetingTaskListExternalSource.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExternalSources {
    return {
      workOrders: Array.isArray(object?.workOrders)
        ? object.workOrders.map((e: any) => MeetingTaskListExternalSource.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingTaskListExternalSources): unknown {
    const obj: any = {};
    if (message.workOrders) {
      obj.workOrders = message.workOrders.map((e) => e ? MeetingTaskListExternalSource.toJSON(e) : undefined);
    } else {
      obj.workOrders = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExternalSources>, I>>(base?: I): MeetingTaskListExternalSources {
    return MeetingTaskListExternalSources.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExternalSources>, I>>(
    object: I,
  ): MeetingTaskListExternalSources {
    const message = createBaseMeetingTaskListExternalSources();
    message.workOrders = object.workOrders?.map((e) => MeetingTaskListExternalSource.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingTaskListExternalSourceReference(): MeetingTaskListExternalSourceReference {
  return {
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    type: undefined,
    referenceId: undefined,
    taskListId: undefined,
    stringData: undefined,
  };
}

export const MeetingTaskListExternalSourceReference = {
  encode(message: MeetingTaskListExternalSourceReference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(18).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(26).string(message.updatedAt);
    }
    if (message.type !== undefined) {
      writer.uint32(32).int32(message.type);
    }
    if (message.referenceId !== undefined) {
      writer.uint32(40).uint64(message.referenceId);
    }
    if (message.taskListId !== undefined) {
      writer.uint32(48).uint64(message.taskListId);
    }
    if (message.stringData !== undefined) {
      writer.uint32(58).string(message.stringData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExternalSourceReference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExternalSourceReference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.referenceId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.stringData = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExternalSourceReference {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      type: isSet(object.type) ? taskListReferenceTypeFromJSON(object.type) : undefined,
      referenceId: isSet(object.referenceId) ? String(object.referenceId) : undefined,
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
      stringData: isSet(object.stringData) ? String(object.stringData) : undefined,
    };
  },

  toJSON(message: MeetingTaskListExternalSourceReference): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? taskListReferenceTypeToJSON(message.type) : undefined);
    message.referenceId !== undefined && (obj.referenceId = message.referenceId);
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    message.stringData !== undefined && (obj.stringData = message.stringData);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExternalSourceReference>, I>>(
    base?: I,
  ): MeetingTaskListExternalSourceReference {
    return MeetingTaskListExternalSourceReference.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExternalSourceReference>, I>>(
    object: I,
  ): MeetingTaskListExternalSourceReference {
    const message = createBaseMeetingTaskListExternalSourceReference();
    message.id = object.id ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.type = object.type ?? undefined;
    message.referenceId = object.referenceId ?? undefined;
    message.taskListId = object.taskListId ?? undefined;
    message.stringData = object.stringData ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListExternalSourceTask(): MeetingTaskListExternalSourceTask {
  return {
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    description: undefined,
    date: undefined,
    peopleInCharge: [],
    taskListId: undefined,
    subProcessId: undefined,
    status: undefined,
    picCompletedDate: undefined,
    spvCompletedDate: undefined,
    durationMins: undefined,
    qty: undefined,
    serialNumbers: [],
    trips: [],
  };
}

export const MeetingTaskListExternalSourceTask = {
  encode(message: MeetingTaskListExternalSourceTask, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(18).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(26).string(message.updatedAt);
    }
    if (message.description !== undefined) {
      writer.uint32(34).string(message.description);
    }
    if (message.date !== undefined) {
      writer.uint32(42).string(message.date);
    }
    for (const v of message.peopleInCharge) {
      MeetingTaskListExternalSourceTaskInCharge.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.taskListId !== undefined) {
      writer.uint32(56).uint64(message.taskListId);
    }
    if (message.subProcessId !== undefined) {
      writer.uint32(64).uint64(message.subProcessId);
    }
    if (message.status !== undefined) {
      writer.uint32(74).string(message.status);
    }
    if (message.picCompletedDate !== undefined) {
      writer.uint32(82).string(message.picCompletedDate);
    }
    if (message.spvCompletedDate !== undefined) {
      writer.uint32(90).string(message.spvCompletedDate);
    }
    if (message.durationMins !== undefined) {
      writer.uint32(96).int32(message.durationMins);
    }
    if (message.qty !== undefined) {
      writer.uint32(104).int32(message.qty);
    }
    for (const v of message.serialNumbers) {
      MeetingTaskListExternalSourceTaskSerialNumber.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.trips) {
      MeetingTaskListExternalSourceTaskJourney.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExternalSourceTask {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExternalSourceTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.peopleInCharge.push(MeetingTaskListExternalSourceTaskInCharge.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.subProcessId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.status = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.picCompletedDate = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.spvCompletedDate = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.durationMins = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.serialNumbers.push(MeetingTaskListExternalSourceTaskSerialNumber.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.trips.push(MeetingTaskListExternalSourceTaskJourney.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExternalSourceTask {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      peopleInCharge: Array.isArray(object?.peopleInCharge)
        ? object.peopleInCharge.map((e: any) => MeetingTaskListExternalSourceTaskInCharge.fromJSON(e))
        : [],
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
      subProcessId: isSet(object.subProcessId) ? String(object.subProcessId) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      picCompletedDate: isSet(object.picCompletedDate) ? String(object.picCompletedDate) : undefined,
      spvCompletedDate: isSet(object.spvCompletedDate) ? String(object.spvCompletedDate) : undefined,
      durationMins: isSet(object.durationMins) ? Number(object.durationMins) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      serialNumbers: Array.isArray(object?.serialNumbers)
        ? object.serialNumbers.map((e: any) => MeetingTaskListExternalSourceTaskSerialNumber.fromJSON(e))
        : [],
      trips: Array.isArray(object?.trips)
        ? object.trips.map((e: any) => MeetingTaskListExternalSourceTaskJourney.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingTaskListExternalSourceTask): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.description !== undefined && (obj.description = message.description);
    message.date !== undefined && (obj.date = message.date);
    if (message.peopleInCharge) {
      obj.peopleInCharge = message.peopleInCharge.map((e) =>
        e ? MeetingTaskListExternalSourceTaskInCharge.toJSON(e) : undefined
      );
    } else {
      obj.peopleInCharge = [];
    }
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    message.subProcessId !== undefined && (obj.subProcessId = message.subProcessId);
    message.status !== undefined && (obj.status = message.status);
    message.picCompletedDate !== undefined && (obj.picCompletedDate = message.picCompletedDate);
    message.spvCompletedDate !== undefined && (obj.spvCompletedDate = message.spvCompletedDate);
    message.durationMins !== undefined && (obj.durationMins = Math.round(message.durationMins));
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    if (message.serialNumbers) {
      obj.serialNumbers = message.serialNumbers.map((e) =>
        e ? MeetingTaskListExternalSourceTaskSerialNumber.toJSON(e) : undefined
      );
    } else {
      obj.serialNumbers = [];
    }
    if (message.trips) {
      obj.trips = message.trips.map((e) => e ? MeetingTaskListExternalSourceTaskJourney.toJSON(e) : undefined);
    } else {
      obj.trips = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTask>, I>>(
    base?: I,
  ): MeetingTaskListExternalSourceTask {
    return MeetingTaskListExternalSourceTask.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTask>, I>>(
    object: I,
  ): MeetingTaskListExternalSourceTask {
    const message = createBaseMeetingTaskListExternalSourceTask();
    message.id = object.id ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.description = object.description ?? undefined;
    message.date = object.date ?? undefined;
    message.peopleInCharge =
      object.peopleInCharge?.map((e) => MeetingTaskListExternalSourceTaskInCharge.fromPartial(e)) || [];
    message.taskListId = object.taskListId ?? undefined;
    message.subProcessId = object.subProcessId ?? undefined;
    message.status = object.status ?? undefined;
    message.picCompletedDate = object.picCompletedDate ?? undefined;
    message.spvCompletedDate = object.spvCompletedDate ?? undefined;
    message.durationMins = object.durationMins ?? undefined;
    message.qty = object.qty ?? undefined;
    message.serialNumbers =
      object.serialNumbers?.map((e) => MeetingTaskListExternalSourceTaskSerialNumber.fromPartial(e)) || [];
    message.trips = object.trips?.map((e) => MeetingTaskListExternalSourceTaskJourney.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingTaskListExternalSourceTaskJourney(): MeetingTaskListExternalSourceTaskJourney {
  return {
    id: undefined,
    lat: undefined,
    lon: undefined,
    siteName: undefined,
    createdAt: undefined,
    updatedAt: undefined,
  };
}

export const MeetingTaskListExternalSourceTaskJourney = {
  encode(message: MeetingTaskListExternalSourceTaskJourney, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.lat !== undefined) {
      writer.uint32(17).double(message.lat);
    }
    if (message.lon !== undefined) {
      writer.uint32(25).double(message.lon);
    }
    if (message.siteName !== undefined) {
      writer.uint32(34).string(message.siteName);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(42).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(50).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExternalSourceTaskJourney {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExternalSourceTaskJourney();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.lon = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.siteName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExternalSourceTaskJourney {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      lat: isSet(object.lat) ? Number(object.lat) : undefined,
      lon: isSet(object.lon) ? Number(object.lon) : undefined,
      siteName: isSet(object.siteName) ? String(object.siteName) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
    };
  },

  toJSON(message: MeetingTaskListExternalSourceTaskJourney): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.lat !== undefined && (obj.lat = message.lat);
    message.lon !== undefined && (obj.lon = message.lon);
    message.siteName !== undefined && (obj.siteName = message.siteName);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTaskJourney>, I>>(
    base?: I,
  ): MeetingTaskListExternalSourceTaskJourney {
    return MeetingTaskListExternalSourceTaskJourney.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTaskJourney>, I>>(
    object: I,
  ): MeetingTaskListExternalSourceTaskJourney {
    const message = createBaseMeetingTaskListExternalSourceTaskJourney();
    message.id = object.id ?? undefined;
    message.lat = object.lat ?? undefined;
    message.lon = object.lon ?? undefined;
    message.siteName = object.siteName ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListExternalSourceTaskSerialNumber(): MeetingTaskListExternalSourceTaskSerialNumber {
  return { id: undefined, serialNumber: undefined, createdAt: undefined, updatedAt: undefined };
}

export const MeetingTaskListExternalSourceTaskSerialNumber = {
  encode(message: MeetingTaskListExternalSourceTaskSerialNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.serialNumber !== undefined) {
      writer.uint32(18).string(message.serialNumber);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(26).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(34).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExternalSourceTaskSerialNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExternalSourceTaskSerialNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExternalSourceTaskSerialNumber {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      serialNumber: isSet(object.serialNumber) ? String(object.serialNumber) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
    };
  },

  toJSON(message: MeetingTaskListExternalSourceTaskSerialNumber): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.serialNumber !== undefined && (obj.serialNumber = message.serialNumber);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTaskSerialNumber>, I>>(
    base?: I,
  ): MeetingTaskListExternalSourceTaskSerialNumber {
    return MeetingTaskListExternalSourceTaskSerialNumber.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTaskSerialNumber>, I>>(
    object: I,
  ): MeetingTaskListExternalSourceTaskSerialNumber {
    const message = createBaseMeetingTaskListExternalSourceTaskSerialNumber();
    message.id = object.id ?? undefined;
    message.serialNumber = object.serialNumber ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListExternalSourceTaskInCharge(): MeetingTaskListExternalSourceTaskInCharge {
  return {
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    extEmployeeId: undefined,
    extEmployeeName: undefined,
  };
}

export const MeetingTaskListExternalSourceTaskInCharge = {
  encode(message: MeetingTaskListExternalSourceTaskInCharge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(18).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(26).string(message.updatedAt);
    }
    if (message.extEmployeeId !== undefined) {
      writer.uint32(32).uint64(message.extEmployeeId);
    }
    if (message.extEmployeeName !== undefined) {
      writer.uint32(42).string(message.extEmployeeName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListExternalSourceTaskInCharge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListExternalSourceTaskInCharge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extEmployeeId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.extEmployeeName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListExternalSourceTaskInCharge {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      extEmployeeId: isSet(object.extEmployeeId) ? String(object.extEmployeeId) : undefined,
      extEmployeeName: isSet(object.extEmployeeName) ? String(object.extEmployeeName) : undefined,
    };
  },

  toJSON(message: MeetingTaskListExternalSourceTaskInCharge): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.extEmployeeId !== undefined && (obj.extEmployeeId = message.extEmployeeId);
    message.extEmployeeName !== undefined && (obj.extEmployeeName = message.extEmployeeName);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTaskInCharge>, I>>(
    base?: I,
  ): MeetingTaskListExternalSourceTaskInCharge {
    return MeetingTaskListExternalSourceTaskInCharge.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListExternalSourceTaskInCharge>, I>>(
    object: I,
  ): MeetingTaskListExternalSourceTaskInCharge {
    const message = createBaseMeetingTaskListExternalSourceTaskInCharge();
    message.id = object.id ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.extEmployeeId = object.extEmployeeId ?? undefined;
    message.extEmployeeName = object.extEmployeeName ?? undefined;
    return message;
  },
};

function createBaseMeetingAutoAssignParams(): MeetingAutoAssignParams {
  return { from: undefined, to: undefined, pcdti: [], panelCodeId: undefined, jobId: undefined };
}

export const MeetingAutoAssignParams = {
  encode(message: MeetingAutoAssignParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.from !== undefined) {
      writer.uint32(10).string(message.from);
    }
    if (message.to !== undefined) {
      writer.uint32(18).string(message.to);
    }
    for (const v of message.pcdti) {
      PpicPanelCodeDepartmentTemplateItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(32).uint64(message.panelCodeId);
    }
    if (message.jobId !== undefined) {
      writer.uint32(40).uint64(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingAutoAssignParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingAutoAssignParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.from = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pcdti.push(PpicPanelCodeDepartmentTemplateItem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingAutoAssignParams {
    return {
      from: isSet(object.from) ? String(object.from) : undefined,
      to: isSet(object.to) ? String(object.to) : undefined,
      pcdti: Array.isArray(object?.pcdti)
        ? object.pcdti.map((e: any) => PpicPanelCodeDepartmentTemplateItem.fromJSON(e))
        : [],
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
    };
  },

  toJSON(message: MeetingAutoAssignParams): unknown {
    const obj: any = {};
    message.from !== undefined && (obj.from = message.from);
    message.to !== undefined && (obj.to = message.to);
    if (message.pcdti) {
      obj.pcdti = message.pcdti.map((e) => e ? PpicPanelCodeDepartmentTemplateItem.toJSON(e) : undefined);
    } else {
      obj.pcdti = [];
    }
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingAutoAssignParams>, I>>(base?: I): MeetingAutoAssignParams {
    return MeetingAutoAssignParams.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingAutoAssignParams>, I>>(object: I): MeetingAutoAssignParams {
    const message = createBaseMeetingAutoAssignParams();
    message.from = object.from ?? undefined;
    message.to = object.to ?? undefined;
    message.pcdti = object.pcdti?.map((e) => PpicPanelCodeDepartmentTemplateItem.fromPartial(e)) || [];
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.jobId = object.jobId ?? undefined;
    return message;
  },
};

function createBaseKpiDeptCache(): KpiDeptCache {
  return {
    deptId: "0",
    lastCreated: "",
    times: [],
    deptPersonnel: 0,
    attendanceNotes: [],
    lastPlan: undefined,
    jobToday: [],
    jobTomorrow: [],
    userId: undefined,
  };
}

export const KpiDeptCache = {
  encode(message: KpiDeptCache, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deptId !== "0") {
      writer.uint32(8).uint64(message.deptId);
    }
    if (message.lastCreated !== "") {
      writer.uint32(18).string(message.lastCreated);
    }
    for (const v of message.times) {
      KpiDeptCacheTime.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.deptPersonnel !== 0) {
      writer.uint32(32).int32(message.deptPersonnel);
    }
    for (const v of message.attendanceNotes) {
      KpiDeptCacheAttendanceNote.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.lastPlan !== undefined) {
      writer.uint32(50).string(message.lastPlan);
    }
    for (const v of message.jobToday) {
      KpiDeptCacheJob.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.jobTomorrow) {
      KpiDeptCacheJob.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.userId !== undefined) {
      writer.uint32(72).uint64(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCache {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCache();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deptId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastCreated = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.times.push(KpiDeptCacheTime.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deptPersonnel = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.attendanceNotes.push(KpiDeptCacheAttendanceNote.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastPlan = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.jobToday.push(KpiDeptCacheJob.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.jobTomorrow.push(KpiDeptCacheJob.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.userId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCache {
    return {
      deptId: isSet(object.deptId) ? String(object.deptId) : "0",
      lastCreated: isSet(object.lastCreated) ? String(object.lastCreated) : "",
      times: Array.isArray(object?.times) ? object.times.map((e: any) => KpiDeptCacheTime.fromJSON(e)) : [],
      deptPersonnel: isSet(object.deptPersonnel) ? Number(object.deptPersonnel) : 0,
      attendanceNotes: Array.isArray(object?.attendanceNotes)
        ? object.attendanceNotes.map((e: any) => KpiDeptCacheAttendanceNote.fromJSON(e))
        : [],
      lastPlan: isSet(object.lastPlan) ? String(object.lastPlan) : undefined,
      jobToday: Array.isArray(object?.jobToday) ? object.jobToday.map((e: any) => KpiDeptCacheJob.fromJSON(e)) : [],
      jobTomorrow: Array.isArray(object?.jobTomorrow)
        ? object.jobTomorrow.map((e: any) => KpiDeptCacheJob.fromJSON(e))
        : [],
      userId: isSet(object.userId) ? String(object.userId) : undefined,
    };
  },

  toJSON(message: KpiDeptCache): unknown {
    const obj: any = {};
    message.deptId !== undefined && (obj.deptId = message.deptId);
    message.lastCreated !== undefined && (obj.lastCreated = message.lastCreated);
    if (message.times) {
      obj.times = message.times.map((e) => e ? KpiDeptCacheTime.toJSON(e) : undefined);
    } else {
      obj.times = [];
    }
    message.deptPersonnel !== undefined && (obj.deptPersonnel = Math.round(message.deptPersonnel));
    if (message.attendanceNotes) {
      obj.attendanceNotes = message.attendanceNotes.map((e) => e ? KpiDeptCacheAttendanceNote.toJSON(e) : undefined);
    } else {
      obj.attendanceNotes = [];
    }
    message.lastPlan !== undefined && (obj.lastPlan = message.lastPlan);
    if (message.jobToday) {
      obj.jobToday = message.jobToday.map((e) => e ? KpiDeptCacheJob.toJSON(e) : undefined);
    } else {
      obj.jobToday = [];
    }
    if (message.jobTomorrow) {
      obj.jobTomorrow = message.jobTomorrow.map((e) => e ? KpiDeptCacheJob.toJSON(e) : undefined);
    } else {
      obj.jobTomorrow = [];
    }
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCache>, I>>(base?: I): KpiDeptCache {
    return KpiDeptCache.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCache>, I>>(object: I): KpiDeptCache {
    const message = createBaseKpiDeptCache();
    message.deptId = object.deptId ?? "0";
    message.lastCreated = object.lastCreated ?? "";
    message.times = object.times?.map((e) => KpiDeptCacheTime.fromPartial(e)) || [];
    message.deptPersonnel = object.deptPersonnel ?? 0;
    message.attendanceNotes = object.attendanceNotes?.map((e) => KpiDeptCacheAttendanceNote.fromPartial(e)) || [];
    message.lastPlan = object.lastPlan ?? undefined;
    message.jobToday = object.jobToday?.map((e) => KpiDeptCacheJob.fromPartial(e)) || [];
    message.jobTomorrow = object.jobTomorrow?.map((e) => KpiDeptCacheJob.fromPartial(e)) || [];
    message.userId = object.userId ?? undefined;
    return message;
  },
};

function createBaseKpiDeptCacheJob(): KpiDeptCacheJob {
  return { id: undefined, name: undefined };
}

export const KpiDeptCacheJob = {
  encode(message: KpiDeptCacheJob, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCacheJob {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCacheJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCacheJob {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: KpiDeptCacheJob): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCacheJob>, I>>(base?: I): KpiDeptCacheJob {
    return KpiDeptCacheJob.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCacheJob>, I>>(object: I): KpiDeptCacheJob {
    const message = createBaseKpiDeptCacheJob();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseKpiDeptCacheAttendanceNote(): KpiDeptCacheAttendanceNote {
  return { attendanceNoteTypeId: undefined, types: [] };
}

export const KpiDeptCacheAttendanceNote = {
  encode(message: KpiDeptCacheAttendanceNote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.attendanceNoteTypeId !== undefined) {
      writer.uint32(8).uint64(message.attendanceNoteTypeId);
    }
    for (const v of message.types) {
      KpiDeptCacheAttendanceNoteTypeRange.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCacheAttendanceNote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCacheAttendanceNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.attendanceNoteTypeId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.types.push(KpiDeptCacheAttendanceNoteTypeRange.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCacheAttendanceNote {
    return {
      attendanceNoteTypeId: isSet(object.attendanceNoteTypeId) ? String(object.attendanceNoteTypeId) : undefined,
      types: Array.isArray(object?.types)
        ? object.types.map((e: any) => KpiDeptCacheAttendanceNoteTypeRange.fromJSON(e))
        : [],
    };
  },

  toJSON(message: KpiDeptCacheAttendanceNote): unknown {
    const obj: any = {};
    message.attendanceNoteTypeId !== undefined && (obj.attendanceNoteTypeId = message.attendanceNoteTypeId);
    if (message.types) {
      obj.types = message.types.map((e) => e ? KpiDeptCacheAttendanceNoteTypeRange.toJSON(e) : undefined);
    } else {
      obj.types = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCacheAttendanceNote>, I>>(base?: I): KpiDeptCacheAttendanceNote {
    return KpiDeptCacheAttendanceNote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCacheAttendanceNote>, I>>(object: I): KpiDeptCacheAttendanceNote {
    const message = createBaseKpiDeptCacheAttendanceNote();
    message.attendanceNoteTypeId = object.attendanceNoteTypeId ?? undefined;
    message.types = object.types?.map((e) => KpiDeptCacheAttendanceNoteTypeRange.fromPartial(e)) || [];
    return message;
  },
};

function createBaseKpiDeptCacheAttendanceNoteTypeRange(): KpiDeptCacheAttendanceNoteTypeRange {
  return { range: 0, value: undefined, users: [] };
}

export const KpiDeptCacheAttendanceNoteTypeRange = {
  encode(message: KpiDeptCacheAttendanceNoteTypeRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.range !== 0) {
      writer.uint32(8).int32(message.range);
    }
    if (message.value !== undefined) {
      writer.uint32(17).double(message.value);
    }
    for (const v of message.users) {
      KpiDeptCacheAttendanceNoteTypeRangeUser.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCacheAttendanceNoteTypeRange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCacheAttendanceNoteTypeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.range = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.users.push(KpiDeptCacheAttendanceNoteTypeRangeUser.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCacheAttendanceNoteTypeRange {
    return {
      range: isSet(object.range) ? kpiDeptCacheCategoryRangeEnumFromJSON(object.range) : 0,
      value: isSet(object.value) ? Number(object.value) : undefined,
      users: Array.isArray(object?.users)
        ? object.users.map((e: any) => KpiDeptCacheAttendanceNoteTypeRangeUser.fromJSON(e))
        : [],
    };
  },

  toJSON(message: KpiDeptCacheAttendanceNoteTypeRange): unknown {
    const obj: any = {};
    message.range !== undefined && (obj.range = kpiDeptCacheCategoryRangeEnumToJSON(message.range));
    message.value !== undefined && (obj.value = message.value);
    if (message.users) {
      obj.users = message.users.map((e) => e ? KpiDeptCacheAttendanceNoteTypeRangeUser.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCacheAttendanceNoteTypeRange>, I>>(
    base?: I,
  ): KpiDeptCacheAttendanceNoteTypeRange {
    return KpiDeptCacheAttendanceNoteTypeRange.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCacheAttendanceNoteTypeRange>, I>>(
    object: I,
  ): KpiDeptCacheAttendanceNoteTypeRange {
    const message = createBaseKpiDeptCacheAttendanceNoteTypeRange();
    message.range = object.range ?? 0;
    message.value = object.value ?? undefined;
    message.users = object.users?.map((e) => KpiDeptCacheAttendanceNoteTypeRangeUser.fromPartial(e)) || [];
    return message;
  },
};

function createBaseKpiDeptCacheAttendanceNoteTypeRangeUser(): KpiDeptCacheAttendanceNoteTypeRangeUser {
  return { extUserId: undefined };
}

export const KpiDeptCacheAttendanceNoteTypeRangeUser = {
  encode(message: KpiDeptCacheAttendanceNoteTypeRangeUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCacheAttendanceNoteTypeRangeUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCacheAttendanceNoteTypeRangeUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCacheAttendanceNoteTypeRangeUser {
    return { extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined };
  },

  toJSON(message: KpiDeptCacheAttendanceNoteTypeRangeUser): unknown {
    const obj: any = {};
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCacheAttendanceNoteTypeRangeUser>, I>>(
    base?: I,
  ): KpiDeptCacheAttendanceNoteTypeRangeUser {
    return KpiDeptCacheAttendanceNoteTypeRangeUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCacheAttendanceNoteTypeRangeUser>, I>>(
    object: I,
  ): KpiDeptCacheAttendanceNoteTypeRangeUser {
    const message = createBaseKpiDeptCacheAttendanceNoteTypeRangeUser();
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseKpiDeptCacheTime(): KpiDeptCacheTime {
  return { time: 0, categories: [] };
}

export const KpiDeptCacheTime = {
  encode(message: KpiDeptCacheTime, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== 0) {
      writer.uint32(8).int32(message.time);
    }
    for (const v of message.categories) {
      KpiDeptCategory.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCacheTime {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCacheTime();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.time = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.categories.push(KpiDeptCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCacheTime {
    return {
      time: isSet(object.time) ? kpiDeptCacheCategoryTimeEnumFromJSON(object.time) : 0,
      categories: Array.isArray(object?.categories)
        ? object.categories.map((e: any) => KpiDeptCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: KpiDeptCacheTime): unknown {
    const obj: any = {};
    message.time !== undefined && (obj.time = kpiDeptCacheCategoryTimeEnumToJSON(message.time));
    if (message.categories) {
      obj.categories = message.categories.map((e) => e ? KpiDeptCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCacheTime>, I>>(base?: I): KpiDeptCacheTime {
    return KpiDeptCacheTime.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCacheTime>, I>>(object: I): KpiDeptCacheTime {
    const message = createBaseKpiDeptCacheTime();
    message.time = object.time ?? 0;
    message.categories = object.categories?.map((e) => KpiDeptCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseKpiDeptCategory(): KpiDeptCategory {
  return { category: 0, dates: [] };
}

export const KpiDeptCategory = {
  encode(message: KpiDeptCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    for (const v of message.dates) {
      KpiDeptCategoryRangeValue.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dates.push(KpiDeptCategoryRangeValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCategory {
    return {
      category: isSet(object.category) ? kpiDeptCacheCategoryEnumFromJSON(object.category) : 0,
      dates: Array.isArray(object?.dates) ? object.dates.map((e: any) => KpiDeptCategoryRangeValue.fromJSON(e)) : [],
    };
  },

  toJSON(message: KpiDeptCategory): unknown {
    const obj: any = {};
    message.category !== undefined && (obj.category = kpiDeptCacheCategoryEnumToJSON(message.category));
    if (message.dates) {
      obj.dates = message.dates.map((e) => e ? KpiDeptCategoryRangeValue.toJSON(e) : undefined);
    } else {
      obj.dates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCategory>, I>>(base?: I): KpiDeptCategory {
    return KpiDeptCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCategory>, I>>(object: I): KpiDeptCategory {
    const message = createBaseKpiDeptCategory();
    message.category = object.category ?? 0;
    message.dates = object.dates?.map((e) => KpiDeptCategoryRangeValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseKpiDeptCategoryRangeValue(): KpiDeptCategoryRangeValue {
  return {
    identifier: undefined,
    customStart: undefined,
    customEnd: undefined,
    value: undefined,
    range: 0,
    finalStart: undefined,
    finalEnd: undefined,
    totalWorkOrders: undefined,
    totalWorkOrdersDone: undefined,
    numDays: 0,
    totalCapacity: 0,
    capacityDividedByDaysNum: 0,
    capacityDividedByDaysNumAndPersonnel: 0,
    stringValue: undefined,
    totalCapacityDone: 0,
    capacityDividedByDaysNumDone: 0,
    capacityDividedByDaysNumAndPersonnelDone: 0,
    usersCountedInWorkingHour: undefined,
  };
}

export const KpiDeptCategoryRangeValue = {
  encode(message: KpiDeptCategoryRangeValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== undefined) {
      writer.uint32(8).int32(message.identifier);
    }
    if (message.customStart !== undefined) {
      writer.uint32(18).string(message.customStart);
    }
    if (message.customEnd !== undefined) {
      writer.uint32(26).string(message.customEnd);
    }
    if (message.value !== undefined) {
      writer.uint32(33).double(message.value);
    }
    if (message.range !== 0) {
      writer.uint32(40).int32(message.range);
    }
    if (message.finalStart !== undefined) {
      writer.uint32(50).string(message.finalStart);
    }
    if (message.finalEnd !== undefined) {
      writer.uint32(58).string(message.finalEnd);
    }
    if (message.totalWorkOrders !== undefined) {
      writer.uint32(64).int32(message.totalWorkOrders);
    }
    if (message.totalWorkOrdersDone !== undefined) {
      writer.uint32(72).int32(message.totalWorkOrdersDone);
    }
    if (message.numDays !== 0) {
      writer.uint32(80).int32(message.numDays);
    }
    if (message.totalCapacity !== 0) {
      writer.uint32(89).double(message.totalCapacity);
    }
    if (message.capacityDividedByDaysNum !== 0) {
      writer.uint32(97).double(message.capacityDividedByDaysNum);
    }
    if (message.capacityDividedByDaysNumAndPersonnel !== 0) {
      writer.uint32(105).double(message.capacityDividedByDaysNumAndPersonnel);
    }
    if (message.stringValue !== undefined) {
      writer.uint32(114).string(message.stringValue);
    }
    if (message.totalCapacityDone !== 0) {
      writer.uint32(121).double(message.totalCapacityDone);
    }
    if (message.capacityDividedByDaysNumDone !== 0) {
      writer.uint32(129).double(message.capacityDividedByDaysNumDone);
    }
    if (message.capacityDividedByDaysNumAndPersonnelDone !== 0) {
      writer.uint32(137).double(message.capacityDividedByDaysNumAndPersonnelDone);
    }
    if (message.usersCountedInWorkingHour !== undefined) {
      writer.uint32(145).double(message.usersCountedInWorkingHour);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KpiDeptCategoryRangeValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKpiDeptCategoryRangeValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.identifier = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customStart = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.customEnd = reader.string();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.value = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.range = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.finalStart = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.finalEnd = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.totalWorkOrders = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.totalWorkOrdersDone = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.numDays = reader.int32();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.totalCapacity = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.capacityDividedByDaysNum = reader.double();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.capacityDividedByDaysNumAndPersonnel = reader.double();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.stringValue = reader.string();
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.totalCapacityDone = reader.double();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.capacityDividedByDaysNumDone = reader.double();
          continue;
        case 17:
          if (tag !== 137) {
            break;
          }

          message.capacityDividedByDaysNumAndPersonnelDone = reader.double();
          continue;
        case 18:
          if (tag !== 145) {
            break;
          }

          message.usersCountedInWorkingHour = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KpiDeptCategoryRangeValue {
    return {
      identifier: isSet(object.identifier) ? Number(object.identifier) : undefined,
      customStart: isSet(object.customStart) ? String(object.customStart) : undefined,
      customEnd: isSet(object.customEnd) ? String(object.customEnd) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
      range: isSet(object.range) ? kpiDeptCacheCategoryRangeEnumFromJSON(object.range) : 0,
      finalStart: isSet(object.finalStart) ? String(object.finalStart) : undefined,
      finalEnd: isSet(object.finalEnd) ? String(object.finalEnd) : undefined,
      totalWorkOrders: isSet(object.totalWorkOrders) ? Number(object.totalWorkOrders) : undefined,
      totalWorkOrdersDone: isSet(object.totalWorkOrdersDone) ? Number(object.totalWorkOrdersDone) : undefined,
      numDays: isSet(object.numDays) ? Number(object.numDays) : 0,
      totalCapacity: isSet(object.totalCapacity) ? Number(object.totalCapacity) : 0,
      capacityDividedByDaysNum: isSet(object.capacityDividedByDaysNum) ? Number(object.capacityDividedByDaysNum) : 0,
      capacityDividedByDaysNumAndPersonnel: isSet(object.capacityDividedByDaysNumAndPersonnel)
        ? Number(object.capacityDividedByDaysNumAndPersonnel)
        : 0,
      stringValue: isSet(object.stringValue) ? String(object.stringValue) : undefined,
      totalCapacityDone: isSet(object.totalCapacityDone) ? Number(object.totalCapacityDone) : 0,
      capacityDividedByDaysNumDone: isSet(object.capacityDividedByDaysNumDone)
        ? Number(object.capacityDividedByDaysNumDone)
        : 0,
      capacityDividedByDaysNumAndPersonnelDone: isSet(object.capacityDividedByDaysNumAndPersonnelDone)
        ? Number(object.capacityDividedByDaysNumAndPersonnelDone)
        : 0,
      usersCountedInWorkingHour: isSet(object.usersCountedInWorkingHour)
        ? Number(object.usersCountedInWorkingHour)
        : undefined,
    };
  },

  toJSON(message: KpiDeptCategoryRangeValue): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = Math.round(message.identifier));
    message.customStart !== undefined && (obj.customStart = message.customStart);
    message.customEnd !== undefined && (obj.customEnd = message.customEnd);
    message.value !== undefined && (obj.value = message.value);
    message.range !== undefined && (obj.range = kpiDeptCacheCategoryRangeEnumToJSON(message.range));
    message.finalStart !== undefined && (obj.finalStart = message.finalStart);
    message.finalEnd !== undefined && (obj.finalEnd = message.finalEnd);
    message.totalWorkOrders !== undefined && (obj.totalWorkOrders = Math.round(message.totalWorkOrders));
    message.totalWorkOrdersDone !== undefined && (obj.totalWorkOrdersDone = Math.round(message.totalWorkOrdersDone));
    message.numDays !== undefined && (obj.numDays = Math.round(message.numDays));
    message.totalCapacity !== undefined && (obj.totalCapacity = message.totalCapacity);
    message.capacityDividedByDaysNum !== undefined && (obj.capacityDividedByDaysNum = message.capacityDividedByDaysNum);
    message.capacityDividedByDaysNumAndPersonnel !== undefined &&
      (obj.capacityDividedByDaysNumAndPersonnel = message.capacityDividedByDaysNumAndPersonnel);
    message.stringValue !== undefined && (obj.stringValue = message.stringValue);
    message.totalCapacityDone !== undefined && (obj.totalCapacityDone = message.totalCapacityDone);
    message.capacityDividedByDaysNumDone !== undefined &&
      (obj.capacityDividedByDaysNumDone = message.capacityDividedByDaysNumDone);
    message.capacityDividedByDaysNumAndPersonnelDone !== undefined &&
      (obj.capacityDividedByDaysNumAndPersonnelDone = message.capacityDividedByDaysNumAndPersonnelDone);
    message.usersCountedInWorkingHour !== undefined &&
      (obj.usersCountedInWorkingHour = message.usersCountedInWorkingHour);
    return obj;
  },

  create<I extends Exact<DeepPartial<KpiDeptCategoryRangeValue>, I>>(base?: I): KpiDeptCategoryRangeValue {
    return KpiDeptCategoryRangeValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KpiDeptCategoryRangeValue>, I>>(object: I): KpiDeptCategoryRangeValue {
    const message = createBaseKpiDeptCategoryRangeValue();
    message.identifier = object.identifier ?? undefined;
    message.customStart = object.customStart ?? undefined;
    message.customEnd = object.customEnd ?? undefined;
    message.value = object.value ?? undefined;
    message.range = object.range ?? 0;
    message.finalStart = object.finalStart ?? undefined;
    message.finalEnd = object.finalEnd ?? undefined;
    message.totalWorkOrders = object.totalWorkOrders ?? undefined;
    message.totalWorkOrdersDone = object.totalWorkOrdersDone ?? undefined;
    message.numDays = object.numDays ?? 0;
    message.totalCapacity = object.totalCapacity ?? 0;
    message.capacityDividedByDaysNum = object.capacityDividedByDaysNum ?? 0;
    message.capacityDividedByDaysNumAndPersonnel = object.capacityDividedByDaysNumAndPersonnel ?? 0;
    message.stringValue = object.stringValue ?? undefined;
    message.totalCapacityDone = object.totalCapacityDone ?? 0;
    message.capacityDividedByDaysNumDone = object.capacityDividedByDaysNumDone ?? 0;
    message.capacityDividedByDaysNumAndPersonnelDone = object.capacityDividedByDaysNumAndPersonnelDone ?? 0;
    message.usersCountedInWorkingHour = object.usersCountedInWorkingHour ?? undefined;
    return message;
  },
};

function createBaseMeetingAttendanceNote(): MeetingAttendanceNote {
  return {
    masterJavaBaseModel: undefined,
    note: undefined,
    date: undefined,
    hrApproved: undefined,
    extHrApprovedById: undefined,
    lat: undefined,
    lon: undefined,
    attendanceNoteTypeId: undefined,
    extUserId: undefined,
    isAffectingLeaveBalance: undefined,
    fromBulkAssignment: undefined,
  };
}

export const MeetingAttendanceNote = {
  encode(message: MeetingAttendanceNote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.note !== undefined) {
      writer.uint32(18).string(message.note);
    }
    if (message.date !== undefined) {
      writer.uint32(26).string(message.date);
    }
    if (message.hrApproved !== undefined) {
      writer.uint32(34).string(message.hrApproved);
    }
    if (message.extHrApprovedById !== undefined) {
      writer.uint32(40).uint64(message.extHrApprovedById);
    }
    if (message.lat !== undefined) {
      writer.uint32(49).double(message.lat);
    }
    if (message.lon !== undefined) {
      writer.uint32(57).double(message.lon);
    }
    if (message.attendanceNoteTypeId !== undefined) {
      writer.uint32(64).uint64(message.attendanceNoteTypeId);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(72).uint64(message.extUserId);
    }
    if (message.isAffectingLeaveBalance !== undefined) {
      writer.uint32(80).bool(message.isAffectingLeaveBalance);
    }
    if (message.fromBulkAssignment !== undefined) {
      writer.uint32(88).bool(message.fromBulkAssignment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingAttendanceNote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingAttendanceNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.note = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.date = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.hrApproved = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extHrApprovedById = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.lon = reader.double();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.attendanceNoteTypeId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isAffectingLeaveBalance = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.fromBulkAssignment = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingAttendanceNote {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      note: isSet(object.note) ? String(object.note) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      hrApproved: isSet(object.hrApproved) ? String(object.hrApproved) : undefined,
      extHrApprovedById: isSet(object.extHrApprovedById) ? String(object.extHrApprovedById) : undefined,
      lat: isSet(object.lat) ? Number(object.lat) : undefined,
      lon: isSet(object.lon) ? Number(object.lon) : undefined,
      attendanceNoteTypeId: isSet(object.attendanceNoteTypeId) ? String(object.attendanceNoteTypeId) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      isAffectingLeaveBalance: isSet(object.isAffectingLeaveBalance)
        ? Boolean(object.isAffectingLeaveBalance)
        : undefined,
      fromBulkAssignment: isSet(object.fromBulkAssignment) ? Boolean(object.fromBulkAssignment) : undefined,
    };
  },

  toJSON(message: MeetingAttendanceNote): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.note !== undefined && (obj.note = message.note);
    message.date !== undefined && (obj.date = message.date);
    message.hrApproved !== undefined && (obj.hrApproved = message.hrApproved);
    message.extHrApprovedById !== undefined && (obj.extHrApprovedById = message.extHrApprovedById);
    message.lat !== undefined && (obj.lat = message.lat);
    message.lon !== undefined && (obj.lon = message.lon);
    message.attendanceNoteTypeId !== undefined && (obj.attendanceNoteTypeId = message.attendanceNoteTypeId);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.isAffectingLeaveBalance !== undefined && (obj.isAffectingLeaveBalance = message.isAffectingLeaveBalance);
    message.fromBulkAssignment !== undefined && (obj.fromBulkAssignment = message.fromBulkAssignment);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingAttendanceNote>, I>>(base?: I): MeetingAttendanceNote {
    return MeetingAttendanceNote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingAttendanceNote>, I>>(object: I): MeetingAttendanceNote {
    const message = createBaseMeetingAttendanceNote();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.note = object.note ?? undefined;
    message.date = object.date ?? undefined;
    message.hrApproved = object.hrApproved ?? undefined;
    message.extHrApprovedById = object.extHrApprovedById ?? undefined;
    message.lat = object.lat ?? undefined;
    message.lon = object.lon ?? undefined;
    message.attendanceNoteTypeId = object.attendanceNoteTypeId ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.isAffectingLeaveBalance = object.isAffectingLeaveBalance ?? undefined;
    message.fromBulkAssignment = object.fromBulkAssignment ?? undefined;
    return message;
  },
};

function createBaseMeetingAttendanceNotes(): MeetingAttendanceNotes {
  return { notes: [] };
}

export const MeetingAttendanceNotes = {
  encode(message: MeetingAttendanceNotes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notes) {
      MeetingAttendanceNote.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingAttendanceNotes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingAttendanceNotes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notes.push(MeetingAttendanceNote.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingAttendanceNotes {
    return {
      notes: Array.isArray(object?.notes) ? object.notes.map((e: any) => MeetingAttendanceNote.fromJSON(e)) : [],
    };
  },

  toJSON(message: MeetingAttendanceNotes): unknown {
    const obj: any = {};
    if (message.notes) {
      obj.notes = message.notes.map((e) => e ? MeetingAttendanceNote.toJSON(e) : undefined);
    } else {
      obj.notes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingAttendanceNotes>, I>>(base?: I): MeetingAttendanceNotes {
    return MeetingAttendanceNotes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingAttendanceNotes>, I>>(object: I): MeetingAttendanceNotes {
    const message = createBaseMeetingAttendanceNotes();
    message.notes = object.notes?.map((e) => MeetingAttendanceNote.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingAttendanceNoteType(): MeetingAttendanceNoteType {
  return {
    masterJavaBaseModel: undefined,
    attendanceNoteId: undefined,
    name: undefined,
    alias: undefined,
    uniqIdentifier: undefined,
    isAffectingLeaveBalance: undefined,
    doesNotReduceAttendance: undefined,
  };
}

export const MeetingAttendanceNoteType = {
  encode(message: MeetingAttendanceNoteType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.attendanceNoteId !== undefined) {
      writer.uint32(16).uint64(message.attendanceNoteId);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.alias !== undefined) {
      writer.uint32(34).string(message.alias);
    }
    if (message.uniqIdentifier !== undefined) {
      writer.uint32(40).int32(message.uniqIdentifier);
    }
    if (message.isAffectingLeaveBalance !== undefined) {
      writer.uint32(48).bool(message.isAffectingLeaveBalance);
    }
    if (message.doesNotReduceAttendance !== undefined) {
      writer.uint32(56).bool(message.doesNotReduceAttendance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingAttendanceNoteType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingAttendanceNoteType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.attendanceNoteId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.alias = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.uniqIdentifier = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isAffectingLeaveBalance = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.doesNotReduceAttendance = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingAttendanceNoteType {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      attendanceNoteId: isSet(object.attendanceNoteId) ? String(object.attendanceNoteId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      alias: isSet(object.alias) ? String(object.alias) : undefined,
      uniqIdentifier: isSet(object.uniqIdentifier) ? Number(object.uniqIdentifier) : undefined,
      isAffectingLeaveBalance: isSet(object.isAffectingLeaveBalance)
        ? Boolean(object.isAffectingLeaveBalance)
        : undefined,
      doesNotReduceAttendance: isSet(object.doesNotReduceAttendance)
        ? Boolean(object.doesNotReduceAttendance)
        : undefined,
    };
  },

  toJSON(message: MeetingAttendanceNoteType): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.attendanceNoteId !== undefined && (obj.attendanceNoteId = message.attendanceNoteId);
    message.name !== undefined && (obj.name = message.name);
    message.alias !== undefined && (obj.alias = message.alias);
    message.uniqIdentifier !== undefined && (obj.uniqIdentifier = Math.round(message.uniqIdentifier));
    message.isAffectingLeaveBalance !== undefined && (obj.isAffectingLeaveBalance = message.isAffectingLeaveBalance);
    message.doesNotReduceAttendance !== undefined && (obj.doesNotReduceAttendance = message.doesNotReduceAttendance);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingAttendanceNoteType>, I>>(base?: I): MeetingAttendanceNoteType {
    return MeetingAttendanceNoteType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingAttendanceNoteType>, I>>(object: I): MeetingAttendanceNoteType {
    const message = createBaseMeetingAttendanceNoteType();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.attendanceNoteId = object.attendanceNoteId ?? undefined;
    message.name = object.name ?? undefined;
    message.alias = object.alias ?? undefined;
    message.uniqIdentifier = object.uniqIdentifier ?? undefined;
    message.isAffectingLeaveBalance = object.isAffectingLeaveBalance ?? undefined;
    message.doesNotReduceAttendance = object.doesNotReduceAttendance ?? undefined;
    return message;
  },
};

function createBaseMeetingAttendanceNoteTypes(): MeetingAttendanceNoteTypes {
  return { types: [] };
}

export const MeetingAttendanceNoteTypes = {
  encode(message: MeetingAttendanceNoteTypes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.types) {
      MeetingAttendanceNoteType.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingAttendanceNoteTypes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingAttendanceNoteTypes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.types.push(MeetingAttendanceNoteType.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingAttendanceNoteTypes {
    return {
      types: Array.isArray(object?.types) ? object.types.map((e: any) => MeetingAttendanceNoteType.fromJSON(e)) : [],
    };
  },

  toJSON(message: MeetingAttendanceNoteTypes): unknown {
    const obj: any = {};
    if (message.types) {
      obj.types = message.types.map((e) => e ? MeetingAttendanceNoteType.toJSON(e) : undefined);
    } else {
      obj.types = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingAttendanceNoteTypes>, I>>(base?: I): MeetingAttendanceNoteTypes {
    return MeetingAttendanceNoteTypes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingAttendanceNoteTypes>, I>>(object: I): MeetingAttendanceNoteTypes {
    const message = createBaseMeetingAttendanceNoteTypes();
    message.types = object.types?.map((e) => MeetingAttendanceNoteType.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingSubProject(): MeetingSubProject {
  return { masterJavaBaseModel: undefined };
}

export const MeetingSubProject = {
  encode(message: MeetingSubProject, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingSubProject {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingSubProject();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingSubProject {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingSubProject): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingSubProject>, I>>(base?: I): MeetingSubProject {
    return MeetingSubProject.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingSubProject>, I>>(object: I): MeetingSubProject {
    const message = createBaseMeetingSubProject();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskListTargetDate(): MeetingTaskListTargetDate {
  return { masterJavaBaseModel: undefined };
}

export const MeetingTaskListTargetDate = {
  encode(message: MeetingTaskListTargetDate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListTargetDate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListTargetDate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListTargetDate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingTaskListTargetDate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListTargetDate>, I>>(base?: I): MeetingTaskListTargetDate {
    return MeetingTaskListTargetDate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListTargetDate>, I>>(object: I): MeetingTaskListTargetDate {
    const message = createBaseMeetingTaskListTargetDate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseTaskListTemplate(): TaskListTemplate {
  return { masterJavaBaseModel: undefined };
}

export const TaskListTemplate = {
  encode(message: TaskListTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskListTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskListTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskListTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: TaskListTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskListTemplate>, I>>(base?: I): TaskListTemplate {
    return TaskListTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TaskListTemplate>, I>>(object: I): TaskListTemplate {
    const message = createBaseTaskListTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskListInCharge(): MeetingTaskListInCharge {
  return { masterJavaBaseModel: undefined, extUserId: undefined };
}

export const MeetingTaskListInCharge = {
  encode(message: MeetingTaskListInCharge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListInCharge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListInCharge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListInCharge {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: MeetingTaskListInCharge): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListInCharge>, I>>(base?: I): MeetingTaskListInCharge {
    return MeetingTaskListInCharge.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListInCharge>, I>>(object: I): MeetingTaskListInCharge {
    const message = createBaseMeetingTaskListInCharge();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListSites(): MeetingTaskListSites {
  return { masterJavaBaseModel: undefined, siteId: undefined, site: undefined };
}

export const MeetingTaskListSites = {
  encode(message: MeetingTaskListSites, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.siteId !== undefined) {
      writer.uint32(16).uint64(message.siteId);
    }
    if (message.site !== undefined) {
      MeetingSite.encode(message.site, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListSites {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListSites();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.site = MeetingSite.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListSites {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      siteId: isSet(object.siteId) ? String(object.siteId) : undefined,
      site: isSet(object.site) ? MeetingSite.fromJSON(object.site) : undefined,
    };
  },

  toJSON(message: MeetingTaskListSites): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.siteId !== undefined && (obj.siteId = message.siteId);
    message.site !== undefined && (obj.site = message.site ? MeetingSite.toJSON(message.site) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListSites>, I>>(base?: I): MeetingTaskListSites {
    return MeetingTaskListSites.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListSites>, I>>(object: I): MeetingTaskListSites {
    const message = createBaseMeetingTaskListSites();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.siteId = object.siteId ?? undefined;
    message.site = (object.site !== undefined && object.site !== null)
      ? MeetingSite.fromPartial(object.site)
      : undefined;
    return message;
  },
};

function createBaseMeetingProjectMasterTemplate(): MeetingProjectMasterTemplate {
  return { masterJavaBaseModel: undefined, name: undefined, extDepartmentTemplateId: undefined };
}

export const MeetingProjectMasterTemplate = {
  encode(message: MeetingProjectMasterTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.extDepartmentTemplateId !== undefined) {
      writer.uint32(24).uint64(message.extDepartmentTemplateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProjectMasterTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProjectMasterTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extDepartmentTemplateId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProjectMasterTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      extDepartmentTemplateId: isSet(object.extDepartmentTemplateId)
        ? String(object.extDepartmentTemplateId)
        : undefined,
    };
  },

  toJSON(message: MeetingProjectMasterTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.extDepartmentTemplateId !== undefined && (obj.extDepartmentTemplateId = message.extDepartmentTemplateId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProjectMasterTemplate>, I>>(base?: I): MeetingProjectMasterTemplate {
    return MeetingProjectMasterTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProjectMasterTemplate>, I>>(object: I): MeetingProjectMasterTemplate {
    const message = createBaseMeetingProjectMasterTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.extDepartmentTemplateId = object.extDepartmentTemplateId ?? undefined;
    return message;
  },
};

function createBaseMeetingProjectMasterTemplates(): MeetingProjectMasterTemplates {
  return { templates: [] };
}

export const MeetingProjectMasterTemplates = {
  encode(message: MeetingProjectMasterTemplates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      MeetingProjectMasterTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProjectMasterTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProjectMasterTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(MeetingProjectMasterTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProjectMasterTemplates {
    return {
      templates: Array.isArray(object?.templates)
        ? object.templates.map((e: any) => MeetingProjectMasterTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingProjectMasterTemplates): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) => e ? MeetingProjectMasterTemplate.toJSON(e) : undefined);
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProjectMasterTemplates>, I>>(base?: I): MeetingProjectMasterTemplates {
    return MeetingProjectMasterTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProjectMasterTemplates>, I>>(
    object: I,
  ): MeetingProjectMasterTemplates {
    const message = createBaseMeetingProjectMasterTemplates();
    message.templates = object.templates?.map((e) => MeetingProjectMasterTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingSite(): MeetingSite {
  return {
    masterJavaBaseModel: undefined,
    lat: undefined,
    lon: undefined,
    name: undefined,
    areaId: undefined,
    extPurchaseOrderId: undefined,
  };
}

export const MeetingSite = {
  encode(message: MeetingSite, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.lat !== undefined) {
      writer.uint32(17).double(message.lat);
    }
    if (message.lon !== undefined) {
      writer.uint32(25).double(message.lon);
    }
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    if (message.areaId !== undefined) {
      writer.uint32(40).uint64(message.areaId);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(48).uint64(message.extPurchaseOrderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingSite {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingSite();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.lon = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.areaId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingSite {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      lat: isSet(object.lat) ? Number(object.lat) : undefined,
      lon: isSet(object.lon) ? Number(object.lon) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      areaId: isSet(object.areaId) ? String(object.areaId) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
    };
  },

  toJSON(message: MeetingSite): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.lat !== undefined && (obj.lat = message.lat);
    message.lon !== undefined && (obj.lon = message.lon);
    message.name !== undefined && (obj.name = message.name);
    message.areaId !== undefined && (obj.areaId = message.areaId);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingSite>, I>>(base?: I): MeetingSite {
    return MeetingSite.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingSite>, I>>(object: I): MeetingSite {
    const message = createBaseMeetingSite();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.lat = object.lat ?? undefined;
    message.lon = object.lon ?? undefined;
    message.name = object.name ?? undefined;
    message.areaId = object.areaId ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    return message;
  },
};

function createBaseMeetingSites(): MeetingSites {
  return { sites: [] };
}

export const MeetingSites = {
  encode(message: MeetingSites, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sites) {
      MeetingSite.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingSites {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingSites();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sites.push(MeetingSite.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingSites {
    return { sites: Array.isArray(object?.sites) ? object.sites.map((e: any) => MeetingSite.fromJSON(e)) : [] };
  },

  toJSON(message: MeetingSites): unknown {
    const obj: any = {};
    if (message.sites) {
      obj.sites = message.sites.map((e) => e ? MeetingSite.toJSON(e) : undefined);
    } else {
      obj.sites = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingSites>, I>>(base?: I): MeetingSites {
    return MeetingSites.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingSites>, I>>(object: I): MeetingSites {
    const message = createBaseMeetingSites();
    message.sites = object.sites?.map((e) => MeetingSite.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTaskListCashAdvancements(): TaskListCashAdvancements {
  return { masterJavaBaseModel: undefined };
}

export const TaskListCashAdvancements = {
  encode(message: TaskListCashAdvancements, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskListCashAdvancements {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskListCashAdvancements();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskListCashAdvancements {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: TaskListCashAdvancements): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskListCashAdvancements>, I>>(base?: I): TaskListCashAdvancements {
    return TaskListCashAdvancements.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TaskListCashAdvancements>, I>>(object: I): TaskListCashAdvancements {
    const message = createBaseTaskListCashAdvancements();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseTaskListCashSettlement(): TaskListCashSettlement {
  return { masterJavaBaseModel: undefined };
}

export const TaskListCashSettlement = {
  encode(message: TaskListCashSettlement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskListCashSettlement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskListCashSettlement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskListCashSettlement {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: TaskListCashSettlement): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskListCashSettlement>, I>>(base?: I): TaskListCashSettlement {
    return TaskListCashSettlement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TaskListCashSettlement>, I>>(object: I): TaskListCashSettlement {
    const message = createBaseTaskListCashSettlement();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskProjectPo(): MeetingTaskProjectPo {
  return { masterJavaBaseModel: undefined };
}

export const MeetingTaskProjectPo = {
  encode(message: MeetingTaskProjectPo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskProjectPo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskProjectPo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskProjectPo {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingTaskProjectPo): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskProjectPo>, I>>(base?: I): MeetingTaskProjectPo {
    return MeetingTaskProjectPo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskProjectPo>, I>>(object: I): MeetingTaskProjectPo {
    const message = createBaseMeetingTaskProjectPo();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseTaskListCashAdvancementWorkOrder(): TaskListCashAdvancementWorkOrder {
  return { masterJavaBaseModel: undefined };
}

export const TaskListCashAdvancementWorkOrder = {
  encode(message: TaskListCashAdvancementWorkOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskListCashAdvancementWorkOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskListCashAdvancementWorkOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskListCashAdvancementWorkOrder {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: TaskListCashAdvancementWorkOrder): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskListCashAdvancementWorkOrder>, I>>(
    base?: I,
  ): TaskListCashAdvancementWorkOrder {
    return TaskListCashAdvancementWorkOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TaskListCashAdvancementWorkOrder>, I>>(
    object: I,
  ): TaskListCashAdvancementWorkOrder {
    const message = createBaseTaskListCashAdvancementWorkOrder();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskListCashAdvancement(): MeetingTaskListCashAdvancement {
  return { masterJavaBaseModel: undefined };
}

export const MeetingTaskListCashAdvancement = {
  encode(message: MeetingTaskListCashAdvancement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListCashAdvancement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListCashAdvancement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListCashAdvancement {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingTaskListCashAdvancement): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListCashAdvancement>, I>>(base?: I): MeetingTaskListCashAdvancement {
    return MeetingTaskListCashAdvancement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListCashAdvancement>, I>>(
    object: I,
  ): MeetingTaskListCashAdvancement {
    const message = createBaseMeetingTaskListCashAdvancement();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskListCashSettlement(): MeetingTaskListCashSettlement {
  return { masterJavaBaseModel: undefined };
}

export const MeetingTaskListCashSettlement = {
  encode(message: MeetingTaskListCashSettlement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListCashSettlement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListCashSettlement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListCashSettlement {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingTaskListCashSettlement): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListCashSettlement>, I>>(base?: I): MeetingTaskListCashSettlement {
    return MeetingTaskListCashSettlement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListCashSettlement>, I>>(
    object: I,
  ): MeetingTaskListCashSettlement {
    const message = createBaseMeetingTaskListCashSettlement();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskListCashAdvancementWorkOrder(): MeetingTaskListCashAdvancementWorkOrder {
  return { masterJavaBaseModel: undefined };
}

export const MeetingTaskListCashAdvancementWorkOrder = {
  encode(message: MeetingTaskListCashAdvancementWorkOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListCashAdvancementWorkOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListCashAdvancementWorkOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListCashAdvancementWorkOrder {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingTaskListCashAdvancementWorkOrder): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListCashAdvancementWorkOrder>, I>>(
    base?: I,
  ): MeetingTaskListCashAdvancementWorkOrder {
    return MeetingTaskListCashAdvancementWorkOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListCashAdvancementWorkOrder>, I>>(
    object: I,
  ): MeetingTaskListCashAdvancementWorkOrder {
    const message = createBaseMeetingTaskListCashAdvancementWorkOrder();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskListView(): MeetingTaskListView {
  return {
    taskList: undefined,
    meetingTaskDeleteIds: [],
    meetingTaskInChargeDeleteIds: [],
    meetingTaskProblemCatalogDeleteIds: [],
  };
}

export const MeetingTaskListView = {
  encode(message: MeetingTaskListView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.taskList !== undefined) {
      MeetingTaskList.encode(message.taskList, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.meetingTaskDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.meetingTaskInChargeDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.meetingTaskProblemCatalogDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.taskList = MeetingTaskList.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag === 16) {
            message.meetingTaskDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.meetingTaskDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.meetingTaskInChargeDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.meetingTaskInChargeDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.meetingTaskProblemCatalogDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.meetingTaskProblemCatalogDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListView {
    return {
      taskList: isSet(object.taskList) ? MeetingTaskList.fromJSON(object.taskList) : undefined,
      meetingTaskDeleteIds: Array.isArray(object?.meetingTaskDeleteIds)
        ? object.meetingTaskDeleteIds.map((e: any) => String(e))
        : [],
      meetingTaskInChargeDeleteIds: Array.isArray(object?.meetingTaskInChargeDeleteIds)
        ? object.meetingTaskInChargeDeleteIds.map((e: any) => String(e))
        : [],
      meetingTaskProblemCatalogDeleteIds: Array.isArray(object?.meetingTaskProblemCatalogDeleteIds)
        ? object.meetingTaskProblemCatalogDeleteIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: MeetingTaskListView): unknown {
    const obj: any = {};
    message.taskList !== undefined &&
      (obj.taskList = message.taskList ? MeetingTaskList.toJSON(message.taskList) : undefined);
    if (message.meetingTaskDeleteIds) {
      obj.meetingTaskDeleteIds = message.meetingTaskDeleteIds.map((e) => e);
    } else {
      obj.meetingTaskDeleteIds = [];
    }
    if (message.meetingTaskInChargeDeleteIds) {
      obj.meetingTaskInChargeDeleteIds = message.meetingTaskInChargeDeleteIds.map((e) => e);
    } else {
      obj.meetingTaskInChargeDeleteIds = [];
    }
    if (message.meetingTaskProblemCatalogDeleteIds) {
      obj.meetingTaskProblemCatalogDeleteIds = message.meetingTaskProblemCatalogDeleteIds.map((e) => e);
    } else {
      obj.meetingTaskProblemCatalogDeleteIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListView>, I>>(base?: I): MeetingTaskListView {
    return MeetingTaskListView.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListView>, I>>(object: I): MeetingTaskListView {
    const message = createBaseMeetingTaskListView();
    message.taskList = (object.taskList !== undefined && object.taskList !== null)
      ? MeetingTaskList.fromPartial(object.taskList)
      : undefined;
    message.meetingTaskDeleteIds = object.meetingTaskDeleteIds?.map((e) => e) || [];
    message.meetingTaskInChargeDeleteIds = object.meetingTaskInChargeDeleteIds?.map((e) => e) || [];
    message.meetingTaskProblemCatalogDeleteIds = object.meetingTaskProblemCatalogDeleteIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseMeetingJourneyCostView(): MeetingJourneyCostView {
  return { taskList: undefined, detail: undefined };
}

export const MeetingJourneyCostView = {
  encode(message: MeetingJourneyCostView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.taskList !== undefined) {
      MeetingTaskList.encode(message.taskList, writer.uint32(10).fork()).ldelim();
    }
    if (message.detail !== undefined) {
      MeetingJourneyCostViewDetail.encode(message.detail, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingJourneyCostView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingJourneyCostView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.taskList = MeetingTaskList.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.detail = MeetingJourneyCostViewDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingJourneyCostView {
    return {
      taskList: isSet(object.taskList) ? MeetingTaskList.fromJSON(object.taskList) : undefined,
      detail: isSet(object.detail) ? MeetingJourneyCostViewDetail.fromJSON(object.detail) : undefined,
    };
  },

  toJSON(message: MeetingJourneyCostView): unknown {
    const obj: any = {};
    message.taskList !== undefined &&
      (obj.taskList = message.taskList ? MeetingTaskList.toJSON(message.taskList) : undefined);
    message.detail !== undefined &&
      (obj.detail = message.detail ? MeetingJourneyCostViewDetail.toJSON(message.detail) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingJourneyCostView>, I>>(base?: I): MeetingJourneyCostView {
    return MeetingJourneyCostView.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingJourneyCostView>, I>>(object: I): MeetingJourneyCostView {
    const message = createBaseMeetingJourneyCostView();
    message.taskList = (object.taskList !== undefined && object.taskList !== null)
      ? MeetingTaskList.fromPartial(object.taskList)
      : undefined;
    message.detail = (object.detail !== undefined && object.detail !== null)
      ? MeetingJourneyCostViewDetail.fromPartial(object.detail)
      : undefined;
    return message;
  },
};

function createBaseMeetingJourneyCostViewDetail(): MeetingJourneyCostViewDetail {
  return { totalRange: undefined, items: [], totalCostIdr: undefined };
}

export const MeetingJourneyCostViewDetail = {
  encode(message: MeetingJourneyCostViewDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.totalRange !== undefined) {
      writer.uint32(17).double(message.totalRange);
    }
    for (const v of message.items) {
      MeetingJourneyCostViewDetailItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.totalCostIdr !== undefined) {
      writer.uint32(33).double(message.totalCostIdr);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingJourneyCostViewDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingJourneyCostViewDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 17) {
            break;
          }

          message.totalRange = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.items.push(MeetingJourneyCostViewDetailItem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.totalCostIdr = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingJourneyCostViewDetail {
    return {
      totalRange: isSet(object.totalRange) ? Number(object.totalRange) : undefined,
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MeetingJourneyCostViewDetailItem.fromJSON(e))
        : [],
      totalCostIdr: isSet(object.totalCostIdr) ? Number(object.totalCostIdr) : undefined,
    };
  },

  toJSON(message: MeetingJourneyCostViewDetail): unknown {
    const obj: any = {};
    message.totalRange !== undefined && (obj.totalRange = message.totalRange);
    if (message.items) {
      obj.items = message.items.map((e) => e ? MeetingJourneyCostViewDetailItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.totalCostIdr !== undefined && (obj.totalCostIdr = message.totalCostIdr);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingJourneyCostViewDetail>, I>>(base?: I): MeetingJourneyCostViewDetail {
    return MeetingJourneyCostViewDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingJourneyCostViewDetail>, I>>(object: I): MeetingJourneyCostViewDetail {
    const message = createBaseMeetingJourneyCostViewDetail();
    message.totalRange = object.totalRange ?? undefined;
    message.items = object.items?.map((e) => MeetingJourneyCostViewDetailItem.fromPartial(e)) || [];
    message.totalCostIdr = object.totalCostIdr ?? undefined;
    return message;
  },
};

function createBaseMeetingJourneyCostViewDetailItem(): MeetingJourneyCostViewDetailItem {
  return { journeyFrom: undefined, journeyTo: undefined, range: undefined };
}

export const MeetingJourneyCostViewDetailItem = {
  encode(message: MeetingJourneyCostViewDetailItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.journeyFrom !== undefined) {
      Journey.encode(message.journeyFrom, writer.uint32(10).fork()).ldelim();
    }
    if (message.journeyTo !== undefined) {
      Journey.encode(message.journeyTo, writer.uint32(18).fork()).ldelim();
    }
    if (message.range !== undefined) {
      writer.uint32(25).double(message.range);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingJourneyCostViewDetailItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingJourneyCostViewDetailItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.journeyFrom = Journey.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.journeyTo = Journey.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.range = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingJourneyCostViewDetailItem {
    return {
      journeyFrom: isSet(object.journeyFrom) ? Journey.fromJSON(object.journeyFrom) : undefined,
      journeyTo: isSet(object.journeyTo) ? Journey.fromJSON(object.journeyTo) : undefined,
      range: isSet(object.range) ? Number(object.range) : undefined,
    };
  },

  toJSON(message: MeetingJourneyCostViewDetailItem): unknown {
    const obj: any = {};
    message.journeyFrom !== undefined &&
      (obj.journeyFrom = message.journeyFrom ? Journey.toJSON(message.journeyFrom) : undefined);
    message.journeyTo !== undefined &&
      (obj.journeyTo = message.journeyTo ? Journey.toJSON(message.journeyTo) : undefined);
    message.range !== undefined && (obj.range = message.range);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingJourneyCostViewDetailItem>, I>>(
    base?: I,
  ): MeetingJourneyCostViewDetailItem {
    return MeetingJourneyCostViewDetailItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingJourneyCostViewDetailItem>, I>>(
    object: I,
  ): MeetingJourneyCostViewDetailItem {
    const message = createBaseMeetingJourneyCostViewDetailItem();
    message.journeyFrom = (object.journeyFrom !== undefined && object.journeyFrom !== null)
      ? Journey.fromPartial(object.journeyFrom)
      : undefined;
    message.journeyTo = (object.journeyTo !== undefined && object.journeyTo !== null)
      ? Journey.fromPartial(object.journeyTo)
      : undefined;
    message.range = object.range ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListsView(): MeetingTaskListsView {
  return { taskLists: [] };
}

export const MeetingTaskListsView = {
  encode(message: MeetingTaskListsView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.taskLists) {
      MeetingTaskListView.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListsView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListsView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.taskLists.push(MeetingTaskListView.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListsView {
    return {
      taskLists: Array.isArray(object?.taskLists)
        ? object.taskLists.map((e: any) => MeetingTaskListView.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingTaskListsView): unknown {
    const obj: any = {};
    if (message.taskLists) {
      obj.taskLists = message.taskLists.map((e) => e ? MeetingTaskListView.toJSON(e) : undefined);
    } else {
      obj.taskLists = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListsView>, I>>(base?: I): MeetingTaskListsView {
    return MeetingTaskListsView.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListsView>, I>>(object: I): MeetingTaskListsView {
    const message = createBaseMeetingTaskListsView();
    message.taskLists = object.taskLists?.map((e) => MeetingTaskListView.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingTaskView(): MeetingMeetingTaskView {
  return { meetingTask: undefined };
}

export const MeetingMeetingTaskView = {
  encode(message: MeetingMeetingTaskView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.meetingTask !== undefined) {
      MeetingMeetingTask.encode(message.meetingTask, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meetingTask = MeetingMeetingTask.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskView {
    return { meetingTask: isSet(object.meetingTask) ? MeetingMeetingTask.fromJSON(object.meetingTask) : undefined };
  },

  toJSON(message: MeetingMeetingTaskView): unknown {
    const obj: any = {};
    message.meetingTask !== undefined &&
      (obj.meetingTask = message.meetingTask ? MeetingMeetingTask.toJSON(message.meetingTask) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskView>, I>>(base?: I): MeetingMeetingTaskView {
    return MeetingMeetingTaskView.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskView>, I>>(object: I): MeetingMeetingTaskView {
    const message = createBaseMeetingMeetingTaskView();
    message.meetingTask = (object.meetingTask !== undefined && object.meetingTask !== null)
      ? MeetingMeetingTask.fromPartial(object.meetingTask)
      : undefined;
    return message;
  },
};

function createBaseMeetingMeetingTasksView(): MeetingMeetingTasksView {
  return { meetingTasks: [] };
}

export const MeetingMeetingTasksView = {
  encode(message: MeetingMeetingTasksView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.meetingTasks) {
      MeetingMeetingTaskView.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTasksView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTasksView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meetingTasks.push(MeetingMeetingTaskView.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTasksView {
    return {
      meetingTasks: Array.isArray(object?.meetingTasks)
        ? object.meetingTasks.map((e: any) => MeetingMeetingTaskView.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMeetingTasksView): unknown {
    const obj: any = {};
    if (message.meetingTasks) {
      obj.meetingTasks = message.meetingTasks.map((e) => e ? MeetingMeetingTaskView.toJSON(e) : undefined);
    } else {
      obj.meetingTasks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTasksView>, I>>(base?: I): MeetingMeetingTasksView {
    return MeetingMeetingTasksView.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTasksView>, I>>(object: I): MeetingMeetingTasksView {
    const message = createBaseMeetingMeetingTasksView();
    message.meetingTasks = object.meetingTasks?.map((e) => MeetingMeetingTaskView.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingTaskProblemCatalogs(): MeetingMeetingTaskProblemCatalogs {
  return { meetingTaskProblemCatalogs: [] };
}

export const MeetingMeetingTaskProblemCatalogs = {
  encode(message: MeetingMeetingTaskProblemCatalogs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.meetingTaskProblemCatalogs) {
      MeetingMeetingTaskProblemCatalog.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskProblemCatalogs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskProblemCatalogs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meetingTaskProblemCatalogs.push(MeetingMeetingTaskProblemCatalog.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskProblemCatalogs {
    return {
      meetingTaskProblemCatalogs: Array.isArray(object?.meetingTaskProblemCatalogs)
        ? object.meetingTaskProblemCatalogs.map((e: any) => MeetingMeetingTaskProblemCatalog.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMeetingTaskProblemCatalogs): unknown {
    const obj: any = {};
    if (message.meetingTaskProblemCatalogs) {
      obj.meetingTaskProblemCatalogs = message.meetingTaskProblemCatalogs.map((e) =>
        e ? MeetingMeetingTaskProblemCatalog.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskProblemCatalogs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskProblemCatalogs>, I>>(
    base?: I,
  ): MeetingMeetingTaskProblemCatalogs {
    return MeetingMeetingTaskProblemCatalogs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskProblemCatalogs>, I>>(
    object: I,
  ): MeetingMeetingTaskProblemCatalogs {
    const message = createBaseMeetingMeetingTaskProblemCatalogs();
    message.meetingTaskProblemCatalogs =
      object.meetingTaskProblemCatalogs?.map((e) => MeetingMeetingTaskProblemCatalog.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingTaskListCashAdvancementArea(): MeetingTaskListCashAdvancementArea {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const MeetingTaskListCashAdvancementArea = {
  encode(message: MeetingTaskListCashAdvancementArea, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListCashAdvancementArea {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListCashAdvancementArea();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListCashAdvancementArea {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: MeetingTaskListCashAdvancementArea): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListCashAdvancementArea>, I>>(
    base?: I,
  ): MeetingTaskListCashAdvancementArea {
    return MeetingTaskListCashAdvancementArea.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListCashAdvancementArea>, I>>(
    object: I,
  ): MeetingTaskListCashAdvancementArea {
    const message = createBaseMeetingTaskListCashAdvancementArea();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskListCashAdvancementAreas(): MeetingTaskListCashAdvancementAreas {
  return { areas: [] };
}

export const MeetingTaskListCashAdvancementAreas = {
  encode(message: MeetingTaskListCashAdvancementAreas, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.areas) {
      MeetingTaskListCashAdvancementArea.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListCashAdvancementAreas {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListCashAdvancementAreas();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.areas.push(MeetingTaskListCashAdvancementArea.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListCashAdvancementAreas {
    return {
      areas: Array.isArray(object?.areas)
        ? object.areas.map((e: any) => MeetingTaskListCashAdvancementArea.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingTaskListCashAdvancementAreas): unknown {
    const obj: any = {};
    if (message.areas) {
      obj.areas = message.areas.map((e) => e ? MeetingTaskListCashAdvancementArea.toJSON(e) : undefined);
    } else {
      obj.areas = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListCashAdvancementAreas>, I>>(
    base?: I,
  ): MeetingTaskListCashAdvancementAreas {
    return MeetingTaskListCashAdvancementAreas.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListCashAdvancementAreas>, I>>(
    object: I,
  ): MeetingTaskListCashAdvancementAreas {
    const message = createBaseMeetingTaskListCashAdvancementAreas();
    message.areas = object.areas?.map((e) => MeetingTaskListCashAdvancementArea.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingTaskProblemCatalog(): MeetingMeetingTaskProblemCatalog {
  return {
    masterJavaBaseModel: undefined,
    meetingTaskId: undefined,
    problemCatalogId: undefined,
    remark: undefined,
    extInternalDeptId: undefined,
    extExternalDeptId: undefined,
    targetDate: undefined,
    extUserId: undefined,
    status: undefined,
  };
}

export const MeetingMeetingTaskProblemCatalog = {
  encode(message: MeetingMeetingTaskProblemCatalog, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.meetingTaskId !== undefined) {
      writer.uint32(16).uint64(message.meetingTaskId);
    }
    if (message.problemCatalogId !== undefined) {
      writer.uint32(24).uint64(message.problemCatalogId);
    }
    if (message.remark !== undefined) {
      writer.uint32(34).string(message.remark);
    }
    if (message.extInternalDeptId !== undefined) {
      writer.uint32(40).uint64(message.extInternalDeptId);
    }
    if (message.extExternalDeptId !== undefined) {
      writer.uint32(48).uint64(message.extExternalDeptId);
    }
    if (message.targetDate !== undefined) {
      writer.uint32(58).string(message.targetDate);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(64).uint64(message.extUserId);
    }
    if (message.status !== undefined) {
      writer.uint32(72).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskProblemCatalog {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskProblemCatalog();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.meetingTaskId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.problemCatalogId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extInternalDeptId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.extExternalDeptId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.targetDate = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskProblemCatalog {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      meetingTaskId: isSet(object.meetingTaskId) ? String(object.meetingTaskId) : undefined,
      problemCatalogId: isSet(object.problemCatalogId) ? String(object.problemCatalogId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      extInternalDeptId: isSet(object.extInternalDeptId) ? String(object.extInternalDeptId) : undefined,
      extExternalDeptId: isSet(object.extExternalDeptId) ? String(object.extExternalDeptId) : undefined,
      targetDate: isSet(object.targetDate) ? String(object.targetDate) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      status: isSet(object.status) ? meetingTaskProblemCatalogStatusFromJSON(object.status) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskProblemCatalog): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.meetingTaskId !== undefined && (obj.meetingTaskId = message.meetingTaskId);
    message.problemCatalogId !== undefined && (obj.problemCatalogId = message.problemCatalogId);
    message.remark !== undefined && (obj.remark = message.remark);
    message.extInternalDeptId !== undefined && (obj.extInternalDeptId = message.extInternalDeptId);
    message.extExternalDeptId !== undefined && (obj.extExternalDeptId = message.extExternalDeptId);
    message.targetDate !== undefined && (obj.targetDate = message.targetDate);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? meetingTaskProblemCatalogStatusToJSON(message.status) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskProblemCatalog>, I>>(
    base?: I,
  ): MeetingMeetingTaskProblemCatalog {
    return MeetingMeetingTaskProblemCatalog.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskProblemCatalog>, I>>(
    object: I,
  ): MeetingMeetingTaskProblemCatalog {
    const message = createBaseMeetingMeetingTaskProblemCatalog();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.meetingTaskId = object.meetingTaskId ?? undefined;
    message.problemCatalogId = object.problemCatalogId ?? undefined;
    message.remark = object.remark ?? undefined;
    message.extInternalDeptId = object.extInternalDeptId ?? undefined;
    message.extExternalDeptId = object.extExternalDeptId ?? undefined;
    message.targetDate = object.targetDate ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.status = object.status ?? undefined;
    return message;
  },
};

function createBaseMeetingProblemCatalog(): MeetingProblemCatalog {
  return {
    masterJavaBaseModel: undefined,
    extDepartmentId: undefined,
    alias: undefined,
    problemType: undefined,
    problemIntIdentifier: undefined,
    extDepartmentVersusId: undefined,
    isQuality: undefined,
    isCost: undefined,
  };
}

export const MeetingProblemCatalog = {
  encode(message: MeetingProblemCatalog, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(16).uint64(message.extDepartmentId);
    }
    if (message.alias !== undefined) {
      writer.uint32(34).string(message.alias);
    }
    if (message.problemType !== undefined) {
      writer.uint32(40).int32(message.problemType);
    }
    if (message.problemIntIdentifier !== undefined) {
      writer.uint32(48).int32(message.problemIntIdentifier);
    }
    if (message.extDepartmentVersusId !== undefined) {
      writer.uint32(56).uint64(message.extDepartmentVersusId);
    }
    if (message.isQuality !== undefined) {
      writer.uint32(64).bool(message.isQuality);
    }
    if (message.isCost !== undefined) {
      writer.uint32(72).bool(message.isCost);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProblemCatalog {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProblemCatalog();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.alias = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.problemType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.problemIntIdentifier = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.extDepartmentVersusId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.isQuality = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isCost = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProblemCatalog {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      alias: isSet(object.alias) ? String(object.alias) : undefined,
      problemType: isSet(object.problemType) ? meetingProblemTypeFromJSON(object.problemType) : undefined,
      problemIntIdentifier: isSet(object.problemIntIdentifier) ? Number(object.problemIntIdentifier) : undefined,
      extDepartmentVersusId: isSet(object.extDepartmentVersusId) ? String(object.extDepartmentVersusId) : undefined,
      isQuality: isSet(object.isQuality) ? Boolean(object.isQuality) : undefined,
      isCost: isSet(object.isCost) ? Boolean(object.isCost) : undefined,
    };
  },

  toJSON(message: MeetingProblemCatalog): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.alias !== undefined && (obj.alias = message.alias);
    message.problemType !== undefined &&
      (obj.problemType = message.problemType !== undefined ? meetingProblemTypeToJSON(message.problemType) : undefined);
    message.problemIntIdentifier !== undefined && (obj.problemIntIdentifier = Math.round(message.problemIntIdentifier));
    message.extDepartmentVersusId !== undefined && (obj.extDepartmentVersusId = message.extDepartmentVersusId);
    message.isQuality !== undefined && (obj.isQuality = message.isQuality);
    message.isCost !== undefined && (obj.isCost = message.isCost);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProblemCatalog>, I>>(base?: I): MeetingProblemCatalog {
    return MeetingProblemCatalog.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProblemCatalog>, I>>(object: I): MeetingProblemCatalog {
    const message = createBaseMeetingProblemCatalog();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.alias = object.alias ?? undefined;
    message.problemType = object.problemType ?? undefined;
    message.problemIntIdentifier = object.problemIntIdentifier ?? undefined;
    message.extDepartmentVersusId = object.extDepartmentVersusId ?? undefined;
    message.isQuality = object.isQuality ?? undefined;
    message.isCost = object.isCost ?? undefined;
    return message;
  },
};

function createBaseMeetingProblemCatalogs(): MeetingProblemCatalogs {
  return { catalogs: [] };
}

export const MeetingProblemCatalogs = {
  encode(message: MeetingProblemCatalogs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.catalogs) {
      MeetingProblemCatalog.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProblemCatalogs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProblemCatalogs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.catalogs.push(MeetingProblemCatalog.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProblemCatalogs {
    return {
      catalogs: Array.isArray(object?.catalogs)
        ? object.catalogs.map((e: any) => MeetingProblemCatalog.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingProblemCatalogs): unknown {
    const obj: any = {};
    if (message.catalogs) {
      obj.catalogs = message.catalogs.map((e) => e ? MeetingProblemCatalog.toJSON(e) : undefined);
    } else {
      obj.catalogs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProblemCatalogs>, I>>(base?: I): MeetingProblemCatalogs {
    return MeetingProblemCatalogs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProblemCatalogs>, I>>(object: I): MeetingProblemCatalogs {
    const message = createBaseMeetingProblemCatalogs();
    message.catalogs = object.catalogs?.map((e) => MeetingProblemCatalog.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingTaskTemplate(): MeetingMeetingTaskTemplate {
  return { masterJavaBaseModel: undefined, name: undefined, items: [] };
}

export const MeetingMeetingTaskTemplate = {
  encode(message: MeetingMeetingTaskTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.items) {
      MeetingMeetingTaskTemplateItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.items.push(MeetingMeetingTaskTemplateItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MeetingMeetingTaskTemplateItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMeetingTaskTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.items) {
      obj.items = message.items.map((e) => e ? MeetingMeetingTaskTemplateItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskTemplate>, I>>(base?: I): MeetingMeetingTaskTemplate {
    return MeetingMeetingTaskTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskTemplate>, I>>(object: I): MeetingMeetingTaskTemplate {
    const message = createBaseMeetingMeetingTaskTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.items = object.items?.map((e) => MeetingMeetingTaskTemplateItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingTaskTemplateItem(): MeetingMeetingTaskTemplateItem {
  return { masterJavaBaseModel: undefined, name: undefined, templateId: undefined, template: undefined };
}

export const MeetingMeetingTaskTemplateItem = {
  encode(message: MeetingMeetingTaskTemplateItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.templateId !== undefined) {
      writer.uint32(24).uint64(message.templateId);
    }
    if (message.template !== undefined) {
      MeetingMeetingTaskTemplate.encode(message.template, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskTemplateItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskTemplateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.templateId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.template = MeetingMeetingTaskTemplate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskTemplateItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      templateId: isSet(object.templateId) ? String(object.templateId) : undefined,
      template: isSet(object.template) ? MeetingMeetingTaskTemplate.fromJSON(object.template) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskTemplateItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.templateId !== undefined && (obj.templateId = message.templateId);
    message.template !== undefined &&
      (obj.template = message.template ? MeetingMeetingTaskTemplate.toJSON(message.template) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskTemplateItem>, I>>(base?: I): MeetingMeetingTaskTemplateItem {
    return MeetingMeetingTaskTemplateItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskTemplateItem>, I>>(
    object: I,
  ): MeetingMeetingTaskTemplateItem {
    const message = createBaseMeetingMeetingTaskTemplateItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.templateId = object.templateId ?? undefined;
    message.template = (object.template !== undefined && object.template !== null)
      ? MeetingMeetingTaskTemplate.fromPartial(object.template)
      : undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskListTemplate(): MeetingMeetingTaskListTemplate {
  return { masterJavaBaseModel: undefined, name: undefined, items: [], workingMins: undefined };
}

export const MeetingMeetingTaskListTemplate = {
  encode(message: MeetingMeetingTaskListTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.items) {
      MeetingMeetingTaskListTemplateItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.workingMins !== undefined) {
      writer.uint32(32).int32(message.workingMins);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskListTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskListTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.items.push(MeetingMeetingTaskListTemplateItem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.workingMins = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskListTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MeetingMeetingTaskListTemplateItem.fromJSON(e))
        : [],
      workingMins: isSet(object.workingMins) ? Number(object.workingMins) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskListTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.items) {
      obj.items = message.items.map((e) => e ? MeetingMeetingTaskListTemplateItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.workingMins !== undefined && (obj.workingMins = Math.round(message.workingMins));
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskListTemplate>, I>>(base?: I): MeetingMeetingTaskListTemplate {
    return MeetingMeetingTaskListTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskListTemplate>, I>>(
    object: I,
  ): MeetingMeetingTaskListTemplate {
    const message = createBaseMeetingMeetingTaskListTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.items = object.items?.map((e) => MeetingMeetingTaskListTemplateItem.fromPartial(e)) || [];
    message.workingMins = object.workingMins ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskListTemplates(): MeetingMeetingTaskListTemplates {
  return { templates: [] };
}

export const MeetingMeetingTaskListTemplates = {
  encode(message: MeetingMeetingTaskListTemplates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      MeetingMeetingTaskListTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskListTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskListTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(MeetingMeetingTaskListTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskListTemplates {
    return {
      templates: Array.isArray(object?.templates)
        ? object.templates.map((e: any) => MeetingMeetingTaskListTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMeetingTaskListTemplates): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) => e ? MeetingMeetingTaskListTemplate.toJSON(e) : undefined);
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskListTemplates>, I>>(base?: I): MeetingMeetingTaskListTemplates {
    return MeetingMeetingTaskListTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskListTemplates>, I>>(
    object: I,
  ): MeetingMeetingTaskListTemplates {
    const message = createBaseMeetingMeetingTaskListTemplates();
    message.templates = object.templates?.map((e) => MeetingMeetingTaskListTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingTaskListTemplateItem(): MeetingMeetingTaskListTemplateItem {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    templateId: undefined,
    template: undefined,
    documentsNeeded: undefined,
    remark: undefined,
    workingMins: undefined,
  };
}

export const MeetingMeetingTaskListTemplateItem = {
  encode(message: MeetingMeetingTaskListTemplateItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.templateId !== undefined) {
      writer.uint32(24).uint64(message.templateId);
    }
    if (message.template !== undefined) {
      MeetingMeetingTaskListTemplate.encode(message.template, writer.uint32(34).fork()).ldelim();
    }
    if (message.documentsNeeded !== undefined) {
      writer.uint32(40).int32(message.documentsNeeded);
    }
    if (message.remark !== undefined) {
      writer.uint32(50).string(message.remark);
    }
    if (message.workingMins !== undefined) {
      writer.uint32(56).int32(message.workingMins);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskListTemplateItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskListTemplateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.templateId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.template = MeetingMeetingTaskListTemplate.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.documentsNeeded = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.workingMins = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskListTemplateItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      templateId: isSet(object.templateId) ? String(object.templateId) : undefined,
      template: isSet(object.template) ? MeetingMeetingTaskListTemplate.fromJSON(object.template) : undefined,
      documentsNeeded: isSet(object.documentsNeeded) ? Number(object.documentsNeeded) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      workingMins: isSet(object.workingMins) ? Number(object.workingMins) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskListTemplateItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.templateId !== undefined && (obj.templateId = message.templateId);
    message.template !== undefined &&
      (obj.template = message.template ? MeetingMeetingTaskListTemplate.toJSON(message.template) : undefined);
    message.documentsNeeded !== undefined && (obj.documentsNeeded = Math.round(message.documentsNeeded));
    message.remark !== undefined && (obj.remark = message.remark);
    message.workingMins !== undefined && (obj.workingMins = Math.round(message.workingMins));
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskListTemplateItem>, I>>(
    base?: I,
  ): MeetingMeetingTaskListTemplateItem {
    return MeetingMeetingTaskListTemplateItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskListTemplateItem>, I>>(
    object: I,
  ): MeetingMeetingTaskListTemplateItem {
    const message = createBaseMeetingMeetingTaskListTemplateItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.templateId = object.templateId ?? undefined;
    message.template = (object.template !== undefined && object.template !== null)
      ? MeetingMeetingTaskListTemplate.fromPartial(object.template)
      : undefined;
    message.documentsNeeded = object.documentsNeeded ?? undefined;
    message.remark = object.remark ?? undefined;
    message.workingMins = object.workingMins ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTask(): MeetingMeetingTask {
  return {
    masterJavaBaseModel: undefined,
    meeting: undefined,
    meetingId: undefined,
    sequenceNumber: undefined,
    description: undefined,
    pic: undefined,
    start: undefined,
    deadline: undefined,
    target: undefined,
    completedDate: undefined,
    remark: undefined,
    problemRemark: undefined,
    durationDays: undefined,
    durationMins: undefined,
    durationMinsActual: undefined,
    status: undefined,
    budget: undefined,
    priority: undefined,
    dependencyUuid: undefined,
    hourStart: undefined,
    hourEnd: undefined,
    allDay: undefined,
    taskPlaceString: undefined,
    dependency: undefined,
    dependencyId: undefined,
    children: [],
    meetingTaskNotes: [],
    meetingTaskIssues: [],
    peopleInCharge: [],
    meetingTaskTargetDates: [],
    meetingTaskInCharges: [],
    project: undefined,
    meetingName: undefined,
    meetingLink: undefined,
    meetingOnline: undefined,
    meetingPlace: undefined,
    meetingRoom: undefined,
    meetingPlatform: undefined,
    meetingSummary: undefined,
    projectName: undefined,
    projectIdLong: undefined,
    subApp: undefined,
    subAppId: undefined,
    note: undefined,
    hasAlert: undefined,
    secondsToTaskAlert: undefined,
    travelTimeSecs: undefined,
    todoType: undefined,
    travelTimeMins: undefined,
    meetingTaskDepartments: [],
    statusSubType: undefined,
    taskList: undefined,
    taskListId: undefined,
    taskListIdNo: undefined,
    rateStars: undefined,
    lat: undefined,
    lon: undefined,
    extLocationById: undefined,
    meetingTaskRemarks: [],
    meetingTaskAttachment: [],
    extSubProcessId: undefined,
    extSubProcessCommonId: undefined,
    meetingTaskComments: [],
    meetingTaskProblemCatalogs: [],
    journeys: [],
    fromMins: undefined,
    toMins: undefined,
    fromHour: undefined,
    toHour: undefined,
    extPicCompletedDate: undefined,
    isNotClear: undefined,
    qty: undefined,
    qcPassedCompletedDate: undefined,
    qaPassedCompletedDate: undefined,
    extPicCompletedTimestamp: undefined,
    extRcemSubProcessId: undefined,
    extPanelCodeDepartmentTemplateItemId: undefined,
    meetingTaskSerialNumbers: [],
    weight: undefined,
    okrChildrenTaskList: [],
    abcReferenceId: undefined,
  };
}

export const MeetingMeetingTask = {
  encode(message: MeetingMeetingTask, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.meeting !== undefined) {
      MeetingMeeting.encode(message.meeting, writer.uint32(50).fork()).ldelim();
    }
    if (message.meetingId !== undefined) {
      writer.uint32(200).uint64(message.meetingId);
    }
    if (message.sequenceNumber !== undefined) {
      writer.uint32(208).int32(message.sequenceNumber);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.pic !== undefined) {
      writer.uint32(58).string(message.pic);
    }
    if (message.start !== undefined) {
      writer.uint32(34).string(message.start);
    }
    if (message.deadline !== undefined) {
      writer.uint32(42).string(message.deadline);
    }
    if (message.target !== undefined) {
      writer.uint32(74).string(message.target);
    }
    if (message.completedDate !== undefined) {
      writer.uint32(82).string(message.completedDate);
    }
    if (message.remark !== undefined) {
      writer.uint32(90).string(message.remark);
    }
    if (message.problemRemark !== undefined) {
      writer.uint32(98).string(message.problemRemark);
    }
    if (message.durationDays !== undefined) {
      writer.uint32(104).int32(message.durationDays);
    }
    if (message.durationMins !== undefined) {
      writer.uint32(112).int32(message.durationMins);
    }
    if (message.durationMinsActual !== undefined) {
      writer.uint32(120).int32(message.durationMinsActual);
    }
    if (message.status !== undefined) {
      writer.uint32(130).string(message.status);
    }
    if (message.budget !== undefined) {
      writer.uint32(136).uint64(message.budget);
    }
    if (message.priority !== undefined) {
      writer.uint32(146).string(message.priority);
    }
    if (message.dependencyUuid !== undefined) {
      writer.uint32(154).string(message.dependencyUuid);
    }
    if (message.hourStart !== undefined) {
      writer.uint32(160).uint64(message.hourStart);
    }
    if (message.hourEnd !== undefined) {
      writer.uint32(168).uint64(message.hourEnd);
    }
    if (message.allDay !== undefined) {
      writer.uint32(176).bool(message.allDay);
    }
    if (message.taskPlaceString !== undefined) {
      writer.uint32(186).string(message.taskPlaceString);
    }
    if (message.dependency !== undefined) {
      MeetingMeetingTask.encode(message.dependency, writer.uint32(218).fork()).ldelim();
    }
    if (message.dependencyId !== undefined) {
      writer.uint32(224).uint64(message.dependencyId);
    }
    for (const v of message.children) {
      MeetingMeetingTask.encode(v!, writer.uint32(234).fork()).ldelim();
    }
    for (const v of message.meetingTaskNotes) {
      MeetingTaskNote.encode(v!, writer.uint32(242).fork()).ldelim();
    }
    for (const v of message.meetingTaskIssues) {
      MeetingTaskIssue.encode(v!, writer.uint32(250).fork()).ldelim();
    }
    for (const v of message.peopleInCharge) {
      MeetingUser.encode(v!, writer.uint32(258).fork()).ldelim();
    }
    for (const v of message.meetingTaskTargetDates) {
      MeetingMeetingTaskTargetDate.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.meetingTaskInCharges) {
      MeetingMeetingTaskInCharge.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.project !== undefined) {
      MeetingProject.encode(message.project, writer.uint32(266).fork()).ldelim();
    }
    if (message.meetingName !== undefined) {
      writer.uint32(274).string(message.meetingName);
    }
    if (message.meetingLink !== undefined) {
      writer.uint32(290).string(message.meetingLink);
    }
    if (message.meetingOnline !== undefined) {
      writer.uint32(296).bool(message.meetingOnline);
    }
    if (message.meetingPlace !== undefined) {
      MeetingPlace.encode(message.meetingPlace, writer.uint32(306).fork()).ldelim();
    }
    if (message.meetingRoom !== undefined) {
      MeetingRoom.encode(message.meetingRoom, writer.uint32(314).fork()).ldelim();
    }
    if (message.meetingPlatform !== undefined) {
      MeetingPlatform.encode(message.meetingPlatform, writer.uint32(322).fork()).ldelim();
    }
    if (message.meetingSummary !== undefined) {
      writer.uint32(330).string(message.meetingSummary);
    }
    if (message.projectName !== undefined) {
      writer.uint32(338).string(message.projectName);
    }
    if (message.projectIdLong !== undefined) {
      writer.uint32(344).uint64(message.projectIdLong);
    }
    if (message.subApp !== undefined) {
      MeetingSubApp.encode(message.subApp, writer.uint32(194).fork()).ldelim();
    }
    if (message.subAppId !== undefined) {
      writer.uint32(480).uint64(message.subAppId);
    }
    if (message.note !== undefined) {
      writer.uint32(354).string(message.note);
    }
    if (message.hasAlert !== undefined) {
      writer.uint32(360).bool(message.hasAlert);
    }
    if (message.secondsToTaskAlert !== undefined) {
      writer.uint32(368).int64(message.secondsToTaskAlert);
    }
    if (message.travelTimeSecs !== undefined) {
      writer.uint32(488).int32(message.travelTimeSecs);
    }
    if (message.todoType !== undefined) {
      writer.uint32(378).string(message.todoType);
    }
    if (message.travelTimeMins !== undefined) {
      writer.uint32(384).int32(message.travelTimeMins);
    }
    for (const v of message.meetingTaskDepartments) {
      MeetingTaskDepartment.encode(v!, writer.uint32(394).fork()).ldelim();
    }
    if (message.statusSubType !== undefined) {
      writer.uint32(402).string(message.statusSubType);
    }
    if (message.taskList !== undefined) {
      MeetingTaskList.encode(message.taskList, writer.uint32(410).fork()).ldelim();
    }
    if (message.taskListId !== undefined) {
      writer.uint32(416).uint64(message.taskListId);
    }
    if (message.taskListIdNo !== undefined) {
      writer.uint32(424).uint64(message.taskListIdNo);
    }
    if (message.rateStars !== undefined) {
      writer.uint32(432).int32(message.rateStars);
    }
    if (message.lat !== undefined) {
      writer.uint32(441).double(message.lat);
    }
    if (message.lon !== undefined) {
      writer.uint32(449).double(message.lon);
    }
    if (message.extLocationById !== undefined) {
      writer.uint32(456).uint64(message.extLocationById);
    }
    for (const v of message.meetingTaskRemarks) {
      MeetingTaskRemark.encode(v!, writer.uint32(466).fork()).ldelim();
    }
    for (const v of message.meetingTaskAttachment) {
      MeeetingTaskAttachment.encode(v!, writer.uint32(474).fork()).ldelim();
    }
    if (message.extSubProcessId !== undefined) {
      writer.uint32(496).uint64(message.extSubProcessId);
    }
    if (message.extSubProcessCommonId !== undefined) {
      writer.uint32(506).string(message.extSubProcessCommonId);
    }
    for (const v of message.meetingTaskComments) {
      MeetingMeetingTaskComment.encode(v!, writer.uint32(514).fork()).ldelim();
    }
    for (const v of message.meetingTaskProblemCatalogs) {
      MeetingMeetingTaskProblemCatalog.encode(v!, writer.uint32(522).fork()).ldelim();
    }
    for (const v of message.journeys) {
      Journey.encode(v!, writer.uint32(530).fork()).ldelim();
    }
    if (message.fromMins !== undefined) {
      writer.uint32(536).int32(message.fromMins);
    }
    if (message.toMins !== undefined) {
      writer.uint32(544).int32(message.toMins);
    }
    if (message.fromHour !== undefined) {
      writer.uint32(552).int32(message.fromHour);
    }
    if (message.toHour !== undefined) {
      writer.uint32(560).int32(message.toHour);
    }
    if (message.extPicCompletedDate !== undefined) {
      writer.uint32(570).string(message.extPicCompletedDate);
    }
    if (message.isNotClear !== undefined) {
      writer.uint32(576).bool(message.isNotClear);
    }
    if (message.qty !== undefined) {
      writer.uint32(584).int32(message.qty);
    }
    if (message.qcPassedCompletedDate !== undefined) {
      writer.uint32(594).string(message.qcPassedCompletedDate);
    }
    if (message.qaPassedCompletedDate !== undefined) {
      writer.uint32(602).string(message.qaPassedCompletedDate);
    }
    if (message.extPicCompletedTimestamp !== undefined) {
      writer.uint32(610).string(message.extPicCompletedTimestamp);
    }
    if (message.extRcemSubProcessId !== undefined) {
      writer.uint32(616).uint64(message.extRcemSubProcessId);
    }
    if (message.extPanelCodeDepartmentTemplateItemId !== undefined) {
      writer.uint32(624).uint64(message.extPanelCodeDepartmentTemplateItemId);
    }
    for (const v of message.meetingTaskSerialNumbers) {
      MeetingMeetingTaskSerialNumber.encode(v!, writer.uint32(634).fork()).ldelim();
    }
    if (message.weight !== undefined) {
      writer.uint32(640).int32(message.weight);
    }
    for (const v of message.okrChildrenTaskList) {
      MeetingTaskList.encode(v!, writer.uint32(650).fork()).ldelim();
    }
    if (message.abcReferenceId !== undefined) {
      writer.uint32(656).uint64(message.abcReferenceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTask {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.meeting = MeetingMeeting.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.meetingId = longToString(reader.uint64() as Long);
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.sequenceNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pic = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.start = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deadline = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.target = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.completedDate = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.problemRemark = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.durationDays = reader.int32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.durationMins = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.durationMinsActual = reader.int32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.status = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.budget = longToString(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.priority = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.dependencyUuid = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.hourStart = longToString(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.hourEnd = longToString(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.allDay = reader.bool();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.taskPlaceString = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.dependency = MeetingMeetingTask.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.dependencyId = longToString(reader.uint64() as Long);
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.children.push(MeetingMeetingTask.decode(reader, reader.uint32()));
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.meetingTaskNotes.push(MeetingTaskNote.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.meetingTaskIssues.push(MeetingTaskIssue.decode(reader, reader.uint32()));
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.peopleInCharge.push(MeetingUser.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.meetingTaskTargetDates.push(MeetingMeetingTaskTargetDate.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.meetingTaskInCharges.push(MeetingMeetingTaskInCharge.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.project = MeetingProject.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.meetingName = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.meetingLink = reader.string();
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.meetingOnline = reader.bool();
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.meetingPlace = MeetingPlace.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.meetingRoom = MeetingRoom.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.meetingPlatform = MeetingPlatform.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.meetingSummary = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.projectName = reader.string();
          continue;
        case 43:
          if (tag !== 344) {
            break;
          }

          message.projectIdLong = longToString(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.subApp = MeetingSubApp.decode(reader, reader.uint32());
          continue;
        case 60:
          if (tag !== 480) {
            break;
          }

          message.subAppId = longToString(reader.uint64() as Long);
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.note = reader.string();
          continue;
        case 45:
          if (tag !== 360) {
            break;
          }

          message.hasAlert = reader.bool();
          continue;
        case 46:
          if (tag !== 368) {
            break;
          }

          message.secondsToTaskAlert = longToString(reader.int64() as Long);
          continue;
        case 61:
          if (tag !== 488) {
            break;
          }

          message.travelTimeSecs = reader.int32();
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.todoType = reader.string();
          continue;
        case 48:
          if (tag !== 384) {
            break;
          }

          message.travelTimeMins = reader.int32();
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.meetingTaskDepartments.push(MeetingTaskDepartment.decode(reader, reader.uint32()));
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.statusSubType = reader.string();
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.taskList = MeetingTaskList.decode(reader, reader.uint32());
          continue;
        case 52:
          if (tag !== 416) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
        case 53:
          if (tag !== 424) {
            break;
          }

          message.taskListIdNo = longToString(reader.uint64() as Long);
          continue;
        case 54:
          if (tag !== 432) {
            break;
          }

          message.rateStars = reader.int32();
          continue;
        case 55:
          if (tag !== 441) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 56:
          if (tag !== 449) {
            break;
          }

          message.lon = reader.double();
          continue;
        case 57:
          if (tag !== 456) {
            break;
          }

          message.extLocationById = longToString(reader.uint64() as Long);
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.meetingTaskRemarks.push(MeetingTaskRemark.decode(reader, reader.uint32()));
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.meetingTaskAttachment.push(MeeetingTaskAttachment.decode(reader, reader.uint32()));
          continue;
        case 62:
          if (tag !== 496) {
            break;
          }

          message.extSubProcessId = longToString(reader.uint64() as Long);
          continue;
        case 63:
          if (tag !== 506) {
            break;
          }

          message.extSubProcessCommonId = reader.string();
          continue;
        case 64:
          if (tag !== 514) {
            break;
          }

          message.meetingTaskComments.push(MeetingMeetingTaskComment.decode(reader, reader.uint32()));
          continue;
        case 65:
          if (tag !== 522) {
            break;
          }

          message.meetingTaskProblemCatalogs.push(MeetingMeetingTaskProblemCatalog.decode(reader, reader.uint32()));
          continue;
        case 66:
          if (tag !== 530) {
            break;
          }

          message.journeys.push(Journey.decode(reader, reader.uint32()));
          continue;
        case 67:
          if (tag !== 536) {
            break;
          }

          message.fromMins = reader.int32();
          continue;
        case 68:
          if (tag !== 544) {
            break;
          }

          message.toMins = reader.int32();
          continue;
        case 69:
          if (tag !== 552) {
            break;
          }

          message.fromHour = reader.int32();
          continue;
        case 70:
          if (tag !== 560) {
            break;
          }

          message.toHour = reader.int32();
          continue;
        case 71:
          if (tag !== 570) {
            break;
          }

          message.extPicCompletedDate = reader.string();
          continue;
        case 72:
          if (tag !== 576) {
            break;
          }

          message.isNotClear = reader.bool();
          continue;
        case 73:
          if (tag !== 584) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 74:
          if (tag !== 594) {
            break;
          }

          message.qcPassedCompletedDate = reader.string();
          continue;
        case 75:
          if (tag !== 602) {
            break;
          }

          message.qaPassedCompletedDate = reader.string();
          continue;
        case 76:
          if (tag !== 610) {
            break;
          }

          message.extPicCompletedTimestamp = reader.string();
          continue;
        case 77:
          if (tag !== 616) {
            break;
          }

          message.extRcemSubProcessId = longToString(reader.uint64() as Long);
          continue;
        case 78:
          if (tag !== 624) {
            break;
          }

          message.extPanelCodeDepartmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
        case 79:
          if (tag !== 634) {
            break;
          }

          message.meetingTaskSerialNumbers.push(MeetingMeetingTaskSerialNumber.decode(reader, reader.uint32()));
          continue;
        case 80:
          if (tag !== 640) {
            break;
          }

          message.weight = reader.int32();
          continue;
        case 81:
          if (tag !== 650) {
            break;
          }

          message.okrChildrenTaskList.push(MeetingTaskList.decode(reader, reader.uint32()));
          continue;
        case 82:
          if (tag !== 656) {
            break;
          }

          message.abcReferenceId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTask {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      meeting: isSet(object.meeting) ? MeetingMeeting.fromJSON(object.meeting) : undefined,
      meetingId: isSet(object.meetingId) ? String(object.meetingId) : undefined,
      sequenceNumber: isSet(object.sequenceNumber) ? Number(object.sequenceNumber) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      pic: isSet(object.pic) ? String(object.pic) : undefined,
      start: isSet(object.start) ? String(object.start) : undefined,
      deadline: isSet(object.deadline) ? String(object.deadline) : undefined,
      target: isSet(object.target) ? String(object.target) : undefined,
      completedDate: isSet(object.completedDate) ? String(object.completedDate) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      problemRemark: isSet(object.problemRemark) ? String(object.problemRemark) : undefined,
      durationDays: isSet(object.durationDays) ? Number(object.durationDays) : undefined,
      durationMins: isSet(object.durationMins) ? Number(object.durationMins) : undefined,
      durationMinsActual: isSet(object.durationMinsActual) ? Number(object.durationMinsActual) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      budget: isSet(object.budget) ? String(object.budget) : undefined,
      priority: isSet(object.priority) ? String(object.priority) : undefined,
      dependencyUuid: isSet(object.dependencyUuid) ? String(object.dependencyUuid) : undefined,
      hourStart: isSet(object.hourStart) ? String(object.hourStart) : undefined,
      hourEnd: isSet(object.hourEnd) ? String(object.hourEnd) : undefined,
      allDay: isSet(object.allDay) ? Boolean(object.allDay) : undefined,
      taskPlaceString: isSet(object.taskPlaceString) ? String(object.taskPlaceString) : undefined,
      dependency: isSet(object.dependency) ? MeetingMeetingTask.fromJSON(object.dependency) : undefined,
      dependencyId: isSet(object.dependencyId) ? String(object.dependencyId) : undefined,
      children: Array.isArray(object?.children) ? object.children.map((e: any) => MeetingMeetingTask.fromJSON(e)) : [],
      meetingTaskNotes: Array.isArray(object?.meetingTaskNotes)
        ? object.meetingTaskNotes.map((e: any) => MeetingTaskNote.fromJSON(e))
        : [],
      meetingTaskIssues: Array.isArray(object?.meetingTaskIssues)
        ? object.meetingTaskIssues.map((e: any) => MeetingTaskIssue.fromJSON(e))
        : [],
      peopleInCharge: Array.isArray(object?.peopleInCharge)
        ? object.peopleInCharge.map((e: any) => MeetingUser.fromJSON(e))
        : [],
      meetingTaskTargetDates: Array.isArray(object?.meetingTaskTargetDates)
        ? object.meetingTaskTargetDates.map((e: any) => MeetingMeetingTaskTargetDate.fromJSON(e))
        : [],
      meetingTaskInCharges: Array.isArray(object?.meetingTaskInCharges)
        ? object.meetingTaskInCharges.map((e: any) => MeetingMeetingTaskInCharge.fromJSON(e))
        : [],
      project: isSet(object.project) ? MeetingProject.fromJSON(object.project) : undefined,
      meetingName: isSet(object.meetingName) ? String(object.meetingName) : undefined,
      meetingLink: isSet(object.meetingLink) ? String(object.meetingLink) : undefined,
      meetingOnline: isSet(object.meetingOnline) ? Boolean(object.meetingOnline) : undefined,
      meetingPlace: isSet(object.meetingPlace) ? MeetingPlace.fromJSON(object.meetingPlace) : undefined,
      meetingRoom: isSet(object.meetingRoom) ? MeetingRoom.fromJSON(object.meetingRoom) : undefined,
      meetingPlatform: isSet(object.meetingPlatform) ? MeetingPlatform.fromJSON(object.meetingPlatform) : undefined,
      meetingSummary: isSet(object.meetingSummary) ? String(object.meetingSummary) : undefined,
      projectName: isSet(object.projectName) ? String(object.projectName) : undefined,
      projectIdLong: isSet(object.projectIdLong) ? String(object.projectIdLong) : undefined,
      subApp: isSet(object.subApp) ? MeetingSubApp.fromJSON(object.subApp) : undefined,
      subAppId: isSet(object.subAppId) ? String(object.subAppId) : undefined,
      note: isSet(object.note) ? String(object.note) : undefined,
      hasAlert: isSet(object.hasAlert) ? Boolean(object.hasAlert) : undefined,
      secondsToTaskAlert: isSet(object.secondsToTaskAlert) ? String(object.secondsToTaskAlert) : undefined,
      travelTimeSecs: isSet(object.travelTimeSecs) ? Number(object.travelTimeSecs) : undefined,
      todoType: isSet(object.todoType) ? String(object.todoType) : undefined,
      travelTimeMins: isSet(object.travelTimeMins) ? Number(object.travelTimeMins) : undefined,
      meetingTaskDepartments: Array.isArray(object?.meetingTaskDepartments)
        ? object.meetingTaskDepartments.map((e: any) => MeetingTaskDepartment.fromJSON(e))
        : [],
      statusSubType: isSet(object.statusSubType) ? String(object.statusSubType) : undefined,
      taskList: isSet(object.taskList) ? MeetingTaskList.fromJSON(object.taskList) : undefined,
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
      taskListIdNo: isSet(object.taskListIdNo) ? String(object.taskListIdNo) : undefined,
      rateStars: isSet(object.rateStars) ? Number(object.rateStars) : undefined,
      lat: isSet(object.lat) ? Number(object.lat) : undefined,
      lon: isSet(object.lon) ? Number(object.lon) : undefined,
      extLocationById: isSet(object.extLocationById) ? String(object.extLocationById) : undefined,
      meetingTaskRemarks: Array.isArray(object?.meetingTaskRemarks)
        ? object.meetingTaskRemarks.map((e: any) => MeetingTaskRemark.fromJSON(e))
        : [],
      meetingTaskAttachment: Array.isArray(object?.meetingTaskAttachment)
        ? object.meetingTaskAttachment.map((e: any) => MeeetingTaskAttachment.fromJSON(e))
        : [],
      extSubProcessId: isSet(object.extSubProcessId) ? String(object.extSubProcessId) : undefined,
      extSubProcessCommonId: isSet(object.extSubProcessCommonId) ? String(object.extSubProcessCommonId) : undefined,
      meetingTaskComments: Array.isArray(object?.meetingTaskComments)
        ? object.meetingTaskComments.map((e: any) => MeetingMeetingTaskComment.fromJSON(e))
        : [],
      meetingTaskProblemCatalogs: Array.isArray(object?.meetingTaskProblemCatalogs)
        ? object.meetingTaskProblemCatalogs.map((e: any) => MeetingMeetingTaskProblemCatalog.fromJSON(e))
        : [],
      journeys: Array.isArray(object?.journeys)
        ? object.journeys.map((e: any) => Journey.fromJSON(e))
        : [],
      fromMins: isSet(object.fromMins) ? Number(object.fromMins) : undefined,
      toMins: isSet(object.toMins) ? Number(object.toMins) : undefined,
      fromHour: isSet(object.fromHour) ? Number(object.fromHour) : undefined,
      toHour: isSet(object.toHour) ? Number(object.toHour) : undefined,
      extPicCompletedDate: isSet(object.extPicCompletedDate) ? String(object.extPicCompletedDate) : undefined,
      isNotClear: isSet(object.isNotClear) ? Boolean(object.isNotClear) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      qcPassedCompletedDate: isSet(object.qcPassedCompletedDate) ? String(object.qcPassedCompletedDate) : undefined,
      qaPassedCompletedDate: isSet(object.qaPassedCompletedDate) ? String(object.qaPassedCompletedDate) : undefined,
      extPicCompletedTimestamp: isSet(object.extPicCompletedTimestamp)
        ? String(object.extPicCompletedTimestamp)
        : undefined,
      extRcemSubProcessId: isSet(object.extRcemSubProcessId) ? String(object.extRcemSubProcessId) : undefined,
      extPanelCodeDepartmentTemplateItemId: isSet(object.extPanelCodeDepartmentTemplateItemId)
        ? String(object.extPanelCodeDepartmentTemplateItemId)
        : undefined,
      meetingTaskSerialNumbers: Array.isArray(object?.meetingTaskSerialNumbers)
        ? object.meetingTaskSerialNumbers.map((e: any) => MeetingMeetingTaskSerialNumber.fromJSON(e))
        : [],
      weight: isSet(object.weight) ? Number(object.weight) : undefined,
      okrChildrenTaskList: Array.isArray(object?.okrChildrenTaskList)
        ? object.okrChildrenTaskList.map((e: any) => MeetingTaskList.fromJSON(e))
        : [],
      abcReferenceId: isSet(object.abcReferenceId) ? String(object.abcReferenceId) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTask): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.meeting !== undefined &&
      (obj.meeting = message.meeting ? MeetingMeeting.toJSON(message.meeting) : undefined);
    message.meetingId !== undefined && (obj.meetingId = message.meetingId);
    message.sequenceNumber !== undefined && (obj.sequenceNumber = Math.round(message.sequenceNumber));
    message.description !== undefined && (obj.description = message.description);
    message.pic !== undefined && (obj.pic = message.pic);
    message.start !== undefined && (obj.start = message.start);
    message.deadline !== undefined && (obj.deadline = message.deadline);
    message.target !== undefined && (obj.target = message.target);
    message.completedDate !== undefined && (obj.completedDate = message.completedDate);
    message.remark !== undefined && (obj.remark = message.remark);
    message.problemRemark !== undefined && (obj.problemRemark = message.problemRemark);
    message.durationDays !== undefined && (obj.durationDays = Math.round(message.durationDays));
    message.durationMins !== undefined && (obj.durationMins = Math.round(message.durationMins));
    message.durationMinsActual !== undefined && (obj.durationMinsActual = Math.round(message.durationMinsActual));
    message.status !== undefined && (obj.status = message.status);
    message.budget !== undefined && (obj.budget = message.budget);
    message.priority !== undefined && (obj.priority = message.priority);
    message.dependencyUuid !== undefined && (obj.dependencyUuid = message.dependencyUuid);
    message.hourStart !== undefined && (obj.hourStart = message.hourStart);
    message.hourEnd !== undefined && (obj.hourEnd = message.hourEnd);
    message.allDay !== undefined && (obj.allDay = message.allDay);
    message.taskPlaceString !== undefined && (obj.taskPlaceString = message.taskPlaceString);
    message.dependency !== undefined &&
      (obj.dependency = message.dependency ? MeetingMeetingTask.toJSON(message.dependency) : undefined);
    message.dependencyId !== undefined && (obj.dependencyId = message.dependencyId);
    if (message.children) {
      obj.children = message.children.map((e) => e ? MeetingMeetingTask.toJSON(e) : undefined);
    } else {
      obj.children = [];
    }
    if (message.meetingTaskNotes) {
      obj.meetingTaskNotes = message.meetingTaskNotes.map((e) => e ? MeetingTaskNote.toJSON(e) : undefined);
    } else {
      obj.meetingTaskNotes = [];
    }
    if (message.meetingTaskIssues) {
      obj.meetingTaskIssues = message.meetingTaskIssues.map((e) => e ? MeetingTaskIssue.toJSON(e) : undefined);
    } else {
      obj.meetingTaskIssues = [];
    }
    if (message.peopleInCharge) {
      obj.peopleInCharge = message.peopleInCharge.map((e) => e ? MeetingUser.toJSON(e) : undefined);
    } else {
      obj.peopleInCharge = [];
    }
    if (message.meetingTaskTargetDates) {
      obj.meetingTaskTargetDates = message.meetingTaskTargetDates.map((e) =>
        e ? MeetingMeetingTaskTargetDate.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskTargetDates = [];
    }
    if (message.meetingTaskInCharges) {
      obj.meetingTaskInCharges = message.meetingTaskInCharges.map((e) =>
        e ? MeetingMeetingTaskInCharge.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskInCharges = [];
    }
    message.project !== undefined &&
      (obj.project = message.project ? MeetingProject.toJSON(message.project) : undefined);
    message.meetingName !== undefined && (obj.meetingName = message.meetingName);
    message.meetingLink !== undefined && (obj.meetingLink = message.meetingLink);
    message.meetingOnline !== undefined && (obj.meetingOnline = message.meetingOnline);
    message.meetingPlace !== undefined &&
      (obj.meetingPlace = message.meetingPlace ? MeetingPlace.toJSON(message.meetingPlace) : undefined);
    message.meetingRoom !== undefined &&
      (obj.meetingRoom = message.meetingRoom ? MeetingRoom.toJSON(message.meetingRoom) : undefined);
    message.meetingPlatform !== undefined &&
      (obj.meetingPlatform = message.meetingPlatform ? MeetingPlatform.toJSON(message.meetingPlatform) : undefined);
    message.meetingSummary !== undefined && (obj.meetingSummary = message.meetingSummary);
    message.projectName !== undefined && (obj.projectName = message.projectName);
    message.projectIdLong !== undefined && (obj.projectIdLong = message.projectIdLong);
    message.subApp !== undefined && (obj.subApp = message.subApp ? MeetingSubApp.toJSON(message.subApp) : undefined);
    message.subAppId !== undefined && (obj.subAppId = message.subAppId);
    message.note !== undefined && (obj.note = message.note);
    message.hasAlert !== undefined && (obj.hasAlert = message.hasAlert);
    message.secondsToTaskAlert !== undefined && (obj.secondsToTaskAlert = message.secondsToTaskAlert);
    message.travelTimeSecs !== undefined && (obj.travelTimeSecs = Math.round(message.travelTimeSecs));
    message.todoType !== undefined && (obj.todoType = message.todoType);
    message.travelTimeMins !== undefined && (obj.travelTimeMins = Math.round(message.travelTimeMins));
    if (message.meetingTaskDepartments) {
      obj.meetingTaskDepartments = message.meetingTaskDepartments.map((e) =>
        e ? MeetingTaskDepartment.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskDepartments = [];
    }
    message.statusSubType !== undefined && (obj.statusSubType = message.statusSubType);
    message.taskList !== undefined &&
      (obj.taskList = message.taskList ? MeetingTaskList.toJSON(message.taskList) : undefined);
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    message.taskListIdNo !== undefined && (obj.taskListIdNo = message.taskListIdNo);
    message.rateStars !== undefined && (obj.rateStars = Math.round(message.rateStars));
    message.lat !== undefined && (obj.lat = message.lat);
    message.lon !== undefined && (obj.lon = message.lon);
    message.extLocationById !== undefined && (obj.extLocationById = message.extLocationById);
    if (message.meetingTaskRemarks) {
      obj.meetingTaskRemarks = message.meetingTaskRemarks.map((e) => e ? MeetingTaskRemark.toJSON(e) : undefined);
    } else {
      obj.meetingTaskRemarks = [];
    }
    if (message.meetingTaskAttachment) {
      obj.meetingTaskAttachment = message.meetingTaskAttachment.map((e) =>
        e ? MeeetingTaskAttachment.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskAttachment = [];
    }
    message.extSubProcessId !== undefined && (obj.extSubProcessId = message.extSubProcessId);
    message.extSubProcessCommonId !== undefined && (obj.extSubProcessCommonId = message.extSubProcessCommonId);
    if (message.meetingTaskComments) {
      obj.meetingTaskComments = message.meetingTaskComments.map((e) =>
        e ? MeetingMeetingTaskComment.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskComments = [];
    }
    if (message.meetingTaskProblemCatalogs) {
      obj.meetingTaskProblemCatalogs = message.meetingTaskProblemCatalogs.map((e) =>
        e ? MeetingMeetingTaskProblemCatalog.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskProblemCatalogs = [];
    }
    if (message.journeys) {
      obj.journeys = message.journeys.map((e) => e ? Journey.toJSON(e) : undefined);
    } else {
      obj.journeys = [];
    }
    message.fromMins !== undefined && (obj.fromMins = Math.round(message.fromMins));
    message.toMins !== undefined && (obj.toMins = Math.round(message.toMins));
    message.fromHour !== undefined && (obj.fromHour = Math.round(message.fromHour));
    message.toHour !== undefined && (obj.toHour = Math.round(message.toHour));
    message.extPicCompletedDate !== undefined && (obj.extPicCompletedDate = message.extPicCompletedDate);
    message.isNotClear !== undefined && (obj.isNotClear = message.isNotClear);
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.qcPassedCompletedDate !== undefined && (obj.qcPassedCompletedDate = message.qcPassedCompletedDate);
    message.qaPassedCompletedDate !== undefined && (obj.qaPassedCompletedDate = message.qaPassedCompletedDate);
    message.extPicCompletedTimestamp !== undefined && (obj.extPicCompletedTimestamp = message.extPicCompletedTimestamp);
    message.extRcemSubProcessId !== undefined && (obj.extRcemSubProcessId = message.extRcemSubProcessId);
    message.extPanelCodeDepartmentTemplateItemId !== undefined &&
      (obj.extPanelCodeDepartmentTemplateItemId = message.extPanelCodeDepartmentTemplateItemId);
    if (message.meetingTaskSerialNumbers) {
      obj.meetingTaskSerialNumbers = message.meetingTaskSerialNumbers.map((e) =>
        e ? MeetingMeetingTaskSerialNumber.toJSON(e) : undefined
      );
    } else {
      obj.meetingTaskSerialNumbers = [];
    }
    message.weight !== undefined && (obj.weight = Math.round(message.weight));
    if (message.okrChildrenTaskList) {
      obj.okrChildrenTaskList = message.okrChildrenTaskList.map((e) => e ? MeetingTaskList.toJSON(e) : undefined);
    } else {
      obj.okrChildrenTaskList = [];
    }
    message.abcReferenceId !== undefined && (obj.abcReferenceId = message.abcReferenceId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTask>, I>>(base?: I): MeetingMeetingTask {
    return MeetingMeetingTask.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTask>, I>>(object: I): MeetingMeetingTask {
    const message = createBaseMeetingMeetingTask();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.meeting = (object.meeting !== undefined && object.meeting !== null)
      ? MeetingMeeting.fromPartial(object.meeting)
      : undefined;
    message.meetingId = object.meetingId ?? undefined;
    message.sequenceNumber = object.sequenceNumber ?? undefined;
    message.description = object.description ?? undefined;
    message.pic = object.pic ?? undefined;
    message.start = object.start ?? undefined;
    message.deadline = object.deadline ?? undefined;
    message.target = object.target ?? undefined;
    message.completedDate = object.completedDate ?? undefined;
    message.remark = object.remark ?? undefined;
    message.problemRemark = object.problemRemark ?? undefined;
    message.durationDays = object.durationDays ?? undefined;
    message.durationMins = object.durationMins ?? undefined;
    message.durationMinsActual = object.durationMinsActual ?? undefined;
    message.status = object.status ?? undefined;
    message.budget = object.budget ?? undefined;
    message.priority = object.priority ?? undefined;
    message.dependencyUuid = object.dependencyUuid ?? undefined;
    message.hourStart = object.hourStart ?? undefined;
    message.hourEnd = object.hourEnd ?? undefined;
    message.allDay = object.allDay ?? undefined;
    message.taskPlaceString = object.taskPlaceString ?? undefined;
    message.dependency = (object.dependency !== undefined && object.dependency !== null)
      ? MeetingMeetingTask.fromPartial(object.dependency)
      : undefined;
    message.dependencyId = object.dependencyId ?? undefined;
    message.children = object.children?.map((e) => MeetingMeetingTask.fromPartial(e)) || [];
    message.meetingTaskNotes = object.meetingTaskNotes?.map((e) => MeetingTaskNote.fromPartial(e)) || [];
    message.meetingTaskIssues = object.meetingTaskIssues?.map((e) => MeetingTaskIssue.fromPartial(e)) || [];
    message.peopleInCharge = object.peopleInCharge?.map((e) => MeetingUser.fromPartial(e)) || [];
    message.meetingTaskTargetDates =
      object.meetingTaskTargetDates?.map((e) => MeetingMeetingTaskTargetDate.fromPartial(e)) || [];
    message.meetingTaskInCharges = object.meetingTaskInCharges?.map((e) => MeetingMeetingTaskInCharge.fromPartial(e)) ||
      [];
    message.project = (object.project !== undefined && object.project !== null)
      ? MeetingProject.fromPartial(object.project)
      : undefined;
    message.meetingName = object.meetingName ?? undefined;
    message.meetingLink = object.meetingLink ?? undefined;
    message.meetingOnline = object.meetingOnline ?? undefined;
    message.meetingPlace = (object.meetingPlace !== undefined && object.meetingPlace !== null)
      ? MeetingPlace.fromPartial(object.meetingPlace)
      : undefined;
    message.meetingRoom = (object.meetingRoom !== undefined && object.meetingRoom !== null)
      ? MeetingRoom.fromPartial(object.meetingRoom)
      : undefined;
    message.meetingPlatform = (object.meetingPlatform !== undefined && object.meetingPlatform !== null)
      ? MeetingPlatform.fromPartial(object.meetingPlatform)
      : undefined;
    message.meetingSummary = object.meetingSummary ?? undefined;
    message.projectName = object.projectName ?? undefined;
    message.projectIdLong = object.projectIdLong ?? undefined;
    message.subApp = (object.subApp !== undefined && object.subApp !== null)
      ? MeetingSubApp.fromPartial(object.subApp)
      : undefined;
    message.subAppId = object.subAppId ?? undefined;
    message.note = object.note ?? undefined;
    message.hasAlert = object.hasAlert ?? undefined;
    message.secondsToTaskAlert = object.secondsToTaskAlert ?? undefined;
    message.travelTimeSecs = object.travelTimeSecs ?? undefined;
    message.todoType = object.todoType ?? undefined;
    message.travelTimeMins = object.travelTimeMins ?? undefined;
    message.meetingTaskDepartments = object.meetingTaskDepartments?.map((e) => MeetingTaskDepartment.fromPartial(e)) ||
      [];
    message.statusSubType = object.statusSubType ?? undefined;
    message.taskList = (object.taskList !== undefined && object.taskList !== null)
      ? MeetingTaskList.fromPartial(object.taskList)
      : undefined;
    message.taskListId = object.taskListId ?? undefined;
    message.taskListIdNo = object.taskListIdNo ?? undefined;
    message.rateStars = object.rateStars ?? undefined;
    message.lat = object.lat ?? undefined;
    message.lon = object.lon ?? undefined;
    message.extLocationById = object.extLocationById ?? undefined;
    message.meetingTaskRemarks = object.meetingTaskRemarks?.map((e) => MeetingTaskRemark.fromPartial(e)) || [];
    message.meetingTaskAttachment = object.meetingTaskAttachment?.map((e) => MeeetingTaskAttachment.fromPartial(e)) ||
      [];
    message.extSubProcessId = object.extSubProcessId ?? undefined;
    message.extSubProcessCommonId = object.extSubProcessCommonId ?? undefined;
    message.meetingTaskComments = object.meetingTaskComments?.map((e) => MeetingMeetingTaskComment.fromPartial(e)) ||
      [];
    message.meetingTaskProblemCatalogs =
      object.meetingTaskProblemCatalogs?.map((e) => MeetingMeetingTaskProblemCatalog.fromPartial(e)) || [];
    message.journeys = object.journeys?.map((e) => Journey.fromPartial(e)) || [];
    message.fromMins = object.fromMins ?? undefined;
    message.toMins = object.toMins ?? undefined;
    message.fromHour = object.fromHour ?? undefined;
    message.toHour = object.toHour ?? undefined;
    message.extPicCompletedDate = object.extPicCompletedDate ?? undefined;
    message.isNotClear = object.isNotClear ?? undefined;
    message.qty = object.qty ?? undefined;
    message.qcPassedCompletedDate = object.qcPassedCompletedDate ?? undefined;
    message.qaPassedCompletedDate = object.qaPassedCompletedDate ?? undefined;
    message.extPicCompletedTimestamp = object.extPicCompletedTimestamp ?? undefined;
    message.extRcemSubProcessId = object.extRcemSubProcessId ?? undefined;
    message.extPanelCodeDepartmentTemplateItemId = object.extPanelCodeDepartmentTemplateItemId ?? undefined;
    message.meetingTaskSerialNumbers =
      object.meetingTaskSerialNumbers?.map((e) => MeetingMeetingTaskSerialNumber.fromPartial(e)) || [];
    message.weight = object.weight ?? undefined;
    message.okrChildrenTaskList = object.okrChildrenTaskList?.map((e) => MeetingTaskList.fromPartial(e)) || [];
    message.abcReferenceId = object.abcReferenceId ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskSerialNumber(): MeetingMeetingTaskSerialNumber {
  return { masterJavaBaseModel: undefined, serialNumber: undefined, meetingTaskId: undefined };
}

export const MeetingMeetingTaskSerialNumber = {
  encode(message: MeetingMeetingTaskSerialNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.serialNumber !== undefined) {
      writer.uint32(18).string(message.serialNumber);
    }
    if (message.meetingTaskId !== undefined) {
      writer.uint32(24).uint64(message.meetingTaskId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskSerialNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskSerialNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.meetingTaskId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskSerialNumber {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      serialNumber: isSet(object.serialNumber) ? String(object.serialNumber) : undefined,
      meetingTaskId: isSet(object.meetingTaskId) ? String(object.meetingTaskId) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskSerialNumber): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.serialNumber !== undefined && (obj.serialNumber = message.serialNumber);
    message.meetingTaskId !== undefined && (obj.meetingTaskId = message.meetingTaskId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskSerialNumber>, I>>(base?: I): MeetingMeetingTaskSerialNumber {
    return MeetingMeetingTaskSerialNumber.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskSerialNumber>, I>>(
    object: I,
  ): MeetingMeetingTaskSerialNumber {
    const message = createBaseMeetingMeetingTaskSerialNumber();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.serialNumber = object.serialNumber ?? undefined;
    message.meetingTaskId = object.meetingTaskId ?? undefined;
    return message;
  },
};

function createBaseJourney(): Journey {
  return { masterJavaBaseModel: undefined, lat: undefined, lon: undefined, taskId: undefined, siteName: undefined };
}

export const Journey = {
  encode(message: Journey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.lat !== undefined) {
      writer.uint32(17).double(message.lat);
    }
    if (message.lon !== undefined) {
      writer.uint32(25).double(message.lon);
    }
    if (message.taskId !== undefined) {
      writer.uint32(32).uint64(message.taskId);
    }
    if (message.siteName !== undefined) {
      writer.uint32(42).string(message.siteName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Journey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJourney();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.lon = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.taskId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.siteName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Journey {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      lat: isSet(object.lat) ? Number(object.lat) : undefined,
      lon: isSet(object.lon) ? Number(object.lon) : undefined,
      taskId: isSet(object.taskId) ? String(object.taskId) : undefined,
      siteName: isSet(object.siteName) ? String(object.siteName) : undefined,
    };
  },

  toJSON(message: Journey): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.lat !== undefined && (obj.lat = message.lat);
    message.lon !== undefined && (obj.lon = message.lon);
    message.taskId !== undefined && (obj.taskId = message.taskId);
    message.siteName !== undefined && (obj.siteName = message.siteName);
    return obj;
  },

  create<I extends Exact<DeepPartial<Journey>, I>>(base?: I): Journey {
    return Journey.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Journey>, I>>(object: I): Journey {
    const message = createBaseJourney();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.lat = object.lat ?? undefined;
    message.lon = object.lon ?? undefined;
    message.taskId = object.taskId ?? undefined;
    message.siteName = object.siteName ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskNote(): MeetingTaskNote {
  return { masterJavaBaseModel: undefined };
}

export const MeetingTaskNote = {
  encode(message: MeetingTaskNote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskNote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskNote {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingTaskNote): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskNote>, I>>(base?: I): MeetingTaskNote {
    return MeetingTaskNote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskNote>, I>>(object: I): MeetingTaskNote {
    const message = createBaseMeetingTaskNote();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskIssue(): MeetingTaskIssue {
  return { masterJavaBaseModel: undefined };
}

export const MeetingTaskIssue = {
  encode(message: MeetingTaskIssue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskIssue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskIssue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskIssue {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingTaskIssue): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskIssue>, I>>(base?: I): MeetingTaskIssue {
    return MeetingTaskIssue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskIssue>, I>>(object: I): MeetingTaskIssue {
    const message = createBaseMeetingTaskIssue();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingUser(): MeetingUser {
  return { masterJavaBaseModel: undefined };
}

export const MeetingUser = {
  encode(message: MeetingUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingUser {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingUser): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingUser>, I>>(base?: I): MeetingUser {
    return MeetingUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingUser>, I>>(object: I): MeetingUser {
    const message = createBaseMeetingUser();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingProject(): MeetingProject {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    products: [],
    contracts: [],
    managerId: undefined,
    deadline: undefined,
    closing: undefined,
    extPurchaseOrderId: undefined,
    extLeaderId: undefined,
    pos: [],
  };
}

export const MeetingProject = {
  encode(message: MeetingProject, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.products) {
      MeetingProjectProduct.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.contracts) {
      MeetingProjectContract.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.managerId !== undefined) {
      writer.uint32(40).uint64(message.managerId);
    }
    if (message.deadline !== undefined) {
      writer.uint32(50).string(message.deadline);
    }
    if (message.closing !== undefined) {
      writer.uint32(58).string(message.closing);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(64).uint64(message.extPurchaseOrderId);
    }
    if (message.extLeaderId !== undefined) {
      writer.uint32(72).uint64(message.extLeaderId);
    }
    for (const v of message.pos) {
      MeetingProjectPurchaseOrder.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProject {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProject();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.products.push(MeetingProjectProduct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.contracts.push(MeetingProjectContract.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.managerId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deadline = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.closing = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.extLeaderId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pos.push(MeetingProjectPurchaseOrder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProject {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      products: Array.isArray(object?.products)
        ? object.products.map((e: any) => MeetingProjectProduct.fromJSON(e))
        : [],
      contracts: Array.isArray(object?.contracts)
        ? object.contracts.map((e: any) => MeetingProjectContract.fromJSON(e))
        : [],
      managerId: isSet(object.managerId) ? String(object.managerId) : undefined,
      deadline: isSet(object.deadline) ? String(object.deadline) : undefined,
      closing: isSet(object.closing) ? String(object.closing) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
      extLeaderId: isSet(object.extLeaderId) ? String(object.extLeaderId) : undefined,
      pos: Array.isArray(object?.pos) ? object.pos.map((e: any) => MeetingProjectPurchaseOrder.fromJSON(e)) : [],
    };
  },

  toJSON(message: MeetingProject): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.products) {
      obj.products = message.products.map((e) => e ? MeetingProjectProduct.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    if (message.contracts) {
      obj.contracts = message.contracts.map((e) => e ? MeetingProjectContract.toJSON(e) : undefined);
    } else {
      obj.contracts = [];
    }
    message.managerId !== undefined && (obj.managerId = message.managerId);
    message.deadline !== undefined && (obj.deadline = message.deadline);
    message.closing !== undefined && (obj.closing = message.closing);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    message.extLeaderId !== undefined && (obj.extLeaderId = message.extLeaderId);
    if (message.pos) {
      obj.pos = message.pos.map((e) => e ? MeetingProjectPurchaseOrder.toJSON(e) : undefined);
    } else {
      obj.pos = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProject>, I>>(base?: I): MeetingProject {
    return MeetingProject.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProject>, I>>(object: I): MeetingProject {
    const message = createBaseMeetingProject();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.products = object.products?.map((e) => MeetingProjectProduct.fromPartial(e)) || [];
    message.contracts = object.contracts?.map((e) => MeetingProjectContract.fromPartial(e)) || [];
    message.managerId = object.managerId ?? undefined;
    message.deadline = object.deadline ?? undefined;
    message.closing = object.closing ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    message.extLeaderId = object.extLeaderId ?? undefined;
    message.pos = object.pos?.map((e) => MeetingProjectPurchaseOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingProjectPurchaseOrder(): MeetingProjectPurchaseOrder {
  return { masterJavaBaseModel: undefined, extPurchaseOrderId: undefined };
}

export const MeetingProjectPurchaseOrder = {
  encode(message: MeetingProjectPurchaseOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(16).uint64(message.extPurchaseOrderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProjectPurchaseOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProjectPurchaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProjectPurchaseOrder {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
    };
  },

  toJSON(message: MeetingProjectPurchaseOrder): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProjectPurchaseOrder>, I>>(base?: I): MeetingProjectPurchaseOrder {
    return MeetingProjectPurchaseOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProjectPurchaseOrder>, I>>(object: I): MeetingProjectPurchaseOrder {
    const message = createBaseMeetingProjectPurchaseOrder();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    return message;
  },
};

function createBaseMeetingATPDocumentForm(): MeetingATPDocumentForm {
  return {
    masterJavaBaseModel: undefined,
    extCustomerId: undefined,
    extPurchaseOrderId: undefined,
    formSubDocuments: [],
    formTestUnits: [],
    photos: [],
  };
}

export const MeetingATPDocumentForm = {
  encode(message: MeetingATPDocumentForm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extCustomerId !== undefined) {
      writer.uint32(16).uint64(message.extCustomerId);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(24).uint64(message.extPurchaseOrderId);
    }
    for (const v of message.formSubDocuments) {
      MeetingATPDocumentFormSubDocument.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.formTestUnits) {
      MeetingATPDocumentFormTestUnit.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.photos) {
      MeetingATPDocumentFormPhoto.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocumentForm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocumentForm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extCustomerId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.formSubDocuments.push(MeetingATPDocumentFormSubDocument.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.formTestUnits.push(MeetingATPDocumentFormTestUnit.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.photos.push(MeetingATPDocumentFormPhoto.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocumentForm {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extCustomerId: isSet(object.extCustomerId) ? String(object.extCustomerId) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
      formSubDocuments: Array.isArray(object?.formSubDocuments)
        ? object.formSubDocuments.map((e: any) => MeetingATPDocumentFormSubDocument.fromJSON(e))
        : [],
      formTestUnits: Array.isArray(object?.formTestUnits)
        ? object.formTestUnits.map((e: any) => MeetingATPDocumentFormTestUnit.fromJSON(e))
        : [],
      photos: Array.isArray(object?.photos)
        ? object.photos.map((e: any) => MeetingATPDocumentFormPhoto.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingATPDocumentForm): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extCustomerId !== undefined && (obj.extCustomerId = message.extCustomerId);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    if (message.formSubDocuments) {
      obj.formSubDocuments = message.formSubDocuments.map((e) =>
        e ? MeetingATPDocumentFormSubDocument.toJSON(e) : undefined
      );
    } else {
      obj.formSubDocuments = [];
    }
    if (message.formTestUnits) {
      obj.formTestUnits = message.formTestUnits.map((e) => e ? MeetingATPDocumentFormTestUnit.toJSON(e) : undefined);
    } else {
      obj.formTestUnits = [];
    }
    if (message.photos) {
      obj.photos = message.photos.map((e) => e ? MeetingATPDocumentFormPhoto.toJSON(e) : undefined);
    } else {
      obj.photos = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocumentForm>, I>>(base?: I): MeetingATPDocumentForm {
    return MeetingATPDocumentForm.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocumentForm>, I>>(object: I): MeetingATPDocumentForm {
    const message = createBaseMeetingATPDocumentForm();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extCustomerId = object.extCustomerId ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    message.formSubDocuments = object.formSubDocuments?.map((e) => MeetingATPDocumentFormSubDocument.fromPartial(e)) ||
      [];
    message.formTestUnits = object.formTestUnits?.map((e) => MeetingATPDocumentFormTestUnit.fromPartial(e)) || [];
    message.photos = object.photos?.map((e) => MeetingATPDocumentFormPhoto.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingATPDocumentFormPhoto(): MeetingATPDocumentFormPhoto {
  return {
    masterJavaBaseModel: undefined,
    bankNumber: undefined,
    serialNumber: undefined,
    originalFileName: undefined,
    base64File: undefined,
    name: undefined,
    atpFormId: undefined,
    batteryNumber: undefined,
    type: undefined,
  };
}

export const MeetingATPDocumentFormPhoto = {
  encode(message: MeetingATPDocumentFormPhoto, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.bankNumber !== undefined) {
      writer.uint32(16).int32(message.bankNumber);
    }
    if (message.serialNumber !== undefined) {
      writer.uint32(26).string(message.serialNumber);
    }
    if (message.originalFileName !== undefined) {
      writer.uint32(34).string(message.originalFileName);
    }
    if (message.base64File !== undefined) {
      writer.uint32(42).string(message.base64File);
    }
    if (message.name !== undefined) {
      writer.uint32(50).string(message.name);
    }
    if (message.atpFormId !== undefined) {
      writer.uint32(56).uint64(message.atpFormId);
    }
    if (message.batteryNumber !== undefined) {
      writer.uint32(64).int32(message.batteryNumber);
    }
    if (message.type !== undefined) {
      writer.uint32(72).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocumentFormPhoto {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocumentFormPhoto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bankNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.originalFileName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.base64File = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.atpFormId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.batteryNumber = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocumentFormPhoto {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      bankNumber: isSet(object.bankNumber) ? Number(object.bankNumber) : undefined,
      serialNumber: isSet(object.serialNumber) ? String(object.serialNumber) : undefined,
      originalFileName: isSet(object.originalFileName) ? String(object.originalFileName) : undefined,
      base64File: isSet(object.base64File) ? String(object.base64File) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      atpFormId: isSet(object.atpFormId) ? String(object.atpFormId) : undefined,
      batteryNumber: isSet(object.batteryNumber) ? Number(object.batteryNumber) : undefined,
      type: isSet(object.type) ? meetingATPDocumentFormPhotoTypeFromJSON(object.type) : undefined,
    };
  },

  toJSON(message: MeetingATPDocumentFormPhoto): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.bankNumber !== undefined && (obj.bankNumber = Math.round(message.bankNumber));
    message.serialNumber !== undefined && (obj.serialNumber = message.serialNumber);
    message.originalFileName !== undefined && (obj.originalFileName = message.originalFileName);
    message.base64File !== undefined && (obj.base64File = message.base64File);
    message.name !== undefined && (obj.name = message.name);
    message.atpFormId !== undefined && (obj.atpFormId = message.atpFormId);
    message.batteryNumber !== undefined && (obj.batteryNumber = Math.round(message.batteryNumber));
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? meetingATPDocumentFormPhotoTypeToJSON(message.type) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocumentFormPhoto>, I>>(base?: I): MeetingATPDocumentFormPhoto {
    return MeetingATPDocumentFormPhoto.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocumentFormPhoto>, I>>(object: I): MeetingATPDocumentFormPhoto {
    const message = createBaseMeetingATPDocumentFormPhoto();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.bankNumber = object.bankNumber ?? undefined;
    message.serialNumber = object.serialNumber ?? undefined;
    message.originalFileName = object.originalFileName ?? undefined;
    message.base64File = object.base64File ?? undefined;
    message.name = object.name ?? undefined;
    message.atpFormId = object.atpFormId ?? undefined;
    message.batteryNumber = object.batteryNumber ?? undefined;
    message.type = object.type ?? undefined;
    return message;
  },
};

function createBaseMeetingATPDocumentForms(): MeetingATPDocumentForms {
  return { forms: [] };
}

export const MeetingATPDocumentForms = {
  encode(message: MeetingATPDocumentForms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.forms) {
      MeetingATPDocumentForm.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocumentForms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocumentForms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.forms.push(MeetingATPDocumentForm.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocumentForms {
    return {
      forms: Array.isArray(object?.forms) ? object.forms.map((e: any) => MeetingATPDocumentForm.fromJSON(e)) : [],
    };
  },

  toJSON(message: MeetingATPDocumentForms): unknown {
    const obj: any = {};
    if (message.forms) {
      obj.forms = message.forms.map((e) => e ? MeetingATPDocumentForm.toJSON(e) : undefined);
    } else {
      obj.forms = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocumentForms>, I>>(base?: I): MeetingATPDocumentForms {
    return MeetingATPDocumentForms.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocumentForms>, I>>(object: I): MeetingATPDocumentForms {
    const message = createBaseMeetingATPDocumentForms();
    message.forms = object.forms?.map((e) => MeetingATPDocumentForm.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingATPDocumentFormSubDocument(): MeetingATPDocumentFormSubDocument {
  return { masterJavaBaseModel: undefined, atpDocumentFormId: undefined, subDocumentId: undefined, batteries: [] };
}

export const MeetingATPDocumentFormSubDocument = {
  encode(message: MeetingATPDocumentFormSubDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.atpDocumentFormId !== undefined) {
      writer.uint32(16).uint64(message.atpDocumentFormId);
    }
    if (message.subDocumentId !== undefined) {
      writer.uint32(24).uint64(message.subDocumentId);
    }
    for (const v of message.batteries) {
      MeetingATPDocumentFormSubDocumentBattery.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocumentFormSubDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocumentFormSubDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.atpDocumentFormId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.subDocumentId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.batteries.push(MeetingATPDocumentFormSubDocumentBattery.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocumentFormSubDocument {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      atpDocumentFormId: isSet(object.atpDocumentFormId) ? String(object.atpDocumentFormId) : undefined,
      subDocumentId: isSet(object.subDocumentId) ? String(object.subDocumentId) : undefined,
      batteries: Array.isArray(object?.batteries)
        ? object.batteries.map((e: any) => MeetingATPDocumentFormSubDocumentBattery.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingATPDocumentFormSubDocument): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.atpDocumentFormId !== undefined && (obj.atpDocumentFormId = message.atpDocumentFormId);
    message.subDocumentId !== undefined && (obj.subDocumentId = message.subDocumentId);
    if (message.batteries) {
      obj.batteries = message.batteries.map((e) => e ? MeetingATPDocumentFormSubDocumentBattery.toJSON(e) : undefined);
    } else {
      obj.batteries = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocumentFormSubDocument>, I>>(
    base?: I,
  ): MeetingATPDocumentFormSubDocument {
    return MeetingATPDocumentFormSubDocument.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocumentFormSubDocument>, I>>(
    object: I,
  ): MeetingATPDocumentFormSubDocument {
    const message = createBaseMeetingATPDocumentFormSubDocument();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.atpDocumentFormId = object.atpDocumentFormId ?? undefined;
    message.subDocumentId = object.subDocumentId ?? undefined;
    message.batteries = object.batteries?.map((e) => MeetingATPDocumentFormSubDocumentBattery.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingATPDocumentFormSubDocumentBattery(): MeetingATPDocumentFormSubDocumentBattery {
  return {
    masterJavaBaseModel: undefined,
    atpDocumentFormSubDocumentId: undefined,
    serialNo: undefined,
    open: undefined,
    testValues: [],
  };
}

export const MeetingATPDocumentFormSubDocumentBattery = {
  encode(message: MeetingATPDocumentFormSubDocumentBattery, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.atpDocumentFormSubDocumentId !== undefined) {
      writer.uint32(16).uint64(message.atpDocumentFormSubDocumentId);
    }
    if (message.serialNo !== undefined) {
      writer.uint32(26).string(message.serialNo);
    }
    if (message.open !== undefined) {
      writer.uint32(34).string(message.open);
    }
    for (const v of message.testValues) {
      MeetingATPDocumentFormSubDocumentBatteryTestValue.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocumentFormSubDocumentBattery {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocumentFormSubDocumentBattery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.atpDocumentFormSubDocumentId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serialNo = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.open = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.testValues.push(MeetingATPDocumentFormSubDocumentBatteryTestValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocumentFormSubDocumentBattery {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      atpDocumentFormSubDocumentId: isSet(object.atpDocumentFormSubDocumentId)
        ? String(object.atpDocumentFormSubDocumentId)
        : undefined,
      serialNo: isSet(object.serialNo) ? String(object.serialNo) : undefined,
      open: isSet(object.open) ? String(object.open) : undefined,
      testValues: Array.isArray(object?.testValues)
        ? object.testValues.map((e: any) => MeetingATPDocumentFormSubDocumentBatteryTestValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingATPDocumentFormSubDocumentBattery): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.atpDocumentFormSubDocumentId !== undefined &&
      (obj.atpDocumentFormSubDocumentId = message.atpDocumentFormSubDocumentId);
    message.serialNo !== undefined && (obj.serialNo = message.serialNo);
    message.open !== undefined && (obj.open = message.open);
    if (message.testValues) {
      obj.testValues = message.testValues.map((e) =>
        e ? MeetingATPDocumentFormSubDocumentBatteryTestValue.toJSON(e) : undefined
      );
    } else {
      obj.testValues = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocumentFormSubDocumentBattery>, I>>(
    base?: I,
  ): MeetingATPDocumentFormSubDocumentBattery {
    return MeetingATPDocumentFormSubDocumentBattery.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocumentFormSubDocumentBattery>, I>>(
    object: I,
  ): MeetingATPDocumentFormSubDocumentBattery {
    const message = createBaseMeetingATPDocumentFormSubDocumentBattery();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.atpDocumentFormSubDocumentId = object.atpDocumentFormSubDocumentId ?? undefined;
    message.serialNo = object.serialNo ?? undefined;
    message.open = object.open ?? undefined;
    message.testValues =
      object.testValues?.map((e) => MeetingATPDocumentFormSubDocumentBatteryTestValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingATPDocumentFormSubDocumentBatteryTestValue(): MeetingATPDocumentFormSubDocumentBatteryTestValue {
  return {
    masterJavaBaseModel: undefined,
    atpDocumentFormSubDocumentBatteryId: undefined,
    durationMins: undefined,
    value: undefined,
  };
}

export const MeetingATPDocumentFormSubDocumentBatteryTestValue = {
  encode(
    message: MeetingATPDocumentFormSubDocumentBatteryTestValue,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.atpDocumentFormSubDocumentBatteryId !== undefined) {
      writer.uint32(16).uint64(message.atpDocumentFormSubDocumentBatteryId);
    }
    if (message.durationMins !== undefined) {
      writer.uint32(24).int32(message.durationMins);
    }
    if (message.value !== undefined) {
      writer.uint32(33).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocumentFormSubDocumentBatteryTestValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocumentFormSubDocumentBatteryTestValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.atpDocumentFormSubDocumentBatteryId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.durationMins = reader.int32();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocumentFormSubDocumentBatteryTestValue {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      atpDocumentFormSubDocumentBatteryId: isSet(object.atpDocumentFormSubDocumentBatteryId)
        ? String(object.atpDocumentFormSubDocumentBatteryId)
        : undefined,
      durationMins: isSet(object.durationMins) ? Number(object.durationMins) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
    };
  },

  toJSON(message: MeetingATPDocumentFormSubDocumentBatteryTestValue): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.atpDocumentFormSubDocumentBatteryId !== undefined &&
      (obj.atpDocumentFormSubDocumentBatteryId = message.atpDocumentFormSubDocumentBatteryId);
    message.durationMins !== undefined && (obj.durationMins = Math.round(message.durationMins));
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocumentFormSubDocumentBatteryTestValue>, I>>(
    base?: I,
  ): MeetingATPDocumentFormSubDocumentBatteryTestValue {
    return MeetingATPDocumentFormSubDocumentBatteryTestValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocumentFormSubDocumentBatteryTestValue>, I>>(
    object: I,
  ): MeetingATPDocumentFormSubDocumentBatteryTestValue {
    const message = createBaseMeetingATPDocumentFormSubDocumentBatteryTestValue();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.atpDocumentFormSubDocumentBatteryId = object.atpDocumentFormSubDocumentBatteryId ?? undefined;
    message.durationMins = object.durationMins ?? undefined;
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseMeetingATPDocumentFormTestUnit(): MeetingATPDocumentFormTestUnit {
  return {
    masterJavaBaseModel: undefined,
    atpDocumentFormId: undefined,
    testUnitId: undefined,
    value: undefined,
    status: undefined,
    valueActual: undefined,
  };
}

export const MeetingATPDocumentFormTestUnit = {
  encode(message: MeetingATPDocumentFormTestUnit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.atpDocumentFormId !== undefined) {
      writer.uint32(16).uint64(message.atpDocumentFormId);
    }
    if (message.testUnitId !== undefined) {
      writer.uint32(24).uint64(message.testUnitId);
    }
    if (message.value !== undefined) {
      writer.uint32(33).double(message.value);
    }
    if (message.status !== undefined) {
      writer.uint32(40).bool(message.status);
    }
    if (message.valueActual !== undefined) {
      writer.uint32(49).double(message.valueActual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocumentFormTestUnit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocumentFormTestUnit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.atpDocumentFormId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.testUnitId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.value = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.bool();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.valueActual = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocumentFormTestUnit {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      atpDocumentFormId: isSet(object.atpDocumentFormId) ? String(object.atpDocumentFormId) : undefined,
      testUnitId: isSet(object.testUnitId) ? String(object.testUnitId) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
      status: isSet(object.status) ? Boolean(object.status) : undefined,
      valueActual: isSet(object.valueActual) ? Number(object.valueActual) : undefined,
    };
  },

  toJSON(message: MeetingATPDocumentFormTestUnit): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.atpDocumentFormId !== undefined && (obj.atpDocumentFormId = message.atpDocumentFormId);
    message.testUnitId !== undefined && (obj.testUnitId = message.testUnitId);
    message.value !== undefined && (obj.value = message.value);
    message.status !== undefined && (obj.status = message.status);
    message.valueActual !== undefined && (obj.valueActual = message.valueActual);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocumentFormTestUnit>, I>>(base?: I): MeetingATPDocumentFormTestUnit {
    return MeetingATPDocumentFormTestUnit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocumentFormTestUnit>, I>>(
    object: I,
  ): MeetingATPDocumentFormTestUnit {
    const message = createBaseMeetingATPDocumentFormTestUnit();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.atpDocumentFormId = object.atpDocumentFormId ?? undefined;
    message.testUnitId = object.testUnitId ?? undefined;
    message.value = object.value ?? undefined;
    message.status = object.status ?? undefined;
    message.valueActual = object.valueActual ?? undefined;
    return message;
  },
};

function createBaseMeetingATPDocument(): MeetingATPDocument {
  return { masterJavaBaseModel: undefined, subDocuments: [] };
}

export const MeetingATPDocument = {
  encode(message: MeetingATPDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.subDocuments) {
      MeetingATPSubDocument.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subDocuments.push(MeetingATPSubDocument.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocument {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      subDocuments: Array.isArray(object?.subDocuments)
        ? object.subDocuments.map((e: any) => MeetingATPSubDocument.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingATPDocument): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    if (message.subDocuments) {
      obj.subDocuments = message.subDocuments.map((e) => e ? MeetingATPSubDocument.toJSON(e) : undefined);
    } else {
      obj.subDocuments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocument>, I>>(base?: I): MeetingATPDocument {
    return MeetingATPDocument.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocument>, I>>(object: I): MeetingATPDocument {
    const message = createBaseMeetingATPDocument();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.subDocuments = object.subDocuments?.map((e) => MeetingATPSubDocument.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingATPDocuments(): MeetingATPDocuments {
  return { atpDocuments: [] };
}

export const MeetingATPDocuments = {
  encode(message: MeetingATPDocuments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.atpDocuments) {
      MeetingATPDocument.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPDocuments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPDocuments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.atpDocuments.push(MeetingATPDocument.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPDocuments {
    return {
      atpDocuments: Array.isArray(object?.atpDocuments)
        ? object.atpDocuments.map((e: any) => MeetingATPDocument.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingATPDocuments): unknown {
    const obj: any = {};
    if (message.atpDocuments) {
      obj.atpDocuments = message.atpDocuments.map((e) => e ? MeetingATPDocument.toJSON(e) : undefined);
    } else {
      obj.atpDocuments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPDocuments>, I>>(base?: I): MeetingATPDocuments {
    return MeetingATPDocuments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPDocuments>, I>>(object: I): MeetingATPDocuments {
    const message = createBaseMeetingATPDocuments();
    message.atpDocuments = object.atpDocuments?.map((e) => MeetingATPDocument.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingATPSubDocument(): MeetingATPSubDocument {
  return { masterJavaBaseModel: undefined, testUnits: [], type: undefined, name: undefined };
}

export const MeetingATPSubDocument = {
  encode(message: MeetingATPSubDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.testUnits) {
      MeetingATPTestUnit.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPSubDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPSubDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.testUnits.push(MeetingATPTestUnit.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPSubDocument {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      testUnits: Array.isArray(object?.testUnits)
        ? object.testUnits.map((e: any) => MeetingATPTestUnit.fromJSON(e))
        : [],
      type: isSet(object.type) ? aTPSubDocumentTypeFromJSON(object.type) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: MeetingATPSubDocument): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    if (message.testUnits) {
      obj.testUnits = message.testUnits.map((e) => e ? MeetingATPTestUnit.toJSON(e) : undefined);
    } else {
      obj.testUnits = [];
    }
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? aTPSubDocumentTypeToJSON(message.type) : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPSubDocument>, I>>(base?: I): MeetingATPSubDocument {
    return MeetingATPSubDocument.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPSubDocument>, I>>(object: I): MeetingATPSubDocument {
    const message = createBaseMeetingATPSubDocument();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.testUnits = object.testUnits?.map((e) => MeetingATPTestUnit.fromPartial(e)) || [];
    message.type = object.type ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseMeetingATPSubDocuments(): MeetingATPSubDocuments {
  return { atpSubDocuments: [] };
}

export const MeetingATPSubDocuments = {
  encode(message: MeetingATPSubDocuments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.atpSubDocuments) {
      MeetingATPSubDocument.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPSubDocuments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPSubDocuments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.atpSubDocuments.push(MeetingATPSubDocument.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPSubDocuments {
    return {
      atpSubDocuments: Array.isArray(object?.atpSubDocuments)
        ? object.atpSubDocuments.map((e: any) => MeetingATPSubDocument.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingATPSubDocuments): unknown {
    const obj: any = {};
    if (message.atpSubDocuments) {
      obj.atpSubDocuments = message.atpSubDocuments.map((e) => e ? MeetingATPSubDocument.toJSON(e) : undefined);
    } else {
      obj.atpSubDocuments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPSubDocuments>, I>>(base?: I): MeetingATPSubDocuments {
    return MeetingATPSubDocuments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPSubDocuments>, I>>(object: I): MeetingATPSubDocuments {
    const message = createBaseMeetingATPSubDocuments();
    message.atpSubDocuments = object.atpSubDocuments?.map((e) => MeetingATPSubDocument.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingATPTestUnit(): MeetingATPTestUnit {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    positiveDesc: undefined,
    negativeDesc: undefined,
    subDocumentId: undefined,
    unitOfMeasurement: undefined,
  };
}

export const MeetingATPTestUnit = {
  encode(message: MeetingATPTestUnit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.positiveDesc !== undefined) {
      writer.uint32(26).string(message.positiveDesc);
    }
    if (message.negativeDesc !== undefined) {
      writer.uint32(34).string(message.negativeDesc);
    }
    if (message.subDocumentId !== undefined) {
      writer.uint32(40).uint64(message.subDocumentId);
    }
    if (message.unitOfMeasurement !== undefined) {
      writer.uint32(50).string(message.unitOfMeasurement);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPTestUnit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPTestUnit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.positiveDesc = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.negativeDesc = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.subDocumentId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.unitOfMeasurement = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPTestUnit {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      positiveDesc: isSet(object.positiveDesc) ? String(object.positiveDesc) : undefined,
      negativeDesc: isSet(object.negativeDesc) ? String(object.negativeDesc) : undefined,
      subDocumentId: isSet(object.subDocumentId) ? String(object.subDocumentId) : undefined,
      unitOfMeasurement: isSet(object.unitOfMeasurement) ? String(object.unitOfMeasurement) : undefined,
    };
  },

  toJSON(message: MeetingATPTestUnit): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.positiveDesc !== undefined && (obj.positiveDesc = message.positiveDesc);
    message.negativeDesc !== undefined && (obj.negativeDesc = message.negativeDesc);
    message.subDocumentId !== undefined && (obj.subDocumentId = message.subDocumentId);
    message.unitOfMeasurement !== undefined && (obj.unitOfMeasurement = message.unitOfMeasurement);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPTestUnit>, I>>(base?: I): MeetingATPTestUnit {
    return MeetingATPTestUnit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPTestUnit>, I>>(object: I): MeetingATPTestUnit {
    const message = createBaseMeetingATPTestUnit();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.positiveDesc = object.positiveDesc ?? undefined;
    message.negativeDesc = object.negativeDesc ?? undefined;
    message.subDocumentId = object.subDocumentId ?? undefined;
    message.unitOfMeasurement = object.unitOfMeasurement ?? undefined;
    return message;
  },
};

function createBaseMeetingATPTestUnits(): MeetingATPTestUnits {
  return { testUnits: [] };
}

export const MeetingATPTestUnits = {
  encode(message: MeetingATPTestUnits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.testUnits) {
      MeetingATPTestUnit.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingATPTestUnits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingATPTestUnits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.testUnits.push(MeetingATPTestUnit.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingATPTestUnits {
    return {
      testUnits: Array.isArray(object?.testUnits)
        ? object.testUnits.map((e: any) => MeetingATPTestUnit.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingATPTestUnits): unknown {
    const obj: any = {};
    if (message.testUnits) {
      obj.testUnits = message.testUnits.map((e) => e ? MeetingATPTestUnit.toJSON(e) : undefined);
    } else {
      obj.testUnits = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingATPTestUnits>, I>>(base?: I): MeetingATPTestUnits {
    return MeetingATPTestUnits.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingATPTestUnits>, I>>(object: I): MeetingATPTestUnits {
    const message = createBaseMeetingATPTestUnits();
    message.testUnits = object.testUnits?.map((e) => MeetingATPTestUnit.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingBASTDocument(): MeetingBASTDocument {
  return {
    masterJavaBaseModel: undefined,
    date: undefined,
    gspePics: [],
    custPics: [],
    projectId: undefined,
    bastNumber: undefined,
    customerId: undefined,
    purchaseOrderId: undefined,
    siteId: undefined,
    templateType: undefined,
    projectCostIdr: undefined,
    customerReferenceName: undefined,
    grReports: [],
    projectIsManual: undefined,
    projectManualName: undefined,
    siteIsManual: undefined,
    siteManualName: undefined,
    poIsManual: undefined,
    poManualName: undefined,
    customTemplate: undefined,
    customTemplateId: undefined,
    variables: [],
  };
}

export const MeetingBASTDocument = {
  encode(message: MeetingBASTDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(18).string(message.date);
    }
    for (const v of message.gspePics) {
      MeetingBASTDocumentGSPEPIC.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.custPics) {
      MeetingBASTDocumentCustomerPIC.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.projectId !== undefined) {
      writer.uint32(40).uint64(message.projectId);
    }
    if (message.bastNumber !== undefined) {
      writer.uint32(50).string(message.bastNumber);
    }
    if (message.customerId !== undefined) {
      writer.uint32(56).uint64(message.customerId);
    }
    if (message.purchaseOrderId !== undefined) {
      writer.uint32(64).uint64(message.purchaseOrderId);
    }
    if (message.siteId !== undefined) {
      writer.uint32(72).uint64(message.siteId);
    }
    if (message.templateType !== undefined) {
      writer.uint32(82).string(message.templateType);
    }
    if (message.projectCostIdr !== undefined) {
      writer.uint32(89).double(message.projectCostIdr);
    }
    if (message.customerReferenceName !== undefined) {
      writer.uint32(98).string(message.customerReferenceName);
    }
    for (const v of message.grReports) {
      MeetingBASTDocumentGRReport.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.projectIsManual !== undefined) {
      writer.uint32(112).bool(message.projectIsManual);
    }
    if (message.projectManualName !== undefined) {
      writer.uint32(122).string(message.projectManualName);
    }
    if (message.siteIsManual !== undefined) {
      writer.uint32(128).bool(message.siteIsManual);
    }
    if (message.siteManualName !== undefined) {
      writer.uint32(138).string(message.siteManualName);
    }
    if (message.poIsManual !== undefined) {
      writer.uint32(144).bool(message.poIsManual);
    }
    if (message.poManualName !== undefined) {
      writer.uint32(154).string(message.poManualName);
    }
    if (message.customTemplate !== undefined) {
      writer.uint32(162).string(message.customTemplate);
    }
    if (message.customTemplateId !== undefined) {
      writer.uint32(168).uint64(message.customTemplateId);
    }
    for (const v of message.variables) {
      MeetingBASTDocumentCustomTemplateVariable.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.date = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.gspePics.push(MeetingBASTDocumentGSPEPIC.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.custPics.push(MeetingBASTDocumentCustomerPIC.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.projectId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.bastNumber = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.customerId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.purchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.templateType = reader.string();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.projectCostIdr = reader.double();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.customerReferenceName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.grReports.push(MeetingBASTDocumentGRReport.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.projectIsManual = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.projectManualName = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.siteIsManual = reader.bool();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.siteManualName = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.poIsManual = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.poManualName = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.customTemplate = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.customTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.variables.push(MeetingBASTDocumentCustomTemplateVariable.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocument {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      gspePics: Array.isArray(object?.gspePics)
        ? object.gspePics.map((e: any) => MeetingBASTDocumentGSPEPIC.fromJSON(e))
        : [],
      custPics: Array.isArray(object?.custPics)
        ? object.custPics.map((e: any) => MeetingBASTDocumentCustomerPIC.fromJSON(e))
        : [],
      projectId: isSet(object.projectId) ? String(object.projectId) : undefined,
      bastNumber: isSet(object.bastNumber) ? String(object.bastNumber) : undefined,
      customerId: isSet(object.customerId) ? String(object.customerId) : undefined,
      purchaseOrderId: isSet(object.purchaseOrderId) ? String(object.purchaseOrderId) : undefined,
      siteId: isSet(object.siteId) ? String(object.siteId) : undefined,
      templateType: isSet(object.templateType) ? String(object.templateType) : undefined,
      projectCostIdr: isSet(object.projectCostIdr) ? Number(object.projectCostIdr) : undefined,
      customerReferenceName: isSet(object.customerReferenceName) ? String(object.customerReferenceName) : undefined,
      grReports: Array.isArray(object?.grReports)
        ? object.grReports.map((e: any) => MeetingBASTDocumentGRReport.fromJSON(e))
        : [],
      projectIsManual: isSet(object.projectIsManual) ? Boolean(object.projectIsManual) : undefined,
      projectManualName: isSet(object.projectManualName) ? String(object.projectManualName) : undefined,
      siteIsManual: isSet(object.siteIsManual) ? Boolean(object.siteIsManual) : undefined,
      siteManualName: isSet(object.siteManualName) ? String(object.siteManualName) : undefined,
      poIsManual: isSet(object.poIsManual) ? Boolean(object.poIsManual) : undefined,
      poManualName: isSet(object.poManualName) ? String(object.poManualName) : undefined,
      customTemplate: isSet(object.customTemplate) ? String(object.customTemplate) : undefined,
      customTemplateId: isSet(object.customTemplateId) ? String(object.customTemplateId) : undefined,
      variables: Array.isArray(object?.variables)
        ? object.variables.map((e: any) => MeetingBASTDocumentCustomTemplateVariable.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingBASTDocument): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.date !== undefined && (obj.date = message.date);
    if (message.gspePics) {
      obj.gspePics = message.gspePics.map((e) => e ? MeetingBASTDocumentGSPEPIC.toJSON(e) : undefined);
    } else {
      obj.gspePics = [];
    }
    if (message.custPics) {
      obj.custPics = message.custPics.map((e) => e ? MeetingBASTDocumentCustomerPIC.toJSON(e) : undefined);
    } else {
      obj.custPics = [];
    }
    message.projectId !== undefined && (obj.projectId = message.projectId);
    message.bastNumber !== undefined && (obj.bastNumber = message.bastNumber);
    message.customerId !== undefined && (obj.customerId = message.customerId);
    message.purchaseOrderId !== undefined && (obj.purchaseOrderId = message.purchaseOrderId);
    message.siteId !== undefined && (obj.siteId = message.siteId);
    message.templateType !== undefined && (obj.templateType = message.templateType);
    message.projectCostIdr !== undefined && (obj.projectCostIdr = message.projectCostIdr);
    message.customerReferenceName !== undefined && (obj.customerReferenceName = message.customerReferenceName);
    if (message.grReports) {
      obj.grReports = message.grReports.map((e) => e ? MeetingBASTDocumentGRReport.toJSON(e) : undefined);
    } else {
      obj.grReports = [];
    }
    message.projectIsManual !== undefined && (obj.projectIsManual = message.projectIsManual);
    message.projectManualName !== undefined && (obj.projectManualName = message.projectManualName);
    message.siteIsManual !== undefined && (obj.siteIsManual = message.siteIsManual);
    message.siteManualName !== undefined && (obj.siteManualName = message.siteManualName);
    message.poIsManual !== undefined && (obj.poIsManual = message.poIsManual);
    message.poManualName !== undefined && (obj.poManualName = message.poManualName);
    message.customTemplate !== undefined && (obj.customTemplate = message.customTemplate);
    message.customTemplateId !== undefined && (obj.customTemplateId = message.customTemplateId);
    if (message.variables) {
      obj.variables = message.variables.map((e) => e ? MeetingBASTDocumentCustomTemplateVariable.toJSON(e) : undefined);
    } else {
      obj.variables = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocument>, I>>(base?: I): MeetingBASTDocument {
    return MeetingBASTDocument.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocument>, I>>(object: I): MeetingBASTDocument {
    const message = createBaseMeetingBASTDocument();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.date = object.date ?? undefined;
    message.gspePics = object.gspePics?.map((e) => MeetingBASTDocumentGSPEPIC.fromPartial(e)) || [];
    message.custPics = object.custPics?.map((e) => MeetingBASTDocumentCustomerPIC.fromPartial(e)) || [];
    message.projectId = object.projectId ?? undefined;
    message.bastNumber = object.bastNumber ?? undefined;
    message.customerId = object.customerId ?? undefined;
    message.purchaseOrderId = object.purchaseOrderId ?? undefined;
    message.siteId = object.siteId ?? undefined;
    message.templateType = object.templateType ?? undefined;
    message.projectCostIdr = object.projectCostIdr ?? undefined;
    message.customerReferenceName = object.customerReferenceName ?? undefined;
    message.grReports = object.grReports?.map((e) => MeetingBASTDocumentGRReport.fromPartial(e)) || [];
    message.projectIsManual = object.projectIsManual ?? undefined;
    message.projectManualName = object.projectManualName ?? undefined;
    message.siteIsManual = object.siteIsManual ?? undefined;
    message.siteManualName = object.siteManualName ?? undefined;
    message.poIsManual = object.poIsManual ?? undefined;
    message.poManualName = object.poManualName ?? undefined;
    message.customTemplate = object.customTemplate ?? undefined;
    message.customTemplateId = object.customTemplateId ?? undefined;
    message.variables = object.variables?.map((e) => MeetingBASTDocumentCustomTemplateVariable.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingBASTDocumentCustomTemplateVariable(): MeetingBASTDocumentCustomTemplateVariable {
  return { masterJavaBaseModel: undefined, value: undefined, text: undefined, bastDocumentId: undefined };
}

export const MeetingBASTDocumentCustomTemplateVariable = {
  encode(message: MeetingBASTDocumentCustomTemplateVariable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.value !== undefined) {
      writer.uint32(16).int32(message.value);
    }
    if (message.text !== undefined) {
      writer.uint32(26).string(message.text);
    }
    if (message.bastDocumentId !== undefined) {
      writer.uint32(32).uint64(message.bastDocumentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentCustomTemplateVariable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentCustomTemplateVariable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.text = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bastDocumentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentCustomTemplateVariable {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
      text: isSet(object.text) ? String(object.text) : undefined,
      bastDocumentId: isSet(object.bastDocumentId) ? String(object.bastDocumentId) : undefined,
    };
  },

  toJSON(message: MeetingBASTDocumentCustomTemplateVariable): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.value !== undefined && (obj.value = Math.round(message.value));
    message.text !== undefined && (obj.text = message.text);
    message.bastDocumentId !== undefined && (obj.bastDocumentId = message.bastDocumentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentCustomTemplateVariable>, I>>(
    base?: I,
  ): MeetingBASTDocumentCustomTemplateVariable {
    return MeetingBASTDocumentCustomTemplateVariable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentCustomTemplateVariable>, I>>(
    object: I,
  ): MeetingBASTDocumentCustomTemplateVariable {
    const message = createBaseMeetingBASTDocumentCustomTemplateVariable();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.value = object.value ?? undefined;
    message.text = object.text ?? undefined;
    message.bastDocumentId = object.bastDocumentId ?? undefined;
    return message;
  },
};

function createBaseMeetingBASTDocumentCustomTemplate(): MeetingBASTDocumentCustomTemplate {
  return { masterJavaBaseModel: undefined, customTemplate: undefined, name: undefined };
}

export const MeetingBASTDocumentCustomTemplate = {
  encode(message: MeetingBASTDocumentCustomTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.customTemplate !== undefined) {
      writer.uint32(18).string(message.customTemplate);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentCustomTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentCustomTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customTemplate = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentCustomTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      customTemplate: isSet(object.customTemplate) ? String(object.customTemplate) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: MeetingBASTDocumentCustomTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.customTemplate !== undefined && (obj.customTemplate = message.customTemplate);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentCustomTemplate>, I>>(
    base?: I,
  ): MeetingBASTDocumentCustomTemplate {
    return MeetingBASTDocumentCustomTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentCustomTemplate>, I>>(
    object: I,
  ): MeetingBASTDocumentCustomTemplate {
    const message = createBaseMeetingBASTDocumentCustomTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.customTemplate = object.customTemplate ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseMeetingBASTDocumentCustomTemplates(): MeetingBASTDocumentCustomTemplates {
  return { templates: [] };
}

export const MeetingBASTDocumentCustomTemplates = {
  encode(message: MeetingBASTDocumentCustomTemplates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      MeetingBASTDocumentCustomTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentCustomTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentCustomTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(MeetingBASTDocumentCustomTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentCustomTemplates {
    return {
      templates: Array.isArray(object?.templates)
        ? object.templates.map((e: any) => MeetingBASTDocumentCustomTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingBASTDocumentCustomTemplates): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) => e ? MeetingBASTDocumentCustomTemplate.toJSON(e) : undefined);
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentCustomTemplates>, I>>(
    base?: I,
  ): MeetingBASTDocumentCustomTemplates {
    return MeetingBASTDocumentCustomTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentCustomTemplates>, I>>(
    object: I,
  ): MeetingBASTDocumentCustomTemplates {
    const message = createBaseMeetingBASTDocumentCustomTemplates();
    message.templates = object.templates?.map((e) => MeetingBASTDocumentCustomTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingBASTDocumentGRReport(): MeetingBASTDocumentGRReport {
  return { masterJavaBaseModel: undefined, name: undefined, bastDocumentId: undefined, items: [], date: undefined };
}

export const MeetingBASTDocumentGRReport = {
  encode(message: MeetingBASTDocumentGRReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.bastDocumentId !== undefined) {
      writer.uint32(24).uint64(message.bastDocumentId);
    }
    for (const v of message.items) {
      MeetingBASTDocumentGRReportItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(42).string(message.date);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentGRReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentGRReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bastDocumentId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.items.push(MeetingBASTDocumentGRReportItem.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentGRReport {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      bastDocumentId: isSet(object.bastDocumentId) ? String(object.bastDocumentId) : undefined,
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MeetingBASTDocumentGRReportItem.fromJSON(e))
        : [],
      date: isSet(object.date) ? String(object.date) : undefined,
    };
  },

  toJSON(message: MeetingBASTDocumentGRReport): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.bastDocumentId !== undefined && (obj.bastDocumentId = message.bastDocumentId);
    if (message.items) {
      obj.items = message.items.map((e) => e ? MeetingBASTDocumentGRReportItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.date !== undefined && (obj.date = message.date);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentGRReport>, I>>(base?: I): MeetingBASTDocumentGRReport {
    return MeetingBASTDocumentGRReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentGRReport>, I>>(object: I): MeetingBASTDocumentGRReport {
    const message = createBaseMeetingBASTDocumentGRReport();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.bastDocumentId = object.bastDocumentId ?? undefined;
    message.items = object.items?.map((e) => MeetingBASTDocumentGRReportItem.fromPartial(e)) || [];
    message.date = object.date ?? undefined;
    return message;
  },
};

function createBaseMeetingBASTDocumentGRReportItem(): MeetingBASTDocumentGRReportItem {
  return {
    masterJavaBaseModel: undefined,
    extCustomerId: undefined,
    remark: undefined,
    bastDocumentGrReportId: undefined,
    values: [],
  };
}

export const MeetingBASTDocumentGRReportItem = {
  encode(message: MeetingBASTDocumentGRReportItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extCustomerId !== undefined) {
      writer.uint32(16).uint64(message.extCustomerId);
    }
    if (message.remark !== undefined) {
      writer.uint32(26).string(message.remark);
    }
    if (message.bastDocumentGrReportId !== undefined) {
      writer.uint32(32).uint64(message.bastDocumentGrReportId);
    }
    for (const v of message.values) {
      MeetingBASTDocumentGRValue.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentGRReportItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentGRReportItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extCustomerId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bastDocumentGrReportId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.values.push(MeetingBASTDocumentGRValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentGRReportItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extCustomerId: isSet(object.extCustomerId) ? String(object.extCustomerId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      bastDocumentGrReportId: isSet(object.bastDocumentGrReportId) ? String(object.bastDocumentGrReportId) : undefined,
      values: Array.isArray(object?.values)
        ? object.values.map((e: any) => MeetingBASTDocumentGRValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingBASTDocumentGRReportItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extCustomerId !== undefined && (obj.extCustomerId = message.extCustomerId);
    message.remark !== undefined && (obj.remark = message.remark);
    message.bastDocumentGrReportId !== undefined && (obj.bastDocumentGrReportId = message.bastDocumentGrReportId);
    if (message.values) {
      obj.values = message.values.map((e) => e ? MeetingBASTDocumentGRValue.toJSON(e) : undefined);
    } else {
      obj.values = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentGRReportItem>, I>>(base?: I): MeetingBASTDocumentGRReportItem {
    return MeetingBASTDocumentGRReportItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentGRReportItem>, I>>(
    object: I,
  ): MeetingBASTDocumentGRReportItem {
    const message = createBaseMeetingBASTDocumentGRReportItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extCustomerId = object.extCustomerId ?? undefined;
    message.remark = object.remark ?? undefined;
    message.bastDocumentGrReportId = object.bastDocumentGrReportId ?? undefined;
    message.values = object.values?.map((e) => MeetingBASTDocumentGRValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingBASTDocumentGRValue(): MeetingBASTDocumentGRValue {
  return {
    masterJavaBaseModel: undefined,
    type: undefined,
    value: undefined,
    bastDocumentGrReportItemId: undefined,
    identifier: undefined,
  };
}

export const MeetingBASTDocumentGRValue = {
  encode(message: MeetingBASTDocumentGRValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    if (message.value !== undefined) {
      writer.uint32(25).double(message.value);
    }
    if (message.bastDocumentGrReportItemId !== undefined) {
      writer.uint32(32).uint64(message.bastDocumentGrReportItemId);
    }
    if (message.identifier !== undefined) {
      writer.uint32(40).int32(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentGRValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentGRValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.value = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bastDocumentGrReportItemId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.identifier = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentGRValue {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      type: isSet(object.type) ? meetingBASTDocumentGRReportValueTypeFromJSON(object.type) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
      bastDocumentGrReportItemId: isSet(object.bastDocumentGrReportItemId)
        ? String(object.bastDocumentGrReportItemId)
        : undefined,
      identifier: isSet(object.identifier) ? Number(object.identifier) : undefined,
    };
  },

  toJSON(message: MeetingBASTDocumentGRValue): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? meetingBASTDocumentGRReportValueTypeToJSON(message.type) : undefined);
    message.value !== undefined && (obj.value = message.value);
    message.bastDocumentGrReportItemId !== undefined &&
      (obj.bastDocumentGrReportItemId = message.bastDocumentGrReportItemId);
    message.identifier !== undefined && (obj.identifier = Math.round(message.identifier));
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentGRValue>, I>>(base?: I): MeetingBASTDocumentGRValue {
    return MeetingBASTDocumentGRValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentGRValue>, I>>(object: I): MeetingBASTDocumentGRValue {
    const message = createBaseMeetingBASTDocumentGRValue();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.type = object.type ?? undefined;
    message.value = object.value ?? undefined;
    message.bastDocumentGrReportItemId = object.bastDocumentGrReportItemId ?? undefined;
    message.identifier = object.identifier ?? undefined;
    return message;
  },
};

function createBaseMeetingBASTDocumentGRReports(): MeetingBASTDocumentGRReports {
  return { reports: [] };
}

export const MeetingBASTDocumentGRReports = {
  encode(message: MeetingBASTDocumentGRReports, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.reports) {
      MeetingBASTDocumentGRReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentGRReports {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentGRReports();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reports.push(MeetingBASTDocumentGRReport.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentGRReports {
    return {
      reports: Array.isArray(object?.reports)
        ? object.reports.map((e: any) => MeetingBASTDocumentGRReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingBASTDocumentGRReports): unknown {
    const obj: any = {};
    if (message.reports) {
      obj.reports = message.reports.map((e) => e ? MeetingBASTDocumentGRReport.toJSON(e) : undefined);
    } else {
      obj.reports = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentGRReports>, I>>(base?: I): MeetingBASTDocumentGRReports {
    return MeetingBASTDocumentGRReports.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentGRReports>, I>>(object: I): MeetingBASTDocumentGRReports {
    const message = createBaseMeetingBASTDocumentGRReports();
    message.reports = object.reports?.map((e) => MeetingBASTDocumentGRReport.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingBASTDocuments(): MeetingBASTDocuments {
  return { bastDocuments: [] };
}

export const MeetingBASTDocuments = {
  encode(message: MeetingBASTDocuments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bastDocuments) {
      MeetingBASTDocument.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocuments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocuments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bastDocuments.push(MeetingBASTDocument.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocuments {
    return {
      bastDocuments: Array.isArray(object?.bastDocuments)
        ? object.bastDocuments.map((e: any) => MeetingBASTDocument.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingBASTDocuments): unknown {
    const obj: any = {};
    if (message.bastDocuments) {
      obj.bastDocuments = message.bastDocuments.map((e) => e ? MeetingBASTDocument.toJSON(e) : undefined);
    } else {
      obj.bastDocuments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocuments>, I>>(base?: I): MeetingBASTDocuments {
    return MeetingBASTDocuments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocuments>, I>>(object: I): MeetingBASTDocuments {
    const message = createBaseMeetingBASTDocuments();
    message.bastDocuments = object.bastDocuments?.map((e) => MeetingBASTDocument.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingBASTDocumentGSPEPIC(): MeetingBASTDocumentGSPEPIC {
  return { masterJavaBaseModel: undefined, extUserId: undefined, position: undefined, bastDocumentId: undefined };
}

export const MeetingBASTDocumentGSPEPIC = {
  encode(message: MeetingBASTDocumentGSPEPIC, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.position !== undefined) {
      writer.uint32(26).string(message.position);
    }
    if (message.bastDocumentId !== undefined) {
      writer.uint32(32).uint64(message.bastDocumentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentGSPEPIC {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentGSPEPIC();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.position = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bastDocumentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentGSPEPIC {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      position: isSet(object.position) ? String(object.position) : undefined,
      bastDocumentId: isSet(object.bastDocumentId) ? String(object.bastDocumentId) : undefined,
    };
  },

  toJSON(message: MeetingBASTDocumentGSPEPIC): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.position !== undefined && (obj.position = message.position);
    message.bastDocumentId !== undefined && (obj.bastDocumentId = message.bastDocumentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentGSPEPIC>, I>>(base?: I): MeetingBASTDocumentGSPEPIC {
    return MeetingBASTDocumentGSPEPIC.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentGSPEPIC>, I>>(object: I): MeetingBASTDocumentGSPEPIC {
    const message = createBaseMeetingBASTDocumentGSPEPIC();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.position = object.position ?? undefined;
    message.bastDocumentId = object.bastDocumentId ?? undefined;
    return message;
  },
};

function createBaseMeetingBASTDocumentCustomerPIC(): MeetingBASTDocumentCustomerPIC {
  return { masterJavaBaseModel: undefined, name: undefined, position: undefined, bastDocumentId: undefined };
}

export const MeetingBASTDocumentCustomerPIC = {
  encode(message: MeetingBASTDocumentCustomerPIC, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.position !== undefined) {
      writer.uint32(26).string(message.position);
    }
    if (message.bastDocumentId !== undefined) {
      writer.uint32(32).uint64(message.bastDocumentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingBASTDocumentCustomerPIC {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingBASTDocumentCustomerPIC();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.position = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bastDocumentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingBASTDocumentCustomerPIC {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      position: isSet(object.position) ? String(object.position) : undefined,
      bastDocumentId: isSet(object.bastDocumentId) ? String(object.bastDocumentId) : undefined,
    };
  },

  toJSON(message: MeetingBASTDocumentCustomerPIC): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.position !== undefined && (obj.position = message.position);
    message.bastDocumentId !== undefined && (obj.bastDocumentId = message.bastDocumentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingBASTDocumentCustomerPIC>, I>>(base?: I): MeetingBASTDocumentCustomerPIC {
    return MeetingBASTDocumentCustomerPIC.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingBASTDocumentCustomerPIC>, I>>(
    object: I,
  ): MeetingBASTDocumentCustomerPIC {
    const message = createBaseMeetingBASTDocumentCustomerPIC();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.position = object.position ?? undefined;
    message.bastDocumentId = object.bastDocumentId ?? undefined;
    return message;
  },
};

function createBaseMeetingProjects(): MeetingProjects {
  return { projects: [] };
}

export const MeetingProjects = {
  encode(message: MeetingProjects, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.projects) {
      MeetingProject.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProjects {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProjects();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.projects.push(MeetingProject.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProjects {
    return {
      projects: Array.isArray(object?.projects) ? object.projects.map((e: any) => MeetingProject.fromJSON(e)) : [],
    };
  },

  toJSON(message: MeetingProjects): unknown {
    const obj: any = {};
    if (message.projects) {
      obj.projects = message.projects.map((e) => e ? MeetingProject.toJSON(e) : undefined);
    } else {
      obj.projects = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProjects>, I>>(base?: I): MeetingProjects {
    return MeetingProjects.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProjects>, I>>(object: I): MeetingProjects {
    const message = createBaseMeetingProjects();
    message.projects = object.projects?.map((e) => MeetingProject.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingProjectProduct(): MeetingProjectProduct {
  return {
    masterJavaBaseModel: undefined,
    extCrmPurchaseOrderItemId: undefined,
    name: undefined,
    qty: undefined,
    masterTemplates: [],
    projectTemplatePresetId: undefined,
  };
}

export const MeetingProjectProduct = {
  encode(message: MeetingProjectProduct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extCrmPurchaseOrderItemId !== undefined) {
      writer.uint32(16).uint64(message.extCrmPurchaseOrderItemId);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.qty !== undefined) {
      writer.uint32(32).int32(message.qty);
    }
    for (const v of message.masterTemplates) {
      MeetingProjectProductMasterTemplate.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.projectTemplatePresetId !== undefined) {
      writer.uint32(48).uint64(message.projectTemplatePresetId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProjectProduct {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProjectProduct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extCrmPurchaseOrderItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.masterTemplates.push(MeetingProjectProductMasterTemplate.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.projectTemplatePresetId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProjectProduct {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extCrmPurchaseOrderItemId: isSet(object.extCrmPurchaseOrderItemId)
        ? String(object.extCrmPurchaseOrderItemId)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      masterTemplates: Array.isArray(object?.masterTemplates)
        ? object.masterTemplates.map((e: any) => MeetingProjectProductMasterTemplate.fromJSON(e))
        : [],
      projectTemplatePresetId: isSet(object.projectTemplatePresetId)
        ? String(object.projectTemplatePresetId)
        : undefined,
    };
  },

  toJSON(message: MeetingProjectProduct): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extCrmPurchaseOrderItemId !== undefined &&
      (obj.extCrmPurchaseOrderItemId = message.extCrmPurchaseOrderItemId);
    message.name !== undefined && (obj.name = message.name);
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    if (message.masterTemplates) {
      obj.masterTemplates = message.masterTemplates.map((e) =>
        e ? MeetingProjectProductMasterTemplate.toJSON(e) : undefined
      );
    } else {
      obj.masterTemplates = [];
    }
    message.projectTemplatePresetId !== undefined && (obj.projectTemplatePresetId = message.projectTemplatePresetId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProjectProduct>, I>>(base?: I): MeetingProjectProduct {
    return MeetingProjectProduct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProjectProduct>, I>>(object: I): MeetingProjectProduct {
    const message = createBaseMeetingProjectProduct();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extCrmPurchaseOrderItemId = object.extCrmPurchaseOrderItemId ?? undefined;
    message.name = object.name ?? undefined;
    message.qty = object.qty ?? undefined;
    message.masterTemplates = object.masterTemplates?.map((e) => MeetingProjectProductMasterTemplate.fromPartial(e)) ||
      [];
    message.projectTemplatePresetId = object.projectTemplatePresetId ?? undefined;
    return message;
  },
};

function createBaseMeetingProjectProductMasterTemplate(): MeetingProjectProductMasterTemplate {
  return {
    masterJavaBaseModel: undefined,
    masterTemplateId: undefined,
    date: undefined,
    completed: undefined,
    projectProductId: undefined,
    excluded: undefined,
  };
}

export const MeetingProjectProductMasterTemplate = {
  encode(message: MeetingProjectProductMasterTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.masterTemplateId !== undefined) {
      writer.uint32(16).uint64(message.masterTemplateId);
    }
    if (message.date !== undefined) {
      writer.uint32(26).string(message.date);
    }
    if (message.completed !== undefined) {
      writer.uint32(34).string(message.completed);
    }
    if (message.projectProductId !== undefined) {
      writer.uint32(40).uint64(message.projectProductId);
    }
    if (message.excluded !== undefined) {
      writer.uint32(48).bool(message.excluded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProjectProductMasterTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProjectProductMasterTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.masterTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.date = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.completed = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.projectProductId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.excluded = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProjectProductMasterTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      masterTemplateId: isSet(object.masterTemplateId) ? String(object.masterTemplateId) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      completed: isSet(object.completed) ? String(object.completed) : undefined,
      projectProductId: isSet(object.projectProductId) ? String(object.projectProductId) : undefined,
      excluded: isSet(object.excluded) ? Boolean(object.excluded) : undefined,
    };
  },

  toJSON(message: MeetingProjectProductMasterTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.masterTemplateId !== undefined && (obj.masterTemplateId = message.masterTemplateId);
    message.date !== undefined && (obj.date = message.date);
    message.completed !== undefined && (obj.completed = message.completed);
    message.projectProductId !== undefined && (obj.projectProductId = message.projectProductId);
    message.excluded !== undefined && (obj.excluded = message.excluded);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProjectProductMasterTemplate>, I>>(
    base?: I,
  ): MeetingProjectProductMasterTemplate {
    return MeetingProjectProductMasterTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProjectProductMasterTemplate>, I>>(
    object: I,
  ): MeetingProjectProductMasterTemplate {
    const message = createBaseMeetingProjectProductMasterTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.masterTemplateId = object.masterTemplateId ?? undefined;
    message.date = object.date ?? undefined;
    message.completed = object.completed ?? undefined;
    message.projectProductId = object.projectProductId ?? undefined;
    message.excluded = object.excluded ?? undefined;
    return message;
  },
};

function createBaseMeetingMasterTemplatePreset(): MeetingMasterTemplatePreset {
  return { masterJavaBaseModel: undefined, name: undefined, items: [] };
}

export const MeetingMasterTemplatePreset = {
  encode(message: MeetingMasterTemplatePreset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.items) {
      MeetingMasterTemplatePresetItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMasterTemplatePreset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMasterTemplatePreset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.items.push(MeetingMasterTemplatePresetItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMasterTemplatePreset {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MeetingMasterTemplatePresetItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMasterTemplatePreset): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.items) {
      obj.items = message.items.map((e) => e ? MeetingMasterTemplatePresetItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMasterTemplatePreset>, I>>(base?: I): MeetingMasterTemplatePreset {
    return MeetingMasterTemplatePreset.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMasterTemplatePreset>, I>>(object: I): MeetingMasterTemplatePreset {
    const message = createBaseMeetingMasterTemplatePreset();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.items = object.items?.map((e) => MeetingMasterTemplatePresetItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMasterTemplatePresets(): MeetingMasterTemplatePresets {
  return { presets: [] };
}

export const MeetingMasterTemplatePresets = {
  encode(message: MeetingMasterTemplatePresets, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.presets) {
      MeetingMasterTemplatePreset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMasterTemplatePresets {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMasterTemplatePresets();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.presets.push(MeetingMasterTemplatePreset.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMasterTemplatePresets {
    return {
      presets: Array.isArray(object?.presets)
        ? object.presets.map((e: any) => MeetingMasterTemplatePreset.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMasterTemplatePresets): unknown {
    const obj: any = {};
    if (message.presets) {
      obj.presets = message.presets.map((e) => e ? MeetingMasterTemplatePreset.toJSON(e) : undefined);
    } else {
      obj.presets = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMasterTemplatePresets>, I>>(base?: I): MeetingMasterTemplatePresets {
    return MeetingMasterTemplatePresets.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMasterTemplatePresets>, I>>(object: I): MeetingMasterTemplatePresets {
    const message = createBaseMeetingMasterTemplatePresets();
    message.presets = object.presets?.map((e) => MeetingMasterTemplatePreset.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMasterTemplatePresetItem(): MeetingMasterTemplatePresetItem {
  return {
    masterJavaBaseModel: undefined,
    masterTemplatePresetId: undefined,
    masterTemplateId: undefined,
    excluded: undefined,
  };
}

export const MeetingMasterTemplatePresetItem = {
  encode(message: MeetingMasterTemplatePresetItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.masterTemplatePresetId !== undefined) {
      writer.uint32(16).uint64(message.masterTemplatePresetId);
    }
    if (message.masterTemplateId !== undefined) {
      writer.uint32(32).uint64(message.masterTemplateId);
    }
    if (message.excluded !== undefined) {
      writer.uint32(24).bool(message.excluded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMasterTemplatePresetItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMasterTemplatePresetItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.masterTemplatePresetId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.masterTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.excluded = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMasterTemplatePresetItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      masterTemplatePresetId: isSet(object.masterTemplatePresetId) ? String(object.masterTemplatePresetId) : undefined,
      masterTemplateId: isSet(object.masterTemplateId) ? String(object.masterTemplateId) : undefined,
      excluded: isSet(object.excluded) ? Boolean(object.excluded) : undefined,
    };
  },

  toJSON(message: MeetingMasterTemplatePresetItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.masterTemplatePresetId !== undefined && (obj.masterTemplatePresetId = message.masterTemplatePresetId);
    message.masterTemplateId !== undefined && (obj.masterTemplateId = message.masterTemplateId);
    message.excluded !== undefined && (obj.excluded = message.excluded);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMasterTemplatePresetItem>, I>>(base?: I): MeetingMasterTemplatePresetItem {
    return MeetingMasterTemplatePresetItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMasterTemplatePresetItem>, I>>(
    object: I,
  ): MeetingMasterTemplatePresetItem {
    const message = createBaseMeetingMasterTemplatePresetItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.masterTemplatePresetId = object.masterTemplatePresetId ?? undefined;
    message.masterTemplateId = object.masterTemplateId ?? undefined;
    message.excluded = object.excluded ?? undefined;
    return message;
  },
};

function createBaseMeetingMasterTemplatePresetItems(): MeetingMasterTemplatePresetItems {
  return { items: [] };
}

export const MeetingMasterTemplatePresetItems = {
  encode(message: MeetingMasterTemplatePresetItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      MeetingMasterTemplatePresetItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMasterTemplatePresetItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMasterTemplatePresetItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(MeetingMasterTemplatePresetItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMasterTemplatePresetItems {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MeetingMasterTemplatePresetItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMasterTemplatePresetItems): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? MeetingMasterTemplatePresetItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMasterTemplatePresetItems>, I>>(
    base?: I,
  ): MeetingMasterTemplatePresetItems {
    return MeetingMasterTemplatePresetItems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMasterTemplatePresetItems>, I>>(
    object: I,
  ): MeetingMasterTemplatePresetItems {
    const message = createBaseMeetingMasterTemplatePresetItems();
    message.items = object.items?.map((e) => MeetingMasterTemplatePresetItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingProjectContract(): MeetingProjectContract {
  return { masterJavaBaseModel: undefined };
}

export const MeetingProjectContract = {
  encode(message: MeetingProjectContract, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingProjectContract {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingProjectContract();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingProjectContract {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingProjectContract): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingProjectContract>, I>>(base?: I): MeetingProjectContract {
    return MeetingProjectContract.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingProjectContract>, I>>(object: I): MeetingProjectContract {
    const message = createBaseMeetingProjectContract();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingPlace(): MeetingPlace {
  return { masterJavaBaseModel: undefined };
}

export const MeetingPlace = {
  encode(message: MeetingPlace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingPlace {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingPlace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingPlace {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingPlace): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingPlace>, I>>(base?: I): MeetingPlace {
    return MeetingPlace.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingPlace>, I>>(object: I): MeetingPlace {
    const message = createBaseMeetingPlace();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingRoom(): MeetingRoom {
  return { masterJavaBaseModel: undefined };
}

export const MeetingRoom = {
  encode(message: MeetingRoom, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingRoom {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingRoom();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingRoom {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingRoom): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingRoom>, I>>(base?: I): MeetingRoom {
    return MeetingRoom.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingRoom>, I>>(object: I): MeetingRoom {
    const message = createBaseMeetingRoom();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingPlatform(): MeetingPlatform {
  return { masterJavaBaseModel: undefined };
}

export const MeetingPlatform = {
  encode(message: MeetingPlatform, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingPlatform {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingPlatform();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingPlatform {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingPlatform): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingPlatform>, I>>(base?: I): MeetingPlatform {
    return MeetingPlatform.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingPlatform>, I>>(object: I): MeetingPlatform {
    const message = createBaseMeetingPlatform();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingTaskDepartment(): MeetingTaskDepartment {
  return {
    masterJavaBaseModel: undefined,
    extDepartmentId: undefined,
    meetingTask: undefined,
    meetingTaskId: undefined,
  };
}

export const MeetingTaskDepartment = {
  encode(message: MeetingTaskDepartment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(16).uint64(message.extDepartmentId);
    }
    if (message.meetingTask !== undefined) {
      MeetingMeetingTask.encode(message.meetingTask, writer.uint32(26).fork()).ldelim();
    }
    if (message.meetingTaskId !== undefined) {
      writer.uint32(32).uint64(message.meetingTaskId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskDepartment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskDepartment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.meetingTask = MeetingMeetingTask.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.meetingTaskId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskDepartment {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      meetingTask: isSet(object.meetingTask) ? MeetingMeetingTask.fromJSON(object.meetingTask) : undefined,
      meetingTaskId: isSet(object.meetingTaskId) ? String(object.meetingTaskId) : undefined,
    };
  },

  toJSON(message: MeetingTaskDepartment): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.meetingTask !== undefined &&
      (obj.meetingTask = message.meetingTask ? MeetingMeetingTask.toJSON(message.meetingTask) : undefined);
    message.meetingTaskId !== undefined && (obj.meetingTaskId = message.meetingTaskId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskDepartment>, I>>(base?: I): MeetingTaskDepartment {
    return MeetingTaskDepartment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskDepartment>, I>>(object: I): MeetingTaskDepartment {
    const message = createBaseMeetingTaskDepartment();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.meetingTask = (object.meetingTask !== undefined && object.meetingTask !== null)
      ? MeetingMeetingTask.fromPartial(object.meetingTask)
      : undefined;
    message.meetingTaskId = object.meetingTaskId ?? undefined;
    return message;
  },
};

function createBaseMeetingTaskRemark(): MeetingTaskRemark {
  return { masterJavaBaseModel: undefined, meetingTask: undefined, meetingTaskId: undefined };
}

export const MeetingTaskRemark = {
  encode(message: MeetingTaskRemark, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.meetingTask !== undefined) {
      MeetingMeetingTask.encode(message.meetingTask, writer.uint32(18).fork()).ldelim();
    }
    if (message.meetingTaskId !== undefined) {
      writer.uint32(24).uint64(message.meetingTaskId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskRemark {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskRemark();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.meetingTask = MeetingMeetingTask.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.meetingTaskId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskRemark {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      meetingTask: isSet(object.meetingTask) ? MeetingMeetingTask.fromJSON(object.meetingTask) : undefined,
      meetingTaskId: isSet(object.meetingTaskId) ? String(object.meetingTaskId) : undefined,
    };
  },

  toJSON(message: MeetingTaskRemark): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.meetingTask !== undefined &&
      (obj.meetingTask = message.meetingTask ? MeetingMeetingTask.toJSON(message.meetingTask) : undefined);
    message.meetingTaskId !== undefined && (obj.meetingTaskId = message.meetingTaskId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskRemark>, I>>(base?: I): MeetingTaskRemark {
    return MeetingTaskRemark.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskRemark>, I>>(object: I): MeetingTaskRemark {
    const message = createBaseMeetingTaskRemark();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.meetingTask = (object.meetingTask !== undefined && object.meetingTask !== null)
      ? MeetingMeetingTask.fromPartial(object.meetingTask)
      : undefined;
    message.meetingTaskId = object.meetingTaskId ?? undefined;
    return message;
  },
};

function createBaseMeeetingTaskAttachment(): MeeetingTaskAttachment {
  return { masterJavaBaseModel: undefined, meetingTask: undefined, meetingTaskId: undefined };
}

export const MeeetingTaskAttachment = {
  encode(message: MeeetingTaskAttachment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.meetingTask !== undefined) {
      MeetingMeetingTask.encode(message.meetingTask, writer.uint32(18).fork()).ldelim();
    }
    if (message.meetingTaskId !== undefined) {
      writer.uint32(24).uint64(message.meetingTaskId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeeetingTaskAttachment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeeetingTaskAttachment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.meetingTask = MeetingMeetingTask.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.meetingTaskId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeeetingTaskAttachment {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      meetingTask: isSet(object.meetingTask) ? MeetingMeetingTask.fromJSON(object.meetingTask) : undefined,
      meetingTaskId: isSet(object.meetingTaskId) ? String(object.meetingTaskId) : undefined,
    };
  },

  toJSON(message: MeeetingTaskAttachment): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.meetingTask !== undefined &&
      (obj.meetingTask = message.meetingTask ? MeetingMeetingTask.toJSON(message.meetingTask) : undefined);
    message.meetingTaskId !== undefined && (obj.meetingTaskId = message.meetingTaskId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeeetingTaskAttachment>, I>>(base?: I): MeeetingTaskAttachment {
    return MeeetingTaskAttachment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeeetingTaskAttachment>, I>>(object: I): MeeetingTaskAttachment {
    const message = createBaseMeeetingTaskAttachment();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.meetingTask = (object.meetingTask !== undefined && object.meetingTask !== null)
      ? MeetingMeetingTask.fromPartial(object.meetingTask)
      : undefined;
    message.meetingTaskId = object.meetingTaskId ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskPostBody(): MeetingMeetingTaskPostBody {
  return {
    masterJavaBaseModel: undefined,
    description: undefined,
    date: undefined,
    inCharges: [],
    extCreatedById: undefined,
    subApp: undefined,
    remark: undefined,
    problemRemark: undefined,
    meetingTaskInChargeDeleteIds: [],
    ppicWorkOrderRevId: undefined,
  };
}

export const MeetingMeetingTaskPostBody = {
  encode(message: MeetingMeetingTaskPostBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.date !== undefined) {
      writer.uint32(26).string(message.date);
    }
    for (const v of message.inCharges) {
      MeetingMeetingTaskPostBodyInCharge.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.extCreatedById !== undefined) {
      writer.uint32(40).uint64(message.extCreatedById);
    }
    if (message.subApp !== undefined) {
      writer.uint32(50).string(message.subApp);
    }
    if (message.remark !== undefined) {
      writer.uint32(58).string(message.remark);
    }
    if (message.problemRemark !== undefined) {
      writer.uint32(66).string(message.problemRemark);
    }
    writer.uint32(74).fork();
    for (const v of message.meetingTaskInChargeDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.ppicWorkOrderRevId !== undefined) {
      writer.uint32(80).uint64(message.ppicWorkOrderRevId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskPostBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskPostBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.date = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.inCharges.push(MeetingMeetingTaskPostBodyInCharge.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extCreatedById = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.subApp = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.problemRemark = reader.string();
          continue;
        case 9:
          if (tag === 72) {
            message.meetingTaskInChargeDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.meetingTaskInChargeDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.ppicWorkOrderRevId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskPostBody {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      inCharges: Array.isArray(object?.inCharges)
        ? object.inCharges.map((e: any) => MeetingMeetingTaskPostBodyInCharge.fromJSON(e))
        : [],
      extCreatedById: isSet(object.extCreatedById) ? String(object.extCreatedById) : undefined,
      subApp: isSet(object.subApp) ? String(object.subApp) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      problemRemark: isSet(object.problemRemark) ? String(object.problemRemark) : undefined,
      meetingTaskInChargeDeleteIds: Array.isArray(object?.meetingTaskInChargeDeleteIds)
        ? object.meetingTaskInChargeDeleteIds.map((e: any) => String(e))
        : [],
      ppicWorkOrderRevId: isSet(object.ppicWorkOrderRevId) ? String(object.ppicWorkOrderRevId) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskPostBody): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.description !== undefined && (obj.description = message.description);
    message.date !== undefined && (obj.date = message.date);
    if (message.inCharges) {
      obj.inCharges = message.inCharges.map((e) => e ? MeetingMeetingTaskPostBodyInCharge.toJSON(e) : undefined);
    } else {
      obj.inCharges = [];
    }
    message.extCreatedById !== undefined && (obj.extCreatedById = message.extCreatedById);
    message.subApp !== undefined && (obj.subApp = message.subApp);
    message.remark !== undefined && (obj.remark = message.remark);
    message.problemRemark !== undefined && (obj.problemRemark = message.problemRemark);
    if (message.meetingTaskInChargeDeleteIds) {
      obj.meetingTaskInChargeDeleteIds = message.meetingTaskInChargeDeleteIds.map((e) => e);
    } else {
      obj.meetingTaskInChargeDeleteIds = [];
    }
    message.ppicWorkOrderRevId !== undefined && (obj.ppicWorkOrderRevId = message.ppicWorkOrderRevId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskPostBody>, I>>(base?: I): MeetingMeetingTaskPostBody {
    return MeetingMeetingTaskPostBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskPostBody>, I>>(object: I): MeetingMeetingTaskPostBody {
    const message = createBaseMeetingMeetingTaskPostBody();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.description = object.description ?? undefined;
    message.date = object.date ?? undefined;
    message.inCharges = object.inCharges?.map((e) => MeetingMeetingTaskPostBodyInCharge.fromPartial(e)) || [];
    message.extCreatedById = object.extCreatedById ?? undefined;
    message.subApp = object.subApp ?? undefined;
    message.remark = object.remark ?? undefined;
    message.problemRemark = object.problemRemark ?? undefined;
    message.meetingTaskInChargeDeleteIds = object.meetingTaskInChargeDeleteIds?.map((e) => e) || [];
    message.ppicWorkOrderRevId = object.ppicWorkOrderRevId ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskPostBodyInCharge(): MeetingMeetingTaskPostBodyInCharge {
  return { masterJavaBaseModel: undefined, extUserId: undefined, employeeId: undefined };
}

export const MeetingMeetingTaskPostBodyInCharge = {
  encode(message: MeetingMeetingTaskPostBodyInCharge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.employeeId !== undefined) {
      writer.uint32(24).uint64(message.employeeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskPostBodyInCharge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskPostBodyInCharge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.employeeId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskPostBodyInCharge {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      employeeId: isSet(object.employeeId) ? String(object.employeeId) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskPostBodyInCharge): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.employeeId !== undefined && (obj.employeeId = message.employeeId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskPostBodyInCharge>, I>>(
    base?: I,
  ): MeetingMeetingTaskPostBodyInCharge {
    return MeetingMeetingTaskPostBodyInCharge.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskPostBodyInCharge>, I>>(
    object: I,
  ): MeetingMeetingTaskPostBodyInCharge {
    const message = createBaseMeetingMeetingTaskPostBodyInCharge();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.employeeId = object.employeeId ?? undefined;
    return message;
  },
};

function createBaseMeetingSubApp(): MeetingSubApp {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const MeetingSubApp = {
  encode(message: MeetingSubApp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingSubApp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingSubApp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingSubApp {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: MeetingSubApp): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingSubApp>, I>>(base?: I): MeetingSubApp {
    return MeetingSubApp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingSubApp>, I>>(object: I): MeetingSubApp {
    const message = createBaseMeetingSubApp();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetings(): MeetingMeetings {
  return { meetings: [] };
}

export const MeetingMeetings = {
  encode(message: MeetingMeetings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.meetings) {
      MeetingMeeting.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meetings.push(MeetingMeeting.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetings {
    return {
      meetings: Array.isArray(object?.meetings) ? object.meetings.map((e: any) => MeetingMeeting.fromJSON(e)) : [],
    };
  },

  toJSON(message: MeetingMeetings): unknown {
    const obj: any = {};
    if (message.meetings) {
      obj.meetings = message.meetings.map((e) => e ? MeetingMeeting.toJSON(e) : undefined);
    } else {
      obj.meetings = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetings>, I>>(base?: I): MeetingMeetings {
    return MeetingMeetings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetings>, I>>(object: I): MeetingMeetings {
    const message = createBaseMeetingMeetings();
    message.meetings = object.meetings?.map((e) => MeetingMeeting.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeeting(): MeetingMeeting {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    date: undefined,
    start: undefined,
    end: undefined,
    approved: undefined,
    status: undefined,
    online: undefined,
    meetingLink: undefined,
    meetingSummary: undefined,
    participants: [],
    agendas: [],
    tasks: [],
    place: undefined,
    placeId: undefined,
    room: undefined,
    roomId: undefined,
    createdBy: undefined,
    createdById: undefined,
    platform: undefined,
    platformId: undefined,
    taskLists: [],
  };
}

export const MeetingMeeting = {
  encode(message: MeetingMeeting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.date !== undefined) {
      writer.uint32(26).string(message.date);
    }
    if (message.start !== undefined) {
      writer.uint32(32).int32(message.start);
    }
    if (message.end !== undefined) {
      writer.uint32(40).int32(message.end);
    }
    if (message.approved !== undefined) {
      writer.uint32(48).bool(message.approved);
    }
    if (message.status !== undefined) {
      writer.uint32(58).string(message.status);
    }
    if (message.online !== undefined) {
      writer.uint32(64).bool(message.online);
    }
    if (message.meetingLink !== undefined) {
      writer.uint32(74).string(message.meetingLink);
    }
    if (message.meetingSummary !== undefined) {
      writer.uint32(82).string(message.meetingSummary);
    }
    for (const v of message.participants) {
      MeetingMeetingParticipant.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.agendas) {
      MeetingMeetingAgenda.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.tasks) {
      MeetingMeetingTask.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.place !== undefined) {
      MeetingPlace.encode(message.place, writer.uint32(114).fork()).ldelim();
    }
    if (message.placeId !== undefined) {
      writer.uint32(120).uint64(message.placeId);
    }
    if (message.room !== undefined) {
      MeetingRoom.encode(message.room, writer.uint32(130).fork()).ldelim();
    }
    if (message.roomId !== undefined) {
      writer.uint32(136).uint64(message.roomId);
    }
    if (message.createdBy !== undefined) {
      MeetingUser.encode(message.createdBy, writer.uint32(146).fork()).ldelim();
    }
    if (message.createdById !== undefined) {
      writer.uint32(152).uint64(message.createdById);
    }
    if (message.platform !== undefined) {
      MeetingPlatform.encode(message.platform, writer.uint32(162).fork()).ldelim();
    }
    if (message.platformId !== undefined) {
      writer.uint32(168).uint64(message.platformId);
    }
    for (const v of message.taskLists) {
      MeetingTaskList.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeeting {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeeting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.date = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.start = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.end = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.approved = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.status = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.online = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.meetingLink = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.meetingSummary = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.participants.push(MeetingMeetingParticipant.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.agendas.push(MeetingMeetingAgenda.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.tasks.push(MeetingMeetingTask.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.place = MeetingPlace.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.placeId = longToString(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.room = MeetingRoom.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.roomId = longToString(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.createdBy = MeetingUser.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.createdById = longToString(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.platform = MeetingPlatform.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.platformId = longToString(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.taskLists.push(MeetingTaskList.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeeting {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      start: isSet(object.start) ? Number(object.start) : undefined,
      end: isSet(object.end) ? Number(object.end) : undefined,
      approved: isSet(object.approved) ? Boolean(object.approved) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      online: isSet(object.online) ? Boolean(object.online) : undefined,
      meetingLink: isSet(object.meetingLink) ? String(object.meetingLink) : undefined,
      meetingSummary: isSet(object.meetingSummary) ? String(object.meetingSummary) : undefined,
      participants: Array.isArray(object?.participants)
        ? object.participants.map((e: any) => MeetingMeetingParticipant.fromJSON(e))
        : [],
      agendas: Array.isArray(object?.agendas) ? object.agendas.map((e: any) => MeetingMeetingAgenda.fromJSON(e)) : [],
      tasks: Array.isArray(object?.tasks) ? object.tasks.map((e: any) => MeetingMeetingTask.fromJSON(e)) : [],
      place: isSet(object.place) ? MeetingPlace.fromJSON(object.place) : undefined,
      placeId: isSet(object.placeId) ? String(object.placeId) : undefined,
      room: isSet(object.room) ? MeetingRoom.fromJSON(object.room) : undefined,
      roomId: isSet(object.roomId) ? String(object.roomId) : undefined,
      createdBy: isSet(object.createdBy) ? MeetingUser.fromJSON(object.createdBy) : undefined,
      createdById: isSet(object.createdById) ? String(object.createdById) : undefined,
      platform: isSet(object.platform) ? MeetingPlatform.fromJSON(object.platform) : undefined,
      platformId: isSet(object.platformId) ? String(object.platformId) : undefined,
      taskLists: Array.isArray(object?.taskLists) ? object.taskLists.map((e: any) => MeetingTaskList.fromJSON(e)) : [],
    };
  },

  toJSON(message: MeetingMeeting): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.date !== undefined && (obj.date = message.date);
    message.start !== undefined && (obj.start = Math.round(message.start));
    message.end !== undefined && (obj.end = Math.round(message.end));
    message.approved !== undefined && (obj.approved = message.approved);
    message.status !== undefined && (obj.status = message.status);
    message.online !== undefined && (obj.online = message.online);
    message.meetingLink !== undefined && (obj.meetingLink = message.meetingLink);
    message.meetingSummary !== undefined && (obj.meetingSummary = message.meetingSummary);
    if (message.participants) {
      obj.participants = message.participants.map((e) => e ? MeetingMeetingParticipant.toJSON(e) : undefined);
    } else {
      obj.participants = [];
    }
    if (message.agendas) {
      obj.agendas = message.agendas.map((e) => e ? MeetingMeetingAgenda.toJSON(e) : undefined);
    } else {
      obj.agendas = [];
    }
    if (message.tasks) {
      obj.tasks = message.tasks.map((e) => e ? MeetingMeetingTask.toJSON(e) : undefined);
    } else {
      obj.tasks = [];
    }
    message.place !== undefined && (obj.place = message.place ? MeetingPlace.toJSON(message.place) : undefined);
    message.placeId !== undefined && (obj.placeId = message.placeId);
    message.room !== undefined && (obj.room = message.room ? MeetingRoom.toJSON(message.room) : undefined);
    message.roomId !== undefined && (obj.roomId = message.roomId);
    message.createdBy !== undefined &&
      (obj.createdBy = message.createdBy ? MeetingUser.toJSON(message.createdBy) : undefined);
    message.createdById !== undefined && (obj.createdById = message.createdById);
    message.platform !== undefined &&
      (obj.platform = message.platform ? MeetingPlatform.toJSON(message.platform) : undefined);
    message.platformId !== undefined && (obj.platformId = message.platformId);
    if (message.taskLists) {
      obj.taskLists = message.taskLists.map((e) => e ? MeetingTaskList.toJSON(e) : undefined);
    } else {
      obj.taskLists = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeeting>, I>>(base?: I): MeetingMeeting {
    return MeetingMeeting.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeeting>, I>>(object: I): MeetingMeeting {
    const message = createBaseMeetingMeeting();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.date = object.date ?? undefined;
    message.start = object.start ?? undefined;
    message.end = object.end ?? undefined;
    message.approved = object.approved ?? undefined;
    message.status = object.status ?? undefined;
    message.online = object.online ?? undefined;
    message.meetingLink = object.meetingLink ?? undefined;
    message.meetingSummary = object.meetingSummary ?? undefined;
    message.participants = object.participants?.map((e) => MeetingMeetingParticipant.fromPartial(e)) || [];
    message.agendas = object.agendas?.map((e) => MeetingMeetingAgenda.fromPartial(e)) || [];
    message.tasks = object.tasks?.map((e) => MeetingMeetingTask.fromPartial(e)) || [];
    message.place = (object.place !== undefined && object.place !== null)
      ? MeetingPlace.fromPartial(object.place)
      : undefined;
    message.placeId = object.placeId ?? undefined;
    message.room = (object.room !== undefined && object.room !== null)
      ? MeetingRoom.fromPartial(object.room)
      : undefined;
    message.roomId = object.roomId ?? undefined;
    message.createdBy = (object.createdBy !== undefined && object.createdBy !== null)
      ? MeetingUser.fromPartial(object.createdBy)
      : undefined;
    message.createdById = object.createdById ?? undefined;
    message.platform = (object.platform !== undefined && object.platform !== null)
      ? MeetingPlatform.fromPartial(object.platform)
      : undefined;
    message.platformId = object.platformId ?? undefined;
    message.taskLists = object.taskLists?.map((e) => MeetingTaskList.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingParticipant(): MeetingMeetingParticipant {
  return { masterJavaBaseModel: undefined, extUserId: undefined };
}

export const MeetingMeetingParticipant = {
  encode(message: MeetingMeetingParticipant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingParticipant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingParticipant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingParticipant {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: MeetingMeetingParticipant): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingParticipant>, I>>(base?: I): MeetingMeetingParticipant {
    return MeetingMeetingParticipant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingParticipant>, I>>(object: I): MeetingMeetingParticipant {
    const message = createBaseMeetingMeetingParticipant();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingAgenda(): MeetingMeetingAgenda {
  return { masterJavaBaseModel: undefined };
}

export const MeetingMeetingAgenda = {
  encode(message: MeetingMeetingAgenda, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingAgenda {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingAgenda();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingAgenda {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: MeetingMeetingAgenda): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingAgenda>, I>>(base?: I): MeetingMeetingAgenda {
    return MeetingMeetingAgenda.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingAgenda>, I>>(object: I): MeetingMeetingAgenda {
    const message = createBaseMeetingMeetingAgenda();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskList(): MeetingMeetingTaskList {
  return { masterJavaBaseModel: undefined, meetingId: undefined, taskList: undefined, taskListId: undefined };
}

export const MeetingMeetingTaskList = {
  encode(message: MeetingMeetingTaskList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.meetingId !== undefined) {
      writer.uint32(16).uint64(message.meetingId);
    }
    if (message.taskList !== undefined) {
      MeetingTaskList.encode(message.taskList, writer.uint32(26).fork()).ldelim();
    }
    if (message.taskListId !== undefined) {
      writer.uint32(32).uint64(message.taskListId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.meetingId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.taskList = MeetingTaskList.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskList {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      meetingId: isSet(object.meetingId) ? String(object.meetingId) : undefined,
      taskList: isSet(object.taskList) ? MeetingTaskList.fromJSON(object.taskList) : undefined,
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskList): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.meetingId !== undefined && (obj.meetingId = message.meetingId);
    message.taskList !== undefined &&
      (obj.taskList = message.taskList ? MeetingTaskList.toJSON(message.taskList) : undefined);
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskList>, I>>(base?: I): MeetingMeetingTaskList {
    return MeetingMeetingTaskList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskList>, I>>(object: I): MeetingMeetingTaskList {
    const message = createBaseMeetingMeetingTaskList();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.meetingId = object.meetingId ?? undefined;
    message.taskList = (object.taskList !== undefined && object.taskList !== null)
      ? MeetingTaskList.fromPartial(object.taskList)
      : undefined;
    message.taskListId = object.taskListId ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskInCharge(): MeetingMeetingTaskInCharge {
  return {
    masterJavaBaseModel: undefined,
    extUserId: undefined,
    rateStars: undefined,
    creator: undefined,
    meetingTask: undefined,
    meetingTaskId: undefined,
    isQc: undefined,
    isQa: undefined,
    totalTimeHours: undefined,
    totalTimeHoursTask: undefined,
  };
}

export const MeetingMeetingTaskInCharge = {
  encode(message: MeetingMeetingTaskInCharge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.rateStars !== undefined) {
      writer.uint32(24).int32(message.rateStars);
    }
    if (message.creator !== undefined) {
      writer.uint32(32).bool(message.creator);
    }
    if (message.meetingTask !== undefined) {
      MeetingMeetingTask.encode(message.meetingTask, writer.uint32(42).fork()).ldelim();
    }
    if (message.meetingTaskId !== undefined) {
      writer.uint32(48).uint64(message.meetingTaskId);
    }
    if (message.isQc !== undefined) {
      writer.uint32(56).bool(message.isQc);
    }
    if (message.isQa !== undefined) {
      writer.uint32(64).bool(message.isQa);
    }
    if (message.totalTimeHours !== undefined) {
      writer.uint32(73).double(message.totalTimeHours);
    }
    if (message.totalTimeHoursTask !== undefined) {
      writer.uint32(81).double(message.totalTimeHoursTask);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskInCharge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskInCharge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rateStars = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.creator = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.meetingTask = MeetingMeetingTask.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.meetingTaskId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isQc = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.isQa = reader.bool();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.totalTimeHours = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.totalTimeHoursTask = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskInCharge {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      rateStars: isSet(object.rateStars) ? Number(object.rateStars) : undefined,
      creator: isSet(object.creator) ? Boolean(object.creator) : undefined,
      meetingTask: isSet(object.meetingTask) ? MeetingMeetingTask.fromJSON(object.meetingTask) : undefined,
      meetingTaskId: isSet(object.meetingTaskId) ? String(object.meetingTaskId) : undefined,
      isQc: isSet(object.isQc) ? Boolean(object.isQc) : undefined,
      isQa: isSet(object.isQa) ? Boolean(object.isQa) : undefined,
      totalTimeHours: isSet(object.totalTimeHours) ? Number(object.totalTimeHours) : undefined,
      totalTimeHoursTask: isSet(object.totalTimeHoursTask) ? Number(object.totalTimeHoursTask) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskInCharge): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.rateStars !== undefined && (obj.rateStars = Math.round(message.rateStars));
    message.creator !== undefined && (obj.creator = message.creator);
    message.meetingTask !== undefined &&
      (obj.meetingTask = message.meetingTask ? MeetingMeetingTask.toJSON(message.meetingTask) : undefined);
    message.meetingTaskId !== undefined && (obj.meetingTaskId = message.meetingTaskId);
    message.isQc !== undefined && (obj.isQc = message.isQc);
    message.isQa !== undefined && (obj.isQa = message.isQa);
    message.totalTimeHours !== undefined && (obj.totalTimeHours = message.totalTimeHours);
    message.totalTimeHoursTask !== undefined && (obj.totalTimeHoursTask = message.totalTimeHoursTask);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskInCharge>, I>>(base?: I): MeetingMeetingTaskInCharge {
    return MeetingMeetingTaskInCharge.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskInCharge>, I>>(object: I): MeetingMeetingTaskInCharge {
    const message = createBaseMeetingMeetingTaskInCharge();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.rateStars = object.rateStars ?? undefined;
    message.creator = object.creator ?? undefined;
    message.meetingTask = (object.meetingTask !== undefined && object.meetingTask !== null)
      ? MeetingMeetingTask.fromPartial(object.meetingTask)
      : undefined;
    message.meetingTaskId = object.meetingTaskId ?? undefined;
    message.isQc = object.isQc ?? undefined;
    message.isQa = object.isQa ?? undefined;
    message.totalTimeHours = object.totalTimeHours ?? undefined;
    message.totalTimeHoursTask = object.totalTimeHoursTask ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskComment(): MeetingMeetingTaskComment {
  return {
    masterJavaBaseModel: undefined,
    extUserId: undefined,
    comment: undefined,
    extPanelCodeId: undefined,
    extSubProcessId: undefined,
    readBySpvTimestamp: undefined,
  };
}

export const MeetingMeetingTaskComment = {
  encode(message: MeetingMeetingTaskComment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.comment !== undefined) {
      writer.uint32(26).string(message.comment);
    }
    if (message.extPanelCodeId !== undefined) {
      writer.uint32(32).uint64(message.extPanelCodeId);
    }
    if (message.extSubProcessId !== undefined) {
      writer.uint32(40).uint64(message.extSubProcessId);
    }
    if (message.readBySpvTimestamp !== undefined) {
      writer.uint32(50).string(message.readBySpvTimestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskComment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskComment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extPanelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extSubProcessId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.readBySpvTimestamp = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskComment {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      comment: isSet(object.comment) ? String(object.comment) : undefined,
      extPanelCodeId: isSet(object.extPanelCodeId) ? String(object.extPanelCodeId) : undefined,
      extSubProcessId: isSet(object.extSubProcessId) ? String(object.extSubProcessId) : undefined,
      readBySpvTimestamp: isSet(object.readBySpvTimestamp) ? String(object.readBySpvTimestamp) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskComment): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.comment !== undefined && (obj.comment = message.comment);
    message.extPanelCodeId !== undefined && (obj.extPanelCodeId = message.extPanelCodeId);
    message.extSubProcessId !== undefined && (obj.extSubProcessId = message.extSubProcessId);
    message.readBySpvTimestamp !== undefined && (obj.readBySpvTimestamp = message.readBySpvTimestamp);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskComment>, I>>(base?: I): MeetingMeetingTaskComment {
    return MeetingMeetingTaskComment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskComment>, I>>(object: I): MeetingMeetingTaskComment {
    const message = createBaseMeetingMeetingTaskComment();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.comment = object.comment ?? undefined;
    message.extPanelCodeId = object.extPanelCodeId ?? undefined;
    message.extSubProcessId = object.extSubProcessId ?? undefined;
    message.readBySpvTimestamp = object.readBySpvTimestamp ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTaskComments(): MeetingMeetingTaskComments {
  return { comments: [] };
}

export const MeetingMeetingTaskComments = {
  encode(message: MeetingMeetingTaskComments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.comments) {
      MeetingMeetingTaskComment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskComments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskComments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comments.push(MeetingMeetingTaskComment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskComments {
    return {
      comments: Array.isArray(object?.comments)
        ? object.comments.map((e: any) => MeetingMeetingTaskComment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMeetingTaskComments): unknown {
    const obj: any = {};
    if (message.comments) {
      obj.comments = message.comments.map((e) => e ? MeetingMeetingTaskComment.toJSON(e) : undefined);
    } else {
      obj.comments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskComments>, I>>(base?: I): MeetingMeetingTaskComments {
    return MeetingMeetingTaskComments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskComments>, I>>(object: I): MeetingMeetingTaskComments {
    const message = createBaseMeetingMeetingTaskComments();
    message.comments = object.comments?.map((e) => MeetingMeetingTaskComment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingMeetingTaskTargetDate(): MeetingMeetingTaskTargetDate {
  return { masterJavaBaseModel: undefined, date: undefined, meetingTask: undefined, meetingTaskId: undefined };
}

export const MeetingMeetingTaskTargetDate = {
  encode(message: MeetingMeetingTaskTargetDate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(18).string(message.date);
    }
    if (message.meetingTask !== undefined) {
      MeetingMeetingTask.encode(message.meetingTask, writer.uint32(26).fork()).ldelim();
    }
    if (message.meetingTaskId !== undefined) {
      writer.uint32(32).uint64(message.meetingTaskId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTaskTargetDate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTaskTargetDate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.date = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.meetingTask = MeetingMeetingTask.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.meetingTaskId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTaskTargetDate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      meetingTask: isSet(object.meetingTask) ? MeetingMeetingTask.fromJSON(object.meetingTask) : undefined,
      meetingTaskId: isSet(object.meetingTaskId) ? String(object.meetingTaskId) : undefined,
    };
  },

  toJSON(message: MeetingMeetingTaskTargetDate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.date !== undefined && (obj.date = message.date);
    message.meetingTask !== undefined &&
      (obj.meetingTask = message.meetingTask ? MeetingMeetingTask.toJSON(message.meetingTask) : undefined);
    message.meetingTaskId !== undefined && (obj.meetingTaskId = message.meetingTaskId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTaskTargetDate>, I>>(base?: I): MeetingMeetingTaskTargetDate {
    return MeetingMeetingTaskTargetDate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTaskTargetDate>, I>>(object: I): MeetingMeetingTaskTargetDate {
    const message = createBaseMeetingMeetingTaskTargetDate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.date = object.date ?? undefined;
    message.meetingTask = (object.meetingTask !== undefined && object.meetingTask !== null)
      ? MeetingMeetingTask.fromPartial(object.meetingTask)
      : undefined;
    message.meetingTaskId = object.meetingTaskId ?? undefined;
    return message;
  },
};

function createBaseMeetingMeetingTasks(): MeetingMeetingTasks {
  return { meetingTasks: [] };
}

export const MeetingMeetingTasks = {
  encode(message: MeetingMeetingTasks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.meetingTasks) {
      MeetingMeetingTask.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingMeetingTasks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingMeetingTasks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meetingTasks.push(MeetingMeetingTask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingMeetingTasks {
    return {
      meetingTasks: Array.isArray(object?.meetingTasks)
        ? object.meetingTasks.map((e: any) => MeetingMeetingTask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingMeetingTasks): unknown {
    const obj: any = {};
    if (message.meetingTasks) {
      obj.meetingTasks = message.meetingTasks.map((e) => e ? MeetingMeetingTask.toJSON(e) : undefined);
    } else {
      obj.meetingTasks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingMeetingTasks>, I>>(base?: I): MeetingMeetingTasks {
    return MeetingMeetingTasks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingMeetingTasks>, I>>(object: I): MeetingMeetingTasks {
    const message = createBaseMeetingMeetingTasks();
    message.meetingTasks = object.meetingTasks?.map((e) => MeetingMeetingTask.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicJobInfo(): PpicJobInfo {
  return {
    job: undefined,
    timeMins: undefined,
    productTreeTime: undefined,
    programTreeTime: undefined,
    timeWoAssigned: undefined,
    timeWoDone: undefined,
    deptTemplateTime: undefined,
  };
}

export const PpicJobInfo = {
  encode(message: PpicJobInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.job !== undefined) {
      PpicJob.encode(message.job, writer.uint32(10).fork()).ldelim();
    }
    if (message.timeMins !== undefined) {
      writer.uint32(17).double(message.timeMins);
    }
    if (message.productTreeTime !== undefined) {
      writer.uint32(25).double(message.productTreeTime);
    }
    if (message.programTreeTime !== undefined) {
      writer.uint32(33).double(message.programTreeTime);
    }
    if (message.timeWoAssigned !== undefined) {
      writer.uint32(41).double(message.timeWoAssigned);
    }
    if (message.timeWoDone !== undefined) {
      writer.uint32(49).double(message.timeWoDone);
    }
    if (message.deptTemplateTime !== undefined) {
      writer.uint32(57).double(message.deptTemplateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = PpicJob.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.timeMins = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.productTreeTime = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.programTreeTime = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.timeWoAssigned = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.timeWoDone = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.deptTemplateTime = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobInfo {
    return {
      job: isSet(object.job) ? PpicJob.fromJSON(object.job) : undefined,
      timeMins: isSet(object.timeMins) ? Number(object.timeMins) : undefined,
      productTreeTime: isSet(object.productTreeTime) ? Number(object.productTreeTime) : undefined,
      programTreeTime: isSet(object.programTreeTime) ? Number(object.programTreeTime) : undefined,
      timeWoAssigned: isSet(object.timeWoAssigned) ? Number(object.timeWoAssigned) : undefined,
      timeWoDone: isSet(object.timeWoDone) ? Number(object.timeWoDone) : undefined,
      deptTemplateTime: isSet(object.deptTemplateTime) ? Number(object.deptTemplateTime) : undefined,
    };
  },

  toJSON(message: PpicJobInfo): unknown {
    const obj: any = {};
    message.job !== undefined && (obj.job = message.job ? PpicJob.toJSON(message.job) : undefined);
    message.timeMins !== undefined && (obj.timeMins = message.timeMins);
    message.productTreeTime !== undefined && (obj.productTreeTime = message.productTreeTime);
    message.programTreeTime !== undefined && (obj.programTreeTime = message.programTreeTime);
    message.timeWoAssigned !== undefined && (obj.timeWoAssigned = message.timeWoAssigned);
    message.timeWoDone !== undefined && (obj.timeWoDone = message.timeWoDone);
    message.deptTemplateTime !== undefined && (obj.deptTemplateTime = message.deptTemplateTime);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobInfo>, I>>(base?: I): PpicJobInfo {
    return PpicJobInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobInfo>, I>>(object: I): PpicJobInfo {
    const message = createBasePpicJobInfo();
    message.job = (object.job !== undefined && object.job !== null) ? PpicJob.fromPartial(object.job) : undefined;
    message.timeMins = object.timeMins ?? undefined;
    message.productTreeTime = object.productTreeTime ?? undefined;
    message.programTreeTime = object.programTreeTime ?? undefined;
    message.timeWoAssigned = object.timeWoAssigned ?? undefined;
    message.timeWoDone = object.timeWoDone ?? undefined;
    message.deptTemplateTime = object.deptTemplateTime ?? undefined;
    return message;
  },
};

function createBasePpicJobInfoList(): PpicJobInfoList {
  return { info: [] };
}

export const PpicJobInfoList = {
  encode(message: PpicJobInfoList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.info) {
      PpicJobInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobInfoList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobInfoList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.info.push(PpicJobInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobInfoList {
    return { info: Array.isArray(object?.info) ? object.info.map((e: any) => PpicJobInfo.fromJSON(e)) : [] };
  },

  toJSON(message: PpicJobInfoList): unknown {
    const obj: any = {};
    if (message.info) {
      obj.info = message.info.map((e) => e ? PpicJobInfo.toJSON(e) : undefined);
    } else {
      obj.info = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobInfoList>, I>>(base?: I): PpicJobInfoList {
    return PpicJobInfoList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobInfoList>, I>>(object: I): PpicJobInfoList {
    const message = createBasePpicJobInfoList();
    message.info = object.info?.map((e) => PpicJobInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMacroCategory(): PpicMacroCategory {
  return { masterJavaBaseModel: undefined, name: undefined, items: [] };
}

export const PpicMacroCategory = {
  encode(message: PpicMacroCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.items) {
      PpicMacroCategoryItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMacroCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMacroCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.items.push(PpicMacroCategoryItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMacroCategory {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => PpicMacroCategoryItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicMacroCategory): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicMacroCategoryItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMacroCategory>, I>>(base?: I): PpicMacroCategory {
    return PpicMacroCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMacroCategory>, I>>(object: I): PpicMacroCategory {
    const message = createBasePpicMacroCategory();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.items = object.items?.map((e) => PpicMacroCategoryItem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicOKRObjective(): PpicOKRObjective {
  return { masterJavaBaseModel: undefined, name: undefined, details: [] };
}

export const PpicOKRObjective = {
  encode(message: PpicOKRObjective, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.details) {
      PpicOKRObjectiveDetail.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicOKRObjective {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicOKRObjective();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.details.push(PpicOKRObjectiveDetail.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicOKRObjective {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      details: Array.isArray(object?.details) ? object.details.map((e: any) => PpicOKRObjectiveDetail.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicOKRObjective): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.details) {
      obj.details = message.details.map((e) => e ? PpicOKRObjectiveDetail.toJSON(e) : undefined);
    } else {
      obj.details = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicOKRObjective>, I>>(base?: I): PpicOKRObjective {
    return PpicOKRObjective.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicOKRObjective>, I>>(object: I): PpicOKRObjective {
    const message = createBasePpicOKRObjective();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.details = object.details?.map((e) => PpicOKRObjectiveDetail.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicOKRObjectiveDetail(): PpicOKRObjectiveDetail {
  return { masterJavaBaseModel: undefined, name: undefined, weight: undefined };
}

export const PpicOKRObjectiveDetail = {
  encode(message: PpicOKRObjectiveDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.weight !== undefined) {
      writer.uint32(24).int32(message.weight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicOKRObjectiveDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicOKRObjectiveDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.weight = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicOKRObjectiveDetail {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      weight: isSet(object.weight) ? Number(object.weight) : undefined,
    };
  },

  toJSON(message: PpicOKRObjectiveDetail): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.weight !== undefined && (obj.weight = Math.round(message.weight));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicOKRObjectiveDetail>, I>>(base?: I): PpicOKRObjectiveDetail {
    return PpicOKRObjectiveDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicOKRObjectiveDetail>, I>>(object: I): PpicOKRObjectiveDetail {
    const message = createBasePpicOKRObjectiveDetail();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.weight = object.weight ?? undefined;
    return message;
  },
};

function createBasePpicOKR(): PpicOKR {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    type: undefined,
    details: [],
    departmentId: undefined,
    teamId: undefined,
    extUserId: undefined,
    timeRange: undefined,
  };
}

export const PpicOKR = {
  encode(message: PpicOKR, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    for (const v of message.details) {
      PpicOKRDetail.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.departmentId !== undefined) {
      writer.uint32(40).uint64(message.departmentId);
    }
    if (message.teamId !== undefined) {
      writer.uint32(48).uint64(message.teamId);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(56).uint64(message.extUserId);
    }
    if (message.timeRange !== undefined) {
      writer.uint32(64).int32(message.timeRange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicOKR {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicOKR();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.details.push(PpicOKRDetail.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.departmentId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.teamId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.timeRange = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicOKR {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      type: isSet(object.type) ? oKRTypeFromJSON(object.type) : undefined,
      details: Array.isArray(object?.details) ? object.details.map((e: any) => PpicOKRDetail.fromJSON(e)) : [],
      departmentId: isSet(object.departmentId) ? String(object.departmentId) : undefined,
      teamId: isSet(object.teamId) ? String(object.teamId) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      timeRange: isSet(object.timeRange) ? oKRTimeRangeFromJSON(object.timeRange) : undefined,
    };
  },

  toJSON(message: PpicOKR): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type !== undefined ? oKRTypeToJSON(message.type) : undefined);
    if (message.details) {
      obj.details = message.details.map((e) => e ? PpicOKRDetail.toJSON(e) : undefined);
    } else {
      obj.details = [];
    }
    message.departmentId !== undefined && (obj.departmentId = message.departmentId);
    message.teamId !== undefined && (obj.teamId = message.teamId);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.timeRange !== undefined &&
      (obj.timeRange = message.timeRange !== undefined ? oKRTimeRangeToJSON(message.timeRange) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicOKR>, I>>(base?: I): PpicOKR {
    return PpicOKR.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicOKR>, I>>(object: I): PpicOKR {
    const message = createBasePpicOKR();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.details = object.details?.map((e) => PpicOKRDetail.fromPartial(e)) || [];
    message.departmentId = object.departmentId ?? undefined;
    message.teamId = object.teamId ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.timeRange = object.timeRange ?? undefined;
    return message;
  },
};

function createBasePpicOKRDetail(): PpicOKRDetail {
  return { masterJavaBaseModel: undefined, name: undefined, weight: undefined, progressPercentage: undefined };
}

export const PpicOKRDetail = {
  encode(message: PpicOKRDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.weight !== undefined) {
      writer.uint32(24).int32(message.weight);
    }
    if (message.progressPercentage !== undefined) {
      writer.uint32(32).int32(message.progressPercentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicOKRDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicOKRDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.weight = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.progressPercentage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicOKRDetail {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      weight: isSet(object.weight) ? Number(object.weight) : undefined,
      progressPercentage: isSet(object.progressPercentage) ? Number(object.progressPercentage) : undefined,
    };
  },

  toJSON(message: PpicOKRDetail): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.weight !== undefined && (obj.weight = Math.round(message.weight));
    message.progressPercentage !== undefined && (obj.progressPercentage = Math.round(message.progressPercentage));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicOKRDetail>, I>>(base?: I): PpicOKRDetail {
    return PpicOKRDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicOKRDetail>, I>>(object: I): PpicOKRDetail {
    const message = createBasePpicOKRDetail();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.weight = object.weight ?? undefined;
    message.progressPercentage = object.progressPercentage ?? undefined;
    return message;
  },
};

function createBasePpicOKRObjectives(): PpicOKRObjectives {
  return { objectives: [] };
}

export const PpicOKRObjectives = {
  encode(message: PpicOKRObjectives, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectives) {
      PpicOKRObjective.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicOKRObjectives {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicOKRObjectives();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectives.push(PpicOKRObjective.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicOKRObjectives {
    return {
      objectives: Array.isArray(object?.objectives)
        ? object.objectives.map((e: any) => PpicOKRObjective.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicOKRObjectives): unknown {
    const obj: any = {};
    if (message.objectives) {
      obj.objectives = message.objectives.map((e) => e ? PpicOKRObjective.toJSON(e) : undefined);
    } else {
      obj.objectives = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicOKRObjectives>, I>>(base?: I): PpicOKRObjectives {
    return PpicOKRObjectives.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicOKRObjectives>, I>>(object: I): PpicOKRObjectives {
    const message = createBasePpicOKRObjectives();
    message.objectives = object.objectives?.map((e) => PpicOKRObjective.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicOKRs(): PpicOKRs {
  return { okrs: [] };
}

export const PpicOKRs = {
  encode(message: PpicOKRs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.okrs) {
      PpicOKR.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicOKRs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicOKRs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.okrs.push(PpicOKR.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicOKRs {
    return { okrs: Array.isArray(object?.okrs) ? object.okrs.map((e: any) => PpicOKR.fromJSON(e)) : [] };
  },

  toJSON(message: PpicOKRs): unknown {
    const obj: any = {};
    if (message.okrs) {
      obj.okrs = message.okrs.map((e) => e ? PpicOKR.toJSON(e) : undefined);
    } else {
      obj.okrs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicOKRs>, I>>(base?: I): PpicOKRs {
    return PpicOKRs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicOKRs>, I>>(object: I): PpicOKRs {
    const message = createBasePpicOKRs();
    message.okrs = object.okrs?.map((e) => PpicOKR.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMacroCategories(): PpicMacroCategories {
  return { categories: [] };
}

export const PpicMacroCategories = {
  encode(message: PpicMacroCategories, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.categories) {
      PpicMacroCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMacroCategories {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMacroCategories();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categories.push(PpicMacroCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMacroCategories {
    return {
      categories: Array.isArray(object?.categories)
        ? object.categories.map((e: any) => PpicMacroCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicMacroCategories): unknown {
    const obj: any = {};
    if (message.categories) {
      obj.categories = message.categories.map((e) => e ? PpicMacroCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMacroCategories>, I>>(base?: I): PpicMacroCategories {
    return PpicMacroCategories.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMacroCategories>, I>>(object: I): PpicMacroCategories {
    const message = createBasePpicMacroCategories();
    message.categories = object.categories?.map((e) => PpicMacroCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicDeliveryNote(): PpicDeliveryNote {
  return {
    masterJavaBaseModel: undefined,
    to: undefined,
    address: undefined,
    effectiveDate: undefined,
    date: undefined,
    purpose: undefined,
    extPurchaseOrderId: undefined,
    deliveryNoteItems: [],
    revision: undefined,
    dnNumber: undefined,
  };
}

export const PpicDeliveryNote = {
  encode(message: PpicDeliveryNote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.to !== undefined) {
      writer.uint32(18).string(message.to);
    }
    if (message.address !== undefined) {
      writer.uint32(26).string(message.address);
    }
    if (message.effectiveDate !== undefined) {
      writer.uint32(34).string(message.effectiveDate);
    }
    if (message.date !== undefined) {
      writer.uint32(42).string(message.date);
    }
    if (message.purpose !== undefined) {
      writer.uint32(50).string(message.purpose);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(56).uint64(message.extPurchaseOrderId);
    }
    for (const v of message.deliveryNoteItems) {
      PpicDeliveryNoteItem.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.revision !== undefined) {
      writer.uint32(72).int32(message.revision);
    }
    if (message.dnNumber !== undefined) {
      writer.uint32(82).string(message.dnNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDeliveryNote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDeliveryNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.effectiveDate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.purpose = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.deliveryNoteItems.push(PpicDeliveryNoteItem.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.revision = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.dnNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDeliveryNote {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      to: isSet(object.to) ? String(object.to) : undefined,
      address: isSet(object.address) ? String(object.address) : undefined,
      effectiveDate: isSet(object.effectiveDate) ? String(object.effectiveDate) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      purpose: isSet(object.purpose) ? String(object.purpose) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
      deliveryNoteItems: Array.isArray(object?.deliveryNoteItems)
        ? object.deliveryNoteItems.map((e: any) => PpicDeliveryNoteItem.fromJSON(e))
        : [],
      revision: isSet(object.revision) ? Number(object.revision) : undefined,
      dnNumber: isSet(object.dnNumber) ? String(object.dnNumber) : undefined,
    };
  },

  toJSON(message: PpicDeliveryNote): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.to !== undefined && (obj.to = message.to);
    message.address !== undefined && (obj.address = message.address);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate);
    message.date !== undefined && (obj.date = message.date);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    if (message.deliveryNoteItems) {
      obj.deliveryNoteItems = message.deliveryNoteItems.map((e) => e ? PpicDeliveryNoteItem.toJSON(e) : undefined);
    } else {
      obj.deliveryNoteItems = [];
    }
    message.revision !== undefined && (obj.revision = Math.round(message.revision));
    message.dnNumber !== undefined && (obj.dnNumber = message.dnNumber);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDeliveryNote>, I>>(base?: I): PpicDeliveryNote {
    return PpicDeliveryNote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDeliveryNote>, I>>(object: I): PpicDeliveryNote {
    const message = createBasePpicDeliveryNote();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.to = object.to ?? undefined;
    message.address = object.address ?? undefined;
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.date = object.date ?? undefined;
    message.purpose = object.purpose ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    message.deliveryNoteItems = object.deliveryNoteItems?.map((e) => PpicDeliveryNoteItem.fromPartial(e)) || [];
    message.revision = object.revision ?? undefined;
    message.dnNumber = object.dnNumber ?? undefined;
    return message;
  },
};

function createBasePpicDeliveryNotes(): PpicDeliveryNotes {
  return { notes: [] };
}

export const PpicDeliveryNotes = {
  encode(message: PpicDeliveryNotes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notes) {
      PpicDeliveryNote.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDeliveryNotes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDeliveryNotes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notes.push(PpicDeliveryNote.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDeliveryNotes {
    return { notes: Array.isArray(object?.notes) ? object.notes.map((e: any) => PpicDeliveryNote.fromJSON(e)) : [] };
  },

  toJSON(message: PpicDeliveryNotes): unknown {
    const obj: any = {};
    if (message.notes) {
      obj.notes = message.notes.map((e) => e ? PpicDeliveryNote.toJSON(e) : undefined);
    } else {
      obj.notes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDeliveryNotes>, I>>(base?: I): PpicDeliveryNotes {
    return PpicDeliveryNotes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDeliveryNotes>, I>>(object: I): PpicDeliveryNotes {
    const message = createBasePpicDeliveryNotes();
    message.notes = object.notes?.map((e) => PpicDeliveryNote.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicDeliveryNoteItem(): PpicDeliveryNoteItem {
  return {
    masterJavaBaseModel: undefined,
    extItemId: undefined,
    description: undefined,
    deliveryNoteId: undefined,
    qty: undefined,
  };
}

export const PpicDeliveryNoteItem = {
  encode(message: PpicDeliveryNoteItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extItemId !== undefined) {
      writer.uint32(16).uint64(message.extItemId);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.deliveryNoteId !== undefined) {
      writer.uint32(32).uint64(message.deliveryNoteId);
    }
    if (message.qty !== undefined) {
      writer.uint32(41).double(message.qty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDeliveryNoteItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDeliveryNoteItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deliveryNoteId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.qty = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDeliveryNoteItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      deliveryNoteId: isSet(object.deliveryNoteId) ? String(object.deliveryNoteId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
    };
  },

  toJSON(message: PpicDeliveryNoteItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.description !== undefined && (obj.description = message.description);
    message.deliveryNoteId !== undefined && (obj.deliveryNoteId = message.deliveryNoteId);
    message.qty !== undefined && (obj.qty = message.qty);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDeliveryNoteItem>, I>>(base?: I): PpicDeliveryNoteItem {
    return PpicDeliveryNoteItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDeliveryNoteItem>, I>>(object: I): PpicDeliveryNoteItem {
    const message = createBasePpicDeliveryNoteItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.description = object.description ?? undefined;
    message.deliveryNoteId = object.deliveryNoteId ?? undefined;
    message.qty = object.qty ?? undefined;
    return message;
  },
};

function createBasePpicMacroCategoryItem(): PpicMacroCategoryItem {
  return { masterJavaBaseModel: undefined, name: undefined, macroCategoryId: undefined };
}

export const PpicMacroCategoryItem = {
  encode(message: PpicMacroCategoryItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.macroCategoryId !== undefined) {
      writer.uint32(24).uint64(message.macroCategoryId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMacroCategoryItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMacroCategoryItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.macroCategoryId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMacroCategoryItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      macroCategoryId: isSet(object.macroCategoryId) ? String(object.macroCategoryId) : undefined,
    };
  },

  toJSON(message: PpicMacroCategoryItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.macroCategoryId !== undefined && (obj.macroCategoryId = message.macroCategoryId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMacroCategoryItem>, I>>(base?: I): PpicMacroCategoryItem {
    return PpicMacroCategoryItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMacroCategoryItem>, I>>(object: I): PpicMacroCategoryItem {
    const message = createBasePpicMacroCategoryItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.macroCategoryId = object.macroCategoryId ?? undefined;
    return message;
  },
};

function createBasePpicMacroCategoryItems(): PpicMacroCategoryItems {
  return { items: [] };
}

export const PpicMacroCategoryItems = {
  encode(message: PpicMacroCategoryItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      PpicMacroCategoryItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMacroCategoryItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMacroCategoryItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(PpicMacroCategoryItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMacroCategoryItems {
    return {
      items: Array.isArray(object?.items) ? object.items.map((e: any) => PpicMacroCategoryItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicMacroCategoryItems): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicMacroCategoryItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMacroCategoryItems>, I>>(base?: I): PpicMacroCategoryItems {
    return PpicMacroCategoryItems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMacroCategoryItems>, I>>(object: I): PpicMacroCategoryItems {
    const message = createBasePpicMacroCategoryItems();
    message.items = object.items?.map((e) => PpicMacroCategoryItem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRemoteControlPayload(): PpicRemoteControlPayload {
  return {
    screen: undefined,
    deptId: undefined,
    workOrderType: undefined,
    jobId: undefined,
    extUserId: undefined,
    skipDate: undefined,
    presetRecursive: [],
    scheduled: undefined,
    picOnly: undefined,
    quadScreen: undefined,
    jdpPresetId: undefined,
    extDeptVersusId: undefined,
    extProblemCatalogId: undefined,
    quadScreenV2: undefined,
    refresh: undefined,
    filterTaskDateOnly: undefined,
    woGraph: undefined,
  };
}

export const PpicRemoteControlPayload = {
  encode(message: PpicRemoteControlPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.screen !== undefined) {
      writer.uint32(10).string(message.screen);
    }
    if (message.deptId !== undefined) {
      writer.uint32(16).uint64(message.deptId);
    }
    if (message.workOrderType !== undefined) {
      writer.uint32(24).int32(message.workOrderType);
    }
    if (message.jobId !== undefined) {
      writer.uint32(32).uint64(message.jobId);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(40).uint64(message.extUserId);
    }
    if (message.skipDate !== undefined) {
      writer.uint32(72).int32(message.skipDate);
    }
    for (const v of message.presetRecursive) {
      PpicRemoteControlPreset.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.scheduled !== undefined) {
      writer.uint32(96).bool(message.scheduled);
    }
    if (message.picOnly !== undefined) {
      writer.uint32(104).bool(message.picOnly);
    }
    if (message.quadScreen !== undefined) {
      writer.uint32(112).bool(message.quadScreen);
    }
    if (message.jdpPresetId !== undefined) {
      writer.uint32(120).uint64(message.jdpPresetId);
    }
    if (message.extDeptVersusId !== undefined) {
      writer.uint32(128).uint64(message.extDeptVersusId);
    }
    if (message.extProblemCatalogId !== undefined) {
      writer.uint32(136).uint64(message.extProblemCatalogId);
    }
    if (message.quadScreenV2 !== undefined) {
      writer.uint32(144).bool(message.quadScreenV2);
    }
    if (message.refresh !== undefined) {
      writer.uint32(152).bool(message.refresh);
    }
    if (message.filterTaskDateOnly !== undefined) {
      writer.uint32(160).bool(message.filterTaskDateOnly);
    }
    if (message.woGraph !== undefined) {
      writer.uint32(168).bool(message.woGraph);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRemoteControlPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRemoteControlPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.screen = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deptId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.workOrderType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.skipDate = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.presetRecursive.push(PpicRemoteControlPreset.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.scheduled = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.picOnly = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.quadScreen = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.jdpPresetId = longToString(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.extDeptVersusId = longToString(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.extProblemCatalogId = longToString(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.quadScreenV2 = reader.bool();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.refresh = reader.bool();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.filterTaskDateOnly = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.woGraph = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRemoteControlPayload {
    return {
      screen: isSet(object.screen) ? String(object.screen) : undefined,
      deptId: isSet(object.deptId) ? String(object.deptId) : undefined,
      workOrderType: isSet(object.workOrderType) ? workOrderTypeFromJSON(object.workOrderType) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      skipDate: isSet(object.skipDate) ? Number(object.skipDate) : undefined,
      presetRecursive: Array.isArray(object?.presetRecursive)
        ? object.presetRecursive.map((e: any) => PpicRemoteControlPreset.fromJSON(e))
        : [],
      scheduled: isSet(object.scheduled) ? Boolean(object.scheduled) : undefined,
      picOnly: isSet(object.picOnly) ? Boolean(object.picOnly) : undefined,
      quadScreen: isSet(object.quadScreen) ? Boolean(object.quadScreen) : undefined,
      jdpPresetId: isSet(object.jdpPresetId) ? String(object.jdpPresetId) : undefined,
      extDeptVersusId: isSet(object.extDeptVersusId) ? String(object.extDeptVersusId) : undefined,
      extProblemCatalogId: isSet(object.extProblemCatalogId) ? String(object.extProblemCatalogId) : undefined,
      quadScreenV2: isSet(object.quadScreenV2) ? Boolean(object.quadScreenV2) : undefined,
      refresh: isSet(object.refresh) ? Boolean(object.refresh) : undefined,
      filterTaskDateOnly: isSet(object.filterTaskDateOnly) ? Boolean(object.filterTaskDateOnly) : undefined,
      woGraph: isSet(object.woGraph) ? Boolean(object.woGraph) : undefined,
    };
  },

  toJSON(message: PpicRemoteControlPayload): unknown {
    const obj: any = {};
    message.screen !== undefined && (obj.screen = message.screen);
    message.deptId !== undefined && (obj.deptId = message.deptId);
    message.workOrderType !== undefined &&
      (obj.workOrderType = message.workOrderType !== undefined
        ? workOrderTypeToJSON(message.workOrderType)
        : undefined);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.skipDate !== undefined && (obj.skipDate = Math.round(message.skipDate));
    if (message.presetRecursive) {
      obj.presetRecursive = message.presetRecursive.map((e) => e ? PpicRemoteControlPreset.toJSON(e) : undefined);
    } else {
      obj.presetRecursive = [];
    }
    message.scheduled !== undefined && (obj.scheduled = message.scheduled);
    message.picOnly !== undefined && (obj.picOnly = message.picOnly);
    message.quadScreen !== undefined && (obj.quadScreen = message.quadScreen);
    message.jdpPresetId !== undefined && (obj.jdpPresetId = message.jdpPresetId);
    message.extDeptVersusId !== undefined && (obj.extDeptVersusId = message.extDeptVersusId);
    message.extProblemCatalogId !== undefined && (obj.extProblemCatalogId = message.extProblemCatalogId);
    message.quadScreenV2 !== undefined && (obj.quadScreenV2 = message.quadScreenV2);
    message.refresh !== undefined && (obj.refresh = message.refresh);
    message.filterTaskDateOnly !== undefined && (obj.filterTaskDateOnly = message.filterTaskDateOnly);
    message.woGraph !== undefined && (obj.woGraph = message.woGraph);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRemoteControlPayload>, I>>(base?: I): PpicRemoteControlPayload {
    return PpicRemoteControlPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRemoteControlPayload>, I>>(object: I): PpicRemoteControlPayload {
    const message = createBasePpicRemoteControlPayload();
    message.screen = object.screen ?? undefined;
    message.deptId = object.deptId ?? undefined;
    message.workOrderType = object.workOrderType ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.skipDate = object.skipDate ?? undefined;
    message.presetRecursive = object.presetRecursive?.map((e) => PpicRemoteControlPreset.fromPartial(e)) || [];
    message.scheduled = object.scheduled ?? undefined;
    message.picOnly = object.picOnly ?? undefined;
    message.quadScreen = object.quadScreen ?? undefined;
    message.jdpPresetId = object.jdpPresetId ?? undefined;
    message.extDeptVersusId = object.extDeptVersusId ?? undefined;
    message.extProblemCatalogId = object.extProblemCatalogId ?? undefined;
    message.quadScreenV2 = object.quadScreenV2 ?? undefined;
    message.refresh = object.refresh ?? undefined;
    message.filterTaskDateOnly = object.filterTaskDateOnly ?? undefined;
    message.woGraph = object.woGraph ?? undefined;
    return message;
  },
};

function createBaseMeetingOvertimeRequest(): MeetingOvertimeRequest {
  return {
    masterJavaBaseModel: undefined,
    extRequestedById: undefined,
    extNotifyToApproveId: undefined,
    remark: undefined,
    extJobId: undefined,
    from: undefined,
    to: undefined,
    approvalDate: undefined,
    extApprovedById: undefined,
    cancelled: undefined,
    cancellationReason: undefined,
    pics: [],
    approvalPics: [],
    taskListId: undefined,
  };
}

export const MeetingOvertimeRequest = {
  encode(message: MeetingOvertimeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extRequestedById !== undefined) {
      writer.uint32(16).uint64(message.extRequestedById);
    }
    if (message.extNotifyToApproveId !== undefined) {
      writer.uint32(24).uint64(message.extNotifyToApproveId);
    }
    if (message.remark !== undefined) {
      writer.uint32(34).string(message.remark);
    }
    if (message.extJobId !== undefined) {
      writer.uint32(40).uint64(message.extJobId);
    }
    if (message.from !== undefined) {
      writer.uint32(50).string(message.from);
    }
    if (message.to !== undefined) {
      writer.uint32(58).string(message.to);
    }
    if (message.approvalDate !== undefined) {
      writer.uint32(66).string(message.approvalDate);
    }
    if (message.extApprovedById !== undefined) {
      writer.uint32(72).uint64(message.extApprovedById);
    }
    if (message.cancelled !== undefined) {
      writer.uint32(80).bool(message.cancelled);
    }
    if (message.cancellationReason !== undefined) {
      writer.uint32(90).string(message.cancellationReason);
    }
    for (const v of message.pics) {
      MeetingOvertimePic.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.approvalPics) {
      MeetingOvertimeApprovalPic.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.taskListId !== undefined) {
      writer.uint32(112).uint64(message.taskListId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingOvertimeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingOvertimeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extRequestedById = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extNotifyToApproveId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extJobId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.from = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.to = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.approvalDate = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.extApprovedById = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.cancelled = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.cancellationReason = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.pics.push(MeetingOvertimePic.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.approvalPics.push(MeetingOvertimeApprovalPic.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingOvertimeRequest {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extRequestedById: isSet(object.extRequestedById) ? String(object.extRequestedById) : undefined,
      extNotifyToApproveId: isSet(object.extNotifyToApproveId) ? String(object.extNotifyToApproveId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      extJobId: isSet(object.extJobId) ? String(object.extJobId) : undefined,
      from: isSet(object.from) ? String(object.from) : undefined,
      to: isSet(object.to) ? String(object.to) : undefined,
      approvalDate: isSet(object.approvalDate) ? String(object.approvalDate) : undefined,
      extApprovedById: isSet(object.extApprovedById) ? String(object.extApprovedById) : undefined,
      cancelled: isSet(object.cancelled) ? Boolean(object.cancelled) : undefined,
      cancellationReason: isSet(object.cancellationReason) ? String(object.cancellationReason) : undefined,
      pics: Array.isArray(object?.pics) ? object.pics.map((e: any) => MeetingOvertimePic.fromJSON(e)) : [],
      approvalPics: Array.isArray(object?.approvalPics)
        ? object.approvalPics.map((e: any) => MeetingOvertimeApprovalPic.fromJSON(e))
        : [],
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
    };
  },

  toJSON(message: MeetingOvertimeRequest): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extRequestedById !== undefined && (obj.extRequestedById = message.extRequestedById);
    message.extNotifyToApproveId !== undefined && (obj.extNotifyToApproveId = message.extNotifyToApproveId);
    message.remark !== undefined && (obj.remark = message.remark);
    message.extJobId !== undefined && (obj.extJobId = message.extJobId);
    message.from !== undefined && (obj.from = message.from);
    message.to !== undefined && (obj.to = message.to);
    message.approvalDate !== undefined && (obj.approvalDate = message.approvalDate);
    message.extApprovedById !== undefined && (obj.extApprovedById = message.extApprovedById);
    message.cancelled !== undefined && (obj.cancelled = message.cancelled);
    message.cancellationReason !== undefined && (obj.cancellationReason = message.cancellationReason);
    if (message.pics) {
      obj.pics = message.pics.map((e) => e ? MeetingOvertimePic.toJSON(e) : undefined);
    } else {
      obj.pics = [];
    }
    if (message.approvalPics) {
      obj.approvalPics = message.approvalPics.map((e) => e ? MeetingOvertimeApprovalPic.toJSON(e) : undefined);
    } else {
      obj.approvalPics = [];
    }
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingOvertimeRequest>, I>>(base?: I): MeetingOvertimeRequest {
    return MeetingOvertimeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingOvertimeRequest>, I>>(object: I): MeetingOvertimeRequest {
    const message = createBaseMeetingOvertimeRequest();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extRequestedById = object.extRequestedById ?? undefined;
    message.extNotifyToApproveId = object.extNotifyToApproveId ?? undefined;
    message.remark = object.remark ?? undefined;
    message.extJobId = object.extJobId ?? undefined;
    message.from = object.from ?? undefined;
    message.to = object.to ?? undefined;
    message.approvalDate = object.approvalDate ?? undefined;
    message.extApprovedById = object.extApprovedById ?? undefined;
    message.cancelled = object.cancelled ?? undefined;
    message.cancellationReason = object.cancellationReason ?? undefined;
    message.pics = object.pics?.map((e) => MeetingOvertimePic.fromPartial(e)) || [];
    message.approvalPics = object.approvalPics?.map((e) => MeetingOvertimeApprovalPic.fromPartial(e)) || [];
    message.taskListId = object.taskListId ?? undefined;
    return message;
  },
};

function createBaseMeetingOvertimePic(): MeetingOvertimePic {
  return {
    masterJavaBaseModel: undefined,
    extUserId: undefined,
    overrideHrs: undefined,
    approvalRealizationDate: undefined,
    actualWorkingHoursFromAttendance: undefined,
    remark: undefined,
  };
}

export const MeetingOvertimePic = {
  encode(message: MeetingOvertimePic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.overrideHrs !== undefined) {
      writer.uint32(25).double(message.overrideHrs);
    }
    if (message.approvalRealizationDate !== undefined) {
      writer.uint32(34).string(message.approvalRealizationDate);
    }
    if (message.actualWorkingHoursFromAttendance !== undefined) {
      writer.uint32(41).double(message.actualWorkingHoursFromAttendance);
    }
    if (message.remark !== undefined) {
      writer.uint32(50).string(message.remark);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingOvertimePic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingOvertimePic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.overrideHrs = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.approvalRealizationDate = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.actualWorkingHoursFromAttendance = reader.double();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.remark = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingOvertimePic {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      overrideHrs: isSet(object.overrideHrs) ? Number(object.overrideHrs) : undefined,
      approvalRealizationDate: isSet(object.approvalRealizationDate)
        ? String(object.approvalRealizationDate)
        : undefined,
      actualWorkingHoursFromAttendance: isSet(object.actualWorkingHoursFromAttendance)
        ? Number(object.actualWorkingHoursFromAttendance)
        : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
    };
  },

  toJSON(message: MeetingOvertimePic): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.overrideHrs !== undefined && (obj.overrideHrs = message.overrideHrs);
    message.approvalRealizationDate !== undefined && (obj.approvalRealizationDate = message.approvalRealizationDate);
    message.actualWorkingHoursFromAttendance !== undefined &&
      (obj.actualWorkingHoursFromAttendance = message.actualWorkingHoursFromAttendance);
    message.remark !== undefined && (obj.remark = message.remark);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingOvertimePic>, I>>(base?: I): MeetingOvertimePic {
    return MeetingOvertimePic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingOvertimePic>, I>>(object: I): MeetingOvertimePic {
    const message = createBaseMeetingOvertimePic();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.overrideHrs = object.overrideHrs ?? undefined;
    message.approvalRealizationDate = object.approvalRealizationDate ?? undefined;
    message.actualWorkingHoursFromAttendance = object.actualWorkingHoursFromAttendance ?? undefined;
    message.remark = object.remark ?? undefined;
    return message;
  },
};

function createBaseMeetingOvertimeApprovalTemplate(): MeetingOvertimeApprovalTemplate {
  return { masterJavaBaseModel: undefined, name: undefined, items: [], extDepartmentId: undefined };
}

export const MeetingOvertimeApprovalTemplate = {
  encode(message: MeetingOvertimeApprovalTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.items) {
      MeetingOvertimeApprovalTemplateItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(32).uint64(message.extDepartmentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingOvertimeApprovalTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingOvertimeApprovalTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.items.push(MeetingOvertimeApprovalTemplateItem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingOvertimeApprovalTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MeetingOvertimeApprovalTemplateItem.fromJSON(e))
        : [],
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
    };
  },

  toJSON(message: MeetingOvertimeApprovalTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.items) {
      obj.items = message.items.map((e) => e ? MeetingOvertimeApprovalTemplateItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingOvertimeApprovalTemplate>, I>>(base?: I): MeetingOvertimeApprovalTemplate {
    return MeetingOvertimeApprovalTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingOvertimeApprovalTemplate>, I>>(
    object: I,
  ): MeetingOvertimeApprovalTemplate {
    const message = createBaseMeetingOvertimeApprovalTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.items = object.items?.map((e) => MeetingOvertimeApprovalTemplateItem.fromPartial(e)) || [];
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    return message;
  },
};

function createBaseMeetingOvertimeApprovalTemplateItem(): MeetingOvertimeApprovalTemplateItem {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    overtimeApprovalTemplateId: undefined,
    extUserId: undefined,
    type: undefined,
  };
}

export const MeetingOvertimeApprovalTemplateItem = {
  encode(message: MeetingOvertimeApprovalTemplateItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.overtimeApprovalTemplateId !== undefined) {
      writer.uint32(24).uint64(message.overtimeApprovalTemplateId);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(32).uint64(message.extUserId);
    }
    if (message.type !== undefined) {
      writer.uint32(40).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingOvertimeApprovalTemplateItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingOvertimeApprovalTemplateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.overtimeApprovalTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingOvertimeApprovalTemplateItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      overtimeApprovalTemplateId: isSet(object.overtimeApprovalTemplateId)
        ? String(object.overtimeApprovalTemplateId)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      type: isSet(object.type) ? meetingOvertimeApprovalPicTypeFromJSON(object.type) : undefined,
    };
  },

  toJSON(message: MeetingOvertimeApprovalTemplateItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.overtimeApprovalTemplateId !== undefined &&
      (obj.overtimeApprovalTemplateId = message.overtimeApprovalTemplateId);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? meetingOvertimeApprovalPicTypeToJSON(message.type) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingOvertimeApprovalTemplateItem>, I>>(
    base?: I,
  ): MeetingOvertimeApprovalTemplateItem {
    return MeetingOvertimeApprovalTemplateItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingOvertimeApprovalTemplateItem>, I>>(
    object: I,
  ): MeetingOvertimeApprovalTemplateItem {
    const message = createBaseMeetingOvertimeApprovalTemplateItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.overtimeApprovalTemplateId = object.overtimeApprovalTemplateId ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.type = object.type ?? undefined;
    return message;
  },
};

function createBaseAuthserverPph21MarriageCategory(): AuthserverPph21MarriageCategory {
  return { masterJavaBaseModel: undefined, name: undefined, terCategory: undefined };
}

export const AuthserverPph21MarriageCategory = {
  encode(message: AuthserverPph21MarriageCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.terCategory !== undefined) {
      writer.uint32(26).string(message.terCategory);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverPph21MarriageCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverPph21MarriageCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.terCategory = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverPph21MarriageCategory {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      terCategory: isSet(object.terCategory) ? String(object.terCategory) : undefined,
    };
  },

  toJSON(message: AuthserverPph21MarriageCategory): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.terCategory !== undefined && (obj.terCategory = message.terCategory);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverPph21MarriageCategory>, I>>(base?: I): AuthserverPph21MarriageCategory {
    return AuthserverPph21MarriageCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverPph21MarriageCategory>, I>>(
    object: I,
  ): AuthserverPph21MarriageCategory {
    const message = createBaseAuthserverPph21MarriageCategory();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.terCategory = object.terCategory ?? undefined;
    return message;
  },
};

function createBaseAuthserverPph21MarriageCategories(): AuthserverPph21MarriageCategories {
  return { categories: [] };
}

export const AuthserverPph21MarriageCategories = {
  encode(message: AuthserverPph21MarriageCategories, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.categories) {
      AuthserverPph21MarriageCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverPph21MarriageCategories {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverPph21MarriageCategories();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categories.push(AuthserverPph21MarriageCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverPph21MarriageCategories {
    return {
      categories: Array.isArray(object?.categories)
        ? object.categories.map((e: any) => AuthserverPph21MarriageCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AuthserverPph21MarriageCategories): unknown {
    const obj: any = {};
    if (message.categories) {
      obj.categories = message.categories.map((e) => e ? AuthserverPph21MarriageCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverPph21MarriageCategories>, I>>(
    base?: I,
  ): AuthserverPph21MarriageCategories {
    return AuthserverPph21MarriageCategories.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverPph21MarriageCategories>, I>>(
    object: I,
  ): AuthserverPph21MarriageCategories {
    const message = createBaseAuthserverPph21MarriageCategories();
    message.categories = object.categories?.map((e) => AuthserverPph21MarriageCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingOvertimeApprovalTemplates(): MeetingOvertimeApprovalTemplates {
  return { templates: [] };
}

export const MeetingOvertimeApprovalTemplates = {
  encode(message: MeetingOvertimeApprovalTemplates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      MeetingOvertimeApprovalTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingOvertimeApprovalTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingOvertimeApprovalTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(MeetingOvertimeApprovalTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingOvertimeApprovalTemplates {
    return {
      templates: Array.isArray(object?.templates)
        ? object.templates.map((e: any) => MeetingOvertimeApprovalTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingOvertimeApprovalTemplates): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) => e ? MeetingOvertimeApprovalTemplate.toJSON(e) : undefined);
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingOvertimeApprovalTemplates>, I>>(
    base?: I,
  ): MeetingOvertimeApprovalTemplates {
    return MeetingOvertimeApprovalTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingOvertimeApprovalTemplates>, I>>(
    object: I,
  ): MeetingOvertimeApprovalTemplates {
    const message = createBaseMeetingOvertimeApprovalTemplates();
    message.templates = object.templates?.map((e) => MeetingOvertimeApprovalTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingOvertimeApprovalPic(): MeetingOvertimeApprovalPic {
  return { masterJavaBaseModel: undefined, extUserId: undefined, type: undefined, approvalDate: undefined };
}

export const MeetingOvertimeApprovalPic = {
  encode(message: MeetingOvertimeApprovalPic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.approvalDate !== undefined) {
      writer.uint32(34).string(message.approvalDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingOvertimeApprovalPic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingOvertimeApprovalPic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.approvalDate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingOvertimeApprovalPic {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      type: isSet(object.type) ? meetingOvertimeApprovalPicTypeFromJSON(object.type) : undefined,
      approvalDate: isSet(object.approvalDate) ? String(object.approvalDate) : undefined,
    };
  },

  toJSON(message: MeetingOvertimeApprovalPic): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? meetingOvertimeApprovalPicTypeToJSON(message.type) : undefined);
    message.approvalDate !== undefined && (obj.approvalDate = message.approvalDate);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingOvertimeApprovalPic>, I>>(base?: I): MeetingOvertimeApprovalPic {
    return MeetingOvertimeApprovalPic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingOvertimeApprovalPic>, I>>(object: I): MeetingOvertimeApprovalPic {
    const message = createBaseMeetingOvertimeApprovalPic();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.type = object.type ?? undefined;
    message.approvalDate = object.approvalDate ?? undefined;
    return message;
  },
};

function createBaseMeetingOvertimeRequests(): MeetingOvertimeRequests {
  return { overtimeRequests: [] };
}

export const MeetingOvertimeRequests = {
  encode(message: MeetingOvertimeRequests, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.overtimeRequests) {
      MeetingOvertimeRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingOvertimeRequests {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingOvertimeRequests();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.overtimeRequests.push(MeetingOvertimeRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingOvertimeRequests {
    return {
      overtimeRequests: Array.isArray(object?.overtimeRequests)
        ? object.overtimeRequests.map((e: any) => MeetingOvertimeRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingOvertimeRequests): unknown {
    const obj: any = {};
    if (message.overtimeRequests) {
      obj.overtimeRequests = message.overtimeRequests.map((e) => e ? MeetingOvertimeRequest.toJSON(e) : undefined);
    } else {
      obj.overtimeRequests = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingOvertimeRequests>, I>>(base?: I): MeetingOvertimeRequests {
    return MeetingOvertimeRequests.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingOvertimeRequests>, I>>(object: I): MeetingOvertimeRequests {
    const message = createBaseMeetingOvertimeRequests();
    message.overtimeRequests = object.overtimeRequests?.map((e) => MeetingOvertimeRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRemoteControlPreset(): PpicRemoteControlPreset {
  return {
    masterJavaBaseModel: undefined,
    userId: undefined,
    extDepartmentId: undefined,
    screen: undefined,
    name: undefined,
    jobId: undefined,
    extUserId: undefined,
    workOrderType: undefined,
    skipDate: undefined,
    presetRecursive: [],
    presetId: undefined,
    scheduled: undefined,
    manualCode: undefined,
    picOnly: undefined,
    quadScreen: undefined,
    jdpPresetId: undefined,
    extDeptVersusId: undefined,
    extProblemCatalogId: undefined,
    quadScreenV2: undefined,
    refresh: undefined,
    filterTaskDateOnly: undefined,
    woGraph: undefined,
  };
}

export const PpicRemoteControlPreset = {
  encode(message: PpicRemoteControlPreset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.userId !== undefined) {
      writer.uint32(16).uint64(message.userId);
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(24).uint64(message.extDepartmentId);
    }
    if (message.screen !== undefined) {
      writer.uint32(34).string(message.screen);
    }
    if (message.name !== undefined) {
      writer.uint32(42).string(message.name);
    }
    if (message.jobId !== undefined) {
      writer.uint32(48).uint64(message.jobId);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(56).uint64(message.extUserId);
    }
    if (message.workOrderType !== undefined) {
      writer.uint32(64).int32(message.workOrderType);
    }
    if (message.skipDate !== undefined) {
      writer.uint32(72).int32(message.skipDate);
    }
    for (const v of message.presetRecursive) {
      PpicRemoteControlPreset.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.presetId !== undefined) {
      writer.uint32(88).uint64(message.presetId);
    }
    if (message.scheduled !== undefined) {
      writer.uint32(96).bool(message.scheduled);
    }
    if (message.manualCode !== undefined) {
      writer.uint32(106).string(message.manualCode);
    }
    if (message.picOnly !== undefined) {
      writer.uint32(112).bool(message.picOnly);
    }
    if (message.quadScreen !== undefined) {
      writer.uint32(120).bool(message.quadScreen);
    }
    if (message.jdpPresetId !== undefined) {
      writer.uint32(128).uint64(message.jdpPresetId);
    }
    if (message.extDeptVersusId !== undefined) {
      writer.uint32(136).uint64(message.extDeptVersusId);
    }
    if (message.extProblemCatalogId !== undefined) {
      writer.uint32(144).uint64(message.extProblemCatalogId);
    }
    if (message.quadScreenV2 !== undefined) {
      writer.uint32(152).bool(message.quadScreenV2);
    }
    if (message.refresh !== undefined) {
      writer.uint32(160).bool(message.refresh);
    }
    if (message.filterTaskDateOnly !== undefined) {
      writer.uint32(168).bool(message.filterTaskDateOnly);
    }
    if (message.woGraph !== undefined) {
      writer.uint32(176).bool(message.woGraph);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRemoteControlPreset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRemoteControlPreset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.screen = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.workOrderType = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.skipDate = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.presetRecursive.push(PpicRemoteControlPreset.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.presetId = longToString(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.scheduled = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.manualCode = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.picOnly = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.quadScreen = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.jdpPresetId = longToString(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.extDeptVersusId = longToString(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.extProblemCatalogId = longToString(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.quadScreenV2 = reader.bool();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.refresh = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.filterTaskDateOnly = reader.bool();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.woGraph = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRemoteControlPreset {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      screen: isSet(object.screen) ? String(object.screen) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      workOrderType: isSet(object.workOrderType) ? workOrderTypeFromJSON(object.workOrderType) : undefined,
      skipDate: isSet(object.skipDate) ? Number(object.skipDate) : undefined,
      presetRecursive: Array.isArray(object?.presetRecursive)
        ? object.presetRecursive.map((e: any) => PpicRemoteControlPreset.fromJSON(e))
        : [],
      presetId: isSet(object.presetId) ? String(object.presetId) : undefined,
      scheduled: isSet(object.scheduled) ? Boolean(object.scheduled) : undefined,
      manualCode: isSet(object.manualCode) ? String(object.manualCode) : undefined,
      picOnly: isSet(object.picOnly) ? Boolean(object.picOnly) : undefined,
      quadScreen: isSet(object.quadScreen) ? Boolean(object.quadScreen) : undefined,
      jdpPresetId: isSet(object.jdpPresetId) ? String(object.jdpPresetId) : undefined,
      extDeptVersusId: isSet(object.extDeptVersusId) ? String(object.extDeptVersusId) : undefined,
      extProblemCatalogId: isSet(object.extProblemCatalogId) ? String(object.extProblemCatalogId) : undefined,
      quadScreenV2: isSet(object.quadScreenV2) ? Boolean(object.quadScreenV2) : undefined,
      refresh: isSet(object.refresh) ? Boolean(object.refresh) : undefined,
      filterTaskDateOnly: isSet(object.filterTaskDateOnly) ? Boolean(object.filterTaskDateOnly) : undefined,
      woGraph: isSet(object.woGraph) ? Boolean(object.woGraph) : undefined,
    };
  },

  toJSON(message: PpicRemoteControlPreset): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.screen !== undefined && (obj.screen = message.screen);
    message.name !== undefined && (obj.name = message.name);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.workOrderType !== undefined &&
      (obj.workOrderType = message.workOrderType !== undefined
        ? workOrderTypeToJSON(message.workOrderType)
        : undefined);
    message.skipDate !== undefined && (obj.skipDate = Math.round(message.skipDate));
    if (message.presetRecursive) {
      obj.presetRecursive = message.presetRecursive.map((e) => e ? PpicRemoteControlPreset.toJSON(e) : undefined);
    } else {
      obj.presetRecursive = [];
    }
    message.presetId !== undefined && (obj.presetId = message.presetId);
    message.scheduled !== undefined && (obj.scheduled = message.scheduled);
    message.manualCode !== undefined && (obj.manualCode = message.manualCode);
    message.picOnly !== undefined && (obj.picOnly = message.picOnly);
    message.quadScreen !== undefined && (obj.quadScreen = message.quadScreen);
    message.jdpPresetId !== undefined && (obj.jdpPresetId = message.jdpPresetId);
    message.extDeptVersusId !== undefined && (obj.extDeptVersusId = message.extDeptVersusId);
    message.extProblemCatalogId !== undefined && (obj.extProblemCatalogId = message.extProblemCatalogId);
    message.quadScreenV2 !== undefined && (obj.quadScreenV2 = message.quadScreenV2);
    message.refresh !== undefined && (obj.refresh = message.refresh);
    message.filterTaskDateOnly !== undefined && (obj.filterTaskDateOnly = message.filterTaskDateOnly);
    message.woGraph !== undefined && (obj.woGraph = message.woGraph);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRemoteControlPreset>, I>>(base?: I): PpicRemoteControlPreset {
    return PpicRemoteControlPreset.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRemoteControlPreset>, I>>(object: I): PpicRemoteControlPreset {
    const message = createBasePpicRemoteControlPreset();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.userId = object.userId ?? undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.screen = object.screen ?? undefined;
    message.name = object.name ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.workOrderType = object.workOrderType ?? undefined;
    message.skipDate = object.skipDate ?? undefined;
    message.presetRecursive = object.presetRecursive?.map((e) => PpicRemoteControlPreset.fromPartial(e)) || [];
    message.presetId = object.presetId ?? undefined;
    message.scheduled = object.scheduled ?? undefined;
    message.manualCode = object.manualCode ?? undefined;
    message.picOnly = object.picOnly ?? undefined;
    message.quadScreen = object.quadScreen ?? undefined;
    message.jdpPresetId = object.jdpPresetId ?? undefined;
    message.extDeptVersusId = object.extDeptVersusId ?? undefined;
    message.extProblemCatalogId = object.extProblemCatalogId ?? undefined;
    message.quadScreenV2 = object.quadScreenV2 ?? undefined;
    message.refresh = object.refresh ?? undefined;
    message.filterTaskDateOnly = object.filterTaskDateOnly ?? undefined;
    message.woGraph = object.woGraph ?? undefined;
    return message;
  },
};

function createBasePpicRemoteControlPresets(): PpicRemoteControlPresets {
  return { presets: [] };
}

export const PpicRemoteControlPresets = {
  encode(message: PpicRemoteControlPresets, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.presets) {
      PpicRemoteControlPreset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRemoteControlPresets {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRemoteControlPresets();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.presets.push(PpicRemoteControlPreset.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRemoteControlPresets {
    return {
      presets: Array.isArray(object?.presets)
        ? object.presets.map((e: any) => PpicRemoteControlPreset.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicRemoteControlPresets): unknown {
    const obj: any = {};
    if (message.presets) {
      obj.presets = message.presets.map((e) => e ? PpicRemoteControlPreset.toJSON(e) : undefined);
    } else {
      obj.presets = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRemoteControlPresets>, I>>(base?: I): PpicRemoteControlPresets {
    return PpicRemoteControlPresets.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRemoteControlPresets>, I>>(object: I): PpicRemoteControlPresets {
    const message = createBasePpicRemoteControlPresets();
    message.presets = object.presets?.map((e) => PpicRemoteControlPreset.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMeetingTaskListMiscellaneousSettlement(): MeetingTaskListMiscellaneousSettlement {
  return { masterJavaBaseModel: undefined, name: undefined, value: undefined, taskListId: undefined };
}

export const MeetingTaskListMiscellaneousSettlement = {
  encode(message: MeetingTaskListMiscellaneousSettlement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.value !== undefined) {
      writer.uint32(25).double(message.value);
    }
    if (message.taskListId !== undefined) {
      writer.uint32(32).uint64(message.taskListId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingTaskListMiscellaneousSettlement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingTaskListMiscellaneousSettlement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.value = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.taskListId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingTaskListMiscellaneousSettlement {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
      taskListId: isSet(object.taskListId) ? String(object.taskListId) : undefined,
    };
  },

  toJSON(message: MeetingTaskListMiscellaneousSettlement): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    message.taskListId !== undefined && (obj.taskListId = message.taskListId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingTaskListMiscellaneousSettlement>, I>>(
    base?: I,
  ): MeetingTaskListMiscellaneousSettlement {
    return MeetingTaskListMiscellaneousSettlement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingTaskListMiscellaneousSettlement>, I>>(
    object: I,
  ): MeetingTaskListMiscellaneousSettlement {
    const message = createBaseMeetingTaskListMiscellaneousSettlement();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.value = object.value ?? undefined;
    message.taskListId = object.taskListId ?? undefined;
    return message;
  },
};

function createBasePpicDepartmentTemplateRule(): PpicDepartmentTemplateRule {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    type: undefined,
    departmentTemplateRule: undefined,
    departmentTemplateRuleId: undefined,
    departmentTemplateRules: [],
    departmentTemplateItem: undefined,
    departmentTemplateItemId: undefined,
  };
}

export const PpicDepartmentTemplateRule = {
  encode(message: PpicDepartmentTemplateRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.departmentTemplateRule !== undefined) {
      PpicDepartmentTemplateRule.encode(message.departmentTemplateRule, writer.uint32(34).fork()).ldelim();
    }
    if (message.departmentTemplateRuleId !== undefined) {
      writer.uint32(56).uint64(message.departmentTemplateRuleId);
    }
    for (const v of message.departmentTemplateRules) {
      PpicDepartmentTemplateRule.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.departmentTemplateItem !== undefined) {
      PpicDepartmentTemplateItem.encode(message.departmentTemplateItem, writer.uint32(50).fork()).ldelim();
    }
    if (message.departmentTemplateItemId !== undefined) {
      writer.uint32(64).uint64(message.departmentTemplateItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplateRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplateRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.departmentTemplateRule = PpicDepartmentTemplateRule.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.departmentTemplateRuleId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.departmentTemplateRules.push(PpicDepartmentTemplateRule.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.departmentTemplateItem = PpicDepartmentTemplateItem.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.departmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplateRule {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      type: isSet(object.type) ? ppicDepartmentTemplateRule_PpicDepartmentRuleTypeFromJSON(object.type) : undefined,
      departmentTemplateRule: isSet(object.departmentTemplateRule)
        ? PpicDepartmentTemplateRule.fromJSON(object.departmentTemplateRule)
        : undefined,
      departmentTemplateRuleId: isSet(object.departmentTemplateRuleId)
        ? String(object.departmentTemplateRuleId)
        : undefined,
      departmentTemplateRules: Array.isArray(object?.departmentTemplateRules)
        ? object.departmentTemplateRules.map((e: any) => PpicDepartmentTemplateRule.fromJSON(e))
        : [],
      departmentTemplateItem: isSet(object.departmentTemplateItem)
        ? PpicDepartmentTemplateItem.fromJSON(object.departmentTemplateItem)
        : undefined,
      departmentTemplateItemId: isSet(object.departmentTemplateItemId)
        ? String(object.departmentTemplateItemId)
        : undefined,
    };
  },

  toJSON(message: PpicDepartmentTemplateRule): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type !== undefined
      ? ppicDepartmentTemplateRule_PpicDepartmentRuleTypeToJSON(message.type)
      : undefined);
    message.departmentTemplateRule !== undefined && (obj.departmentTemplateRule = message.departmentTemplateRule
      ? PpicDepartmentTemplateRule.toJSON(message.departmentTemplateRule)
      : undefined);
    message.departmentTemplateRuleId !== undefined && (obj.departmentTemplateRuleId = message.departmentTemplateRuleId);
    if (message.departmentTemplateRules) {
      obj.departmentTemplateRules = message.departmentTemplateRules.map((e) =>
        e ? PpicDepartmentTemplateRule.toJSON(e) : undefined
      );
    } else {
      obj.departmentTemplateRules = [];
    }
    message.departmentTemplateItem !== undefined && (obj.departmentTemplateItem = message.departmentTemplateItem
      ? PpicDepartmentTemplateItem.toJSON(message.departmentTemplateItem)
      : undefined);
    message.departmentTemplateItemId !== undefined && (obj.departmentTemplateItemId = message.departmentTemplateItemId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplateRule>, I>>(base?: I): PpicDepartmentTemplateRule {
    return PpicDepartmentTemplateRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplateRule>, I>>(object: I): PpicDepartmentTemplateRule {
    const message = createBasePpicDepartmentTemplateRule();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.departmentTemplateRule =
      (object.departmentTemplateRule !== undefined && object.departmentTemplateRule !== null)
        ? PpicDepartmentTemplateRule.fromPartial(object.departmentTemplateRule)
        : undefined;
    message.departmentTemplateRuleId = object.departmentTemplateRuleId ?? undefined;
    message.departmentTemplateRules =
      object.departmentTemplateRules?.map((e) => PpicDepartmentTemplateRule.fromPartial(e)) || [];
    message.departmentTemplateItem =
      (object.departmentTemplateItem !== undefined && object.departmentTemplateItem !== null)
        ? PpicDepartmentTemplateItem.fromPartial(object.departmentTemplateItem)
        : undefined;
    message.departmentTemplateItemId = object.departmentTemplateItemId ?? undefined;
    return message;
  },
};

function createBasePpicDepartmentTemplateRules(): PpicDepartmentTemplateRules {
  return { rules: [] };
}

export const PpicDepartmentTemplateRules = {
  encode(message: PpicDepartmentTemplateRules, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rules) {
      PpicDepartmentTemplateRule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplateRules {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplateRules();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rules.push(PpicDepartmentTemplateRule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplateRules {
    return {
      rules: Array.isArray(object?.rules) ? object.rules.map((e: any) => PpicDepartmentTemplateRule.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicDepartmentTemplateRules): unknown {
    const obj: any = {};
    if (message.rules) {
      obj.rules = message.rules.map((e) => e ? PpicDepartmentTemplateRule.toJSON(e) : undefined);
    } else {
      obj.rules = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplateRules>, I>>(base?: I): PpicDepartmentTemplateRules {
    return PpicDepartmentTemplateRules.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplateRules>, I>>(object: I): PpicDepartmentTemplateRules {
    const message = createBasePpicDepartmentTemplateRules();
    message.rules = object.rules?.map((e) => PpicDepartmentTemplateRule.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicWorkingHour(): PpicWorkingHour {
  return {
    masterJavaBaseModel: undefined,
    start: undefined,
    end: undefined,
    label: undefined,
    breakStart: undefined,
    breakEnd: undefined,
  };
}

export const PpicWorkingHour = {
  encode(message: PpicWorkingHour, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.start !== undefined) {
      writer.uint32(16).int32(message.start);
    }
    if (message.end !== undefined) {
      writer.uint32(24).int32(message.end);
    }
    if (message.label !== undefined) {
      writer.uint32(34).string(message.label);
    }
    if (message.breakStart !== undefined) {
      writer.uint32(40).int32(message.breakStart);
    }
    if (message.breakEnd !== undefined) {
      writer.uint32(48).int32(message.breakEnd);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicWorkingHour {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicWorkingHour();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.start = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.end = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.label = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.breakStart = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.breakEnd = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicWorkingHour {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      start: isSet(object.start) ? Number(object.start) : undefined,
      end: isSet(object.end) ? Number(object.end) : undefined,
      label: isSet(object.label) ? String(object.label) : undefined,
      breakStart: isSet(object.breakStart) ? Number(object.breakStart) : undefined,
      breakEnd: isSet(object.breakEnd) ? Number(object.breakEnd) : undefined,
    };
  },

  toJSON(message: PpicWorkingHour): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.start !== undefined && (obj.start = Math.round(message.start));
    message.end !== undefined && (obj.end = Math.round(message.end));
    message.label !== undefined && (obj.label = message.label);
    message.breakStart !== undefined && (obj.breakStart = Math.round(message.breakStart));
    message.breakEnd !== undefined && (obj.breakEnd = Math.round(message.breakEnd));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicWorkingHour>, I>>(base?: I): PpicWorkingHour {
    return PpicWorkingHour.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicWorkingHour>, I>>(object: I): PpicWorkingHour {
    const message = createBasePpicWorkingHour();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.start = object.start ?? undefined;
    message.end = object.end ?? undefined;
    message.label = object.label ?? undefined;
    message.breakStart = object.breakStart ?? undefined;
    message.breakEnd = object.breakEnd ?? undefined;
    return message;
  },
};

function createBasePpicWorkingHours(): PpicWorkingHours {
  return { workingHours: [] };
}

export const PpicWorkingHours = {
  encode(message: PpicWorkingHours, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.workingHours) {
      PpicWorkingHour.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicWorkingHours {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicWorkingHours();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workingHours.push(PpicWorkingHour.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicWorkingHours {
    return {
      workingHours: Array.isArray(object?.workingHours)
        ? object.workingHours.map((e: any) => PpicWorkingHour.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicWorkingHours): unknown {
    const obj: any = {};
    if (message.workingHours) {
      obj.workingHours = message.workingHours.map((e) => e ? PpicWorkingHour.toJSON(e) : undefined);
    } else {
      obj.workingHours = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicWorkingHours>, I>>(base?: I): PpicWorkingHours {
    return PpicWorkingHours.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicWorkingHours>, I>>(object: I): PpicWorkingHours {
    const message = createBasePpicWorkingHours();
    message.workingHours = object.workingHours?.map((e) => PpicWorkingHour.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicWarehouseReportQty(): PpicWarehouseReportQty {
  return { inventoryId: undefined, qtySeen: undefined };
}

export const PpicWarehouseReportQty = {
  encode(message: PpicWarehouseReportQty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inventoryId !== undefined) {
      writer.uint32(8).uint64(message.inventoryId);
    }
    if (message.qtySeen !== undefined) {
      writer.uint32(16).int32(message.qtySeen);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicWarehouseReportQty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicWarehouseReportQty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.inventoryId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.qtySeen = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicWarehouseReportQty {
    return {
      inventoryId: isSet(object.inventoryId) ? String(object.inventoryId) : undefined,
      qtySeen: isSet(object.qtySeen) ? Number(object.qtySeen) : undefined,
    };
  },

  toJSON(message: PpicWarehouseReportQty): unknown {
    const obj: any = {};
    message.inventoryId !== undefined && (obj.inventoryId = message.inventoryId);
    message.qtySeen !== undefined && (obj.qtySeen = Math.round(message.qtySeen));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicWarehouseReportQty>, I>>(base?: I): PpicWarehouseReportQty {
    return PpicWarehouseReportQty.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicWarehouseReportQty>, I>>(object: I): PpicWarehouseReportQty {
    const message = createBasePpicWarehouseReportQty();
    message.inventoryId = object.inventoryId ?? undefined;
    message.qtySeen = object.qtySeen ?? undefined;
    return message;
  },
};

function createBasePpicIndividualEntity(): PpicIndividualEntity {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const PpicIndividualEntity = {
  encode(message: PpicIndividualEntity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicIndividualEntity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicIndividualEntity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicIndividualEntity {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: PpicIndividualEntity): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicIndividualEntity>, I>>(base?: I): PpicIndividualEntity {
    return PpicIndividualEntity.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicIndividualEntity>, I>>(object: I): PpicIndividualEntity {
    const message = createBasePpicIndividualEntity();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBasePpicIndividualEntities(): PpicIndividualEntities {
  return { entities: [] };
}

export const PpicIndividualEntities = {
  encode(message: PpicIndividualEntities, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entities) {
      PpicIndividualEntity.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicIndividualEntities {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicIndividualEntities();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entities.push(PpicIndividualEntity.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicIndividualEntities {
    return {
      entities: Array.isArray(object?.entities)
        ? object.entities.map((e: any) => PpicIndividualEntity.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicIndividualEntities): unknown {
    const obj: any = {};
    if (message.entities) {
      obj.entities = message.entities.map((e) => e ? PpicIndividualEntity.toJSON(e) : undefined);
    } else {
      obj.entities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicIndividualEntities>, I>>(base?: I): PpicIndividualEntities {
    return PpicIndividualEntities.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicIndividualEntities>, I>>(object: I): PpicIndividualEntities {
    const message = createBasePpicIndividualEntities();
    message.entities = object.entities?.map((e) => PpicIndividualEntity.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPartNumberMatchGroup(): PpicPartNumberMatchGroup {
  return { masterJavaBaseModel: undefined, extItemId: undefined, extPurchaseOrderId: undefined };
}

export const PpicPartNumberMatchGroup = {
  encode(message: PpicPartNumberMatchGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extItemId !== undefined) {
      writer.uint32(16).uint64(message.extItemId);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(24).uint64(message.extPurchaseOrderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPartNumberMatchGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPartNumberMatchGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPartNumberMatchGroup {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
    };
  },

  toJSON(message: PpicPartNumberMatchGroup): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPartNumberMatchGroup>, I>>(base?: I): PpicPartNumberMatchGroup {
    return PpicPartNumberMatchGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPartNumberMatchGroup>, I>>(object: I): PpicPartNumberMatchGroup {
    const message = createBasePpicPartNumberMatchGroup();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    return message;
  },
};

function createBasePpicPartNumberMatchGroups(): PpicPartNumberMatchGroups {
  return { groups: [] };
}

export const PpicPartNumberMatchGroups = {
  encode(message: PpicPartNumberMatchGroups, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      PpicPartNumberMatchGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPartNumberMatchGroups {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPartNumberMatchGroups();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(PpicPartNumberMatchGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPartNumberMatchGroups {
    return {
      groups: Array.isArray(object?.groups) ? object.groups.map((e: any) => PpicPartNumberMatchGroup.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicPartNumberMatchGroups): unknown {
    const obj: any = {};
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? PpicPartNumberMatchGroup.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPartNumberMatchGroups>, I>>(base?: I): PpicPartNumberMatchGroups {
    return PpicPartNumberMatchGroups.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPartNumberMatchGroups>, I>>(object: I): PpicPartNumberMatchGroups {
    const message = createBasePpicPartNumberMatchGroups();
    message.groups = object.groups?.map((e) => PpicPartNumberMatchGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPartNumberMatch(): PpicPartNumberMatch {
  return {
    masterJavaBaseModel: undefined,
    customerPartNumber: undefined,
    gspePartNumber: undefined,
    source: undefined,
    destination: undefined,
    qty: undefined,
    extPurchaseOrderId: undefined,
    sourcePartNumber: undefined,
    destinationPartNumber: undefined,
    extPurchaseOrderItemId: undefined,
    groupQty: undefined,
    isGroup: undefined,
    sequenceNumber: undefined,
  };
}

export const PpicPartNumberMatch = {
  encode(message: PpicPartNumberMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.customerPartNumber !== undefined) {
      writer.uint32(18).string(message.customerPartNumber);
    }
    if (message.gspePartNumber !== undefined) {
      writer.uint32(24).uint64(message.gspePartNumber);
    }
    if (message.source !== undefined) {
      writer.uint32(32).uint64(message.source);
    }
    if (message.destination !== undefined) {
      writer.uint32(40).uint64(message.destination);
    }
    if (message.qty !== undefined) {
      writer.uint32(48).int32(message.qty);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(56).uint64(message.extPurchaseOrderId);
    }
    if (message.sourcePartNumber !== undefined) {
      writer.uint32(66).string(message.sourcePartNumber);
    }
    if (message.destinationPartNumber !== undefined) {
      writer.uint32(74).string(message.destinationPartNumber);
    }
    if (message.extPurchaseOrderItemId !== undefined) {
      writer.uint32(80).uint64(message.extPurchaseOrderItemId);
    }
    if (message.groupQty !== undefined) {
      writer.uint32(88).int32(message.groupQty);
    }
    if (message.isGroup !== undefined) {
      writer.uint32(96).bool(message.isGroup);
    }
    if (message.sequenceNumber !== undefined) {
      writer.uint32(104).int32(message.sequenceNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPartNumberMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPartNumberMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customerPartNumber = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.gspePartNumber = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.source = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.destination = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.sourcePartNumber = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.destinationPartNumber = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.extPurchaseOrderItemId = longToString(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.groupQty = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isGroup = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.sequenceNumber = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPartNumberMatch {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      customerPartNumber: isSet(object.customerPartNumber) ? String(object.customerPartNumber) : undefined,
      gspePartNumber: isSet(object.gspePartNumber) ? String(object.gspePartNumber) : undefined,
      source: isSet(object.source) ? String(object.source) : undefined,
      destination: isSet(object.destination) ? String(object.destination) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
      sourcePartNumber: isSet(object.sourcePartNumber) ? String(object.sourcePartNumber) : undefined,
      destinationPartNumber: isSet(object.destinationPartNumber) ? String(object.destinationPartNumber) : undefined,
      extPurchaseOrderItemId: isSet(object.extPurchaseOrderItemId) ? String(object.extPurchaseOrderItemId) : undefined,
      groupQty: isSet(object.groupQty) ? Number(object.groupQty) : undefined,
      isGroup: isSet(object.isGroup) ? Boolean(object.isGroup) : undefined,
      sequenceNumber: isSet(object.sequenceNumber) ? Number(object.sequenceNumber) : undefined,
    };
  },

  toJSON(message: PpicPartNumberMatch): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.customerPartNumber !== undefined && (obj.customerPartNumber = message.customerPartNumber);
    message.gspePartNumber !== undefined && (obj.gspePartNumber = message.gspePartNumber);
    message.source !== undefined && (obj.source = message.source);
    message.destination !== undefined && (obj.destination = message.destination);
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    message.sourcePartNumber !== undefined && (obj.sourcePartNumber = message.sourcePartNumber);
    message.destinationPartNumber !== undefined && (obj.destinationPartNumber = message.destinationPartNumber);
    message.extPurchaseOrderItemId !== undefined && (obj.extPurchaseOrderItemId = message.extPurchaseOrderItemId);
    message.groupQty !== undefined && (obj.groupQty = Math.round(message.groupQty));
    message.isGroup !== undefined && (obj.isGroup = message.isGroup);
    message.sequenceNumber !== undefined && (obj.sequenceNumber = Math.round(message.sequenceNumber));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPartNumberMatch>, I>>(base?: I): PpicPartNumberMatch {
    return PpicPartNumberMatch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPartNumberMatch>, I>>(object: I): PpicPartNumberMatch {
    const message = createBasePpicPartNumberMatch();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.customerPartNumber = object.customerPartNumber ?? undefined;
    message.gspePartNumber = object.gspePartNumber ?? undefined;
    message.source = object.source ?? undefined;
    message.destination = object.destination ?? undefined;
    message.qty = object.qty ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    message.sourcePartNumber = object.sourcePartNumber ?? undefined;
    message.destinationPartNumber = object.destinationPartNumber ?? undefined;
    message.extPurchaseOrderItemId = object.extPurchaseOrderItemId ?? undefined;
    message.groupQty = object.groupQty ?? undefined;
    message.isGroup = object.isGroup ?? undefined;
    message.sequenceNumber = object.sequenceNumber ?? undefined;
    return message;
  },
};

function createBasePpicPartNumberCustomerMatch(): PpicPartNumberCustomerMatch {
  return {
    masterJavaBaseModel: undefined,
    source: undefined,
    destination: undefined,
    qty: undefined,
    extCustomerId: undefined,
  };
}

export const PpicPartNumberCustomerMatch = {
  encode(message: PpicPartNumberCustomerMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.source !== undefined) {
      writer.uint32(16).uint64(message.source);
    }
    if (message.destination !== undefined) {
      writer.uint32(24).uint64(message.destination);
    }
    if (message.qty !== undefined) {
      writer.uint32(32).int32(message.qty);
    }
    if (message.extCustomerId !== undefined) {
      writer.uint32(40).uint64(message.extCustomerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPartNumberCustomerMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPartNumberCustomerMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.source = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.destination = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extCustomerId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPartNumberCustomerMatch {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      source: isSet(object.source) ? String(object.source) : undefined,
      destination: isSet(object.destination) ? String(object.destination) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      extCustomerId: isSet(object.extCustomerId) ? String(object.extCustomerId) : undefined,
    };
  },

  toJSON(message: PpicPartNumberCustomerMatch): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.source !== undefined && (obj.source = message.source);
    message.destination !== undefined && (obj.destination = message.destination);
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.extCustomerId !== undefined && (obj.extCustomerId = message.extCustomerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPartNumberCustomerMatch>, I>>(base?: I): PpicPartNumberCustomerMatch {
    return PpicPartNumberCustomerMatch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPartNumberCustomerMatch>, I>>(object: I): PpicPartNumberCustomerMatch {
    const message = createBasePpicPartNumberCustomerMatch();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.source = object.source ?? undefined;
    message.destination = object.destination ?? undefined;
    message.qty = object.qty ?? undefined;
    message.extCustomerId = object.extCustomerId ?? undefined;
    return message;
  },
};

function createBasePpicPartNumberMatches(): PpicPartNumberMatches {
  return { matches: [] };
}

export const PpicPartNumberMatches = {
  encode(message: PpicPartNumberMatches, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.matches) {
      PpicPartNumberMatch.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPartNumberMatches {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPartNumberMatches();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.matches.push(PpicPartNumberMatch.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPartNumberMatches {
    return {
      matches: Array.isArray(object?.matches) ? object.matches.map((e: any) => PpicPartNumberMatch.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicPartNumberMatches): unknown {
    const obj: any = {};
    if (message.matches) {
      obj.matches = message.matches.map((e) => e ? PpicPartNumberMatch.toJSON(e) : undefined);
    } else {
      obj.matches = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPartNumberMatches>, I>>(base?: I): PpicPartNumberMatches {
    return PpicPartNumberMatches.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPartNumberMatches>, I>>(object: I): PpicPartNumberMatches {
    const message = createBasePpicPartNumberMatches();
    message.matches = object.matches?.map((e) => PpicPartNumberMatch.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPartNumberCustomerMatches(): PpicPartNumberCustomerMatches {
  return { matches: [] };
}

export const PpicPartNumberCustomerMatches = {
  encode(message: PpicPartNumberCustomerMatches, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.matches) {
      PpicPartNumberCustomerMatch.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPartNumberCustomerMatches {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPartNumberCustomerMatches();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.matches.push(PpicPartNumberCustomerMatch.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPartNumberCustomerMatches {
    return {
      matches: Array.isArray(object?.matches)
        ? object.matches.map((e: any) => PpicPartNumberCustomerMatch.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPartNumberCustomerMatches): unknown {
    const obj: any = {};
    if (message.matches) {
      obj.matches = message.matches.map((e) => e ? PpicPartNumberCustomerMatch.toJSON(e) : undefined);
    } else {
      obj.matches = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPartNumberCustomerMatches>, I>>(base?: I): PpicPartNumberCustomerMatches {
    return PpicPartNumberCustomerMatches.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPartNumberCustomerMatches>, I>>(
    object: I,
  ): PpicPartNumberCustomerMatches {
    const message = createBasePpicPartNumberCustomerMatches();
    message.matches = object.matches?.map((e) => PpicPartNumberCustomerMatch.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemSkill(): PpicRcemSkill {
  return {
    rcem: undefined,
    rcemId: undefined,
    skill: undefined,
    skillId: undefined,
    masterJavaBaseModel: undefined,
    rcemSkillCategoryId: undefined,
  };
}

export const PpicRcemSkill = {
  encode(message: PpicRcemSkill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rcem !== undefined) {
      PpicRcem.encode(message.rcem, writer.uint32(10).fork()).ldelim();
    }
    if (message.rcemId !== undefined) {
      writer.uint32(16).uint64(message.rcemId);
    }
    if (message.skill !== undefined) {
      PpicSkill.encode(message.skill, writer.uint32(26).fork()).ldelim();
    }
    if (message.skillId !== undefined) {
      writer.uint32(32).uint64(message.skillId);
    }
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(42).fork()).ldelim();
    }
    if (message.rcemSkillCategoryId !== undefined) {
      writer.uint32(48).uint64(message.rcemSkillCategoryId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemSkill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemSkill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rcem = PpicRcem.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rcemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.skill = PpicSkill.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.skillId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.rcemSkillCategoryId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemSkill {
    return {
      rcem: isSet(object.rcem) ? PpicRcem.fromJSON(object.rcem) : undefined,
      rcemId: isSet(object.rcemId) ? String(object.rcemId) : undefined,
      skill: isSet(object.skill) ? PpicSkill.fromJSON(object.skill) : undefined,
      skillId: isSet(object.skillId) ? String(object.skillId) : undefined,
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      rcemSkillCategoryId: isSet(object.rcemSkillCategoryId) ? String(object.rcemSkillCategoryId) : undefined,
    };
  },

  toJSON(message: PpicRcemSkill): unknown {
    const obj: any = {};
    message.rcem !== undefined && (obj.rcem = message.rcem ? PpicRcem.toJSON(message.rcem) : undefined);
    message.rcemId !== undefined && (obj.rcemId = message.rcemId);
    message.skill !== undefined && (obj.skill = message.skill ? PpicSkill.toJSON(message.skill) : undefined);
    message.skillId !== undefined && (obj.skillId = message.skillId);
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.rcemSkillCategoryId !== undefined && (obj.rcemSkillCategoryId = message.rcemSkillCategoryId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemSkill>, I>>(base?: I): PpicRcemSkill {
    return PpicRcemSkill.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemSkill>, I>>(object: I): PpicRcemSkill {
    const message = createBasePpicRcemSkill();
    message.rcem = (object.rcem !== undefined && object.rcem !== null) ? PpicRcem.fromPartial(object.rcem) : undefined;
    message.rcemId = object.rcemId ?? undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? PpicSkill.fromPartial(object.skill)
      : undefined;
    message.skillId = object.skillId ?? undefined;
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.rcemSkillCategoryId = object.rcemSkillCategoryId ?? undefined;
    return message;
  },
};

function createBasePpicRcemSkills(): PpicRcemSkills {
  return { rcemSkills: [] };
}

export const PpicRcemSkills = {
  encode(message: PpicRcemSkills, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rcemSkills) {
      PpicRcemSkill.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemSkills {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemSkills();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rcemSkills.push(PpicRcemSkill.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemSkills {
    return {
      rcemSkills: Array.isArray(object?.rcemSkills) ? object.rcemSkills.map((e: any) => PpicRcemSkill.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicRcemSkills): unknown {
    const obj: any = {};
    if (message.rcemSkills) {
      obj.rcemSkills = message.rcemSkills.map((e) => e ? PpicRcemSkill.toJSON(e) : undefined);
    } else {
      obj.rcemSkills = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemSkills>, I>>(base?: I): PpicRcemSkills {
    return PpicRcemSkills.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemSkills>, I>>(object: I): PpicRcemSkills {
    const message = createBasePpicRcemSkills();
    message.rcemSkills = object.rcemSkills?.map((e) => PpicRcemSkill.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemProcessSkill(): PpicRcemProcessSkill {
  return {
    rcemProcess: undefined,
    rcemProcessId: undefined,
    skill: undefined,
    skillId: undefined,
    masterJavaBaseModel: undefined,
  };
}

export const PpicRcemProcessSkill = {
  encode(message: PpicRcemProcessSkill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rcemProcess !== undefined) {
      PpicRcemProcess.encode(message.rcemProcess, writer.uint32(10).fork()).ldelim();
    }
    if (message.rcemProcessId !== undefined) {
      writer.uint32(16).uint64(message.rcemProcessId);
    }
    if (message.skill !== undefined) {
      PpicSkill.encode(message.skill, writer.uint32(26).fork()).ldelim();
    }
    if (message.skillId !== undefined) {
      writer.uint32(32).uint64(message.skillId);
    }
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemProcessSkill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemProcessSkill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rcemProcess = PpicRcemProcess.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rcemProcessId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.skill = PpicSkill.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.skillId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemProcessSkill {
    return {
      rcemProcess: isSet(object.rcemProcess) ? PpicRcemProcess.fromJSON(object.rcemProcess) : undefined,
      rcemProcessId: isSet(object.rcemProcessId) ? String(object.rcemProcessId) : undefined,
      skill: isSet(object.skill) ? PpicSkill.fromJSON(object.skill) : undefined,
      skillId: isSet(object.skillId) ? String(object.skillId) : undefined,
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: PpicRcemProcessSkill): unknown {
    const obj: any = {};
    message.rcemProcess !== undefined &&
      (obj.rcemProcess = message.rcemProcess ? PpicRcemProcess.toJSON(message.rcemProcess) : undefined);
    message.rcemProcessId !== undefined && (obj.rcemProcessId = message.rcemProcessId);
    message.skill !== undefined && (obj.skill = message.skill ? PpicSkill.toJSON(message.skill) : undefined);
    message.skillId !== undefined && (obj.skillId = message.skillId);
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemProcessSkill>, I>>(base?: I): PpicRcemProcessSkill {
    return PpicRcemProcessSkill.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemProcessSkill>, I>>(object: I): PpicRcemProcessSkill {
    const message = createBasePpicRcemProcessSkill();
    message.rcemProcess = (object.rcemProcess !== undefined && object.rcemProcess !== null)
      ? PpicRcemProcess.fromPartial(object.rcemProcess)
      : undefined;
    message.rcemProcessId = object.rcemProcessId ?? undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? PpicSkill.fromPartial(object.skill)
      : undefined;
    message.skillId = object.skillId ?? undefined;
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBasePpicRcemProcessSkills(): PpicRcemProcessSkills {
  return { rcemProcessSkills: [] };
}

export const PpicRcemProcessSkills = {
  encode(message: PpicRcemProcessSkills, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rcemProcessSkills) {
      PpicRcemProcessSkill.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemProcessSkills {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemProcessSkills();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rcemProcessSkills.push(PpicRcemProcessSkill.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemProcessSkills {
    return {
      rcemProcessSkills: Array.isArray(object?.rcemProcessSkills)
        ? object.rcemProcessSkills.map((e: any) => PpicRcemProcessSkill.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicRcemProcessSkills): unknown {
    const obj: any = {};
    if (message.rcemProcessSkills) {
      obj.rcemProcessSkills = message.rcemProcessSkills.map((e) => e ? PpicRcemProcessSkill.toJSON(e) : undefined);
    } else {
      obj.rcemProcessSkills = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemProcessSkills>, I>>(base?: I): PpicRcemProcessSkills {
    return PpicRcemProcessSkills.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemProcessSkills>, I>>(object: I): PpicRcemProcessSkills {
    const message = createBasePpicRcemProcessSkills();
    message.rcemProcessSkills = object.rcemProcessSkills?.map((e) => PpicRcemProcessSkill.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemSkillCategory(): PpicRcemSkillCategory {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const PpicRcemSkillCategory = {
  encode(message: PpicRcemSkillCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemSkillCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemSkillCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemSkillCategory {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: PpicRcemSkillCategory): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemSkillCategory>, I>>(base?: I): PpicRcemSkillCategory {
    return PpicRcemSkillCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemSkillCategory>, I>>(object: I): PpicRcemSkillCategory {
    const message = createBasePpicRcemSkillCategory();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBasePpicSkillWorker(): PpicSkillWorker {
  return {
    masterJavaBaseModel: undefined,
    skill: undefined,
    skillId: undefined,
    extUserId: undefined,
    skillLevel: undefined,
    isPriority: undefined,
  };
}

export const PpicSkillWorker = {
  encode(message: PpicSkillWorker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.skill !== undefined) {
      PpicSkill.encode(message.skill, writer.uint32(18).fork()).ldelim();
    }
    if (message.skillId !== undefined) {
      writer.uint32(24).uint64(message.skillId);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(32).uint64(message.extUserId);
    }
    if (message.skillLevel !== undefined) {
      writer.uint32(40).int32(message.skillLevel);
    }
    if (message.isPriority !== undefined) {
      writer.uint32(48).bool(message.isPriority);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSkillWorker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSkillWorker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.skill = PpicSkill.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.skillId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.skillLevel = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isPriority = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSkillWorker {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      skill: isSet(object.skill) ? PpicSkill.fromJSON(object.skill) : undefined,
      skillId: isSet(object.skillId) ? String(object.skillId) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      skillLevel: isSet(object.skillLevel) ? ppicSkillWorker_SkillLevelFromJSON(object.skillLevel) : undefined,
      isPriority: isSet(object.isPriority) ? Boolean(object.isPriority) : undefined,
    };
  },

  toJSON(message: PpicSkillWorker): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.skill !== undefined && (obj.skill = message.skill ? PpicSkill.toJSON(message.skill) : undefined);
    message.skillId !== undefined && (obj.skillId = message.skillId);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.skillLevel !== undefined && (obj.skillLevel = message.skillLevel !== undefined
      ? ppicSkillWorker_SkillLevelToJSON(message.skillLevel)
      : undefined);
    message.isPriority !== undefined && (obj.isPriority = message.isPriority);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSkillWorker>, I>>(base?: I): PpicSkillWorker {
    return PpicSkillWorker.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSkillWorker>, I>>(object: I): PpicSkillWorker {
    const message = createBasePpicSkillWorker();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? PpicSkill.fromPartial(object.skill)
      : undefined;
    message.skillId = object.skillId ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.skillLevel = object.skillLevel ?? undefined;
    message.isPriority = object.isPriority ?? undefined;
    return message;
  },
};

function createBasePpicSkillWorkers(): PpicSkillWorkers {
  return { workers: [] };
}

export const PpicSkillWorkers = {
  encode(message: PpicSkillWorkers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.workers) {
      PpicSkillWorkers.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSkillWorkers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSkillWorkers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workers.push(PpicSkillWorkers.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSkillWorkers {
    return {
      workers: Array.isArray(object?.workers) ? object.workers.map((e: any) => PpicSkillWorkers.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicSkillWorkers): unknown {
    const obj: any = {};
    if (message.workers) {
      obj.workers = message.workers.map((e) => e ? PpicSkillWorkers.toJSON(e) : undefined);
    } else {
      obj.workers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSkillWorkers>, I>>(base?: I): PpicSkillWorkers {
    return PpicSkillWorkers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSkillWorkers>, I>>(object: I): PpicSkillWorkers {
    const message = createBasePpicSkillWorkers();
    message.workers = object.workers?.map((e) => PpicSkillWorkers.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemSubProcessSkill(): PpicRcemSubProcessSkill {
  return {
    rcemSubProcess: undefined,
    rcemSubProcessId: undefined,
    skill: undefined,
    skillId: undefined,
    masterJavaBaseModel: undefined,
  };
}

export const PpicRcemSubProcessSkill = {
  encode(message: PpicRcemSubProcessSkill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rcemSubProcess !== undefined) {
      PpicRcemSubProcess.encode(message.rcemSubProcess, writer.uint32(10).fork()).ldelim();
    }
    if (message.rcemSubProcessId !== undefined) {
      writer.uint32(16).uint64(message.rcemSubProcessId);
    }
    if (message.skill !== undefined) {
      PpicSkill.encode(message.skill, writer.uint32(26).fork()).ldelim();
    }
    if (message.skillId !== undefined) {
      writer.uint32(32).uint64(message.skillId);
    }
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemSubProcessSkill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemSubProcessSkill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rcemSubProcess = PpicRcemSubProcess.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rcemSubProcessId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.skill = PpicSkill.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.skillId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemSubProcessSkill {
    return {
      rcemSubProcess: isSet(object.rcemSubProcess) ? PpicRcemSubProcess.fromJSON(object.rcemSubProcess) : undefined,
      rcemSubProcessId: isSet(object.rcemSubProcessId) ? String(object.rcemSubProcessId) : undefined,
      skill: isSet(object.skill) ? PpicSkill.fromJSON(object.skill) : undefined,
      skillId: isSet(object.skillId) ? String(object.skillId) : undefined,
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: PpicRcemSubProcessSkill): unknown {
    const obj: any = {};
    message.rcemSubProcess !== undefined &&
      (obj.rcemSubProcess = message.rcemSubProcess ? PpicRcemSubProcess.toJSON(message.rcemSubProcess) : undefined);
    message.rcemSubProcessId !== undefined && (obj.rcemSubProcessId = message.rcemSubProcessId);
    message.skill !== undefined && (obj.skill = message.skill ? PpicSkill.toJSON(message.skill) : undefined);
    message.skillId !== undefined && (obj.skillId = message.skillId);
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemSubProcessSkill>, I>>(base?: I): PpicRcemSubProcessSkill {
    return PpicRcemSubProcessSkill.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemSubProcessSkill>, I>>(object: I): PpicRcemSubProcessSkill {
    const message = createBasePpicRcemSubProcessSkill();
    message.rcemSubProcess = (object.rcemSubProcess !== undefined && object.rcemSubProcess !== null)
      ? PpicRcemSubProcess.fromPartial(object.rcemSubProcess)
      : undefined;
    message.rcemSubProcessId = object.rcemSubProcessId ?? undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? PpicSkill.fromPartial(object.skill)
      : undefined;
    message.skillId = object.skillId ?? undefined;
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBasePpicRcemSubProcessSkills(): PpicRcemSubProcessSkills {
  return { rcemSubProcessSkills: [] };
}

export const PpicRcemSubProcessSkills = {
  encode(message: PpicRcemSubProcessSkills, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rcemSubProcessSkills) {
      PpicRcemSubProcessSkill.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemSubProcessSkills {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemSubProcessSkills();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rcemSubProcessSkills.push(PpicRcemSubProcessSkill.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemSubProcessSkills {
    return {
      rcemSubProcessSkills: Array.isArray(object?.rcemSubProcessSkills)
        ? object.rcemSubProcessSkills.map((e: any) => PpicRcemSubProcessSkill.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicRcemSubProcessSkills): unknown {
    const obj: any = {};
    if (message.rcemSubProcessSkills) {
      obj.rcemSubProcessSkills = message.rcemSubProcessSkills.map((e) =>
        e ? PpicRcemSubProcessSkill.toJSON(e) : undefined
      );
    } else {
      obj.rcemSubProcessSkills = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemSubProcessSkills>, I>>(base?: I): PpicRcemSubProcessSkills {
    return PpicRcemSubProcessSkills.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemSubProcessSkills>, I>>(object: I): PpicRcemSubProcessSkills {
    const message = createBasePpicRcemSubProcessSkills();
    message.rcemSubProcessSkills = object.rcemSubProcessSkills?.map((e) => PpicRcemSubProcessSkill.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBasePpicRcemDepartment(): PpicRcemDepartment {
  return { masterJavaBaseModel: undefined, name: undefined, processes: [] };
}

export const PpicRcemDepartment = {
  encode(message: PpicRcemDepartment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.processes) {
      PpicRcemProcess.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemDepartment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemDepartment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.processes.push(PpicRcemProcess.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemDepartment {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      processes: Array.isArray(object?.processes) ? object.processes.map((e: any) => PpicRcemProcess.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicRcemDepartment): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.processes) {
      obj.processes = message.processes.map((e) => e ? PpicRcemProcess.toJSON(e) : undefined);
    } else {
      obj.processes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemDepartment>, I>>(base?: I): PpicRcemDepartment {
    return PpicRcemDepartment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemDepartment>, I>>(object: I): PpicRcemDepartment {
    const message = createBasePpicRcemDepartment();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.processes = object.processes?.map((e) => PpicRcemProcess.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemDepartments(): PpicRcemDepartments {
  return { departments: [] };
}

export const PpicRcemDepartments = {
  encode(message: PpicRcemDepartments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.departments) {
      PpicRcemDepartment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemDepartments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemDepartments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.departments.push(PpicRcemDepartment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemDepartments {
    return {
      departments: Array.isArray(object?.departments)
        ? object.departments.map((e: any) => PpicRcemDepartment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicRcemDepartments): unknown {
    const obj: any = {};
    if (message.departments) {
      obj.departments = message.departments.map((e) => e ? PpicRcemDepartment.toJSON(e) : undefined);
    } else {
      obj.departments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemDepartments>, I>>(base?: I): PpicRcemDepartments {
    return PpicRcemDepartments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemDepartments>, I>>(object: I): PpicRcemDepartments {
    const message = createBasePpicRcemDepartments();
    message.departments = object.departments?.map((e) => PpicRcemDepartment.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemProcess(): PpicRcemProcess {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    subProcesses: [],
    extDepartmentId: undefined,
    department: undefined,
  };
}

export const PpicRcemProcess = {
  encode(message: PpicRcemProcess, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.subProcesses) {
      PpicRcemSubProcess.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(32).uint64(message.extDepartmentId);
    }
    if (message.department !== undefined) {
      PpicRcemDepartment.encode(message.department, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemProcess {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemProcess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subProcesses.push(PpicRcemSubProcess.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.department = PpicRcemDepartment.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemProcess {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      subProcesses: Array.isArray(object?.subProcesses)
        ? object.subProcesses.map((e: any) => PpicRcemSubProcess.fromJSON(e))
        : [],
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      department: isSet(object.department) ? PpicRcemDepartment.fromJSON(object.department) : undefined,
    };
  },

  toJSON(message: PpicRcemProcess): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.subProcesses) {
      obj.subProcesses = message.subProcesses.map((e) => e ? PpicRcemSubProcess.toJSON(e) : undefined);
    } else {
      obj.subProcesses = [];
    }
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.department !== undefined &&
      (obj.department = message.department ? PpicRcemDepartment.toJSON(message.department) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemProcess>, I>>(base?: I): PpicRcemProcess {
    return PpicRcemProcess.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemProcess>, I>>(object: I): PpicRcemProcess {
    const message = createBasePpicRcemProcess();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.subProcesses = object.subProcesses?.map((e) => PpicRcemSubProcess.fromPartial(e)) || [];
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.department = (object.department !== undefined && object.department !== null)
      ? PpicRcemDepartment.fromPartial(object.department)
      : undefined;
    return message;
  },
};

function createBasePpicRcemProcesses(): PpicRcemProcesses {
  return { processes: [] };
}

export const PpicRcemProcesses = {
  encode(message: PpicRcemProcesses, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.processes) {
      PpicRcemProcess.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemProcesses {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemProcesses();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processes.push(PpicRcemProcess.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemProcesses {
    return {
      processes: Array.isArray(object?.processes) ? object.processes.map((e: any) => PpicRcemProcess.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicRcemProcesses): unknown {
    const obj: any = {};
    if (message.processes) {
      obj.processes = message.processes.map((e) => e ? PpicRcemProcess.toJSON(e) : undefined);
    } else {
      obj.processes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemProcesses>, I>>(base?: I): PpicRcemProcesses {
    return PpicRcemProcesses.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemProcesses>, I>>(object: I): PpicRcemProcesses {
    const message = createBasePpicRcemProcesses();
    message.processes = object.processes?.map((e) => PpicRcemProcess.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemSubProcess(): PpicRcemSubProcess {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    description: undefined,
    ppicRcemProcess: undefined,
    ppicRcemProcessId: undefined,
    rcems: [],
    impact: undefined,
    likelihood: undefined,
    qualitativeRisk: undefined,
    supplierStr: undefined,
    inputStr: undefined,
    processStr: undefined,
    outputStr: undefined,
    customerStr: undefined,
    controlResponsibilityStr: undefined,
    frequencyOfControlApplicationStr: undefined,
    amStr: undefined,
    pdStr: undefined,
    rNrEStr: undefined,
    controlComponentStr: undefined,
    timeProcess: undefined,
    skill: undefined,
    skillId: undefined,
  };
}

export const PpicRcemSubProcess = {
  encode(message: PpicRcemSubProcess, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.ppicRcemProcess !== undefined) {
      PpicRcemProcess.encode(message.ppicRcemProcess, writer.uint32(34).fork()).ldelim();
    }
    if (message.ppicRcemProcessId !== undefined) {
      writer.uint32(40).uint64(message.ppicRcemProcessId);
    }
    for (const v of message.rcems) {
      PpicRcem.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.impact !== undefined) {
      writer.uint32(152).int32(message.impact);
    }
    if (message.likelihood !== undefined) {
      writer.uint32(160).int32(message.likelihood);
    }
    if (message.qualitativeRisk !== undefined) {
      writer.uint32(56).int32(message.qualitativeRisk);
    }
    if (message.supplierStr !== undefined) {
      writer.uint32(66).string(message.supplierStr);
    }
    if (message.inputStr !== undefined) {
      writer.uint32(74).string(message.inputStr);
    }
    if (message.processStr !== undefined) {
      writer.uint32(82).string(message.processStr);
    }
    if (message.outputStr !== undefined) {
      writer.uint32(90).string(message.outputStr);
    }
    if (message.customerStr !== undefined) {
      writer.uint32(98).string(message.customerStr);
    }
    if (message.controlResponsibilityStr !== undefined) {
      writer.uint32(106).string(message.controlResponsibilityStr);
    }
    if (message.frequencyOfControlApplicationStr !== undefined) {
      writer.uint32(114).string(message.frequencyOfControlApplicationStr);
    }
    if (message.amStr !== undefined) {
      writer.uint32(122).string(message.amStr);
    }
    if (message.pdStr !== undefined) {
      writer.uint32(130).string(message.pdStr);
    }
    if (message.rNrEStr !== undefined) {
      writer.uint32(138).string(message.rNrEStr);
    }
    if (message.controlComponentStr !== undefined) {
      writer.uint32(146).string(message.controlComponentStr);
    }
    if (message.timeProcess !== undefined) {
      writer.uint32(168).int32(message.timeProcess);
    }
    if (message.skill !== undefined) {
      PpicSkill.encode(message.skill, writer.uint32(178).fork()).ldelim();
    }
    if (message.skillId !== undefined) {
      writer.uint32(184).uint64(message.skillId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemSubProcess {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemSubProcess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ppicRcemProcess = PpicRcemProcess.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ppicRcemProcessId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.rcems.push(PpicRcem.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.impact = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.likelihood = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.qualitativeRisk = reader.int32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.supplierStr = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.inputStr = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.processStr = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.outputStr = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.customerStr = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.controlResponsibilityStr = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.frequencyOfControlApplicationStr = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.amStr = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.pdStr = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.rNrEStr = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.controlComponentStr = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.timeProcess = reader.int32();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.skill = PpicSkill.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.skillId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemSubProcess {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      ppicRcemProcess: isSet(object.ppicRcemProcess) ? PpicRcemProcess.fromJSON(object.ppicRcemProcess) : undefined,
      ppicRcemProcessId: isSet(object.ppicRcemProcessId) ? String(object.ppicRcemProcessId) : undefined,
      rcems: Array.isArray(object?.rcems) ? object.rcems.map((e: any) => PpicRcem.fromJSON(e)) : [],
      impact: isSet(object.impact) ? ppicRcemSubProcess_ImpactFromJSON(object.impact) : undefined,
      likelihood: isSet(object.likelihood) ? ppicRcemSubProcess_LikelihoodFromJSON(object.likelihood) : undefined,
      qualitativeRisk: isSet(object.qualitativeRisk) ? Number(object.qualitativeRisk) : undefined,
      supplierStr: isSet(object.supplierStr) ? String(object.supplierStr) : undefined,
      inputStr: isSet(object.inputStr) ? String(object.inputStr) : undefined,
      processStr: isSet(object.processStr) ? String(object.processStr) : undefined,
      outputStr: isSet(object.outputStr) ? String(object.outputStr) : undefined,
      customerStr: isSet(object.customerStr) ? String(object.customerStr) : undefined,
      controlResponsibilityStr: isSet(object.controlResponsibilityStr)
        ? String(object.controlResponsibilityStr)
        : undefined,
      frequencyOfControlApplicationStr: isSet(object.frequencyOfControlApplicationStr)
        ? String(object.frequencyOfControlApplicationStr)
        : undefined,
      amStr: isSet(object.amStr) ? String(object.amStr) : undefined,
      pdStr: isSet(object.pdStr) ? String(object.pdStr) : undefined,
      rNrEStr: isSet(object.rNrEStr) ? String(object.rNrEStr) : undefined,
      controlComponentStr: isSet(object.controlComponentStr) ? String(object.controlComponentStr) : undefined,
      timeProcess: isSet(object.timeProcess) ? Number(object.timeProcess) : undefined,
      skill: isSet(object.skill) ? PpicSkill.fromJSON(object.skill) : undefined,
      skillId: isSet(object.skillId) ? String(object.skillId) : undefined,
    };
  },

  toJSON(message: PpicRcemSubProcess): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.ppicRcemProcess !== undefined &&
      (obj.ppicRcemProcess = message.ppicRcemProcess ? PpicRcemProcess.toJSON(message.ppicRcemProcess) : undefined);
    message.ppicRcemProcessId !== undefined && (obj.ppicRcemProcessId = message.ppicRcemProcessId);
    if (message.rcems) {
      obj.rcems = message.rcems.map((e) => e ? PpicRcem.toJSON(e) : undefined);
    } else {
      obj.rcems = [];
    }
    message.impact !== undefined &&
      (obj.impact = message.impact !== undefined ? ppicRcemSubProcess_ImpactToJSON(message.impact) : undefined);
    message.likelihood !== undefined && (obj.likelihood = message.likelihood !== undefined
      ? ppicRcemSubProcess_LikelihoodToJSON(message.likelihood)
      : undefined);
    message.qualitativeRisk !== undefined && (obj.qualitativeRisk = Math.round(message.qualitativeRisk));
    message.supplierStr !== undefined && (obj.supplierStr = message.supplierStr);
    message.inputStr !== undefined && (obj.inputStr = message.inputStr);
    message.processStr !== undefined && (obj.processStr = message.processStr);
    message.outputStr !== undefined && (obj.outputStr = message.outputStr);
    message.customerStr !== undefined && (obj.customerStr = message.customerStr);
    message.controlResponsibilityStr !== undefined && (obj.controlResponsibilityStr = message.controlResponsibilityStr);
    message.frequencyOfControlApplicationStr !== undefined &&
      (obj.frequencyOfControlApplicationStr = message.frequencyOfControlApplicationStr);
    message.amStr !== undefined && (obj.amStr = message.amStr);
    message.pdStr !== undefined && (obj.pdStr = message.pdStr);
    message.rNrEStr !== undefined && (obj.rNrEStr = message.rNrEStr);
    message.controlComponentStr !== undefined && (obj.controlComponentStr = message.controlComponentStr);
    message.timeProcess !== undefined && (obj.timeProcess = Math.round(message.timeProcess));
    message.skill !== undefined && (obj.skill = message.skill ? PpicSkill.toJSON(message.skill) : undefined);
    message.skillId !== undefined && (obj.skillId = message.skillId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemSubProcess>, I>>(base?: I): PpicRcemSubProcess {
    return PpicRcemSubProcess.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemSubProcess>, I>>(object: I): PpicRcemSubProcess {
    const message = createBasePpicRcemSubProcess();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.description = object.description ?? undefined;
    message.ppicRcemProcess = (object.ppicRcemProcess !== undefined && object.ppicRcemProcess !== null)
      ? PpicRcemProcess.fromPartial(object.ppicRcemProcess)
      : undefined;
    message.ppicRcemProcessId = object.ppicRcemProcessId ?? undefined;
    message.rcems = object.rcems?.map((e) => PpicRcem.fromPartial(e)) || [];
    message.impact = object.impact ?? undefined;
    message.likelihood = object.likelihood ?? undefined;
    message.qualitativeRisk = object.qualitativeRisk ?? undefined;
    message.supplierStr = object.supplierStr ?? undefined;
    message.inputStr = object.inputStr ?? undefined;
    message.processStr = object.processStr ?? undefined;
    message.outputStr = object.outputStr ?? undefined;
    message.customerStr = object.customerStr ?? undefined;
    message.controlResponsibilityStr = object.controlResponsibilityStr ?? undefined;
    message.frequencyOfControlApplicationStr = object.frequencyOfControlApplicationStr ?? undefined;
    message.amStr = object.amStr ?? undefined;
    message.pdStr = object.pdStr ?? undefined;
    message.rNrEStr = object.rNrEStr ?? undefined;
    message.controlComponentStr = object.controlComponentStr ?? undefined;
    message.timeProcess = object.timeProcess ?? undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? PpicSkill.fromPartial(object.skill)
      : undefined;
    message.skillId = object.skillId ?? undefined;
    return message;
  },
};

function createBasePpicSkill(): PpicSkill {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    skillWorkers: [],
    pics: undefined,
    extDepartmentId: undefined,
  };
}

export const PpicSkill = {
  encode(message: PpicSkill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.skillWorkers) {
      PpicSkillWorker.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.pics !== undefined) {
      writer.uint32(32).int32(message.pics);
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(40).uint64(message.extDepartmentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSkill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSkill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.skillWorkers.push(PpicSkillWorker.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pics = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSkill {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      skillWorkers: Array.isArray(object?.skillWorkers)
        ? object.skillWorkers.map((e: any) => PpicSkillWorker.fromJSON(e))
        : [],
      pics: isSet(object.pics) ? Number(object.pics) : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
    };
  },

  toJSON(message: PpicSkill): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.skillWorkers) {
      obj.skillWorkers = message.skillWorkers.map((e) => e ? PpicSkillWorker.toJSON(e) : undefined);
    } else {
      obj.skillWorkers = [];
    }
    message.pics !== undefined && (obj.pics = Math.round(message.pics));
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSkill>, I>>(base?: I): PpicSkill {
    return PpicSkill.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSkill>, I>>(object: I): PpicSkill {
    const message = createBasePpicSkill();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.skillWorkers = object.skillWorkers?.map((e) => PpicSkillWorker.fromPartial(e)) || [];
    message.pics = object.pics ?? undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    return message;
  },
};

function createBasePpicSkills(): PpicSkills {
  return { skills: [] };
}

export const PpicSkills = {
  encode(message: PpicSkills, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.skills) {
      PpicSkill.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSkills {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSkills();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.skills.push(PpicSkill.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSkills {
    return { skills: Array.isArray(object?.skills) ? object.skills.map((e: any) => PpicSkill.fromJSON(e)) : [] };
  },

  toJSON(message: PpicSkills): unknown {
    const obj: any = {};
    if (message.skills) {
      obj.skills = message.skills.map((e) => e ? PpicSkill.toJSON(e) : undefined);
    } else {
      obj.skills = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSkills>, I>>(base?: I): PpicSkills {
    return PpicSkills.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSkills>, I>>(object: I): PpicSkills {
    const message = createBasePpicSkills();
    message.skills = object.skills?.map((e) => PpicSkill.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcemSubProcesses(): PpicRcemSubProcesses {
  return { subProcesses: [] };
}

export const PpicRcemSubProcesses = {
  encode(message: PpicRcemSubProcesses, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.subProcesses) {
      PpicRcemSubProcess.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcemSubProcesses {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcemSubProcesses();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.subProcesses.push(PpicRcemSubProcess.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcemSubProcesses {
    return {
      subProcesses: Array.isArray(object?.subProcesses)
        ? object.subProcesses.map((e: any) => PpicRcemSubProcess.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicRcemSubProcesses): unknown {
    const obj: any = {};
    if (message.subProcesses) {
      obj.subProcesses = message.subProcesses.map((e) => e ? PpicRcemSubProcess.toJSON(e) : undefined);
    } else {
      obj.subProcesses = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcemSubProcesses>, I>>(base?: I): PpicRcemSubProcesses {
    return PpicRcemSubProcesses.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcemSubProcesses>, I>>(object: I): PpicRcemSubProcesses {
    const message = createBasePpicRcemSubProcesses();
    message.subProcesses = object.subProcesses?.map((e) => PpicRcemSubProcess.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicRcem(): PpicRcem {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    subProcess: undefined,
    subProcessId: undefined,
    extDepartmentId: undefined,
  };
}

export const PpicRcem = {
  encode(message: PpicRcem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.subProcess !== undefined) {
      PpicRcemSubProcess.encode(message.subProcess, writer.uint32(26).fork()).ldelim();
    }
    if (message.subProcessId !== undefined) {
      writer.uint32(32).uint64(message.subProcessId);
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(40).uint64(message.extDepartmentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subProcess = PpicRcemSubProcess.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.subProcessId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      subProcess: isSet(object.subProcess) ? PpicRcemSubProcess.fromJSON(object.subProcess) : undefined,
      subProcessId: isSet(object.subProcessId) ? String(object.subProcessId) : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
    };
  },

  toJSON(message: PpicRcem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.subProcess !== undefined &&
      (obj.subProcess = message.subProcess ? PpicRcemSubProcess.toJSON(message.subProcess) : undefined);
    message.subProcessId !== undefined && (obj.subProcessId = message.subProcessId);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcem>, I>>(base?: I): PpicRcem {
    return PpicRcem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcem>, I>>(object: I): PpicRcem {
    const message = createBasePpicRcem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.subProcess = (object.subProcess !== undefined && object.subProcess !== null)
      ? PpicRcemSubProcess.fromPartial(object.subProcess)
      : undefined;
    message.subProcessId = object.subProcessId ?? undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    return message;
  },
};

function createBasePpicRcems(): PpicRcems {
  return { rcems: [] };
}

export const PpicRcems = {
  encode(message: PpicRcems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rcems) {
      PpicRcem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicRcems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicRcems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rcems.push(PpicRcem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicRcems {
    return { rcems: Array.isArray(object?.rcems) ? object.rcems.map((e: any) => PpicRcem.fromJSON(e)) : [] };
  },

  toJSON(message: PpicRcems): unknown {
    const obj: any = {};
    if (message.rcems) {
      obj.rcems = message.rcems.map((e) => e ? PpicRcem.toJSON(e) : undefined);
    } else {
      obj.rcems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicRcems>, I>>(base?: I): PpicRcems {
    return PpicRcems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicRcems>, I>>(object: I): PpicRcems {
    const message = createBasePpicRcems();
    message.rcems = object.rcems?.map((e) => PpicRcem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicSimplifiedProcessType(): PpicSimplifiedProcessType {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    machineId: undefined,
    extSpvId: undefined,
    extPicId: undefined,
    pics: [],
  };
}

export const PpicSimplifiedProcessType = {
  encode(message: PpicSimplifiedProcessType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.machineId !== undefined) {
      writer.uint32(24).uint64(message.machineId);
    }
    if (message.extSpvId !== undefined) {
      writer.uint32(32).uint64(message.extSpvId);
    }
    if (message.extPicId !== undefined) {
      writer.uint32(40).uint64(message.extPicId);
    }
    for (const v of message.pics) {
      PpicSimplifiedProcessTypePic.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSimplifiedProcessType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSimplifiedProcessType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.machineId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extSpvId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extPicId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pics.push(PpicSimplifiedProcessTypePic.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSimplifiedProcessType {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      machineId: isSet(object.machineId) ? String(object.machineId) : undefined,
      extSpvId: isSet(object.extSpvId) ? String(object.extSpvId) : undefined,
      extPicId: isSet(object.extPicId) ? String(object.extPicId) : undefined,
      pics: Array.isArray(object?.pics) ? object.pics.map((e: any) => PpicSimplifiedProcessTypePic.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicSimplifiedProcessType): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.machineId !== undefined && (obj.machineId = message.machineId);
    message.extSpvId !== undefined && (obj.extSpvId = message.extSpvId);
    message.extPicId !== undefined && (obj.extPicId = message.extPicId);
    if (message.pics) {
      obj.pics = message.pics.map((e) => e ? PpicSimplifiedProcessTypePic.toJSON(e) : undefined);
    } else {
      obj.pics = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSimplifiedProcessType>, I>>(base?: I): PpicSimplifiedProcessType {
    return PpicSimplifiedProcessType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSimplifiedProcessType>, I>>(object: I): PpicSimplifiedProcessType {
    const message = createBasePpicSimplifiedProcessType();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.machineId = object.machineId ?? undefined;
    message.extSpvId = object.extSpvId ?? undefined;
    message.extPicId = object.extPicId ?? undefined;
    message.pics = object.pics?.map((e) => PpicSimplifiedProcessTypePic.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicSimplifiedProcessTypePic(): PpicSimplifiedProcessTypePic {
  return { masterJavaBaseModel: undefined, extUserId: undefined, simplifiedProcessTypeId: undefined };
}

export const PpicSimplifiedProcessTypePic = {
  encode(message: PpicSimplifiedProcessTypePic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.simplifiedProcessTypeId !== undefined) {
      writer.uint32(24).uint64(message.simplifiedProcessTypeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSimplifiedProcessTypePic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSimplifiedProcessTypePic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.simplifiedProcessTypeId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSimplifiedProcessTypePic {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      simplifiedProcessTypeId: isSet(object.simplifiedProcessTypeId)
        ? String(object.simplifiedProcessTypeId)
        : undefined,
    };
  },

  toJSON(message: PpicSimplifiedProcessTypePic): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.simplifiedProcessTypeId !== undefined && (obj.simplifiedProcessTypeId = message.simplifiedProcessTypeId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSimplifiedProcessTypePic>, I>>(base?: I): PpicSimplifiedProcessTypePic {
    return PpicSimplifiedProcessTypePic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSimplifiedProcessTypePic>, I>>(object: I): PpicSimplifiedProcessTypePic {
    const message = createBasePpicSimplifiedProcessTypePic();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.simplifiedProcessTypeId = object.simplifiedProcessTypeId ?? undefined;
    return message;
  },
};

function createBasePpicAutoMRWarehouseFilters(): PpicAutoMRWarehouseFilters {
  return { filters: [] };
}

export const PpicAutoMRWarehouseFilters = {
  encode(message: PpicAutoMRWarehouseFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.filters) {
      PpicAutoMRWarehouseFilter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicAutoMRWarehouseFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicAutoMRWarehouseFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filters.push(PpicAutoMRWarehouseFilter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicAutoMRWarehouseFilters {
    return {
      filters: Array.isArray(object?.filters)
        ? object.filters.map((e: any) => PpicAutoMRWarehouseFilter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicAutoMRWarehouseFilters): unknown {
    const obj: any = {};
    if (message.filters) {
      obj.filters = message.filters.map((e) => e ? PpicAutoMRWarehouseFilter.toJSON(e) : undefined);
    } else {
      obj.filters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicAutoMRWarehouseFilters>, I>>(base?: I): PpicAutoMRWarehouseFilters {
    return PpicAutoMRWarehouseFilters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicAutoMRWarehouseFilters>, I>>(object: I): PpicAutoMRWarehouseFilters {
    const message = createBasePpicAutoMRWarehouseFilters();
    message.filters = object.filters?.map((e) => PpicAutoMRWarehouseFilter.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicAutoMRWarehouseFilter(): PpicAutoMRWarehouseFilter {
  return { masterJavaBaseModel: undefined, warehouseId: undefined };
}

export const PpicAutoMRWarehouseFilter = {
  encode(message: PpicAutoMRWarehouseFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.warehouseId !== undefined) {
      writer.uint32(16).uint64(message.warehouseId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicAutoMRWarehouseFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicAutoMRWarehouseFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.warehouseId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicAutoMRWarehouseFilter {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      warehouseId: isSet(object.warehouseId) ? String(object.warehouseId) : undefined,
    };
  },

  toJSON(message: PpicAutoMRWarehouseFilter): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.warehouseId !== undefined && (obj.warehouseId = message.warehouseId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicAutoMRWarehouseFilter>, I>>(base?: I): PpicAutoMRWarehouseFilter {
    return PpicAutoMRWarehouseFilter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicAutoMRWarehouseFilter>, I>>(object: I): PpicAutoMRWarehouseFilter {
    const message = createBasePpicAutoMRWarehouseFilter();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.warehouseId = object.warehouseId ?? undefined;
    return message;
  },
};

function createBasePpicSimplifiedProcessTypes(): PpicSimplifiedProcessTypes {
  return { processTypes: [] };
}

export const PpicSimplifiedProcessTypes = {
  encode(message: PpicSimplifiedProcessTypes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.processTypes) {
      PpicSimplifiedProcessType.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSimplifiedProcessTypes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSimplifiedProcessTypes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processTypes.push(PpicSimplifiedProcessType.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSimplifiedProcessTypes {
    return {
      processTypes: Array.isArray(object?.processTypes)
        ? object.processTypes.map((e: any) => PpicSimplifiedProcessType.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicSimplifiedProcessTypes): unknown {
    const obj: any = {};
    if (message.processTypes) {
      obj.processTypes = message.processTypes.map((e) => e ? PpicSimplifiedProcessType.toJSON(e) : undefined);
    } else {
      obj.processTypes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSimplifiedProcessTypes>, I>>(base?: I): PpicSimplifiedProcessTypes {
    return PpicSimplifiedProcessTypes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSimplifiedProcessTypes>, I>>(object: I): PpicSimplifiedProcessTypes {
    const message = createBasePpicSimplifiedProcessTypes();
    message.processTypes = object.processTypes?.map((e) => PpicSimplifiedProcessType.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMachineProgram(): PpicMachineProgram {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    timeProcessMins: undefined,
    panelCode: undefined,
    panelCodeId: undefined,
    qtyRun: undefined,
    machineProgramPics: [],
    machineProgramMachines: [],
    integrationModuleId: undefined,
  };
}

export const PpicMachineProgram = {
  encode(message: PpicMachineProgram, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.timeProcessMins !== undefined) {
      writer.uint32(24).int32(message.timeProcessMins);
    }
    if (message.panelCode !== undefined) {
      PpicPanelCode.encode(message.panelCode, writer.uint32(34).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(40).uint64(message.panelCodeId);
    }
    if (message.qtyRun !== undefined) {
      writer.uint32(48).int32(message.qtyRun);
    }
    for (const v of message.machineProgramPics) {
      PpicMachineProgramPic.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.machineProgramMachines) {
      PpicMachineProgramMachine.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.integrationModuleId !== undefined) {
      writer.uint32(72).uint64(message.integrationModuleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachineProgram {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachineProgram();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timeProcessMins = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.panelCode = PpicPanelCode.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.qtyRun = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.machineProgramPics.push(PpicMachineProgramPic.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.machineProgramMachines.push(PpicMachineProgramMachine.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.integrationModuleId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachineProgram {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      timeProcessMins: isSet(object.timeProcessMins) ? Number(object.timeProcessMins) : undefined,
      panelCode: isSet(object.panelCode) ? PpicPanelCode.fromJSON(object.panelCode) : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      qtyRun: isSet(object.qtyRun) ? Number(object.qtyRun) : undefined,
      machineProgramPics: Array.isArray(object?.machineProgramPics)
        ? object.machineProgramPics.map((e: any) => PpicMachineProgramPic.fromJSON(e))
        : [],
      machineProgramMachines: Array.isArray(object?.machineProgramMachines)
        ? object.machineProgramMachines.map((e: any) => PpicMachineProgramMachine.fromJSON(e))
        : [],
      integrationModuleId: isSet(object.integrationModuleId) ? String(object.integrationModuleId) : undefined,
    };
  },

  toJSON(message: PpicMachineProgram): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.timeProcessMins !== undefined && (obj.timeProcessMins = Math.round(message.timeProcessMins));
    message.panelCode !== undefined &&
      (obj.panelCode = message.panelCode ? PpicPanelCode.toJSON(message.panelCode) : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.qtyRun !== undefined && (obj.qtyRun = Math.round(message.qtyRun));
    if (message.machineProgramPics) {
      obj.machineProgramPics = message.machineProgramPics.map((e) => e ? PpicMachineProgramPic.toJSON(e) : undefined);
    } else {
      obj.machineProgramPics = [];
    }
    if (message.machineProgramMachines) {
      obj.machineProgramMachines = message.machineProgramMachines.map((e) =>
        e ? PpicMachineProgramMachine.toJSON(e) : undefined
      );
    } else {
      obj.machineProgramMachines = [];
    }
    message.integrationModuleId !== undefined && (obj.integrationModuleId = message.integrationModuleId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachineProgram>, I>>(base?: I): PpicMachineProgram {
    return PpicMachineProgram.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachineProgram>, I>>(object: I): PpicMachineProgram {
    const message = createBasePpicMachineProgram();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.timeProcessMins = object.timeProcessMins ?? undefined;
    message.panelCode = (object.panelCode !== undefined && object.panelCode !== null)
      ? PpicPanelCode.fromPartial(object.panelCode)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.qtyRun = object.qtyRun ?? undefined;
    message.machineProgramPics = object.machineProgramPics?.map((e) => PpicMachineProgramPic.fromPartial(e)) || [];
    message.machineProgramMachines =
      object.machineProgramMachines?.map((e) => PpicMachineProgramMachine.fromPartial(e)) || [];
    message.integrationModuleId = object.integrationModuleId ?? undefined;
    return message;
  },
};

function createBasePpicMachineProgramMachine(): PpicMachineProgramMachine {
  return {
    masterJavaBaseModel: undefined,
    machineId: undefined,
    machineProgramId: undefined,
    simplifiedProcessTypeId: undefined,
    extSupervisorId: undefined,
  };
}

export const PpicMachineProgramMachine = {
  encode(message: PpicMachineProgramMachine, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.machineId !== undefined) {
      writer.uint32(16).uint64(message.machineId);
    }
    if (message.machineProgramId !== undefined) {
      writer.uint32(24).uint64(message.machineProgramId);
    }
    if (message.simplifiedProcessTypeId !== undefined) {
      writer.uint32(32).uint64(message.simplifiedProcessTypeId);
    }
    if (message.extSupervisorId !== undefined) {
      writer.uint32(40).uint64(message.extSupervisorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachineProgramMachine {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachineProgramMachine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.machineId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.machineProgramId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.simplifiedProcessTypeId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extSupervisorId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachineProgramMachine {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      machineId: isSet(object.machineId) ? String(object.machineId) : undefined,
      machineProgramId: isSet(object.machineProgramId) ? String(object.machineProgramId) : undefined,
      simplifiedProcessTypeId: isSet(object.simplifiedProcessTypeId)
        ? String(object.simplifiedProcessTypeId)
        : undefined,
      extSupervisorId: isSet(object.extSupervisorId) ? String(object.extSupervisorId) : undefined,
    };
  },

  toJSON(message: PpicMachineProgramMachine): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.machineId !== undefined && (obj.machineId = message.machineId);
    message.machineProgramId !== undefined && (obj.machineProgramId = message.machineProgramId);
    message.simplifiedProcessTypeId !== undefined && (obj.simplifiedProcessTypeId = message.simplifiedProcessTypeId);
    message.extSupervisorId !== undefined && (obj.extSupervisorId = message.extSupervisorId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachineProgramMachine>, I>>(base?: I): PpicMachineProgramMachine {
    return PpicMachineProgramMachine.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachineProgramMachine>, I>>(object: I): PpicMachineProgramMachine {
    const message = createBasePpicMachineProgramMachine();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.machineId = object.machineId ?? undefined;
    message.machineProgramId = object.machineProgramId ?? undefined;
    message.simplifiedProcessTypeId = object.simplifiedProcessTypeId ?? undefined;
    message.extSupervisorId = object.extSupervisorId ?? undefined;
    return message;
  },
};

function createBasePpicMachineProgramMachines(): PpicMachineProgramMachines {
  return { machineProgramMachines: [] };
}

export const PpicMachineProgramMachines = {
  encode(message: PpicMachineProgramMachines, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.machineProgramMachines) {
      PpicMachineProgramMachine.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachineProgramMachines {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachineProgramMachines();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.machineProgramMachines.push(PpicMachineProgramMachine.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachineProgramMachines {
    return {
      machineProgramMachines: Array.isArray(object?.machineProgramMachines)
        ? object.machineProgramMachines.map((e: any) => PpicMachineProgramMachine.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicMachineProgramMachines): unknown {
    const obj: any = {};
    if (message.machineProgramMachines) {
      obj.machineProgramMachines = message.machineProgramMachines.map((e) =>
        e ? PpicMachineProgramMachine.toJSON(e) : undefined
      );
    } else {
      obj.machineProgramMachines = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachineProgramMachines>, I>>(base?: I): PpicMachineProgramMachines {
    return PpicMachineProgramMachines.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachineProgramMachines>, I>>(object: I): PpicMachineProgramMachines {
    const message = createBasePpicMachineProgramMachines();
    message.machineProgramMachines =
      object.machineProgramMachines?.map((e) => PpicMachineProgramMachine.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMachinePrograms(): PpicMachinePrograms {
  return { machinePrograms: [] };
}

export const PpicMachinePrograms = {
  encode(message: PpicMachinePrograms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.machinePrograms) {
      PpicMachineProgram.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachinePrograms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachinePrograms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.machinePrograms.push(PpicMachineProgram.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachinePrograms {
    return {
      machinePrograms: Array.isArray(object?.machinePrograms)
        ? object.machinePrograms.map((e: any) => PpicMachineProgram.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicMachinePrograms): unknown {
    const obj: any = {};
    if (message.machinePrograms) {
      obj.machinePrograms = message.machinePrograms.map((e) => e ? PpicMachineProgram.toJSON(e) : undefined);
    } else {
      obj.machinePrograms = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachinePrograms>, I>>(base?: I): PpicMachinePrograms {
    return PpicMachinePrograms.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachinePrograms>, I>>(object: I): PpicMachinePrograms {
    const message = createBasePpicMachinePrograms();
    message.machinePrograms = object.machinePrograms?.map((e) => PpicMachineProgram.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMachineProgramPic(): PpicMachineProgramPic {
  return { masterJavaBaseModel: undefined, extUserId: undefined, machineProgramId: undefined };
}

export const PpicMachineProgramPic = {
  encode(message: PpicMachineProgramPic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.machineProgramId !== undefined) {
      writer.uint32(24).uint64(message.machineProgramId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachineProgramPic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachineProgramPic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.machineProgramId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachineProgramPic {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      machineProgramId: isSet(object.machineProgramId) ? String(object.machineProgramId) : undefined,
    };
  },

  toJSON(message: PpicMachineProgramPic): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.machineProgramId !== undefined && (obj.machineProgramId = message.machineProgramId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachineProgramPic>, I>>(base?: I): PpicMachineProgramPic {
    return PpicMachineProgramPic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachineProgramPic>, I>>(object: I): PpicMachineProgramPic {
    const message = createBasePpicMachineProgramPic();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.machineProgramId = object.machineProgramId ?? undefined;
    return message;
  },
};

function createBasePpicMachineProgramPics(): PpicMachineProgramPics {
  return { machineProgramPics: [] };
}

export const PpicMachineProgramPics = {
  encode(message: PpicMachineProgramPics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.machineProgramPics) {
      PpicMachineProgramPic.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachineProgramPics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachineProgramPics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.machineProgramPics.push(PpicMachineProgramPic.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachineProgramPics {
    return {
      machineProgramPics: Array.isArray(object?.machineProgramPics)
        ? object.machineProgramPics.map((e: any) => PpicMachineProgramPic.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicMachineProgramPics): unknown {
    const obj: any = {};
    if (message.machineProgramPics) {
      obj.machineProgramPics = message.machineProgramPics.map((e) => e ? PpicMachineProgramPic.toJSON(e) : undefined);
    } else {
      obj.machineProgramPics = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachineProgramPics>, I>>(base?: I): PpicMachineProgramPics {
    return PpicMachineProgramPics.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachineProgramPics>, I>>(object: I): PpicMachineProgramPics {
    const message = createBasePpicMachineProgramPics();
    message.machineProgramPics = object.machineProgramPics?.map((e) => PpicMachineProgramPic.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicIntegrationModule(): PpicIntegrationModule {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    integrationModule: undefined,
    integrationModuleId: undefined,
    integrationModules: [],
    qty: undefined,
    integrationModuleType: undefined,
    tProcess: undefined,
    timeProcessMins: undefined,
    integration: undefined,
    integrationId: undefined,
    type: undefined,
    itemId: undefined,
  };
}

export const PpicIntegrationModule = {
  encode(message: PpicIntegrationModule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.integrationModule !== undefined) {
      PpicIntegrationModule.encode(message.integrationModule, writer.uint32(26).fork()).ldelim();
    }
    if (message.integrationModuleId !== undefined) {
      writer.uint32(32).uint64(message.integrationModuleId);
    }
    for (const v of message.integrationModules) {
      PpicIntegrationModule.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.qty !== undefined) {
      writer.uint32(48).int32(message.qty);
    }
    if (message.integrationModuleType !== undefined) {
      writer.uint32(58).string(message.integrationModuleType);
    }
    if (message.tProcess !== undefined) {
      writer.uint32(64).int32(message.tProcess);
    }
    if (message.timeProcessMins !== undefined) {
      writer.uint32(72).int32(message.timeProcessMins);
    }
    if (message.integration !== undefined) {
      PpicIntegration.encode(message.integration, writer.uint32(82).fork()).ldelim();
    }
    if (message.integrationId !== undefined) {
      writer.uint32(88).uint64(message.integrationId);
    }
    if (message.type !== undefined) {
      writer.uint32(98).string(message.type);
    }
    if (message.itemId !== undefined) {
      writer.uint32(104).uint64(message.itemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicIntegrationModule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicIntegrationModule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.integrationModule = PpicIntegrationModule.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.integrationModuleId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.integrationModules.push(PpicIntegrationModule.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.integrationModuleType = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.tProcess = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.timeProcessMins = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.integration = PpicIntegration.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.integrationId = longToString(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.type = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.itemId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicIntegrationModule {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      integrationModule: isSet(object.integrationModule)
        ? PpicIntegrationModule.fromJSON(object.integrationModule)
        : undefined,
      integrationModuleId: isSet(object.integrationModuleId) ? String(object.integrationModuleId) : undefined,
      integrationModules: Array.isArray(object?.integrationModules)
        ? object.integrationModules.map((e: any) => PpicIntegrationModule.fromJSON(e))
        : [],
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      integrationModuleType: isSet(object.integrationModuleType) ? String(object.integrationModuleType) : undefined,
      tProcess: isSet(object.tProcess) ? Number(object.tProcess) : undefined,
      timeProcessMins: isSet(object.timeProcessMins) ? Number(object.timeProcessMins) : undefined,
      integration: isSet(object.integration) ? PpicIntegration.fromJSON(object.integration) : undefined,
      integrationId: isSet(object.integrationId) ? String(object.integrationId) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      itemId: isSet(object.itemId) ? String(object.itemId) : undefined,
    };
  },

  toJSON(message: PpicIntegrationModule): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.integrationModule !== undefined && (obj.integrationModule = message.integrationModule
      ? PpicIntegrationModule.toJSON(message.integrationModule)
      : undefined);
    message.integrationModuleId !== undefined && (obj.integrationModuleId = message.integrationModuleId);
    if (message.integrationModules) {
      obj.integrationModules = message.integrationModules.map((e) => e ? PpicIntegrationModule.toJSON(e) : undefined);
    } else {
      obj.integrationModules = [];
    }
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.integrationModuleType !== undefined && (obj.integrationModuleType = message.integrationModuleType);
    message.tProcess !== undefined && (obj.tProcess = Math.round(message.tProcess));
    message.timeProcessMins !== undefined && (obj.timeProcessMins = Math.round(message.timeProcessMins));
    message.integration !== undefined &&
      (obj.integration = message.integration ? PpicIntegration.toJSON(message.integration) : undefined);
    message.integrationId !== undefined && (obj.integrationId = message.integrationId);
    message.type !== undefined && (obj.type = message.type);
    message.itemId !== undefined && (obj.itemId = message.itemId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicIntegrationModule>, I>>(base?: I): PpicIntegrationModule {
    return PpicIntegrationModule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicIntegrationModule>, I>>(object: I): PpicIntegrationModule {
    const message = createBasePpicIntegrationModule();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.integrationModule = (object.integrationModule !== undefined && object.integrationModule !== null)
      ? PpicIntegrationModule.fromPartial(object.integrationModule)
      : undefined;
    message.integrationModuleId = object.integrationModuleId ?? undefined;
    message.integrationModules = object.integrationModules?.map((e) => PpicIntegrationModule.fromPartial(e)) || [];
    message.qty = object.qty ?? undefined;
    message.integrationModuleType = object.integrationModuleType ?? undefined;
    message.tProcess = object.tProcess ?? undefined;
    message.timeProcessMins = object.timeProcessMins ?? undefined;
    message.integration = (object.integration !== undefined && object.integration !== null)
      ? PpicIntegration.fromPartial(object.integration)
      : undefined;
    message.integrationId = object.integrationId ?? undefined;
    message.type = object.type ?? undefined;
    message.itemId = object.itemId ?? undefined;
    return message;
  },
};

function createBasePpicHoliday(): PpicHoliday {
  return { masterJavaBaseModel: undefined, date: undefined, name: undefined, templateName: undefined };
}

export const PpicHoliday = {
  encode(message: PpicHoliday, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(18).string(message.date);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.templateName !== undefined) {
      writer.uint32(34).string(message.templateName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHoliday {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHoliday();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.date = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.templateName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHoliday {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      templateName: isSet(object.templateName) ? String(object.templateName) : undefined,
    };
  },

  toJSON(message: PpicHoliday): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.date !== undefined && (obj.date = message.date);
    message.name !== undefined && (obj.name = message.name);
    message.templateName !== undefined && (obj.templateName = message.templateName);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHoliday>, I>>(base?: I): PpicHoliday {
    return PpicHoliday.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHoliday>, I>>(object: I): PpicHoliday {
    const message = createBasePpicHoliday();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.date = object.date ?? undefined;
    message.name = object.name ?? undefined;
    message.templateName = object.templateName ?? undefined;
    return message;
  },
};

function createBasePpicHolidays(): PpicHolidays {
  return { holidays: [] };
}

export const PpicHolidays = {
  encode(message: PpicHolidays, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.holidays) {
      PpicHoliday.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHolidays {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHolidays();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.holidays.push(PpicHoliday.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHolidays {
    return {
      holidays: Array.isArray(object?.holidays) ? object.holidays.map((e: any) => PpicHoliday.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicHolidays): unknown {
    const obj: any = {};
    if (message.holidays) {
      obj.holidays = message.holidays.map((e) => e ? PpicHoliday.toJSON(e) : undefined);
    } else {
      obj.holidays = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHolidays>, I>>(base?: I): PpicHolidays {
    return PpicHolidays.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHolidays>, I>>(object: I): PpicHolidays {
    const message = createBasePpicHolidays();
    message.holidays = object.holidays?.map((e) => PpicHoliday.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicIntegration(): PpicIntegration {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const PpicIntegration = {
  encode(message: PpicIntegration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicIntegration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicIntegration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicIntegration {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: PpicIntegration): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicIntegration>, I>>(base?: I): PpicIntegration {
    return PpicIntegration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicIntegration>, I>>(object: I): PpicIntegration {
    const message = createBasePpicIntegration();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBasePpicHandover(): PpicHandover {
  return {
    masterJavaBaseModel: undefined,
    items: [],
    date: undefined,
    status: undefined,
    type: undefined,
    handoverByHrId: undefined,
    handoverBy: undefined,
    remark: undefined,
    panelCodeSerialNumberId: undefined,
    extJobId: undefined,
    handoverNotifyUsers: [],
    materialInstallationItems: [],
    isMaterialInstallation: undefined,
    extHandoverBomId: undefined,
    materialInstallationItemId: undefined,
    jobName: undefined,
    extPurchaseOrderId: undefined,
  };
}

export const PpicHandover = {
  encode(message: PpicHandover, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.items) {
      PpicHandoverItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(26).string(message.date);
    }
    if (message.status !== undefined) {
      writer.uint32(32).int32(message.status);
    }
    if (message.type !== undefined) {
      writer.uint32(40).int32(message.type);
    }
    if (message.handoverByHrId !== undefined) {
      writer.uint32(48).uint64(message.handoverByHrId);
    }
    if (message.handoverBy !== undefined) {
      writer.uint32(58).string(message.handoverBy);
    }
    if (message.remark !== undefined) {
      writer.uint32(66).string(message.remark);
    }
    if (message.panelCodeSerialNumberId !== undefined) {
      writer.uint32(72).uint64(message.panelCodeSerialNumberId);
    }
    if (message.extJobId !== undefined) {
      writer.uint32(80).uint64(message.extJobId);
    }
    for (const v of message.handoverNotifyUsers) {
      PpicHandoverNotifyUser.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.materialInstallationItems) {
      PpicHandoverMaterialInstallationItem.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.isMaterialInstallation !== undefined) {
      writer.uint32(104).bool(message.isMaterialInstallation);
    }
    if (message.extHandoverBomId !== undefined) {
      writer.uint32(112).uint64(message.extHandoverBomId);
    }
    if (message.materialInstallationItemId !== undefined) {
      writer.uint32(256).uint64(message.materialInstallationItemId);
    }
    if (message.jobName !== undefined) {
      writer.uint32(266).string(message.jobName);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(272).uint64(message.extPurchaseOrderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandover {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandover();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.items.push(PpicHandoverItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.date = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.handoverByHrId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.handoverBy = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.panelCodeSerialNumberId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.extJobId = longToString(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.handoverNotifyUsers.push(PpicHandoverNotifyUser.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.materialInstallationItems.push(PpicHandoverMaterialInstallationItem.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isMaterialInstallation = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.extHandoverBomId = longToString(reader.uint64() as Long);
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.materialInstallationItemId = longToString(reader.uint64() as Long);
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.jobName = reader.string();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandover {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => PpicHandoverItem.fromJSON(e)) : [],
      date: isSet(object.date) ? String(object.date) : undefined,
      status: isSet(object.status) ? ppicHandover_StatusFromJSON(object.status) : undefined,
      type: isSet(object.type) ? ppicHandoverTypeFromJSON(object.type) : undefined,
      handoverByHrId: isSet(object.handoverByHrId) ? String(object.handoverByHrId) : undefined,
      handoverBy: isSet(object.handoverBy) ? String(object.handoverBy) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      panelCodeSerialNumberId: isSet(object.panelCodeSerialNumberId)
        ? String(object.panelCodeSerialNumberId)
        : undefined,
      extJobId: isSet(object.extJobId) ? String(object.extJobId) : undefined,
      handoverNotifyUsers: Array.isArray(object?.handoverNotifyUsers)
        ? object.handoverNotifyUsers.map((e: any) => PpicHandoverNotifyUser.fromJSON(e))
        : [],
      materialInstallationItems: Array.isArray(object?.materialInstallationItems)
        ? object.materialInstallationItems.map((e: any) => PpicHandoverMaterialInstallationItem.fromJSON(e))
        : [],
      isMaterialInstallation: isSet(object.isMaterialInstallation) ? Boolean(object.isMaterialInstallation) : undefined,
      extHandoverBomId: isSet(object.extHandoverBomId) ? String(object.extHandoverBomId) : undefined,
      materialInstallationItemId: isSet(object.materialInstallationItemId)
        ? String(object.materialInstallationItemId)
        : undefined,
      jobName: isSet(object.jobName) ? String(object.jobName) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
    };
  },

  toJSON(message: PpicHandover): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicHandoverItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.date !== undefined && (obj.date = message.date);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? ppicHandover_StatusToJSON(message.status) : undefined);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? ppicHandoverTypeToJSON(message.type) : undefined);
    message.handoverByHrId !== undefined && (obj.handoverByHrId = message.handoverByHrId);
    message.handoverBy !== undefined && (obj.handoverBy = message.handoverBy);
    message.remark !== undefined && (obj.remark = message.remark);
    message.panelCodeSerialNumberId !== undefined && (obj.panelCodeSerialNumberId = message.panelCodeSerialNumberId);
    message.extJobId !== undefined && (obj.extJobId = message.extJobId);
    if (message.handoverNotifyUsers) {
      obj.handoverNotifyUsers = message.handoverNotifyUsers.map((e) =>
        e ? PpicHandoverNotifyUser.toJSON(e) : undefined
      );
    } else {
      obj.handoverNotifyUsers = [];
    }
    if (message.materialInstallationItems) {
      obj.materialInstallationItems = message.materialInstallationItems.map((e) =>
        e ? PpicHandoverMaterialInstallationItem.toJSON(e) : undefined
      );
    } else {
      obj.materialInstallationItems = [];
    }
    message.isMaterialInstallation !== undefined && (obj.isMaterialInstallation = message.isMaterialInstallation);
    message.extHandoverBomId !== undefined && (obj.extHandoverBomId = message.extHandoverBomId);
    message.materialInstallationItemId !== undefined &&
      (obj.materialInstallationItemId = message.materialInstallationItemId);
    message.jobName !== undefined && (obj.jobName = message.jobName);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandover>, I>>(base?: I): PpicHandover {
    return PpicHandover.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandover>, I>>(object: I): PpicHandover {
    const message = createBasePpicHandover();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.items = object.items?.map((e) => PpicHandoverItem.fromPartial(e)) || [];
    message.date = object.date ?? undefined;
    message.status = object.status ?? undefined;
    message.type = object.type ?? undefined;
    message.handoverByHrId = object.handoverByHrId ?? undefined;
    message.handoverBy = object.handoverBy ?? undefined;
    message.remark = object.remark ?? undefined;
    message.panelCodeSerialNumberId = object.panelCodeSerialNumberId ?? undefined;
    message.extJobId = object.extJobId ?? undefined;
    message.handoverNotifyUsers = object.handoverNotifyUsers?.map((e) => PpicHandoverNotifyUser.fromPartial(e)) || [];
    message.materialInstallationItems =
      object.materialInstallationItems?.map((e) => PpicHandoverMaterialInstallationItem.fromPartial(e)) || [];
    message.isMaterialInstallation = object.isMaterialInstallation ?? undefined;
    message.extHandoverBomId = object.extHandoverBomId ?? undefined;
    message.materialInstallationItemId = object.materialInstallationItemId ?? undefined;
    message.jobName = object.jobName ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    return message;
  },
};

function createBasePpicHandoverMaterialInstallationItem(): PpicHandoverMaterialInstallationItem {
  return { masterJavaBaseModel: undefined, extItemId: undefined, qty: undefined, handoverId: undefined };
}

export const PpicHandoverMaterialInstallationItem = {
  encode(message: PpicHandoverMaterialInstallationItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extItemId !== undefined) {
      writer.uint32(16).uint64(message.extItemId);
    }
    if (message.qty !== undefined) {
      writer.uint32(25).double(message.qty);
    }
    if (message.handoverId !== undefined) {
      writer.uint32(32).uint64(message.handoverId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandoverMaterialInstallationItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandoverMaterialInstallationItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.qty = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.handoverId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandoverMaterialInstallationItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      handoverId: isSet(object.handoverId) ? String(object.handoverId) : undefined,
    };
  },

  toJSON(message: PpicHandoverMaterialInstallationItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.qty !== undefined && (obj.qty = message.qty);
    message.handoverId !== undefined && (obj.handoverId = message.handoverId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandoverMaterialInstallationItem>, I>>(
    base?: I,
  ): PpicHandoverMaterialInstallationItem {
    return PpicHandoverMaterialInstallationItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandoverMaterialInstallationItem>, I>>(
    object: I,
  ): PpicHandoverMaterialInstallationItem {
    const message = createBasePpicHandoverMaterialInstallationItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.qty = object.qty ?? undefined;
    message.handoverId = object.handoverId ?? undefined;
    return message;
  },
};

function createBasePpicHandoverNotifyUser(): PpicHandoverNotifyUser {
  return { masterJavaBaseModel: undefined, extUserId: undefined, handoverId: undefined };
}

export const PpicHandoverNotifyUser = {
  encode(message: PpicHandoverNotifyUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.handoverId !== undefined) {
      writer.uint32(24).uint64(message.handoverId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandoverNotifyUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandoverNotifyUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.handoverId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandoverNotifyUser {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      handoverId: isSet(object.handoverId) ? String(object.handoverId) : undefined,
    };
  },

  toJSON(message: PpicHandoverNotifyUser): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.handoverId !== undefined && (obj.handoverId = message.handoverId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandoverNotifyUser>, I>>(base?: I): PpicHandoverNotifyUser {
    return PpicHandoverNotifyUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandoverNotifyUser>, I>>(object: I): PpicHandoverNotifyUser {
    const message = createBasePpicHandoverNotifyUser();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.handoverId = object.handoverId ?? undefined;
    return message;
  },
};

function createBasePpicHandoverStatusBody(): PpicHandoverStatusBody {
  return { handoverId: undefined, status: undefined };
}

export const PpicHandoverStatusBody = {
  encode(message: PpicHandoverStatusBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.handoverId !== undefined) {
      writer.uint32(16).uint64(message.handoverId);
    }
    if (message.status !== undefined) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandoverStatusBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandoverStatusBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.handoverId = longToString(reader.uint64() as Long);
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandoverStatusBody {
    return {
      handoverId: isSet(object.handoverId) ? String(object.handoverId) : undefined,
      status: isSet(object.status) ? ppicHandover_StatusFromJSON(object.status) : undefined,
    };
  },

  toJSON(message: PpicHandoverStatusBody): unknown {
    const obj: any = {};
    message.handoverId !== undefined && (obj.handoverId = message.handoverId);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? ppicHandover_StatusToJSON(message.status) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandoverStatusBody>, I>>(base?: I): PpicHandoverStatusBody {
    return PpicHandoverStatusBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandoverStatusBody>, I>>(object: I): PpicHandoverStatusBody {
    const message = createBasePpicHandoverStatusBody();
    message.handoverId = object.handoverId ?? undefined;
    message.status = object.status ?? undefined;
    return message;
  },
};

function createBasePpicHandovers(): PpicHandovers {
  return { handovers: [] };
}

export const PpicHandovers = {
  encode(message: PpicHandovers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.handovers) {
      PpicHandover.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandovers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandovers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.handovers.push(PpicHandover.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandovers {
    return {
      handovers: Array.isArray(object?.handovers) ? object.handovers.map((e: any) => PpicHandover.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicHandovers): unknown {
    const obj: any = {};
    if (message.handovers) {
      obj.handovers = message.handovers.map((e) => e ? PpicHandover.toJSON(e) : undefined);
    } else {
      obj.handovers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandovers>, I>>(base?: I): PpicHandovers {
    return PpicHandovers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandovers>, I>>(object: I): PpicHandovers {
    const message = createBasePpicHandovers();
    message.handovers = object.handovers?.map((e) => PpicHandover.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicHandoverUpdateStatusBody(): PpicHandoverUpdateStatusBody {
  return {
    id: undefined,
    qaStatus: undefined,
    qaUserId: undefined,
    qaUserCheckedTimestamp: undefined,
    qaId: undefined,
    qcProgress: undefined,
    whStatus: undefined,
    whUserId: undefined,
    whUserCheckedTimestamp: undefined,
  };
}

export const PpicHandoverUpdateStatusBody = {
  encode(message: PpicHandoverUpdateStatusBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.qaStatus !== undefined) {
      writer.uint32(16).int32(message.qaStatus);
    }
    if (message.qaUserId !== undefined) {
      writer.uint32(24).uint64(message.qaUserId);
    }
    if (message.qaUserCheckedTimestamp !== undefined) {
      writer.uint32(34).string(message.qaUserCheckedTimestamp);
    }
    if (message.qaId !== undefined) {
      writer.uint32(40).uint64(message.qaId);
    }
    if (message.qcProgress !== undefined) {
      writer.uint32(48).bool(message.qcProgress);
    }
    if (message.whStatus !== undefined) {
      writer.uint32(56).int32(message.whStatus);
    }
    if (message.whUserId !== undefined) {
      writer.uint32(64).uint64(message.whUserId);
    }
    if (message.whUserCheckedTimestamp !== undefined) {
      writer.uint32(74).string(message.whUserCheckedTimestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandoverUpdateStatusBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandoverUpdateStatusBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.qaStatus = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.qaUserId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.qaUserCheckedTimestamp = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.qaId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.qcProgress = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.whStatus = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.whUserId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.whUserCheckedTimestamp = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandoverUpdateStatusBody {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      qaStatus: isSet(object.qaStatus) ? handoverQAStatusFromJSON(object.qaStatus) : undefined,
      qaUserId: isSet(object.qaUserId) ? String(object.qaUserId) : undefined,
      qaUserCheckedTimestamp: isSet(object.qaUserCheckedTimestamp) ? String(object.qaUserCheckedTimestamp) : undefined,
      qaId: isSet(object.qaId) ? String(object.qaId) : undefined,
      qcProgress: isSet(object.qcProgress) ? Boolean(object.qcProgress) : undefined,
      whStatus: isSet(object.whStatus) ? handoverQAStatusFromJSON(object.whStatus) : undefined,
      whUserId: isSet(object.whUserId) ? String(object.whUserId) : undefined,
      whUserCheckedTimestamp: isSet(object.whUserCheckedTimestamp) ? String(object.whUserCheckedTimestamp) : undefined,
    };
  },

  toJSON(message: PpicHandoverUpdateStatusBody): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.qaStatus !== undefined &&
      (obj.qaStatus = message.qaStatus !== undefined ? handoverQAStatusToJSON(message.qaStatus) : undefined);
    message.qaUserId !== undefined && (obj.qaUserId = message.qaUserId);
    message.qaUserCheckedTimestamp !== undefined && (obj.qaUserCheckedTimestamp = message.qaUserCheckedTimestamp);
    message.qaId !== undefined && (obj.qaId = message.qaId);
    message.qcProgress !== undefined && (obj.qcProgress = message.qcProgress);
    message.whStatus !== undefined &&
      (obj.whStatus = message.whStatus !== undefined ? handoverQAStatusToJSON(message.whStatus) : undefined);
    message.whUserId !== undefined && (obj.whUserId = message.whUserId);
    message.whUserCheckedTimestamp !== undefined && (obj.whUserCheckedTimestamp = message.whUserCheckedTimestamp);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandoverUpdateStatusBody>, I>>(base?: I): PpicHandoverUpdateStatusBody {
    return PpicHandoverUpdateStatusBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandoverUpdateStatusBody>, I>>(object: I): PpicHandoverUpdateStatusBody {
    const message = createBasePpicHandoverUpdateStatusBody();
    message.id = object.id ?? undefined;
    message.qaStatus = object.qaStatus ?? undefined;
    message.qaUserId = object.qaUserId ?? undefined;
    message.qaUserCheckedTimestamp = object.qaUserCheckedTimestamp ?? undefined;
    message.qaId = object.qaId ?? undefined;
    message.qcProgress = object.qcProgress ?? undefined;
    message.whStatus = object.whStatus ?? undefined;
    message.whUserId = object.whUserId ?? undefined;
    message.whUserCheckedTimestamp = object.whUserCheckedTimestamp ?? undefined;
    return message;
  },
};

function createBasePpicHandoverItem(): PpicHandoverItem {
  return {
    masterJavaBaseModel: undefined,
    panelCodeId: undefined,
    qty: undefined,
    extItemId: undefined,
    productId: undefined,
    remark: undefined,
    panelCodeSerialNumberId: undefined,
    jobId: undefined,
    poId: undefined,
    materialRequestId: undefined,
    panelCodeSerialNumberFullName: undefined,
    panelCodeSerialNumberManualId: undefined,
    qaStatus: undefined,
    qaUserId: undefined,
    qaUserCheckedTimestamp: undefined,
    qaId: undefined,
    qaSendTimestamp: undefined,
    qaRemark: undefined,
    qcProgress: undefined,
    whUserId: undefined,
    whUserCheckedTimestamp: undefined,
    whStatus: undefined,
    whSendTimestamp: undefined,
    whRemark: undefined,
    pics: [],
    productionRemark: undefined,
    panelCodePartNumber: undefined,
    panelCodeExtItemId: undefined,
    panelCodeExtItemPrice: undefined,
    partNum: undefined,
    partName: undefined,
    partDesc: undefined,
    mfr: undefined,
    defaultUm: undefined,
    snapshotPriceIdr: undefined,
    extPurchaseOrderId: undefined,
  };
}

export const PpicHandoverItem = {
  encode(message: PpicHandoverItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(16).uint64(message.panelCodeId);
    }
    if (message.qty !== undefined) {
      writer.uint32(24).int32(message.qty);
    }
    if (message.extItemId !== undefined) {
      writer.uint32(32).uint64(message.extItemId);
    }
    if (message.productId !== undefined) {
      writer.uint32(40).uint64(message.productId);
    }
    if (message.remark !== undefined) {
      writer.uint32(50).string(message.remark);
    }
    if (message.panelCodeSerialNumberId !== undefined) {
      writer.uint32(56).uint64(message.panelCodeSerialNumberId);
    }
    if (message.jobId !== undefined) {
      writer.uint32(64).uint64(message.jobId);
    }
    if (message.poId !== undefined) {
      writer.uint32(80).uint64(message.poId);
    }
    if (message.materialRequestId !== undefined) {
      writer.uint32(88).uint64(message.materialRequestId);
    }
    if (message.panelCodeSerialNumberFullName !== undefined) {
      writer.uint32(98).string(message.panelCodeSerialNumberFullName);
    }
    if (message.panelCodeSerialNumberManualId !== undefined) {
      writer.uint32(104).uint64(message.panelCodeSerialNumberManualId);
    }
    if (message.qaStatus !== undefined) {
      writer.uint32(112).int32(message.qaStatus);
    }
    if (message.qaUserId !== undefined) {
      writer.uint32(120).uint64(message.qaUserId);
    }
    if (message.qaUserCheckedTimestamp !== undefined) {
      writer.uint32(130).string(message.qaUserCheckedTimestamp);
    }
    if (message.qaId !== undefined) {
      writer.uint32(136).uint64(message.qaId);
    }
    if (message.qaSendTimestamp !== undefined) {
      writer.uint32(186).string(message.qaSendTimestamp);
    }
    if (message.qaRemark !== undefined) {
      writer.uint32(202).string(message.qaRemark);
    }
    if (message.qcProgress !== undefined) {
      writer.uint32(144).bool(message.qcProgress);
    }
    if (message.whUserId !== undefined) {
      writer.uint32(160).uint64(message.whUserId);
    }
    if (message.whUserCheckedTimestamp !== undefined) {
      writer.uint32(170).string(message.whUserCheckedTimestamp);
    }
    if (message.whStatus !== undefined) {
      writer.uint32(176).int32(message.whStatus);
    }
    if (message.whSendTimestamp !== undefined) {
      writer.uint32(194).string(message.whSendTimestamp);
    }
    if (message.whRemark !== undefined) {
      writer.uint32(210).string(message.whRemark);
    }
    for (const v of message.pics) {
      PpicHandoverItemPic.encode(v!, writer.uint32(218).fork()).ldelim();
    }
    if (message.productionRemark !== undefined) {
      writer.uint32(226).string(message.productionRemark);
    }
    if (message.panelCodePartNumber !== undefined) {
      writer.uint32(234).string(message.panelCodePartNumber);
    }
    if (message.panelCodeExtItemId !== undefined) {
      writer.uint32(240).uint64(message.panelCodeExtItemId);
    }
    if (message.panelCodeExtItemPrice !== undefined) {
      writer.uint32(249).double(message.panelCodeExtItemPrice);
    }
    if (message.partNum !== undefined) {
      writer.uint32(258).string(message.partNum);
    }
    if (message.partName !== undefined) {
      writer.uint32(266).string(message.partName);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(274).string(message.partDesc);
    }
    if (message.mfr !== undefined) {
      writer.uint32(282).string(message.mfr);
    }
    if (message.defaultUm !== undefined) {
      writer.uint32(290).string(message.defaultUm);
    }
    if (message.snapshotPriceIdr !== undefined) {
      writer.uint32(297).double(message.snapshotPriceIdr);
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(304).uint64(message.extPurchaseOrderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandoverItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandoverItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.panelCodeSerialNumberId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.poId = longToString(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.materialRequestId = longToString(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.panelCodeSerialNumberFullName = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.panelCodeSerialNumberManualId = longToString(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.qaStatus = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.qaUserId = longToString(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.qaUserCheckedTimestamp = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.qaId = longToString(reader.uint64() as Long);
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.qaSendTimestamp = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.qaRemark = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.qcProgress = reader.bool();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.whUserId = longToString(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.whUserCheckedTimestamp = reader.string();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.whStatus = reader.int32() as any;
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.whSendTimestamp = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.whRemark = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.pics.push(PpicHandoverItemPic.decode(reader, reader.uint32()));
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.productionRemark = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.panelCodePartNumber = reader.string();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.panelCodeExtItemId = longToString(reader.uint64() as Long);
          continue;
        case 31:
          if (tag !== 249) {
            break;
          }

          message.panelCodeExtItemPrice = reader.double();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.partNum = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.defaultUm = reader.string();
          continue;
        case 37:
          if (tag !== 297) {
            break;
          }

          message.snapshotPriceIdr = reader.double();
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandoverItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      panelCodeSerialNumberId: isSet(object.panelCodeSerialNumberId)
        ? String(object.panelCodeSerialNumberId)
        : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      poId: isSet(object.poId) ? String(object.poId) : undefined,
      materialRequestId: isSet(object.materialRequestId) ? String(object.materialRequestId) : undefined,
      panelCodeSerialNumberFullName: isSet(object.panelCodeSerialNumberFullName)
        ? String(object.panelCodeSerialNumberFullName)
        : undefined,
      panelCodeSerialNumberManualId: isSet(object.panelCodeSerialNumberManualId)
        ? String(object.panelCodeSerialNumberManualId)
        : undefined,
      qaStatus: isSet(object.qaStatus) ? handoverQAStatusFromJSON(object.qaStatus) : undefined,
      qaUserId: isSet(object.qaUserId) ? String(object.qaUserId) : undefined,
      qaUserCheckedTimestamp: isSet(object.qaUserCheckedTimestamp) ? String(object.qaUserCheckedTimestamp) : undefined,
      qaId: isSet(object.qaId) ? String(object.qaId) : undefined,
      qaSendTimestamp: isSet(object.qaSendTimestamp) ? String(object.qaSendTimestamp) : undefined,
      qaRemark: isSet(object.qaRemark) ? String(object.qaRemark) : undefined,
      qcProgress: isSet(object.qcProgress) ? Boolean(object.qcProgress) : undefined,
      whUserId: isSet(object.whUserId) ? String(object.whUserId) : undefined,
      whUserCheckedTimestamp: isSet(object.whUserCheckedTimestamp) ? String(object.whUserCheckedTimestamp) : undefined,
      whStatus: isSet(object.whStatus) ? handoverQAStatusFromJSON(object.whStatus) : undefined,
      whSendTimestamp: isSet(object.whSendTimestamp) ? String(object.whSendTimestamp) : undefined,
      whRemark: isSet(object.whRemark) ? String(object.whRemark) : undefined,
      pics: Array.isArray(object?.pics) ? object.pics.map((e: any) => PpicHandoverItemPic.fromJSON(e)) : [],
      productionRemark: isSet(object.productionRemark) ? String(object.productionRemark) : undefined,
      panelCodePartNumber: isSet(object.panelCodePartNumber) ? String(object.panelCodePartNumber) : undefined,
      panelCodeExtItemId: isSet(object.panelCodeExtItemId) ? String(object.panelCodeExtItemId) : undefined,
      panelCodeExtItemPrice: isSet(object.panelCodeExtItemPrice) ? Number(object.panelCodeExtItemPrice) : undefined,
      partNum: isSet(object.partNum) ? String(object.partNum) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      defaultUm: isSet(object.defaultUm) ? String(object.defaultUm) : undefined,
      snapshotPriceIdr: isSet(object.snapshotPriceIdr) ? Number(object.snapshotPriceIdr) : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
    };
  },

  toJSON(message: PpicHandoverItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.productId !== undefined && (obj.productId = message.productId);
    message.remark !== undefined && (obj.remark = message.remark);
    message.panelCodeSerialNumberId !== undefined && (obj.panelCodeSerialNumberId = message.panelCodeSerialNumberId);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.poId !== undefined && (obj.poId = message.poId);
    message.materialRequestId !== undefined && (obj.materialRequestId = message.materialRequestId);
    message.panelCodeSerialNumberFullName !== undefined &&
      (obj.panelCodeSerialNumberFullName = message.panelCodeSerialNumberFullName);
    message.panelCodeSerialNumberManualId !== undefined &&
      (obj.panelCodeSerialNumberManualId = message.panelCodeSerialNumberManualId);
    message.qaStatus !== undefined &&
      (obj.qaStatus = message.qaStatus !== undefined ? handoverQAStatusToJSON(message.qaStatus) : undefined);
    message.qaUserId !== undefined && (obj.qaUserId = message.qaUserId);
    message.qaUserCheckedTimestamp !== undefined && (obj.qaUserCheckedTimestamp = message.qaUserCheckedTimestamp);
    message.qaId !== undefined && (obj.qaId = message.qaId);
    message.qaSendTimestamp !== undefined && (obj.qaSendTimestamp = message.qaSendTimestamp);
    message.qaRemark !== undefined && (obj.qaRemark = message.qaRemark);
    message.qcProgress !== undefined && (obj.qcProgress = message.qcProgress);
    message.whUserId !== undefined && (obj.whUserId = message.whUserId);
    message.whUserCheckedTimestamp !== undefined && (obj.whUserCheckedTimestamp = message.whUserCheckedTimestamp);
    message.whStatus !== undefined &&
      (obj.whStatus = message.whStatus !== undefined ? handoverQAStatusToJSON(message.whStatus) : undefined);
    message.whSendTimestamp !== undefined && (obj.whSendTimestamp = message.whSendTimestamp);
    message.whRemark !== undefined && (obj.whRemark = message.whRemark);
    if (message.pics) {
      obj.pics = message.pics.map((e) => e ? PpicHandoverItemPic.toJSON(e) : undefined);
    } else {
      obj.pics = [];
    }
    message.productionRemark !== undefined && (obj.productionRemark = message.productionRemark);
    message.panelCodePartNumber !== undefined && (obj.panelCodePartNumber = message.panelCodePartNumber);
    message.panelCodeExtItemId !== undefined && (obj.panelCodeExtItemId = message.panelCodeExtItemId);
    message.panelCodeExtItemPrice !== undefined && (obj.panelCodeExtItemPrice = message.panelCodeExtItemPrice);
    message.partNum !== undefined && (obj.partNum = message.partNum);
    message.partName !== undefined && (obj.partName = message.partName);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.defaultUm !== undefined && (obj.defaultUm = message.defaultUm);
    message.snapshotPriceIdr !== undefined && (obj.snapshotPriceIdr = message.snapshotPriceIdr);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandoverItem>, I>>(base?: I): PpicHandoverItem {
    return PpicHandoverItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandoverItem>, I>>(object: I): PpicHandoverItem {
    const message = createBasePpicHandoverItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.qty = object.qty ?? undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.productId = object.productId ?? undefined;
    message.remark = object.remark ?? undefined;
    message.panelCodeSerialNumberId = object.panelCodeSerialNumberId ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.poId = object.poId ?? undefined;
    message.materialRequestId = object.materialRequestId ?? undefined;
    message.panelCodeSerialNumberFullName = object.panelCodeSerialNumberFullName ?? undefined;
    message.panelCodeSerialNumberManualId = object.panelCodeSerialNumberManualId ?? undefined;
    message.qaStatus = object.qaStatus ?? undefined;
    message.qaUserId = object.qaUserId ?? undefined;
    message.qaUserCheckedTimestamp = object.qaUserCheckedTimestamp ?? undefined;
    message.qaId = object.qaId ?? undefined;
    message.qaSendTimestamp = object.qaSendTimestamp ?? undefined;
    message.qaRemark = object.qaRemark ?? undefined;
    message.qcProgress = object.qcProgress ?? undefined;
    message.whUserId = object.whUserId ?? undefined;
    message.whUserCheckedTimestamp = object.whUserCheckedTimestamp ?? undefined;
    message.whStatus = object.whStatus ?? undefined;
    message.whSendTimestamp = object.whSendTimestamp ?? undefined;
    message.whRemark = object.whRemark ?? undefined;
    message.pics = object.pics?.map((e) => PpicHandoverItemPic.fromPartial(e)) || [];
    message.productionRemark = object.productionRemark ?? undefined;
    message.panelCodePartNumber = object.panelCodePartNumber ?? undefined;
    message.panelCodeExtItemId = object.panelCodeExtItemId ?? undefined;
    message.panelCodeExtItemPrice = object.panelCodeExtItemPrice ?? undefined;
    message.partNum = object.partNum ?? undefined;
    message.partName = object.partName ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.mfr = object.mfr ?? undefined;
    message.defaultUm = object.defaultUm ?? undefined;
    message.snapshotPriceIdr = object.snapshotPriceIdr ?? undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeOutstandings(): PpicPanelCodeOutstandings {
  return { products: [] };
}

export const PpicPanelCodeOutstandings = {
  encode(message: PpicPanelCodeOutstandings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.products) {
      PpicPanelCodeOutstanding.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeOutstandings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeOutstandings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.products.push(PpicPanelCodeOutstanding.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeOutstandings {
    return {
      products: Array.isArray(object?.products)
        ? object.products.map((e: any) => PpicPanelCodeOutstanding.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeOutstandings): unknown {
    const obj: any = {};
    if (message.products) {
      obj.products = message.products.map((e) => e ? PpicPanelCodeOutstanding.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeOutstandings>, I>>(base?: I): PpicPanelCodeOutstandings {
    return PpicPanelCodeOutstandings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeOutstandings>, I>>(object: I): PpicPanelCodeOutstandings {
    const message = createBasePpicPanelCodeOutstandings();
    message.products = object.products?.map((e) => PpicPanelCodeOutstanding.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPanelCodeOutstanding(): PpicPanelCodeOutstanding {
  return {
    masterJavaBaseModel: undefined,
    type: undefined,
    code: undefined,
    qty: undefined,
    extItemId: undefined,
    mfr: undefined,
    partNum: undefined,
    partName: undefined,
    partDesc: undefined,
    defaultUm: undefined,
    qtyDone: undefined,
    lastStep: undefined,
    percentage: undefined,
    bomList: undefined,
    jobId: undefined,
    jobName: undefined,
  };
}

export const PpicPanelCodeOutstanding = {
  encode(message: PpicPanelCodeOutstanding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(18).string(message.type);
    }
    if (message.code !== undefined) {
      writer.uint32(26).string(message.code);
    }
    if (message.qty !== undefined) {
      writer.uint32(32).int32(message.qty);
    }
    if (message.extItemId !== undefined) {
      writer.uint32(40).uint64(message.extItemId);
    }
    if (message.mfr !== undefined) {
      writer.uint32(50).string(message.mfr);
    }
    if (message.partNum !== undefined) {
      writer.uint32(58).string(message.partNum);
    }
    if (message.partName !== undefined) {
      writer.uint32(66).string(message.partName);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(74).string(message.partDesc);
    }
    if (message.defaultUm !== undefined) {
      writer.uint32(82).string(message.defaultUm);
    }
    if (message.qtyDone !== undefined) {
      writer.uint32(88).int32(message.qtyDone);
    }
    if (message.lastStep !== undefined) {
      writer.uint32(98).string(message.lastStep);
    }
    if (message.percentage !== undefined) {
      writer.uint32(105).double(message.percentage);
    }
    if (message.bomList !== undefined) {
      writer.uint32(112).bool(message.bomList);
    }
    if (message.jobId !== undefined) {
      writer.uint32(120).uint64(message.jobId);
    }
    if (message.jobName !== undefined) {
      writer.uint32(130).string(message.jobName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeOutstanding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeOutstanding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.partNum = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.defaultUm = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.qtyDone = reader.int32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lastStep = reader.string();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.percentage = reader.double();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.bomList = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.jobName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeOutstanding {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      code: isSet(object.code) ? String(object.code) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      partNum: isSet(object.partNum) ? String(object.partNum) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      defaultUm: isSet(object.defaultUm) ? String(object.defaultUm) : undefined,
      qtyDone: isSet(object.qtyDone) ? Number(object.qtyDone) : undefined,
      lastStep: isSet(object.lastStep) ? String(object.lastStep) : undefined,
      percentage: isSet(object.percentage) ? Number(object.percentage) : undefined,
      bomList: isSet(object.bomList) ? Boolean(object.bomList) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      jobName: isSet(object.jobName) ? String(object.jobName) : undefined,
    };
  },

  toJSON(message: PpicPanelCodeOutstanding): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.type !== undefined && (obj.type = message.type);
    message.code !== undefined && (obj.code = message.code);
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.partNum !== undefined && (obj.partNum = message.partNum);
    message.partName !== undefined && (obj.partName = message.partName);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.defaultUm !== undefined && (obj.defaultUm = message.defaultUm);
    message.qtyDone !== undefined && (obj.qtyDone = Math.round(message.qtyDone));
    message.lastStep !== undefined && (obj.lastStep = message.lastStep);
    message.percentage !== undefined && (obj.percentage = message.percentage);
    message.bomList !== undefined && (obj.bomList = message.bomList);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.jobName !== undefined && (obj.jobName = message.jobName);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeOutstanding>, I>>(base?: I): PpicPanelCodeOutstanding {
    return PpicPanelCodeOutstanding.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeOutstanding>, I>>(object: I): PpicPanelCodeOutstanding {
    const message = createBasePpicPanelCodeOutstanding();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.type = object.type ?? undefined;
    message.code = object.code ?? undefined;
    message.qty = object.qty ?? undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.mfr = object.mfr ?? undefined;
    message.partNum = object.partNum ?? undefined;
    message.partName = object.partName ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.defaultUm = object.defaultUm ?? undefined;
    message.qtyDone = object.qtyDone ?? undefined;
    message.lastStep = object.lastStep ?? undefined;
    message.percentage = object.percentage ?? undefined;
    message.bomList = object.bomList ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.jobName = object.jobName ?? undefined;
    return message;
  },
};

function createBasePpicHandoverItemPic(): PpicHandoverItemPic {
  return { masterJavaBaseModel: undefined, extUserId: undefined, handoverItemId: undefined };
}

export const PpicHandoverItemPic = {
  encode(message: PpicHandoverItemPic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.handoverItemId !== undefined) {
      writer.uint32(24).uint64(message.handoverItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandoverItemPic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandoverItemPic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.handoverItemId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandoverItemPic {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      handoverItemId: isSet(object.handoverItemId) ? String(object.handoverItemId) : undefined,
    };
  },

  toJSON(message: PpicHandoverItemPic): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.handoverItemId !== undefined && (obj.handoverItemId = message.handoverItemId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandoverItemPic>, I>>(base?: I): PpicHandoverItemPic {
    return PpicHandoverItemPic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandoverItemPic>, I>>(object: I): PpicHandoverItemPic {
    const message = createBasePpicHandoverItemPic();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.handoverItemId = object.handoverItemId ?? undefined;
    return message;
  },
};

function createBasePpicHandoverItems(): PpicHandoverItems {
  return { items: [] };
}

export const PpicHandoverItems = {
  encode(message: PpicHandoverItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      PpicHandoverItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicHandoverItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicHandoverItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(PpicHandoverItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicHandoverItems {
    return { items: Array.isArray(object?.items) ? object.items.map((e: any) => PpicHandoverItem.fromJSON(e)) : [] };
  },

  toJSON(message: PpicHandoverItems): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicHandoverItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicHandoverItems>, I>>(base?: I): PpicHandoverItems {
    return PpicHandoverItems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicHandoverItems>, I>>(object: I): PpicHandoverItems {
    const message = createBasePpicHandoverItems();
    message.items = object.items?.map((e) => PpicHandoverItem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicIntegrationModules(): PpicIntegrationModules {
  return { modules: [] };
}

export const PpicIntegrationModules = {
  encode(message: PpicIntegrationModules, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modules) {
      PpicIntegrationModule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicIntegrationModules {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicIntegrationModules();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modules.push(PpicIntegrationModule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicIntegrationModules {
    return {
      modules: Array.isArray(object?.modules) ? object.modules.map((e: any) => PpicIntegrationModule.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicIntegrationModules): unknown {
    const obj: any = {};
    if (message.modules) {
      obj.modules = message.modules.map((e) => e ? PpicIntegrationModule.toJSON(e) : undefined);
    } else {
      obj.modules = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicIntegrationModules>, I>>(base?: I): PpicIntegrationModules {
    return PpicIntegrationModules.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicIntegrationModules>, I>>(object: I): PpicIntegrationModules {
    const message = createBasePpicIntegrationModules();
    message.modules = object.modules?.map((e) => PpicIntegrationModule.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMachine(): PpicMachine {
  return {
    id: undefined,
    name: undefined,
    description: undefined,
    purchasedDate: undefined,
    status: undefined,
    processTypeId: undefined,
    processType: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    simpleProcessType: undefined,
    simpleProcessTypeId: undefined,
    pics: [],
  };
}

export const PpicMachine = {
  encode(message: PpicMachine, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.purchasedDate !== undefined) {
      writer.uint32(34).string(message.purchasedDate);
    }
    if (message.status !== undefined) {
      writer.uint32(42).string(message.status);
    }
    if (message.processTypeId !== undefined) {
      writer.uint32(48).uint64(message.processTypeId);
    }
    if (message.processType !== undefined) {
      PpicProcessType.encode(message.processType, writer.uint32(58).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      writer.uint32(66).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(74).string(message.updatedAt);
    }
    if (message.simpleProcessType !== undefined) {
      PpicSimplifiedProcessType.encode(message.simpleProcessType, writer.uint32(82).fork()).ldelim();
    }
    if (message.simpleProcessTypeId !== undefined) {
      writer.uint32(88).uint64(message.simpleProcessTypeId);
    }
    for (const v of message.pics) {
      MachinePic.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachine {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.purchasedDate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.status = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.processTypeId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.processType = PpicProcessType.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.simpleProcessType = PpicSimplifiedProcessType.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.simpleProcessTypeId = longToString(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.pics.push(MachinePic.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachine {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      purchasedDate: isSet(object.purchasedDate) ? String(object.purchasedDate) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      processTypeId: isSet(object.processTypeId) ? String(object.processTypeId) : undefined,
      processType: isSet(object.processType) ? PpicProcessType.fromJSON(object.processType) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      simpleProcessType: isSet(object.simpleProcessType)
        ? PpicSimplifiedProcessType.fromJSON(object.simpleProcessType)
        : undefined,
      simpleProcessTypeId: isSet(object.simpleProcessTypeId) ? String(object.simpleProcessTypeId) : undefined,
      pics: Array.isArray(object?.pics) ? object.pics.map((e: any) => MachinePic.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicMachine): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.purchasedDate !== undefined && (obj.purchasedDate = message.purchasedDate);
    message.status !== undefined && (obj.status = message.status);
    message.processTypeId !== undefined && (obj.processTypeId = message.processTypeId);
    message.processType !== undefined &&
      (obj.processType = message.processType ? PpicProcessType.toJSON(message.processType) : undefined);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.simpleProcessType !== undefined && (obj.simpleProcessType = message.simpleProcessType
      ? PpicSimplifiedProcessType.toJSON(message.simpleProcessType)
      : undefined);
    message.simpleProcessTypeId !== undefined && (obj.simpleProcessTypeId = message.simpleProcessTypeId);
    if (message.pics) {
      obj.pics = message.pics.map((e) => e ? MachinePic.toJSON(e) : undefined);
    } else {
      obj.pics = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachine>, I>>(base?: I): PpicMachine {
    return PpicMachine.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachine>, I>>(object: I): PpicMachine {
    const message = createBasePpicMachine();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.description = object.description ?? undefined;
    message.purchasedDate = object.purchasedDate ?? undefined;
    message.status = object.status ?? undefined;
    message.processTypeId = object.processTypeId ?? undefined;
    message.processType = (object.processType !== undefined && object.processType !== null)
      ? PpicProcessType.fromPartial(object.processType)
      : undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.simpleProcessType = (object.simpleProcessType !== undefined && object.simpleProcessType !== null)
      ? PpicSimplifiedProcessType.fromPartial(object.simpleProcessType)
      : undefined;
    message.simpleProcessTypeId = object.simpleProcessTypeId ?? undefined;
    message.pics = object.pics?.map((e) => MachinePic.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMachines(): PpicMachines {
  return { machines: [] };
}

export const PpicMachines = {
  encode(message: PpicMachines, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.machines) {
      PpicMachine.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachines {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachines();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.machines.push(PpicMachine.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachines {
    return {
      machines: Array.isArray(object?.machines) ? object.machines.map((e: any) => PpicMachine.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicMachines): unknown {
    const obj: any = {};
    if (message.machines) {
      obj.machines = message.machines.map((e) => e ? PpicMachine.toJSON(e) : undefined);
    } else {
      obj.machines = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachines>, I>>(base?: I): PpicMachines {
    return PpicMachines.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachines>, I>>(object: I): PpicMachines {
    const message = createBasePpicMachines();
    message.machines = object.machines?.map((e) => PpicMachine.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMachinePic(): MachinePic {
  return { masterJavaBaseModel: undefined, extUserId: undefined, machineId: undefined };
}

export const MachinePic = {
  encode(message: MachinePic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    if (message.machineId !== undefined) {
      writer.uint32(24).uint64(message.machineId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MachinePic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMachinePic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.machineId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MachinePic {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      machineId: isSet(object.machineId) ? String(object.machineId) : undefined,
    };
  },

  toJSON(message: MachinePic): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.machineId !== undefined && (obj.machineId = message.machineId);
    return obj;
  },

  create<I extends Exact<DeepPartial<MachinePic>, I>>(base?: I): MachinePic {
    return MachinePic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MachinePic>, I>>(object: I): MachinePic {
    const message = createBaseMachinePic();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    message.machineId = object.machineId ?? undefined;
    return message;
  },
};

function createBasePpicProcessType(): PpicProcessType {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const PpicProcessType = {
  encode(message: PpicProcessType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicProcessType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicProcessType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicProcessType {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: PpicProcessType): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicProcessType>, I>>(base?: I): PpicProcessType {
    return PpicProcessType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicProcessType>, I>>(object: I): PpicProcessType {
    const message = createBasePpicProcessType();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBasePpicBomDocumentHierarchyXls(): PpicBomDocumentHierarchyXls {
  return { data: undefined };
}

export const PpicBomDocumentHierarchyXls = {
  encode(message: PpicBomDocumentHierarchyXls, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      writer.uint32(10).string(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomDocumentHierarchyXls {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomDocumentHierarchyXls();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomDocumentHierarchyXls {
    return { data: isSet(object.data) ? String(object.data) : undefined };
  },

  toJSON(message: PpicBomDocumentHierarchyXls): unknown {
    const obj: any = {};
    message.data !== undefined && (obj.data = message.data);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomDocumentHierarchyXls>, I>>(base?: I): PpicBomDocumentHierarchyXls {
    return PpicBomDocumentHierarchyXls.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomDocumentHierarchyXls>, I>>(object: I): PpicBomDocumentHierarchyXls {
    const message = createBasePpicBomDocumentHierarchyXls();
    message.data = object.data ?? undefined;
    return message;
  },
};

function createBasePpicForProcess(): PpicForProcess {
  return { masterJavaBaseModel: undefined };
}

export const PpicForProcess = {
  encode(message: PpicForProcess, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicForProcess {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicForProcess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicForProcess {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: PpicForProcess): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicForProcess>, I>>(base?: I): PpicForProcess {
    return PpicForProcess.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicForProcess>, I>>(object: I): PpicForProcess {
    const message = createBasePpicForProcess();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBasePpicBomLeveledGroup(): PpicBomLeveledGroup {
  return { masterJavaBaseModel: undefined, name: undefined, items: [], quoteId: undefined };
}

export const PpicBomLeveledGroup = {
  encode(message: PpicBomLeveledGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.items) {
      PpicBomLeveledGroupItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.quoteId !== undefined) {
      writer.uint32(32).uint64(message.quoteId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.items.push(PpicBomLeveledGroupItem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.quoteId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledGroup {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => PpicBomLeveledGroupItem.fromJSON(e)) : [],
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : undefined,
    };
  },

  toJSON(message: PpicBomLeveledGroup): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicBomLeveledGroupItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledGroup>, I>>(base?: I): PpicBomLeveledGroup {
    return PpicBomLeveledGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledGroup>, I>>(object: I): PpicBomLeveledGroup {
    const message = createBasePpicBomLeveledGroup();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.items = object.items?.map((e) => PpicBomLeveledGroupItem.fromPartial(e)) || [];
    message.quoteId = object.quoteId ?? undefined;
    return message;
  },
};

function createBasePpicBomLeveledGroups(): PpicBomLeveledGroups {
  return { groups: [] };
}

export const PpicBomLeveledGroups = {
  encode(message: PpicBomLeveledGroups, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      PpicBomLeveledGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledGroups {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledGroups();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(PpicBomLeveledGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledGroups {
    return {
      groups: Array.isArray(object?.groups) ? object.groups.map((e: any) => PpicBomLeveledGroup.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicBomLeveledGroups): unknown {
    const obj: any = {};
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? PpicBomLeveledGroup.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledGroups>, I>>(base?: I): PpicBomLeveledGroups {
    return PpicBomLeveledGroups.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledGroups>, I>>(object: I): PpicBomLeveledGroups {
    const message = createBasePpicBomLeveledGroups();
    message.groups = object.groups?.map((e) => PpicBomLeveledGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicBomLeveledGroupItem(): PpicBomLeveledGroupItem {
  return { masterJavaBaseModel: undefined, bomLeveledGroupId: undefined, bomLeveledId: undefined };
}

export const PpicBomLeveledGroupItem = {
  encode(message: PpicBomLeveledGroupItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.bomLeveledGroupId !== undefined) {
      writer.uint32(16).uint64(message.bomLeveledGroupId);
    }
    if (message.bomLeveledId !== undefined) {
      writer.uint32(24).uint64(message.bomLeveledId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledGroupItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledGroupItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bomLeveledGroupId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bomLeveledId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledGroupItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      bomLeveledGroupId: isSet(object.bomLeveledGroupId) ? String(object.bomLeveledGroupId) : undefined,
      bomLeveledId: isSet(object.bomLeveledId) ? String(object.bomLeveledId) : undefined,
    };
  },

  toJSON(message: PpicBomLeveledGroupItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.bomLeveledGroupId !== undefined && (obj.bomLeveledGroupId = message.bomLeveledGroupId);
    message.bomLeveledId !== undefined && (obj.bomLeveledId = message.bomLeveledId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledGroupItem>, I>>(base?: I): PpicBomLeveledGroupItem {
    return PpicBomLeveledGroupItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledGroupItem>, I>>(object: I): PpicBomLeveledGroupItem {
    const message = createBasePpicBomLeveledGroupItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.bomLeveledGroupId = object.bomLeveledGroupId ?? undefined;
    message.bomLeveledId = object.bomLeveledId ?? undefined;
    return message;
  },
};

function createBasePpicBomLeveledSubGroup(): PpicBomLeveledSubGroup {
  return { masterJavaBaseModel: undefined };
}

export const PpicBomLeveledSubGroup = {
  encode(message: PpicBomLeveledSubGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledSubGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledSubGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledSubGroup {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
    };
  },

  toJSON(message: PpicBomLeveledSubGroup): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledSubGroup>, I>>(base?: I): PpicBomLeveledSubGroup {
    return PpicBomLeveledSubGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledSubGroup>, I>>(object: I): PpicBomLeveledSubGroup {
    const message = createBasePpicBomLeveledSubGroup();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    return message;
  },
};

function createBasePpicBomLeveledRecursive(): PpicBomLeveledRecursive {
  return { bomLeveled: undefined, children: [] };
}

export const PpicBomLeveledRecursive = {
  encode(message: PpicBomLeveledRecursive, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bomLeveled !== undefined) {
      PpicBomLeveled.encode(message.bomLeveled, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.children) {
      PpicBomLeveled.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledRecursive {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledRecursive();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bomLeveled = PpicBomLeveled.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.children.push(PpicBomLeveled.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledRecursive {
    return {
      bomLeveled: isSet(object.bomLeveled) ? PpicBomLeveled.fromJSON(object.bomLeveled) : undefined,
      children: Array.isArray(object?.children) ? object.children.map((e: any) => PpicBomLeveled.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicBomLeveledRecursive): unknown {
    const obj: any = {};
    message.bomLeveled !== undefined &&
      (obj.bomLeveled = message.bomLeveled ? PpicBomLeveled.toJSON(message.bomLeveled) : undefined);
    if (message.children) {
      obj.children = message.children.map((e) => e ? PpicBomLeveled.toJSON(e) : undefined);
    } else {
      obj.children = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledRecursive>, I>>(base?: I): PpicBomLeveledRecursive {
    return PpicBomLeveledRecursive.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledRecursive>, I>>(object: I): PpicBomLeveledRecursive {
    const message = createBasePpicBomLeveledRecursive();
    message.bomLeveled = (object.bomLeveled !== undefined && object.bomLeveled !== null)
      ? PpicBomLeveled.fromPartial(object.bomLeveled)
      : undefined;
    message.children = object.children?.map((e) => PpicBomLeveled.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicBomLeveled(): PpicBomLeveled {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    type: undefined,
    bomLeveled: undefined,
    bomLeveledId: undefined,
    qty: undefined,
    children: [],
    forProcess: undefined,
    forProcessId: undefined,
    bomLeveledGroup: undefined,
    bomLeveledGroupId: undefined,
    bomLeveledSubGroup: undefined,
    bomLeveledSubGroupId: undefined,
    extItemId: undefined,
    itemsCount: undefined,
    inMr: undefined,
    priceOriginalCurrency: undefined,
    priceOriginalPrice: undefined,
    priceIdr: undefined,
    extItemToCreateId: undefined,
    mech: undefined,
    elec: undefined,
    extRequestItemId: undefined,
    materialType: undefined,
    mfr: undefined,
    partNum: undefined,
    partName: undefined,
    partDesc: undefined,
    um: undefined,
    snapshotPriceIdr: undefined,
    snapshotPriceLast: undefined,
    entities: [],
    deactivationDate: undefined,
    revision: undefined,
  };
}

export const PpicBomLeveled = {
  encode(message: PpicBomLeveled, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(26).string(message.type);
    }
    if (message.bomLeveled !== undefined) {
      PpicBomLeveled.encode(message.bomLeveled, writer.uint32(34).fork()).ldelim();
    }
    if (message.bomLeveledId !== undefined) {
      writer.uint32(40).uint64(message.bomLeveledId);
    }
    if (message.qty !== undefined) {
      writer.uint32(49).double(message.qty);
    }
    for (const v of message.children) {
      PpicBomLeveled.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.forProcess !== undefined) {
      PpicForProcess.encode(message.forProcess, writer.uint32(66).fork()).ldelim();
    }
    if (message.forProcessId !== undefined) {
      writer.uint32(72).uint64(message.forProcessId);
    }
    if (message.bomLeveledGroup !== undefined) {
      PpicBomLeveledGroup.encode(message.bomLeveledGroup, writer.uint32(82).fork()).ldelim();
    }
    if (message.bomLeveledGroupId !== undefined) {
      writer.uint32(88).uint64(message.bomLeveledGroupId);
    }
    if (message.bomLeveledSubGroup !== undefined) {
      PpicBomLeveledSubGroup.encode(message.bomLeveledSubGroup, writer.uint32(98).fork()).ldelim();
    }
    if (message.bomLeveledSubGroupId !== undefined) {
      writer.uint32(104).uint64(message.bomLeveledSubGroupId);
    }
    if (message.extItemId !== undefined) {
      writer.uint32(112).uint64(message.extItemId);
    }
    if (message.itemsCount !== undefined) {
      writer.uint32(120).int32(message.itemsCount);
    }
    if (message.inMr !== undefined) {
      writer.uint32(129).double(message.inMr);
    }
    if (message.priceOriginalCurrency !== undefined) {
      writer.uint32(138).string(message.priceOriginalCurrency);
    }
    if (message.priceOriginalPrice !== undefined) {
      writer.uint32(145).double(message.priceOriginalPrice);
    }
    if (message.priceIdr !== undefined) {
      writer.uint32(153).double(message.priceIdr);
    }
    if (message.extItemToCreateId !== undefined) {
      writer.uint32(160).uint64(message.extItemToCreateId);
    }
    if (message.mech !== undefined) {
      writer.uint32(168).bool(message.mech);
    }
    if (message.elec !== undefined) {
      writer.uint32(176).bool(message.elec);
    }
    if (message.extRequestItemId !== undefined) {
      writer.uint32(184).uint64(message.extRequestItemId);
    }
    if (message.materialType !== undefined) {
      writer.uint32(192).int32(message.materialType);
    }
    if (message.mfr !== undefined) {
      writer.uint32(202).string(message.mfr);
    }
    if (message.partNum !== undefined) {
      writer.uint32(210).string(message.partNum);
    }
    if (message.partName !== undefined) {
      writer.uint32(218).string(message.partName);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(226).string(message.partDesc);
    }
    if (message.um !== undefined) {
      writer.uint32(234).string(message.um);
    }
    if (message.snapshotPriceIdr !== undefined) {
      writer.uint32(241).double(message.snapshotPriceIdr);
    }
    if (message.snapshotPriceLast !== undefined) {
      writer.uint32(250).string(message.snapshotPriceLast);
    }
    for (const v of message.entities) {
      PpicBomLeveledIndividualEntity.encode(v!, writer.uint32(258).fork()).ldelim();
    }
    if (message.deactivationDate !== undefined) {
      writer.uint32(266).string(message.deactivationDate);
    }
    if (message.revision !== undefined) {
      writer.uint32(272).int32(message.revision);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveled {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveled();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bomLeveled = PpicBomLeveled.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.bomLeveledId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.qty = reader.double();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.children.push(PpicBomLeveled.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.forProcess = PpicForProcess.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.forProcessId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.bomLeveledGroup = PpicBomLeveledGroup.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.bomLeveledGroupId = longToString(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.bomLeveledSubGroup = PpicBomLeveledSubGroup.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.bomLeveledSubGroupId = longToString(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.itemsCount = reader.int32();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.inMr = reader.double();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.priceOriginalCurrency = reader.string();
          continue;
        case 18:
          if (tag !== 145) {
            break;
          }

          message.priceOriginalPrice = reader.double();
          continue;
        case 19:
          if (tag !== 153) {
            break;
          }

          message.priceIdr = reader.double();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.extItemToCreateId = longToString(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.mech = reader.bool();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.elec = reader.bool();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.extRequestItemId = longToString(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.materialType = reader.int32() as any;
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.partNum = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.um = reader.string();
          continue;
        case 30:
          if (tag !== 241) {
            break;
          }

          message.snapshotPriceIdr = reader.double();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.snapshotPriceLast = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.entities.push(PpicBomLeveledIndividualEntity.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.deactivationDate = reader.string();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.revision = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveled {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      bomLeveled: isSet(object.bomLeveled) ? PpicBomLeveled.fromJSON(object.bomLeveled) : undefined,
      bomLeveledId: isSet(object.bomLeveledId) ? String(object.bomLeveledId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      children: Array.isArray(object?.children) ? object.children.map((e: any) => PpicBomLeveled.fromJSON(e)) : [],
      forProcess: isSet(object.forProcess) ? PpicForProcess.fromJSON(object.forProcess) : undefined,
      forProcessId: isSet(object.forProcessId) ? String(object.forProcessId) : undefined,
      bomLeveledGroup: isSet(object.bomLeveledGroup) ? PpicBomLeveledGroup.fromJSON(object.bomLeveledGroup) : undefined,
      bomLeveledGroupId: isSet(object.bomLeveledGroupId) ? String(object.bomLeveledGroupId) : undefined,
      bomLeveledSubGroup: isSet(object.bomLeveledSubGroup)
        ? PpicBomLeveledSubGroup.fromJSON(object.bomLeveledSubGroup)
        : undefined,
      bomLeveledSubGroupId: isSet(object.bomLeveledSubGroupId) ? String(object.bomLeveledSubGroupId) : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      itemsCount: isSet(object.itemsCount) ? Number(object.itemsCount) : undefined,
      inMr: isSet(object.inMr) ? Number(object.inMr) : undefined,
      priceOriginalCurrency: isSet(object.priceOriginalCurrency) ? String(object.priceOriginalCurrency) : undefined,
      priceOriginalPrice: isSet(object.priceOriginalPrice) ? Number(object.priceOriginalPrice) : undefined,
      priceIdr: isSet(object.priceIdr) ? Number(object.priceIdr) : undefined,
      extItemToCreateId: isSet(object.extItemToCreateId) ? String(object.extItemToCreateId) : undefined,
      mech: isSet(object.mech) ? Boolean(object.mech) : undefined,
      elec: isSet(object.elec) ? Boolean(object.elec) : undefined,
      extRequestItemId: isSet(object.extRequestItemId) ? String(object.extRequestItemId) : undefined,
      materialType: isSet(object.materialType) ? bomItemTypeFromJSON(object.materialType) : undefined,
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      partNum: isSet(object.partNum) ? String(object.partNum) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      um: isSet(object.um) ? String(object.um) : undefined,
      snapshotPriceIdr: isSet(object.snapshotPriceIdr) ? Number(object.snapshotPriceIdr) : undefined,
      snapshotPriceLast: isSet(object.snapshotPriceLast) ? String(object.snapshotPriceLast) : undefined,
      entities: Array.isArray(object?.entities)
        ? object.entities.map((e: any) => PpicBomLeveledIndividualEntity.fromJSON(e))
        : [],
      deactivationDate: isSet(object.deactivationDate) ? String(object.deactivationDate) : undefined,
      revision: isSet(object.revision) ? Number(object.revision) : undefined,
    };
  },

  toJSON(message: PpicBomLeveled): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.bomLeveled !== undefined &&
      (obj.bomLeveled = message.bomLeveled ? PpicBomLeveled.toJSON(message.bomLeveled) : undefined);
    message.bomLeveledId !== undefined && (obj.bomLeveledId = message.bomLeveledId);
    message.qty !== undefined && (obj.qty = message.qty);
    if (message.children) {
      obj.children = message.children.map((e) => e ? PpicBomLeveled.toJSON(e) : undefined);
    } else {
      obj.children = [];
    }
    message.forProcess !== undefined &&
      (obj.forProcess = message.forProcess ? PpicForProcess.toJSON(message.forProcess) : undefined);
    message.forProcessId !== undefined && (obj.forProcessId = message.forProcessId);
    message.bomLeveledGroup !== undefined &&
      (obj.bomLeveledGroup = message.bomLeveledGroup ? PpicBomLeveledGroup.toJSON(message.bomLeveledGroup) : undefined);
    message.bomLeveledGroupId !== undefined && (obj.bomLeveledGroupId = message.bomLeveledGroupId);
    message.bomLeveledSubGroup !== undefined && (obj.bomLeveledSubGroup = message.bomLeveledSubGroup
      ? PpicBomLeveledSubGroup.toJSON(message.bomLeveledSubGroup)
      : undefined);
    message.bomLeveledSubGroupId !== undefined && (obj.bomLeveledSubGroupId = message.bomLeveledSubGroupId);
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.itemsCount !== undefined && (obj.itemsCount = Math.round(message.itemsCount));
    message.inMr !== undefined && (obj.inMr = message.inMr);
    message.priceOriginalCurrency !== undefined && (obj.priceOriginalCurrency = message.priceOriginalCurrency);
    message.priceOriginalPrice !== undefined && (obj.priceOriginalPrice = message.priceOriginalPrice);
    message.priceIdr !== undefined && (obj.priceIdr = message.priceIdr);
    message.extItemToCreateId !== undefined && (obj.extItemToCreateId = message.extItemToCreateId);
    message.mech !== undefined && (obj.mech = message.mech);
    message.elec !== undefined && (obj.elec = message.elec);
    message.extRequestItemId !== undefined && (obj.extRequestItemId = message.extRequestItemId);
    message.materialType !== undefined &&
      (obj.materialType = message.materialType !== undefined ? bomItemTypeToJSON(message.materialType) : undefined);
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.partNum !== undefined && (obj.partNum = message.partNum);
    message.partName !== undefined && (obj.partName = message.partName);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.um !== undefined && (obj.um = message.um);
    message.snapshotPriceIdr !== undefined && (obj.snapshotPriceIdr = message.snapshotPriceIdr);
    message.snapshotPriceLast !== undefined && (obj.snapshotPriceLast = message.snapshotPriceLast);
    if (message.entities) {
      obj.entities = message.entities.map((e) => e ? PpicBomLeveledIndividualEntity.toJSON(e) : undefined);
    } else {
      obj.entities = [];
    }
    message.deactivationDate !== undefined && (obj.deactivationDate = message.deactivationDate);
    message.revision !== undefined && (obj.revision = Math.round(message.revision));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveled>, I>>(base?: I): PpicBomLeveled {
    return PpicBomLeveled.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveled>, I>>(object: I): PpicBomLeveled {
    const message = createBasePpicBomLeveled();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.bomLeveled = (object.bomLeveled !== undefined && object.bomLeveled !== null)
      ? PpicBomLeveled.fromPartial(object.bomLeveled)
      : undefined;
    message.bomLeveledId = object.bomLeveledId ?? undefined;
    message.qty = object.qty ?? undefined;
    message.children = object.children?.map((e) => PpicBomLeveled.fromPartial(e)) || [];
    message.forProcess = (object.forProcess !== undefined && object.forProcess !== null)
      ? PpicForProcess.fromPartial(object.forProcess)
      : undefined;
    message.forProcessId = object.forProcessId ?? undefined;
    message.bomLeveledGroup = (object.bomLeveledGroup !== undefined && object.bomLeveledGroup !== null)
      ? PpicBomLeveledGroup.fromPartial(object.bomLeveledGroup)
      : undefined;
    message.bomLeveledGroupId = object.bomLeveledGroupId ?? undefined;
    message.bomLeveledSubGroup = (object.bomLeveledSubGroup !== undefined && object.bomLeveledSubGroup !== null)
      ? PpicBomLeveledSubGroup.fromPartial(object.bomLeveledSubGroup)
      : undefined;
    message.bomLeveledSubGroupId = object.bomLeveledSubGroupId ?? undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.itemsCount = object.itemsCount ?? undefined;
    message.inMr = object.inMr ?? undefined;
    message.priceOriginalCurrency = object.priceOriginalCurrency ?? undefined;
    message.priceOriginalPrice = object.priceOriginalPrice ?? undefined;
    message.priceIdr = object.priceIdr ?? undefined;
    message.extItemToCreateId = object.extItemToCreateId ?? undefined;
    message.mech = object.mech ?? undefined;
    message.elec = object.elec ?? undefined;
    message.extRequestItemId = object.extRequestItemId ?? undefined;
    message.materialType = object.materialType ?? undefined;
    message.mfr = object.mfr ?? undefined;
    message.partNum = object.partNum ?? undefined;
    message.partName = object.partName ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.um = object.um ?? undefined;
    message.snapshotPriceIdr = object.snapshotPriceIdr ?? undefined;
    message.snapshotPriceLast = object.snapshotPriceLast ?? undefined;
    message.entities = object.entities?.map((e) => PpicBomLeveledIndividualEntity.fromPartial(e)) || [];
    message.deactivationDate = object.deactivationDate ?? undefined;
    message.revision = object.revision ?? undefined;
    return message;
  },
};

function createBasePpicBomLeveledIndividualEntity(): PpicBomLeveledIndividualEntity {
  return { masterJavaBaseModel: undefined, individualEntityId: undefined };
}

export const PpicBomLeveledIndividualEntity = {
  encode(message: PpicBomLeveledIndividualEntity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.individualEntityId !== undefined) {
      writer.uint32(16).uint64(message.individualEntityId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledIndividualEntity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledIndividualEntity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.individualEntityId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledIndividualEntity {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      individualEntityId: isSet(object.individualEntityId) ? String(object.individualEntityId) : undefined,
    };
  },

  toJSON(message: PpicBomLeveledIndividualEntity): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.individualEntityId !== undefined && (obj.individualEntityId = message.individualEntityId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledIndividualEntity>, I>>(base?: I): PpicBomLeveledIndividualEntity {
    return PpicBomLeveledIndividualEntity.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledIndividualEntity>, I>>(
    object: I,
  ): PpicBomLeveledIndividualEntity {
    const message = createBasePpicBomLeveledIndividualEntity();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.individualEntityId = object.individualEntityId ?? undefined;
    return message;
  },
};

function createBasePpicBomLeveledWithPrice(): PpicBomLeveledWithPrice {
  return { bomLeveled: undefined, price: undefined };
}

export const PpicBomLeveledWithPrice = {
  encode(message: PpicBomLeveledWithPrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bomLeveled !== undefined) {
      PpicBomLeveled.encode(message.bomLeveled, writer.uint32(10).fork()).ldelim();
    }
    if (message.price !== undefined) {
      writer.uint32(16).int32(message.price);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledWithPrice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledWithPrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bomLeveled = PpicBomLeveled.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.price = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledWithPrice {
    return {
      bomLeveled: isSet(object.bomLeveled) ? PpicBomLeveled.fromJSON(object.bomLeveled) : undefined,
      price: isSet(object.price) ? Number(object.price) : undefined,
    };
  },

  toJSON(message: PpicBomLeveledWithPrice): unknown {
    const obj: any = {};
    message.bomLeveled !== undefined &&
      (obj.bomLeveled = message.bomLeveled ? PpicBomLeveled.toJSON(message.bomLeveled) : undefined);
    message.price !== undefined && (obj.price = Math.round(message.price));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledWithPrice>, I>>(base?: I): PpicBomLeveledWithPrice {
    return PpicBomLeveledWithPrice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledWithPrice>, I>>(object: I): PpicBomLeveledWithPrice {
    const message = createBasePpicBomLeveledWithPrice();
    message.bomLeveled = (object.bomLeveled !== undefined && object.bomLeveled !== null)
      ? PpicBomLeveled.fromPartial(object.bomLeveled)
      : undefined;
    message.price = object.price ?? undefined;
    return message;
  },
};

function createBasePpicBomLeveledsWithPrice(): PpicBomLeveledsWithPrice {
  return { boms: [] };
}

export const PpicBomLeveledsWithPrice = {
  encode(message: PpicBomLeveledsWithPrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.boms) {
      PpicBomLeveledWithPrice.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveledsWithPrice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveledsWithPrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.boms.push(PpicBomLeveledWithPrice.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveledsWithPrice {
    return {
      boms: Array.isArray(object?.boms) ? object.boms.map((e: any) => PpicBomLeveledWithPrice.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicBomLeveledsWithPrice): unknown {
    const obj: any = {};
    if (message.boms) {
      obj.boms = message.boms.map((e) => e ? PpicBomLeveledWithPrice.toJSON(e) : undefined);
    } else {
      obj.boms = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveledsWithPrice>, I>>(base?: I): PpicBomLeveledsWithPrice {
    return PpicBomLeveledsWithPrice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveledsWithPrice>, I>>(object: I): PpicBomLeveledsWithPrice {
    const message = createBasePpicBomLeveledsWithPrice();
    message.boms = object.boms?.map((e) => PpicBomLeveledWithPrice.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicBomLeveleds(): PpicBomLeveleds {
  return { bomLeveleds: [] };
}

export const PpicBomLeveleds = {
  encode(message: PpicBomLeveleds, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bomLeveleds) {
      PpicBomLeveled.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicBomLeveleds {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicBomLeveleds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bomLeveleds.push(PpicBomLeveled.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicBomLeveleds {
    return {
      bomLeveleds: Array.isArray(object?.bomLeveleds)
        ? object.bomLeveleds.map((e: any) => PpicBomLeveled.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicBomLeveleds): unknown {
    const obj: any = {};
    if (message.bomLeveleds) {
      obj.bomLeveleds = message.bomLeveleds.map((e) => e ? PpicBomLeveled.toJSON(e) : undefined);
    } else {
      obj.bomLeveleds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicBomLeveleds>, I>>(base?: I): PpicBomLeveleds {
    return PpicBomLeveleds.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicBomLeveleds>, I>>(object: I): PpicBomLeveleds {
    const message = createBasePpicBomLeveleds();
    message.bomLeveleds = object.bomLeveleds?.map((e) => PpicBomLeveled.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicManpowerAssistance(): PpicManpowerAssistance {
  return {
    masterJavaBaseModel: undefined,
    extDeptFromId: undefined,
    extDeptToId: undefined,
    forDate: undefined,
    extUserId: undefined,
  };
}

export const PpicManpowerAssistance = {
  encode(message: PpicManpowerAssistance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extDeptFromId !== undefined) {
      writer.uint32(16).uint64(message.extDeptFromId);
    }
    if (message.extDeptToId !== undefined) {
      writer.uint32(24).uint64(message.extDeptToId);
    }
    if (message.forDate !== undefined) {
      writer.uint32(34).string(message.forDate);
    }
    if (message.extUserId !== undefined) {
      writer.uint32(40).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicManpowerAssistance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicManpowerAssistance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extDeptFromId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extDeptToId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.forDate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicManpowerAssistance {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extDeptFromId: isSet(object.extDeptFromId) ? String(object.extDeptFromId) : undefined,
      extDeptToId: isSet(object.extDeptToId) ? String(object.extDeptToId) : undefined,
      forDate: isSet(object.forDate) ? String(object.forDate) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: PpicManpowerAssistance): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extDeptFromId !== undefined && (obj.extDeptFromId = message.extDeptFromId);
    message.extDeptToId !== undefined && (obj.extDeptToId = message.extDeptToId);
    message.forDate !== undefined && (obj.forDate = message.forDate);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicManpowerAssistance>, I>>(base?: I): PpicManpowerAssistance {
    return PpicManpowerAssistance.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicManpowerAssistance>, I>>(object: I): PpicManpowerAssistance {
    const message = createBasePpicManpowerAssistance();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extDeptFromId = object.extDeptFromId ?? undefined;
    message.extDeptToId = object.extDeptToId ?? undefined;
    message.forDate = object.forDate ?? undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBasePpicManpowerAssistances(): PpicManpowerAssistances {
  return { assistances: [] };
}

export const PpicManpowerAssistances = {
  encode(message: PpicManpowerAssistances, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assistances) {
      PpicManpowerAssistance.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicManpowerAssistances {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicManpowerAssistances();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assistances.push(PpicManpowerAssistance.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicManpowerAssistances {
    return {
      assistances: Array.isArray(object?.assistances)
        ? object.assistances.map((e: any) => PpicManpowerAssistance.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicManpowerAssistances): unknown {
    const obj: any = {};
    if (message.assistances) {
      obj.assistances = message.assistances.map((e) => e ? PpicManpowerAssistance.toJSON(e) : undefined);
    } else {
      obj.assistances = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicManpowerAssistances>, I>>(base?: I): PpicManpowerAssistances {
    return PpicManpowerAssistances.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicManpowerAssistances>, I>>(object: I): PpicManpowerAssistances {
    const message = createBasePpicManpowerAssistances();
    message.assistances = object.assistances?.map((e) => PpicManpowerAssistance.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicSimplifiedProcessTypeMultipliers(): PpicSimplifiedProcessTypeMultipliers {
  return { multipliers: [] };
}

export const PpicSimplifiedProcessTypeMultipliers = {
  encode(message: PpicSimplifiedProcessTypeMultipliers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.multipliers) {
      PpicSimplifiedProcessTypeMultiplier.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSimplifiedProcessTypeMultipliers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSimplifiedProcessTypeMultipliers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.multipliers.push(PpicSimplifiedProcessTypeMultiplier.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSimplifiedProcessTypeMultipliers {
    return {
      multipliers: Array.isArray(object?.multipliers)
        ? object.multipliers.map((e: any) => PpicSimplifiedProcessTypeMultiplier.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicSimplifiedProcessTypeMultipliers): unknown {
    const obj: any = {};
    if (message.multipliers) {
      obj.multipliers = message.multipliers.map((e) => e ? PpicSimplifiedProcessTypeMultiplier.toJSON(e) : undefined);
    } else {
      obj.multipliers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSimplifiedProcessTypeMultipliers>, I>>(
    base?: I,
  ): PpicSimplifiedProcessTypeMultipliers {
    return PpicSimplifiedProcessTypeMultipliers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSimplifiedProcessTypeMultipliers>, I>>(
    object: I,
  ): PpicSimplifiedProcessTypeMultipliers {
    const message = createBasePpicSimplifiedProcessTypeMultipliers();
    message.multipliers = object.multipliers?.map((e) => PpicSimplifiedProcessTypeMultiplier.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicSimplifiedProcessTypeMultiplier(): PpicSimplifiedProcessTypeMultiplier {
  return { masterJavaBaseModel: undefined, simplifiedProcessTypeId: undefined, multiplier: undefined };
}

export const PpicSimplifiedProcessTypeMultiplier = {
  encode(message: PpicSimplifiedProcessTypeMultiplier, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.simplifiedProcessTypeId !== undefined) {
      writer.uint32(16).uint64(message.simplifiedProcessTypeId);
    }
    if (message.multiplier !== undefined) {
      writer.uint32(25).double(message.multiplier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSimplifiedProcessTypeMultiplier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSimplifiedProcessTypeMultiplier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.simplifiedProcessTypeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.multiplier = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSimplifiedProcessTypeMultiplier {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      simplifiedProcessTypeId: isSet(object.simplifiedProcessTypeId)
        ? String(object.simplifiedProcessTypeId)
        : undefined,
      multiplier: isSet(object.multiplier) ? Number(object.multiplier) : undefined,
    };
  },

  toJSON(message: PpicSimplifiedProcessTypeMultiplier): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.simplifiedProcessTypeId !== undefined && (obj.simplifiedProcessTypeId = message.simplifiedProcessTypeId);
    message.multiplier !== undefined && (obj.multiplier = message.multiplier);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSimplifiedProcessTypeMultiplier>, I>>(
    base?: I,
  ): PpicSimplifiedProcessTypeMultiplier {
    return PpicSimplifiedProcessTypeMultiplier.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSimplifiedProcessTypeMultiplier>, I>>(
    object: I,
  ): PpicSimplifiedProcessTypeMultiplier {
    const message = createBasePpicSimplifiedProcessTypeMultiplier();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.simplifiedProcessTypeId = object.simplifiedProcessTypeId ?? undefined;
    message.multiplier = object.multiplier ?? undefined;
    return message;
  },
};

function createBasePpicJobBomLeveled(): PpicJobBomLeveled {
  return {
    masterJavaBaseModel: undefined,
    job: undefined,
    jobId: undefined,
    bomLeveled: undefined,
    bomLeveledId: undefined,
    qty: undefined,
    bomLeveledName: undefined,
    extPanelCodeId: undefined,
  };
}

export const PpicJobBomLeveled = {
  encode(message: PpicJobBomLeveled, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.job !== undefined) {
      PpicJob.encode(message.job, writer.uint32(18).fork()).ldelim();
    }
    if (message.jobId !== undefined) {
      writer.uint32(24).uint64(message.jobId);
    }
    if (message.bomLeveled !== undefined) {
      PpicBomLeveled.encode(message.bomLeveled, writer.uint32(34).fork()).ldelim();
    }
    if (message.bomLeveledId !== undefined) {
      writer.uint32(40).uint64(message.bomLeveledId);
    }
    if (message.qty !== undefined) {
      writer.uint32(48).int32(message.qty);
    }
    if (message.bomLeveledName !== undefined) {
      writer.uint32(58).string(message.bomLeveledName);
    }
    if (message.extPanelCodeId !== undefined) {
      writer.uint32(64).uint64(message.extPanelCodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobBomLeveled {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobBomLeveled();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.job = PpicJob.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bomLeveled = PpicBomLeveled.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.bomLeveledId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.bomLeveledName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.extPanelCodeId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobBomLeveled {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      job: isSet(object.job) ? PpicJob.fromJSON(object.job) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      bomLeveled: isSet(object.bomLeveled) ? PpicBomLeveled.fromJSON(object.bomLeveled) : undefined,
      bomLeveledId: isSet(object.bomLeveledId) ? String(object.bomLeveledId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      bomLeveledName: isSet(object.bomLeveledName) ? String(object.bomLeveledName) : undefined,
      extPanelCodeId: isSet(object.extPanelCodeId) ? String(object.extPanelCodeId) : undefined,
    };
  },

  toJSON(message: PpicJobBomLeveled): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.job !== undefined && (obj.job = message.job ? PpicJob.toJSON(message.job) : undefined);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.bomLeveled !== undefined &&
      (obj.bomLeveled = message.bomLeveled ? PpicBomLeveled.toJSON(message.bomLeveled) : undefined);
    message.bomLeveledId !== undefined && (obj.bomLeveledId = message.bomLeveledId);
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.bomLeveledName !== undefined && (obj.bomLeveledName = message.bomLeveledName);
    message.extPanelCodeId !== undefined && (obj.extPanelCodeId = message.extPanelCodeId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobBomLeveled>, I>>(base?: I): PpicJobBomLeveled {
    return PpicJobBomLeveled.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobBomLeveled>, I>>(object: I): PpicJobBomLeveled {
    const message = createBasePpicJobBomLeveled();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.job = (object.job !== undefined && object.job !== null) ? PpicJob.fromPartial(object.job) : undefined;
    message.jobId = object.jobId ?? undefined;
    message.bomLeveled = (object.bomLeveled !== undefined && object.bomLeveled !== null)
      ? PpicBomLeveled.fromPartial(object.bomLeveled)
      : undefined;
    message.bomLeveledId = object.bomLeveledId ?? undefined;
    message.qty = object.qty ?? undefined;
    message.bomLeveledName = object.bomLeveledName ?? undefined;
    message.extPanelCodeId = object.extPanelCodeId ?? undefined;
    return message;
  },
};

function createBasePpicNewJobNotifications(): PpicNewJobNotifications {
  return { notifications: [] };
}

export const PpicNewJobNotifications = {
  encode(message: PpicNewJobNotifications, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      PpicNewJobNotification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicNewJobNotifications {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicNewJobNotifications();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(PpicNewJobNotification.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicNewJobNotifications {
    return {
      notifications: Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => PpicNewJobNotification.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicNewJobNotifications): unknown {
    const obj: any = {};
    if (message.notifications) {
      obj.notifications = message.notifications.map((e) => e ? PpicNewJobNotification.toJSON(e) : undefined);
    } else {
      obj.notifications = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicNewJobNotifications>, I>>(base?: I): PpicNewJobNotifications {
    return PpicNewJobNotifications.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicNewJobNotifications>, I>>(object: I): PpicNewJobNotifications {
    const message = createBasePpicNewJobNotifications();
    message.notifications = object.notifications?.map((e) => PpicNewJobNotification.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicNewJobNotification(): PpicNewJobNotification {
  return { masterJavaBaseModel: undefined, extUserId: undefined };
}

export const PpicNewJobNotification = {
  encode(message: PpicNewJobNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(16).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicNewJobNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicNewJobNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicNewJobNotification {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: PpicNewJobNotification): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicNewJobNotification>, I>>(base?: I): PpicNewJobNotification {
    return PpicNewJobNotification.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicNewJobNotification>, I>>(object: I): PpicNewJobNotification {
    const message = createBasePpicNewJobNotification();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBasePpicJob(): PpicJob {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    qty: undefined,
    priority: undefined,
    qtyDone: undefined,
    productionDeadline: undefined,
    deliveryDeadline: undefined,
    deadline: undefined,
    closed: undefined,
    closingDate: undefined,
    jobDate: undefined,
    referenceType: undefined,
    poNumber: undefined,
    workOrders: [],
    workOrderRevs: [],
    wiringWorkOrders: [],
    panelCodes: [],
    extPurchaseOrderId: undefined,
    individualEntityId: undefined,
    category: undefined,
    jobBomLeveleds: [],
    serialNumberDones: [],
    projectId: undefined,
    extJdpPresetId: undefined,
    shortName: undefined,
    batchCount: undefined,
    jobPurchaseOrders: [],
  };
}

export const PpicJob = {
  encode(message: PpicJob, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.qty !== undefined) {
      writer.uint32(24).uint64(message.qty);
    }
    if (message.priority !== undefined) {
      writer.uint32(34).string(message.priority);
    }
    if (message.qtyDone !== undefined) {
      writer.uint32(40).uint64(message.qtyDone);
    }
    if (message.productionDeadline !== undefined) {
      writer.uint32(50).string(message.productionDeadline);
    }
    if (message.deliveryDeadline !== undefined) {
      writer.uint32(58).string(message.deliveryDeadline);
    }
    if (message.deadline !== undefined) {
      writer.uint32(66).string(message.deadline);
    }
    if (message.closed !== undefined) {
      writer.uint32(72).bool(message.closed);
    }
    if (message.closingDate !== undefined) {
      writer.uint32(82).string(message.closingDate);
    }
    if (message.jobDate !== undefined) {
      writer.uint32(90).string(message.jobDate);
    }
    if (message.referenceType !== undefined) {
      writer.uint32(98).string(message.referenceType);
    }
    if (message.poNumber !== undefined) {
      writer.uint32(106).string(message.poNumber);
    }
    for (const v of message.workOrders) {
      PpicWorkOrder.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.workOrderRevs) {
      PpicWorkOrderRev.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.wiringWorkOrders) {
      PpicWiringWorkOrder.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.panelCodes) {
      PpicPanelCode.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(144).uint64(message.extPurchaseOrderId);
    }
    if (message.individualEntityId !== undefined) {
      writer.uint32(152).uint64(message.individualEntityId);
    }
    if (message.category !== undefined) {
      writer.uint32(160).int32(message.category);
    }
    for (const v of message.jobBomLeveleds) {
      PpicJobBomLeveled.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.serialNumberDones) {
      PpicJobPanelCodeSerialNumberDone.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    if (message.projectId !== undefined) {
      writer.uint32(184).uint64(message.projectId);
    }
    if (message.extJdpPresetId !== undefined) {
      writer.uint32(192).uint64(message.extJdpPresetId);
    }
    if (message.shortName !== undefined) {
      writer.uint32(202).string(message.shortName);
    }
    if (message.batchCount !== undefined) {
      writer.uint32(208).int32(message.batchCount);
    }
    for (const v of message.jobPurchaseOrders) {
      PpicJobPurchaseorder.encode(v!, writer.uint32(218).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJob {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.qty = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.priority = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.qtyDone = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.productionDeadline = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.deliveryDeadline = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.deadline = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.closed = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.closingDate = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.jobDate = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.referenceType = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.poNumber = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.workOrders.push(PpicWorkOrder.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.workOrderRevs.push(PpicWorkOrderRev.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.wiringWorkOrders.push(PpicWiringWorkOrder.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.panelCodes.push(PpicPanelCode.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.individualEntityId = longToString(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.jobBomLeveleds.push(PpicJobBomLeveled.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.serialNumberDones.push(PpicJobPanelCodeSerialNumberDone.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.projectId = longToString(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.extJdpPresetId = longToString(reader.uint64() as Long);
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.shortName = reader.string();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.batchCount = reader.int32();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.jobPurchaseOrders.push(PpicJobPurchaseorder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJob {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      qty: isSet(object.qty) ? String(object.qty) : undefined,
      priority: isSet(object.priority) ? String(object.priority) : undefined,
      qtyDone: isSet(object.qtyDone) ? String(object.qtyDone) : undefined,
      productionDeadline: isSet(object.productionDeadline) ? String(object.productionDeadline) : undefined,
      deliveryDeadline: isSet(object.deliveryDeadline) ? String(object.deliveryDeadline) : undefined,
      deadline: isSet(object.deadline) ? String(object.deadline) : undefined,
      closed: isSet(object.closed) ? Boolean(object.closed) : undefined,
      closingDate: isSet(object.closingDate) ? String(object.closingDate) : undefined,
      jobDate: isSet(object.jobDate) ? String(object.jobDate) : undefined,
      referenceType: isSet(object.referenceType) ? String(object.referenceType) : undefined,
      poNumber: isSet(object.poNumber) ? String(object.poNumber) : undefined,
      workOrders: Array.isArray(object?.workOrders) ? object.workOrders.map((e: any) => PpicWorkOrder.fromJSON(e)) : [],
      workOrderRevs: Array.isArray(object?.workOrderRevs)
        ? object.workOrderRevs.map((e: any) => PpicWorkOrderRev.fromJSON(e))
        : [],
      wiringWorkOrders: Array.isArray(object?.wiringWorkOrders)
        ? object.wiringWorkOrders.map((e: any) => PpicWiringWorkOrder.fromJSON(e))
        : [],
      panelCodes: Array.isArray(object?.panelCodes) ? object.panelCodes.map((e: any) => PpicPanelCode.fromJSON(e)) : [],
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
      individualEntityId: isSet(object.individualEntityId) ? String(object.individualEntityId) : undefined,
      category: isSet(object.category) ? ppicJob_PpicJobDeptCategoryFromJSON(object.category) : undefined,
      jobBomLeveleds: Array.isArray(object?.jobBomLeveleds)
        ? object.jobBomLeveleds.map((e: any) => PpicJobBomLeveled.fromJSON(e))
        : [],
      serialNumberDones: Array.isArray(object?.serialNumberDones)
        ? object.serialNumberDones.map((e: any) => PpicJobPanelCodeSerialNumberDone.fromJSON(e))
        : [],
      projectId: isSet(object.projectId) ? String(object.projectId) : undefined,
      extJdpPresetId: isSet(object.extJdpPresetId) ? String(object.extJdpPresetId) : undefined,
      shortName: isSet(object.shortName) ? String(object.shortName) : undefined,
      batchCount: isSet(object.batchCount) ? Number(object.batchCount) : undefined,
      jobPurchaseOrders: Array.isArray(object?.jobPurchaseOrders)
        ? object.jobPurchaseOrders.map((e: any) => PpicJobPurchaseorder.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicJob): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.qty !== undefined && (obj.qty = message.qty);
    message.priority !== undefined && (obj.priority = message.priority);
    message.qtyDone !== undefined && (obj.qtyDone = message.qtyDone);
    message.productionDeadline !== undefined && (obj.productionDeadline = message.productionDeadline);
    message.deliveryDeadline !== undefined && (obj.deliveryDeadline = message.deliveryDeadline);
    message.deadline !== undefined && (obj.deadline = message.deadline);
    message.closed !== undefined && (obj.closed = message.closed);
    message.closingDate !== undefined && (obj.closingDate = message.closingDate);
    message.jobDate !== undefined && (obj.jobDate = message.jobDate);
    message.referenceType !== undefined && (obj.referenceType = message.referenceType);
    message.poNumber !== undefined && (obj.poNumber = message.poNumber);
    if (message.workOrders) {
      obj.workOrders = message.workOrders.map((e) => e ? PpicWorkOrder.toJSON(e) : undefined);
    } else {
      obj.workOrders = [];
    }
    if (message.workOrderRevs) {
      obj.workOrderRevs = message.workOrderRevs.map((e) => e ? PpicWorkOrderRev.toJSON(e) : undefined);
    } else {
      obj.workOrderRevs = [];
    }
    if (message.wiringWorkOrders) {
      obj.wiringWorkOrders = message.wiringWorkOrders.map((e) => e ? PpicWiringWorkOrder.toJSON(e) : undefined);
    } else {
      obj.wiringWorkOrders = [];
    }
    if (message.panelCodes) {
      obj.panelCodes = message.panelCodes.map((e) => e ? PpicPanelCode.toJSON(e) : undefined);
    } else {
      obj.panelCodes = [];
    }
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    message.individualEntityId !== undefined && (obj.individualEntityId = message.individualEntityId);
    message.category !== undefined &&
      (obj.category = message.category !== undefined ? ppicJob_PpicJobDeptCategoryToJSON(message.category) : undefined);
    if (message.jobBomLeveleds) {
      obj.jobBomLeveleds = message.jobBomLeveleds.map((e) => e ? PpicJobBomLeveled.toJSON(e) : undefined);
    } else {
      obj.jobBomLeveleds = [];
    }
    if (message.serialNumberDones) {
      obj.serialNumberDones = message.serialNumberDones.map((e) =>
        e ? PpicJobPanelCodeSerialNumberDone.toJSON(e) : undefined
      );
    } else {
      obj.serialNumberDones = [];
    }
    message.projectId !== undefined && (obj.projectId = message.projectId);
    message.extJdpPresetId !== undefined && (obj.extJdpPresetId = message.extJdpPresetId);
    message.shortName !== undefined && (obj.shortName = message.shortName);
    message.batchCount !== undefined && (obj.batchCount = Math.round(message.batchCount));
    if (message.jobPurchaseOrders) {
      obj.jobPurchaseOrders = message.jobPurchaseOrders.map((e) => e ? PpicJobPurchaseorder.toJSON(e) : undefined);
    } else {
      obj.jobPurchaseOrders = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJob>, I>>(base?: I): PpicJob {
    return PpicJob.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJob>, I>>(object: I): PpicJob {
    const message = createBasePpicJob();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.qty = object.qty ?? undefined;
    message.priority = object.priority ?? undefined;
    message.qtyDone = object.qtyDone ?? undefined;
    message.productionDeadline = object.productionDeadline ?? undefined;
    message.deliveryDeadline = object.deliveryDeadline ?? undefined;
    message.deadline = object.deadline ?? undefined;
    message.closed = object.closed ?? undefined;
    message.closingDate = object.closingDate ?? undefined;
    message.jobDate = object.jobDate ?? undefined;
    message.referenceType = object.referenceType ?? undefined;
    message.poNumber = object.poNumber ?? undefined;
    message.workOrders = object.workOrders?.map((e) => PpicWorkOrder.fromPartial(e)) || [];
    message.workOrderRevs = object.workOrderRevs?.map((e) => PpicWorkOrderRev.fromPartial(e)) || [];
    message.wiringWorkOrders = object.wiringWorkOrders?.map((e) => PpicWiringWorkOrder.fromPartial(e)) || [];
    message.panelCodes = object.panelCodes?.map((e) => PpicPanelCode.fromPartial(e)) || [];
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    message.individualEntityId = object.individualEntityId ?? undefined;
    message.category = object.category ?? undefined;
    message.jobBomLeveleds = object.jobBomLeveleds?.map((e) => PpicJobBomLeveled.fromPartial(e)) || [];
    message.serialNumberDones = object.serialNumberDones?.map((e) => PpicJobPanelCodeSerialNumberDone.fromPartial(e)) ||
      [];
    message.projectId = object.projectId ?? undefined;
    message.extJdpPresetId = object.extJdpPresetId ?? undefined;
    message.shortName = object.shortName ?? undefined;
    message.batchCount = object.batchCount ?? undefined;
    message.jobPurchaseOrders = object.jobPurchaseOrders?.map((e) => PpicJobPurchaseorder.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicJobPurchaseorder(): PpicJobPurchaseorder {
  return { masterJavaBaseModel: undefined, extPurchaseOrderId: undefined };
}

export const PpicJobPurchaseorder = {
  encode(message: PpicJobPurchaseorder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(16).uint64(message.extPurchaseOrderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobPurchaseorder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobPurchaseorder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobPurchaseorder {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
    };
  },

  toJSON(message: PpicJobPurchaseorder): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobPurchaseorder>, I>>(base?: I): PpicJobPurchaseorder {
    return PpicJobPurchaseorder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobPurchaseorder>, I>>(object: I): PpicJobPurchaseorder {
    const message = createBasePpicJobPurchaseorder();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    return message;
  },
};

function createBasePpicJobPanelCodeDepartmentTemplateItem(): PpicJobPanelCodeDepartmentTemplateItem {
  return {
    masterJavaBaseModel: undefined,
    departmentTemplateItemId: undefined,
    startDate: undefined,
    endDate: undefined,
    completedDate: undefined,
    excluded: undefined,
  };
}

export const PpicJobPanelCodeDepartmentTemplateItem = {
  encode(message: PpicJobPanelCodeDepartmentTemplateItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.departmentTemplateItemId !== undefined) {
      writer.uint32(16).uint64(message.departmentTemplateItemId);
    }
    if (message.startDate !== undefined) {
      writer.uint32(26).string(message.startDate);
    }
    if (message.endDate !== undefined) {
      writer.uint32(34).string(message.endDate);
    }
    if (message.completedDate !== undefined) {
      writer.uint32(42).string(message.completedDate);
    }
    if (message.excluded !== undefined) {
      writer.uint32(50).string(message.excluded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobPanelCodeDepartmentTemplateItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobPanelCodeDepartmentTemplateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.departmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.completedDate = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.excluded = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobPanelCodeDepartmentTemplateItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      departmentTemplateItemId: isSet(object.departmentTemplateItemId)
        ? String(object.departmentTemplateItemId)
        : undefined,
      startDate: isSet(object.startDate) ? String(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? String(object.endDate) : undefined,
      completedDate: isSet(object.completedDate) ? String(object.completedDate) : undefined,
      excluded: isSet(object.excluded) ? String(object.excluded) : undefined,
    };
  },

  toJSON(message: PpicJobPanelCodeDepartmentTemplateItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.departmentTemplateItemId !== undefined && (obj.departmentTemplateItemId = message.departmentTemplateItemId);
    message.startDate !== undefined && (obj.startDate = message.startDate);
    message.endDate !== undefined && (obj.endDate = message.endDate);
    message.completedDate !== undefined && (obj.completedDate = message.completedDate);
    message.excluded !== undefined && (obj.excluded = message.excluded);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobPanelCodeDepartmentTemplateItem>, I>>(
    base?: I,
  ): PpicJobPanelCodeDepartmentTemplateItem {
    return PpicJobPanelCodeDepartmentTemplateItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobPanelCodeDepartmentTemplateItem>, I>>(
    object: I,
  ): PpicJobPanelCodeDepartmentTemplateItem {
    const message = createBasePpicJobPanelCodeDepartmentTemplateItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.departmentTemplateItemId = object.departmentTemplateItemId ?? undefined;
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.completedDate = object.completedDate ?? undefined;
    message.excluded = object.excluded ?? undefined;
    return message;
  },
};

function createBasePpicJobPanelCodeDepartmentTemplate(): PpicJobPanelCodeDepartmentTemplate {
  return { masterJavaBaseModel: undefined, departmentTemplateId: undefined };
}

export const PpicJobPanelCodeDepartmentTemplate = {
  encode(message: PpicJobPanelCodeDepartmentTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.departmentTemplateId !== undefined) {
      writer.uint32(16).uint64(message.departmentTemplateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobPanelCodeDepartmentTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobPanelCodeDepartmentTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.departmentTemplateId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobPanelCodeDepartmentTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      departmentTemplateId: isSet(object.departmentTemplateId) ? String(object.departmentTemplateId) : undefined,
    };
  },

  toJSON(message: PpicJobPanelCodeDepartmentTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.departmentTemplateId !== undefined && (obj.departmentTemplateId = message.departmentTemplateId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobPanelCodeDepartmentTemplate>, I>>(
    base?: I,
  ): PpicJobPanelCodeDepartmentTemplate {
    return PpicJobPanelCodeDepartmentTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobPanelCodeDepartmentTemplate>, I>>(
    object: I,
  ): PpicJobPanelCodeDepartmentTemplate {
    const message = createBasePpicJobPanelCodeDepartmentTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.departmentTemplateId = object.departmentTemplateId ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeSerialNumber(): PpicPanelCodeSerialNumber {
  return {
    masterJavaBaseModel: undefined,
    panelCodeId: undefined,
    fullName: undefined,
    jobId: undefined,
    productId: undefined,
    panelCodeType: undefined,
    sequentialSerialNumber: undefined,
    crmPoItemId: undefined,
    done: undefined,
    serialNumberRects: [],
  };
}

export const PpicPanelCodeSerialNumber = {
  encode(message: PpicPanelCodeSerialNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(16).uint64(message.panelCodeId);
    }
    if (message.fullName !== undefined) {
      writer.uint32(26).string(message.fullName);
    }
    if (message.jobId !== undefined) {
      writer.uint32(32).uint64(message.jobId);
    }
    if (message.productId !== undefined) {
      writer.uint32(40).uint64(message.productId);
    }
    if (message.panelCodeType !== undefined) {
      writer.uint32(50).string(message.panelCodeType);
    }
    if (message.sequentialSerialNumber !== undefined) {
      writer.uint32(56).uint64(message.sequentialSerialNumber);
    }
    if (message.crmPoItemId !== undefined) {
      writer.uint32(64).uint64(message.crmPoItemId);
    }
    if (message.done !== undefined) {
      writer.uint32(72).bool(message.done);
    }
    for (const v of message.serialNumberRects) {
      PpicPanelCodeSerialNumberRect.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeSerialNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeSerialNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.panelCodeType = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.sequentialSerialNumber = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.crmPoItemId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.done = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.serialNumberRects.push(PpicPanelCodeSerialNumberRect.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeSerialNumber {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      fullName: isSet(object.fullName) ? String(object.fullName) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      panelCodeType: isSet(object.panelCodeType) ? String(object.panelCodeType) : undefined,
      sequentialSerialNumber: isSet(object.sequentialSerialNumber) ? String(object.sequentialSerialNumber) : undefined,
      crmPoItemId: isSet(object.crmPoItemId) ? String(object.crmPoItemId) : undefined,
      done: isSet(object.done) ? Boolean(object.done) : undefined,
      serialNumberRects: Array.isArray(object?.serialNumberRects)
        ? object.serialNumberRects.map((e: any) => PpicPanelCodeSerialNumberRect.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeSerialNumber): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.fullName !== undefined && (obj.fullName = message.fullName);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.productId !== undefined && (obj.productId = message.productId);
    message.panelCodeType !== undefined && (obj.panelCodeType = message.panelCodeType);
    message.sequentialSerialNumber !== undefined && (obj.sequentialSerialNumber = message.sequentialSerialNumber);
    message.crmPoItemId !== undefined && (obj.crmPoItemId = message.crmPoItemId);
    message.done !== undefined && (obj.done = message.done);
    if (message.serialNumberRects) {
      obj.serialNumberRects = message.serialNumberRects.map((e) =>
        e ? PpicPanelCodeSerialNumberRect.toJSON(e) : undefined
      );
    } else {
      obj.serialNumberRects = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeSerialNumber>, I>>(base?: I): PpicPanelCodeSerialNumber {
    return PpicPanelCodeSerialNumber.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeSerialNumber>, I>>(object: I): PpicPanelCodeSerialNumber {
    const message = createBasePpicPanelCodeSerialNumber();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.fullName = object.fullName ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.productId = object.productId ?? undefined;
    message.panelCodeType = object.panelCodeType ?? undefined;
    message.sequentialSerialNumber = object.sequentialSerialNumber ?? undefined;
    message.crmPoItemId = object.crmPoItemId ?? undefined;
    message.done = object.done ?? undefined;
    message.serialNumberRects = object.serialNumberRects?.map((e) => PpicPanelCodeSerialNumberRect.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBasePpicPanelCodeSerialNumbers(): PpicPanelCodeSerialNumbers {
  return { serialNumbers: [] };
}

export const PpicPanelCodeSerialNumbers = {
  encode(message: PpicPanelCodeSerialNumbers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.serialNumbers) {
      PpicPanelCodeSerialNumber.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeSerialNumbers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeSerialNumbers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumbers.push(PpicPanelCodeSerialNumber.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeSerialNumbers {
    return {
      serialNumbers: Array.isArray(object?.serialNumbers)
        ? object.serialNumbers.map((e: any) => PpicPanelCodeSerialNumber.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeSerialNumbers): unknown {
    const obj: any = {};
    if (message.serialNumbers) {
      obj.serialNumbers = message.serialNumbers.map((e) => e ? PpicPanelCodeSerialNumber.toJSON(e) : undefined);
    } else {
      obj.serialNumbers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeSerialNumbers>, I>>(base?: I): PpicPanelCodeSerialNumbers {
    return PpicPanelCodeSerialNumbers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeSerialNumbers>, I>>(object: I): PpicPanelCodeSerialNumbers {
    const message = createBasePpicPanelCodeSerialNumbers();
    message.serialNumbers = object.serialNumbers?.map((e) => PpicPanelCodeSerialNumber.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPanelCodeSerialNumberManual(): PpicPanelCodeSerialNumberManual {
  return { masterJavaBaseModel: undefined, panelCodeId: undefined, serialNumber: undefined, serialNumberRects: [] };
}

export const PpicPanelCodeSerialNumberManual = {
  encode(message: PpicPanelCodeSerialNumberManual, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(16).uint64(message.panelCodeId);
    }
    if (message.serialNumber !== undefined) {
      writer.uint32(26).string(message.serialNumber);
    }
    for (const v of message.serialNumberRects) {
      PpicPanelCodeSerialNumberRect.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeSerialNumberManual {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeSerialNumberManual();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.serialNumberRects.push(PpicPanelCodeSerialNumberRect.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeSerialNumberManual {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      serialNumber: isSet(object.serialNumber) ? String(object.serialNumber) : undefined,
      serialNumberRects: Array.isArray(object?.serialNumberRects)
        ? object.serialNumberRects.map((e: any) => PpicPanelCodeSerialNumberRect.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeSerialNumberManual): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.serialNumber !== undefined && (obj.serialNumber = message.serialNumber);
    if (message.serialNumberRects) {
      obj.serialNumberRects = message.serialNumberRects.map((e) =>
        e ? PpicPanelCodeSerialNumberRect.toJSON(e) : undefined
      );
    } else {
      obj.serialNumberRects = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberManual>, I>>(base?: I): PpicPanelCodeSerialNumberManual {
    return PpicPanelCodeSerialNumberManual.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberManual>, I>>(
    object: I,
  ): PpicPanelCodeSerialNumberManual {
    const message = createBasePpicPanelCodeSerialNumberManual();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.serialNumber = object.serialNumber ?? undefined;
    message.serialNumberRects = object.serialNumberRects?.map((e) => PpicPanelCodeSerialNumberRect.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBasePpicPanelCodeSerialNumberRect(): PpicPanelCodeSerialNumberRect {
  return {
    masterJavaBaseModel: undefined,
    panelCodeId: undefined,
    name: undefined,
    serialNumberId: undefined,
    serialNumberManualId: undefined,
  };
}

export const PpicPanelCodeSerialNumberRect = {
  encode(message: PpicPanelCodeSerialNumberRect, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(16).uint64(message.panelCodeId);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.serialNumberId !== undefined) {
      writer.uint32(32).uint64(message.serialNumberId);
    }
    if (message.serialNumberManualId !== undefined) {
      writer.uint32(40).uint64(message.serialNumberManualId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeSerialNumberRect {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeSerialNumberRect();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.serialNumberId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.serialNumberManualId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeSerialNumberRect {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      serialNumberId: isSet(object.serialNumberId) ? String(object.serialNumberId) : undefined,
      serialNumberManualId: isSet(object.serialNumberManualId) ? String(object.serialNumberManualId) : undefined,
    };
  },

  toJSON(message: PpicPanelCodeSerialNumberRect): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.name !== undefined && (obj.name = message.name);
    message.serialNumberId !== undefined && (obj.serialNumberId = message.serialNumberId);
    message.serialNumberManualId !== undefined && (obj.serialNumberManualId = message.serialNumberManualId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberRect>, I>>(base?: I): PpicPanelCodeSerialNumberRect {
    return PpicPanelCodeSerialNumberRect.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberRect>, I>>(
    object: I,
  ): PpicPanelCodeSerialNumberRect {
    const message = createBasePpicPanelCodeSerialNumberRect();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.name = object.name ?? undefined;
    message.serialNumberId = object.serialNumberId ?? undefined;
    message.serialNumberManualId = object.serialNumberManualId ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeSerialNumberManuals(): PpicPanelCodeSerialNumberManuals {
  return { serialNumbers: [] };
}

export const PpicPanelCodeSerialNumberManuals = {
  encode(message: PpicPanelCodeSerialNumberManuals, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.serialNumbers) {
      PpicPanelCodeSerialNumberManual.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeSerialNumberManuals {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeSerialNumberManuals();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumbers.push(PpicPanelCodeSerialNumberManual.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeSerialNumberManuals {
    return {
      serialNumbers: Array.isArray(object?.serialNumbers)
        ? object.serialNumbers.map((e: any) => PpicPanelCodeSerialNumberManual.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeSerialNumberManuals): unknown {
    const obj: any = {};
    if (message.serialNumbers) {
      obj.serialNumbers = message.serialNumbers.map((e) => e ? PpicPanelCodeSerialNumberManual.toJSON(e) : undefined);
    } else {
      obj.serialNumbers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberManuals>, I>>(
    base?: I,
  ): PpicPanelCodeSerialNumberManuals {
    return PpicPanelCodeSerialNumberManuals.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberManuals>, I>>(
    object: I,
  ): PpicPanelCodeSerialNumberManuals {
    const message = createBasePpicPanelCodeSerialNumberManuals();
    message.serialNumbers = object.serialNumbers?.map((e) => PpicPanelCodeSerialNumberManual.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicJobPanelCodeSerialNumberDone(): PpicJobPanelCodeSerialNumberDone {
  return {
    masterJavaBaseModel: undefined,
    serialNumber: undefined,
    completedDate: undefined,
    jobId: undefined,
    serialNumberId: undefined,
  };
}

export const PpicJobPanelCodeSerialNumberDone = {
  encode(message: PpicJobPanelCodeSerialNumberDone, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.serialNumber !== undefined) {
      PpicPanelCodeSerialNumber.encode(message.serialNumber, writer.uint32(18).fork()).ldelim();
    }
    if (message.completedDate !== undefined) {
      writer.uint32(26).string(message.completedDate);
    }
    if (message.jobId !== undefined) {
      writer.uint32(32).uint64(message.jobId);
    }
    if (message.serialNumberId !== undefined) {
      writer.uint32(40).uint64(message.serialNumberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobPanelCodeSerialNumberDone {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobPanelCodeSerialNumberDone();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serialNumber = PpicPanelCodeSerialNumber.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.completedDate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.serialNumberId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobPanelCodeSerialNumberDone {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      serialNumber: isSet(object.serialNumber) ? PpicPanelCodeSerialNumber.fromJSON(object.serialNumber) : undefined,
      completedDate: isSet(object.completedDate) ? String(object.completedDate) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      serialNumberId: isSet(object.serialNumberId) ? String(object.serialNumberId) : undefined,
    };
  },

  toJSON(message: PpicJobPanelCodeSerialNumberDone): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.serialNumber !== undefined &&
      (obj.serialNumber = message.serialNumber ? PpicPanelCodeSerialNumber.toJSON(message.serialNumber) : undefined);
    message.completedDate !== undefined && (obj.completedDate = message.completedDate);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.serialNumberId !== undefined && (obj.serialNumberId = message.serialNumberId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobPanelCodeSerialNumberDone>, I>>(
    base?: I,
  ): PpicJobPanelCodeSerialNumberDone {
    return PpicJobPanelCodeSerialNumberDone.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobPanelCodeSerialNumberDone>, I>>(
    object: I,
  ): PpicJobPanelCodeSerialNumberDone {
    const message = createBasePpicJobPanelCodeSerialNumberDone();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.serialNumber = (object.serialNumber !== undefined && object.serialNumber !== null)
      ? PpicPanelCodeSerialNumber.fromPartial(object.serialNumber)
      : undefined;
    message.completedDate = object.completedDate ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.serialNumberId = object.serialNumberId ?? undefined;
    return message;
  },
};

function createBasePpicWorkOrder(): PpicWorkOrder {
  return { masterJavaBaseModel: undefined, name: undefined, jobId: undefined };
}

export const PpicWorkOrder = {
  encode(message: PpicWorkOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.jobId !== undefined) {
      writer.uint32(24).uint64(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicWorkOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicWorkOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicWorkOrder {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
    };
  },

  toJSON(message: PpicWorkOrder): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicWorkOrder>, I>>(base?: I): PpicWorkOrder {
    return PpicWorkOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicWorkOrder>, I>>(object: I): PpicWorkOrder {
    const message = createBasePpicWorkOrder();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.jobId = object.jobId ?? undefined;
    return message;
  },
};

function createBasePpicWorkOrderRev(): PpicWorkOrderRev {
  return { masterJavaBaseModel: undefined, name: undefined, jobId: undefined };
}

export const PpicWorkOrderRev = {
  encode(message: PpicWorkOrderRev, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.jobId !== undefined) {
      writer.uint32(24).uint64(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicWorkOrderRev {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicWorkOrderRev();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicWorkOrderRev {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
    };
  },

  toJSON(message: PpicWorkOrderRev): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicWorkOrderRev>, I>>(base?: I): PpicWorkOrderRev {
    return PpicWorkOrderRev.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicWorkOrderRev>, I>>(object: I): PpicWorkOrderRev {
    const message = createBasePpicWorkOrderRev();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.jobId = object.jobId ?? undefined;
    return message;
  },
};

function createBasePpicWiringWorkOrder(): PpicWiringWorkOrder {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    jobName: undefined,
    panelCodeName: undefined,
    panelCodeType: undefined,
    panelCodeSerialNumber: undefined,
    date: undefined,
    wiringProcessType: undefined,
    wiringDescription: undefined,
    wiringRemark: undefined,
    isCustom: undefined,
    isDone: undefined,
    isCancelled: undefined,
    doneStatus: undefined,
    wiringProcessTypeFix: undefined,
    wiringProcessTypeFixId: undefined,
    deadlineDetail: undefined,
    deadlineDetailId: undefined,
    job: undefined,
    jobId: undefined,
    worker: undefined,
    workerId: undefined,
    createdBy: undefined,
    createdById: undefined,
    extMeetingTaskId: undefined,
    line: undefined,
    zone: undefined,
  };
}

export const PpicWiringWorkOrder = {
  encode(message: PpicWiringWorkOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.jobName !== undefined) {
      writer.uint32(26).string(message.jobName);
    }
    if (message.panelCodeName !== undefined) {
      writer.uint32(34).string(message.panelCodeName);
    }
    if (message.panelCodeType !== undefined) {
      writer.uint32(42).string(message.panelCodeType);
    }
    if (message.panelCodeSerialNumber !== undefined) {
      writer.uint32(50).string(message.panelCodeSerialNumber);
    }
    if (message.date !== undefined) {
      writer.uint32(58).string(message.date);
    }
    if (message.wiringProcessType !== undefined) {
      writer.uint32(66).string(message.wiringProcessType);
    }
    if (message.wiringDescription !== undefined) {
      writer.uint32(74).string(message.wiringDescription);
    }
    if (message.wiringRemark !== undefined) {
      writer.uint32(82).string(message.wiringRemark);
    }
    if (message.isCustom !== undefined) {
      writer.uint32(88).bool(message.isCustom);
    }
    if (message.isDone !== undefined) {
      writer.uint32(96).bool(message.isDone);
    }
    if (message.isCancelled !== undefined) {
      writer.uint32(104).bool(message.isCancelled);
    }
    if (message.doneStatus !== undefined) {
      writer.uint32(114).string(message.doneStatus);
    }
    if (message.wiringProcessTypeFix !== undefined) {
      PpicPanelCodeWiringProcessType.encode(message.wiringProcessTypeFix, writer.uint32(154).fork()).ldelim();
    }
    if (message.wiringProcessTypeFixId !== undefined) {
      writer.uint32(240).uint64(message.wiringProcessTypeFixId);
    }
    if (message.deadlineDetail !== undefined) {
      PpicDeadlineDetail.encode(message.deadlineDetail, writer.uint32(162).fork()).ldelim();
    }
    if (message.deadlineDetailId !== undefined) {
      writer.uint32(248).uint64(message.deadlineDetailId);
    }
    if (message.job !== undefined) {
      PpicJob.encode(message.job, writer.uint32(170).fork()).ldelim();
    }
    if (message.jobId !== undefined) {
      writer.uint32(216).uint64(message.jobId);
    }
    if (message.worker !== undefined) {
      PpicUser.encode(message.worker, writer.uint32(178).fork()).ldelim();
    }
    if (message.workerId !== undefined) {
      writer.uint32(224).uint64(message.workerId);
    }
    if (message.createdBy !== undefined) {
      PpicUser.encode(message.createdBy, writer.uint32(186).fork()).ldelim();
    }
    if (message.createdById !== undefined) {
      writer.uint32(232).uint64(message.createdById);
    }
    if (message.extMeetingTaskId !== undefined) {
      writer.uint32(192).uint64(message.extMeetingTaskId);
    }
    if (message.line !== undefined) {
      writer.uint32(202).string(message.line);
    }
    if (message.zone !== undefined) {
      writer.uint32(210).string(message.zone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicWiringWorkOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicWiringWorkOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.jobName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.panelCodeName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.panelCodeType = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.panelCodeSerialNumber = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.date = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.wiringProcessType = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.wiringDescription = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.wiringRemark = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.isCustom = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isDone = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isCancelled = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.doneStatus = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.wiringProcessTypeFix = PpicPanelCodeWiringProcessType.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.wiringProcessTypeFixId = longToString(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.deadlineDetail = PpicDeadlineDetail.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.deadlineDetailId = longToString(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.job = PpicJob.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.worker = PpicUser.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.workerId = longToString(reader.uint64() as Long);
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.createdBy = PpicUser.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.createdById = longToString(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.extMeetingTaskId = longToString(reader.uint64() as Long);
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.line = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.zone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicWiringWorkOrder {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      jobName: isSet(object.jobName) ? String(object.jobName) : undefined,
      panelCodeName: isSet(object.panelCodeName) ? String(object.panelCodeName) : undefined,
      panelCodeType: isSet(object.panelCodeType) ? String(object.panelCodeType) : undefined,
      panelCodeSerialNumber: isSet(object.panelCodeSerialNumber) ? String(object.panelCodeSerialNumber) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      wiringProcessType: isSet(object.wiringProcessType) ? String(object.wiringProcessType) : undefined,
      wiringDescription: isSet(object.wiringDescription) ? String(object.wiringDescription) : undefined,
      wiringRemark: isSet(object.wiringRemark) ? String(object.wiringRemark) : undefined,
      isCustom: isSet(object.isCustom) ? Boolean(object.isCustom) : undefined,
      isDone: isSet(object.isDone) ? Boolean(object.isDone) : undefined,
      isCancelled: isSet(object.isCancelled) ? Boolean(object.isCancelled) : undefined,
      doneStatus: isSet(object.doneStatus) ? String(object.doneStatus) : undefined,
      wiringProcessTypeFix: isSet(object.wiringProcessTypeFix)
        ? PpicPanelCodeWiringProcessType.fromJSON(object.wiringProcessTypeFix)
        : undefined,
      wiringProcessTypeFixId: isSet(object.wiringProcessTypeFixId) ? String(object.wiringProcessTypeFixId) : undefined,
      deadlineDetail: isSet(object.deadlineDetail) ? PpicDeadlineDetail.fromJSON(object.deadlineDetail) : undefined,
      deadlineDetailId: isSet(object.deadlineDetailId) ? String(object.deadlineDetailId) : undefined,
      job: isSet(object.job) ? PpicJob.fromJSON(object.job) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      worker: isSet(object.worker) ? PpicUser.fromJSON(object.worker) : undefined,
      workerId: isSet(object.workerId) ? String(object.workerId) : undefined,
      createdBy: isSet(object.createdBy) ? PpicUser.fromJSON(object.createdBy) : undefined,
      createdById: isSet(object.createdById) ? String(object.createdById) : undefined,
      extMeetingTaskId: isSet(object.extMeetingTaskId) ? String(object.extMeetingTaskId) : undefined,
      line: isSet(object.line) ? String(object.line) : undefined,
      zone: isSet(object.zone) ? String(object.zone) : undefined,
    };
  },

  toJSON(message: PpicWiringWorkOrder): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.jobName !== undefined && (obj.jobName = message.jobName);
    message.panelCodeName !== undefined && (obj.panelCodeName = message.panelCodeName);
    message.panelCodeType !== undefined && (obj.panelCodeType = message.panelCodeType);
    message.panelCodeSerialNumber !== undefined && (obj.panelCodeSerialNumber = message.panelCodeSerialNumber);
    message.date !== undefined && (obj.date = message.date);
    message.wiringProcessType !== undefined && (obj.wiringProcessType = message.wiringProcessType);
    message.wiringDescription !== undefined && (obj.wiringDescription = message.wiringDescription);
    message.wiringRemark !== undefined && (obj.wiringRemark = message.wiringRemark);
    message.isCustom !== undefined && (obj.isCustom = message.isCustom);
    message.isDone !== undefined && (obj.isDone = message.isDone);
    message.isCancelled !== undefined && (obj.isCancelled = message.isCancelled);
    message.doneStatus !== undefined && (obj.doneStatus = message.doneStatus);
    message.wiringProcessTypeFix !== undefined && (obj.wiringProcessTypeFix = message.wiringProcessTypeFix
      ? PpicPanelCodeWiringProcessType.toJSON(message.wiringProcessTypeFix)
      : undefined);
    message.wiringProcessTypeFixId !== undefined && (obj.wiringProcessTypeFixId = message.wiringProcessTypeFixId);
    message.deadlineDetail !== undefined &&
      (obj.deadlineDetail = message.deadlineDetail ? PpicDeadlineDetail.toJSON(message.deadlineDetail) : undefined);
    message.deadlineDetailId !== undefined && (obj.deadlineDetailId = message.deadlineDetailId);
    message.job !== undefined && (obj.job = message.job ? PpicJob.toJSON(message.job) : undefined);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.worker !== undefined && (obj.worker = message.worker ? PpicUser.toJSON(message.worker) : undefined);
    message.workerId !== undefined && (obj.workerId = message.workerId);
    message.createdBy !== undefined &&
      (obj.createdBy = message.createdBy ? PpicUser.toJSON(message.createdBy) : undefined);
    message.createdById !== undefined && (obj.createdById = message.createdById);
    message.extMeetingTaskId !== undefined && (obj.extMeetingTaskId = message.extMeetingTaskId);
    message.line !== undefined && (obj.line = message.line);
    message.zone !== undefined && (obj.zone = message.zone);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicWiringWorkOrder>, I>>(base?: I): PpicWiringWorkOrder {
    return PpicWiringWorkOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicWiringWorkOrder>, I>>(object: I): PpicWiringWorkOrder {
    const message = createBasePpicWiringWorkOrder();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.jobName = object.jobName ?? undefined;
    message.panelCodeName = object.panelCodeName ?? undefined;
    message.panelCodeType = object.panelCodeType ?? undefined;
    message.panelCodeSerialNumber = object.panelCodeSerialNumber ?? undefined;
    message.date = object.date ?? undefined;
    message.wiringProcessType = object.wiringProcessType ?? undefined;
    message.wiringDescription = object.wiringDescription ?? undefined;
    message.wiringRemark = object.wiringRemark ?? undefined;
    message.isCustom = object.isCustom ?? undefined;
    message.isDone = object.isDone ?? undefined;
    message.isCancelled = object.isCancelled ?? undefined;
    message.doneStatus = object.doneStatus ?? undefined;
    message.wiringProcessTypeFix = (object.wiringProcessTypeFix !== undefined && object.wiringProcessTypeFix !== null)
      ? PpicPanelCodeWiringProcessType.fromPartial(object.wiringProcessTypeFix)
      : undefined;
    message.wiringProcessTypeFixId = object.wiringProcessTypeFixId ?? undefined;
    message.deadlineDetail = (object.deadlineDetail !== undefined && object.deadlineDetail !== null)
      ? PpicDeadlineDetail.fromPartial(object.deadlineDetail)
      : undefined;
    message.deadlineDetailId = object.deadlineDetailId ?? undefined;
    message.job = (object.job !== undefined && object.job !== null) ? PpicJob.fromPartial(object.job) : undefined;
    message.jobId = object.jobId ?? undefined;
    message.worker = (object.worker !== undefined && object.worker !== null)
      ? PpicUser.fromPartial(object.worker)
      : undefined;
    message.workerId = object.workerId ?? undefined;
    message.createdBy = (object.createdBy !== undefined && object.createdBy !== null)
      ? PpicUser.fromPartial(object.createdBy)
      : undefined;
    message.createdById = object.createdById ?? undefined;
    message.extMeetingTaskId = object.extMeetingTaskId ?? undefined;
    message.line = object.line ?? undefined;
    message.zone = object.zone ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeWiringProcessType(): PpicPanelCodeWiringProcessType {
  return { masterJavaBaseModel: undefined, name: undefined, panelCodeId: undefined };
}

export const PpicPanelCodeWiringProcessType = {
  encode(message: PpicPanelCodeWiringProcessType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(24).uint64(message.panelCodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeWiringProcessType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeWiringProcessType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeWiringProcessType {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
    };
  },

  toJSON(message: PpicPanelCodeWiringProcessType): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeWiringProcessType>, I>>(base?: I): PpicPanelCodeWiringProcessType {
    return PpicPanelCodeWiringProcessType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeWiringProcessType>, I>>(
    object: I,
  ): PpicPanelCodeWiringProcessType {
    const message = createBasePpicPanelCodeWiringProcessType();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    return message;
  },
};

function createBasePpicUser(): PpicUser {
  return { masterJavaBaseModel: undefined, name: undefined };
}

export const PpicUser = {
  encode(message: PpicUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicUser {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: PpicUser): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicUser>, I>>(base?: I): PpicUser {
    return PpicUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicUser>, I>>(object: I): PpicUser {
    const message = createBasePpicUser();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBasePpicPanelCode(): PpicPanelCode {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    type: undefined,
    serialNumber: undefined,
    departmentTemplates: [],
    departmentItemStatuses: [],
    jobId: undefined,
    connections: undefined,
    closed: undefined,
    deadline: undefined,
    job: undefined,
    departmentTemplateItems: [],
    qty: undefined,
    extItemId: undefined,
    machinePrograms: [],
    serialNumbers: [],
    panelCodeType: undefined,
    programTreeTime: undefined,
    productTreeTime: undefined,
    deptTemplateTime: undefined,
    firstPanelCodeWorkOrderDate: undefined,
    firstPanelCodeWorkOrderId: undefined,
    timeWoAssigned: undefined,
    timeWoDone: undefined,
    serialNumberManuals: [],
    remarks: [],
    extDefaultQcId: undefined,
    extDefaultQaId: undefined,
    extCrmPurchaseOrderItemId: undefined,
    serialNumberRects: [],
    panelCodeDepartmentTemplateItems: [],
    panelCodeDepartmentTemplates: [],
    extJdpPresetId: undefined,
    batch: undefined,
    productTreeCount: undefined,
    productTreeDone: undefined,
    programProgress: [],
    extMaterialInstallationItemId: undefined,
    totalDone: undefined,
    totalCount: undefined,
    totalPercent: undefined,
    panelCodeMachineProgramExclusions: [],
    lastStep: undefined,
  };
}

export const PpicPanelCode = {
  encode(message: PpicPanelCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(26).string(message.type);
    }
    if (message.serialNumber !== undefined) {
      writer.uint32(34).string(message.serialNumber);
    }
    for (const v of message.departmentTemplates) {
      PpicPanelCodeDepartmentTemplate.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.departmentItemStatuses) {
      PpicDepartmentTemplateItemStatus.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.jobId !== undefined) {
      writer.uint32(56).uint64(message.jobId);
    }
    if (message.connections !== undefined) {
      writer.uint32(64).int64(message.connections);
    }
    if (message.closed !== undefined) {
      writer.uint32(72).bool(message.closed);
    }
    if (message.deadline !== undefined) {
      writer.uint32(82).string(message.deadline);
    }
    if (message.job !== undefined) {
      PpicJob.encode(message.job, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.departmentTemplateItems) {
      PpicPanelCodeDepartmentTemplateItem.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.qty !== undefined) {
      writer.uint32(96).int32(message.qty);
    }
    if (message.extItemId !== undefined) {
      writer.uint32(112).uint64(message.extItemId);
    }
    for (const v of message.machinePrograms) {
      PpicMachineProgram.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.serialNumbers) {
      PpicPanelCodeSerialNumber.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    if (message.panelCodeType !== undefined) {
      writer.uint32(138).string(message.panelCodeType);
    }
    if (message.programTreeTime !== undefined) {
      writer.uint32(145).double(message.programTreeTime);
    }
    if (message.productTreeTime !== undefined) {
      writer.uint32(153).double(message.productTreeTime);
    }
    if (message.deptTemplateTime !== undefined) {
      writer.uint32(177).double(message.deptTemplateTime);
    }
    if (message.firstPanelCodeWorkOrderDate !== undefined) {
      writer.uint32(162).string(message.firstPanelCodeWorkOrderDate);
    }
    if (message.firstPanelCodeWorkOrderId !== undefined) {
      writer.uint32(168).uint64(message.firstPanelCodeWorkOrderId);
    }
    if (message.timeWoAssigned !== undefined) {
      writer.uint32(185).double(message.timeWoAssigned);
    }
    if (message.timeWoDone !== undefined) {
      writer.uint32(193).double(message.timeWoDone);
    }
    for (const v of message.serialNumberManuals) {
      PpicPanelCodeSerialNumberManual.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    for (const v of message.remarks) {
      PpicPanelCodeRemark.encode(v!, writer.uint32(210).fork()).ldelim();
    }
    if (message.extDefaultQcId !== undefined) {
      writer.uint32(216).uint64(message.extDefaultQcId);
    }
    if (message.extDefaultQaId !== undefined) {
      writer.uint32(224).uint64(message.extDefaultQaId);
    }
    if (message.extCrmPurchaseOrderItemId !== undefined) {
      writer.uint32(232).uint64(message.extCrmPurchaseOrderItemId);
    }
    for (const v of message.serialNumberRects) {
      PpicPanelCodeSerialNumberRect.encode(v!, writer.uint32(242).fork()).ldelim();
    }
    for (const v of message.panelCodeDepartmentTemplateItems) {
      PpicPanelCodeDepartmentTemplateItem.encode(v!, writer.uint32(250).fork()).ldelim();
    }
    for (const v of message.panelCodeDepartmentTemplates) {
      PpicPanelCodeDepartmentTemplate.encode(v!, writer.uint32(258).fork()).ldelim();
    }
    if (message.extJdpPresetId !== undefined) {
      writer.uint32(264).uint64(message.extJdpPresetId);
    }
    if (message.batch !== undefined) {
      writer.uint32(272).int32(message.batch);
    }
    if (message.productTreeCount !== undefined) {
      writer.uint32(281).double(message.productTreeCount);
    }
    if (message.productTreeDone !== undefined) {
      writer.uint32(289).double(message.productTreeDone);
    }
    for (const v of message.programProgress) {
      PpicSimplifiedProcessTypeProgramProgress.encode(v!, writer.uint32(298).fork()).ldelim();
    }
    if (message.extMaterialInstallationItemId !== undefined) {
      writer.uint32(304).uint64(message.extMaterialInstallationItemId);
    }
    if (message.totalDone !== undefined) {
      writer.uint32(313).double(message.totalDone);
    }
    if (message.totalCount !== undefined) {
      writer.uint32(321).double(message.totalCount);
    }
    if (message.totalPercent !== undefined) {
      writer.uint32(329).double(message.totalPercent);
    }
    for (const v of message.panelCodeMachineProgramExclusions) {
      PpicMachineProgramExclusion.encode(v!, writer.uint32(338).fork()).ldelim();
    }
    if (message.lastStep !== undefined) {
      writer.uint32(346).string(message.lastStep);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.departmentTemplates.push(PpicPanelCodeDepartmentTemplate.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.departmentItemStatuses.push(PpicDepartmentTemplateItemStatus.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.connections = longToString(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.closed = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deadline = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.job = PpicJob.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.departmentTemplateItems.push(PpicPanelCodeDepartmentTemplateItem.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.qty = reader.int32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.machinePrograms.push(PpicMachineProgram.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.serialNumbers.push(PpicPanelCodeSerialNumber.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.panelCodeType = reader.string();
          continue;
        case 18:
          if (tag !== 145) {
            break;
          }

          message.programTreeTime = reader.double();
          continue;
        case 19:
          if (tag !== 153) {
            break;
          }

          message.productTreeTime = reader.double();
          continue;
        case 22:
          if (tag !== 177) {
            break;
          }

          message.deptTemplateTime = reader.double();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.firstPanelCodeWorkOrderDate = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.firstPanelCodeWorkOrderId = longToString(reader.uint64() as Long);
          continue;
        case 23:
          if (tag !== 185) {
            break;
          }

          message.timeWoAssigned = reader.double();
          continue;
        case 24:
          if (tag !== 193) {
            break;
          }

          message.timeWoDone = reader.double();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.serialNumberManuals.push(PpicPanelCodeSerialNumberManual.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.remarks.push(PpicPanelCodeRemark.decode(reader, reader.uint32()));
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.extDefaultQcId = longToString(reader.uint64() as Long);
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.extDefaultQaId = longToString(reader.uint64() as Long);
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.extCrmPurchaseOrderItemId = longToString(reader.uint64() as Long);
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.serialNumberRects.push(PpicPanelCodeSerialNumberRect.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.panelCodeDepartmentTemplateItems.push(
            PpicPanelCodeDepartmentTemplateItem.decode(reader, reader.uint32()),
          );
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.panelCodeDepartmentTemplates.push(PpicPanelCodeDepartmentTemplate.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.extJdpPresetId = longToString(reader.uint64() as Long);
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.batch = reader.int32();
          continue;
        case 35:
          if (tag !== 281) {
            break;
          }

          message.productTreeCount = reader.double();
          continue;
        case 36:
          if (tag !== 289) {
            break;
          }

          message.productTreeDone = reader.double();
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.programProgress.push(PpicSimplifiedProcessTypeProgramProgress.decode(reader, reader.uint32()));
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.extMaterialInstallationItemId = longToString(reader.uint64() as Long);
          continue;
        case 39:
          if (tag !== 313) {
            break;
          }

          message.totalDone = reader.double();
          continue;
        case 40:
          if (tag !== 321) {
            break;
          }

          message.totalCount = reader.double();
          continue;
        case 41:
          if (tag !== 329) {
            break;
          }

          message.totalPercent = reader.double();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.panelCodeMachineProgramExclusions.push(PpicMachineProgramExclusion.decode(reader, reader.uint32()));
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.lastStep = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCode {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      serialNumber: isSet(object.serialNumber) ? String(object.serialNumber) : undefined,
      departmentTemplates: Array.isArray(object?.departmentTemplates)
        ? object.departmentTemplates.map((e: any) => PpicPanelCodeDepartmentTemplate.fromJSON(e))
        : [],
      departmentItemStatuses: Array.isArray(object?.departmentItemStatuses)
        ? object.departmentItemStatuses.map((e: any) => PpicDepartmentTemplateItemStatus.fromJSON(e))
        : [],
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      connections: isSet(object.connections) ? String(object.connections) : undefined,
      closed: isSet(object.closed) ? Boolean(object.closed) : undefined,
      deadline: isSet(object.deadline) ? String(object.deadline) : undefined,
      job: isSet(object.job) ? PpicJob.fromJSON(object.job) : undefined,
      departmentTemplateItems: Array.isArray(object?.departmentTemplateItems)
        ? object.departmentTemplateItems.map((e: any) => PpicPanelCodeDepartmentTemplateItem.fromJSON(e))
        : [],
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      machinePrograms: Array.isArray(object?.machinePrograms)
        ? object.machinePrograms.map((e: any) => PpicMachineProgram.fromJSON(e))
        : [],
      serialNumbers: Array.isArray(object?.serialNumbers)
        ? object.serialNumbers.map((e: any) => PpicPanelCodeSerialNumber.fromJSON(e))
        : [],
      panelCodeType: isSet(object.panelCodeType) ? String(object.panelCodeType) : undefined,
      programTreeTime: isSet(object.programTreeTime) ? Number(object.programTreeTime) : undefined,
      productTreeTime: isSet(object.productTreeTime) ? Number(object.productTreeTime) : undefined,
      deptTemplateTime: isSet(object.deptTemplateTime) ? Number(object.deptTemplateTime) : undefined,
      firstPanelCodeWorkOrderDate: isSet(object.firstPanelCodeWorkOrderDate)
        ? String(object.firstPanelCodeWorkOrderDate)
        : undefined,
      firstPanelCodeWorkOrderId: isSet(object.firstPanelCodeWorkOrderId)
        ? String(object.firstPanelCodeWorkOrderId)
        : undefined,
      timeWoAssigned: isSet(object.timeWoAssigned) ? Number(object.timeWoAssigned) : undefined,
      timeWoDone: isSet(object.timeWoDone) ? Number(object.timeWoDone) : undefined,
      serialNumberManuals: Array.isArray(object?.serialNumberManuals)
        ? object.serialNumberManuals.map((e: any) => PpicPanelCodeSerialNumberManual.fromJSON(e))
        : [],
      remarks: Array.isArray(object?.remarks)
        ? object.remarks.map((e: any) => PpicPanelCodeRemark.fromJSON(e))
        : [],
      extDefaultQcId: isSet(object.extDefaultQcId) ? String(object.extDefaultQcId) : undefined,
      extDefaultQaId: isSet(object.extDefaultQaId) ? String(object.extDefaultQaId) : undefined,
      extCrmPurchaseOrderItemId: isSet(object.extCrmPurchaseOrderItemId)
        ? String(object.extCrmPurchaseOrderItemId)
        : undefined,
      serialNumberRects: Array.isArray(object?.serialNumberRects)
        ? object.serialNumberRects.map((e: any) => PpicPanelCodeSerialNumberRect.fromJSON(e))
        : [],
      panelCodeDepartmentTemplateItems: Array.isArray(object?.panelCodeDepartmentTemplateItems)
        ? object.panelCodeDepartmentTemplateItems.map((e: any) => PpicPanelCodeDepartmentTemplateItem.fromJSON(e))
        : [],
      panelCodeDepartmentTemplates: Array.isArray(object?.panelCodeDepartmentTemplates)
        ? object.panelCodeDepartmentTemplates.map((e: any) => PpicPanelCodeDepartmentTemplate.fromJSON(e))
        : [],
      extJdpPresetId: isSet(object.extJdpPresetId) ? String(object.extJdpPresetId) : undefined,
      batch: isSet(object.batch) ? Number(object.batch) : undefined,
      productTreeCount: isSet(object.productTreeCount) ? Number(object.productTreeCount) : undefined,
      productTreeDone: isSet(object.productTreeDone) ? Number(object.productTreeDone) : undefined,
      programProgress: Array.isArray(object?.programProgress)
        ? object.programProgress.map((e: any) => PpicSimplifiedProcessTypeProgramProgress.fromJSON(e))
        : [],
      extMaterialInstallationItemId: isSet(object.extMaterialInstallationItemId)
        ? String(object.extMaterialInstallationItemId)
        : undefined,
      totalDone: isSet(object.totalDone) ? Number(object.totalDone) : undefined,
      totalCount: isSet(object.totalCount) ? Number(object.totalCount) : undefined,
      totalPercent: isSet(object.totalPercent) ? Number(object.totalPercent) : undefined,
      panelCodeMachineProgramExclusions: Array.isArray(object?.panelCodeMachineProgramExclusions)
        ? object.panelCodeMachineProgramExclusions.map((e: any) => PpicMachineProgramExclusion.fromJSON(e))
        : [],
      lastStep: isSet(object.lastStep) ? String(object.lastStep) : undefined,
    };
  },

  toJSON(message: PpicPanelCode): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.serialNumber !== undefined && (obj.serialNumber = message.serialNumber);
    if (message.departmentTemplates) {
      obj.departmentTemplates = message.departmentTemplates.map((e) =>
        e ? PpicPanelCodeDepartmentTemplate.toJSON(e) : undefined
      );
    } else {
      obj.departmentTemplates = [];
    }
    if (message.departmentItemStatuses) {
      obj.departmentItemStatuses = message.departmentItemStatuses.map((e) =>
        e ? PpicDepartmentTemplateItemStatus.toJSON(e) : undefined
      );
    } else {
      obj.departmentItemStatuses = [];
    }
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.connections !== undefined && (obj.connections = message.connections);
    message.closed !== undefined && (obj.closed = message.closed);
    message.deadline !== undefined && (obj.deadline = message.deadline);
    message.job !== undefined && (obj.job = message.job ? PpicJob.toJSON(message.job) : undefined);
    if (message.departmentTemplateItems) {
      obj.departmentTemplateItems = message.departmentTemplateItems.map((e) =>
        e ? PpicPanelCodeDepartmentTemplateItem.toJSON(e) : undefined
      );
    } else {
      obj.departmentTemplateItems = [];
    }
    message.qty !== undefined && (obj.qty = Math.round(message.qty));
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    if (message.machinePrograms) {
      obj.machinePrograms = message.machinePrograms.map((e) => e ? PpicMachineProgram.toJSON(e) : undefined);
    } else {
      obj.machinePrograms = [];
    }
    if (message.serialNumbers) {
      obj.serialNumbers = message.serialNumbers.map((e) => e ? PpicPanelCodeSerialNumber.toJSON(e) : undefined);
    } else {
      obj.serialNumbers = [];
    }
    message.panelCodeType !== undefined && (obj.panelCodeType = message.panelCodeType);
    message.programTreeTime !== undefined && (obj.programTreeTime = message.programTreeTime);
    message.productTreeTime !== undefined && (obj.productTreeTime = message.productTreeTime);
    message.deptTemplateTime !== undefined && (obj.deptTemplateTime = message.deptTemplateTime);
    message.firstPanelCodeWorkOrderDate !== undefined &&
      (obj.firstPanelCodeWorkOrderDate = message.firstPanelCodeWorkOrderDate);
    message.firstPanelCodeWorkOrderId !== undefined &&
      (obj.firstPanelCodeWorkOrderId = message.firstPanelCodeWorkOrderId);
    message.timeWoAssigned !== undefined && (obj.timeWoAssigned = message.timeWoAssigned);
    message.timeWoDone !== undefined && (obj.timeWoDone = message.timeWoDone);
    if (message.serialNumberManuals) {
      obj.serialNumberManuals = message.serialNumberManuals.map((e) =>
        e ? PpicPanelCodeSerialNumberManual.toJSON(e) : undefined
      );
    } else {
      obj.serialNumberManuals = [];
    }
    if (message.remarks) {
      obj.remarks = message.remarks.map((e) => e ? PpicPanelCodeRemark.toJSON(e) : undefined);
    } else {
      obj.remarks = [];
    }
    message.extDefaultQcId !== undefined && (obj.extDefaultQcId = message.extDefaultQcId);
    message.extDefaultQaId !== undefined && (obj.extDefaultQaId = message.extDefaultQaId);
    message.extCrmPurchaseOrderItemId !== undefined &&
      (obj.extCrmPurchaseOrderItemId = message.extCrmPurchaseOrderItemId);
    if (message.serialNumberRects) {
      obj.serialNumberRects = message.serialNumberRects.map((e) =>
        e ? PpicPanelCodeSerialNumberRect.toJSON(e) : undefined
      );
    } else {
      obj.serialNumberRects = [];
    }
    if (message.panelCodeDepartmentTemplateItems) {
      obj.panelCodeDepartmentTemplateItems = message.panelCodeDepartmentTemplateItems.map((e) =>
        e ? PpicPanelCodeDepartmentTemplateItem.toJSON(e) : undefined
      );
    } else {
      obj.panelCodeDepartmentTemplateItems = [];
    }
    if (message.panelCodeDepartmentTemplates) {
      obj.panelCodeDepartmentTemplates = message.panelCodeDepartmentTemplates.map((e) =>
        e ? PpicPanelCodeDepartmentTemplate.toJSON(e) : undefined
      );
    } else {
      obj.panelCodeDepartmentTemplates = [];
    }
    message.extJdpPresetId !== undefined && (obj.extJdpPresetId = message.extJdpPresetId);
    message.batch !== undefined && (obj.batch = Math.round(message.batch));
    message.productTreeCount !== undefined && (obj.productTreeCount = message.productTreeCount);
    message.productTreeDone !== undefined && (obj.productTreeDone = message.productTreeDone);
    if (message.programProgress) {
      obj.programProgress = message.programProgress.map((e) =>
        e ? PpicSimplifiedProcessTypeProgramProgress.toJSON(e) : undefined
      );
    } else {
      obj.programProgress = [];
    }
    message.extMaterialInstallationItemId !== undefined &&
      (obj.extMaterialInstallationItemId = message.extMaterialInstallationItemId);
    message.totalDone !== undefined && (obj.totalDone = message.totalDone);
    message.totalCount !== undefined && (obj.totalCount = message.totalCount);
    message.totalPercent !== undefined && (obj.totalPercent = message.totalPercent);
    if (message.panelCodeMachineProgramExclusions) {
      obj.panelCodeMachineProgramExclusions = message.panelCodeMachineProgramExclusions.map((e) =>
        e ? PpicMachineProgramExclusion.toJSON(e) : undefined
      );
    } else {
      obj.panelCodeMachineProgramExclusions = [];
    }
    message.lastStep !== undefined && (obj.lastStep = message.lastStep);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCode>, I>>(base?: I): PpicPanelCode {
    return PpicPanelCode.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCode>, I>>(object: I): PpicPanelCode {
    const message = createBasePpicPanelCode();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.serialNumber = object.serialNumber ?? undefined;
    message.departmentTemplates =
      object.departmentTemplates?.map((e) => PpicPanelCodeDepartmentTemplate.fromPartial(e)) || [];
    message.departmentItemStatuses =
      object.departmentItemStatuses?.map((e) => PpicDepartmentTemplateItemStatus.fromPartial(e)) || [];
    message.jobId = object.jobId ?? undefined;
    message.connections = object.connections ?? undefined;
    message.closed = object.closed ?? undefined;
    message.deadline = object.deadline ?? undefined;
    message.job = (object.job !== undefined && object.job !== null) ? PpicJob.fromPartial(object.job) : undefined;
    message.departmentTemplateItems =
      object.departmentTemplateItems?.map((e) => PpicPanelCodeDepartmentTemplateItem.fromPartial(e)) || [];
    message.qty = object.qty ?? undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.machinePrograms = object.machinePrograms?.map((e) => PpicMachineProgram.fromPartial(e)) || [];
    message.serialNumbers = object.serialNumbers?.map((e) => PpicPanelCodeSerialNumber.fromPartial(e)) || [];
    message.panelCodeType = object.panelCodeType ?? undefined;
    message.programTreeTime = object.programTreeTime ?? undefined;
    message.productTreeTime = object.productTreeTime ?? undefined;
    message.deptTemplateTime = object.deptTemplateTime ?? undefined;
    message.firstPanelCodeWorkOrderDate = object.firstPanelCodeWorkOrderDate ?? undefined;
    message.firstPanelCodeWorkOrderId = object.firstPanelCodeWorkOrderId ?? undefined;
    message.timeWoAssigned = object.timeWoAssigned ?? undefined;
    message.timeWoDone = object.timeWoDone ?? undefined;
    message.serialNumberManuals =
      object.serialNumberManuals?.map((e) => PpicPanelCodeSerialNumberManual.fromPartial(e)) || [];
    message.remarks = object.remarks?.map((e) => PpicPanelCodeRemark.fromPartial(e)) || [];
    message.extDefaultQcId = object.extDefaultQcId ?? undefined;
    message.extDefaultQaId = object.extDefaultQaId ?? undefined;
    message.extCrmPurchaseOrderItemId = object.extCrmPurchaseOrderItemId ?? undefined;
    message.serialNumberRects = object.serialNumberRects?.map((e) => PpicPanelCodeSerialNumberRect.fromPartial(e)) ||
      [];
    message.panelCodeDepartmentTemplateItems =
      object.panelCodeDepartmentTemplateItems?.map((e) => PpicPanelCodeDepartmentTemplateItem.fromPartial(e)) || [];
    message.panelCodeDepartmentTemplates =
      object.panelCodeDepartmentTemplates?.map((e) => PpicPanelCodeDepartmentTemplate.fromPartial(e)) || [];
    message.extJdpPresetId = object.extJdpPresetId ?? undefined;
    message.batch = object.batch ?? undefined;
    message.productTreeCount = object.productTreeCount ?? undefined;
    message.productTreeDone = object.productTreeDone ?? undefined;
    message.programProgress =
      object.programProgress?.map((e) => PpicSimplifiedProcessTypeProgramProgress.fromPartial(e)) || [];
    message.extMaterialInstallationItemId = object.extMaterialInstallationItemId ?? undefined;
    message.totalDone = object.totalDone ?? undefined;
    message.totalCount = object.totalCount ?? undefined;
    message.totalPercent = object.totalPercent ?? undefined;
    message.panelCodeMachineProgramExclusions =
      object.panelCodeMachineProgramExclusions?.map((e) => PpicMachineProgramExclusion.fromPartial(e)) || [];
    message.lastStep = object.lastStep ?? undefined;
    return message;
  },
};

function createBasePpicMachineProgramExclusion(): PpicMachineProgramExclusion {
  return { masterJavaBaseModel: undefined, simplifiedProcessTypeId: undefined, excluded: undefined };
}

export const PpicMachineProgramExclusion = {
  encode(message: PpicMachineProgramExclusion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.simplifiedProcessTypeId !== undefined) {
      writer.uint32(16).uint64(message.simplifiedProcessTypeId);
    }
    if (message.excluded !== undefined) {
      writer.uint32(24).bool(message.excluded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMachineProgramExclusion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMachineProgramExclusion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.simplifiedProcessTypeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.excluded = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMachineProgramExclusion {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      simplifiedProcessTypeId: isSet(object.simplifiedProcessTypeId)
        ? String(object.simplifiedProcessTypeId)
        : undefined,
      excluded: isSet(object.excluded) ? Boolean(object.excluded) : undefined,
    };
  },

  toJSON(message: PpicMachineProgramExclusion): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.simplifiedProcessTypeId !== undefined && (obj.simplifiedProcessTypeId = message.simplifiedProcessTypeId);
    message.excluded !== undefined && (obj.excluded = message.excluded);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMachineProgramExclusion>, I>>(base?: I): PpicMachineProgramExclusion {
    return PpicMachineProgramExclusion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMachineProgramExclusion>, I>>(object: I): PpicMachineProgramExclusion {
    const message = createBasePpicMachineProgramExclusion();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.simplifiedProcessTypeId = object.simplifiedProcessTypeId ?? undefined;
    message.excluded = object.excluded ?? undefined;
    return message;
  },
};

function createBaseMeetingExtProblematicPurchaseOrder(): MeetingExtProblematicPurchaseOrder {
  return { masterJavaBaseModel: undefined, extPurchaseOrderId: undefined, isProblematic: undefined };
}

export const MeetingExtProblematicPurchaseOrder = {
  encode(message: MeetingExtProblematicPurchaseOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extPurchaseOrderId !== undefined) {
      writer.uint32(16).uint64(message.extPurchaseOrderId);
    }
    if (message.isProblematic !== undefined) {
      writer.uint32(24).bool(message.isProblematic);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingExtProblematicPurchaseOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingExtProblematicPurchaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extPurchaseOrderId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isProblematic = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingExtProblematicPurchaseOrder {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extPurchaseOrderId: isSet(object.extPurchaseOrderId) ? String(object.extPurchaseOrderId) : undefined,
      isProblematic: isSet(object.isProblematic) ? Boolean(object.isProblematic) : undefined,
    };
  },

  toJSON(message: MeetingExtProblematicPurchaseOrder): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extPurchaseOrderId !== undefined && (obj.extPurchaseOrderId = message.extPurchaseOrderId);
    message.isProblematic !== undefined && (obj.isProblematic = message.isProblematic);
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingExtProblematicPurchaseOrder>, I>>(
    base?: I,
  ): MeetingExtProblematicPurchaseOrder {
    return MeetingExtProblematicPurchaseOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingExtProblematicPurchaseOrder>, I>>(
    object: I,
  ): MeetingExtProblematicPurchaseOrder {
    const message = createBaseMeetingExtProblematicPurchaseOrder();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extPurchaseOrderId = object.extPurchaseOrderId ?? undefined;
    message.isProblematic = object.isProblematic ?? undefined;
    return message;
  },
};

function createBaseMeetingExtProblematicPurchaseOrders(): MeetingExtProblematicPurchaseOrders {
  return { purchaseOrders: [] };
}

export const MeetingExtProblematicPurchaseOrders = {
  encode(message: MeetingExtProblematicPurchaseOrders, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.purchaseOrders) {
      MeetingExtProblematicPurchaseOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MeetingExtProblematicPurchaseOrders {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMeetingExtProblematicPurchaseOrders();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.purchaseOrders.push(MeetingExtProblematicPurchaseOrder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MeetingExtProblematicPurchaseOrders {
    return {
      purchaseOrders: Array.isArray(object?.purchaseOrders)
        ? object.purchaseOrders.map((e: any) => MeetingExtProblematicPurchaseOrder.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MeetingExtProblematicPurchaseOrders): unknown {
    const obj: any = {};
    if (message.purchaseOrders) {
      obj.purchaseOrders = message.purchaseOrders.map((e) =>
        e ? MeetingExtProblematicPurchaseOrder.toJSON(e) : undefined
      );
    } else {
      obj.purchaseOrders = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MeetingExtProblematicPurchaseOrders>, I>>(
    base?: I,
  ): MeetingExtProblematicPurchaseOrders {
    return MeetingExtProblematicPurchaseOrders.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MeetingExtProblematicPurchaseOrders>, I>>(
    object: I,
  ): MeetingExtProblematicPurchaseOrders {
    const message = createBaseMeetingExtProblematicPurchaseOrders();
    message.purchaseOrders = object.purchaseOrders?.map((e) => MeetingExtProblematicPurchaseOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicSimplifiedProcessTypeProgramProgress(): PpicSimplifiedProcessTypeProgramProgress {
  return { simplifiedProcessTypeId: undefined, programTreeCount: undefined, programTreeDone: undefined };
}

export const PpicSimplifiedProcessTypeProgramProgress = {
  encode(message: PpicSimplifiedProcessTypeProgramProgress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.simplifiedProcessTypeId !== undefined) {
      writer.uint32(296).uint64(message.simplifiedProcessTypeId);
    }
    if (message.programTreeCount !== undefined) {
      writer.uint32(281).double(message.programTreeCount);
    }
    if (message.programTreeDone !== undefined) {
      writer.uint32(289).double(message.programTreeDone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicSimplifiedProcessTypeProgramProgress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicSimplifiedProcessTypeProgramProgress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 37:
          if (tag !== 296) {
            break;
          }

          message.simplifiedProcessTypeId = longToString(reader.uint64() as Long);
          continue;
        case 35:
          if (tag !== 281) {
            break;
          }

          message.programTreeCount = reader.double();
          continue;
        case 36:
          if (tag !== 289) {
            break;
          }

          message.programTreeDone = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicSimplifiedProcessTypeProgramProgress {
    return {
      simplifiedProcessTypeId: isSet(object.simplifiedProcessTypeId)
        ? String(object.simplifiedProcessTypeId)
        : undefined,
      programTreeCount: isSet(object.programTreeCount) ? Number(object.programTreeCount) : undefined,
      programTreeDone: isSet(object.programTreeDone) ? Number(object.programTreeDone) : undefined,
    };
  },

  toJSON(message: PpicSimplifiedProcessTypeProgramProgress): unknown {
    const obj: any = {};
    message.simplifiedProcessTypeId !== undefined && (obj.simplifiedProcessTypeId = message.simplifiedProcessTypeId);
    message.programTreeCount !== undefined && (obj.programTreeCount = message.programTreeCount);
    message.programTreeDone !== undefined && (obj.programTreeDone = message.programTreeDone);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicSimplifiedProcessTypeProgramProgress>, I>>(
    base?: I,
  ): PpicSimplifiedProcessTypeProgramProgress {
    return PpicSimplifiedProcessTypeProgramProgress.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicSimplifiedProcessTypeProgramProgress>, I>>(
    object: I,
  ): PpicSimplifiedProcessTypeProgramProgress {
    const message = createBasePpicSimplifiedProcessTypeProgramProgress();
    message.simplifiedProcessTypeId = object.simplifiedProcessTypeId ?? undefined;
    message.programTreeCount = object.programTreeCount ?? undefined;
    message.programTreeDone = object.programTreeDone ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodes(): PpicPanelCodes {
  return { panelCodes: [] };
}

export const PpicPanelCodes = {
  encode(message: PpicPanelCodes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.panelCodes) {
      PpicPanelCode.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.panelCodes.push(PpicPanelCode.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodes {
    return {
      panelCodes: Array.isArray(object?.panelCodes) ? object.panelCodes.map((e: any) => PpicPanelCode.fromJSON(e)) : [],
    };
  },

  toJSON(message: PpicPanelCodes): unknown {
    const obj: any = {};
    if (message.panelCodes) {
      obj.panelCodes = message.panelCodes.map((e) => e ? PpicPanelCode.toJSON(e) : undefined);
    } else {
      obj.panelCodes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodes>, I>>(base?: I): PpicPanelCodes {
    return PpicPanelCodes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodes>, I>>(object: I): PpicPanelCodes {
    const message = createBasePpicPanelCodes();
    message.panelCodes = object.panelCodes?.map((e) => PpicPanelCode.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmDJOs(): CrmDJOs {
  return { djos: [] };
}

export const CrmDJOs = {
  encode(message: CrmDJOs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.djos) {
      CrmDJO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmDJOs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmDJOs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.djos.push(CrmDJO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmDJOs {
    return { djos: Array.isArray(object?.djos) ? object.djos.map((e: any) => CrmDJO.fromJSON(e)) : [] };
  },

  toJSON(message: CrmDJOs): unknown {
    const obj: any = {};
    if (message.djos) {
      obj.djos = message.djos.map((e) => e ? CrmDJO.toJSON(e) : undefined);
    } else {
      obj.djos = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmDJOs>, I>>(base?: I): CrmDJOs {
    return CrmDJOs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmDJOs>, I>>(object: I): CrmDJOs {
    const message = createBaseCrmDJOs();
    message.djos = object.djos?.map((e) => CrmDJO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmDJO(): CrmDJO {
  return { value: undefined, text: undefined, accountId: undefined, accountName: undefined };
}

export const CrmDJO = {
  encode(message: CrmDJO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== undefined) {
      writer.uint32(8).uint64(message.value);
    }
    if (message.text !== undefined) {
      writer.uint32(18).string(message.text);
    }
    if (message.accountId !== undefined) {
      writer.uint32(24).uint64(message.accountId);
    }
    if (message.accountName !== undefined) {
      writer.uint32(34).string(message.accountName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmDJO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmDJO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.accountId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.accountName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmDJO {
    return {
      value: isSet(object.value) ? String(object.value) : undefined,
      text: isSet(object.text) ? String(object.text) : undefined,
      accountId: isSet(object.accountId) ? String(object.accountId) : undefined,
      accountName: isSet(object.accountName) ? String(object.accountName) : undefined,
    };
  },

  toJSON(message: CrmDJO): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.text !== undefined && (obj.text = message.text);
    message.accountId !== undefined && (obj.accountId = message.accountId);
    message.accountName !== undefined && (obj.accountName = message.accountName);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmDJO>, I>>(base?: I): CrmDJO {
    return CrmDJO.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmDJO>, I>>(object: I): CrmDJO {
    const message = createBaseCrmDJO();
    message.value = object.value ?? undefined;
    message.text = object.text ?? undefined;
    message.accountId = object.accountId ?? undefined;
    message.accountName = object.accountName ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeRemark(): PpicPanelCodeRemark {
  return { masterJavaBaseModel: undefined, panelCodeId: undefined, remark: undefined, done: undefined };
}

export const PpicPanelCodeRemark = {
  encode(message: PpicPanelCodeRemark, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(16).uint64(message.panelCodeId);
    }
    if (message.remark !== undefined) {
      writer.uint32(26).string(message.remark);
    }
    if (message.done !== undefined) {
      writer.uint32(32).bool(message.done);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeRemark {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeRemark();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.done = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeRemark {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      done: isSet(object.done) ? Boolean(object.done) : undefined,
    };
  },

  toJSON(message: PpicPanelCodeRemark): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.remark !== undefined && (obj.remark = message.remark);
    message.done !== undefined && (obj.done = message.done);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeRemark>, I>>(base?: I): PpicPanelCodeRemark {
    return PpicPanelCodeRemark.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeRemark>, I>>(object: I): PpicPanelCodeRemark {
    const message = createBasePpicPanelCodeRemark();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.remark = object.remark ?? undefined;
    message.done = object.done ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplate(): PpicPanelCodeDepartmentTemplate {
  return {
    masterJavaBaseModel: undefined,
    panelCode: undefined,
    panelCodeId: undefined,
    departmentTemplate: undefined,
    departmentTemplateId: undefined,
    departmentTemplateName: undefined,
  };
}

export const PpicPanelCodeDepartmentTemplate = {
  encode(message: PpicPanelCodeDepartmentTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.panelCode !== undefined) {
      PpicPanelCode.encode(message.panelCode, writer.uint32(18).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(32).uint64(message.panelCodeId);
    }
    if (message.departmentTemplate !== undefined) {
      PpicDepartmentTemplate.encode(message.departmentTemplate, writer.uint32(26).fork()).ldelim();
    }
    if (message.departmentTemplateId !== undefined) {
      writer.uint32(40).uint64(message.departmentTemplateId);
    }
    if (message.departmentTemplateName !== undefined) {
      writer.uint32(50).string(message.departmentTemplateName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.panelCode = PpicPanelCode.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departmentTemplate = PpicDepartmentTemplate.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.departmentTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.departmentTemplateName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      panelCode: isSet(object.panelCode) ? PpicPanelCode.fromJSON(object.panelCode) : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      departmentTemplate: isSet(object.departmentTemplate)
        ? PpicDepartmentTemplate.fromJSON(object.departmentTemplate)
        : undefined,
      departmentTemplateId: isSet(object.departmentTemplateId) ? String(object.departmentTemplateId) : undefined,
      departmentTemplateName: isSet(object.departmentTemplateName) ? String(object.departmentTemplateName) : undefined,
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.panelCode !== undefined &&
      (obj.panelCode = message.panelCode ? PpicPanelCode.toJSON(message.panelCode) : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.departmentTemplate !== undefined && (obj.departmentTemplate = message.departmentTemplate
      ? PpicDepartmentTemplate.toJSON(message.departmentTemplate)
      : undefined);
    message.departmentTemplateId !== undefined && (obj.departmentTemplateId = message.departmentTemplateId);
    message.departmentTemplateName !== undefined && (obj.departmentTemplateName = message.departmentTemplateName);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplate>, I>>(base?: I): PpicPanelCodeDepartmentTemplate {
    return PpicPanelCodeDepartmentTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplate>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplate {
    const message = createBasePpicPanelCodeDepartmentTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.panelCode = (object.panelCode !== undefined && object.panelCode !== null)
      ? PpicPanelCode.fromPartial(object.panelCode)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.departmentTemplate = (object.departmentTemplate !== undefined && object.departmentTemplate !== null)
      ? PpicDepartmentTemplate.fromPartial(object.departmentTemplate)
      : undefined;
    message.departmentTemplateId = object.departmentTemplateId ?? undefined;
    message.departmentTemplateName = object.departmentTemplateName ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplateItem(): PpicPanelCodeDepartmentTemplateItem {
  return {
    masterJavaBaseModel: undefined,
    panelCode: undefined,
    panelCodeId: undefined,
    departmentTemplateItem: undefined,
    departmentTemplateItemId: undefined,
    startDate: undefined,
    endDate: undefined,
    completedDate: undefined,
    timeProcessMins: undefined,
    excluded: undefined,
    numComments: undefined,
    departmentTemplateItemName: undefined,
  };
}

export const PpicPanelCodeDepartmentTemplateItem = {
  encode(message: PpicPanelCodeDepartmentTemplateItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.panelCode !== undefined) {
      PpicPanelCode.encode(message.panelCode, writer.uint32(18).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(32).uint64(message.panelCodeId);
    }
    if (message.departmentTemplateItem !== undefined) {
      PpicDepartmentTemplateItem.encode(message.departmentTemplateItem, writer.uint32(26).fork()).ldelim();
    }
    if (message.departmentTemplateItemId !== undefined) {
      writer.uint32(40).uint64(message.departmentTemplateItemId);
    }
    if (message.startDate !== undefined) {
      writer.uint32(50).string(message.startDate);
    }
    if (message.endDate !== undefined) {
      writer.uint32(58).string(message.endDate);
    }
    if (message.completedDate !== undefined) {
      writer.uint32(66).string(message.completedDate);
    }
    if (message.timeProcessMins !== undefined) {
      writer.uint32(72).int32(message.timeProcessMins);
    }
    if (message.excluded !== undefined) {
      writer.uint32(80).bool(message.excluded);
    }
    if (message.numComments !== undefined) {
      writer.uint32(88).int32(message.numComments);
    }
    if (message.departmentTemplateItemName !== undefined) {
      writer.uint32(98).string(message.departmentTemplateItemName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplateItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.panelCode = PpicPanelCode.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departmentTemplateItem = PpicDepartmentTemplateItem.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.departmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startDate = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.endDate = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.completedDate = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.timeProcessMins = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.excluded = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.numComments = reader.int32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.departmentTemplateItemName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplateItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      panelCode: isSet(object.panelCode) ? PpicPanelCode.fromJSON(object.panelCode) : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      departmentTemplateItem: isSet(object.departmentTemplateItem)
        ? PpicDepartmentTemplateItem.fromJSON(object.departmentTemplateItem)
        : undefined,
      departmentTemplateItemId: isSet(object.departmentTemplateItemId)
        ? String(object.departmentTemplateItemId)
        : undefined,
      startDate: isSet(object.startDate) ? String(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? String(object.endDate) : undefined,
      completedDate: isSet(object.completedDate) ? String(object.completedDate) : undefined,
      timeProcessMins: isSet(object.timeProcessMins) ? Number(object.timeProcessMins) : undefined,
      excluded: isSet(object.excluded) ? Boolean(object.excluded) : undefined,
      numComments: isSet(object.numComments) ? Number(object.numComments) : undefined,
      departmentTemplateItemName: isSet(object.departmentTemplateItemName)
        ? String(object.departmentTemplateItemName)
        : undefined,
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplateItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.panelCode !== undefined &&
      (obj.panelCode = message.panelCode ? PpicPanelCode.toJSON(message.panelCode) : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.departmentTemplateItem !== undefined && (obj.departmentTemplateItem = message.departmentTemplateItem
      ? PpicDepartmentTemplateItem.toJSON(message.departmentTemplateItem)
      : undefined);
    message.departmentTemplateItemId !== undefined && (obj.departmentTemplateItemId = message.departmentTemplateItemId);
    message.startDate !== undefined && (obj.startDate = message.startDate);
    message.endDate !== undefined && (obj.endDate = message.endDate);
    message.completedDate !== undefined && (obj.completedDate = message.completedDate);
    message.timeProcessMins !== undefined && (obj.timeProcessMins = Math.round(message.timeProcessMins));
    message.excluded !== undefined && (obj.excluded = message.excluded);
    message.numComments !== undefined && (obj.numComments = Math.round(message.numComments));
    message.departmentTemplateItemName !== undefined &&
      (obj.departmentTemplateItemName = message.departmentTemplateItemName);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplateItem>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplateItem {
    return PpicPanelCodeDepartmentTemplateItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplateItem>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplateItem {
    const message = createBasePpicPanelCodeDepartmentTemplateItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.panelCode = (object.panelCode !== undefined && object.panelCode !== null)
      ? PpicPanelCode.fromPartial(object.panelCode)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.departmentTemplateItem =
      (object.departmentTemplateItem !== undefined && object.departmentTemplateItem !== null)
        ? PpicDepartmentTemplateItem.fromPartial(object.departmentTemplateItem)
        : undefined;
    message.departmentTemplateItemId = object.departmentTemplateItemId ?? undefined;
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.completedDate = object.completedDate ?? undefined;
    message.timeProcessMins = object.timeProcessMins ?? undefined;
    message.excluded = object.excluded ?? undefined;
    message.numComments = object.numComments ?? undefined;
    message.departmentTemplateItemName = object.departmentTemplateItemName ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplateItems(): PpicPanelCodeDepartmentTemplateItems {
  return { departmentTemplateItems: [] };
}

export const PpicPanelCodeDepartmentTemplateItems = {
  encode(message: PpicPanelCodeDepartmentTemplateItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.departmentTemplateItems) {
      PpicPanelCodeDepartmentTemplateItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplateItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplateItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.departmentTemplateItems.push(PpicPanelCodeDepartmentTemplateItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplateItems {
    return {
      departmentTemplateItems: Array.isArray(object?.departmentTemplateItems)
        ? object.departmentTemplateItems.map((e: any) => PpicPanelCodeDepartmentTemplateItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplateItems): unknown {
    const obj: any = {};
    if (message.departmentTemplateItems) {
      obj.departmentTemplateItems = message.departmentTemplateItems.map((e) =>
        e ? PpicPanelCodeDepartmentTemplateItem.toJSON(e) : undefined
      );
    } else {
      obj.departmentTemplateItems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplateItems>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplateItems {
    return PpicPanelCodeDepartmentTemplateItems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplateItems>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplateItems {
    const message = createBasePpicPanelCodeDepartmentTemplateItems();
    message.departmentTemplateItems =
      object.departmentTemplateItems?.map((e) => PpicPanelCodeDepartmentTemplateItem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplateItemUpdateId(): PpicPanelCodeDepartmentTemplateItemUpdateId {
  return {
    panelCodeId: undefined,
    departmentTemplateItemId: undefined,
    status: undefined,
    date: undefined,
    panelCodeDepartmentTemplateItemId: undefined,
  };
}

export const PpicPanelCodeDepartmentTemplateItemUpdateId = {
  encode(message: PpicPanelCodeDepartmentTemplateItemUpdateId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.panelCodeId !== undefined) {
      writer.uint32(8).uint64(message.panelCodeId);
    }
    if (message.departmentTemplateItemId !== undefined) {
      writer.uint32(16).uint64(message.departmentTemplateItemId);
    }
    if (message.status !== undefined) {
      writer.uint32(24).int32(message.status);
    }
    if (message.date !== undefined) {
      writer.uint32(34).string(message.date);
    }
    if (message.panelCodeDepartmentTemplateItemId !== undefined) {
      writer.uint32(40).uint64(message.panelCodeDepartmentTemplateItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplateItemUpdateId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplateItemUpdateId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.departmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.date = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.panelCodeDepartmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplateItemUpdateId {
    return {
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      departmentTemplateItemId: isSet(object.departmentTemplateItemId)
        ? String(object.departmentTemplateItemId)
        : undefined,
      status: isSet(object.status)
        ? ppicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatusFromJSON(object.status)
        : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      panelCodeDepartmentTemplateItemId: isSet(object.panelCodeDepartmentTemplateItemId)
        ? String(object.panelCodeDepartmentTemplateItemId)
        : undefined,
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplateItemUpdateId): unknown {
    const obj: any = {};
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.departmentTemplateItemId !== undefined && (obj.departmentTemplateItemId = message.departmentTemplateItemId);
    message.status !== undefined && (obj.status = message.status !== undefined
      ? ppicPanelCodeDepartmentTemplateItemUpdateId_MeetingTaskStatusToJSON(message.status)
      : undefined);
    message.date !== undefined && (obj.date = message.date);
    message.panelCodeDepartmentTemplateItemId !== undefined &&
      (obj.panelCodeDepartmentTemplateItemId = message.panelCodeDepartmentTemplateItemId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplateItemUpdateId>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplateItemUpdateId {
    return PpicPanelCodeDepartmentTemplateItemUpdateId.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplateItemUpdateId>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplateItemUpdateId {
    const message = createBasePpicPanelCodeDepartmentTemplateItemUpdateId();
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.departmentTemplateItemId = object.departmentTemplateItemId ?? undefined;
    message.status = object.status ?? undefined;
    message.date = object.date ?? undefined;
    message.panelCodeDepartmentTemplateItemId = object.panelCodeDepartmentTemplateItemId ?? undefined;
    return message;
  },
};

function createBasePpicDeadlineDetail(): PpicDeadlineDetail {
  return { masterJavaBaseModel: undefined, date: undefined };
}

export const PpicDeadlineDetail = {
  encode(message: PpicDeadlineDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(18).string(message.date);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDeadlineDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDeadlineDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.date = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDeadlineDetail {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
    };
  },

  toJSON(message: PpicDeadlineDetail): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.date !== undefined && (obj.date = message.date);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDeadlineDetail>, I>>(base?: I): PpicDeadlineDetail {
    return PpicDeadlineDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDeadlineDetail>, I>>(object: I): PpicDeadlineDetail {
    const message = createBasePpicDeadlineDetail();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.date = object.date ?? undefined;
    return message;
  },
};

function createBaseJobExtPanelCodeDepartmentTemplateUpdate(): JobExtPanelCodeDepartmentTemplateUpdate {
  return {
    outputId: undefined,
    completedDate: undefined,
    startDate: undefined,
    endDate: undefined,
    handleClearCompletedDate: undefined,
  };
}

export const JobExtPanelCodeDepartmentTemplateUpdate = {
  encode(message: JobExtPanelCodeDepartmentTemplateUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.outputId !== undefined) {
      writer.uint32(8).uint64(message.outputId);
    }
    if (message.completedDate !== undefined) {
      writer.uint32(18).string(message.completedDate);
    }
    if (message.startDate !== undefined) {
      writer.uint32(26).string(message.startDate);
    }
    if (message.endDate !== undefined) {
      writer.uint32(34).string(message.endDate);
    }
    if (message.handleClearCompletedDate !== undefined) {
      writer.uint32(40).bool(message.handleClearCompletedDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobExtPanelCodeDepartmentTemplateUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobExtPanelCodeDepartmentTemplateUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.outputId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.completedDate = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.handleClearCompletedDate = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobExtPanelCodeDepartmentTemplateUpdate {
    return {
      outputId: isSet(object.outputId) ? String(object.outputId) : undefined,
      completedDate: isSet(object.completedDate) ? String(object.completedDate) : undefined,
      startDate: isSet(object.startDate) ? String(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? String(object.endDate) : undefined,
      handleClearCompletedDate: isSet(object.handleClearCompletedDate)
        ? Boolean(object.handleClearCompletedDate)
        : undefined,
    };
  },

  toJSON(message: JobExtPanelCodeDepartmentTemplateUpdate): unknown {
    const obj: any = {};
    message.outputId !== undefined && (obj.outputId = message.outputId);
    message.completedDate !== undefined && (obj.completedDate = message.completedDate);
    message.startDate !== undefined && (obj.startDate = message.startDate);
    message.endDate !== undefined && (obj.endDate = message.endDate);
    message.handleClearCompletedDate !== undefined && (obj.handleClearCompletedDate = message.handleClearCompletedDate);
    return obj;
  },

  create<I extends Exact<DeepPartial<JobExtPanelCodeDepartmentTemplateUpdate>, I>>(
    base?: I,
  ): JobExtPanelCodeDepartmentTemplateUpdate {
    return JobExtPanelCodeDepartmentTemplateUpdate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobExtPanelCodeDepartmentTemplateUpdate>, I>>(
    object: I,
  ): JobExtPanelCodeDepartmentTemplateUpdate {
    const message = createBaseJobExtPanelCodeDepartmentTemplateUpdate();
    message.outputId = object.outputId ?? undefined;
    message.completedDate = object.completedDate ?? undefined;
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.handleClearCompletedDate = object.handleClearCompletedDate ?? undefined;
    return message;
  },
};

function createBaseJobExtPanelCodeDepartmentTemplateUpdates(): JobExtPanelCodeDepartmentTemplateUpdates {
  return { updates: [] };
}

export const JobExtPanelCodeDepartmentTemplateUpdates = {
  encode(message: JobExtPanelCodeDepartmentTemplateUpdates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.updates) {
      JobExtPanelCodeDepartmentTemplateUpdate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobExtPanelCodeDepartmentTemplateUpdates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobExtPanelCodeDepartmentTemplateUpdates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updates.push(JobExtPanelCodeDepartmentTemplateUpdate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobExtPanelCodeDepartmentTemplateUpdates {
    return {
      updates: Array.isArray(object?.updates)
        ? object.updates.map((e: any) => JobExtPanelCodeDepartmentTemplateUpdate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: JobExtPanelCodeDepartmentTemplateUpdates): unknown {
    const obj: any = {};
    if (message.updates) {
      obj.updates = message.updates.map((e) => e ? JobExtPanelCodeDepartmentTemplateUpdate.toJSON(e) : undefined);
    } else {
      obj.updates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobExtPanelCodeDepartmentTemplateUpdates>, I>>(
    base?: I,
  ): JobExtPanelCodeDepartmentTemplateUpdates {
    return JobExtPanelCodeDepartmentTemplateUpdates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobExtPanelCodeDepartmentTemplateUpdates>, I>>(
    object: I,
  ): JobExtPanelCodeDepartmentTemplateUpdates {
    const message = createBaseJobExtPanelCodeDepartmentTemplateUpdates();
    message.updates = object.updates?.map((e) => JobExtPanelCodeDepartmentTemplateUpdate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseJobExtDepartmentTemplateJobWrapper(): JobExtDepartmentTemplateJobWrapper {
  return { job: undefined, panelCodes: [] };
}

export const JobExtDepartmentTemplateJobWrapper = {
  encode(message: JobExtDepartmentTemplateJobWrapper, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.job !== undefined) {
      PpicJob.encode(message.job, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.panelCodes) {
      JobExtDepartmentTemplatePanelCodeWrapper.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobExtDepartmentTemplateJobWrapper {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobExtDepartmentTemplateJobWrapper();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = PpicJob.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.panelCodes.push(JobExtDepartmentTemplatePanelCodeWrapper.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobExtDepartmentTemplateJobWrapper {
    return {
      job: isSet(object.job) ? PpicJob.fromJSON(object.job) : undefined,
      panelCodes: Array.isArray(object?.panelCodes)
        ? object.panelCodes.map((e: any) => JobExtDepartmentTemplatePanelCodeWrapper.fromJSON(e))
        : [],
    };
  },

  toJSON(message: JobExtDepartmentTemplateJobWrapper): unknown {
    const obj: any = {};
    message.job !== undefined && (obj.job = message.job ? PpicJob.toJSON(message.job) : undefined);
    if (message.panelCodes) {
      obj.panelCodes = message.panelCodes.map((e) =>
        e ? JobExtDepartmentTemplatePanelCodeWrapper.toJSON(e) : undefined
      );
    } else {
      obj.panelCodes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobExtDepartmentTemplateJobWrapper>, I>>(
    base?: I,
  ): JobExtDepartmentTemplateJobWrapper {
    return JobExtDepartmentTemplateJobWrapper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobExtDepartmentTemplateJobWrapper>, I>>(
    object: I,
  ): JobExtDepartmentTemplateJobWrapper {
    const message = createBaseJobExtDepartmentTemplateJobWrapper();
    message.job = (object.job !== undefined && object.job !== null) ? PpicJob.fromPartial(object.job) : undefined;
    message.panelCodes = object.panelCodes?.map((e) => JobExtDepartmentTemplatePanelCodeWrapper.fromPartial(e)) || [];
    return message;
  },
};

function createBaseJobExtDepartmentTemplatePanelCodeWrapper(): JobExtDepartmentTemplatePanelCodeWrapper {
  return { panelCode: undefined, deptTemplates: [], deptTemplateItems: [] };
}

export const JobExtDepartmentTemplatePanelCodeWrapper = {
  encode(message: JobExtDepartmentTemplatePanelCodeWrapper, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.panelCode !== undefined) {
      PpicPanelCode.encode(message.panelCode, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.deptTemplates) {
      PpicPanelCodeDepartmentTemplate.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.deptTemplateItems) {
      PpicPanelCodeDepartmentTemplateItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobExtDepartmentTemplatePanelCodeWrapper {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobExtDepartmentTemplatePanelCodeWrapper();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.panelCode = PpicPanelCode.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deptTemplates.push(PpicPanelCodeDepartmentTemplate.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deptTemplateItems.push(PpicPanelCodeDepartmentTemplateItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobExtDepartmentTemplatePanelCodeWrapper {
    return {
      panelCode: isSet(object.panelCode) ? PpicPanelCode.fromJSON(object.panelCode) : undefined,
      deptTemplates: Array.isArray(object?.deptTemplates)
        ? object.deptTemplates.map((e: any) => PpicPanelCodeDepartmentTemplate.fromJSON(e))
        : [],
      deptTemplateItems: Array.isArray(object?.deptTemplateItems)
        ? object.deptTemplateItems.map((e: any) => PpicPanelCodeDepartmentTemplateItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: JobExtDepartmentTemplatePanelCodeWrapper): unknown {
    const obj: any = {};
    message.panelCode !== undefined &&
      (obj.panelCode = message.panelCode ? PpicPanelCode.toJSON(message.panelCode) : undefined);
    if (message.deptTemplates) {
      obj.deptTemplates = message.deptTemplates.map((e) => e ? PpicPanelCodeDepartmentTemplate.toJSON(e) : undefined);
    } else {
      obj.deptTemplates = [];
    }
    if (message.deptTemplateItems) {
      obj.deptTemplateItems = message.deptTemplateItems.map((e) =>
        e ? PpicPanelCodeDepartmentTemplateItem.toJSON(e) : undefined
      );
    } else {
      obj.deptTemplateItems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobExtDepartmentTemplatePanelCodeWrapper>, I>>(
    base?: I,
  ): JobExtDepartmentTemplatePanelCodeWrapper {
    return JobExtDepartmentTemplatePanelCodeWrapper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobExtDepartmentTemplatePanelCodeWrapper>, I>>(
    object: I,
  ): JobExtDepartmentTemplatePanelCodeWrapper {
    const message = createBaseJobExtDepartmentTemplatePanelCodeWrapper();
    message.panelCode = (object.panelCode !== undefined && object.panelCode !== null)
      ? PpicPanelCode.fromPartial(object.panelCode)
      : undefined;
    message.deptTemplates = object.deptTemplates?.map((e) => PpicPanelCodeDepartmentTemplate.fromPartial(e)) || [];
    message.deptTemplateItems =
      object.deptTemplateItems?.map((e) => PpicPanelCodeDepartmentTemplateItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseJobExtDepartmentTemplate(): JobExtDepartmentTemplate {
  return { data: undefined };
}

export const JobExtDepartmentTemplate = {
  encode(message: JobExtDepartmentTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      JobExtDepartmentTemplateJobWrapper.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobExtDepartmentTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobExtDepartmentTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = JobExtDepartmentTemplateJobWrapper.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobExtDepartmentTemplate {
    return { data: isSet(object.data) ? JobExtDepartmentTemplateJobWrapper.fromJSON(object.data) : undefined };
  },

  toJSON(message: JobExtDepartmentTemplate): unknown {
    const obj: any = {};
    message.data !== undefined &&
      (obj.data = message.data ? JobExtDepartmentTemplateJobWrapper.toJSON(message.data) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<JobExtDepartmentTemplate>, I>>(base?: I): JobExtDepartmentTemplate {
    return JobExtDepartmentTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobExtDepartmentTemplate>, I>>(object: I): JobExtDepartmentTemplate {
    const message = createBaseJobExtDepartmentTemplate();
    message.data = (object.data !== undefined && object.data !== null)
      ? JobExtDepartmentTemplateJobWrapper.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseJobExtDepartmentTemplates(): JobExtDepartmentTemplates {
  return { jobs: [] };
}

export const JobExtDepartmentTemplates = {
  encode(message: JobExtDepartmentTemplates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.jobs) {
      JobExtDepartmentTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobExtDepartmentTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobExtDepartmentTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobs.push(JobExtDepartmentTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobExtDepartmentTemplates {
    return {
      jobs: Array.isArray(object?.jobs) ? object.jobs.map((e: any) => JobExtDepartmentTemplate.fromJSON(e)) : [],
    };
  },

  toJSON(message: JobExtDepartmentTemplates): unknown {
    const obj: any = {};
    if (message.jobs) {
      obj.jobs = message.jobs.map((e) => e ? JobExtDepartmentTemplate.toJSON(e) : undefined);
    } else {
      obj.jobs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobExtDepartmentTemplates>, I>>(base?: I): JobExtDepartmentTemplates {
    return JobExtDepartmentTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobExtDepartmentTemplates>, I>>(object: I): JobExtDepartmentTemplates {
    const message = createBaseJobExtDepartmentTemplates();
    message.jobs = object.jobs?.map((e) => JobExtDepartmentTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicMaterialRequestSimple(): PpicMaterialRequestSimple {
  return {
    id: undefined,
    name: undefined,
    jobName: undefined,
    date: undefined,
    status: undefined,
    masterJavaBaseModel: undefined,
    jobId: undefined,
    items: [],
    type: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    remark: undefined,
  };
}

export const PpicMaterialRequestSimple = {
  encode(message: PpicMaterialRequestSimple, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.jobName !== undefined) {
      writer.uint32(26).string(message.jobName);
    }
    if (message.date !== undefined) {
      writer.uint32(42).string(message.date);
    }
    if (message.status !== undefined) {
      writer.uint32(32).int64(message.status);
    }
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(50).fork()).ldelim();
    }
    if (message.jobId !== undefined) {
      writer.uint32(56).uint64(message.jobId);
    }
    for (const v of message.items) {
      PpicMaterialRequestSimpleItem.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(74).string(message.type);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(82).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(90).string(message.updatedAt);
    }
    if (message.remark !== undefined) {
      writer.uint32(98).string(message.remark);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMaterialRequestSimple {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMaterialRequestSimple();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.jobName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = longToString(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.items.push(PpicMaterialRequestSimpleItem.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.type = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.remark = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMaterialRequestSimple {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      jobName: isSet(object.jobName) ? String(object.jobName) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => PpicMaterialRequestSimpleItem.fromJSON(e))
        : [],
      type: isSet(object.type) ? String(object.type) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
    };
  },

  toJSON(message: PpicMaterialRequestSimple): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.jobName !== undefined && (obj.jobName = message.jobName);
    message.date !== undefined && (obj.date = message.date);
    message.status !== undefined && (obj.status = message.status);
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicMaterialRequestSimpleItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.type !== undefined && (obj.type = message.type);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.remark !== undefined && (obj.remark = message.remark);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMaterialRequestSimple>, I>>(base?: I): PpicMaterialRequestSimple {
    return PpicMaterialRequestSimple.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMaterialRequestSimple>, I>>(object: I): PpicMaterialRequestSimple {
    const message = createBasePpicMaterialRequestSimple();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.jobName = object.jobName ?? undefined;
    message.date = object.date ?? undefined;
    message.status = object.status ?? undefined;
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.jobId = object.jobId ?? undefined;
    message.items = object.items?.map((e) => PpicMaterialRequestSimpleItem.fromPartial(e)) || [];
    message.type = object.type ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.remark = object.remark ?? undefined;
    return message;
  },
};

function createBasePpicMaterialRequestSimpleItem(): PpicMaterialRequestSimpleItem {
  return {
    masterJavaBaseModel: undefined,
    extItemId: undefined,
    extInventoryId: undefined,
    qty: undefined,
    extReserveListId: undefined,
  };
}

export const PpicMaterialRequestSimpleItem = {
  encode(message: PpicMaterialRequestSimpleItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extItemId !== undefined) {
      writer.uint32(16).uint64(message.extItemId);
    }
    if (message.extInventoryId !== undefined) {
      writer.uint32(24).uint64(message.extInventoryId);
    }
    if (message.qty !== undefined) {
      writer.uint32(33).double(message.qty);
    }
    if (message.extReserveListId !== undefined) {
      writer.uint32(40).uint64(message.extReserveListId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMaterialRequestSimpleItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMaterialRequestSimpleItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extInventoryId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.qty = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.extReserveListId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMaterialRequestSimpleItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      extInventoryId: isSet(object.extInventoryId) ? String(object.extInventoryId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      extReserveListId: isSet(object.extReserveListId) ? String(object.extReserveListId) : undefined,
    };
  },

  toJSON(message: PpicMaterialRequestSimpleItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.extInventoryId !== undefined && (obj.extInventoryId = message.extInventoryId);
    message.qty !== undefined && (obj.qty = message.qty);
    message.extReserveListId !== undefined && (obj.extReserveListId = message.extReserveListId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMaterialRequestSimpleItem>, I>>(base?: I): PpicMaterialRequestSimpleItem {
    return PpicMaterialRequestSimpleItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMaterialRequestSimpleItem>, I>>(
    object: I,
  ): PpicMaterialRequestSimpleItem {
    const message = createBasePpicMaterialRequestSimpleItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.extInventoryId = object.extInventoryId ?? undefined;
    message.qty = object.qty ?? undefined;
    message.extReserveListId = object.extReserveListId ?? undefined;
    return message;
  },
};

function createBasePpicMaterialRequestsSimple(): PpicMaterialRequestsSimple {
  return { materialRequests: [] };
}

export const PpicMaterialRequestsSimple = {
  encode(message: PpicMaterialRequestsSimple, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.materialRequests) {
      PpicMaterialRequestSimple.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicMaterialRequestsSimple {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicMaterialRequestsSimple();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.materialRequests.push(PpicMaterialRequestSimple.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicMaterialRequestsSimple {
    return {
      materialRequests: Array.isArray(object?.materialRequests)
        ? object.materialRequests.map((e: any) => PpicMaterialRequestSimple.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicMaterialRequestsSimple): unknown {
    const obj: any = {};
    if (message.materialRequests) {
      obj.materialRequests = message.materialRequests.map((e) => e ? PpicMaterialRequestSimple.toJSON(e) : undefined);
    } else {
      obj.materialRequests = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicMaterialRequestsSimple>, I>>(base?: I): PpicMaterialRequestsSimple {
    return PpicMaterialRequestsSimple.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicMaterialRequestsSimple>, I>>(object: I): PpicMaterialRequestsSimple {
    const message = createBasePpicMaterialRequestsSimple();
    message.materialRequests = object.materialRequests?.map((e) => PpicMaterialRequestSimple.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicJobs(): PpicJobs {
  return { jobs: [] };
}

export const PpicJobs = {
  encode(message: PpicJobs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.jobs) {
      PpicJob.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobs.push(PpicJob.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobs {
    return { jobs: Array.isArray(object?.jobs) ? object.jobs.map((e: any) => PpicJob.fromJSON(e)) : [] };
  },

  toJSON(message: PpicJobs): unknown {
    const obj: any = {};
    if (message.jobs) {
      obj.jobs = message.jobs.map((e) => e ? PpicJob.toJSON(e) : undefined);
    } else {
      obj.jobs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobs>, I>>(base?: I): PpicJobs {
    return PpicJobs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobs>, I>>(object: I): PpicJobs {
    const message = createBasePpicJobs();
    message.jobs = object.jobs?.map((e) => PpicJob.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicDepartmentTemplates(): PpicDepartmentTemplates {
  return { templates: [] };
}

export const PpicDepartmentTemplates = {
  encode(message: PpicDepartmentTemplates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      PpicDepartmentTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(PpicDepartmentTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplates {
    return {
      templates: Array.isArray(object?.templates)
        ? object.templates.map((e: any) => PpicDepartmentTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicDepartmentTemplates): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) => e ? PpicDepartmentTemplate.toJSON(e) : undefined);
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplates>, I>>(base?: I): PpicDepartmentTemplates {
    return PpicDepartmentTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplates>, I>>(object: I): PpicDepartmentTemplates {
    const message = createBasePpicDepartmentTemplates();
    message.templates = object.templates?.map((e) => PpicDepartmentTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAuthserverUserShift(): AuthserverUserShift {
  return {
    masterJavaBaseModel: undefined,
    userId: undefined,
    shift: undefined,
    date: undefined,
    shiftTemplateId: undefined,
  };
}

export const AuthserverUserShift = {
  encode(message: AuthserverUserShift, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(34).fork()).ldelim();
    }
    if (message.userId !== undefined) {
      writer.uint32(8).uint64(message.userId);
    }
    if (message.shift !== undefined) {
      writer.uint32(16).bool(message.shift);
    }
    if (message.date !== undefined) {
      writer.uint32(26).string(message.date);
    }
    if (message.shiftTemplateId !== undefined) {
      writer.uint32(40).uint64(message.shiftTemplateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverUserShift {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverUserShift();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.shift = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.date = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.shiftTemplateId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverUserShift {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      shift: isSet(object.shift) ? Boolean(object.shift) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      shiftTemplateId: isSet(object.shiftTemplateId) ? String(object.shiftTemplateId) : undefined,
    };
  },

  toJSON(message: AuthserverUserShift): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.shift !== undefined && (obj.shift = message.shift);
    message.date !== undefined && (obj.date = message.date);
    message.shiftTemplateId !== undefined && (obj.shiftTemplateId = message.shiftTemplateId);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverUserShift>, I>>(base?: I): AuthserverUserShift {
    return AuthserverUserShift.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverUserShift>, I>>(object: I): AuthserverUserShift {
    const message = createBaseAuthserverUserShift();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.userId = object.userId ?? undefined;
    message.shift = object.shift ?? undefined;
    message.date = object.date ?? undefined;
    message.shiftTemplateId = object.shiftTemplateId ?? undefined;
    return message;
  },
};

function createBaseAuthserverUserShifts(): AuthserverUserShifts {
  return { shifts: [] };
}

export const AuthserverUserShifts = {
  encode(message: AuthserverUserShifts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.shifts) {
      AuthserverUserShift.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverUserShifts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverUserShifts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.shifts.push(AuthserverUserShift.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverUserShifts {
    return {
      shifts: Array.isArray(object?.shifts) ? object.shifts.map((e: any) => AuthserverUserShift.fromJSON(e)) : [],
    };
  },

  toJSON(message: AuthserverUserShifts): unknown {
    const obj: any = {};
    if (message.shifts) {
      obj.shifts = message.shifts.map((e) => e ? AuthserverUserShift.toJSON(e) : undefined);
    } else {
      obj.shifts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverUserShifts>, I>>(base?: I): AuthserverUserShifts {
    return AuthserverUserShifts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverUserShifts>, I>>(object: I): AuthserverUserShifts {
    const message = createBaseAuthserverUserShifts();
    message.shifts = object.shifts?.map((e) => AuthserverUserShift.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAuthserverShiftTemplate(): AuthserverShiftTemplate {
  return {
    masterJavaBaseModel: undefined,
    fromHour: undefined,
    fromMins: undefined,
    toHour: undefined,
    toMins: undefined,
    name: undefined,
  };
}

export const AuthserverShiftTemplate = {
  encode(message: AuthserverShiftTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.fromHour !== undefined) {
      writer.uint32(16).int32(message.fromHour);
    }
    if (message.fromMins !== undefined) {
      writer.uint32(24).int32(message.fromMins);
    }
    if (message.toHour !== undefined) {
      writer.uint32(32).int32(message.toHour);
    }
    if (message.toMins !== undefined) {
      writer.uint32(40).int32(message.toMins);
    }
    if (message.name !== undefined) {
      writer.uint32(50).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverShiftTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverShiftTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fromHour = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fromMins = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.toHour = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.toMins = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverShiftTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      fromHour: isSet(object.fromHour) ? Number(object.fromHour) : undefined,
      fromMins: isSet(object.fromMins) ? Number(object.fromMins) : undefined,
      toHour: isSet(object.toHour) ? Number(object.toHour) : undefined,
      toMins: isSet(object.toMins) ? Number(object.toMins) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: AuthserverShiftTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.fromHour !== undefined && (obj.fromHour = Math.round(message.fromHour));
    message.fromMins !== undefined && (obj.fromMins = Math.round(message.fromMins));
    message.toHour !== undefined && (obj.toHour = Math.round(message.toHour));
    message.toMins !== undefined && (obj.toMins = Math.round(message.toMins));
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverShiftTemplate>, I>>(base?: I): AuthserverShiftTemplate {
    return AuthserverShiftTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverShiftTemplate>, I>>(object: I): AuthserverShiftTemplate {
    const message = createBaseAuthserverShiftTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.fromHour = object.fromHour ?? undefined;
    message.fromMins = object.fromMins ?? undefined;
    message.toHour = object.toHour ?? undefined;
    message.toMins = object.toMins ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseAuthserverShiftTemplates(): AuthserverShiftTemplates {
  return { templates: [] };
}

export const AuthserverShiftTemplates = {
  encode(message: AuthserverShiftTemplates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      AuthserverShiftTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthserverShiftTemplates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthserverShiftTemplates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(AuthserverShiftTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthserverShiftTemplates {
    return {
      templates: Array.isArray(object?.templates)
        ? object.templates.map((e: any) => AuthserverShiftTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AuthserverShiftTemplates): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) => e ? AuthserverShiftTemplate.toJSON(e) : undefined);
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthserverShiftTemplates>, I>>(base?: I): AuthserverShiftTemplates {
    return AuthserverShiftTemplates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthserverShiftTemplates>, I>>(object: I): AuthserverShiftTemplates {
    const message = createBaseAuthserverShiftTemplates();
    message.templates = object.templates?.map((e) => AuthserverShiftTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicDepartmentTemplate(): PpicDepartmentTemplate {
  return {
    masterJavaBaseModel: undefined,
    items: [],
    name: undefined,
    panelCode: undefined,
    panelCodeId: undefined,
    tProcessMins: undefined,
    variable: undefined,
    processDays: undefined,
    commonId: undefined,
    extHeadId: undefined,
    extDepartmentId: undefined,
    projectEditable: undefined,
    isProject: undefined,
    extAppId: undefined,
  };
}

export const PpicDepartmentTemplate = {
  encode(message: PpicDepartmentTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.items) {
      PpicDepartmentTemplateItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.panelCode !== undefined) {
      PpicPanelCode.encode(message.panelCode, writer.uint32(34).fork()).ldelim();
    }
    if (message.panelCodeId !== undefined) {
      writer.uint32(40).uint64(message.panelCodeId);
    }
    if (message.tProcessMins !== undefined) {
      writer.uint32(48).int32(message.tProcessMins);
    }
    if (message.variable !== undefined) {
      writer.uint32(56).bool(message.variable);
    }
    if (message.processDays !== undefined) {
      writer.uint32(64).int32(message.processDays);
    }
    if (message.commonId !== undefined) {
      writer.uint32(74).string(message.commonId);
    }
    if (message.extHeadId !== undefined) {
      writer.uint32(80).uint64(message.extHeadId);
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(88).uint64(message.extDepartmentId);
    }
    if (message.projectEditable !== undefined) {
      writer.uint32(96).bool(message.projectEditable);
    }
    if (message.isProject !== undefined) {
      writer.uint32(104).bool(message.isProject);
    }
    if (message.extAppId !== undefined) {
      writer.uint32(112).uint64(message.extAppId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.items.push(PpicDepartmentTemplateItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.panelCode = PpicPanelCode.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.panelCodeId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.tProcessMins = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.variable = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.processDays = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.commonId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.extHeadId = longToString(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.projectEditable = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isProject = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.extAppId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => PpicDepartmentTemplateItem.fromJSON(e)) : [],
      name: isSet(object.name) ? String(object.name) : undefined,
      panelCode: isSet(object.panelCode) ? PpicPanelCode.fromJSON(object.panelCode) : undefined,
      panelCodeId: isSet(object.panelCodeId) ? String(object.panelCodeId) : undefined,
      tProcessMins: isSet(object.tProcessMins) ? Number(object.tProcessMins) : undefined,
      variable: isSet(object.variable) ? Boolean(object.variable) : undefined,
      processDays: isSet(object.processDays) ? Number(object.processDays) : undefined,
      commonId: isSet(object.commonId) ? String(object.commonId) : undefined,
      extHeadId: isSet(object.extHeadId) ? String(object.extHeadId) : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
      projectEditable: isSet(object.projectEditable) ? Boolean(object.projectEditable) : undefined,
      isProject: isSet(object.isProject) ? Boolean(object.isProject) : undefined,
      extAppId: isSet(object.extAppId) ? String(object.extAppId) : undefined,
    };
  },

  toJSON(message: PpicDepartmentTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicDepartmentTemplateItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.name !== undefined && (obj.name = message.name);
    message.panelCode !== undefined &&
      (obj.panelCode = message.panelCode ? PpicPanelCode.toJSON(message.panelCode) : undefined);
    message.panelCodeId !== undefined && (obj.panelCodeId = message.panelCodeId);
    message.tProcessMins !== undefined && (obj.tProcessMins = Math.round(message.tProcessMins));
    message.variable !== undefined && (obj.variable = message.variable);
    message.processDays !== undefined && (obj.processDays = Math.round(message.processDays));
    message.commonId !== undefined && (obj.commonId = message.commonId);
    message.extHeadId !== undefined && (obj.extHeadId = message.extHeadId);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    message.projectEditable !== undefined && (obj.projectEditable = message.projectEditable);
    message.isProject !== undefined && (obj.isProject = message.isProject);
    message.extAppId !== undefined && (obj.extAppId = message.extAppId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplate>, I>>(base?: I): PpicDepartmentTemplate {
    return PpicDepartmentTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplate>, I>>(object: I): PpicDepartmentTemplate {
    const message = createBasePpicDepartmentTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.items = object.items?.map((e) => PpicDepartmentTemplateItem.fromPartial(e)) || [];
    message.name = object.name ?? undefined;
    message.panelCode = (object.panelCode !== undefined && object.panelCode !== null)
      ? PpicPanelCode.fromPartial(object.panelCode)
      : undefined;
    message.panelCodeId = object.panelCodeId ?? undefined;
    message.tProcessMins = object.tProcessMins ?? undefined;
    message.variable = object.variable ?? undefined;
    message.processDays = object.processDays ?? undefined;
    message.commonId = object.commonId ?? undefined;
    message.extHeadId = object.extHeadId ?? undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    message.projectEditable = object.projectEditable ?? undefined;
    message.isProject = object.isProject ?? undefined;
    message.extAppId = object.extAppId ?? undefined;
    return message;
  },
};

function createBasePpicDepartmentTemplateItem(): PpicDepartmentTemplateItem {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    departmentTemplate: undefined,
    departmentTemplateId: undefined,
    timeProcessMins: undefined,
    commonId: undefined,
    extRcemSubProcessId: undefined,
  };
}

export const PpicDepartmentTemplateItem = {
  encode(message: PpicDepartmentTemplateItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.departmentTemplate !== undefined) {
      PpicDepartmentTemplate.encode(message.departmentTemplate, writer.uint32(26).fork()).ldelim();
    }
    if (message.departmentTemplateId !== undefined) {
      writer.uint32(32).uint64(message.departmentTemplateId);
    }
    if (message.timeProcessMins !== undefined) {
      writer.uint32(40).int32(message.timeProcessMins);
    }
    if (message.commonId !== undefined) {
      writer.uint32(50).string(message.commonId);
    }
    if (message.extRcemSubProcessId !== undefined) {
      writer.uint32(56).uint64(message.extRcemSubProcessId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplateItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departmentTemplate = PpicDepartmentTemplate.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.departmentTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.timeProcessMins = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.commonId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.extRcemSubProcessId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplateItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      departmentTemplate: isSet(object.departmentTemplate)
        ? PpicDepartmentTemplate.fromJSON(object.departmentTemplate)
        : undefined,
      departmentTemplateId: isSet(object.departmentTemplateId) ? String(object.departmentTemplateId) : undefined,
      timeProcessMins: isSet(object.timeProcessMins) ? Number(object.timeProcessMins) : undefined,
      commonId: isSet(object.commonId) ? String(object.commonId) : undefined,
      extRcemSubProcessId: isSet(object.extRcemSubProcessId) ? String(object.extRcemSubProcessId) : undefined,
    };
  },

  toJSON(message: PpicDepartmentTemplateItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.departmentTemplate !== undefined && (obj.departmentTemplate = message.departmentTemplate
      ? PpicDepartmentTemplate.toJSON(message.departmentTemplate)
      : undefined);
    message.departmentTemplateId !== undefined && (obj.departmentTemplateId = message.departmentTemplateId);
    message.timeProcessMins !== undefined && (obj.timeProcessMins = Math.round(message.timeProcessMins));
    message.commonId !== undefined && (obj.commonId = message.commonId);
    message.extRcemSubProcessId !== undefined && (obj.extRcemSubProcessId = message.extRcemSubProcessId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplateItem>, I>>(base?: I): PpicDepartmentTemplateItem {
    return PpicDepartmentTemplateItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplateItem>, I>>(object: I): PpicDepartmentTemplateItem {
    const message = createBasePpicDepartmentTemplateItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.departmentTemplate = (object.departmentTemplate !== undefined && object.departmentTemplate !== null)
      ? PpicDepartmentTemplate.fromPartial(object.departmentTemplate)
      : undefined;
    message.departmentTemplateId = object.departmentTemplateId ?? undefined;
    message.timeProcessMins = object.timeProcessMins ?? undefined;
    message.commonId = object.commonId ?? undefined;
    message.extRcemSubProcessId = object.extRcemSubProcessId ?? undefined;
    return message;
  },
};

function createBasePpicDepartmentTemplateItemStatus(): PpicDepartmentTemplateItemStatus {
  return {
    masterJavaBaseModel: undefined,
    item: undefined,
    panelCode: undefined,
    completedDate: undefined,
    departmentTemplateItemId: undefined,
  };
}

export const PpicDepartmentTemplateItemStatus = {
  encode(message: PpicDepartmentTemplateItemStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.item !== undefined) {
      PpicDepartmentTemplateItem.encode(message.item, writer.uint32(18).fork()).ldelim();
    }
    if (message.panelCode !== undefined) {
      PpicPanelCode.encode(message.panelCode, writer.uint32(26).fork()).ldelim();
    }
    if (message.completedDate !== undefined) {
      writer.uint32(34).string(message.completedDate);
    }
    if (message.departmentTemplateItemId !== undefined) {
      writer.uint32(40).uint64(message.departmentTemplateItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplateItemStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplateItemStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.item = PpicDepartmentTemplateItem.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.panelCode = PpicPanelCode.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.completedDate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.departmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplateItemStatus {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      item: isSet(object.item) ? PpicDepartmentTemplateItem.fromJSON(object.item) : undefined,
      panelCode: isSet(object.panelCode) ? PpicPanelCode.fromJSON(object.panelCode) : undefined,
      completedDate: isSet(object.completedDate) ? String(object.completedDate) : undefined,
      departmentTemplateItemId: isSet(object.departmentTemplateItemId)
        ? String(object.departmentTemplateItemId)
        : undefined,
    };
  },

  toJSON(message: PpicDepartmentTemplateItemStatus): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.item !== undefined &&
      (obj.item = message.item ? PpicDepartmentTemplateItem.toJSON(message.item) : undefined);
    message.panelCode !== undefined &&
      (obj.panelCode = message.panelCode ? PpicPanelCode.toJSON(message.panelCode) : undefined);
    message.completedDate !== undefined && (obj.completedDate = message.completedDate);
    message.departmentTemplateItemId !== undefined && (obj.departmentTemplateItemId = message.departmentTemplateItemId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplateItemStatus>, I>>(
    base?: I,
  ): PpicDepartmentTemplateItemStatus {
    return PpicDepartmentTemplateItemStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplateItemStatus>, I>>(
    object: I,
  ): PpicDepartmentTemplateItemStatus {
    const message = createBasePpicDepartmentTemplateItemStatus();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.item = (object.item !== undefined && object.item !== null)
      ? PpicDepartmentTemplateItem.fromPartial(object.item)
      : undefined;
    message.panelCode = (object.panelCode !== undefined && object.panelCode !== null)
      ? PpicPanelCode.fromPartial(object.panelCode)
      : undefined;
    message.completedDate = object.completedDate ?? undefined;
    message.departmentTemplateItemId = object.departmentTemplateItemId ?? undefined;
    return message;
  },
};

function createBasePpicDepartmentTemplateVariant(): PpicDepartmentTemplateVariant {
  return { masterJavaBaseModel: undefined, name: undefined, departmentTemplateVariantTemplates: [] };
}

export const PpicDepartmentTemplateVariant = {
  encode(message: PpicDepartmentTemplateVariant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.departmentTemplateVariantTemplates) {
      PpicDepartmentTemplateVariantTemplate.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplateVariant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplateVariant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departmentTemplateVariantTemplates.push(
            PpicDepartmentTemplateVariantTemplate.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplateVariant {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      departmentTemplateVariantTemplates: Array.isArray(object?.departmentTemplateVariantTemplates)
        ? object.departmentTemplateVariantTemplates.map((e: any) => PpicDepartmentTemplateVariantTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicDepartmentTemplateVariant): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.departmentTemplateVariantTemplates) {
      obj.departmentTemplateVariantTemplates = message.departmentTemplateVariantTemplates.map((e) =>
        e ? PpicDepartmentTemplateVariantTemplate.toJSON(e) : undefined
      );
    } else {
      obj.departmentTemplateVariantTemplates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplateVariant>, I>>(base?: I): PpicDepartmentTemplateVariant {
    return PpicDepartmentTemplateVariant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplateVariant>, I>>(
    object: I,
  ): PpicDepartmentTemplateVariant {
    const message = createBasePpicDepartmentTemplateVariant();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.departmentTemplateVariantTemplates =
      object.departmentTemplateVariantTemplates?.map((e) => PpicDepartmentTemplateVariantTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicDepartmentTemplateVariantTemplate(): PpicDepartmentTemplateVariantTemplate {
  return {
    masterJavaBaseModel: undefined,
    departmentTemplateVariant: undefined,
    departmentTemplateVariantId: undefined,
    departmentTemplate: undefined,
    departmentTemplateId: undefined,
    departmentTemplateVariantTemplateItems: [],
  };
}

export const PpicDepartmentTemplateVariantTemplate = {
  encode(message: PpicDepartmentTemplateVariantTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.departmentTemplateVariant !== undefined) {
      PpicDepartmentTemplateVariant.encode(message.departmentTemplateVariant, writer.uint32(18).fork()).ldelim();
    }
    if (message.departmentTemplateVariantId !== undefined) {
      writer.uint32(24).uint64(message.departmentTemplateVariantId);
    }
    if (message.departmentTemplate !== undefined) {
      PpicDepartmentTemplate.encode(message.departmentTemplate, writer.uint32(34).fork()).ldelim();
    }
    if (message.departmentTemplateId !== undefined) {
      writer.uint32(40).uint64(message.departmentTemplateId);
    }
    for (const v of message.departmentTemplateVariantTemplateItems) {
      PpicDepartmentTemplateVariantTemplateItem.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplateVariantTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplateVariantTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.departmentTemplateVariant = PpicDepartmentTemplateVariant.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.departmentTemplateVariantId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.departmentTemplate = PpicDepartmentTemplate.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.departmentTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.departmentTemplateVariantTemplateItems.push(
            PpicDepartmentTemplateVariantTemplateItem.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplateVariantTemplate {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      departmentTemplateVariant: isSet(object.departmentTemplateVariant)
        ? PpicDepartmentTemplateVariant.fromJSON(object.departmentTemplateVariant)
        : undefined,
      departmentTemplateVariantId: isSet(object.departmentTemplateVariantId)
        ? String(object.departmentTemplateVariantId)
        : undefined,
      departmentTemplate: isSet(object.departmentTemplate)
        ? PpicDepartmentTemplate.fromJSON(object.departmentTemplate)
        : undefined,
      departmentTemplateId: isSet(object.departmentTemplateId) ? String(object.departmentTemplateId) : undefined,
      departmentTemplateVariantTemplateItems: Array.isArray(object?.departmentTemplateVariantTemplateItems)
        ? object.departmentTemplateVariantTemplateItems.map((e: any) =>
          PpicDepartmentTemplateVariantTemplateItem.fromJSON(e)
        )
        : [],
    };
  },

  toJSON(message: PpicDepartmentTemplateVariantTemplate): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.departmentTemplateVariant !== undefined &&
      (obj.departmentTemplateVariant = message.departmentTemplateVariant
        ? PpicDepartmentTemplateVariant.toJSON(message.departmentTemplateVariant)
        : undefined);
    message.departmentTemplateVariantId !== undefined &&
      (obj.departmentTemplateVariantId = message.departmentTemplateVariantId);
    message.departmentTemplate !== undefined && (obj.departmentTemplate = message.departmentTemplate
      ? PpicDepartmentTemplate.toJSON(message.departmentTemplate)
      : undefined);
    message.departmentTemplateId !== undefined && (obj.departmentTemplateId = message.departmentTemplateId);
    if (message.departmentTemplateVariantTemplateItems) {
      obj.departmentTemplateVariantTemplateItems = message.departmentTemplateVariantTemplateItems.map((e) =>
        e ? PpicDepartmentTemplateVariantTemplateItem.toJSON(e) : undefined
      );
    } else {
      obj.departmentTemplateVariantTemplateItems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplateVariantTemplate>, I>>(
    base?: I,
  ): PpicDepartmentTemplateVariantTemplate {
    return PpicDepartmentTemplateVariantTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplateVariantTemplate>, I>>(
    object: I,
  ): PpicDepartmentTemplateVariantTemplate {
    const message = createBasePpicDepartmentTemplateVariantTemplate();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.departmentTemplateVariant =
      (object.departmentTemplateVariant !== undefined && object.departmentTemplateVariant !== null)
        ? PpicDepartmentTemplateVariant.fromPartial(object.departmentTemplateVariant)
        : undefined;
    message.departmentTemplateVariantId = object.departmentTemplateVariantId ?? undefined;
    message.departmentTemplate = (object.departmentTemplate !== undefined && object.departmentTemplate !== null)
      ? PpicDepartmentTemplate.fromPartial(object.departmentTemplate)
      : undefined;
    message.departmentTemplateId = object.departmentTemplateId ?? undefined;
    message.departmentTemplateVariantTemplateItems =
      object.departmentTemplateVariantTemplateItems?.map((e) =>
        PpicDepartmentTemplateVariantTemplateItem.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBasePpicDepartmentTemplateVariantTemplateItem(): PpicDepartmentTemplateVariantTemplateItem {
  return {
    masterJavaBaseModel: undefined,
    name: undefined,
    departmentTemplateVariantTemplate: undefined,
    departmentTemplateVariantTemplateId: undefined,
    departmentTemplateItem: undefined,
    departmentTemplateItemId: undefined,
  };
}

export const PpicDepartmentTemplateVariantTemplateItem = {
  encode(message: PpicDepartmentTemplateVariantTemplateItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.departmentTemplateVariantTemplate !== undefined) {
      PpicDepartmentTemplateVariantTemplate.encode(message.departmentTemplateVariantTemplate, writer.uint32(26).fork())
        .ldelim();
    }
    if (message.departmentTemplateVariantTemplateId !== undefined) {
      writer.uint32(32).uint64(message.departmentTemplateVariantTemplateId);
    }
    if (message.departmentTemplateItem !== undefined) {
      PpicDepartmentTemplateItem.encode(message.departmentTemplateItem, writer.uint32(42).fork()).ldelim();
    }
    if (message.departmentTemplateItemId !== undefined) {
      writer.uint32(48).uint64(message.departmentTemplateItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicDepartmentTemplateVariantTemplateItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicDepartmentTemplateVariantTemplateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departmentTemplateVariantTemplate = PpicDepartmentTemplateVariantTemplate.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.departmentTemplateVariantTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.departmentTemplateItem = PpicDepartmentTemplateItem.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.departmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicDepartmentTemplateVariantTemplateItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      departmentTemplateVariantTemplate: isSet(object.departmentTemplateVariantTemplate)
        ? PpicDepartmentTemplateVariantTemplate.fromJSON(object.departmentTemplateVariantTemplate)
        : undefined,
      departmentTemplateVariantTemplateId: isSet(object.departmentTemplateVariantTemplateId)
        ? String(object.departmentTemplateVariantTemplateId)
        : undefined,
      departmentTemplateItem: isSet(object.departmentTemplateItem)
        ? PpicDepartmentTemplateItem.fromJSON(object.departmentTemplateItem)
        : undefined,
      departmentTemplateItemId: isSet(object.departmentTemplateItemId)
        ? String(object.departmentTemplateItemId)
        : undefined,
    };
  },

  toJSON(message: PpicDepartmentTemplateVariantTemplateItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.departmentTemplateVariantTemplate !== undefined &&
      (obj.departmentTemplateVariantTemplate = message.departmentTemplateVariantTemplate
        ? PpicDepartmentTemplateVariantTemplate.toJSON(message.departmentTemplateVariantTemplate)
        : undefined);
    message.departmentTemplateVariantTemplateId !== undefined &&
      (obj.departmentTemplateVariantTemplateId = message.departmentTemplateVariantTemplateId);
    message.departmentTemplateItem !== undefined && (obj.departmentTemplateItem = message.departmentTemplateItem
      ? PpicDepartmentTemplateItem.toJSON(message.departmentTemplateItem)
      : undefined);
    message.departmentTemplateItemId !== undefined && (obj.departmentTemplateItemId = message.departmentTemplateItemId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicDepartmentTemplateVariantTemplateItem>, I>>(
    base?: I,
  ): PpicDepartmentTemplateVariantTemplateItem {
    return PpicDepartmentTemplateVariantTemplateItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicDepartmentTemplateVariantTemplateItem>, I>>(
    object: I,
  ): PpicDepartmentTemplateVariantTemplateItem {
    const message = createBasePpicDepartmentTemplateVariantTemplateItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.departmentTemplateVariantTemplate =
      (object.departmentTemplateVariantTemplate !== undefined && object.departmentTemplateVariantTemplate !== null)
        ? PpicDepartmentTemplateVariantTemplate.fromPartial(object.departmentTemplateVariantTemplate)
        : undefined;
    message.departmentTemplateVariantTemplateId = object.departmentTemplateVariantTemplateId ?? undefined;
    message.departmentTemplateItem =
      (object.departmentTemplateItem !== undefined && object.departmentTemplateItem !== null)
        ? PpicDepartmentTemplateItem.fromPartial(object.departmentTemplateItem)
        : undefined;
    message.departmentTemplateItemId = object.departmentTemplateItemId ?? undefined;
    return message;
  },
};

function createBasePpicJobOverview(): PpicJobOverview {
  return { job: undefined, wos: undefined, taskLists: undefined, tasks: undefined };
}

export const PpicJobOverview = {
  encode(message: PpicJobOverview, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.job !== undefined) {
      PpicJob.encode(message.job, writer.uint32(10).fork()).ldelim();
    }
    if (message.wos !== undefined) {
      PpicOverviewElem.encode(message.wos, writer.uint32(18).fork()).ldelim();
    }
    if (message.taskLists !== undefined) {
      MeetingTaskListsView.encode(message.taskLists, writer.uint32(34).fork()).ldelim();
    }
    if (message.tasks !== undefined) {
      PpicOverviewElem.encode(message.tasks, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobOverview {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobOverview();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = PpicJob.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.wos = PpicOverviewElem.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.taskLists = MeetingTaskListsView.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tasks = PpicOverviewElem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobOverview {
    return {
      job: isSet(object.job) ? PpicJob.fromJSON(object.job) : undefined,
      wos: isSet(object.wos) ? PpicOverviewElem.fromJSON(object.wos) : undefined,
      taskLists: isSet(object.taskLists) ? MeetingTaskListsView.fromJSON(object.taskLists) : undefined,
      tasks: isSet(object.tasks) ? PpicOverviewElem.fromJSON(object.tasks) : undefined,
    };
  },

  toJSON(message: PpicJobOverview): unknown {
    const obj: any = {};
    message.job !== undefined && (obj.job = message.job ? PpicJob.toJSON(message.job) : undefined);
    message.wos !== undefined && (obj.wos = message.wos ? PpicOverviewElem.toJSON(message.wos) : undefined);
    message.taskLists !== undefined &&
      (obj.taskLists = message.taskLists ? MeetingTaskListsView.toJSON(message.taskLists) : undefined);
    message.tasks !== undefined && (obj.tasks = message.tasks ? PpicOverviewElem.toJSON(message.tasks) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobOverview>, I>>(base?: I): PpicJobOverview {
    return PpicJobOverview.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobOverview>, I>>(object: I): PpicJobOverview {
    const message = createBasePpicJobOverview();
    message.job = (object.job !== undefined && object.job !== null) ? PpicJob.fromPartial(object.job) : undefined;
    message.wos = (object.wos !== undefined && object.wos !== null)
      ? PpicOverviewElem.fromPartial(object.wos)
      : undefined;
    message.taskLists = (object.taskLists !== undefined && object.taskLists !== null)
      ? MeetingTaskListsView.fromPartial(object.taskLists)
      : undefined;
    message.tasks = (object.tasks !== undefined && object.tasks !== null)
      ? PpicOverviewElem.fromPartial(object.tasks)
      : undefined;
    return message;
  },
};

function createBasePpicOverviewElem(): PpicOverviewElem {
  return { total: undefined, done: undefined };
}

export const PpicOverviewElem = {
  encode(message: PpicOverviewElem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.total !== undefined) {
      writer.uint32(8).int32(message.total);
    }
    if (message.done !== undefined) {
      writer.uint32(16).int32(message.done);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicOverviewElem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicOverviewElem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.total = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.done = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicOverviewElem {
    return {
      total: isSet(object.total) ? Number(object.total) : undefined,
      done: isSet(object.done) ? Number(object.done) : undefined,
    };
  },

  toJSON(message: PpicOverviewElem): unknown {
    const obj: any = {};
    message.total !== undefined && (obj.total = Math.round(message.total));
    message.done !== undefined && (obj.done = Math.round(message.done));
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicOverviewElem>, I>>(base?: I): PpicOverviewElem {
    return PpicOverviewElem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicOverviewElem>, I>>(object: I): PpicOverviewElem {
    const message = createBasePpicOverviewElem();
    message.total = object.total ?? undefined;
    message.done = object.done ?? undefined;
    return message;
  },
};

function createBasePpicJobOverviews(): PpicJobOverviews {
  return { jobs: [] };
}

export const PpicJobOverviews = {
  encode(message: PpicJobOverviews, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.jobs) {
      PpicJobOverview.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobOverviews {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobOverviews();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobs.push(PpicJobOverview.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobOverviews {
    return { jobs: Array.isArray(object?.jobs) ? object.jobs.map((e: any) => PpicJobOverview.fromJSON(e)) : [] };
  },

  toJSON(message: PpicJobOverviews): unknown {
    const obj: any = {};
    if (message.jobs) {
      obj.jobs = message.jobs.map((e) => e ? PpicJobOverview.toJSON(e) : undefined);
    } else {
      obj.jobs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobOverviews>, I>>(base?: I): PpicJobOverviews {
    return PpicJobOverviews.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobOverviews>, I>>(object: I): PpicJobOverviews {
    const message = createBasePpicJobOverviews();
    message.jobs = object.jobs?.map((e) => PpicJobOverview.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicExtDepartmentHiddenJobOverviewCol(): PpicExtDepartmentHiddenJobOverviewCol {
  return { masterJavaBaseModel: undefined, name: undefined, extDepartmentId: undefined };
}

export const PpicExtDepartmentHiddenJobOverviewCol = {
  encode(message: PpicExtDepartmentHiddenJobOverviewCol, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.extDepartmentId !== undefined) {
      writer.uint32(24).uint64(message.extDepartmentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicExtDepartmentHiddenJobOverviewCol {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicExtDepartmentHiddenJobOverviewCol();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extDepartmentId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicExtDepartmentHiddenJobOverviewCol {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      extDepartmentId: isSet(object.extDepartmentId) ? String(object.extDepartmentId) : undefined,
    };
  },

  toJSON(message: PpicExtDepartmentHiddenJobOverviewCol): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.extDepartmentId !== undefined && (obj.extDepartmentId = message.extDepartmentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicExtDepartmentHiddenJobOverviewCol>, I>>(
    base?: I,
  ): PpicExtDepartmentHiddenJobOverviewCol {
    return PpicExtDepartmentHiddenJobOverviewCol.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicExtDepartmentHiddenJobOverviewCol>, I>>(
    object: I,
  ): PpicExtDepartmentHiddenJobOverviewCol {
    const message = createBasePpicExtDepartmentHiddenJobOverviewCol();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.extDepartmentId = object.extDepartmentId ?? undefined;
    return message;
  },
};

function createBasePpicExtDepartmentHiddenJobOverviewCols(): PpicExtDepartmentHiddenJobOverviewCols {
  return { hiddenCols: [] };
}

export const PpicExtDepartmentHiddenJobOverviewCols = {
  encode(message: PpicExtDepartmentHiddenJobOverviewCols, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.hiddenCols) {
      PpicExtDepartmentHiddenJobOverviewCol.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicExtDepartmentHiddenJobOverviewCols {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicExtDepartmentHiddenJobOverviewCols();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.hiddenCols.push(PpicExtDepartmentHiddenJobOverviewCol.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicExtDepartmentHiddenJobOverviewCols {
    return {
      hiddenCols: Array.isArray(object?.hiddenCols)
        ? object.hiddenCols.map((e: any) => PpicExtDepartmentHiddenJobOverviewCol.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicExtDepartmentHiddenJobOverviewCols): unknown {
    const obj: any = {};
    if (message.hiddenCols) {
      obj.hiddenCols = message.hiddenCols.map((e) => e ? PpicExtDepartmentHiddenJobOverviewCol.toJSON(e) : undefined);
    } else {
      obj.hiddenCols = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicExtDepartmentHiddenJobOverviewCols>, I>>(
    base?: I,
  ): PpicExtDepartmentHiddenJobOverviewCols {
    return PpicExtDepartmentHiddenJobOverviewCols.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicExtDepartmentHiddenJobOverviewCols>, I>>(
    object: I,
  ): PpicExtDepartmentHiddenJobOverviewCols {
    const message = createBasePpicExtDepartmentHiddenJobOverviewCols();
    message.hiddenCols = object.hiddenCols?.map((e) => PpicExtDepartmentHiddenJobOverviewCol.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicJobBomFlattened(): PpicJobBomFlattened {
  return { job: undefined, bomItems: [], uniqItems: [] };
}

export const PpicJobBomFlattened = {
  encode(message: PpicJobBomFlattened, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.job !== undefined) {
      PpicJob.encode(message.job, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.bomItems) {
      PpicJobBomFlattenedBomItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.uniqItems) {
      PpicJobBomFlattenedUnique.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobBomFlattened {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobBomFlattened();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = PpicJob.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bomItems.push(PpicJobBomFlattenedBomItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.uniqItems.push(PpicJobBomFlattenedUnique.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobBomFlattened {
    return {
      job: isSet(object.job) ? PpicJob.fromJSON(object.job) : undefined,
      bomItems: Array.isArray(object?.bomItems)
        ? object.bomItems.map((e: any) => PpicJobBomFlattenedBomItem.fromJSON(e))
        : [],
      uniqItems: Array.isArray(object?.uniqItems)
        ? object.uniqItems.map((e: any) => PpicJobBomFlattenedUnique.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicJobBomFlattened): unknown {
    const obj: any = {};
    message.job !== undefined && (obj.job = message.job ? PpicJob.toJSON(message.job) : undefined);
    if (message.bomItems) {
      obj.bomItems = message.bomItems.map((e) => e ? PpicJobBomFlattenedBomItem.toJSON(e) : undefined);
    } else {
      obj.bomItems = [];
    }
    if (message.uniqItems) {
      obj.uniqItems = message.uniqItems.map((e) => e ? PpicJobBomFlattenedUnique.toJSON(e) : undefined);
    } else {
      obj.uniqItems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobBomFlattened>, I>>(base?: I): PpicJobBomFlattened {
    return PpicJobBomFlattened.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobBomFlattened>, I>>(object: I): PpicJobBomFlattened {
    const message = createBasePpicJobBomFlattened();
    message.job = (object.job !== undefined && object.job !== null) ? PpicJob.fromPartial(object.job) : undefined;
    message.bomItems = object.bomItems?.map((e) => PpicJobBomFlattenedBomItem.fromPartial(e)) || [];
    message.uniqItems = object.uniqItems?.map((e) => PpicJobBomFlattenedUnique.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicJobBomFlattenedBomItem(): PpicJobBomFlattenedBomItem {
  return { masterJavaBaseModel: undefined, extItemId: undefined, qty: undefined };
}

export const PpicJobBomFlattenedBomItem = {
  encode(message: PpicJobBomFlattenedBomItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.extItemId !== undefined) {
      writer.uint32(16).uint64(message.extItemId);
    }
    if (message.qty !== undefined) {
      writer.uint32(25).double(message.qty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobBomFlattenedBomItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobBomFlattenedBomItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.qty = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobBomFlattenedBomItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
    };
  },

  toJSON(message: PpicJobBomFlattenedBomItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.qty !== undefined && (obj.qty = message.qty);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobBomFlattenedBomItem>, I>>(base?: I): PpicJobBomFlattenedBomItem {
    return PpicJobBomFlattenedBomItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobBomFlattenedBomItem>, I>>(object: I): PpicJobBomFlattenedBomItem {
    const message = createBasePpicJobBomFlattenedBomItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.extItemId = object.extItemId ?? undefined;
    message.qty = object.qty ?? undefined;
    return message;
  },
};

function createBasePpicJobBomFlattenedUnique(): PpicJobBomFlattenedUnique {
  return { extItemId: undefined, qty: undefined };
}

export const PpicJobBomFlattenedUnique = {
  encode(message: PpicJobBomFlattenedUnique, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extItemId !== undefined) {
      writer.uint32(16).uint64(message.extItemId);
    }
    if (message.qty !== undefined) {
      writer.uint32(25).double(message.qty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicJobBomFlattenedUnique {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicJobBomFlattenedUnique();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.extItemId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.qty = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicJobBomFlattenedUnique {
    return {
      extItemId: isSet(object.extItemId) ? String(object.extItemId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
    };
  },

  toJSON(message: PpicJobBomFlattenedUnique): unknown {
    const obj: any = {};
    message.extItemId !== undefined && (obj.extItemId = message.extItemId);
    message.qty !== undefined && (obj.qty = message.qty);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicJobBomFlattenedUnique>, I>>(base?: I): PpicJobBomFlattenedUnique {
    return PpicJobBomFlattenedUnique.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicJobBomFlattenedUnique>, I>>(object: I): PpicJobBomFlattenedUnique {
    const message = createBasePpicJobBomFlattenedUnique();
    message.extItemId = object.extItemId ?? undefined;
    message.qty = object.qty ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeSerialNumberBodyExternal(): PpicPanelCodeSerialNumberBodyExternal {
  return { productId: undefined, serialNumbers: [] };
}

export const PpicPanelCodeSerialNumberBodyExternal = {
  encode(message: PpicPanelCodeSerialNumberBodyExternal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productId !== undefined) {
      writer.uint32(8).uint64(message.productId);
    }
    for (const v of message.serialNumbers) {
      PpicPanelCodeSerialNumberExternal.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeSerialNumberBodyExternal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeSerialNumberBodyExternal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serialNumbers.push(PpicPanelCodeSerialNumberExternal.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeSerialNumberBodyExternal {
    return {
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      serialNumbers: Array.isArray(object?.serialNumbers)
        ? object.serialNumbers.map((e: any) => PpicPanelCodeSerialNumberExternal.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeSerialNumberBodyExternal): unknown {
    const obj: any = {};
    message.productId !== undefined && (obj.productId = message.productId);
    if (message.serialNumbers) {
      obj.serialNumbers = message.serialNumbers.map((e) => e ? PpicPanelCodeSerialNumberExternal.toJSON(e) : undefined);
    } else {
      obj.serialNumbers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberBodyExternal>, I>>(
    base?: I,
  ): PpicPanelCodeSerialNumberBodyExternal {
    return PpicPanelCodeSerialNumberBodyExternal.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberBodyExternal>, I>>(
    object: I,
  ): PpicPanelCodeSerialNumberBodyExternal {
    const message = createBasePpicPanelCodeSerialNumberBodyExternal();
    message.productId = object.productId ?? undefined;
    message.serialNumbers = object.serialNumbers?.map((e) => PpicPanelCodeSerialNumberExternal.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPanelCodeSerialNumberExternal(): PpicPanelCodeSerialNumberExternal {
  return {
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    jobId: undefined,
    fullName: undefined,
    panelCodeType: undefined,
  };
}

export const PpicPanelCodeSerialNumberExternal = {
  encode(message: PpicPanelCodeSerialNumberExternal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(18).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(26).string(message.updatedAt);
    }
    if (message.jobId !== undefined) {
      writer.uint32(32).uint64(message.jobId);
    }
    if (message.fullName !== undefined) {
      writer.uint32(42).string(message.fullName);
    }
    if (message.panelCodeType !== undefined) {
      writer.uint32(50).string(message.panelCodeType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeSerialNumberExternal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeSerialNumberExternal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.panelCodeType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeSerialNumberExternal {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      fullName: isSet(object.fullName) ? String(object.fullName) : undefined,
      panelCodeType: isSet(object.panelCodeType) ? String(object.panelCodeType) : undefined,
    };
  },

  toJSON(message: PpicPanelCodeSerialNumberExternal): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.fullName !== undefined && (obj.fullName = message.fullName);
    message.panelCodeType !== undefined && (obj.panelCodeType = message.panelCodeType);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberExternal>, I>>(
    base?: I,
  ): PpicPanelCodeSerialNumberExternal {
    return PpicPanelCodeSerialNumberExternal.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeSerialNumberExternal>, I>>(
    object: I,
  ): PpicPanelCodeSerialNumberExternal {
    const message = createBasePpicPanelCodeSerialNumberExternal();
    message.id = object.id ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.fullName = object.fullName ?? undefined;
    message.panelCodeType = object.panelCodeType ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplatePreset(): PpicPanelCodeDepartmentTemplatePreset {
  return { masterJavaBaseModel: undefined, name: undefined, depts: [], items: [] };
}

export const PpicPanelCodeDepartmentTemplatePreset = {
  encode(message: PpicPanelCodeDepartmentTemplatePreset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.depts) {
      PpicPanelCodeDepartmentTemplatePresetDept.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.items) {
      PpicPanelCodeDepartmentTemplatePresetDeptItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplatePreset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplatePreset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.depts.push(PpicPanelCodeDepartmentTemplatePresetDept.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.items.push(PpicPanelCodeDepartmentTemplatePresetDeptItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplatePreset {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      depts: Array.isArray(object?.depts)
        ? object.depts.map((e: any) => PpicPanelCodeDepartmentTemplatePresetDept.fromJSON(e))
        : [],
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => PpicPanelCodeDepartmentTemplatePresetDeptItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplatePreset): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.depts) {
      obj.depts = message.depts.map((e) => e ? PpicPanelCodeDepartmentTemplatePresetDept.toJSON(e) : undefined);
    } else {
      obj.depts = [];
    }
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicPanelCodeDepartmentTemplatePresetDeptItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePreset>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplatePreset {
    return PpicPanelCodeDepartmentTemplatePreset.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePreset>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplatePreset {
    const message = createBasePpicPanelCodeDepartmentTemplatePreset();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.depts = object.depts?.map((e) => PpicPanelCodeDepartmentTemplatePresetDept.fromPartial(e)) || [];
    message.items = object.items?.map((e) => PpicPanelCodeDepartmentTemplatePresetDeptItem.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplatePresets(): PpicPanelCodeDepartmentTemplatePresets {
  return { presets: [] };
}

export const PpicPanelCodeDepartmentTemplatePresets = {
  encode(message: PpicPanelCodeDepartmentTemplatePresets, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.presets) {
      PpicPanelCodeDepartmentTemplatePreset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplatePresets {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplatePresets();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.presets.push(PpicPanelCodeDepartmentTemplatePreset.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplatePresets {
    return {
      presets: Array.isArray(object?.presets)
        ? object.presets.map((e: any) => PpicPanelCodeDepartmentTemplatePreset.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplatePresets): unknown {
    const obj: any = {};
    if (message.presets) {
      obj.presets = message.presets.map((e) => e ? PpicPanelCodeDepartmentTemplatePreset.toJSON(e) : undefined);
    } else {
      obj.presets = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresets>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplatePresets {
    return PpicPanelCodeDepartmentTemplatePresets.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresets>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplatePresets {
    const message = createBasePpicPanelCodeDepartmentTemplatePresets();
    message.presets = object.presets?.map((e) => PpicPanelCodeDepartmentTemplatePreset.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplatePresetDept(): PpicPanelCodeDepartmentTemplatePresetDept {
  return {
    masterJavaBaseModel: undefined,
    presetId: undefined,
    panelCodeDepartmentTemplateId: undefined,
    deptItems: [],
  };
}

export const PpicPanelCodeDepartmentTemplatePresetDept = {
  encode(message: PpicPanelCodeDepartmentTemplatePresetDept, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.presetId !== undefined) {
      writer.uint32(16).uint64(message.presetId);
    }
    if (message.panelCodeDepartmentTemplateId !== undefined) {
      writer.uint32(24).uint64(message.panelCodeDepartmentTemplateId);
    }
    for (const v of message.deptItems) {
      PpicPanelCodeDepartmentTemplatePresetDeptItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplatePresetDept {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDept();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.presetId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.panelCodeDepartmentTemplateId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deptItems.push(PpicPanelCodeDepartmentTemplatePresetDeptItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplatePresetDept {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      presetId: isSet(object.presetId) ? String(object.presetId) : undefined,
      panelCodeDepartmentTemplateId: isSet(object.panelCodeDepartmentTemplateId)
        ? String(object.panelCodeDepartmentTemplateId)
        : undefined,
      deptItems: Array.isArray(object?.deptItems)
        ? object.deptItems.map((e: any) => PpicPanelCodeDepartmentTemplatePresetDeptItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplatePresetDept): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.presetId !== undefined && (obj.presetId = message.presetId);
    message.panelCodeDepartmentTemplateId !== undefined &&
      (obj.panelCodeDepartmentTemplateId = message.panelCodeDepartmentTemplateId);
    if (message.deptItems) {
      obj.deptItems = message.deptItems.map((e) =>
        e ? PpicPanelCodeDepartmentTemplatePresetDeptItem.toJSON(e) : undefined
      );
    } else {
      obj.deptItems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDept>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplatePresetDept {
    return PpicPanelCodeDepartmentTemplatePresetDept.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDept>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplatePresetDept {
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDept();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.presetId = object.presetId ?? undefined;
    message.panelCodeDepartmentTemplateId = object.panelCodeDepartmentTemplateId ?? undefined;
    message.deptItems = object.deptItems?.map((e) => PpicPanelCodeDepartmentTemplatePresetDeptItem.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplatePresetDepts(): PpicPanelCodeDepartmentTemplatePresetDepts {
  return { depts: [] };
}

export const PpicPanelCodeDepartmentTemplatePresetDepts = {
  encode(message: PpicPanelCodeDepartmentTemplatePresetDepts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.depts) {
      PpicPanelCodeDepartmentTemplatePresetDept.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplatePresetDepts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDepts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.depts.push(PpicPanelCodeDepartmentTemplatePresetDept.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplatePresetDepts {
    return {
      depts: Array.isArray(object?.depts)
        ? object.depts.map((e: any) => PpicPanelCodeDepartmentTemplatePresetDept.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplatePresetDepts): unknown {
    const obj: any = {};
    if (message.depts) {
      obj.depts = message.depts.map((e) => e ? PpicPanelCodeDepartmentTemplatePresetDept.toJSON(e) : undefined);
    } else {
      obj.depts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDepts>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplatePresetDepts {
    return PpicPanelCodeDepartmentTemplatePresetDepts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDepts>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplatePresetDepts {
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDepts();
    message.depts = object.depts?.map((e) => PpicPanelCodeDepartmentTemplatePresetDept.fromPartial(e)) || [];
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplatePresetDeptItem(): PpicPanelCodeDepartmentTemplatePresetDeptItem {
  return {
    masterJavaBaseModel: undefined,
    presetDeptId: undefined,
    panelCodeDepartmentTemplateItemId: undefined,
    presetId: undefined,
    departmentTemplateItemId: undefined,
  };
}

export const PpicPanelCodeDepartmentTemplatePresetDeptItem = {
  encode(message: PpicPanelCodeDepartmentTemplatePresetDeptItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.masterJavaBaseModel !== undefined) {
      MasterJavaBaseModel.encode(message.masterJavaBaseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.presetDeptId !== undefined) {
      writer.uint32(16).uint64(message.presetDeptId);
    }
    if (message.panelCodeDepartmentTemplateItemId !== undefined) {
      writer.uint32(32).uint64(message.panelCodeDepartmentTemplateItemId);
    }
    if (message.presetId !== undefined) {
      writer.uint32(40).uint64(message.presetId);
    }
    if (message.departmentTemplateItemId !== undefined) {
      writer.uint32(48).uint64(message.departmentTemplateItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplatePresetDeptItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDeptItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.masterJavaBaseModel = MasterJavaBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.presetDeptId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.panelCodeDepartmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.presetId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.departmentTemplateItemId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplatePresetDeptItem {
    return {
      masterJavaBaseModel: isSet(object.masterJavaBaseModel)
        ? MasterJavaBaseModel.fromJSON(object.masterJavaBaseModel)
        : undefined,
      presetDeptId: isSet(object.presetDeptId) ? String(object.presetDeptId) : undefined,
      panelCodeDepartmentTemplateItemId: isSet(object.panelCodeDepartmentTemplateItemId)
        ? String(object.panelCodeDepartmentTemplateItemId)
        : undefined,
      presetId: isSet(object.presetId) ? String(object.presetId) : undefined,
      departmentTemplateItemId: isSet(object.departmentTemplateItemId)
        ? String(object.departmentTemplateItemId)
        : undefined,
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplatePresetDeptItem): unknown {
    const obj: any = {};
    message.masterJavaBaseModel !== undefined && (obj.masterJavaBaseModel = message.masterJavaBaseModel
      ? MasterJavaBaseModel.toJSON(message.masterJavaBaseModel)
      : undefined);
    message.presetDeptId !== undefined && (obj.presetDeptId = message.presetDeptId);
    message.panelCodeDepartmentTemplateItemId !== undefined &&
      (obj.panelCodeDepartmentTemplateItemId = message.panelCodeDepartmentTemplateItemId);
    message.presetId !== undefined && (obj.presetId = message.presetId);
    message.departmentTemplateItemId !== undefined && (obj.departmentTemplateItemId = message.departmentTemplateItemId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDeptItem>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplatePresetDeptItem {
    return PpicPanelCodeDepartmentTemplatePresetDeptItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDeptItem>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplatePresetDeptItem {
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDeptItem();
    message.masterJavaBaseModel = (object.masterJavaBaseModel !== undefined && object.masterJavaBaseModel !== null)
      ? MasterJavaBaseModel.fromPartial(object.masterJavaBaseModel)
      : undefined;
    message.presetDeptId = object.presetDeptId ?? undefined;
    message.panelCodeDepartmentTemplateItemId = object.panelCodeDepartmentTemplateItemId ?? undefined;
    message.presetId = object.presetId ?? undefined;
    message.departmentTemplateItemId = object.departmentTemplateItemId ?? undefined;
    return message;
  },
};

function createBasePpicPanelCodeDepartmentTemplatePresetDeptItems(): PpicPanelCodeDepartmentTemplatePresetDeptItems {
  return { items: [] };
}

export const PpicPanelCodeDepartmentTemplatePresetDeptItems = {
  encode(
    message: PpicPanelCodeDepartmentTemplatePresetDeptItems,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.items) {
      PpicPanelCodeDepartmentTemplatePresetDeptItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PpicPanelCodeDepartmentTemplatePresetDeptItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDeptItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(PpicPanelCodeDepartmentTemplatePresetDeptItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PpicPanelCodeDepartmentTemplatePresetDeptItems {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => PpicPanelCodeDepartmentTemplatePresetDeptItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PpicPanelCodeDepartmentTemplatePresetDeptItems): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? PpicPanelCodeDepartmentTemplatePresetDeptItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDeptItems>, I>>(
    base?: I,
  ): PpicPanelCodeDepartmentTemplatePresetDeptItems {
    return PpicPanelCodeDepartmentTemplatePresetDeptItems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PpicPanelCodeDepartmentTemplatePresetDeptItems>, I>>(
    object: I,
  ): PpicPanelCodeDepartmentTemplatePresetDeptItems {
    const message = createBasePpicPanelCodeDepartmentTemplatePresetDeptItems();
    message.items = object.items?.map((e) => PpicPanelCodeDepartmentTemplatePresetDeptItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWarehouseItem(): WarehouseItem {
  return {
    id: undefined,
    mfr: undefined,
    partNum: undefined,
    partName: undefined,
    partDesc: undefined,
    defaultUm: undefined,
    type: undefined,
    categoryPartNum: undefined,
  };
}

export const WarehouseItem = {
  encode(message: WarehouseItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.mfr !== undefined) {
      writer.uint32(18).string(message.mfr);
    }
    if (message.partNum !== undefined) {
      writer.uint32(26).string(message.partNum);
    }
    if (message.partName !== undefined) {
      writer.uint32(34).string(message.partName);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(42).string(message.partDesc);
    }
    if (message.defaultUm !== undefined) {
      writer.uint32(50).string(message.defaultUm);
    }
    if (message.type !== undefined) {
      writer.uint32(56).int32(message.type);
    }
    if (message.categoryPartNum !== undefined) {
      writer.uint32(64).int32(message.categoryPartNum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partNum = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.defaultUm = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.categoryPartNum = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseItem {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      partNum: isSet(object.partNum) ? String(object.partNum) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      defaultUm: isSet(object.defaultUm) ? String(object.defaultUm) : undefined,
      type: isSet(object.type) ? warehouseItem_WarehouseItemTypeFromJSON(object.type) : undefined,
      categoryPartNum: isSet(object.categoryPartNum)
        ? warehouseItemCategoryPartNumFromJSON(object.categoryPartNum)
        : undefined,
    };
  },

  toJSON(message: WarehouseItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.partNum !== undefined && (obj.partNum = message.partNum);
    message.partName !== undefined && (obj.partName = message.partName);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.defaultUm !== undefined && (obj.defaultUm = message.defaultUm);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? warehouseItem_WarehouseItemTypeToJSON(message.type) : undefined);
    message.categoryPartNum !== undefined && (obj.categoryPartNum = message.categoryPartNum !== undefined
      ? warehouseItemCategoryPartNumToJSON(message.categoryPartNum)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseItem>, I>>(base?: I): WarehouseItem {
    return WarehouseItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseItem>, I>>(object: I): WarehouseItem {
    const message = createBaseWarehouseItem();
    message.id = object.id ?? undefined;
    message.mfr = object.mfr ?? undefined;
    message.partNum = object.partNum ?? undefined;
    message.partName = object.partName ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.defaultUm = object.defaultUm ?? undefined;
    message.type = object.type ?? undefined;
    message.categoryPartNum = object.categoryPartNum ?? undefined;
    return message;
  },
};

function createBaseWarehouseMaterialRequestSendPostBody(): WarehouseMaterialRequestSendPostBody {
  return { jobId: undefined, items: undefined };
}

export const WarehouseMaterialRequestSendPostBody = {
  encode(message: WarehouseMaterialRequestSendPostBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== undefined) {
      writer.uint32(8).uint64(message.jobId);
    }
    if (message.items !== undefined) {
      WarehouseMaterialRequestSendPostBodyItem.encode(message.items, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseMaterialRequestSendPostBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseMaterialRequestSendPostBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.items = WarehouseMaterialRequestSendPostBodyItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseMaterialRequestSendPostBody {
    return {
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      items: isSet(object.items) ? WarehouseMaterialRequestSendPostBodyItem.fromJSON(object.items) : undefined,
    };
  },

  toJSON(message: WarehouseMaterialRequestSendPostBody): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.items !== undefined &&
      (obj.items = message.items ? WarehouseMaterialRequestSendPostBodyItem.toJSON(message.items) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseMaterialRequestSendPostBody>, I>>(
    base?: I,
  ): WarehouseMaterialRequestSendPostBody {
    return WarehouseMaterialRequestSendPostBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseMaterialRequestSendPostBody>, I>>(
    object: I,
  ): WarehouseMaterialRequestSendPostBody {
    const message = createBaseWarehouseMaterialRequestSendPostBody();
    message.jobId = object.jobId ?? undefined;
    message.items = (object.items !== undefined && object.items !== null)
      ? WarehouseMaterialRequestSendPostBodyItem.fromPartial(object.items)
      : undefined;
    return message;
  },
};

function createBaseWarehouseMaterialRequestSendPostBodyItem(): WarehouseMaterialRequestSendPostBodyItem {
  return { id: undefined, transactionType: undefined, productId: undefined, qtyReserve: undefined };
}

export const WarehouseMaterialRequestSendPostBodyItem = {
  encode(message: WarehouseMaterialRequestSendPostBodyItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.transactionType !== undefined) {
      writer.uint32(18).string(message.transactionType);
    }
    if (message.productId !== undefined) {
      writer.uint32(24).uint64(message.productId);
    }
    if (message.qtyReserve !== undefined) {
      writer.uint32(32).uint64(message.qtyReserve);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseMaterialRequestSendPostBodyItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseMaterialRequestSendPostBodyItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionType = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qtyReserve = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseMaterialRequestSendPostBodyItem {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      transactionType: isSet(object.transactionType) ? String(object.transactionType) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      qtyReserve: isSet(object.qtyReserve) ? String(object.qtyReserve) : undefined,
    };
  },

  toJSON(message: WarehouseMaterialRequestSendPostBodyItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.transactionType !== undefined && (obj.transactionType = message.transactionType);
    message.productId !== undefined && (obj.productId = message.productId);
    message.qtyReserve !== undefined && (obj.qtyReserve = message.qtyReserve);
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseMaterialRequestSendPostBodyItem>, I>>(
    base?: I,
  ): WarehouseMaterialRequestSendPostBodyItem {
    return WarehouseMaterialRequestSendPostBodyItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseMaterialRequestSendPostBodyItem>, I>>(
    object: I,
  ): WarehouseMaterialRequestSendPostBodyItem {
    const message = createBaseWarehouseMaterialRequestSendPostBodyItem();
    message.id = object.id ?? undefined;
    message.transactionType = object.transactionType ?? undefined;
    message.productId = object.productId ?? undefined;
    message.qtyReserve = object.qtyReserve ?? undefined;
    return message;
  },
};

function createBaseWarehouseMaterialRequestPostBody(): WarehouseMaterialRequestPostBody {
  return { jobId: undefined, items: undefined };
}

export const WarehouseMaterialRequestPostBody = {
  encode(message: WarehouseMaterialRequestPostBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== undefined) {
      writer.uint32(8).uint64(message.jobId);
    }
    if (message.items !== undefined) {
      WarehouseMaterialRequestPostBodyItem.encode(message.items, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseMaterialRequestPostBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseMaterialRequestPostBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.items = WarehouseMaterialRequestPostBodyItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseMaterialRequestPostBody {
    return {
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      items: isSet(object.items) ? WarehouseMaterialRequestPostBodyItem.fromJSON(object.items) : undefined,
    };
  },

  toJSON(message: WarehouseMaterialRequestPostBody): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.items !== undefined &&
      (obj.items = message.items ? WarehouseMaterialRequestPostBodyItem.toJSON(message.items) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBody>, I>>(
    base?: I,
  ): WarehouseMaterialRequestPostBody {
    return WarehouseMaterialRequestPostBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBody>, I>>(
    object: I,
  ): WarehouseMaterialRequestPostBody {
    const message = createBaseWarehouseMaterialRequestPostBody();
    message.jobId = object.jobId ?? undefined;
    message.items = (object.items !== undefined && object.items !== null)
      ? WarehouseMaterialRequestPostBodyItem.fromPartial(object.items)
      : undefined;
    return message;
  },
};

function createBaseWarehouseMaterialRequestPostBodyItem(): WarehouseMaterialRequestPostBodyItem {
  return { id: undefined, transactionType: undefined, productId: undefined, qtyReserve: undefined };
}

export const WarehouseMaterialRequestPostBodyItem = {
  encode(message: WarehouseMaterialRequestPostBodyItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.transactionType !== undefined) {
      writer.uint32(18).string(message.transactionType);
    }
    if (message.productId !== undefined) {
      writer.uint32(24).uint64(message.productId);
    }
    if (message.qtyReserve !== undefined) {
      writer.uint32(32).uint64(message.qtyReserve);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseMaterialRequestPostBodyItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseMaterialRequestPostBodyItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionType = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qtyReserve = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseMaterialRequestPostBodyItem {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      transactionType: isSet(object.transactionType) ? String(object.transactionType) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      qtyReserve: isSet(object.qtyReserve) ? String(object.qtyReserve) : undefined,
    };
  },

  toJSON(message: WarehouseMaterialRequestPostBodyItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.transactionType !== undefined && (obj.transactionType = message.transactionType);
    message.productId !== undefined && (obj.productId = message.productId);
    message.qtyReserve !== undefined && (obj.qtyReserve = message.qtyReserve);
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBodyItem>, I>>(
    base?: I,
  ): WarehouseMaterialRequestPostBodyItem {
    return WarehouseMaterialRequestPostBodyItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBodyItem>, I>>(
    object: I,
  ): WarehouseMaterialRequestPostBodyItem {
    const message = createBaseWarehouseMaterialRequestPostBodyItem();
    message.id = object.id ?? undefined;
    message.transactionType = object.transactionType ?? undefined;
    message.productId = object.productId ?? undefined;
    message.qtyReserve = object.qtyReserve ?? undefined;
    return message;
  },
};

function createBaseWarehouseMaterialRequestPostBodyItemResponse(): WarehouseMaterialRequestPostBodyItemResponse {
  return {
    id: undefined,
    productId: undefined,
    transactionType: undefined,
    jobId: undefined,
    qtyReserve: undefined,
    createdAt: undefined,
    updatedAt: undefined,
  };
}

export const WarehouseMaterialRequestPostBodyItemResponse = {
  encode(message: WarehouseMaterialRequestPostBodyItemResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.productId !== undefined) {
      writer.uint32(18).string(message.productId);
    }
    if (message.transactionType !== undefined) {
      writer.uint32(26).string(message.transactionType);
    }
    if (message.jobId !== undefined) {
      writer.uint32(32).uint64(message.jobId);
    }
    if (message.qtyReserve !== undefined) {
      writer.uint32(40).uint64(message.qtyReserve);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(50).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(58).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseMaterialRequestPostBodyItemResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseMaterialRequestPostBodyItemResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.productId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactionType = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.qtyReserve = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseMaterialRequestPostBodyItemResponse {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      transactionType: isSet(object.transactionType) ? String(object.transactionType) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      qtyReserve: isSet(object.qtyReserve) ? String(object.qtyReserve) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
    };
  },

  toJSON(message: WarehouseMaterialRequestPostBodyItemResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.productId !== undefined && (obj.productId = message.productId);
    message.transactionType !== undefined && (obj.transactionType = message.transactionType);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.qtyReserve !== undefined && (obj.qtyReserve = message.qtyReserve);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBodyItemResponse>, I>>(
    base?: I,
  ): WarehouseMaterialRequestPostBodyItemResponse {
    return WarehouseMaterialRequestPostBodyItemResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBodyItemResponse>, I>>(
    object: I,
  ): WarehouseMaterialRequestPostBodyItemResponse {
    const message = createBaseWarehouseMaterialRequestPostBodyItemResponse();
    message.id = object.id ?? undefined;
    message.productId = object.productId ?? undefined;
    message.transactionType = object.transactionType ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.qtyReserve = object.qtyReserve ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseWarehouseMaterialRequestPostBodyResponse(): WarehouseMaterialRequestPostBodyResponse {
  return { items: [] };
}

export const WarehouseMaterialRequestPostBodyResponse = {
  encode(message: WarehouseMaterialRequestPostBodyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      WarehouseMaterialRequestPostBodyItemResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseMaterialRequestPostBodyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseMaterialRequestPostBodyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(WarehouseMaterialRequestPostBodyItemResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseMaterialRequestPostBodyResponse {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => WarehouseMaterialRequestPostBodyItemResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WarehouseMaterialRequestPostBodyResponse): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? WarehouseMaterialRequestPostBodyItemResponse.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBodyResponse>, I>>(
    base?: I,
  ): WarehouseMaterialRequestPostBodyResponse {
    return WarehouseMaterialRequestPostBodyResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseMaterialRequestPostBodyResponse>, I>>(
    object: I,
  ): WarehouseMaterialRequestPostBodyResponse {
    const message = createBaseWarehouseMaterialRequestPostBodyResponse();
    message.items = object.items?.map((e) => WarehouseMaterialRequestPostBodyItemResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWarehouseReserveList(): WarehouseReserveList {
  return {
    id: undefined,
    transactionType: undefined,
    jobId: undefined,
    qtyReserve: undefined,
    productId: undefined,
    products: undefined,
  };
}

export const WarehouseReserveList = {
  encode(message: WarehouseReserveList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.transactionType !== undefined) {
      writer.uint32(18).string(message.transactionType);
    }
    if (message.jobId !== undefined) {
      writer.uint32(24).uint64(message.jobId);
    }
    if (message.qtyReserve !== undefined) {
      writer.uint32(32).uint64(message.qtyReserve);
    }
    if (message.productId !== undefined) {
      writer.uint32(42).string(message.productId);
    }
    if (message.products !== undefined) {
      WarehouseItem.encode(message.products, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseReserveList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseReserveList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionType = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qtyReserve = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.productId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.products = WarehouseItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseReserveList {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      transactionType: isSet(object.transactionType) ? String(object.transactionType) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      qtyReserve: isSet(object.qtyReserve) ? String(object.qtyReserve) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      products: isSet(object.products) ? WarehouseItem.fromJSON(object.products) : undefined,
    };
  },

  toJSON(message: WarehouseReserveList): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.transactionType !== undefined && (obj.transactionType = message.transactionType);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.qtyReserve !== undefined && (obj.qtyReserve = message.qtyReserve);
    message.productId !== undefined && (obj.productId = message.productId);
    message.products !== undefined &&
      (obj.products = message.products ? WarehouseItem.toJSON(message.products) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseReserveList>, I>>(base?: I): WarehouseReserveList {
    return WarehouseReserveList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseReserveList>, I>>(object: I): WarehouseReserveList {
    const message = createBaseWarehouseReserveList();
    message.id = object.id ?? undefined;
    message.transactionType = object.transactionType ?? undefined;
    message.jobId = object.jobId ?? undefined;
    message.qtyReserve = object.qtyReserve ?? undefined;
    message.productId = object.productId ?? undefined;
    message.products = (object.products !== undefined && object.products !== null)
      ? WarehouseItem.fromPartial(object.products)
      : undefined;
    return message;
  },
};

function createBaseWarehouseReserveLists(): WarehouseReserveLists {
  return { reserveLists: [] };
}

export const WarehouseReserveLists = {
  encode(message: WarehouseReserveLists, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.reserveLists) {
      WarehouseReserveList.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseReserveLists {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseReserveLists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reserveLists.push(WarehouseReserveList.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseReserveLists {
    return {
      reserveLists: Array.isArray(object?.reserveLists)
        ? object.reserveLists.map((e: any) => WarehouseReserveList.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WarehouseReserveLists): unknown {
    const obj: any = {};
    if (message.reserveLists) {
      obj.reserveLists = message.reserveLists.map((e) => e ? WarehouseReserveList.toJSON(e) : undefined);
    } else {
      obj.reserveLists = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseReserveLists>, I>>(base?: I): WarehouseReserveLists {
    return WarehouseReserveLists.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseReserveLists>, I>>(object: I): WarehouseReserveLists {
    const message = createBaseWarehouseReserveLists();
    message.reserveLists = object.reserveLists?.map((e) => WarehouseReserveList.fromPartial(e)) || [];
    return message;
  },
};

function createBasePurchasingSupplierPurchaseOrder(): PurchasingSupplierPurchaseOrder {
  return { id: undefined, productId: undefined, products: undefined, qtyDelivered: undefined, qtyPos: undefined };
}

export const PurchasingSupplierPurchaseOrder = {
  encode(message: PurchasingSupplierPurchaseOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.productId !== undefined) {
      writer.uint32(16).uint64(message.productId);
    }
    if (message.products !== undefined) {
      MasterEmpty.encode(message.products, writer.uint32(26).fork()).ldelim();
    }
    if (message.qtyDelivered !== undefined) {
      writer.uint32(33).double(message.qtyDelivered);
    }
    if (message.qtyPos !== undefined) {
      writer.uint32(41).double(message.qtyPos);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingSupplierPurchaseOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingSupplierPurchaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.products = MasterEmpty.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.qtyDelivered = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.qtyPos = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingSupplierPurchaseOrder {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      products: isSet(object.products) ? MasterEmpty.fromJSON(object.products) : undefined,
      qtyDelivered: isSet(object.qtyDelivered) ? Number(object.qtyDelivered) : undefined,
      qtyPos: isSet(object.qtyPos) ? Number(object.qtyPos) : undefined,
    };
  },

  toJSON(message: PurchasingSupplierPurchaseOrder): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.productId !== undefined && (obj.productId = message.productId);
    message.products !== undefined &&
      (obj.products = message.products ? MasterEmpty.toJSON(message.products) : undefined);
    message.qtyDelivered !== undefined && (obj.qtyDelivered = message.qtyDelivered);
    message.qtyPos !== undefined && (obj.qtyPos = message.qtyPos);
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingSupplierPurchaseOrder>, I>>(base?: I): PurchasingSupplierPurchaseOrder {
    return PurchasingSupplierPurchaseOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingSupplierPurchaseOrder>, I>>(
    object: I,
  ): PurchasingSupplierPurchaseOrder {
    const message = createBasePurchasingSupplierPurchaseOrder();
    message.id = object.id ?? undefined;
    message.productId = object.productId ?? undefined;
    message.products = (object.products !== undefined && object.products !== null)
      ? MasterEmpty.fromPartial(object.products)
      : undefined;
    message.qtyDelivered = object.qtyDelivered ?? undefined;
    message.qtyPos = object.qtyPos ?? undefined;
    return message;
  },
};

function createBasePurchasingSupplierPurchaseOrders(): PurchasingSupplierPurchaseOrders {
  return { purchaseOrders: [] };
}

export const PurchasingSupplierPurchaseOrders = {
  encode(message: PurchasingSupplierPurchaseOrders, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.purchaseOrders) {
      PurchasingSupplierPurchaseOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingSupplierPurchaseOrders {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingSupplierPurchaseOrders();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.purchaseOrders.push(PurchasingSupplierPurchaseOrder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingSupplierPurchaseOrders {
    return {
      purchaseOrders: Array.isArray(object?.purchaseOrders)
        ? object.purchaseOrders.map((e: any) => PurchasingSupplierPurchaseOrder.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PurchasingSupplierPurchaseOrders): unknown {
    const obj: any = {};
    if (message.purchaseOrders) {
      obj.purchaseOrders = message.purchaseOrders.map((e) => e ? PurchasingSupplierPurchaseOrder.toJSON(e) : undefined);
    } else {
      obj.purchaseOrders = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingSupplierPurchaseOrders>, I>>(
    base?: I,
  ): PurchasingSupplierPurchaseOrders {
    return PurchasingSupplierPurchaseOrders.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingSupplierPurchaseOrders>, I>>(
    object: I,
  ): PurchasingSupplierPurchaseOrders {
    const message = createBasePurchasingSupplierPurchaseOrders();
    message.purchaseOrders = object.purchaseOrders?.map((e) => PurchasingSupplierPurchaseOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBasePurchasingPurchaseRequestPostBody(): PurchasingPurchaseRequestPostBody {
  return {
    prNumber: undefined,
    requestFrom: undefined,
    purpose: undefined,
    purposeRemark: undefined,
    requestMode: undefined,
    prDate: undefined,
    prTarget: undefined,
    createdBy: undefined,
    products: [],
  };
}

export const PurchasingPurchaseRequestPostBody = {
  encode(message: PurchasingPurchaseRequestPostBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prNumber !== undefined) {
      writer.uint32(10).string(message.prNumber);
    }
    if (message.requestFrom !== undefined) {
      writer.uint32(18).string(message.requestFrom);
    }
    if (message.purpose !== undefined) {
      writer.uint32(26).string(message.purpose);
    }
    if (message.purposeRemark !== undefined) {
      writer.uint32(34).string(message.purposeRemark);
    }
    if (message.requestMode !== undefined) {
      writer.uint32(40).uint64(message.requestMode);
    }
    if (message.prDate !== undefined) {
      writer.uint32(50).string(message.prDate);
    }
    if (message.prTarget !== undefined) {
      writer.uint32(58).string(message.prTarget);
    }
    if (message.createdBy !== undefined) {
      writer.uint32(66).string(message.createdBy);
    }
    for (const v of message.products) {
      PurchasingPurchaseRequestPostBodyProduct.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingPurchaseRequestPostBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingPurchaseRequestPostBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.prNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requestFrom = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.purpose = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.purposeRemark = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.requestMode = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.prDate = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.prTarget = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.products.push(PurchasingPurchaseRequestPostBodyProduct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingPurchaseRequestPostBody {
    return {
      prNumber: isSet(object.prNumber) ? String(object.prNumber) : undefined,
      requestFrom: isSet(object.requestFrom) ? String(object.requestFrom) : undefined,
      purpose: isSet(object.purpose) ? String(object.purpose) : undefined,
      purposeRemark: isSet(object.purposeRemark) ? String(object.purposeRemark) : undefined,
      requestMode: isSet(object.requestMode) ? String(object.requestMode) : undefined,
      prDate: isSet(object.prDate) ? String(object.prDate) : undefined,
      prTarget: isSet(object.prTarget) ? String(object.prTarget) : undefined,
      createdBy: isSet(object.createdBy) ? String(object.createdBy) : undefined,
      products: Array.isArray(object?.products)
        ? object.products.map((e: any) => PurchasingPurchaseRequestPostBodyProduct.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PurchasingPurchaseRequestPostBody): unknown {
    const obj: any = {};
    message.prNumber !== undefined && (obj.prNumber = message.prNumber);
    message.requestFrom !== undefined && (obj.requestFrom = message.requestFrom);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.purposeRemark !== undefined && (obj.purposeRemark = message.purposeRemark);
    message.requestMode !== undefined && (obj.requestMode = message.requestMode);
    message.prDate !== undefined && (obj.prDate = message.prDate);
    message.prTarget !== undefined && (obj.prTarget = message.prTarget);
    message.createdBy !== undefined && (obj.createdBy = message.createdBy);
    if (message.products) {
      obj.products = message.products.map((e) => e ? PurchasingPurchaseRequestPostBodyProduct.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingPurchaseRequestPostBody>, I>>(
    base?: I,
  ): PurchasingPurchaseRequestPostBody {
    return PurchasingPurchaseRequestPostBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingPurchaseRequestPostBody>, I>>(
    object: I,
  ): PurchasingPurchaseRequestPostBody {
    const message = createBasePurchasingPurchaseRequestPostBody();
    message.prNumber = object.prNumber ?? undefined;
    message.requestFrom = object.requestFrom ?? undefined;
    message.purpose = object.purpose ?? undefined;
    message.purposeRemark = object.purposeRemark ?? undefined;
    message.requestMode = object.requestMode ?? undefined;
    message.prDate = object.prDate ?? undefined;
    message.prTarget = object.prTarget ?? undefined;
    message.createdBy = object.createdBy ?? undefined;
    message.products = object.products?.map((e) => PurchasingPurchaseRequestPostBodyProduct.fromPartial(e)) || [];
    return message;
  },
};

function createBasePurchasingPurchaseRequestPostBodyProduct(): PurchasingPurchaseRequestPostBodyProduct {
  return {
    productId: undefined,
    mfr: undefined,
    partNum: undefined,
    partName: undefined,
    partDesc: undefined,
    curr: undefined,
    unitCost: undefined,
    qtyPr: undefined,
    umPr: undefined,
  };
}

export const PurchasingPurchaseRequestPostBodyProduct = {
  encode(message: PurchasingPurchaseRequestPostBodyProduct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productId !== undefined) {
      writer.uint32(8).uint64(message.productId);
    }
    if (message.mfr !== undefined) {
      writer.uint32(18).string(message.mfr);
    }
    if (message.partNum !== undefined) {
      writer.uint32(24).uint64(message.partNum);
    }
    if (message.partName !== undefined) {
      writer.uint32(34).string(message.partName);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(42).string(message.partDesc);
    }
    if (message.curr !== undefined) {
      writer.uint32(50).string(message.curr);
    }
    if (message.unitCost !== undefined) {
      writer.uint32(56).uint64(message.unitCost);
    }
    if (message.qtyPr !== undefined) {
      writer.uint32(64).uint64(message.qtyPr);
    }
    if (message.umPr !== undefined) {
      writer.uint32(74).string(message.umPr);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingPurchaseRequestPostBodyProduct {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingPurchaseRequestPostBodyProduct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.partNum = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.curr = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.unitCost = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.qtyPr = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.umPr = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingPurchaseRequestPostBodyProduct {
    return {
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      partNum: isSet(object.partNum) ? String(object.partNum) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      curr: isSet(object.curr) ? String(object.curr) : undefined,
      unitCost: isSet(object.unitCost) ? String(object.unitCost) : undefined,
      qtyPr: isSet(object.qtyPr) ? String(object.qtyPr) : undefined,
      umPr: isSet(object.umPr) ? String(object.umPr) : undefined,
    };
  },

  toJSON(message: PurchasingPurchaseRequestPostBodyProduct): unknown {
    const obj: any = {};
    message.productId !== undefined && (obj.productId = message.productId);
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.partNum !== undefined && (obj.partNum = message.partNum);
    message.partName !== undefined && (obj.partName = message.partName);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.curr !== undefined && (obj.curr = message.curr);
    message.unitCost !== undefined && (obj.unitCost = message.unitCost);
    message.qtyPr !== undefined && (obj.qtyPr = message.qtyPr);
    message.umPr !== undefined && (obj.umPr = message.umPr);
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingPurchaseRequestPostBodyProduct>, I>>(
    base?: I,
  ): PurchasingPurchaseRequestPostBodyProduct {
    return PurchasingPurchaseRequestPostBodyProduct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingPurchaseRequestPostBodyProduct>, I>>(
    object: I,
  ): PurchasingPurchaseRequestPostBodyProduct {
    const message = createBasePurchasingPurchaseRequestPostBodyProduct();
    message.productId = object.productId ?? undefined;
    message.mfr = object.mfr ?? undefined;
    message.partNum = object.partNum ?? undefined;
    message.partName = object.partName ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.curr = object.curr ?? undefined;
    message.unitCost = object.unitCost ?? undefined;
    message.qtyPr = object.qtyPr ?? undefined;
    message.umPr = object.umPr ?? undefined;
    return message;
  },
};

function createBaseWarehouseItems(): WarehouseItems {
  return { items: [] };
}

export const WarehouseItems = {
  encode(message: WarehouseItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      WarehouseItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WarehouseItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWarehouseItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(WarehouseItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WarehouseItems {
    return { items: Array.isArray(object?.items) ? object.items.map((e: any) => WarehouseItem.fromJSON(e)) : [] };
  },

  toJSON(message: WarehouseItems): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? WarehouseItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WarehouseItems>, I>>(base?: I): WarehouseItems {
    return WarehouseItems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WarehouseItems>, I>>(object: I): WarehouseItems {
    const message = createBaseWarehouseItems();
    message.items = object.items?.map((e) => WarehouseItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmBoqs(): CrmBoqs {
  return { boqs: [] };
}

export const CrmBoqs = {
  encode(message: CrmBoqs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.boqs) {
      CrmBoq.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmBoqs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmBoqs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.boqs.push(CrmBoq.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmBoqs {
    return { boqs: Array.isArray(object?.boqs) ? object.boqs.map((e: any) => CrmBoq.fromJSON(e)) : [] };
  },

  toJSON(message: CrmBoqs): unknown {
    const obj: any = {};
    if (message.boqs) {
      obj.boqs = message.boqs.map((e) => e ? CrmBoq.toJSON(e) : undefined);
    } else {
      obj.boqs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmBoqs>, I>>(base?: I): CrmBoqs {
    return CrmBoqs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmBoqs>, I>>(object: I): CrmBoqs {
    const message = createBaseCrmBoqs();
    message.boqs = object.boqs?.map((e) => CrmBoq.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmBoq(): CrmBoq {
  return {
    id: undefined,
    boqId: undefined,
    boqProductName: undefined,
    type: undefined,
    poProductId: undefined,
    poProductName: undefined,
    poProductNumber: undefined,
    bomProductId: undefined,
    bomProductName: undefined,
    bomProductNumber: undefined,
    createdBy: undefined,
    updatedBy: undefined,
    poId: undefined,
    items: [],
  };
}

export const CrmBoq = {
  encode(message: CrmBoq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).int32(message.id);
    }
    if (message.boqId !== undefined) {
      writer.uint32(16).int32(message.boqId);
    }
    if (message.boqProductName !== undefined) {
      writer.uint32(26).string(message.boqProductName);
    }
    if (message.type !== undefined) {
      writer.uint32(34).string(message.type);
    }
    if (message.poProductId !== undefined) {
      writer.uint32(40).int32(message.poProductId);
    }
    if (message.poProductName !== undefined) {
      writer.uint32(50).string(message.poProductName);
    }
    if (message.poProductNumber !== undefined) {
      writer.uint32(58).string(message.poProductNumber);
    }
    if (message.bomProductId !== undefined) {
      writer.uint32(64).int32(message.bomProductId);
    }
    if (message.bomProductName !== undefined) {
      writer.uint32(74).string(message.bomProductName);
    }
    if (message.bomProductNumber !== undefined) {
      writer.uint32(82).string(message.bomProductNumber);
    }
    if (message.createdBy !== undefined) {
      writer.uint32(88).int32(message.createdBy);
    }
    if (message.updatedBy !== undefined) {
      writer.uint32(96).int32(message.updatedBy);
    }
    if (message.poId !== undefined) {
      writer.uint32(104).int32(message.poId);
    }
    for (const v of message.items) {
      CrmBoqItemItem.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmBoq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmBoq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.boqId = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.boqProductName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.poProductId = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.poProductName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.poProductNumber = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.bomProductId = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.bomProductName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.bomProductNumber = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.createdBy = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.updatedBy = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.poId = reader.int32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.items.push(CrmBoqItemItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmBoq {
    return {
      id: isSet(object.id) ? Number(object.id) : undefined,
      boqId: isSet(object.boqId) ? Number(object.boqId) : undefined,
      boqProductName: isSet(object.boqProductName) ? String(object.boqProductName) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      poProductId: isSet(object.poProductId) ? Number(object.poProductId) : undefined,
      poProductName: isSet(object.poProductName) ? String(object.poProductName) : undefined,
      poProductNumber: isSet(object.poProductNumber) ? String(object.poProductNumber) : undefined,
      bomProductId: isSet(object.bomProductId) ? Number(object.bomProductId) : undefined,
      bomProductName: isSet(object.bomProductName) ? String(object.bomProductName) : undefined,
      bomProductNumber: isSet(object.bomProductNumber) ? String(object.bomProductNumber) : undefined,
      createdBy: isSet(object.createdBy) ? Number(object.createdBy) : undefined,
      updatedBy: isSet(object.updatedBy) ? Number(object.updatedBy) : undefined,
      poId: isSet(object.poId) ? Number(object.poId) : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => CrmBoqItemItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: CrmBoq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.boqId !== undefined && (obj.boqId = Math.round(message.boqId));
    message.boqProductName !== undefined && (obj.boqProductName = message.boqProductName);
    message.type !== undefined && (obj.type = message.type);
    message.poProductId !== undefined && (obj.poProductId = Math.round(message.poProductId));
    message.poProductName !== undefined && (obj.poProductName = message.poProductName);
    message.poProductNumber !== undefined && (obj.poProductNumber = message.poProductNumber);
    message.bomProductId !== undefined && (obj.bomProductId = Math.round(message.bomProductId));
    message.bomProductName !== undefined && (obj.bomProductName = message.bomProductName);
    message.bomProductNumber !== undefined && (obj.bomProductNumber = message.bomProductNumber);
    message.createdBy !== undefined && (obj.createdBy = Math.round(message.createdBy));
    message.updatedBy !== undefined && (obj.updatedBy = Math.round(message.updatedBy));
    message.poId !== undefined && (obj.poId = Math.round(message.poId));
    if (message.items) {
      obj.items = message.items.map((e) => e ? CrmBoqItemItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmBoq>, I>>(base?: I): CrmBoq {
    return CrmBoq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmBoq>, I>>(object: I): CrmBoq {
    const message = createBaseCrmBoq();
    message.id = object.id ?? undefined;
    message.boqId = object.boqId ?? undefined;
    message.boqProductName = object.boqProductName ?? undefined;
    message.type = object.type ?? undefined;
    message.poProductId = object.poProductId ?? undefined;
    message.poProductName = object.poProductName ?? undefined;
    message.poProductNumber = object.poProductNumber ?? undefined;
    message.bomProductId = object.bomProductId ?? undefined;
    message.bomProductName = object.bomProductName ?? undefined;
    message.bomProductNumber = object.bomProductNumber ?? undefined;
    message.createdBy = object.createdBy ?? undefined;
    message.updatedBy = object.updatedBy ?? undefined;
    message.poId = object.poId ?? undefined;
    message.items = object.items?.map((e) => CrmBoqItemItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmBoqItems(): CrmBoqItems {
  return { items: [] };
}

export const CrmBoqItems = {
  encode(message: CrmBoqItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      CrmBoqItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmBoqItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmBoqItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(CrmBoqItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmBoqItems {
    return { items: Array.isArray(object?.items) ? object.items.map((e: any) => CrmBoqItem.fromJSON(e)) : [] };
  },

  toJSON(message: CrmBoqItems): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? CrmBoqItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmBoqItems>, I>>(base?: I): CrmBoqItems {
    return CrmBoqItems.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmBoqItems>, I>>(object: I): CrmBoqItems {
    const message = createBaseCrmBoqItems();
    message.items = object.items?.map((e) => CrmBoqItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmBoqItem(): CrmBoqItem {
  return {
    id: undefined,
    requirementId: undefined,
    productId: undefined,
    partNum: undefined,
    partNumGspe: undefined,
    partDesc: undefined,
    mfr: undefined,
    um: undefined,
    partName: undefined,
    qty: undefined,
    type: undefined,
    unitPrice: undefined,
    price: undefined,
    netPrice: undefined,
    sellPrice: undefined,
    version: undefined,
    margin: undefined,
    inventory: undefined,
    updatedBy: undefined,
    createdBy: undefined,
    items: [],
    equalProductId: undefined,
  };
}

export const CrmBoqItem = {
  encode(message: CrmBoqItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.requirementId !== undefined) {
      writer.uint32(18).string(message.requirementId);
    }
    if (message.productId !== undefined) {
      writer.uint32(26).string(message.productId);
    }
    if (message.partNum !== undefined) {
      writer.uint32(34).string(message.partNum);
    }
    if (message.partNumGspe !== undefined) {
      writer.uint32(42).string(message.partNumGspe);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(50).string(message.partDesc);
    }
    if (message.mfr !== undefined) {
      writer.uint32(58).string(message.mfr);
    }
    if (message.um !== undefined) {
      writer.uint32(66).string(message.um);
    }
    if (message.partName !== undefined) {
      writer.uint32(74).string(message.partName);
    }
    if (message.qty !== undefined) {
      writer.uint32(81).double(message.qty);
    }
    if (message.type !== undefined) {
      writer.uint32(90).string(message.type);
    }
    if (message.unitPrice !== undefined) {
      writer.uint32(97).double(message.unitPrice);
    }
    if (message.price !== undefined) {
      writer.uint32(105).double(message.price);
    }
    if (message.netPrice !== undefined) {
      writer.uint32(113).double(message.netPrice);
    }
    if (message.sellPrice !== undefined) {
      writer.uint32(121).double(message.sellPrice);
    }
    if (message.version !== undefined) {
      writer.uint32(128).int32(message.version);
    }
    if (message.margin !== undefined) {
      writer.uint32(137).double(message.margin);
    }
    if (message.inventory !== undefined) {
      writer.uint32(144).bool(message.inventory);
    }
    if (message.updatedBy !== undefined) {
      writer.uint32(154).string(message.updatedBy);
    }
    if (message.createdBy !== undefined) {
      writer.uint32(162).string(message.createdBy);
    }
    for (const v of message.items) {
      CrmBoqItemItem.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    if (message.equalProductId !== undefined) {
      writer.uint32(178).string(message.equalProductId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmBoqItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmBoqItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requirementId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partNum = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.partNumGspe = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.um = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.qty = reader.double();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.type = reader.string();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.unitPrice = reader.double();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.price = reader.double();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.netPrice = reader.double();
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.sellPrice = reader.double();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.version = reader.int32();
          continue;
        case 17:
          if (tag !== 137) {
            break;
          }

          message.margin = reader.double();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.inventory = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.items.push(CrmBoqItemItem.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.equalProductId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmBoqItem {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      requirementId: isSet(object.requirementId) ? String(object.requirementId) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      partNum: isSet(object.partNum) ? String(object.partNum) : undefined,
      partNumGspe: isSet(object.partNumGspe) ? String(object.partNumGspe) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      um: isSet(object.um) ? String(object.um) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      unitPrice: isSet(object.unitPrice) ? Number(object.unitPrice) : undefined,
      price: isSet(object.price) ? Number(object.price) : undefined,
      netPrice: isSet(object.netPrice) ? Number(object.netPrice) : undefined,
      sellPrice: isSet(object.sellPrice) ? Number(object.sellPrice) : undefined,
      version: isSet(object.version) ? Number(object.version) : undefined,
      margin: isSet(object.margin) ? Number(object.margin) : undefined,
      inventory: isSet(object.inventory) ? Boolean(object.inventory) : undefined,
      updatedBy: isSet(object.updatedBy) ? String(object.updatedBy) : undefined,
      createdBy: isSet(object.createdBy) ? String(object.createdBy) : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => CrmBoqItemItem.fromJSON(e)) : [],
      equalProductId: isSet(object.equalProductId) ? String(object.equalProductId) : undefined,
    };
  },

  toJSON(message: CrmBoqItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.requirementId !== undefined && (obj.requirementId = message.requirementId);
    message.productId !== undefined && (obj.productId = message.productId);
    message.partNum !== undefined && (obj.partNum = message.partNum);
    message.partNumGspe !== undefined && (obj.partNumGspe = message.partNumGspe);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.um !== undefined && (obj.um = message.um);
    message.partName !== undefined && (obj.partName = message.partName);
    message.qty !== undefined && (obj.qty = message.qty);
    message.type !== undefined && (obj.type = message.type);
    message.unitPrice !== undefined && (obj.unitPrice = message.unitPrice);
    message.price !== undefined && (obj.price = message.price);
    message.netPrice !== undefined && (obj.netPrice = message.netPrice);
    message.sellPrice !== undefined && (obj.sellPrice = message.sellPrice);
    message.version !== undefined && (obj.version = Math.round(message.version));
    message.margin !== undefined && (obj.margin = message.margin);
    message.inventory !== undefined && (obj.inventory = message.inventory);
    message.updatedBy !== undefined && (obj.updatedBy = message.updatedBy);
    message.createdBy !== undefined && (obj.createdBy = message.createdBy);
    if (message.items) {
      obj.items = message.items.map((e) => e ? CrmBoqItemItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.equalProductId !== undefined && (obj.equalProductId = message.equalProductId);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmBoqItem>, I>>(base?: I): CrmBoqItem {
    return CrmBoqItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmBoqItem>, I>>(object: I): CrmBoqItem {
    const message = createBaseCrmBoqItem();
    message.id = object.id ?? undefined;
    message.requirementId = object.requirementId ?? undefined;
    message.productId = object.productId ?? undefined;
    message.partNum = object.partNum ?? undefined;
    message.partNumGspe = object.partNumGspe ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.mfr = object.mfr ?? undefined;
    message.um = object.um ?? undefined;
    message.partName = object.partName ?? undefined;
    message.qty = object.qty ?? undefined;
    message.type = object.type ?? undefined;
    message.unitPrice = object.unitPrice ?? undefined;
    message.price = object.price ?? undefined;
    message.netPrice = object.netPrice ?? undefined;
    message.sellPrice = object.sellPrice ?? undefined;
    message.version = object.version ?? undefined;
    message.margin = object.margin ?? undefined;
    message.inventory = object.inventory ?? undefined;
    message.updatedBy = object.updatedBy ?? undefined;
    message.createdBy = object.createdBy ?? undefined;
    message.items = object.items?.map((e) => CrmBoqItemItem.fromPartial(e)) || [];
    message.equalProductId = object.equalProductId ?? undefined;
    return message;
  },
};

function createBaseCrmBoqItemItem(): CrmBoqItemItem {
  return {};
}

export const CrmBoqItemItem = {
  encode(_: CrmBoqItemItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmBoqItemItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmBoqItemItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CrmBoqItemItem {
    return {};
  },

  toJSON(_: CrmBoqItemItem): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmBoqItemItem>, I>>(base?: I): CrmBoqItemItem {
    return CrmBoqItemItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmBoqItemItem>, I>>(_: I): CrmBoqItemItem {
    const message = createBaseCrmBoqItemItem();
    return message;
  },
};

function createBaseItem(): Item {
  return {};
}

export const Item = {
  encode(_: Item, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Item {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Item {
    return {};
  },

  toJSON(_: Item): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Item>, I>>(base?: I): Item {
    return Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Item>, I>>(_: I): Item {
    const message = createBaseItem();
    return message;
  },
};

function createBaseCrmCustomer(): CrmCustomer {
  return { value: undefined, text: undefined };
}

export const CrmCustomer = {
  encode(message: CrmCustomer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== undefined) {
      writer.uint32(8).uint64(message.value);
    }
    if (message.text !== undefined) {
      writer.uint32(18).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmCustomer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmCustomer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmCustomer {
    return {
      value: isSet(object.value) ? String(object.value) : undefined,
      text: isSet(object.text) ? String(object.text) : undefined,
    };
  },

  toJSON(message: CrmCustomer): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmCustomer>, I>>(base?: I): CrmCustomer {
    return CrmCustomer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmCustomer>, I>>(object: I): CrmCustomer {
    const message = createBaseCrmCustomer();
    message.value = object.value ?? undefined;
    message.text = object.text ?? undefined;
    return message;
  },
};

function createBaseCrmPurchaseOrder(): CrmPurchaseOrder {
  return {
    id: undefined,
    purchaseOrderNumber: undefined,
    account: undefined,
    products: [],
    date: undefined,
    createdAt: undefined,
    quoteNumber: undefined,
    quoteId: undefined,
    quoteAmount: undefined,
  };
}

export const CrmPurchaseOrder = {
  encode(message: CrmPurchaseOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.purchaseOrderNumber !== undefined) {
      writer.uint32(18).string(message.purchaseOrderNumber);
    }
    if (message.account !== undefined) {
      CrmAccount.encode(message.account, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.products) {
      CrmProduct.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(42).string(message.date);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(50).string(message.createdAt);
    }
    if (message.quoteNumber !== undefined) {
      writer.uint32(58).string(message.quoteNumber);
    }
    if (message.quoteId !== undefined) {
      writer.uint32(64).uint64(message.quoteId);
    }
    if (message.quoteAmount !== undefined) {
      writer.uint32(73).double(message.quoteAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPurchaseOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPurchaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.purchaseOrderNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.account = CrmAccount.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.products.push(CrmProduct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.quoteNumber = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.quoteId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.quoteAmount = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPurchaseOrder {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      purchaseOrderNumber: isSet(object.purchaseOrderNumber) ? String(object.purchaseOrderNumber) : undefined,
      account: isSet(object.account) ? CrmAccount.fromJSON(object.account) : undefined,
      products: Array.isArray(object?.products) ? object.products.map((e: any) => CrmProduct.fromJSON(e)) : [],
      date: isSet(object.date) ? String(object.date) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      quoteNumber: isSet(object.quoteNumber) ? String(object.quoteNumber) : undefined,
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : undefined,
      quoteAmount: isSet(object.quoteAmount) ? Number(object.quoteAmount) : undefined,
    };
  },

  toJSON(message: CrmPurchaseOrder): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.purchaseOrderNumber !== undefined && (obj.purchaseOrderNumber = message.purchaseOrderNumber);
    message.account !== undefined && (obj.account = message.account ? CrmAccount.toJSON(message.account) : undefined);
    if (message.products) {
      obj.products = message.products.map((e) => e ? CrmProduct.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    message.date !== undefined && (obj.date = message.date);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.quoteNumber !== undefined && (obj.quoteNumber = message.quoteNumber);
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.quoteAmount !== undefined && (obj.quoteAmount = message.quoteAmount);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPurchaseOrder>, I>>(base?: I): CrmPurchaseOrder {
    return CrmPurchaseOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPurchaseOrder>, I>>(object: I): CrmPurchaseOrder {
    const message = createBaseCrmPurchaseOrder();
    message.id = object.id ?? undefined;
    message.purchaseOrderNumber = object.purchaseOrderNumber ?? undefined;
    message.account = (object.account !== undefined && object.account !== null)
      ? CrmAccount.fromPartial(object.account)
      : undefined;
    message.products = object.products?.map((e) => CrmProduct.fromPartial(e)) || [];
    message.date = object.date ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.quoteNumber = object.quoteNumber ?? undefined;
    message.quoteId = object.quoteId ?? undefined;
    message.quoteAmount = object.quoteAmount ?? undefined;
    return message;
  },
};

function createBaseCrmBPO(): CrmBPO {
  return { id: undefined, bpoNumber: undefined, account: undefined, products: [], date: undefined };
}

export const CrmBPO = {
  encode(message: CrmBPO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.bpoNumber !== undefined) {
      writer.uint32(18).string(message.bpoNumber);
    }
    if (message.account !== undefined) {
      CrmAccount.encode(message.account, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.products) {
      CrmProduct.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.date !== undefined) {
      writer.uint32(42).string(message.date);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmBPO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmBPO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bpoNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.account = CrmAccount.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.products.push(CrmProduct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmBPO {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      bpoNumber: isSet(object.bpoNumber) ? String(object.bpoNumber) : undefined,
      account: isSet(object.account) ? CrmAccount.fromJSON(object.account) : undefined,
      products: Array.isArray(object?.products) ? object.products.map((e: any) => CrmProduct.fromJSON(e)) : [],
      date: isSet(object.date) ? String(object.date) : undefined,
    };
  },

  toJSON(message: CrmBPO): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.bpoNumber !== undefined && (obj.bpoNumber = message.bpoNumber);
    message.account !== undefined && (obj.account = message.account ? CrmAccount.toJSON(message.account) : undefined);
    if (message.products) {
      obj.products = message.products.map((e) => e ? CrmProduct.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    message.date !== undefined && (obj.date = message.date);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmBPO>, I>>(base?: I): CrmBPO {
    return CrmBPO.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmBPO>, I>>(object: I): CrmBPO {
    const message = createBaseCrmBPO();
    message.id = object.id ?? undefined;
    message.bpoNumber = object.bpoNumber ?? undefined;
    message.account = (object.account !== undefined && object.account !== null)
      ? CrmAccount.fromPartial(object.account)
      : undefined;
    message.products = object.products?.map((e) => CrmProduct.fromPartial(e)) || [];
    message.date = object.date ?? undefined;
    return message;
  },
};

function createBaseCrmProduct(): CrmProduct {
  return {
    mfr: undefined,
    partDesc: undefined,
    partName: undefined,
    partNumber: undefined,
    partNumberCustomer: undefined,
    productCustomerId: undefined,
    productGspeId: undefined,
    qty: undefined,
    unitMeasure: undefined,
    productId: undefined,
    unitPrice: undefined,
    sequence: undefined,
    siteName: undefined,
    siteId: undefined,
    type: undefined,
  };
}

export const CrmProduct = {
  encode(message: CrmProduct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mfr !== undefined) {
      writer.uint32(10).string(message.mfr);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(18).string(message.partDesc);
    }
    if (message.partName !== undefined) {
      writer.uint32(26).string(message.partName);
    }
    if (message.partNumber !== undefined) {
      writer.uint32(34).string(message.partNumber);
    }
    if (message.partNumberCustomer !== undefined) {
      writer.uint32(42).string(message.partNumberCustomer);
    }
    if (message.productCustomerId !== undefined) {
      writer.uint32(48).uint64(message.productCustomerId);
    }
    if (message.productGspeId !== undefined) {
      writer.uint32(56).uint64(message.productGspeId);
    }
    if (message.qty !== undefined) {
      writer.uint32(65).double(message.qty);
    }
    if (message.unitMeasure !== undefined) {
      writer.uint32(74).string(message.unitMeasure);
    }
    if (message.productId !== undefined) {
      writer.uint32(80).uint64(message.productId);
    }
    if (message.unitPrice !== undefined) {
      writer.uint32(89).double(message.unitPrice);
    }
    if (message.sequence !== undefined) {
      writer.uint32(96).int32(message.sequence);
    }
    if (message.siteName !== undefined) {
      writer.uint32(106).string(message.siteName);
    }
    if (message.siteId !== undefined) {
      writer.uint32(114).string(message.siteId);
    }
    if (message.type !== undefined) {
      writer.uint32(122).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmProduct {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmProduct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.partNumberCustomer = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.productCustomerId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.productGspeId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.qty = reader.double();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.unitMeasure = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.unitPrice = reader.double();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.sequence = reader.int32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.siteName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.siteId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmProduct {
    return {
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      partNumber: isSet(object.partNumber) ? String(object.partNumber) : undefined,
      partNumberCustomer: isSet(object.partNumberCustomer) ? String(object.partNumberCustomer) : undefined,
      productCustomerId: isSet(object.productCustomerId) ? String(object.productCustomerId) : undefined,
      productGspeId: isSet(object.productGspeId) ? String(object.productGspeId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
      unitMeasure: isSet(object.unitMeasure) ? String(object.unitMeasure) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      unitPrice: isSet(object.unitPrice) ? Number(object.unitPrice) : undefined,
      sequence: isSet(object.sequence) ? Number(object.sequence) : undefined,
      siteName: isSet(object.siteName) ? String(object.siteName) : undefined,
      siteId: isSet(object.siteId) ? String(object.siteId) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
    };
  },

  toJSON(message: CrmProduct): unknown {
    const obj: any = {};
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.partName !== undefined && (obj.partName = message.partName);
    message.partNumber !== undefined && (obj.partNumber = message.partNumber);
    message.partNumberCustomer !== undefined && (obj.partNumberCustomer = message.partNumberCustomer);
    message.productCustomerId !== undefined && (obj.productCustomerId = message.productCustomerId);
    message.productGspeId !== undefined && (obj.productGspeId = message.productGspeId);
    message.qty !== undefined && (obj.qty = message.qty);
    message.unitMeasure !== undefined && (obj.unitMeasure = message.unitMeasure);
    message.productId !== undefined && (obj.productId = message.productId);
    message.unitPrice !== undefined && (obj.unitPrice = message.unitPrice);
    message.sequence !== undefined && (obj.sequence = Math.round(message.sequence));
    message.siteName !== undefined && (obj.siteName = message.siteName);
    message.siteId !== undefined && (obj.siteId = message.siteId);
    message.type !== undefined && (obj.type = message.type);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmProduct>, I>>(base?: I): CrmProduct {
    return CrmProduct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmProduct>, I>>(object: I): CrmProduct {
    const message = createBaseCrmProduct();
    message.mfr = object.mfr ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.partName = object.partName ?? undefined;
    message.partNumber = object.partNumber ?? undefined;
    message.partNumberCustomer = object.partNumberCustomer ?? undefined;
    message.productCustomerId = object.productCustomerId ?? undefined;
    message.productGspeId = object.productGspeId ?? undefined;
    message.qty = object.qty ?? undefined;
    message.unitMeasure = object.unitMeasure ?? undefined;
    message.productId = object.productId ?? undefined;
    message.unitPrice = object.unitPrice ?? undefined;
    message.sequence = object.sequence ?? undefined;
    message.siteName = object.siteName ?? undefined;
    message.siteId = object.siteId ?? undefined;
    message.type = object.type ?? undefined;
    return message;
  },
};

function createBaseCrmPurchaseOrders(): CrmPurchaseOrders {
  return { purchaseOrders: [] };
}

export const CrmPurchaseOrders = {
  encode(message: CrmPurchaseOrders, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.purchaseOrders) {
      CrmPurchaseOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPurchaseOrders {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPurchaseOrders();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.purchaseOrders.push(CrmPurchaseOrder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPurchaseOrders {
    return {
      purchaseOrders: Array.isArray(object?.purchaseOrders)
        ? object.purchaseOrders.map((e: any) => CrmPurchaseOrder.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CrmPurchaseOrders): unknown {
    const obj: any = {};
    if (message.purchaseOrders) {
      obj.purchaseOrders = message.purchaseOrders.map((e) => e ? CrmPurchaseOrder.toJSON(e) : undefined);
    } else {
      obj.purchaseOrders = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPurchaseOrders>, I>>(base?: I): CrmPurchaseOrders {
    return CrmPurchaseOrders.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPurchaseOrders>, I>>(object: I): CrmPurchaseOrders {
    const message = createBaseCrmPurchaseOrders();
    message.purchaseOrders = object.purchaseOrders?.map((e) => CrmPurchaseOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmBPOs(): CrmBPOs {
  return { purchaseOrders: [] };
}

export const CrmBPOs = {
  encode(message: CrmBPOs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.purchaseOrders) {
      CrmBPO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmBPOs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmBPOs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.purchaseOrders.push(CrmBPO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmBPOs {
    return {
      purchaseOrders: Array.isArray(object?.purchaseOrders)
        ? object.purchaseOrders.map((e: any) => CrmBPO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CrmBPOs): unknown {
    const obj: any = {};
    if (message.purchaseOrders) {
      obj.purchaseOrders = message.purchaseOrders.map((e) => e ? CrmBPO.toJSON(e) : undefined);
    } else {
      obj.purchaseOrders = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmBPOs>, I>>(base?: I): CrmBPOs {
    return CrmBPOs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmBPOs>, I>>(object: I): CrmBPOs {
    const message = createBaseCrmBPOs();
    message.purchaseOrders = object.purchaseOrders?.map((e) => CrmBPO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmPurchaseOrderIdRequest(): CrmPurchaseOrderIdRequest {
  return { id: "0" };
}

export const CrmPurchaseOrderIdRequest = {
  encode(message: CrmPurchaseOrderIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPurchaseOrderIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPurchaseOrderIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPurchaseOrderIdRequest {
    return { id: isSet(object.id) ? String(object.id) : "0" };
  },

  toJSON(message: CrmPurchaseOrderIdRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPurchaseOrderIdRequest>, I>>(base?: I): CrmPurchaseOrderIdRequest {
    return CrmPurchaseOrderIdRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPurchaseOrderIdRequest>, I>>(object: I): CrmPurchaseOrderIdRequest {
    const message = createBaseCrmPurchaseOrderIdRequest();
    message.id = object.id ?? "0";
    return message;
  },
};

function createBaseCrmAccount(): CrmAccount {
  return { id: undefined, name: undefined };
}

export const CrmAccount = {
  encode(message: CrmAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmAccount {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: CrmAccount): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmAccount>, I>>(base?: I): CrmAccount {
    return CrmAccount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmAccount>, I>>(object: I): CrmAccount {
    const message = createBaseCrmAccount();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseCrmCustomers(): CrmCustomers {
  return { customers: [] };
}

export const CrmCustomers = {
  encode(message: CrmCustomers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.customers) {
      CrmCustomer.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmCustomers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmCustomers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customers.push(CrmCustomer.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmCustomers {
    return {
      customers: Array.isArray(object?.customers) ? object.customers.map((e: any) => CrmCustomer.fromJSON(e)) : [],
    };
  },

  toJSON(message: CrmCustomers): unknown {
    const obj: any = {};
    if (message.customers) {
      obj.customers = message.customers.map((e) => e ? CrmCustomer.toJSON(e) : undefined);
    } else {
      obj.customers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmCustomers>, I>>(base?: I): CrmCustomers {
    return CrmCustomers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmCustomers>, I>>(object: I): CrmCustomers {
    const message = createBaseCrmCustomers();
    message.customers = object.customers?.map((e) => CrmCustomer.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHrEmployee(): HrEmployee {
  return {
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    hierarchyId: undefined,
    id: undefined,
    contact: undefined,
  };
}

export const HrEmployee = {
  encode(message: HrEmployee, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.firstName !== undefined) {
      writer.uint32(10).string(message.firstName);
    }
    if (message.middleName !== undefined) {
      writer.uint32(18).string(message.middleName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(26).string(message.lastName);
    }
    if (message.hierarchyId !== undefined) {
      writer.uint32(32).uint64(message.hierarchyId);
    }
    if (message.id !== undefined) {
      writer.uint32(40).uint64(message.id);
    }
    if (message.contact !== undefined) {
      HrContact.encode(message.contact, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HrEmployee {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHrEmployee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.middleName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hierarchyId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.contact = HrContact.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HrEmployee {
    return {
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      middleName: isSet(object.middleName) ? String(object.middleName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      hierarchyId: isSet(object.hierarchyId) ? String(object.hierarchyId) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      contact: isSet(object.contact) ? HrContact.fromJSON(object.contact) : undefined,
    };
  },

  toJSON(message: HrEmployee): unknown {
    const obj: any = {};
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.middleName !== undefined && (obj.middleName = message.middleName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.hierarchyId !== undefined && (obj.hierarchyId = message.hierarchyId);
    message.id !== undefined && (obj.id = message.id);
    message.contact !== undefined && (obj.contact = message.contact ? HrContact.toJSON(message.contact) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<HrEmployee>, I>>(base?: I): HrEmployee {
    return HrEmployee.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HrEmployee>, I>>(object: I): HrEmployee {
    const message = createBaseHrEmployee();
    message.firstName = object.firstName ?? undefined;
    message.middleName = object.middleName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.hierarchyId = object.hierarchyId ?? undefined;
    message.id = object.id ?? undefined;
    message.contact = (object.contact !== undefined && object.contact !== null)
      ? HrContact.fromPartial(object.contact)
      : undefined;
    return message;
  },
};

function createBaseHrContact(): HrContact {
  return {
    id: undefined,
    empId: undefined,
    address1: undefined,
    address2: undefined,
    city: undefined,
    province: undefined,
    zipcode: undefined,
    hp: undefined,
    wa: undefined,
    homeTlp: undefined,
    email: undefined,
    workEmail: undefined,
    emcContactName: undefined,
    emcContactPhone: undefined,
    relationship: undefined,
    status: undefined,
    createdAt: undefined,
    updatedAt: undefined,
  };
}

export const HrContact = {
  encode(message: HrContact, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.empId !== undefined) {
      writer.uint32(16).uint64(message.empId);
    }
    if (message.address1 !== undefined) {
      writer.uint32(26).string(message.address1);
    }
    if (message.address2 !== undefined) {
      writer.uint32(34).string(message.address2);
    }
    if (message.city !== undefined) {
      writer.uint32(42).string(message.city);
    }
    if (message.province !== undefined) {
      writer.uint32(50).string(message.province);
    }
    if (message.zipcode !== undefined) {
      writer.uint32(58).string(message.zipcode);
    }
    if (message.hp !== undefined) {
      writer.uint32(66).string(message.hp);
    }
    if (message.wa !== undefined) {
      writer.uint32(74).string(message.wa);
    }
    if (message.homeTlp !== undefined) {
      writer.uint32(82).string(message.homeTlp);
    }
    if (message.email !== undefined) {
      writer.uint32(90).string(message.email);
    }
    if (message.workEmail !== undefined) {
      writer.uint32(98).string(message.workEmail);
    }
    if (message.emcContactName !== undefined) {
      writer.uint32(106).string(message.emcContactName);
    }
    if (message.emcContactPhone !== undefined) {
      writer.uint32(114).string(message.emcContactPhone);
    }
    if (message.relationship !== undefined) {
      writer.uint32(122).string(message.relationship);
    }
    if (message.status !== undefined) {
      writer.uint32(128).uint64(message.status);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(138).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(146).string(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HrContact {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHrContact();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.empId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address1 = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.address2 = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.city = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.province = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.zipcode = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.hp = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.wa = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.homeTlp = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.email = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.workEmail = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.emcContactName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.emcContactPhone = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.relationship = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.status = longToString(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HrContact {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      empId: isSet(object.empId) ? String(object.empId) : undefined,
      address1: isSet(object.address1) ? String(object.address1) : undefined,
      address2: isSet(object.address2) ? String(object.address2) : undefined,
      city: isSet(object.city) ? String(object.city) : undefined,
      province: isSet(object.province) ? String(object.province) : undefined,
      zipcode: isSet(object.zipcode) ? String(object.zipcode) : undefined,
      hp: isSet(object.hp) ? String(object.hp) : undefined,
      wa: isSet(object.wa) ? String(object.wa) : undefined,
      homeTlp: isSet(object.homeTlp) ? String(object.homeTlp) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      workEmail: isSet(object.workEmail) ? String(object.workEmail) : undefined,
      emcContactName: isSet(object.emcContactName) ? String(object.emcContactName) : undefined,
      emcContactPhone: isSet(object.emcContactPhone) ? String(object.emcContactPhone) : undefined,
      relationship: isSet(object.relationship) ? String(object.relationship) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
    };
  },

  toJSON(message: HrContact): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.empId !== undefined && (obj.empId = message.empId);
    message.address1 !== undefined && (obj.address1 = message.address1);
    message.address2 !== undefined && (obj.address2 = message.address2);
    message.city !== undefined && (obj.city = message.city);
    message.province !== undefined && (obj.province = message.province);
    message.zipcode !== undefined && (obj.zipcode = message.zipcode);
    message.hp !== undefined && (obj.hp = message.hp);
    message.wa !== undefined && (obj.wa = message.wa);
    message.homeTlp !== undefined && (obj.homeTlp = message.homeTlp);
    message.email !== undefined && (obj.email = message.email);
    message.workEmail !== undefined && (obj.workEmail = message.workEmail);
    message.emcContactName !== undefined && (obj.emcContactName = message.emcContactName);
    message.emcContactPhone !== undefined && (obj.emcContactPhone = message.emcContactPhone);
    message.relationship !== undefined && (obj.relationship = message.relationship);
    message.status !== undefined && (obj.status = message.status);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    return obj;
  },

  create<I extends Exact<DeepPartial<HrContact>, I>>(base?: I): HrContact {
    return HrContact.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HrContact>, I>>(object: I): HrContact {
    const message = createBaseHrContact();
    message.id = object.id ?? undefined;
    message.empId = object.empId ?? undefined;
    message.address1 = object.address1 ?? undefined;
    message.address2 = object.address2 ?? undefined;
    message.city = object.city ?? undefined;
    message.province = object.province ?? undefined;
    message.zipcode = object.zipcode ?? undefined;
    message.hp = object.hp ?? undefined;
    message.wa = object.wa ?? undefined;
    message.homeTlp = object.homeTlp ?? undefined;
    message.email = object.email ?? undefined;
    message.workEmail = object.workEmail ?? undefined;
    message.emcContactName = object.emcContactName ?? undefined;
    message.emcContactPhone = object.emcContactPhone ?? undefined;
    message.relationship = object.relationship ?? undefined;
    message.status = object.status ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseHrEmployees(): HrEmployees {
  return { employees: [] };
}

export const HrEmployees = {
  encode(message: HrEmployees, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.employees) {
      HrEmployee.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HrEmployees {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHrEmployees();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.employees.push(HrEmployee.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HrEmployees {
    return {
      employees: Array.isArray(object?.employees) ? object.employees.map((e: any) => HrEmployee.fromJSON(e)) : [],
    };
  },

  toJSON(message: HrEmployees): unknown {
    const obj: any = {};
    if (message.employees) {
      obj.employees = message.employees.map((e) => e ? HrEmployee.toJSON(e) : undefined);
    } else {
      obj.employees = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HrEmployees>, I>>(base?: I): HrEmployees {
    return HrEmployees.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HrEmployees>, I>>(object: I): HrEmployees {
    const message = createBaseHrEmployees();
    message.employees = object.employees?.map((e) => HrEmployee.fromPartial(e)) || [];
    return message;
  },
};

function createBasePurchasingPurchaseRequest(): PurchasingPurchaseRequest {
  return {
    id: undefined,
    prNumber: undefined,
    prNumberSeq: undefined,
    qsId: undefined,
    requestFrom: undefined,
    requestMode: undefined,
    prDept: undefined,
    prReferenceType: undefined,
    prRequesterId: undefined,
    purpose: undefined,
    purposeRemark: undefined,
    status: undefined,
    prDate: undefined,
    prTarget: undefined,
    rejected: undefined,
    rejectReason: undefined,
    approved: undefined,
    approvedBy: undefined,
    approvedDate: undefined,
    createdBy: undefined,
    modifiedBy: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    null: undefined,
  };
}

export const PurchasingPurchaseRequest = {
  encode(message: PurchasingPurchaseRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.prNumber !== undefined) {
      writer.uint32(18).string(message.prNumber);
    }
    if (message.prNumberSeq !== undefined) {
      writer.uint32(26).string(message.prNumberSeq);
    }
    if (message.qsId !== undefined) {
      writer.uint32(34).string(message.qsId);
    }
    if (message.requestFrom !== undefined) {
      writer.uint32(42).string(message.requestFrom);
    }
    if (message.requestMode !== undefined) {
      writer.uint32(50).string(message.requestMode);
    }
    if (message.prDept !== undefined) {
      writer.uint32(58).string(message.prDept);
    }
    if (message.prReferenceType !== undefined) {
      writer.uint32(66).string(message.prReferenceType);
    }
    if (message.prRequesterId !== undefined) {
      writer.uint32(74).string(message.prRequesterId);
    }
    if (message.purpose !== undefined) {
      writer.uint32(82).string(message.purpose);
    }
    if (message.purposeRemark !== undefined) {
      writer.uint32(90).string(message.purposeRemark);
    }
    if (message.status !== undefined) {
      writer.uint32(96).uint64(message.status);
    }
    if (message.prDate !== undefined) {
      writer.uint32(106).string(message.prDate);
    }
    if (message.prTarget !== undefined) {
      writer.uint32(114).string(message.prTarget);
    }
    if (message.rejected !== undefined) {
      writer.uint32(120).uint64(message.rejected);
    }
    if (message.rejectReason !== undefined) {
      writer.uint32(130).string(message.rejectReason);
    }
    if (message.approved !== undefined) {
      writer.uint32(136).uint64(message.approved);
    }
    if (message.approvedBy !== undefined) {
      writer.uint32(146).string(message.approvedBy);
    }
    if (message.approvedDate !== undefined) {
      writer.uint32(154).string(message.approvedDate);
    }
    if (message.createdBy !== undefined) {
      writer.uint32(162).string(message.createdBy);
    }
    if (message.modifiedBy !== undefined) {
      writer.uint32(170).string(message.modifiedBy);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(178).string(message.createdAt);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(186).string(message.updatedAt);
    }
    if (message.null !== undefined) {
      writer.uint32(194).string(message.null);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingPurchaseRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingPurchaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.prNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.prNumberSeq = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.qsId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.requestFrom = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.requestMode = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.prDept = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.prReferenceType = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.prRequesterId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.purpose = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.purposeRemark = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.status = longToString(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.prDate = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.prTarget = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.rejected = longToString(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.rejectReason = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.approved = longToString(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.approvedBy = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.approvedDate = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.modifiedBy = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.null = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingPurchaseRequest {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      prNumber: isSet(object.prNumber) ? String(object.prNumber) : undefined,
      prNumberSeq: isSet(object.prNumberSeq) ? String(object.prNumberSeq) : undefined,
      qsId: isSet(object.qsId) ? String(object.qsId) : undefined,
      requestFrom: isSet(object.requestFrom) ? String(object.requestFrom) : undefined,
      requestMode: isSet(object.requestMode) ? String(object.requestMode) : undefined,
      prDept: isSet(object.prDept) ? String(object.prDept) : undefined,
      prReferenceType: isSet(object.prReferenceType) ? String(object.prReferenceType) : undefined,
      prRequesterId: isSet(object.prRequesterId) ? String(object.prRequesterId) : undefined,
      purpose: isSet(object.purpose) ? String(object.purpose) : undefined,
      purposeRemark: isSet(object.purposeRemark) ? String(object.purposeRemark) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      prDate: isSet(object.prDate) ? String(object.prDate) : undefined,
      prTarget: isSet(object.prTarget) ? String(object.prTarget) : undefined,
      rejected: isSet(object.rejected) ? String(object.rejected) : undefined,
      rejectReason: isSet(object.rejectReason) ? String(object.rejectReason) : undefined,
      approved: isSet(object.approved) ? String(object.approved) : undefined,
      approvedBy: isSet(object.approvedBy) ? String(object.approvedBy) : undefined,
      approvedDate: isSet(object.approvedDate) ? String(object.approvedDate) : undefined,
      createdBy: isSet(object.createdBy) ? String(object.createdBy) : undefined,
      modifiedBy: isSet(object.modifiedBy) ? String(object.modifiedBy) : undefined,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : undefined,
      null: isSet(object.null) ? String(object.null) : undefined,
    };
  },

  toJSON(message: PurchasingPurchaseRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.prNumber !== undefined && (obj.prNumber = message.prNumber);
    message.prNumberSeq !== undefined && (obj.prNumberSeq = message.prNumberSeq);
    message.qsId !== undefined && (obj.qsId = message.qsId);
    message.requestFrom !== undefined && (obj.requestFrom = message.requestFrom);
    message.requestMode !== undefined && (obj.requestMode = message.requestMode);
    message.prDept !== undefined && (obj.prDept = message.prDept);
    message.prReferenceType !== undefined && (obj.prReferenceType = message.prReferenceType);
    message.prRequesterId !== undefined && (obj.prRequesterId = message.prRequesterId);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.purposeRemark !== undefined && (obj.purposeRemark = message.purposeRemark);
    message.status !== undefined && (obj.status = message.status);
    message.prDate !== undefined && (obj.prDate = message.prDate);
    message.prTarget !== undefined && (obj.prTarget = message.prTarget);
    message.rejected !== undefined && (obj.rejected = message.rejected);
    message.rejectReason !== undefined && (obj.rejectReason = message.rejectReason);
    message.approved !== undefined && (obj.approved = message.approved);
    message.approvedBy !== undefined && (obj.approvedBy = message.approvedBy);
    message.approvedDate !== undefined && (obj.approvedDate = message.approvedDate);
    message.createdBy !== undefined && (obj.createdBy = message.createdBy);
    message.modifiedBy !== undefined && (obj.modifiedBy = message.modifiedBy);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt);
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
    message.null !== undefined && (obj.null = message.null);
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingPurchaseRequest>, I>>(base?: I): PurchasingPurchaseRequest {
    return PurchasingPurchaseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingPurchaseRequest>, I>>(object: I): PurchasingPurchaseRequest {
    const message = createBasePurchasingPurchaseRequest();
    message.id = object.id ?? undefined;
    message.prNumber = object.prNumber ?? undefined;
    message.prNumberSeq = object.prNumberSeq ?? undefined;
    message.qsId = object.qsId ?? undefined;
    message.requestFrom = object.requestFrom ?? undefined;
    message.requestMode = object.requestMode ?? undefined;
    message.prDept = object.prDept ?? undefined;
    message.prReferenceType = object.prReferenceType ?? undefined;
    message.prRequesterId = object.prRequesterId ?? undefined;
    message.purpose = object.purpose ?? undefined;
    message.purposeRemark = object.purposeRemark ?? undefined;
    message.status = object.status ?? undefined;
    message.prDate = object.prDate ?? undefined;
    message.prTarget = object.prTarget ?? undefined;
    message.rejected = object.rejected ?? undefined;
    message.rejectReason = object.rejectReason ?? undefined;
    message.approved = object.approved ?? undefined;
    message.approvedBy = object.approvedBy ?? undefined;
    message.approvedDate = object.approvedDate ?? undefined;
    message.createdBy = object.createdBy ?? undefined;
    message.modifiedBy = object.modifiedBy ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.null = object.null ?? undefined;
    return message;
  },
};

function createBasePurchasingPurchaseRequests(): PurchasingPurchaseRequests {
  return { purchaseRequests: [] };
}

export const PurchasingPurchaseRequests = {
  encode(message: PurchasingPurchaseRequests, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.purchaseRequests) {
      PurchasingPurchaseRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingPurchaseRequests {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingPurchaseRequests();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.purchaseRequests.push(PurchasingPurchaseRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingPurchaseRequests {
    return {
      purchaseRequests: Array.isArray(object?.purchaseRequests)
        ? object.purchaseRequests.map((e: any) => PurchasingPurchaseRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PurchasingPurchaseRequests): unknown {
    const obj: any = {};
    if (message.purchaseRequests) {
      obj.purchaseRequests = message.purchaseRequests.map((e) => e ? PurchasingPurchaseRequest.toJSON(e) : undefined);
    } else {
      obj.purchaseRequests = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingPurchaseRequests>, I>>(base?: I): PurchasingPurchaseRequests {
    return PurchasingPurchaseRequests.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingPurchaseRequests>, I>>(object: I): PurchasingPurchaseRequests {
    const message = createBasePurchasingPurchaseRequests();
    message.purchaseRequests = object.purchaseRequests?.map((e) => PurchasingPurchaseRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBasePurchasingPurchaseRequestItemOutstanding(): PurchasingPurchaseRequestItemOutstanding {
  return {
    jobId: undefined,
    mfr: undefined,
    partDesc: undefined,
    partName: undefined,
    partNum: undefined,
    pr: undefined,
    prId: undefined,
    productId: undefined,
    qtyPr: undefined,
    umPr: undefined,
  };
}

export const PurchasingPurchaseRequestItemOutstanding = {
  encode(message: PurchasingPurchaseRequestItemOutstanding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== undefined) {
      writer.uint32(8).uint64(message.jobId);
    }
    if (message.mfr !== undefined) {
      writer.uint32(18).string(message.mfr);
    }
    if (message.partDesc !== undefined) {
      writer.uint32(26).string(message.partDesc);
    }
    if (message.partName !== undefined) {
      writer.uint32(34).string(message.partName);
    }
    if (message.partNum !== undefined) {
      writer.uint32(42).string(message.partNum);
    }
    if (message.pr !== undefined) {
      PurchasingPurchaseRequest.encode(message.pr, writer.uint32(50).fork()).ldelim();
    }
    if (message.prId !== undefined) {
      writer.uint32(56).uint64(message.prId);
    }
    if (message.productId !== undefined) {
      writer.uint32(64).uint64(message.productId);
    }
    if (message.qtyPr !== undefined) {
      writer.uint32(72).int32(message.qtyPr);
    }
    if (message.umPr !== undefined) {
      writer.uint32(82).string(message.umPr);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingPurchaseRequestItemOutstanding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingPurchaseRequestItemOutstanding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mfr = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partDesc = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.partNum = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pr = PurchasingPurchaseRequest.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.prId = longToString(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.qtyPr = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.umPr = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingPurchaseRequestItemOutstanding {
    return {
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      mfr: isSet(object.mfr) ? String(object.mfr) : undefined,
      partDesc: isSet(object.partDesc) ? String(object.partDesc) : undefined,
      partName: isSet(object.partName) ? String(object.partName) : undefined,
      partNum: isSet(object.partNum) ? String(object.partNum) : undefined,
      pr: isSet(object.pr) ? PurchasingPurchaseRequest.fromJSON(object.pr) : undefined,
      prId: isSet(object.prId) ? String(object.prId) : undefined,
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      qtyPr: isSet(object.qtyPr) ? Number(object.qtyPr) : undefined,
      umPr: isSet(object.umPr) ? String(object.umPr) : undefined,
    };
  },

  toJSON(message: PurchasingPurchaseRequestItemOutstanding): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.mfr !== undefined && (obj.mfr = message.mfr);
    message.partDesc !== undefined && (obj.partDesc = message.partDesc);
    message.partName !== undefined && (obj.partName = message.partName);
    message.partNum !== undefined && (obj.partNum = message.partNum);
    message.pr !== undefined && (obj.pr = message.pr ? PurchasingPurchaseRequest.toJSON(message.pr) : undefined);
    message.prId !== undefined && (obj.prId = message.prId);
    message.productId !== undefined && (obj.productId = message.productId);
    message.qtyPr !== undefined && (obj.qtyPr = Math.round(message.qtyPr));
    message.umPr !== undefined && (obj.umPr = message.umPr);
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingPurchaseRequestItemOutstanding>, I>>(
    base?: I,
  ): PurchasingPurchaseRequestItemOutstanding {
    return PurchasingPurchaseRequestItemOutstanding.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingPurchaseRequestItemOutstanding>, I>>(
    object: I,
  ): PurchasingPurchaseRequestItemOutstanding {
    const message = createBasePurchasingPurchaseRequestItemOutstanding();
    message.jobId = object.jobId ?? undefined;
    message.mfr = object.mfr ?? undefined;
    message.partDesc = object.partDesc ?? undefined;
    message.partName = object.partName ?? undefined;
    message.partNum = object.partNum ?? undefined;
    message.pr = (object.pr !== undefined && object.pr !== null)
      ? PurchasingPurchaseRequest.fromPartial(object.pr)
      : undefined;
    message.prId = object.prId ?? undefined;
    message.productId = object.productId ?? undefined;
    message.qtyPr = object.qtyPr ?? undefined;
    message.umPr = object.umPr ?? undefined;
    return message;
  },
};

function createBasePurchasingPurchaseRequestItemsOutstanding(): PurchasingPurchaseRequestItemsOutstanding {
  return { purchaseRequestItems: [] };
}

export const PurchasingPurchaseRequestItemsOutstanding = {
  encode(message: PurchasingPurchaseRequestItemsOutstanding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.purchaseRequestItems) {
      PurchasingPurchaseRequestItemOutstanding.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingPurchaseRequestItemsOutstanding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingPurchaseRequestItemsOutstanding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.purchaseRequestItems.push(PurchasingPurchaseRequestItemOutstanding.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingPurchaseRequestItemsOutstanding {
    return {
      purchaseRequestItems: Array.isArray(object?.purchaseRequestItems)
        ? object.purchaseRequestItems.map((e: any) => PurchasingPurchaseRequestItemOutstanding.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PurchasingPurchaseRequestItemsOutstanding): unknown {
    const obj: any = {};
    if (message.purchaseRequestItems) {
      obj.purchaseRequestItems = message.purchaseRequestItems.map((e) =>
        e ? PurchasingPurchaseRequestItemOutstanding.toJSON(e) : undefined
      );
    } else {
      obj.purchaseRequestItems = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingPurchaseRequestItemsOutstanding>, I>>(
    base?: I,
  ): PurchasingPurchaseRequestItemsOutstanding {
    return PurchasingPurchaseRequestItemsOutstanding.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchasingPurchaseRequestItemsOutstanding>, I>>(
    object: I,
  ): PurchasingPurchaseRequestItemsOutstanding {
    const message = createBasePurchasingPurchaseRequestItemsOutstanding();
    message.purchaseRequestItems =
      object.purchaseRequestItems?.map((e) => PurchasingPurchaseRequestItemOutstanding.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoUser(): SsoUser {
  return {
    id: undefined,
    name: undefined,
    username: undefined,
    password: undefined,
    confirmPassword: undefined,
    department: undefined,
    roles: [],
  };
}

export const SsoUser = {
  encode(message: SsoUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.username !== undefined) {
      writer.uint32(26).string(message.username);
    }
    if (message.password !== undefined) {
      writer.uint32(34).string(message.password);
    }
    if (message.confirmPassword !== undefined) {
      writer.uint32(42).string(message.confirmPassword);
    }
    if (message.department !== undefined) {
      SsoDepartment.encode(message.department, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.roles) {
      SsoRole.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.username = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.confirmPassword = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.department = SsoDepartment.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.roles.push(SsoRole.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoUser {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      username: isSet(object.username) ? String(object.username) : undefined,
      password: isSet(object.password) ? String(object.password) : undefined,
      confirmPassword: isSet(object.confirmPassword) ? String(object.confirmPassword) : undefined,
      department: isSet(object.department) ? SsoDepartment.fromJSON(object.department) : undefined,
      roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => SsoRole.fromJSON(e)) : [],
    };
  },

  toJSON(message: SsoUser): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    message.confirmPassword !== undefined && (obj.confirmPassword = message.confirmPassword);
    message.department !== undefined &&
      (obj.department = message.department ? SsoDepartment.toJSON(message.department) : undefined);
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? SsoRole.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoUser>, I>>(base?: I): SsoUser {
    return SsoUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoUser>, I>>(object: I): SsoUser {
    const message = createBaseSsoUser();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.username = object.username ?? undefined;
    message.password = object.password ?? undefined;
    message.confirmPassword = object.confirmPassword ?? undefined;
    message.department = (object.department !== undefined && object.department !== null)
      ? SsoDepartment.fromPartial(object.department)
      : undefined;
    message.roles = object.roles?.map((e) => SsoRole.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoDepartment(): SsoDepartment {
  return { id: undefined, name: undefined };
}

export const SsoDepartment = {
  encode(message: SsoDepartment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoDepartment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoDepartment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoDepartment {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: SsoDepartment): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoDepartment>, I>>(base?: I): SsoDepartment {
    return SsoDepartment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoDepartment>, I>>(object: I): SsoDepartment {
    const message = createBaseSsoDepartment();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseSsoRole(): SsoRole {
  return { id: undefined, name: undefined };
}

export const SsoRole = {
  encode(message: SsoRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoRole {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: SsoRole): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoRole>, I>>(base?: I): SsoRole {
    return SsoRole.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoRole>, I>>(object: I): SsoRole {
    const message = createBaseSsoRole();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseSsoAppUsers(): SsoAppUsers {
  return { appName: undefined, users: [] };
}

export const SsoAppUsers = {
  encode(message: SsoAppUsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appName !== undefined) {
      writer.uint32(10).string(message.appName);
    }
    for (const v of message.users) {
      SsoUser.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoAppUsers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoAppUsers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.users.push(SsoUser.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoAppUsers {
    return {
      appName: isSet(object.appName) ? String(object.appName) : undefined,
      users: Array.isArray(object?.users) ? object.users.map((e: any) => SsoUser.fromJSON(e)) : [],
    };
  },

  toJSON(message: SsoAppUsers): unknown {
    const obj: any = {};
    message.appName !== undefined && (obj.appName = message.appName);
    if (message.users) {
      obj.users = message.users.map((e) => e ? SsoUser.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoAppUsers>, I>>(base?: I): SsoAppUsers {
    return SsoAppUsers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoAppUsers>, I>>(object: I): SsoAppUsers {
    const message = createBaseSsoAppUsers();
    message.appName = object.appName ?? undefined;
    message.users = object.users?.map((e) => SsoUser.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoAppsData(): SsoAppsData {
  return { app: [] };
}

export const SsoAppsData = {
  encode(message: SsoAppsData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.app) {
      SsoAppUsers.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoAppsData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoAppsData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.app.push(SsoAppUsers.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoAppsData {
    return { app: Array.isArray(object?.app) ? object.app.map((e: any) => SsoAppUsers.fromJSON(e)) : [] };
  },

  toJSON(message: SsoAppsData): unknown {
    const obj: any = {};
    if (message.app) {
      obj.app = message.app.map((e) => e ? SsoAppUsers.toJSON(e) : undefined);
    } else {
      obj.app = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoAppsData>, I>>(base?: I): SsoAppsData {
    return SsoAppsData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoAppsData>, I>>(object: I): SsoAppsData {
    const message = createBaseSsoAppsData();
    message.app = object.app?.map((e) => SsoAppUsers.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoAppEntityProto(): SsoAppEntityProto {
  return { gormModel: undefined, baseModel: undefined, name: undefined, ssoUserAppEntities: [] };
}

export const SsoAppEntityProto = {
  encode(message: SsoAppEntityProto, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.ssoUserAppEntities) {
      SsoUserAppEntityProto.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoAppEntityProto {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoAppEntityProto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ssoUserAppEntities.push(SsoUserAppEntityProto.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoAppEntityProto {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      ssoUserAppEntities: Array.isArray(object?.ssoUserAppEntities)
        ? object.ssoUserAppEntities.map((e: any) => SsoUserAppEntityProto.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SsoAppEntityProto): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.ssoUserAppEntities) {
      obj.ssoUserAppEntities = message.ssoUserAppEntities.map((e) => e ? SsoUserAppEntityProto.toJSON(e) : undefined);
    } else {
      obj.ssoUserAppEntities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoAppEntityProto>, I>>(base?: I): SsoAppEntityProto {
    return SsoAppEntityProto.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoAppEntityProto>, I>>(object: I): SsoAppEntityProto {
    const message = createBaseSsoAppEntityProto();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.ssoUserAppEntities = object.ssoUserAppEntities?.map((e) => SsoUserAppEntityProto.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoAppEntitiesData(): SsoAppEntitiesData {
  return { ssoAppEntities: [] };
}

export const SsoAppEntitiesData = {
  encode(message: SsoAppEntitiesData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ssoAppEntities) {
      SsoAppEntityProto.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoAppEntitiesData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoAppEntitiesData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ssoAppEntities.push(SsoAppEntityProto.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoAppEntitiesData {
    return {
      ssoAppEntities: Array.isArray(object?.ssoAppEntities)
        ? object.ssoAppEntities.map((e: any) => SsoAppEntityProto.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SsoAppEntitiesData): unknown {
    const obj: any = {};
    if (message.ssoAppEntities) {
      obj.ssoAppEntities = message.ssoAppEntities.map((e) => e ? SsoAppEntityProto.toJSON(e) : undefined);
    } else {
      obj.ssoAppEntities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoAppEntitiesData>, I>>(base?: I): SsoAppEntitiesData {
    return SsoAppEntitiesData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoAppEntitiesData>, I>>(object: I): SsoAppEntitiesData {
    const message = createBaseSsoAppEntitiesData();
    message.ssoAppEntities = object.ssoAppEntities?.map((e) => SsoAppEntityProto.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoUserEntityProto(): SsoUserEntityProto {
  return {
    gormModel: undefined,
    baseModel: undefined,
    name: undefined,
    username: undefined,
    password: undefined,
    ssoAppEntityId: undefined,
    ssoUserAppEntity: [],
  };
}

export const SsoUserEntityProto = {
  encode(message: SsoUserEntityProto, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.username !== undefined) {
      writer.uint32(34).string(message.username);
    }
    if (message.password !== undefined) {
      writer.uint32(42).string(message.password);
    }
    if (message.ssoAppEntityId !== undefined) {
      writer.uint32(48).uint64(message.ssoAppEntityId);
    }
    for (const v of message.ssoUserAppEntity) {
      SsoUserAppEntityProto.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoUserEntityProto {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoUserEntityProto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.username = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.password = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ssoAppEntityId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ssoUserAppEntity.push(SsoUserAppEntityProto.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoUserEntityProto {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      username: isSet(object.username) ? String(object.username) : undefined,
      password: isSet(object.password) ? String(object.password) : undefined,
      ssoAppEntityId: isSet(object.ssoAppEntityId) ? String(object.ssoAppEntityId) : undefined,
      ssoUserAppEntity: Array.isArray(object?.ssoUserAppEntity)
        ? object.ssoUserAppEntity.map((e: any) => SsoUserAppEntityProto.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SsoUserEntityProto): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    message.ssoAppEntityId !== undefined && (obj.ssoAppEntityId = message.ssoAppEntityId);
    if (message.ssoUserAppEntity) {
      obj.ssoUserAppEntity = message.ssoUserAppEntity.map((e) => e ? SsoUserAppEntityProto.toJSON(e) : undefined);
    } else {
      obj.ssoUserAppEntity = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoUserEntityProto>, I>>(base?: I): SsoUserEntityProto {
    return SsoUserEntityProto.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoUserEntityProto>, I>>(object: I): SsoUserEntityProto {
    const message = createBaseSsoUserEntityProto();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.username = object.username ?? undefined;
    message.password = object.password ?? undefined;
    message.ssoAppEntityId = object.ssoAppEntityId ?? undefined;
    message.ssoUserAppEntity = object.ssoUserAppEntity?.map((e) => SsoUserAppEntityProto.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoUserEntitiesData(): SsoUserEntitiesData {
  return { ssoUserEntities: [] };
}

export const SsoUserEntitiesData = {
  encode(message: SsoUserEntitiesData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ssoUserEntities) {
      SsoUserEntityProto.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoUserEntitiesData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoUserEntitiesData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ssoUserEntities.push(SsoUserEntityProto.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoUserEntitiesData {
    return {
      ssoUserEntities: Array.isArray(object?.ssoUserEntities)
        ? object.ssoUserEntities.map((e: any) => SsoUserEntityProto.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SsoUserEntitiesData): unknown {
    const obj: any = {};
    if (message.ssoUserEntities) {
      obj.ssoUserEntities = message.ssoUserEntities.map((e) => e ? SsoUserEntityProto.toJSON(e) : undefined);
    } else {
      obj.ssoUserEntities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoUserEntitiesData>, I>>(base?: I): SsoUserEntitiesData {
    return SsoUserEntitiesData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoUserEntitiesData>, I>>(object: I): SsoUserEntitiesData {
    const message = createBaseSsoUserEntitiesData();
    message.ssoUserEntities = object.ssoUserEntities?.map((e) => SsoUserEntityProto.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoUserAppEntityProto(): SsoUserAppEntityProto {
  return {
    gormModel: undefined,
    baseModel: undefined,
    ssoUserEntityId: undefined,
    ssoUserEntity: undefined,
    ssoAppEntityId: undefined,
    ssoAppEntity: undefined,
    extUserId: undefined,
  };
}

export const SsoUserAppEntityProto = {
  encode(message: SsoUserAppEntityProto, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.ssoUserEntityId !== undefined) {
      writer.uint32(24).uint64(message.ssoUserEntityId);
    }
    if (message.ssoUserEntity !== undefined) {
      SsoUserEntityProto.encode(message.ssoUserEntity, writer.uint32(34).fork()).ldelim();
    }
    if (message.ssoAppEntityId !== undefined) {
      writer.uint32(40).uint64(message.ssoAppEntityId);
    }
    if (message.ssoAppEntity !== undefined) {
      SsoAppEntityProto.encode(message.ssoAppEntity, writer.uint32(50).fork()).ldelim();
    }
    if (message.extUserId !== undefined) {
      writer.uint32(56).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoUserAppEntityProto {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoUserAppEntityProto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ssoUserEntityId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ssoUserEntity = SsoUserEntityProto.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ssoAppEntityId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ssoAppEntity = SsoAppEntityProto.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoUserAppEntityProto {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      ssoUserEntityId: isSet(object.ssoUserEntityId) ? String(object.ssoUserEntityId) : undefined,
      ssoUserEntity: isSet(object.ssoUserEntity) ? SsoUserEntityProto.fromJSON(object.ssoUserEntity) : undefined,
      ssoAppEntityId: isSet(object.ssoAppEntityId) ? String(object.ssoAppEntityId) : undefined,
      ssoAppEntity: isSet(object.ssoAppEntity) ? SsoAppEntityProto.fromJSON(object.ssoAppEntity) : undefined,
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
    };
  },

  toJSON(message: SsoUserAppEntityProto): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.ssoUserEntityId !== undefined && (obj.ssoUserEntityId = message.ssoUserEntityId);
    message.ssoUserEntity !== undefined &&
      (obj.ssoUserEntity = message.ssoUserEntity ? SsoUserEntityProto.toJSON(message.ssoUserEntity) : undefined);
    message.ssoAppEntityId !== undefined && (obj.ssoAppEntityId = message.ssoAppEntityId);
    message.ssoAppEntity !== undefined &&
      (obj.ssoAppEntity = message.ssoAppEntity ? SsoAppEntityProto.toJSON(message.ssoAppEntity) : undefined);
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoUserAppEntityProto>, I>>(base?: I): SsoUserAppEntityProto {
    return SsoUserAppEntityProto.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoUserAppEntityProto>, I>>(object: I): SsoUserAppEntityProto {
    const message = createBaseSsoUserAppEntityProto();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.ssoUserEntityId = object.ssoUserEntityId ?? undefined;
    message.ssoUserEntity = (object.ssoUserEntity !== undefined && object.ssoUserEntity !== null)
      ? SsoUserEntityProto.fromPartial(object.ssoUserEntity)
      : undefined;
    message.ssoAppEntityId = object.ssoAppEntityId ?? undefined;
    message.ssoAppEntity = (object.ssoAppEntity !== undefined && object.ssoAppEntity !== null)
      ? SsoAppEntityProto.fromPartial(object.ssoAppEntity)
      : undefined;
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseSsoUserAppEntitiesData(): SsoUserAppEntitiesData {
  return { ssoUserAppEntities: [] };
}

export const SsoUserAppEntitiesData = {
  encode(message: SsoUserAppEntitiesData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ssoUserAppEntities) {
      SsoUserAppEntityProto.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoUserAppEntitiesData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoUserAppEntitiesData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ssoUserAppEntities.push(SsoUserAppEntityProto.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoUserAppEntitiesData {
    return {
      ssoUserAppEntities: Array.isArray(object?.ssoUserAppEntities)
        ? object.ssoUserAppEntities.map((e: any) => SsoUserAppEntityProto.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SsoUserAppEntitiesData): unknown {
    const obj: any = {};
    if (message.ssoUserAppEntities) {
      obj.ssoUserAppEntities = message.ssoUserAppEntities.map((e) => e ? SsoUserAppEntityProto.toJSON(e) : undefined);
    } else {
      obj.ssoUserAppEntities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoUserAppEntitiesData>, I>>(base?: I): SsoUserAppEntitiesData {
    return SsoUserAppEntitiesData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoUserAppEntitiesData>, I>>(object: I): SsoUserAppEntitiesData {
    const message = createBaseSsoUserAppEntitiesData();
    message.ssoUserAppEntities = object.ssoUserAppEntities?.map((e) => SsoUserAppEntityProto.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSsoUserAppAddBody(): SsoUserAppAddBody {
  return { extUserId: undefined, appName: undefined, ssoUserId: undefined };
}

export const SsoUserAppAddBody = {
  encode(message: SsoUserAppAddBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extUserId !== undefined) {
      writer.uint32(8).uint64(message.extUserId);
    }
    if (message.appName !== undefined) {
      writer.uint32(18).string(message.appName);
    }
    if (message.ssoUserId !== undefined) {
      writer.uint32(24).uint64(message.ssoUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoUserAppAddBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoUserAppAddBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.appName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ssoUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoUserAppAddBody {
    return {
      extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined,
      appName: isSet(object.appName) ? String(object.appName) : undefined,
      ssoUserId: isSet(object.ssoUserId) ? String(object.ssoUserId) : undefined,
    };
  },

  toJSON(message: SsoUserAppAddBody): unknown {
    const obj: any = {};
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    message.appName !== undefined && (obj.appName = message.appName);
    message.ssoUserId !== undefined && (obj.ssoUserId = message.ssoUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoUserAppAddBody>, I>>(base?: I): SsoUserAppAddBody {
    return SsoUserAppAddBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoUserAppAddBody>, I>>(object: I): SsoUserAppAddBody {
    const message = createBaseSsoUserAppAddBody();
    message.extUserId = object.extUserId ?? undefined;
    message.appName = object.appName ?? undefined;
    message.ssoUserId = object.ssoUserId ?? undefined;
    return message;
  },
};

function createBaseSsoUserAppAddHrBody(): SsoUserAppAddHrBody {
  return { extUserId: undefined };
}

export const SsoUserAppAddHrBody = {
  encode(message: SsoUserAppAddHrBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extUserId !== undefined) {
      writer.uint32(8).uint64(message.extUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SsoUserAppAddHrBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSsoUserAppAddHrBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.extUserId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SsoUserAppAddHrBody {
    return { extUserId: isSet(object.extUserId) ? String(object.extUserId) : undefined };
  },

  toJSON(message: SsoUserAppAddHrBody): unknown {
    const obj: any = {};
    message.extUserId !== undefined && (obj.extUserId = message.extUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SsoUserAppAddHrBody>, I>>(base?: I): SsoUserAppAddHrBody {
    return SsoUserAppAddHrBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SsoUserAppAddHrBody>, I>>(object: I): SsoUserAppAddHrBody {
    const message = createBaseSsoUserAppAddHrBody();
    message.extUserId = object.extUserId ?? undefined;
    return message;
  },
};

function createBaseIssueTrackerIssue(): IssueTrackerIssue {
  return {
    gormModel: undefined,
    baseModel: undefined,
    issueDescription: undefined,
    foundDate: undefined,
    foundBys: [],
    assignedTos: [],
    status: undefined,
    tags: [],
    tasks: [],
    comments: [],
    issueTags: [],
  };
}

export const IssueTrackerIssue = {
  encode(message: IssueTrackerIssue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.issueDescription !== undefined) {
      writer.uint32(26).string(message.issueDescription);
    }
    if (message.foundDate !== undefined) {
      writer.uint32(34).string(message.foundDate);
    }
    for (const v of message.foundBys) {
      IssueTrackerIssueFoundBy.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.assignedTos) {
      IssueTrackerIssueAssignedTo.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(56).int32(message.status);
    }
    for (const v of message.tags) {
      IssueTrackerIssueTag.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.tasks) {
      IssueTrackerIssueTask.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.comments) {
      IssueTrackerIssueComment.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.issueTags) {
      IssueTrackerIssueIssueTag.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.issueDescription = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.foundDate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.foundBys.push(IssueTrackerIssueFoundBy.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.assignedTos.push(IssueTrackerIssueAssignedTo.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tags.push(IssueTrackerIssueTag.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tasks.push(IssueTrackerIssueTask.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.comments.push(IssueTrackerIssueComment.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.issueTags.push(IssueTrackerIssueIssueTag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssue {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      issueDescription: isSet(object.issueDescription) ? String(object.issueDescription) : undefined,
      foundDate: isSet(object.foundDate) ? String(object.foundDate) : undefined,
      foundBys: Array.isArray(object?.foundBys)
        ? object.foundBys.map((e: any) => IssueTrackerIssueFoundBy.fromJSON(e))
        : [],
      assignedTos: Array.isArray(object?.assignedTos)
        ? object.assignedTos.map((e: any) => IssueTrackerIssueAssignedTo.fromJSON(e))
        : [],
      status: isSet(object.status) ? issueTrackerIssue_IssueTrackerStatusFromJSON(object.status) : undefined,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => IssueTrackerIssueTag.fromJSON(e)) : [],
      tasks: Array.isArray(object?.tasks) ? object.tasks.map((e: any) => IssueTrackerIssueTask.fromJSON(e)) : [],
      comments: Array.isArray(object?.comments)
        ? object.comments.map((e: any) => IssueTrackerIssueComment.fromJSON(e))
        : [],
      issueTags: Array.isArray(object?.issueTags)
        ? object.issueTags.map((e: any) => IssueTrackerIssueIssueTag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IssueTrackerIssue): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.issueDescription !== undefined && (obj.issueDescription = message.issueDescription);
    message.foundDate !== undefined && (obj.foundDate = message.foundDate);
    if (message.foundBys) {
      obj.foundBys = message.foundBys.map((e) => e ? IssueTrackerIssueFoundBy.toJSON(e) : undefined);
    } else {
      obj.foundBys = [];
    }
    if (message.assignedTos) {
      obj.assignedTos = message.assignedTos.map((e) => e ? IssueTrackerIssueAssignedTo.toJSON(e) : undefined);
    } else {
      obj.assignedTos = [];
    }
    message.status !== undefined && (obj.status = message.status !== undefined
      ? issueTrackerIssue_IssueTrackerStatusToJSON(message.status)
      : undefined);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? IssueTrackerIssueTag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    if (message.tasks) {
      obj.tasks = message.tasks.map((e) => e ? IssueTrackerIssueTask.toJSON(e) : undefined);
    } else {
      obj.tasks = [];
    }
    if (message.comments) {
      obj.comments = message.comments.map((e) => e ? IssueTrackerIssueComment.toJSON(e) : undefined);
    } else {
      obj.comments = [];
    }
    if (message.issueTags) {
      obj.issueTags = message.issueTags.map((e) => e ? IssueTrackerIssueIssueTag.toJSON(e) : undefined);
    } else {
      obj.issueTags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssue>, I>>(base?: I): IssueTrackerIssue {
    return IssueTrackerIssue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssue>, I>>(object: I): IssueTrackerIssue {
    const message = createBaseIssueTrackerIssue();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.issueDescription = object.issueDescription ?? undefined;
    message.foundDate = object.foundDate ?? undefined;
    message.foundBys = object.foundBys?.map((e) => IssueTrackerIssueFoundBy.fromPartial(e)) || [];
    message.assignedTos = object.assignedTos?.map((e) => IssueTrackerIssueAssignedTo.fromPartial(e)) || [];
    message.status = object.status ?? undefined;
    message.tags = object.tags?.map((e) => IssueTrackerIssueTag.fromPartial(e)) || [];
    message.tasks = object.tasks?.map((e) => IssueTrackerIssueTask.fromPartial(e)) || [];
    message.comments = object.comments?.map((e) => IssueTrackerIssueComment.fromPartial(e)) || [];
    message.issueTags = object.issueTags?.map((e) => IssueTrackerIssueIssueTag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssues(): IssueTrackerIssues {
  return { issues: [] };
}

export const IssueTrackerIssues = {
  encode(message: IssueTrackerIssues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.issues) {
      IssueTrackerIssue.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.issues.push(IssueTrackerIssue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssues {
    return {
      issues: Array.isArray(object?.issues) ? object.issues.map((e: any) => IssueTrackerIssue.fromJSON(e)) : [],
    };
  },

  toJSON(message: IssueTrackerIssues): unknown {
    const obj: any = {};
    if (message.issues) {
      obj.issues = message.issues.map((e) => e ? IssueTrackerIssue.toJSON(e) : undefined);
    } else {
      obj.issues = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssues>, I>>(base?: I): IssueTrackerIssues {
    return IssueTrackerIssues.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssues>, I>>(object: I): IssueTrackerIssues {
    const message = createBaseIssueTrackerIssues();
    message.issues = object.issues?.map((e) => IssueTrackerIssue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssueFoundBy(): IssueTrackerIssueFoundBy {
  return {
    gormModel: undefined,
    baseModel: undefined,
    extGspeUserAppId: undefined,
    remark: undefined,
    issue: undefined,
    issueId: undefined,
  };
}

export const IssueTrackerIssueFoundBy = {
  encode(message: IssueTrackerIssueFoundBy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.extGspeUserAppId !== undefined) {
      writer.uint32(24).uint64(message.extGspeUserAppId);
    }
    if (message.remark !== undefined) {
      writer.uint32(34).string(message.remark);
    }
    if (message.issue !== undefined) {
      IssueTrackerIssue.encode(message.issue, writer.uint32(42).fork()).ldelim();
    }
    if (message.issueId !== undefined) {
      writer.uint32(48).uint64(message.issueId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueFoundBy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueFoundBy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extGspeUserAppId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.issue = IssueTrackerIssue.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.issueId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueFoundBy {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      extGspeUserAppId: isSet(object.extGspeUserAppId) ? String(object.extGspeUserAppId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      issue: isSet(object.issue) ? IssueTrackerIssue.fromJSON(object.issue) : undefined,
      issueId: isSet(object.issueId) ? String(object.issueId) : undefined,
    };
  },

  toJSON(message: IssueTrackerIssueFoundBy): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.extGspeUserAppId !== undefined && (obj.extGspeUserAppId = message.extGspeUserAppId);
    message.remark !== undefined && (obj.remark = message.remark);
    message.issue !== undefined && (obj.issue = message.issue ? IssueTrackerIssue.toJSON(message.issue) : undefined);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueFoundBy>, I>>(base?: I): IssueTrackerIssueFoundBy {
    return IssueTrackerIssueFoundBy.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueFoundBy>, I>>(object: I): IssueTrackerIssueFoundBy {
    const message = createBaseIssueTrackerIssueFoundBy();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.extGspeUserAppId = object.extGspeUserAppId ?? undefined;
    message.remark = object.remark ?? undefined;
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? IssueTrackerIssue.fromPartial(object.issue)
      : undefined;
    message.issueId = object.issueId ?? undefined;
    return message;
  },
};

function createBaseIssueTrackerIssueFoundBys(): IssueTrackerIssueFoundBys {
  return { foundBys: [] };
}

export const IssueTrackerIssueFoundBys = {
  encode(message: IssueTrackerIssueFoundBys, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.foundBys) {
      IssueTrackerIssueFoundBy.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueFoundBys {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueFoundBys();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.foundBys.push(IssueTrackerIssueFoundBy.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueFoundBys {
    return {
      foundBys: Array.isArray(object?.foundBys)
        ? object.foundBys.map((e: any) => IssueTrackerIssueFoundBy.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IssueTrackerIssueFoundBys): unknown {
    const obj: any = {};
    if (message.foundBys) {
      obj.foundBys = message.foundBys.map((e) => e ? IssueTrackerIssueFoundBy.toJSON(e) : undefined);
    } else {
      obj.foundBys = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueFoundBys>, I>>(base?: I): IssueTrackerIssueFoundBys {
    return IssueTrackerIssueFoundBys.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueFoundBys>, I>>(object: I): IssueTrackerIssueFoundBys {
    const message = createBaseIssueTrackerIssueFoundBys();
    message.foundBys = object.foundBys?.map((e) => IssueTrackerIssueFoundBy.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssueAssignedTo(): IssueTrackerIssueAssignedTo {
  return {
    gormModel: undefined,
    baseModel: undefined,
    extGspeUserAppId: undefined,
    remark: undefined,
    issue: undefined,
    issueId: undefined,
  };
}

export const IssueTrackerIssueAssignedTo = {
  encode(message: IssueTrackerIssueAssignedTo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.extGspeUserAppId !== undefined) {
      writer.uint32(24).uint64(message.extGspeUserAppId);
    }
    if (message.remark !== undefined) {
      writer.uint32(34).string(message.remark);
    }
    if (message.issue !== undefined) {
      IssueTrackerIssue.encode(message.issue, writer.uint32(42).fork()).ldelim();
    }
    if (message.issueId !== undefined) {
      writer.uint32(48).uint64(message.issueId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueAssignedTo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueAssignedTo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extGspeUserAppId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.remark = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.issue = IssueTrackerIssue.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.issueId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueAssignedTo {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      extGspeUserAppId: isSet(object.extGspeUserAppId) ? String(object.extGspeUserAppId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
      issue: isSet(object.issue) ? IssueTrackerIssue.fromJSON(object.issue) : undefined,
      issueId: isSet(object.issueId) ? String(object.issueId) : undefined,
    };
  },

  toJSON(message: IssueTrackerIssueAssignedTo): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.extGspeUserAppId !== undefined && (obj.extGspeUserAppId = message.extGspeUserAppId);
    message.remark !== undefined && (obj.remark = message.remark);
    message.issue !== undefined && (obj.issue = message.issue ? IssueTrackerIssue.toJSON(message.issue) : undefined);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueAssignedTo>, I>>(base?: I): IssueTrackerIssueAssignedTo {
    return IssueTrackerIssueAssignedTo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueAssignedTo>, I>>(object: I): IssueTrackerIssueAssignedTo {
    const message = createBaseIssueTrackerIssueAssignedTo();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.extGspeUserAppId = object.extGspeUserAppId ?? undefined;
    message.remark = object.remark ?? undefined;
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? IssueTrackerIssue.fromPartial(object.issue)
      : undefined;
    message.issueId = object.issueId ?? undefined;
    return message;
  },
};

function createBaseIssueTrackerIssueAssignedTos(): IssueTrackerIssueAssignedTos {
  return { assignedTos: [] };
}

export const IssueTrackerIssueAssignedTos = {
  encode(message: IssueTrackerIssueAssignedTos, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assignedTos) {
      IssueTrackerIssueAssignedTo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueAssignedTos {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueAssignedTos();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assignedTos.push(IssueTrackerIssueAssignedTo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueAssignedTos {
    return {
      assignedTos: Array.isArray(object?.assignedTos)
        ? object.assignedTos.map((e: any) => IssueTrackerIssueAssignedTo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IssueTrackerIssueAssignedTos): unknown {
    const obj: any = {};
    if (message.assignedTos) {
      obj.assignedTos = message.assignedTos.map((e) => e ? IssueTrackerIssueAssignedTo.toJSON(e) : undefined);
    } else {
      obj.assignedTos = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueAssignedTos>, I>>(base?: I): IssueTrackerIssueAssignedTos {
    return IssueTrackerIssueAssignedTos.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueAssignedTos>, I>>(object: I): IssueTrackerIssueAssignedTos {
    const message = createBaseIssueTrackerIssueAssignedTos();
    message.assignedTos = object.assignedTos?.map((e) => IssueTrackerIssueAssignedTo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssueTag(): IssueTrackerIssueTag {
  return { gormModel: undefined, baseModel: undefined, name: undefined, issue: undefined, issueId: undefined };
}

export const IssueTrackerIssueTag = {
  encode(message: IssueTrackerIssueTag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.issue !== undefined) {
      IssueTrackerIssue.encode(message.issue, writer.uint32(34).fork()).ldelim();
    }
    if (message.issueId !== undefined) {
      writer.uint32(40).uint64(message.issueId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueTag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.issue = IssueTrackerIssue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.issueId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueTag {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      issue: isSet(object.issue) ? IssueTrackerIssue.fromJSON(object.issue) : undefined,
      issueId: isSet(object.issueId) ? String(object.issueId) : undefined,
    };
  },

  toJSON(message: IssueTrackerIssueTag): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.issue !== undefined && (obj.issue = message.issue ? IssueTrackerIssue.toJSON(message.issue) : undefined);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueTag>, I>>(base?: I): IssueTrackerIssueTag {
    return IssueTrackerIssueTag.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueTag>, I>>(object: I): IssueTrackerIssueTag {
    const message = createBaseIssueTrackerIssueTag();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.name = object.name ?? undefined;
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? IssueTrackerIssue.fromPartial(object.issue)
      : undefined;
    message.issueId = object.issueId ?? undefined;
    return message;
  },
};

function createBaseIssueTrackerIssueTags(): IssueTrackerIssueTags {
  return { tags: [] };
}

export const IssueTrackerIssueTags = {
  encode(message: IssueTrackerIssueTags, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tags) {
      IssueTrackerIssueTag.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueTags {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueTags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tags.push(IssueTrackerIssueTag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueTags {
    return { tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => IssueTrackerIssueTag.fromJSON(e)) : [] };
  },

  toJSON(message: IssueTrackerIssueTags): unknown {
    const obj: any = {};
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? IssueTrackerIssueTag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueTags>, I>>(base?: I): IssueTrackerIssueTags {
    return IssueTrackerIssueTags.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueTags>, I>>(object: I): IssueTrackerIssueTags {
    const message = createBaseIssueTrackerIssueTags();
    message.tags = object.tags?.map((e) => IssueTrackerIssueTag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssueTask(): IssueTrackerIssueTask {
  return {
    gormModel: undefined,
    baseModel: undefined,
    extMeetingTaskId: undefined,
    issue: undefined,
    issueId: undefined,
    remark: undefined,
  };
}

export const IssueTrackerIssueTask = {
  encode(message: IssueTrackerIssueTask, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.extMeetingTaskId !== undefined) {
      writer.uint32(24).uint64(message.extMeetingTaskId);
    }
    if (message.issue !== undefined) {
      IssueTrackerIssue.encode(message.issue, writer.uint32(34).fork()).ldelim();
    }
    if (message.issueId !== undefined) {
      writer.uint32(40).uint64(message.issueId);
    }
    if (message.remark !== undefined) {
      writer.uint32(50).string(message.remark);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueTask {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extMeetingTaskId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.issue = IssueTrackerIssue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.issueId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.remark = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueTask {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      extMeetingTaskId: isSet(object.extMeetingTaskId) ? String(object.extMeetingTaskId) : undefined,
      issue: isSet(object.issue) ? IssueTrackerIssue.fromJSON(object.issue) : undefined,
      issueId: isSet(object.issueId) ? String(object.issueId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
    };
  },

  toJSON(message: IssueTrackerIssueTask): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.extMeetingTaskId !== undefined && (obj.extMeetingTaskId = message.extMeetingTaskId);
    message.issue !== undefined && (obj.issue = message.issue ? IssueTrackerIssue.toJSON(message.issue) : undefined);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.remark !== undefined && (obj.remark = message.remark);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueTask>, I>>(base?: I): IssueTrackerIssueTask {
    return IssueTrackerIssueTask.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueTask>, I>>(object: I): IssueTrackerIssueTask {
    const message = createBaseIssueTrackerIssueTask();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.extMeetingTaskId = object.extMeetingTaskId ?? undefined;
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? IssueTrackerIssue.fromPartial(object.issue)
      : undefined;
    message.issueId = object.issueId ?? undefined;
    message.remark = object.remark ?? undefined;
    return message;
  },
};

function createBaseIssueTrackerIssueTasks(): IssueTrackerIssueTasks {
  return { tasks: [] };
}

export const IssueTrackerIssueTasks = {
  encode(message: IssueTrackerIssueTasks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tasks) {
      IssueTrackerIssueTask.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueTasks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueTasks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tasks.push(IssueTrackerIssueTask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueTasks {
    return {
      tasks: Array.isArray(object?.tasks) ? object.tasks.map((e: any) => IssueTrackerIssueTask.fromJSON(e)) : [],
    };
  },

  toJSON(message: IssueTrackerIssueTasks): unknown {
    const obj: any = {};
    if (message.tasks) {
      obj.tasks = message.tasks.map((e) => e ? IssueTrackerIssueTask.toJSON(e) : undefined);
    } else {
      obj.tasks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueTasks>, I>>(base?: I): IssueTrackerIssueTasks {
    return IssueTrackerIssueTasks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueTasks>, I>>(object: I): IssueTrackerIssueTasks {
    const message = createBaseIssueTrackerIssueTasks();
    message.tasks = object.tasks?.map((e) => IssueTrackerIssueTask.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssueComment(): IssueTrackerIssueComment {
  return {
    gormModel: undefined,
    baseModel: undefined,
    extGspeUserAppId: undefined,
    issue: undefined,
    issueId: undefined,
    remark: undefined,
  };
}

export const IssueTrackerIssueComment = {
  encode(message: IssueTrackerIssueComment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.extGspeUserAppId !== undefined) {
      writer.uint32(24).uint64(message.extGspeUserAppId);
    }
    if (message.issue !== undefined) {
      IssueTrackerIssue.encode(message.issue, writer.uint32(34).fork()).ldelim();
    }
    if (message.issueId !== undefined) {
      writer.uint32(40).uint64(message.issueId);
    }
    if (message.remark !== undefined) {
      writer.uint32(50).string(message.remark);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueComment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueComment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extGspeUserAppId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.issue = IssueTrackerIssue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.issueId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.remark = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueComment {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      extGspeUserAppId: isSet(object.extGspeUserAppId) ? String(object.extGspeUserAppId) : undefined,
      issue: isSet(object.issue) ? IssueTrackerIssue.fromJSON(object.issue) : undefined,
      issueId: isSet(object.issueId) ? String(object.issueId) : undefined,
      remark: isSet(object.remark) ? String(object.remark) : undefined,
    };
  },

  toJSON(message: IssueTrackerIssueComment): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.extGspeUserAppId !== undefined && (obj.extGspeUserAppId = message.extGspeUserAppId);
    message.issue !== undefined && (obj.issue = message.issue ? IssueTrackerIssue.toJSON(message.issue) : undefined);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.remark !== undefined && (obj.remark = message.remark);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueComment>, I>>(base?: I): IssueTrackerIssueComment {
    return IssueTrackerIssueComment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueComment>, I>>(object: I): IssueTrackerIssueComment {
    const message = createBaseIssueTrackerIssueComment();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.extGspeUserAppId = object.extGspeUserAppId ?? undefined;
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? IssueTrackerIssue.fromPartial(object.issue)
      : undefined;
    message.issueId = object.issueId ?? undefined;
    message.remark = object.remark ?? undefined;
    return message;
  },
};

function createBaseIssueTrackerIssueComments(): IssueTrackerIssueComments {
  return { comments: [] };
}

export const IssueTrackerIssueComments = {
  encode(message: IssueTrackerIssueComments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.comments) {
      IssueTrackerIssueComment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueComments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueComments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comments.push(IssueTrackerIssueComment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueComments {
    return {
      comments: Array.isArray(object?.comments)
        ? object.comments.map((e: any) => IssueTrackerIssueComment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IssueTrackerIssueComments): unknown {
    const obj: any = {};
    if (message.comments) {
      obj.comments = message.comments.map((e) => e ? IssueTrackerIssueComment.toJSON(e) : undefined);
    } else {
      obj.comments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueComments>, I>>(base?: I): IssueTrackerIssueComments {
    return IssueTrackerIssueComments.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueComments>, I>>(object: I): IssueTrackerIssueComments {
    const message = createBaseIssueTrackerIssueComments();
    message.comments = object.comments?.map((e) => IssueTrackerIssueComment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssueIssueTag(): IssueTrackerIssueIssueTag {
  return {
    gormModel: undefined,
    baseModel: undefined,
    extGspeUserAppId: undefined,
    issue: undefined,
    issueId: undefined,
    tag: undefined,
    tagId: undefined,
  };
}

export const IssueTrackerIssueIssueTag = {
  encode(message: IssueTrackerIssueIssueTag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.extGspeUserAppId !== undefined) {
      writer.uint32(24).uint64(message.extGspeUserAppId);
    }
    if (message.issue !== undefined) {
      IssueTrackerIssue.encode(message.issue, writer.uint32(34).fork()).ldelim();
    }
    if (message.issueId !== undefined) {
      writer.uint32(40).uint64(message.issueId);
    }
    if (message.tag !== undefined) {
      IssueTrackerIssueTag.encode(message.tag, writer.uint32(50).fork()).ldelim();
    }
    if (message.tagId !== undefined) {
      writer.uint32(56).uint64(message.tagId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueIssueTag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueIssueTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.extGspeUserAppId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.issue = IssueTrackerIssue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.issueId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tag = IssueTrackerIssueTag.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.tagId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueIssueTag {
    return {
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      extGspeUserAppId: isSet(object.extGspeUserAppId) ? String(object.extGspeUserAppId) : undefined,
      issue: isSet(object.issue) ? IssueTrackerIssue.fromJSON(object.issue) : undefined,
      issueId: isSet(object.issueId) ? String(object.issueId) : undefined,
      tag: isSet(object.tag) ? IssueTrackerIssueTag.fromJSON(object.tag) : undefined,
      tagId: isSet(object.tagId) ? String(object.tagId) : undefined,
    };
  },

  toJSON(message: IssueTrackerIssueIssueTag): unknown {
    const obj: any = {};
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.extGspeUserAppId !== undefined && (obj.extGspeUserAppId = message.extGspeUserAppId);
    message.issue !== undefined && (obj.issue = message.issue ? IssueTrackerIssue.toJSON(message.issue) : undefined);
    message.issueId !== undefined && (obj.issueId = message.issueId);
    message.tag !== undefined && (obj.tag = message.tag ? IssueTrackerIssueTag.toJSON(message.tag) : undefined);
    message.tagId !== undefined && (obj.tagId = message.tagId);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueIssueTag>, I>>(base?: I): IssueTrackerIssueIssueTag {
    return IssueTrackerIssueIssueTag.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueIssueTag>, I>>(object: I): IssueTrackerIssueIssueTag {
    const message = createBaseIssueTrackerIssueIssueTag();
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.extGspeUserAppId = object.extGspeUserAppId ?? undefined;
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? IssueTrackerIssue.fromPartial(object.issue)
      : undefined;
    message.issueId = object.issueId ?? undefined;
    message.tag = (object.tag !== undefined && object.tag !== null)
      ? IssueTrackerIssueTag.fromPartial(object.tag)
      : undefined;
    message.tagId = object.tagId ?? undefined;
    return message;
  },
};

function createBaseIssueTrackerIssueIssueTags(): IssueTrackerIssueIssueTags {
  return { issueTags: [] };
}

export const IssueTrackerIssueIssueTags = {
  encode(message: IssueTrackerIssueIssueTags, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.issueTags) {
      IssueTrackerIssueIssueTag.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssueIssueTags {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssueIssueTags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.issueTags.push(IssueTrackerIssueIssueTag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssueIssueTags {
    return {
      issueTags: Array.isArray(object?.issueTags)
        ? object.issueTags.map((e: any) => IssueTrackerIssueIssueTag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IssueTrackerIssueIssueTags): unknown {
    const obj: any = {};
    if (message.issueTags) {
      obj.issueTags = message.issueTags.map((e) => e ? IssueTrackerIssueIssueTag.toJSON(e) : undefined);
    } else {
      obj.issueTags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssueIssueTags>, I>>(base?: I): IssueTrackerIssueIssueTags {
    return IssueTrackerIssueIssueTags.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssueIssueTags>, I>>(object: I): IssueTrackerIssueIssueTags {
    const message = createBaseIssueTrackerIssueIssueTags();
    message.issueTags = object.issueTags?.map((e) => IssueTrackerIssueIssueTag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTrackerIssuePostBody(): IssueTrackerIssuePostBody {
  return {
    issue: undefined,
    foundByDeleteIds: [],
    assignedToDeleteIds: [],
    tagDeleteIds: [],
    taskDeleteIds: [],
    commentDeleteIds: [],
  };
}

export const IssueTrackerIssuePostBody = {
  encode(message: IssueTrackerIssuePostBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issue !== undefined) {
      IssueTrackerIssue.encode(message.issue, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.foundByDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.assignedToDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.tagDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.taskDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.commentDeleteIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTrackerIssuePostBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTrackerIssuePostBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.issue = IssueTrackerIssue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag === 16) {
            message.foundByDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.foundByDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.assignedToDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.assignedToDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.tagDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tagDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.taskDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.taskDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 6:
          if (tag === 48) {
            message.commentDeleteIds.push(longToString(reader.uint64() as Long));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.commentDeleteIds.push(longToString(reader.uint64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTrackerIssuePostBody {
    return {
      issue: isSet(object.issue) ? IssueTrackerIssue.fromJSON(object.issue) : undefined,
      foundByDeleteIds: Array.isArray(object?.foundByDeleteIds)
        ? object.foundByDeleteIds.map((e: any) => String(e))
        : [],
      assignedToDeleteIds: Array.isArray(object?.assignedToDeleteIds)
        ? object.assignedToDeleteIds.map((e: any) => String(e))
        : [],
      tagDeleteIds: Array.isArray(object?.tagDeleteIds) ? object.tagDeleteIds.map((e: any) => String(e)) : [],
      taskDeleteIds: Array.isArray(object?.taskDeleteIds) ? object.taskDeleteIds.map((e: any) => String(e)) : [],
      commentDeleteIds: Array.isArray(object?.commentDeleteIds)
        ? object.commentDeleteIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: IssueTrackerIssuePostBody): unknown {
    const obj: any = {};
    message.issue !== undefined && (obj.issue = message.issue ? IssueTrackerIssue.toJSON(message.issue) : undefined);
    if (message.foundByDeleteIds) {
      obj.foundByDeleteIds = message.foundByDeleteIds.map((e) => e);
    } else {
      obj.foundByDeleteIds = [];
    }
    if (message.assignedToDeleteIds) {
      obj.assignedToDeleteIds = message.assignedToDeleteIds.map((e) => e);
    } else {
      obj.assignedToDeleteIds = [];
    }
    if (message.tagDeleteIds) {
      obj.tagDeleteIds = message.tagDeleteIds.map((e) => e);
    } else {
      obj.tagDeleteIds = [];
    }
    if (message.taskDeleteIds) {
      obj.taskDeleteIds = message.taskDeleteIds.map((e) => e);
    } else {
      obj.taskDeleteIds = [];
    }
    if (message.commentDeleteIds) {
      obj.commentDeleteIds = message.commentDeleteIds.map((e) => e);
    } else {
      obj.commentDeleteIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTrackerIssuePostBody>, I>>(base?: I): IssueTrackerIssuePostBody {
    return IssueTrackerIssuePostBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueTrackerIssuePostBody>, I>>(object: I): IssueTrackerIssuePostBody {
    const message = createBaseIssueTrackerIssuePostBody();
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? IssueTrackerIssue.fromPartial(object.issue)
      : undefined;
    message.foundByDeleteIds = object.foundByDeleteIds?.map((e) => e) || [];
    message.assignedToDeleteIds = object.assignedToDeleteIds?.map((e) => e) || [];
    message.tagDeleteIds = object.tagDeleteIds?.map((e) => e) || [];
    message.taskDeleteIds = object.taskDeleteIds?.map((e) => e) || [];
    message.commentDeleteIds = object.commentDeleteIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseAccountingChartOfAccount(): AccountingChartOfAccount {
  return { id: undefined, accountNumber: undefined, accountName: undefined };
}

export const AccountingChartOfAccount = {
  encode(message: AccountingChartOfAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.accountNumber !== undefined) {
      writer.uint32(18).string(message.accountNumber);
    }
    if (message.accountName !== undefined) {
      writer.uint32(26).string(message.accountName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountingChartOfAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountingChartOfAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountingChartOfAccount {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      accountNumber: isSet(object.accountNumber) ? String(object.accountNumber) : undefined,
      accountName: isSet(object.accountName) ? String(object.accountName) : undefined,
    };
  },

  toJSON(message: AccountingChartOfAccount): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.accountNumber !== undefined && (obj.accountNumber = message.accountNumber);
    message.accountName !== undefined && (obj.accountName = message.accountName);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountingChartOfAccount>, I>>(base?: I): AccountingChartOfAccount {
    return AccountingChartOfAccount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountingChartOfAccount>, I>>(object: I): AccountingChartOfAccount {
    const message = createBaseAccountingChartOfAccount();
    message.id = object.id ?? undefined;
    message.accountNumber = object.accountNumber ?? undefined;
    message.accountName = object.accountName ?? undefined;
    return message;
  },
};

function createBaseAccountingChartOfAccounts(): AccountingChartOfAccounts {
  return { chartOfAccounts: [] };
}

export const AccountingChartOfAccounts = {
  encode(message: AccountingChartOfAccounts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.chartOfAccounts) {
      AccountingChartOfAccount.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountingChartOfAccounts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountingChartOfAccounts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chartOfAccounts.push(AccountingChartOfAccount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountingChartOfAccounts {
    return {
      chartOfAccounts: Array.isArray(object?.chartOfAccounts)
        ? object.chartOfAccounts.map((e: any) => AccountingChartOfAccount.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountingChartOfAccounts): unknown {
    const obj: any = {};
    if (message.chartOfAccounts) {
      obj.chartOfAccounts = message.chartOfAccounts.map((e) => e ? AccountingChartOfAccount.toJSON(e) : undefined);
    } else {
      obj.chartOfAccounts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountingChartOfAccounts>, I>>(base?: I): AccountingChartOfAccounts {
    return AccountingChartOfAccounts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountingChartOfAccounts>, I>>(object: I): AccountingChartOfAccounts {
    const message = createBaseAccountingChartOfAccounts();
    message.chartOfAccounts = object.chartOfAccounts?.map((e) => AccountingChartOfAccount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDynaformBaseModel(): DynaformBaseModel {
  return { baseModel: undefined, gormModel: undefined };
}

export const DynaformBaseModel = {
  encode(message: DynaformBaseModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseModel !== undefined) {
      AuthserverBaseModel.encode(message.baseModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.gormModel !== undefined) {
      AuthserverGormModel.encode(message.gormModel, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformBaseModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformBaseModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.baseModel = AuthserverBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gormModel = AuthserverGormModel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformBaseModel {
    return {
      baseModel: isSet(object.baseModel) ? AuthserverBaseModel.fromJSON(object.baseModel) : undefined,
      gormModel: isSet(object.gormModel) ? AuthserverGormModel.fromJSON(object.gormModel) : undefined,
    };
  },

  toJSON(message: DynaformBaseModel): unknown {
    const obj: any = {};
    message.baseModel !== undefined &&
      (obj.baseModel = message.baseModel ? AuthserverBaseModel.toJSON(message.baseModel) : undefined);
    message.gormModel !== undefined &&
      (obj.gormModel = message.gormModel ? AuthserverGormModel.toJSON(message.gormModel) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformBaseModel>, I>>(base?: I): DynaformBaseModel {
    return DynaformBaseModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformBaseModel>, I>>(object: I): DynaformBaseModel {
    const message = createBaseDynaformBaseModel();
    message.baseModel = (object.baseModel !== undefined && object.baseModel !== null)
      ? AuthserverBaseModel.fromPartial(object.baseModel)
      : undefined;
    message.gormModel = (object.gormModel !== undefined && object.gormModel !== null)
      ? AuthserverGormModel.fromPartial(object.gormModel)
      : undefined;
    return message;
  },
};

function createBaseDynaformForm(): DynaformForm {
  return { base: undefined, name: undefined, versions: [] };
}

export const DynaformForm = {
  encode(message: DynaformForm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base !== undefined) {
      DynaformBaseModel.encode(message.base, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.versions) {
      DynaformFormVersion.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformForm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformForm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base = DynaformBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.versions.push(DynaformFormVersion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformForm {
    return {
      base: isSet(object.base) ? DynaformBaseModel.fromJSON(object.base) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      versions: Array.isArray(object?.versions) ? object.versions.map((e: any) => DynaformFormVersion.fromJSON(e)) : [],
    };
  },

  toJSON(message: DynaformForm): unknown {
    const obj: any = {};
    message.base !== undefined && (obj.base = message.base ? DynaformBaseModel.toJSON(message.base) : undefined);
    message.name !== undefined && (obj.name = message.name);
    if (message.versions) {
      obj.versions = message.versions.map((e) => e ? DynaformFormVersion.toJSON(e) : undefined);
    } else {
      obj.versions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformForm>, I>>(base?: I): DynaformForm {
    return DynaformForm.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformForm>, I>>(object: I): DynaformForm {
    const message = createBaseDynaformForm();
    message.base = (object.base !== undefined && object.base !== null)
      ? DynaformBaseModel.fromPartial(object.base)
      : undefined;
    message.name = object.name ?? undefined;
    message.versions = object.versions?.map((e) => DynaformFormVersion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDynaformForms(): DynaformForms {
  return { forms: [] };
}

export const DynaformForms = {
  encode(message: DynaformForms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.forms) {
      DynaformForm.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformForms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformForms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.forms.push(DynaformForm.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformForms {
    return { forms: Array.isArray(object?.forms) ? object.forms.map((e: any) => DynaformForm.fromJSON(e)) : [] };
  },

  toJSON(message: DynaformForms): unknown {
    const obj: any = {};
    if (message.forms) {
      obj.forms = message.forms.map((e) => e ? DynaformForm.toJSON(e) : undefined);
    } else {
      obj.forms = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformForms>, I>>(base?: I): DynaformForms {
    return DynaformForms.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformForms>, I>>(object: I): DynaformForms {
    const message = createBaseDynaformForms();
    message.forms = object.forms?.map((e) => DynaformForm.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDynaformFormVersion(): DynaformFormVersion {
  return { base: undefined, name: undefined, form: undefined, formId: undefined, fields: [], documents: [] };
}

export const DynaformFormVersion = {
  encode(message: DynaformFormVersion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base !== undefined) {
      DynaformBaseModel.encode(message.base, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.form !== undefined) {
      DynaformForm.encode(message.form, writer.uint32(26).fork()).ldelim();
    }
    if (message.formId !== undefined) {
      writer.uint32(32).uint64(message.formId);
    }
    for (const v of message.fields) {
      DynaformFormVersionField.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.documents) {
      DynaformFormDocument.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformFormVersion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformFormVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base = DynaformBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.form = DynaformForm.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.formId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fields.push(DynaformFormVersionField.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.documents.push(DynaformFormDocument.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformFormVersion {
    return {
      base: isSet(object.base) ? DynaformBaseModel.fromJSON(object.base) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      form: isSet(object.form) ? DynaformForm.fromJSON(object.form) : undefined,
      formId: isSet(object.formId) ? String(object.formId) : undefined,
      fields: Array.isArray(object?.fields) ? object.fields.map((e: any) => DynaformFormVersionField.fromJSON(e)) : [],
      documents: Array.isArray(object?.documents)
        ? object.documents.map((e: any) => DynaformFormDocument.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DynaformFormVersion): unknown {
    const obj: any = {};
    message.base !== undefined && (obj.base = message.base ? DynaformBaseModel.toJSON(message.base) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.form !== undefined && (obj.form = message.form ? DynaformForm.toJSON(message.form) : undefined);
    message.formId !== undefined && (obj.formId = message.formId);
    if (message.fields) {
      obj.fields = message.fields.map((e) => e ? DynaformFormVersionField.toJSON(e) : undefined);
    } else {
      obj.fields = [];
    }
    if (message.documents) {
      obj.documents = message.documents.map((e) => e ? DynaformFormDocument.toJSON(e) : undefined);
    } else {
      obj.documents = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformFormVersion>, I>>(base?: I): DynaformFormVersion {
    return DynaformFormVersion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformFormVersion>, I>>(object: I): DynaformFormVersion {
    const message = createBaseDynaformFormVersion();
    message.base = (object.base !== undefined && object.base !== null)
      ? DynaformBaseModel.fromPartial(object.base)
      : undefined;
    message.name = object.name ?? undefined;
    message.form = (object.form !== undefined && object.form !== null)
      ? DynaformForm.fromPartial(object.form)
      : undefined;
    message.formId = object.formId ?? undefined;
    message.fields = object.fields?.map((e) => DynaformFormVersionField.fromPartial(e)) || [];
    message.documents = object.documents?.map((e) => DynaformFormDocument.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDynaformFormVersionField(): DynaformFormVersionField {
  return {
    base: undefined,
    name: undefined,
    fieldName: undefined,
    version: undefined,
    versionId: undefined,
    dataType: undefined,
    checks: [],
  };
}

export const DynaformFormVersionField = {
  encode(message: DynaformFormVersionField, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base !== undefined) {
      DynaformBaseModel.encode(message.base, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.fieldName !== undefined) {
      writer.uint32(26).string(message.fieldName);
    }
    if (message.version !== undefined) {
      DynaformFormVersion.encode(message.version, writer.uint32(34).fork()).ldelim();
    }
    if (message.versionId !== undefined) {
      writer.uint32(40).uint64(message.versionId);
    }
    if (message.dataType !== undefined) {
      writer.uint32(48).int32(message.dataType);
    }
    for (const v of message.checks) {
      DynaformFormVersionFieldCheck.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformFormVersionField {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformFormVersionField();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base = DynaformBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fieldName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.version = DynaformFormVersion.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.versionId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dataType = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.checks.push(DynaformFormVersionFieldCheck.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformFormVersionField {
    return {
      base: isSet(object.base) ? DynaformBaseModel.fromJSON(object.base) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      fieldName: isSet(object.fieldName) ? String(object.fieldName) : undefined,
      version: isSet(object.version) ? DynaformFormVersion.fromJSON(object.version) : undefined,
      versionId: isSet(object.versionId) ? String(object.versionId) : undefined,
      dataType: isSet(object.dataType) ? dynaformFormVersionField_DataTypeFromJSON(object.dataType) : undefined,
      checks: Array.isArray(object?.checks)
        ? object.checks.map((e: any) => DynaformFormVersionFieldCheck.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DynaformFormVersionField): unknown {
    const obj: any = {};
    message.base !== undefined && (obj.base = message.base ? DynaformBaseModel.toJSON(message.base) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.fieldName !== undefined && (obj.fieldName = message.fieldName);
    message.version !== undefined &&
      (obj.version = message.version ? DynaformFormVersion.toJSON(message.version) : undefined);
    message.versionId !== undefined && (obj.versionId = message.versionId);
    message.dataType !== undefined && (obj.dataType = message.dataType !== undefined
      ? dynaformFormVersionField_DataTypeToJSON(message.dataType)
      : undefined);
    if (message.checks) {
      obj.checks = message.checks.map((e) => e ? DynaformFormVersionFieldCheck.toJSON(e) : undefined);
    } else {
      obj.checks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformFormVersionField>, I>>(base?: I): DynaformFormVersionField {
    return DynaformFormVersionField.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformFormVersionField>, I>>(object: I): DynaformFormVersionField {
    const message = createBaseDynaformFormVersionField();
    message.base = (object.base !== undefined && object.base !== null)
      ? DynaformBaseModel.fromPartial(object.base)
      : undefined;
    message.name = object.name ?? undefined;
    message.fieldName = object.fieldName ?? undefined;
    message.version = (object.version !== undefined && object.version !== null)
      ? DynaformFormVersion.fromPartial(object.version)
      : undefined;
    message.versionId = object.versionId ?? undefined;
    message.dataType = object.dataType ?? undefined;
    message.checks = object.checks?.map((e) => DynaformFormVersionFieldCheck.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDynaformFormVersionFieldCheck(): DynaformFormVersionFieldCheck {
  return { base: undefined, name: undefined, field: undefined, fieldId: undefined };
}

export const DynaformFormVersionFieldCheck = {
  encode(message: DynaformFormVersionFieldCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base !== undefined) {
      DynaformBaseModel.encode(message.base, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.field !== undefined) {
      DynaformFormVersionField.encode(message.field, writer.uint32(26).fork()).ldelim();
    }
    if (message.fieldId !== undefined) {
      writer.uint32(32).uint64(message.fieldId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformFormVersionFieldCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformFormVersionFieldCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base = DynaformBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.field = DynaformFormVersionField.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.fieldId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformFormVersionFieldCheck {
    return {
      base: isSet(object.base) ? DynaformBaseModel.fromJSON(object.base) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      field: isSet(object.field) ? DynaformFormVersionField.fromJSON(object.field) : undefined,
      fieldId: isSet(object.fieldId) ? String(object.fieldId) : undefined,
    };
  },

  toJSON(message: DynaformFormVersionFieldCheck): unknown {
    const obj: any = {};
    message.base !== undefined && (obj.base = message.base ? DynaformBaseModel.toJSON(message.base) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.field !== undefined &&
      (obj.field = message.field ? DynaformFormVersionField.toJSON(message.field) : undefined);
    message.fieldId !== undefined && (obj.fieldId = message.fieldId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformFormVersionFieldCheck>, I>>(base?: I): DynaformFormVersionFieldCheck {
    return DynaformFormVersionFieldCheck.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformFormVersionFieldCheck>, I>>(
    object: I,
  ): DynaformFormVersionFieldCheck {
    const message = createBaseDynaformFormVersionFieldCheck();
    message.base = (object.base !== undefined && object.base !== null)
      ? DynaformBaseModel.fromPartial(object.base)
      : undefined;
    message.name = object.name ?? undefined;
    message.field = (object.field !== undefined && object.field !== null)
      ? DynaformFormVersionField.fromPartial(object.field)
      : undefined;
    message.fieldId = object.fieldId ?? undefined;
    return message;
  },
};

function createBaseDynaformFormDocument(): DynaformFormDocument {
  return { base: undefined, name: undefined, version: undefined, versionId: undefined, values: [] };
}

export const DynaformFormDocument = {
  encode(message: DynaformFormDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base !== undefined) {
      DynaformBaseModel.encode(message.base, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.version !== undefined) {
      DynaformFormVersion.encode(message.version, writer.uint32(26).fork()).ldelim();
    }
    if (message.versionId !== undefined) {
      writer.uint32(32).uint64(message.versionId);
    }
    for (const v of message.values) {
      DynaformFormDocumentValue.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformFormDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformFormDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base = DynaformBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.version = DynaformFormVersion.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.versionId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.values.push(DynaformFormDocumentValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformFormDocument {
    return {
      base: isSet(object.base) ? DynaformBaseModel.fromJSON(object.base) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      version: isSet(object.version) ? DynaformFormVersion.fromJSON(object.version) : undefined,
      versionId: isSet(object.versionId) ? String(object.versionId) : undefined,
      values: Array.isArray(object?.values) ? object.values.map((e: any) => DynaformFormDocumentValue.fromJSON(e)) : [],
    };
  },

  toJSON(message: DynaformFormDocument): unknown {
    const obj: any = {};
    message.base !== undefined && (obj.base = message.base ? DynaformBaseModel.toJSON(message.base) : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.version !== undefined &&
      (obj.version = message.version ? DynaformFormVersion.toJSON(message.version) : undefined);
    message.versionId !== undefined && (obj.versionId = message.versionId);
    if (message.values) {
      obj.values = message.values.map((e) => e ? DynaformFormDocumentValue.toJSON(e) : undefined);
    } else {
      obj.values = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformFormDocument>, I>>(base?: I): DynaformFormDocument {
    return DynaformFormDocument.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformFormDocument>, I>>(object: I): DynaformFormDocument {
    const message = createBaseDynaformFormDocument();
    message.base = (object.base !== undefined && object.base !== null)
      ? DynaformBaseModel.fromPartial(object.base)
      : undefined;
    message.name = object.name ?? undefined;
    message.version = (object.version !== undefined && object.version !== null)
      ? DynaformFormVersion.fromPartial(object.version)
      : undefined;
    message.versionId = object.versionId ?? undefined;
    message.values = object.values?.map((e) => DynaformFormDocumentValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDynaformFormDocumentValue(): DynaformFormDocumentValue {
  return {
    base: undefined,
    document: undefined,
    documentId: undefined,
    field: undefined,
    fieldId: undefined,
    numericValue: undefined,
    stringValue: undefined,
    name: undefined,
    checks: [],
  };
}

export const DynaformFormDocumentValue = {
  encode(message: DynaformFormDocumentValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base !== undefined) {
      DynaformBaseModel.encode(message.base, writer.uint32(10).fork()).ldelim();
    }
    if (message.document !== undefined) {
      DynaformFormDocument.encode(message.document, writer.uint32(18).fork()).ldelim();
    }
    if (message.documentId !== undefined) {
      writer.uint32(24).uint64(message.documentId);
    }
    if (message.field !== undefined) {
      DynaformFormVersionField.encode(message.field, writer.uint32(34).fork()).ldelim();
    }
    if (message.fieldId !== undefined) {
      writer.uint32(40).uint64(message.fieldId);
    }
    if (message.numericValue !== undefined) {
      writer.uint32(49).double(message.numericValue);
    }
    if (message.stringValue !== undefined) {
      writer.uint32(58).string(message.stringValue);
    }
    if (message.name !== undefined) {
      writer.uint32(66).string(message.name);
    }
    for (const v of message.checks) {
      DynaformFormDocumentValueCheck.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformFormDocumentValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformFormDocumentValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base = DynaformBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.document = DynaformFormDocument.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.documentId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.field = DynaformFormVersionField.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fieldId = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.numericValue = reader.double();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.stringValue = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.name = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.checks.push(DynaformFormDocumentValueCheck.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformFormDocumentValue {
    return {
      base: isSet(object.base) ? DynaformBaseModel.fromJSON(object.base) : undefined,
      document: isSet(object.document) ? DynaformFormDocument.fromJSON(object.document) : undefined,
      documentId: isSet(object.documentId) ? String(object.documentId) : undefined,
      field: isSet(object.field) ? DynaformFormVersionField.fromJSON(object.field) : undefined,
      fieldId: isSet(object.fieldId) ? String(object.fieldId) : undefined,
      numericValue: isSet(object.numericValue) ? Number(object.numericValue) : undefined,
      stringValue: isSet(object.stringValue) ? String(object.stringValue) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      checks: Array.isArray(object?.checks)
        ? object.checks.map((e: any) => DynaformFormDocumentValueCheck.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DynaformFormDocumentValue): unknown {
    const obj: any = {};
    message.base !== undefined && (obj.base = message.base ? DynaformBaseModel.toJSON(message.base) : undefined);
    message.document !== undefined &&
      (obj.document = message.document ? DynaformFormDocument.toJSON(message.document) : undefined);
    message.documentId !== undefined && (obj.documentId = message.documentId);
    message.field !== undefined &&
      (obj.field = message.field ? DynaformFormVersionField.toJSON(message.field) : undefined);
    message.fieldId !== undefined && (obj.fieldId = message.fieldId);
    message.numericValue !== undefined && (obj.numericValue = message.numericValue);
    message.stringValue !== undefined && (obj.stringValue = message.stringValue);
    message.name !== undefined && (obj.name = message.name);
    if (message.checks) {
      obj.checks = message.checks.map((e) => e ? DynaformFormDocumentValueCheck.toJSON(e) : undefined);
    } else {
      obj.checks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformFormDocumentValue>, I>>(base?: I): DynaformFormDocumentValue {
    return DynaformFormDocumentValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformFormDocumentValue>, I>>(object: I): DynaformFormDocumentValue {
    const message = createBaseDynaformFormDocumentValue();
    message.base = (object.base !== undefined && object.base !== null)
      ? DynaformBaseModel.fromPartial(object.base)
      : undefined;
    message.document = (object.document !== undefined && object.document !== null)
      ? DynaformFormDocument.fromPartial(object.document)
      : undefined;
    message.documentId = object.documentId ?? undefined;
    message.field = (object.field !== undefined && object.field !== null)
      ? DynaformFormVersionField.fromPartial(object.field)
      : undefined;
    message.fieldId = object.fieldId ?? undefined;
    message.numericValue = object.numericValue ?? undefined;
    message.stringValue = object.stringValue ?? undefined;
    message.name = object.name ?? undefined;
    message.checks = object.checks?.map((e) => DynaformFormDocumentValueCheck.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDynaformFormDocumentValueCheck(): DynaformFormDocumentValueCheck {
  return {
    base: undefined,
    formDocumentValue: undefined,
    formDocumentValueId: undefined,
    name: undefined,
    value: undefined,
  };
}

export const DynaformFormDocumentValueCheck = {
  encode(message: DynaformFormDocumentValueCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base !== undefined) {
      DynaformBaseModel.encode(message.base, writer.uint32(10).fork()).ldelim();
    }
    if (message.formDocumentValue !== undefined) {
      DynaformFormDocumentValue.encode(message.formDocumentValue, writer.uint32(18).fork()).ldelim();
    }
    if (message.formDocumentValueId !== undefined) {
      writer.uint32(24).uint64(message.formDocumentValueId);
    }
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    if (message.value !== undefined) {
      writer.uint32(40).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynaformFormDocumentValueCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynaformFormDocumentValueCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base = DynaformBaseModel.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.formDocumentValue = DynaformFormDocumentValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.formDocumentValueId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynaformFormDocumentValueCheck {
    return {
      base: isSet(object.base) ? DynaformBaseModel.fromJSON(object.base) : undefined,
      formDocumentValue: isSet(object.formDocumentValue)
        ? DynaformFormDocumentValue.fromJSON(object.formDocumentValue)
        : undefined,
      formDocumentValueId: isSet(object.formDocumentValueId) ? String(object.formDocumentValueId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      value: isSet(object.value) ? Boolean(object.value) : undefined,
    };
  },

  toJSON(message: DynaformFormDocumentValueCheck): unknown {
    const obj: any = {};
    message.base !== undefined && (obj.base = message.base ? DynaformBaseModel.toJSON(message.base) : undefined);
    message.formDocumentValue !== undefined && (obj.formDocumentValue = message.formDocumentValue
      ? DynaformFormDocumentValue.toJSON(message.formDocumentValue)
      : undefined);
    message.formDocumentValueId !== undefined && (obj.formDocumentValueId = message.formDocumentValueId);
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<DynaformFormDocumentValueCheck>, I>>(base?: I): DynaformFormDocumentValueCheck {
    return DynaformFormDocumentValueCheck.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynaformFormDocumentValueCheck>, I>>(
    object: I,
  ): DynaformFormDocumentValueCheck {
    const message = createBaseDynaformFormDocumentValueCheck();
    message.base = (object.base !== undefined && object.base !== null)
      ? DynaformBaseModel.fromPartial(object.base)
      : undefined;
    message.formDocumentValue = (object.formDocumentValue !== undefined && object.formDocumentValue !== null)
      ? DynaformFormDocumentValue.fromPartial(object.formDocumentValue)
      : undefined;
    message.formDocumentValueId = object.formDocumentValueId ?? undefined;
    message.name = object.name ?? undefined;
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseCrmPOOutstandingInvoice(): CrmPOOutstandingInvoice {
  return { id: "0", invoiceNumber: "", percentage: 0, invoiceDate: [], type: "" };
}

export const CrmPOOutstandingInvoice = {
  encode(message: CrmPOOutstandingInvoice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.invoiceNumber !== "") {
      writer.uint32(18).string(message.invoiceNumber);
    }
    if (message.percentage !== 0) {
      writer.uint32(25).double(message.percentage);
    }
    writer.uint32(34).fork();
    for (const v of message.invoiceDate) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.type !== "") {
      writer.uint32(42).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPOOutstandingInvoice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPOOutstandingInvoice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.invoiceNumber = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.percentage = reader.double();
          continue;
        case 4:
          if (tag === 32) {
            message.invoiceDate.push(reader.int32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.invoiceDate.push(reader.int32());
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPOOutstandingInvoice {
    return {
      id: isSet(object.id) ? String(object.id) : "0",
      invoiceNumber: isSet(object.invoiceNumber) ? String(object.invoiceNumber) : "",
      percentage: isSet(object.percentage) ? Number(object.percentage) : 0,
      invoiceDate: Array.isArray(object?.invoiceDate) ? object.invoiceDate.map((e: any) => Number(e)) : [],
      type: isSet(object.type) ? String(object.type) : "",
    };
  },

  toJSON(message: CrmPOOutstandingInvoice): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.invoiceNumber !== undefined && (obj.invoiceNumber = message.invoiceNumber);
    message.percentage !== undefined && (obj.percentage = message.percentage);
    if (message.invoiceDate) {
      obj.invoiceDate = message.invoiceDate.map((e) => Math.round(e));
    } else {
      obj.invoiceDate = [];
    }
    message.type !== undefined && (obj.type = message.type);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPOOutstandingInvoice>, I>>(base?: I): CrmPOOutstandingInvoice {
    return CrmPOOutstandingInvoice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPOOutstandingInvoice>, I>>(object: I): CrmPOOutstandingInvoice {
    const message = createBaseCrmPOOutstandingInvoice();
    message.id = object.id ?? "0";
    message.invoiceNumber = object.invoiceNumber ?? "";
    message.percentage = object.percentage ?? 0;
    message.invoiceDate = object.invoiceDate?.map((e) => e) || [];
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseCrmPOOutstanding(): CrmPOOutstanding {
  return {
    id: "0",
    poNumber: "",
    receivedDate: "",
    poDate: "",
    poDueDate: "",
    sites: [],
    customerName: "",
    poType: "",
    invoices: [],
    dos: [],
    paymentTerm: undefined,
  };
}

export const CrmPOOutstanding = {
  encode(message: CrmPOOutstanding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.poNumber !== "") {
      writer.uint32(18).string(message.poNumber);
    }
    if (message.receivedDate !== "") {
      writer.uint32(26).string(message.receivedDate);
    }
    if (message.poDate !== "") {
      writer.uint32(34).string(message.poDate);
    }
    if (message.poDueDate !== "") {
      writer.uint32(42).string(message.poDueDate);
    }
    for (const v of message.sites) {
      writer.uint32(50).string(v!);
    }
    if (message.customerName !== "") {
      writer.uint32(58).string(message.customerName);
    }
    if (message.poType !== "") {
      writer.uint32(66).string(message.poType);
    }
    for (const v of message.invoices) {
      CrmPOOutstandingInvoice.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.dos) {
      CrmPOOutstandingDO.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.paymentTerm !== undefined) {
      writer.uint32(90).string(message.paymentTerm);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPOOutstanding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPOOutstanding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.poNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.receivedDate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.poDate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.poDueDate = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sites.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.customerName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.poType = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.invoices.push(CrmPOOutstandingInvoice.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.dos.push(CrmPOOutstandingDO.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.paymentTerm = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPOOutstanding {
    return {
      id: isSet(object.id) ? String(object.id) : "0",
      poNumber: isSet(object.poNumber) ? String(object.poNumber) : "",
      receivedDate: isSet(object.receivedDate) ? String(object.receivedDate) : "",
      poDate: isSet(object.poDate) ? String(object.poDate) : "",
      poDueDate: isSet(object.poDueDate) ? String(object.poDueDate) : "",
      sites: Array.isArray(object?.sites) ? object.sites.map((e: any) => String(e)) : [],
      customerName: isSet(object.customerName) ? String(object.customerName) : "",
      poType: isSet(object.poType) ? String(object.poType) : "",
      invoices: Array.isArray(object?.invoices)
        ? object.invoices.map((e: any) => CrmPOOutstandingInvoice.fromJSON(e))
        : [],
      dos: Array.isArray(object?.dos) ? object.dos.map((e: any) => CrmPOOutstandingDO.fromJSON(e)) : [],
      paymentTerm: isSet(object.paymentTerm) ? String(object.paymentTerm) : undefined,
    };
  },

  toJSON(message: CrmPOOutstanding): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.poNumber !== undefined && (obj.poNumber = message.poNumber);
    message.receivedDate !== undefined && (obj.receivedDate = message.receivedDate);
    message.poDate !== undefined && (obj.poDate = message.poDate);
    message.poDueDate !== undefined && (obj.poDueDate = message.poDueDate);
    if (message.sites) {
      obj.sites = message.sites.map((e) => e);
    } else {
      obj.sites = [];
    }
    message.customerName !== undefined && (obj.customerName = message.customerName);
    message.poType !== undefined && (obj.poType = message.poType);
    if (message.invoices) {
      obj.invoices = message.invoices.map((e) => e ? CrmPOOutstandingInvoice.toJSON(e) : undefined);
    } else {
      obj.invoices = [];
    }
    if (message.dos) {
      obj.dos = message.dos.map((e) => e ? CrmPOOutstandingDO.toJSON(e) : undefined);
    } else {
      obj.dos = [];
    }
    message.paymentTerm !== undefined && (obj.paymentTerm = message.paymentTerm);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPOOutstanding>, I>>(base?: I): CrmPOOutstanding {
    return CrmPOOutstanding.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPOOutstanding>, I>>(object: I): CrmPOOutstanding {
    const message = createBaseCrmPOOutstanding();
    message.id = object.id ?? "0";
    message.poNumber = object.poNumber ?? "";
    message.receivedDate = object.receivedDate ?? "";
    message.poDate = object.poDate ?? "";
    message.poDueDate = object.poDueDate ?? "";
    message.sites = object.sites?.map((e) => e) || [];
    message.customerName = object.customerName ?? "";
    message.poType = object.poType ?? "";
    message.invoices = object.invoices?.map((e) => CrmPOOutstandingInvoice.fromPartial(e)) || [];
    message.dos = object.dos?.map((e) => CrmPOOutstandingDO.fromPartial(e)) || [];
    message.paymentTerm = object.paymentTerm ?? undefined;
    return message;
  },
};

function createBaseCrmPOOutstandings(): CrmPOOutstandings {
  return { pos: [] };
}

export const CrmPOOutstandings = {
  encode(message: CrmPOOutstandings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.pos) {
      CrmPOOutstanding.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPOOutstandings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPOOutstandings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pos.push(CrmPOOutstanding.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPOOutstandings {
    return { pos: Array.isArray(object?.pos) ? object.pos.map((e: any) => CrmPOOutstanding.fromJSON(e)) : [] };
  },

  toJSON(message: CrmPOOutstandings): unknown {
    const obj: any = {};
    if (message.pos) {
      obj.pos = message.pos.map((e) => e ? CrmPOOutstanding.toJSON(e) : undefined);
    } else {
      obj.pos = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPOOutstandings>, I>>(base?: I): CrmPOOutstandings {
    return CrmPOOutstandings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPOOutstandings>, I>>(object: I): CrmPOOutstandings {
    const message = createBaseCrmPOOutstandings();
    message.pos = object.pos?.map((e) => CrmPOOutstanding.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmPOOutstandingDO(): CrmPOOutstandingDO {
  return { id: undefined, doName: undefined, refDo: undefined, date: undefined, products: [] };
}

export const CrmPOOutstandingDO = {
  encode(message: CrmPOOutstandingDO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.doName !== undefined) {
      writer.uint32(18).string(message.doName);
    }
    if (message.refDo !== undefined) {
      writer.uint32(26).string(message.refDo);
    }
    if (message.date !== undefined) {
      writer.uint32(34).string(message.date);
    }
    for (const v of message.products) {
      CrmPOOutstandingDOProduct.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPOOutstandingDO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPOOutstandingDO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.doName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.refDo = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.date = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.products.push(CrmPOOutstandingDOProduct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPOOutstandingDO {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      doName: isSet(object.doName) ? String(object.doName) : undefined,
      refDo: isSet(object.refDo) ? String(object.refDo) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      products: Array.isArray(object?.products)
        ? object.products.map((e: any) => CrmPOOutstandingDOProduct.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CrmPOOutstandingDO): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.doName !== undefined && (obj.doName = message.doName);
    message.refDo !== undefined && (obj.refDo = message.refDo);
    message.date !== undefined && (obj.date = message.date);
    if (message.products) {
      obj.products = message.products.map((e) => e ? CrmPOOutstandingDOProduct.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPOOutstandingDO>, I>>(base?: I): CrmPOOutstandingDO {
    return CrmPOOutstandingDO.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPOOutstandingDO>, I>>(object: I): CrmPOOutstandingDO {
    const message = createBaseCrmPOOutstandingDO();
    message.id = object.id ?? undefined;
    message.doName = object.doName ?? undefined;
    message.refDo = object.refDo ?? undefined;
    message.date = object.date ?? undefined;
    message.products = object.products?.map((e) => CrmPOOutstandingDOProduct.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCrmPOOutstandingDOProduct(): CrmPOOutstandingDOProduct {
  return { productId: undefined, qty: undefined };
}

export const CrmPOOutstandingDOProduct = {
  encode(message: CrmPOOutstandingDOProduct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productId !== undefined) {
      writer.uint32(8).uint64(message.productId);
    }
    if (message.qty !== undefined) {
      writer.uint32(17).double(message.qty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrmPOOutstandingDOProduct {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrmPOOutstandingDOProduct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.productId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.qty = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrmPOOutstandingDOProduct {
    return {
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      qty: isSet(object.qty) ? Number(object.qty) : undefined,
    };
  },

  toJSON(message: CrmPOOutstandingDOProduct): unknown {
    const obj: any = {};
    message.productId !== undefined && (obj.productId = message.productId);
    message.qty !== undefined && (obj.qty = message.qty);
    return obj;
  },

  create<I extends Exact<DeepPartial<CrmPOOutstandingDOProduct>, I>>(base?: I): CrmPOOutstandingDOProduct {
    return CrmPOOutstandingDOProduct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrmPOOutstandingDOProduct>, I>>(object: I): CrmPOOutstandingDOProduct {
    const message = createBaseCrmPOOutstandingDOProduct();
    message.productId = object.productId ?? undefined;
    message.qty = object.qty ?? undefined;
    return message;
  },
};

/** 50052 */
export interface AuthserverGoService {
  Login(request: AuthserverGoUserLogin): Promise<AuthserverGoTokenReturn>;
  GetUsers(request: AuthserverEmpty): Promise<AuthserverUsers>;
  GetUser(request: GetUserPostBody): Promise<AuthserverUser>;
  PostUsers(request: AuthserverUsers): Promise<AuthserverEmpty>;
  GetRoles(request: AuthserverEmpty): Promise<AuthserverRoles>;
  GetDepartments(request: AuthserverEmpty): Promise<AuthserverDepartments>;
  UpdateFcmToken(request: UpdateFcmTokenBody): Promise<AuthserverEmpty>;
}

export class AuthserverGoServiceClientImpl implements AuthserverGoService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "AuthserverGoService";
    this.rpc = rpc;
    this.Login = this.Login.bind(this);
    this.GetUsers = this.GetUsers.bind(this);
    this.GetUser = this.GetUser.bind(this);
    this.PostUsers = this.PostUsers.bind(this);
    this.GetRoles = this.GetRoles.bind(this);
    this.GetDepartments = this.GetDepartments.bind(this);
    this.UpdateFcmToken = this.UpdateFcmToken.bind(this);
  }
  Login(request: AuthserverGoUserLogin): Promise<AuthserverGoTokenReturn> {
    const data = AuthserverGoUserLogin.encode(request).finish();
    const promise = this.rpc.request(this.service, "Login", data);
    return promise.then((data) => AuthserverGoTokenReturn.decode(_m0.Reader.create(data)));
  }

  GetUsers(request: AuthserverEmpty): Promise<AuthserverUsers> {
    const data = AuthserverEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetUsers", data);
    return promise.then((data) => AuthserverUsers.decode(_m0.Reader.create(data)));
  }

  GetUser(request: GetUserPostBody): Promise<AuthserverUser> {
    const data = GetUserPostBody.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetUser", data);
    return promise.then((data) => AuthserverUser.decode(_m0.Reader.create(data)));
  }

  PostUsers(request: AuthserverUsers): Promise<AuthserverEmpty> {
    const data = AuthserverUsers.encode(request).finish();
    const promise = this.rpc.request(this.service, "PostUsers", data);
    return promise.then((data) => AuthserverEmpty.decode(_m0.Reader.create(data)));
  }

  GetRoles(request: AuthserverEmpty): Promise<AuthserverRoles> {
    const data = AuthserverEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetRoles", data);
    return promise.then((data) => AuthserverRoles.decode(_m0.Reader.create(data)));
  }

  GetDepartments(request: AuthserverEmpty): Promise<AuthserverDepartments> {
    const data = AuthserverEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetDepartments", data);
    return promise.then((data) => AuthserverDepartments.decode(_m0.Reader.create(data)));
  }

  UpdateFcmToken(request: UpdateFcmTokenBody): Promise<AuthserverEmpty> {
    const data = UpdateFcmTokenBody.encode(request).finish();
    const promise = this.rpc.request(this.service, "UpdateFcmToken", data);
    return promise.then((data) => AuthserverEmpty.decode(_m0.Reader.create(data)));
  }
}

/** 50060 */
export interface AuthserverJavaService {
}

export class AuthserverJavaServiceClientImpl implements AuthserverJavaService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "AuthserverJavaService";
    this.rpc = rpc;
  }
}

/** 50061 */
export interface MeetingJavaService {
  GetMeetingTasks(request: AuthserverEmpty): Promise<MeetingMeetingTasks>;
  SaveExternalTask(request: MeetingMeetingTaskPostBody): Promise<MeetingMeetingTask>;
}

export class MeetingJavaServiceClientImpl implements MeetingJavaService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "MeetingJavaService";
    this.rpc = rpc;
    this.GetMeetingTasks = this.GetMeetingTasks.bind(this);
    this.SaveExternalTask = this.SaveExternalTask.bind(this);
  }
  GetMeetingTasks(request: AuthserverEmpty): Promise<MeetingMeetingTasks> {
    const data = AuthserverEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetMeetingTasks", data);
    return promise.then((data) => MeetingMeetingTasks.decode(_m0.Reader.create(data)));
  }

  SaveExternalTask(request: MeetingMeetingTaskPostBody): Promise<MeetingMeetingTask> {
    const data = MeetingMeetingTaskPostBody.encode(request).finish();
    const promise = this.rpc.request(this.service, "SaveExternalTask", data);
    return promise.then((data) => MeetingMeetingTask.decode(_m0.Reader.create(data)));
  }
}

/** 50062 */
export interface PpicService {
  GetJobs(request: MasterEmpty): Promise<PpicJobs>;
}

export class PpicServiceClientImpl implements PpicService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "PpicService";
    this.rpc = rpc;
    this.GetJobs = this.GetJobs.bind(this);
  }
  GetJobs(request: MasterEmpty): Promise<PpicJobs> {
    const data = MasterEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetJobs", data);
    return promise.then((data) => PpicJobs.decode(_m0.Reader.create(data)));
  }
}

export interface WarehouseService {
  /** /api/items */
  GetItems(request: MasterEmpty): Promise<WarehouseItems>;
  /** /api/save-material-request */
  SaveMaterialRequests(request: WarehouseMaterialRequestPostBody): Promise<WarehouseMaterialRequestPostBodyResponse>;
  /** /api/reserve-list */
  GetReserveList(request: MasterEmpty): Promise<WarehouseReserveLists>;
}

export class WarehouseServiceClientImpl implements WarehouseService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "WarehouseService";
    this.rpc = rpc;
    this.GetItems = this.GetItems.bind(this);
    this.SaveMaterialRequests = this.SaveMaterialRequests.bind(this);
    this.GetReserveList = this.GetReserveList.bind(this);
  }
  GetItems(request: MasterEmpty): Promise<WarehouseItems> {
    const data = MasterEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetItems", data);
    return promise.then((data) => WarehouseItems.decode(_m0.Reader.create(data)));
  }

  SaveMaterialRequests(request: WarehouseMaterialRequestPostBody): Promise<WarehouseMaterialRequestPostBodyResponse> {
    const data = WarehouseMaterialRequestPostBody.encode(request).finish();
    const promise = this.rpc.request(this.service, "SaveMaterialRequests", data);
    return promise.then((data) => WarehouseMaterialRequestPostBodyResponse.decode(_m0.Reader.create(data)));
  }

  GetReserveList(request: MasterEmpty): Promise<WarehouseReserveLists> {
    const data = MasterEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetReserveList", data);
    return promise.then((data) => WarehouseReserveLists.decode(_m0.Reader.create(data)));
  }
}

export interface PurchasingService {
  /** /api/save-pr */
  SendPurchaseRequest(request: PurchasingPurchaseRequestPostBody): Promise<MasterEmpty>;
  /** /api/pr-data */
  GetPurchaserRequests(request: MasterEmpty): Promise<PurchasingPurchaseRequests>;
}

export class PurchasingServiceClientImpl implements PurchasingService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "PurchasingService";
    this.rpc = rpc;
    this.SendPurchaseRequest = this.SendPurchaseRequest.bind(this);
    this.GetPurchaserRequests = this.GetPurchaserRequests.bind(this);
  }
  SendPurchaseRequest(request: PurchasingPurchaseRequestPostBody): Promise<MasterEmpty> {
    const data = PurchasingPurchaseRequestPostBody.encode(request).finish();
    const promise = this.rpc.request(this.service, "SendPurchaseRequest", data);
    return promise.then((data) => MasterEmpty.decode(_m0.Reader.create(data)));
  }

  GetPurchaserRequests(request: MasterEmpty): Promise<PurchasingPurchaseRequests> {
    const data = MasterEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetPurchaserRequests", data);
    return promise.then((data) => PurchasingPurchaseRequests.decode(_m0.Reader.create(data)));
  }
}

export interface CrmService {
  /** /accounts/dropdown/customer?id=1&role=Admin */
  GetCustomers(request: MasterEmpty): Promise<CrmCustomers>;
  /** /api/external/purchase-orders */
  GetPurchaseOrders(request: MasterEmpty): Promise<CrmPurchaseOrders>;
  /** /api/external/purchase-order/{po_id} */
  GetPurchaseOrder(request: CrmPurchaseOrderIdRequest): Promise<CrmPurchaseOrder>;
}

export class CrmServiceClientImpl implements CrmService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "CrmService";
    this.rpc = rpc;
    this.GetCustomers = this.GetCustomers.bind(this);
    this.GetPurchaseOrders = this.GetPurchaseOrders.bind(this);
    this.GetPurchaseOrder = this.GetPurchaseOrder.bind(this);
  }
  GetCustomers(request: MasterEmpty): Promise<CrmCustomers> {
    const data = MasterEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetCustomers", data);
    return promise.then((data) => CrmCustomers.decode(_m0.Reader.create(data)));
  }

  GetPurchaseOrders(request: MasterEmpty): Promise<CrmPurchaseOrders> {
    const data = MasterEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetPurchaseOrders", data);
    return promise.then((data) => CrmPurchaseOrders.decode(_m0.Reader.create(data)));
  }

  GetPurchaseOrder(request: CrmPurchaseOrderIdRequest): Promise<CrmPurchaseOrder> {
    const data = CrmPurchaseOrderIdRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetPurchaseOrder", data);
    return promise.then((data) => CrmPurchaseOrder.decode(_m0.Reader.create(data)));
  }
}

export interface HrService {
  /** /HR/api/emp-list */
  GetEmployees(request: MasterEmpty): Promise<HrEmployees>;
}

export class HrServiceClientImpl implements HrService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "HrService";
    this.rpc = rpc;
    this.GetEmployees = this.GetEmployees.bind(this);
  }
  GetEmployees(request: MasterEmpty): Promise<HrEmployees> {
    const data = MasterEmpty.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetEmployees", data);
    return promise.then((data) => HrEmployees.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
