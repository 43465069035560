import { useEffect, useRef, useState } from "react";
import {
  AuthserverDepartmentGroup,
  AuthserverDepartmentGroups,
} from "../../masterbigsystem";
import {
  fetchDepartmentGroupsProto,
  fetchDepartments,
} from "../../helpers/fetchers";
import { Link } from "react-router-dom";
import { useAppState } from "../../components/AppProvider/AppProvider";

const DepartmentGroupsPage = () => {
  const deptGroups = useRef(
    AuthserverDepartmentGroups.fromPartial({ groups: [] })
  );

  const [ctx, dispatch] = useAppState() ?? [];
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };

  const init = async () => {
    const d = await fetchDepartmentGroupsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    deptGroups.current = d;

    render();
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <div className="m-3">
        <div>
          <div>
            <div>
              <h4>Department Group</h4>
            </div>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <Link to={`/departmentgroups/new`}>
            <button className="btn btn-sm btn-primary">Add</button>
          </Link>
        </div>
        <div>
          <div
            className="overflow-auto border border-dark"
            style={{ height: "75vh", resize: "vertical" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {["#", "Name", "Depts", "Action"].map((h) => {
                  return (
                    <>
                      <th
                        className="bg-dark text-light"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  );
                })}
              </tr>
              {deptGroups.current.groups.map(
                (g: AuthserverDepartmentGroup, i) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">{g.name}</td>
                        <td className="border border-dark">
                          <div>
                            <ol>
                              {g.departments.map((d) => {
                                return (
                                  <>
                                    <li>
                                      <div>{d.name}</div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </div>
                        </td>
                        <td className="border border-dark">
                          <Link
                            to={`/departmentgroups/${g.masterJavaBaseModel?.id}`}
                          >
                            <button className="btn btn-sm btn-primary px-1 py-0">
                              Edit
                            </button>
                          </Link>
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentGroupsPage;
