// Pages
import {
  AppBar,
  Autocomplete,
  Avatars,
  BackendError,
  Badges,
  Blank,
  ButtonNavigation,
  Buttons,
  Calendar,
  Cards,
  Charts,
  Chat,
  Chips,
  Detail,
  Dialogs,
  Dividers,
  Drawers,
  Editor,
  ExpansionPanels,
  Google,
  GridList,
  Home,
  Invoice,
  Leaflet,
  Lists,
  Lockscreen,
  Media,
  Menus,
  Messages,
  NotFound,
  Paper,
  PasswordReset,
  Pickers,
  PricingPage,
  Products,
  Progress,
  SelectionControls,
  Selects,
  Signin,
  Signup,
  Snackbars,
  Social,
  Steppers,
  Tables,
  Tabs,
  Taskboard,
  TextFields,
  TimelinePage,
  Tooltips,
  Widgets,
} from "./pages";

import AppsIcon from "@material-ui/icons/Apps";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import EqualizerIcon from "@material-ui/icons/Equalizer";
// Icons
import ExploreIcon from "@material-ui/icons/Explore";
import GroupIcon from "@material-ui/icons/Group";

import DomainIcon from "@material-ui/icons/Domain";
import FaceIcon from "@material-ui/icons/Face";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import NavigationIcon from "@material-ui/icons/Navigation";
import PagesIcon from "@material-ui/icons/Pages";
import PersonIcon from "@material-ui/icons/Person";
import PhotoIcon from "@material-ui/icons/Photo";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import {
  AccessAlarm,
  Apps,
  Assignment,
  AttachMoney,
  CalendarToday,
  Domain,
  DomainOutlined,
  DomainRounded,
  DomainSharp,
  FileCopy,
  Group,
  List,
  Money,
  MoneyOffOutlined,
  MoneyRounded,
  MoneySharp,
} from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";
import Users from "./pages/Users/Users";
import UserDetail from "./pages/Users/UserDetail";
import AppsPage from "./pages/Apps/AppsPage";
import AppsDetail from "./pages/Apps/AppsDetail";
import Roles from "./pages/Roles/Roles";
import Departments from "./pages/Departments/Departments";
import Hierarchy from "./pages/Hierarchy/Hierarchy";
import HierarchyV2Page from "./pages/Hierarchy/HierarchyV2Page";
import WhatsappPage from "./pages/WhatsappPage/WhatsappPage";
import EmployeeShiftPage from "./pages/EmployeeShift/EmployeeShiftPage";
import EmployeeShiftTemplatePage from "./pages/EmployeeShift/EmployeeShiftTemplatePage";
import Pph21Category from "./pages/Pph21Category/Pph21Category";
import Pph21CategoryPage from "./pages/Pph21Category/Pph21Category";
import CompanyPage from "./pages/CompanyPage/CompanyPage";
import AttendancePage from "./pages/AttendancePage/AttendancePage";
import DepartmentGroupsPage from "./pages/Departments/DepartmentGroups";
import DepartmentGroupDetail from "./pages/Departments/DepartmentGroupDetail";

export default {
  items: [
    {
      path: "/",
      name: "Users",
      type: "link",
      icon: Group,
      component: Users,
      children: [],
      show: true,
    },
    {
      path: "/users/:id",
      name: "User Detail",
      type: "link",
      icon: Group,
      component: UserDetail,
      children: [],
      show: false,
    },
    {
      path: "/apps",
      name: "Apps",
      type: "link",
      icon: Apps,
      component: AppsPage,
      children: [],
      show: true,
    },
    {
      path: "/apps/:id",
      name: "Apps Detail",
      type: "link",
      icon: Apps,
      component: AppsDetail,
      children: [],
      show: false,
    },

    {
      path: "/departments",
      name: "Departments",
      type: "link",
      icon: Domain,
      component: Departments,
      children: [],
      show: true,
    },
    {
      path: "/departmentgroups",
      name: "Department Groups",
      type: "link",
      icon: Domain,
      component: DepartmentGroupsPage,
      children: [],
      show: true,
    },
    {
      path: "/departmentgroups/:id",
      name: "Department Group Detail",
      type: "link",
      icon: Domain,
      component: DepartmentGroupDetail,
      children: [],
      show: false,
    },
    {
      path: "/roles",
      name: "Roles",
      type: "link",
      icon: Assignment,
      component: Roles,
      children: [],
      show: true,
    },
    {
      path: "/hierarchy",
      name: "Hierarchy",
      type: "link",
      icon: Assignment,
      component: Hierarchy,
      children: [],
      show: true,
    },
    {
      path: "/hierarchyv2",
      name: "Hierarchy v2",
      type: "link",
      icon: Assignment,
      component: HierarchyV2Page,
      children: [],
      show: true,
    },
    {
      path: "/shift",
      name: "Employee Shift",
      type: "link",
      icon: CalendarToday,
      component: EmployeeShiftPage,
      children: [],
      show: true,
    },
    {
      path: "/shift-template",
      name: "Employee Shift Template",
      type: "link",
      icon: FileCopy,
      component: EmployeeShiftTemplatePage,
      children: [],
      show: true,
    },
    {
      path: "/companies",
      name: "Companies",
      type: "link",
      icon: DomainOutlined,
      component: CompanyPage,
      children: [],
      show: true,
    },
    {
      path: "/pph-21",
      name: "PPH 21 Categories",
      type: "link",
      icon: AttachMoney,
      component: Pph21CategoryPage,
      children: [],
      show: true,
    },
    {
      path: "/attendance",
      name: "Attendance",
      type: "link",
      icon: AccessAlarm,
      component: AttendancePage,
      children: [],
      show: true,
    },

    // {
    //   path: "/whatsapp",
    //   name: "WhatsApp",
    //   type: "link",
    //   icon: Assignment,
    //   component: WhatsappPage,
    //   children: [],
    //   show: true,
    // },

    // {
    //   path: "/tables",
    //   name: "Tables",
    //   type: "link",
    //   icon: List,
    //   component: Tables,
    //   children: [],
    //   show: true,
    // },
    // {
    //   path: "/apps",
    //   name: "Apps",
    //   type: "submenu",
    //   icon: AppsIcon,
    //   badge: {
    //     type: "primary",
    //     value: "5",
    //   },
    //   children: [
    //     {
    //       path: "/calendar",
    //       name: "Calendar",
    //       component: Calendar,
    //     },
    //     {
    //       path: "/media",
    //       name: "Media",
    //       component: Media,
    //     },
    //     {
    //       path: "/messages",
    //       name: "Messages",
    //       component: Messages,
    //     },
    //     {
    //       path: "/social",
    //       name: "Social",
    //       component: Social,
    //     },
    //     {
    //       path: "/chat",
    //       name: "Chat",
    //       component: Chat,
    //     },
    //   ],
    // },
    // {
    //   path: "/widgets",
    //   name: "Widgets",
    //   type: "link",
    //   icon: PhotoIcon,
    //   component: Widgets,
    // },
    // {
    //   path: "/material",
    //   name: "Material",
    //   type: "submenu",
    //   icon: EqualizerIcon,
    //   badge: {
    //     type: "error",
    //     value: "10",
    //   },
    //   children: [
    //     {
    //       path: "/appbar",
    //       name: "App Bar",
    //       component: AppBar,
    //     },
    //     {
    //       path: "/autocomplete",
    //       name: "Auto Complete",
    //       component: Autocomplete,
    //     },
    //     {
    //       path: "/avatars",
    //       name: "Avatars",
    //       component: Avatars,
    //     },
    //     {
    //       path: "/badges",
    //       name: "Badges",
    //       component: Badges,
    //     },
    //     {
    //       path: "/button-navigation",
    //       name: "Button Navigation",
    //       component: ButtonNavigation,
    //     },
    //     {
    //       path: "/buttons",
    //       name: "Buttons",
    //       component: Buttons,
    //     },
    //     {
    //       path: "/cards",
    //       name: "Cards",
    //       component: Cards,
    //     },
    //     {
    //       path: "/chips",
    //       name: "Chips",
    //       component: Chips,
    //     },
    //     {
    //       path: "/dialogs",
    //       name: "Dialogs",
    //       component: Dialogs,
    //     },
    //     {
    //       path: "/dividers",
    //       name: "Dividers",
    //       component: Dividers,
    //     },
    //     {
    //       path: "/drawers",
    //       name: "Drawers",
    //       component: Drawers,
    //     },
    //     {
    //       path: "/expansion-panels",
    //       name: "Expansion Panels",
    //       component: ExpansionPanels,
    //     },
    //     {
    //       path: "/grid-list",
    //       name: "Grid List",
    //       component: GridList,
    //     },
    //     {
    //       path: "/lists",
    //       name: "Lists",
    //       component: Lists,
    //     },
    //     {
    //       path: "/menus",
    //       name: "Menus",
    //       component: Menus,
    //     },
    //     {
    //       path: "/paper",
    //       name: "Paper",
    //       component: Paper,
    //     },
    //     {
    //       path: "/pickers",
    //       name: "Pickers",
    //       component: Pickers,
    //     },
    //     {
    //       path: "/progress",
    //       name: "Progress",
    //       component: Progress,
    //     },
    //     {
    //       path: "/selection-controls",
    //       name: "Selection Controls",
    //       component: SelectionControls,
    //     },
    //     {
    //       path: "/selects",
    //       name: "Selects",
    //       component: Selects,
    //     },
    //     {
    //       path: "/snackbars",
    //       name: "Snackbars",
    //       component: Snackbars,
    //     },
    //     {
    //       path: "/steppers",
    //       name: "Steppers",
    //       component: Steppers,
    //     },
    //     {
    //       path: "/tables",
    //       name: "Tables",
    //       component: Tables,
    //     },
    //     {
    //       path: "/tabs",
    //       name: "Tabs",
    //       component: Tabs,
    //     },
    //     {
    //       path: "/text-fields",
    //       name: "Text Fields",
    //       component: TextFields,
    //     },
    //     {
    //       path: "/tooltips",
    //       name: "Tooltips",
    //       component: Tooltips,
    //     },
    //   ],
    // },
    // {
    //   path: "/editor",
    //   name: "Editor",
    //   type: "link",
    //   icon: Looks3Icon,
    //   component: Editor,
    // },
    // {
    //   path: "/ecommerce",
    //   name: "Ecommerce",
    //   type: "submenu",
    //   icon: Looks4Icon,
    //   badge: {
    //     type: "secondary",
    //     value: "N",
    //   },
    //   children: [
    //     {
    //       path: "/products",
    //       name: "Products",
    //       component: Products,
    //     },
    //     {
    //       path: "/detail",
    //       name: "Detail",
    //       component: Detail,
    //     },
    //   ],
    // },
    // {
    //   path: "/taskboard",
    //   name: "Taskboard",
    //   type: "link",
    //   icon: ViewColumnIcon,
    //   component: Taskboard,
    // },
    // {
    //   path: "/charts",
    //   name: "Charts",
    //   type: "link",
    //   icon: ShowChartIcon,
    //   component: Charts,
    // },
    // {
    //   path: "/maps",
    //   name: "Maps",
    //   type: "submenu",
    //   icon: NavigationIcon,
    //   children: [
    //     {
    //       path: "/google",
    //       name: "Google",
    //       component: Google,
    //     },
    //     {
    //       path: "/leaflet",
    //       name: "Leaflet",
    //       component: Leaflet,
    //     },
    //   ],
    // },
    // {
    //   path: "/pages",
    //   name: "Pages",
    //   type: "submenu",
    //   icon: PagesIcon,
    //   children: [
    //     {
    //       path: "/invoice",
    //       name: "Invoice",
    //       component: Invoice,
    //     },
    //     {
    //       path: "/timeline",
    //       name: "Timeline",
    //       component: TimelinePage,
    //     },
    //     {
    //       path: "/blank",
    //       name: "Blank",
    //       component: Blank,
    //     },
    //     {
    //       path: "/pricing",
    //       name: "Pricing",
    //       component: PricingPage,
    //     },
    //   ],
    // },
    // {
    //   name: "Authentication",
    //   type: "submenu",
    //   icon: PersonIcon,
    //   children: [
    //     {
    //       path: "/signin",
    //       name: "Signin",
    //       component: Signin,
    //     },
    //     {
    //       path: "/signup",
    //       name: "Signup",
    //       component: Signup,
    //     },
    //     {
    //       path: "/forgot",
    //       name: "Forgot",
    //       component: PasswordReset,
    //     },
    //     {
    //       path: "/lockscreen",
    //       name: "Lockscreen",
    //       component: Lockscreen,
    //     },
    //   ],
    // },
    // {
    //   name: "Error",
    //   type: "submenu",
    //   icon: FaceIcon,
    //   children: [
    //     {
    //       path: "/404",
    //       name: "404",
    //       component: NotFound,
    //     },
    //     {
    //       path: "/500",
    //       name: "Error",
    //       component: BackendError,
    //     },
    //   ],
    // },
    // {
    //   path: "https://iamnyasha.github.io/react-primer-docs/",
    //   name: "Documentation",
    //   type: "external",
    //   icon: LocalLibraryIcon,
    // },
    // {
    //   path: "https://primer.fusepx.com",
    //   name: "Get Angular Version",
    //   type: "external",
    //   icon: BookmarkIcon,
    // },
  ],
};
