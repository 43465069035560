import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { initialDepartment, initialUser } from "../../helpers/modelinitials";
import { blueTheme } from "../../helpers/themes";
import { User, UserPostBody } from "../../model";

interface UserHierarchy {
  user: User;
  subordinates: UserHierarchy[];
}

const Hierarchy = () => {
  const [ctx, dispatch] = useAppState() ?? [];

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const users = await fetchUsers();

    setUsers(users);
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${ctx?.baseUrl}/users/view`, {
        headers: { authorization: ctx?.apiKey ?? "" },
      });

      if (response.status !== 200) throw await response.text();

      return (await response.json()) as User[];
    } catch (e) {
      return [];
    }
  };

  const findUserInHierarchy = (
    hierarchy: UserHierarchy[],
    userId: number
  ): UserHierarchy | null => {
    let foundUser: UserHierarchy | null = null;

    const findUser = (mappedUser: UserHierarchy) => {
      if (mappedUser.user.id === userId) {
        foundUser = mappedUser;
      } else {
        mappedUser.subordinates.forEach((mappedUser) => {
          findUser(mappedUser);
        });
      }
    };

    hierarchy.forEach((mappedUser) => {
      findUser(mappedUser);
    });

    return foundUser;
  };

  const mappedUsers = (() => {
    let mappedUsersVar = users.map(
      (user) => ({ user: user, subordinates: [] } as UserHierarchy)
    );

    const mapUserToHierarchy = (userToAdd: UserHierarchy) => {
      if (userToAdd.user.reportTo) {
        const findUserAndAddSubordinate = (
          user: UserHierarchy,
          userToAdd: UserHierarchy
        ) => {
          if (user.user.id === userToAdd.user.reportTo?.id) {
            console.log(userToAdd.user.name, "reporting to", user.user.name);
            user.subordinates.push(userToAdd);

            const position = mappedUsersVar.findIndex(
              (mappedUser) => mappedUser.user.id === userToAdd.user.id
            );
            console.log(userToAdd.user.name, "position:", position);

            mappedUsersVar = mappedUsersVar.filter((_, i) => i !== position);
          }

          user.subordinates.forEach((mappedUser) => {
            findUserAndAddSubordinate(mappedUser, userToAdd);
          });
        };

        mappedUsersVar.forEach((mappedUser) => {
          findUserAndAddSubordinate(mappedUser, userToAdd);
        });
      } else {
        // mappedUsers.push(user);
      }
    };

    mappedUsersVar.forEach((mappedUser, i) => {
      console.log("User to map:", mappedUser.user.name, i);
      mapUserToHierarchy(mappedUser);
    });

    return mappedUsersVar;
  })();

  const handleChangeReportTo = (
    mappedUser: UserHierarchy,
    userId: number,
    level: number,
    index: number
  ) => {
    console.log("User ID:", userId);

    const foundUser = findUserInHierarchy(mappedUsers, userId);
    console.log("Found user:", foundUser);
    console.log("User to add subordinate:", mappedUser.user);
    console.log("Level:", level, ", index:", index);
    console.log(
      "Add:",
      mappedUser.user.name,
      "report to",
      foundUser?.user.name
    );

    setUsers(
      users.map((user) =>
        user.id === mappedUser.user.id
          ? { ...user, reportTo: userId === 0 ? null : foundUser?.user ?? null }
          : user
      )
    );
  };

  const flattenMappedUser = (
    mappedUser: UserHierarchy,
    level: number,
    index: number,
    ordering: number[]
  ) => {
    return (
      <Box>
        <Box display="flex" ml={level * 3}>
          <ChevronRight fontSize="small" /> ({ordering.join(".")}){" "}
          <strong className="mx-1">
            {mappedUser.user.name ?? "[NO NAME]"}
          </strong>{" "}
          |{" "}
          {ctx?.departments.find(
            (department) => department.id === mappedUser.user.departmentId
          )?.name ?? "[NO DEPT]"}{" "}
          | subordinates: {mappedUser.subordinates.length} | Report to:
          <Autocomplete
            style={{ minWidth: 400 }}
            id={`${mappedUser.user.id ?? Math.random()}`}
            options={[
              { ...initialUser, name: "None" } as User,
              ...users
                .filter(
                  (user) =>
                    !mappedUser.subordinates
                      .map((subordinate) => subordinate.user.id)
                      .includes(user.id)
                )
                .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
                .map((user) => user),
            ]}
            getOptionLabel={(user) => user.name ?? ""}
            value={null}
            renderInput={(params) => <TextField {...params} value={null} />}
            onChange={(e, user) =>
              handleChangeReportTo(mappedUser, user?.id ?? 0, level, index)
            }
          />
        </Box>
        {mappedUser.subordinates.map((mappedUser, i) =>
          flattenMappedUser(mappedUser, level + 1, i, [...ordering, i + 1])
        )}
      </Box>
    );
  };

  console.log("Users", users);
  console.log("Mapped users:", mappedUsers);

  const handleSave = async () => {
    try {
      const flattenedUsers = [] as User[];

      const flattenUser = (userHierarchy: UserHierarchy) => {
        flattenedUsers.push(userHierarchy.user);

        userHierarchy.subordinates.forEach((user) => {
          flattenUser(user);
        });
      };

      mappedUsers.forEach((user) => {
        flattenUser(user);
      });

      console.log("Mapped users:", flattenedUsers);

      const resp = await Promise.all(
        flattenedUsers.map(async (user) => {
          return await fetch(`${ctx?.baseUrl}/users/save`, {
            method: "post",
            headers: {
              authorization: ctx?.apiKey ?? "",
              "content-type": "application/json ",
            },
            body: JSON.stringify({
              user: {
                ...user,
                department:
                  user.departmentId === null || user.departmentId === 0
                    ? undefined
                    : { ...initialDepartment, id: user.departmentId },
              },
              changePassword: false,
            } as UserPostBody),
          });
        })
      );

      window.location.reload();
    } catch (e) {
      console.log("[handleSave] error", e);
    }
  };

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box m={3}>
          <Box display="flex">
            <Typography variant="h5">Hierarchy</Typography>
            <Box ml={2}>
              <Button
                onClick={handleSave}
                size="small"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </Box>

          <Box>
            {mappedUsers.map((mappedUser, i) => {
              return flattenMappedUser(mappedUser, 0, i, [i + 1]);
            })}
          </Box>
          <Box>
            <small>
              <pre>
                {JSON.stringify(
                  users.map(
                    (user) =>
                      `${user.name} | ${user.username} | report to: ${
                        user.reportTo?.name ?? "NONE"
                      }`
                  ),
                  null,
                  2
                )}
              </pre>
            </small>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Hierarchy;
