import { BaseModel } from "../model";

export default function filterNotHiddenAndSort<T extends BaseModel>(
  items: T[]
) {
  return items.filter((item) => !item.hidden).sort((a, b) => a.id - b.id);
}

export function filterNotHidden<T extends BaseModel>(items: T[]) {
  return items.filter((item) => !item.hidden);
}
