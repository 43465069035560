import React, { createContext, useContext, useEffect, useReducer } from "react";
import {
  StylesProvider,
  jssPreset,
  ThemeProviderProps,
} from "@material-ui/styles";

import { create } from "jss";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import rtl from "jss-rtl";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Department, Role } from "../../model";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

interface AppState {
  direction: "rtl" | "ltr" | undefined;
  type: "light" | "dark" | undefined;
  apiKey: string | null | undefined;
  baseUrl: string | null | undefined;
  loggedIn: boolean;
  departments: Department[];
  roles: Role[];
}

export interface Dispatch {
  type:
    | "direction"
    | "type"
    | "apiKey"
    | "baseUrl"
    | "loggedIn"
    | "departments"
    | "roles";
  data?: any;
}

const Context = createContext<[AppState, (dispatch: Dispatch) => void] | null>(
  null
);
const { Provider } = Context;

const reducer = (state: AppState, action: Dispatch) => {
  console.log("Actoin:", action);

  switch (action.type) {
    case "direction":
      return {
        ...state,
        direction: state.direction === "ltr" ? "rtl" : "ltr",
      } as AppState;

    case "type": {
      return {
        ...state,
        type: state.type === "light" ? "dark" : "light",
      } as AppState;
    }

    case "apiKey":
      return {
        ...state,
        apiKey: action.data,
      } as AppState;

    case "baseUrl":
      return {
        ...state,
        baseUrl: action.data,
      } as AppState;

    case "loggedIn":
      return {
        ...state,
        loggedIn: action.data,
      } as AppState;

    case "departments":
      return {
        ...state,
        departments: action.data,
      } as AppState;

    case "roles":
      return {
        ...state,
        roles: action.data,
      } as AppState;

    default:
      return state;
  }
};

const AppProvider = ({ children }: { children: any }) => {
  const prefersDarkMode = useMediaQuery("@media (prefers-color-scheme: dark)");
  const [state, dispatch] = useReducer(reducer, {
    type: prefersDarkMode ? "dark" : "light",
    direction: "ltr",
    apiKey: localStorage.getItem("apiKey") ?? null,
    baseUrl: process.env.REACT_APP_BASE_URL ?? null,
    loggedIn: false,
    departments: [],
    roles: [],
  });

  const theme = createMuiTheme({
    direction: state.direction,
    palette: {
      type: state.type,
      primary: indigo,
      secondary: red,
      error: red,
    },
    typography: {
      // headline: {
      //   fontSize: "1rem",
      // },
      subtitle1: {
        fontSize: "0.8125rem",
      },
      button: {
        fontWeight: 400,
        textTransform: "initial",
      },
      body1: {
        fontSize: "0.875rem",
      },
    },
    shape: {
      borderRadius: 4,
    },
  });

  useEffect(() => {
    document.body.dir = state.direction ?? "ltr";
  }, [state.direction]);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Provider value={[state, dispatch]}>{children}</Provider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
export const useAppState = () => useContext(Context);
