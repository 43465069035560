import { Add } from "@material-ui/icons";
import murmur from "murmurhash-js";
import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { fetchShiftTemplates } from "../../helpers/fetchers";
import {
  AuthserverShiftTemplate,
  AuthserverShiftTemplates,
  MasterJavaBaseModel,
} from "../../masterbigsystem";

const EmployeeShiftTemplatePage = () => {
  const [ctx, dispatch] = useAppState() ?? [];
  const employeeShiftTemplatesFormer = useRef(
    AuthserverShiftTemplates.fromPartial({})
  );
  const employeeShiftTemplates = useRef(
    AuthserverShiftTemplates.fromPartial({})
  );

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchEmployeeShiftTemplatesData();
  };

  const fetchEmployeeShiftTemplatesData = async () => {
    const d = await fetchShiftTemplates(ctx?.apiKey ?? "");

    employeeShiftTemplates.current = d;
    employeeShiftTemplatesFormer.current = JSON.parse(JSON.stringify(d));
    render();
  };

  const loading = useRef(false);

  const handleSave = async () => {
    try {
      loading.current = true;
      render();

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/employeeshifttemplates-save-bulk`,
        {
          method: "post",
          headers: { authorization: ctx?.apiKey ?? "" },
          body: JSON.stringify({
            ...employeeShiftTemplates.current,
            templates: employeeShiftTemplates.current.templates.filter((s) => {
              const foundTemplate =
                employeeShiftTemplatesFormer.current.templates.find(
                  (sx) =>
                    sx.masterJavaBaseModel?.uuid === s.masterJavaBaseModel?.uuid
                );

              if (!foundTemplate) {
                return true;
              }

              return (
                murmur.murmur3(JSON.stringify(s)) !==
                murmur.murmur3(JSON.stringify(foundTemplate))
              );
            }),
          }),
        }
      );
    } catch (e) {
    } finally {
      window.location.reload();
    }
  };

  return (
    <>
      <div className="m-3">
        <div>
          <div className="d-flex align-items-center">
            <h4>Shift Template</h4>

            <button
              className="mx-1"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          <hr />
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              employeeShiftTemplates.current.templates.push(
                AuthserverShiftTemplate.fromPartial({
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: v4(),
                  }),
                  fromHour: 7,
                  fromMins: 30,
                  toHour: 17,
                  toMins: 30,
                })
              );
              render();
            }}
          >
            <Add />
            Add{" "}
          </button>
        </div>
        <div
          className="overflow-auto border border-dark"
          style={{ height: "65vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["#", "Shift name", "From", "To"].map((h) => (
                <>
                  <th className="bg-dark text-light">{h}</th>
                </>
              ))}
            </tr>
            {employeeShiftTemplates.current.templates.map((t, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">
                      <input
                        className="form-control form-control-sm"
                        placeholder="Name.."
                        key={`name-template-${t.masterJavaBaseModel?.uuid}`}
                        onBlur={(e) => {
                          t.name = e.target.value;
                          render();
                        }}
                        defaultValue={t.name}
                      />
                    </td>
                    <td className="border border-dark">
                      <div className="d-flex">
                        <input
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          key={`from-hr-template-${t.masterJavaBaseModel?.uuid}`}
                          defaultValue={t.fromHour}
                          onBlur={(e) => {
                            const v = parseInt(e.target.value);
                            if (!isNaN(v)) {
                              t.fromHour = v;
                            }
                            render()
                          }}
                        />
                        :
                        <input
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          key={`from-mins-template-${t.masterJavaBaseModel?.uuid}`}
                          defaultValue={t.fromMins}
                          onBlur={(e) => {
                            const v = parseInt(e.target.value);
                            if (!isNaN(v)) {
                              t.fromMins = v;
                            }
                            render()
                          }}
                        />
                      </div>
                    </td>
                    <td className="border border-dark">
                      <div className="d-flex">
                        <input
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          key={`to-hr-template-${t.masterJavaBaseModel?.uuid}`}
                          defaultValue={t.toHour}
                          onBlur={(e) => {
                            const v = parseInt(e.target.value);
                            if (!isNaN(v)) {
                              t.toHour = v;
                            }
                            render()
                          }}
                        />
                        :
                        <input
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          key={`to-mins-template-${t.masterJavaBaseModel?.uuid}`}
                          defaultValue={t.toMins}
                          onBlur={(e) => {
                            const v = parseInt(e.target.value);
                            if (!isNaN(v)) {
                              t.toMins = v;
                            }
                            render()
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default EmployeeShiftTemplatePage;
