import { AuthserverUser_AuthorizationLevel } from "../masterbigsystem";
import {
  App,
  BaseModel,
  Company,
  Department,
  DepartmentHierarchy,
  DepartmentHierarchyMember,
  DepartmentHierarchyMemberView,
  DepartmentHierarchyView,
  Directorat,
  DirectoratMember,
  DirectoratMemberView,
  DirectoratView,
  Division,
  DivisionMember,
  DivisionMemberView,
  DivisionView,
  Pph21MarriageCategory,
  Role,
  Section,
  SectionMember,
  SectionMemberView,
  SectionView,
  User,
  UserApp,
} from "../model";

export const initialBaseModel: BaseModel = {
  createdAt: null,
  id: 0,
  hidden: false,
  ordering: 0,
  updatedAt: null,
  extCreatedById: null,
  uuid: null,
};

export const initialDepartment: Department = {
  ...initialBaseModel,
  name: "",
  users: [],
  deptCategory: null,
  code: null,
  ableToDeleteDoc: null,
  departmentGroup: null
};

export const initialUser: User = {
  ...initialBaseModel,
  address: "",
  reportTo: null,
  apps: [],
  addressLong: "",
  countryCode: "62",
  department: null,
  isHead: false,
  departmentId: 0,
  iosUser: false,
  iosUdid: "",
  deviceUniqueId: null,
  departmentName: "",
  email: "",
  fcmToken: null,
  name: "",
  password: "",
  username: "",
  phone: "",
  roles: [],
  vaccine1: null,
  vaccine2: null,
  vaccine1Done: false,
  vaccine2Done: false,
  bank: null,
  bankAccountNumber: "",
  company: null,
  deviceUniqueIdApproval: null,
  hrId: null,
  authorizationLevel: "OPERATOR",
  departmentCategory: null,
  divisionGrouping: null,
  fullCode: "",
  isWhatsappTemplateSent: null,
  phoneHr: null,
  jobEntryAllowed: null,
  excludeInGlobalAttendance: null,
  isSubcon: null,
  lat: null,
  lon: null,
  terminationTimestamp: null,
  entryDate: null,
  pph21MarriageCategory: null,
  pph21MarriageCategoryId: 0,
  isHr: null,
  companyId: null,
  employeeSerialNumber: null,
  editAttendanceNoteAllowed: null,
  allocation: null
};

export const initialPph21: Pph21MarriageCategory = {
  name: "",
  createdAt: null,
  extCreatedById: null,
  hidden: null,
  id: 0,
  ordering: null,
  updatedAt: null,
  uuid: null,
  terCategory: null
}

export const initialApp: App = {
  ...initialBaseModel,
  name: "",
  url: "",
  user: null,
  author: null,
};

export const initialUserApp: UserApp = {
  ...initialBaseModel,
  user: null,
  app: null,
};

export const initialRole: Role = {
  ...initialBaseModel,
  name: "",
  users: [],
};

export const initialDirectorat: Directorat = {
  ...initialBaseModel,
  name: "",
  directoratMembers: [],
  divisions: [],
};

export const initialDirectoratView: DirectoratView = {
  directorat: null,
  members: [],
  divisions: [],
};

export const initialDivision: Division = {
  ...initialBaseModel,
  name: "",
  departmentHierarchies: [],
  divisionMembers: [],
  directorat: null,
};

export const initialDivisionView: DivisionView = {
  division: null,
  members: [],
  departmentHierarchies: [],
};

export const initialDepartmentHierarchy: DepartmentHierarchy = {
  ...initialBaseModel,
  name: "",
  sections: [],
  departmentHierarchyMembers: [],
  division: null,
};

export const initialDepartmentHierarchyView: DepartmentHierarchyView = {
  departmentHierarchy: null,
  members: [],
  sections: [],
};

export const initialSection: Section = {
  ...initialBaseModel,
  name: "",
  sectionMembers: [],
  departmentHierarchy: null,
};

export const initialSectionView: SectionView = {
  section: null,
  members: [],
};

export const initialSectionMember: SectionMember = {
  ...initialBaseModel,
  extUserId: null,
  section: null,
  user: null,
};

export const initialSectionMemberView: SectionMemberView = {
  member: null,
};

export const initialDirectoratMember: DirectoratMember = {
  ...initialBaseModel,
  extUserId: null,
  directorat: null,
  user: null,
};

export const initialDirectoratMemberView: DirectoratMemberView = {
  member: null,
};

export const initialDivisionMember: DivisionMember = {
  ...initialBaseModel,
  extUserId: null,
  division: null,
  user: null,
};

export const initialDivisionMemberView: DivisionMemberView = {
  member: null,
};

export const initialDepartmentHierarchyMember: DepartmentHierarchyMember = {
  ...initialBaseModel,
  extUserId: null,
  departmentHierarchy: null,
  user: null,
};

export const initialDepartmentHierarchyMemberView: DepartmentHierarchyMemberView =
{
  member: null,
};


export const initialCompany: Company = {
  checkInHour: null,
  checkInMinute: null,
  name: null,
  createdAt: null,
  extCreatedById: null,
  hidden: null,
  id: 0,
  ordering: null,
  updatedAt: null,
  uuid: null
}