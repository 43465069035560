import { useEffect, useRef, useState } from "react";
import { Company } from "../../model";
import { fetchCompanies } from "../../helpers/fetchers";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { initialCompany } from "../../helpers/modelinitials";
import { v4 } from "uuid";

const CompanyPage = () => {
  const [ctx, dispatch] = useAppState() ?? [];
  const companies = useRef([] as Company[]);
  const [, refresh] = useState(false);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchCompanyData();
  };

  const render = () => {
    refresh((n) => !n);
  };

  const fetchCompanyData = async () => {
    const d = await fetchCompanies(ctx?.apiKey ?? "");

    if (d) {
      companies.current = d;
      render();
    }
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/companies-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(companies.current),
        }
      );

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex">
          <h4>Company List</h4>
          <div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  handleSave();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              companies.current.push({ ...initialCompany, uuid: v4() });
              render();
            }}
          >
            Add
          </button>
        </div>
        <div
          className="border border-dark"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table className="table" style={{ borderCollapse: "separate" }}>
            <tr>
              {["#", "Name", "Check in hour", "Check in mins"].map((h) => (
                <>
                  <th className="bg-dark text-light">{h}</th>
                </>
              ))}
            </tr>
            {companies.current.map((c, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">
                      <input
                        placeholder="Name..."
                        key={c.uuid ?? ""}
                        defaultValue={c.name ?? ""}
                        className="form-control form-control-sm"
                        onBlur={(e) => {
                          c.name = e.target.value;
                          render();
                        }}
                      />
                    </td>

                    <td className="border border-dark">
                      <input
                        placeholder="Checkin hr..."
                        key={`ci-${c.uuid ?? ""}`}
                        defaultValue={c.checkInHour ?? ""}
                        className="form-control form-control-sm"
                        onBlur={(e) => {
                          const v = isNaN(parseInt(e.target.value))
                            ? c.checkInHour
                            : parseInt(e.target.value);
                          c.checkInHour = v;
                          render();
                        }}
                      />
                    </td>
                    <td className="border border-dark">
                      <input
                        placeholder="Checkin min..."
                        key={`co-${c.uuid ?? ""}`}
                        defaultValue={c.checkInMinute ?? ""}
                        className="form-control form-control-sm"
                        onBlur={(e) => {
                            const v = isNaN(parseInt(e.target.value))
                            ? c.checkInMinute
                            : parseInt(e.target.value);
                          c.checkInMinute = v;
                          render();
                        }}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default CompanyPage;
