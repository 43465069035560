export const makeDateStringUTC = (date: Date) => {
  const y = date.getUTCFullYear();
  const m =
    date.getUTCMonth() + 1 < 10
      ? `0${date.getUTCMonth() + 1}`
      : `${date.getUTCMonth() + 1}`;
  const d =
    date.getUTCDate() + 1 < 10
      ? `0${date.getUTCDate()}`
      : `${date.getUTCDate()}`;

  return `${y}-${m}-${d}`;
};
export const makeDateString = (date: Date) => {
  const y = date.getFullYear();
  const m =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${y}-${m}-${d}`;
};

export const makeTimeString = (date: Date) => {
  return `${
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`
  }:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`
  }:${
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`
  }`;
};

export const makeReadableDateString = (date: Date) =>
  Intl.DateTimeFormat(navigator.language ?? "en-US", {
    dateStyle: "medium",
  } as any).format(date);

export const intlFormat = (params?: {
  date?: string;
  dateStyle?: "medium" | "full" | "long" | "short" | undefined;
  timeStyle?: "medium" | "full" | "long" | "short" | undefined;
}) => {
  try {
    return Intl.DateTimeFormat("en-US", {
      dateStyle: params?.dateStyle,
      timeStyle: params?.timeStyle,
    }).format(new Date(params?.date ?? ""));
  } catch (e) {
    return "";
  }
};

export const haversine = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres

  return d;
};
