import { CircularProgress } from "@material-ui/core";
import murmur from "murmurhash-js";
import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Select from "react-select";
import { v4 } from "uuid";
import { useAppState } from "../../components/AppProvider/AppProvider";
import {
  fetchDepartments,
  fetchShifts,
  fetchShiftTemplates,
  fetchUsers,
} from "../../helpers/fetchers";
import { makeDateString, makeDateStringUTC } from "../../helpers/helpers";
import {
  AuthserverShiftTemplate,
  AuthserverShiftTemplates,
  AuthserverUserShift,
  AuthserverUserShifts,
  MasterJavaBaseModel,
} from "../../masterbigsystem";
import { Department, User } from "../../model";

const EmployeeShiftPage = () => {
  const users = useRef<User[]>([]);
  const selectedShift = useRef<AuthserverUserShift | null>(null);
  const departments = useRef<Department[]>([]);

  const employeeShiftTemplates = useRef(
    AuthserverShiftTemplates.fromPartial({})
  );
  const selectedShiftTemplate = useRef<AuthserverShiftTemplate | null>(null);
  const shiftsFormer = useRef(AuthserverUserShifts.fromPartial({}));
  const shifts = useRef(AuthserverUserShifts.fromPartial({}));

  const [ctx, dispatch] = useAppState() ?? [];

  const showHidden = useRef(false);
  const loading = useRef(false);
  const from = useRef(makeDateString(new Date()));
  const to = useRef(makeDateString(new Date(new Date().getTime() + 86400000)));
  const selectedDept = useRef<Department | null>(null);

  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };

  const fetchData = async () => {
    console.log("BAseURL:", ctx?.baseUrl, "Api key:", ctx?.apiKey);

    fetchUsersData();
    fetchDeptsData();
    fetchShiftsData();
    fetchEmployeeShiftTemplatesData();
  };

  const fetchUsersData = async () => {
    const u = await fetchUsers(ctx?.baseUrl ?? "", ctx?.apiKey ?? "", {
      showHidden: showHidden.current,
    });
    users.current = u;
    render();
  };
  const fetchShiftsData = async () => {
    const s = await fetchShifts(ctx?.apiKey ?? "");

    shifts.current = s;
    shiftsFormer.current = JSON.parse(JSON.stringify(s));

    render();
  };

  const fetchDeptsData = async () => {
    const d = await fetchDepartments(ctx?.baseUrl ?? "", ctx?.apiKey ?? "");

    departments.current = d;
    render();
  };
  const fetchEmployeeShiftTemplatesData = async () => {
    const d = await fetchShiftTemplates(ctx?.apiKey ?? "");

    employeeShiftTemplates.current = d;
    render();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="m-3">
        <div>
          <div className="d-flex">
            <h5>Employee Shift</h5>
            {loading.current ? (
              <>
                <CircularProgress />{" "}
              </>
            ) : (
              <>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={async () => {
                    try {
                      loading.current = true;
                      render();

                      console.log("tosave", { ...shifts.current });

                      const resp = await fetch(
                        `${process.env.REACT_APP_BASE_URL}/employeeshifts-save-bulk`,
                        {
                          method: "post",
                          headers: { authorization: ctx?.apiKey ?? "" },
                          body: JSON.stringify({
                            ...shifts.current,
                            shifts: shifts.current.shifts.filter((s) => {
                              const foundShift =
                                shiftsFormer.current.shifts.find(
                                  (sx) =>
                                    sx.masterJavaBaseModel?.uuid ===
                                    s.masterJavaBaseModel?.uuid
                                );

                              if (!foundShift) {
                                return true;
                              }

                              return (
                                murmur.murmur3(JSON.stringify(s)) !==
                                murmur.murmur3(JSON.stringify(foundShift))
                              );
                            }),
                          }),
                        }
                      );
                    } catch (e) {
                    } finally {
                      window.location.reload();
                    }
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
        <div>
          <hr />
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <Select
              options={departments.current.map((d) => ({
                label: d.name,
                value: d,
              }))}
              onChange={(v) => {
                const val = v as { value: Department };

                selectedDept.current = val.value;
                render();
              }}
            />
          </div>
          <div className="d-flex mx-2">
            From:{" "}
            <div>
              <input
                className="form-control form-control-sm"
                type="date"
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    from.current = e.target.value;
                    render();
                  }
                }}
              />
            </div>
          </div>
          <div className="d-flex mx-2">
            to:{" "}
            <div>
              <input
                className="form-control form-control-sm"
                type="date"
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    to.current = e.target.value;
                    render();
                  }
                }}
              />
            </div>
          </div>
        </div>
        {/* {JSON.stringify(shifts)} */}

        <div
          className="border border-dark overflow-auto"
          style={{ height: "85vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr className="p-0 m-0">
              {["#", "Username", "Dept"].map((h) => {
                return (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                );
              })}
              {/* {to} - {from} -
              {(new Date(to).getTime() - new Date(from).getTime()) / 86400000} */}
              {[
                ...Array(
                  (new Date(to.current).getTime() -
                    new Date(from.current).getTime()) /
                    86400000
                ),
              ].map((_, n) => {
                const d = new Date(new Date(from.current).getTime() + 86400000 * n);
                return (
                  <>
                    <th
                      className={`p-0 m-0 ${
                        d.getDay() === 0 || d.getDay() === 6
                          ? `bg-danger`
                          : `bg-dark`
                      } text-light`}
                      style={{ position: "sticky", top: 0 }}
                    >
                      {Intl.DateTimeFormat("en-US", {
                        dateStyle: "short",
                      } as any).format(d)}
                    </th>
                  </>
                );
              })}
            </tr>

            {users.current
              .filter((u) =>
                selectedDept.current
                  ? u.departmentId === selectedDept.current?.id
                  : true
              )
              .map((u, i) => {
                return (
                  <>
                    <tr className="p-0 m-0 ">
                      <td className="p-0 m-0 border border-dark">{i + 1}</td>
                      <td
                        className="p-0 m-0 border border-dark bg-light"
                        style={{ position: "sticky", left: 0 }}
                      >
                        {u.username}
                      </td>
                      <td className="p-0 m-0 border border-dark">
                        {
                          departments.current.find(
                            (d) => `${d.id}` === `${u.departmentId}`
                          )?.name
                        }
                      </td>
                      {[
                        ...Array(
                          (new Date(to.current).getTime() -
                            new Date(from.current).getTime()) /
                            86400000
                        ),
                      ].map((_, n) => {
                        const d = new Date(new Date(from.current).getTime() + 86400000 * n);
                        const fShift = shifts.current.shifts.find(
                          (s) =>
                            `${s.userId}` === `${u.id}` &&
                            s.date === makeDateString(d)
                        );
                        const fShiftTemplate =
                          employeeShiftTemplates.current.templates.find(
                            (t) =>
                              `${t.masterJavaBaseModel?.id}` ===
                              `${fShift?.shiftTemplateId}`
                          );
                        return (
                          <>
                            <td
                              className={`p-0 m-0 border border-dark ${(() => {
                                if (!fShift) {
                                  return `bg-dark `;
                                }

                                if (fShift.shift) {
                                  return `bg-success`;
                                } else {
                                  return `bg-danger`;
                                }
                              })()}`}
                            >
                              <div className="d-flex">
                                <button
                                  className="p-0 m-0 btn btn-outline-secondary"
                                  onClick={() => {
                                    if (fShift) {
                                      shifts.current = {
                                        ...shifts,
                                        shifts: shifts.current.shifts.map((s) =>
                                          `${s.userId}` === `${u.id}` &&
                                          s.date === makeDateString(d)
                                            ? { ...s, shift: !s.shift }
                                            : s
                                        ),
                                      };
                                    } else {
                                      shifts.current.shifts.push(
                                        AuthserverUserShift.fromPartial({
                                          masterJavaBaseModel:
                                            MasterJavaBaseModel.fromPartial({
                                              uuid: v4(),
                                            }),
                                          userId: `${u.id}`,
                                          date: makeDateString(d),
                                        })
                                      );
                                    }

                                    render();
                                  }}
                                >
                                  <small>Toggle</small>
                                </button>

                                {fShift && fShift.shift ? (
                                  <>
                                    <button
                                      className="p-0 m-0 btn btn-outline-primary"
                                      onClick={() => {
                                        if (fShift) {
                                          selectedShift.current = fShift;
                                          render();
                                        }
                                      }}
                                    >
                                      <small className="mx-1">S</small>
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {/* <div>{fShift?.shiftTemplateId}</div> */}
                              {/* {JSON.stringify(fShiftTemplate)} */}
                              {fShiftTemplate ? (
                                <>
                                  <div className="text-light">
                                    <small>
                                      <strong>
                                        {fShiftTemplate?.fromHour}:
                                        {fShiftTemplate?.fromMins}-
                                        {fShiftTemplate?.toHour}:
                                        {fShiftTemplate?.toMins}
                                      </strong>
                                    </small>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
          </table>
        </div>
      </div>
      <Modal
        show={selectedShift.current ? true : false}
        size="lg"
        onHide={() => {
          selectedShift.current = null;
          render();
        }}
      >
        <ModalHeader>
          <h5>
            Select shift for{" "}
            {
              users.current.find(
                (u) => `${u.id}` === `${selectedShift.current?.userId}`
              )?.username
            }{" "}
            date {selectedShift.current?.date}{" "}
          </h5>
        </ModalHeader>
        <ModalBody>
          <Select
            options={employeeShiftTemplates.current.templates.map((t) => ({
              label: `${t.name}: ${t.fromHour}:${t.fromMins} - ${t.toHour}:${t.toMins}`,
              value: t,
            }))}
            onChange={(v) => {
              const val = v as { value: AuthserverShiftTemplate };

              if (selectedShift.current) {
                selectedShiftTemplate.current = val.value;
              }

              render();
            }}
          />
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => {
                if (selectedShift.current) {
                  selectedShift.current.shiftTemplateId =
                    selectedShiftTemplate.current?.masterJavaBaseModel?.id;
                  selectedShift.current = null;
                  render();
                }
              }}
            >
              Select{" "}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default EmployeeShiftPage;
