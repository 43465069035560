import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { blue, blueGrey } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { fetchApps, fetchUsers } from "../../helpers/fetchers";
import { RequestStatus } from "../../helpers/RequestStatus";
import { blueTheme } from "../../helpers/themes";
import { App, User } from "../../model";

const useStyles = makeStyles({
  tableCell: {
    borderColor: blueGrey[200],
    borderWidth: 2,
    borderStyle: "solid",
  },
});

const AppsPage = () => {
  const classes = useStyles();
  const [ctx, dispatch] = useAppState() ?? [];
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("NotAsked");
  const [apps, setApps] = useState<App[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setRequestStatus("Loading");

    const [apps, users] = await Promise.all([
      fetchApps(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
      fetchUsers(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
    ]);

    setRequestStatus("Success");
    setApps(apps);
    setUsers(users);
  };

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box m={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">Apps List</Typography>
            {requestStatus === "Loading" ? (
              <Box mx={2}>
                <CircularProgress disableShrink size={25} />
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box display="flex" flexWrap={1} alignItems="center">
            <Link to="/apps/new">
              <Box>
                <Button color="primary" variant="contained">
                  Add
                </Button>
              </Box>
            </Link>

            <Box flexGrow={1} my={1}>
              <TextField
                label="Search"
                size="small"
                variant="outlined"
                fullWidth
              />
            </Box>
          </Box>

          <Box
            overflow="auto"
            my={1}
            boxShadow={5}
            borderColor={blueGrey[200]}
            style={{ height: "72vh" }}
          >
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {["App Name", "Author", "URL"].map((headName) => (
                      <TableCell
                        className={classes.tableCell}
                        style={{
                          backgroundColor: blueGrey[500],
                          color: "white",
                        }}
                        align="center"
                      >
                        {headName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      Warehouse
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Angga
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <a
                        style={{ color: blue[600] }}
                        href="http://128.199.197.171"
                      >
                        http://128.199.197.171
                      </a>
                    </TableCell>
                  </TableRow> */}
                  {apps.map((app) => (
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        style={{ color: blue[600] }}
                      >
                        <Link to={`/apps/${app.id}`}>{app.name}</Link>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {app.author?.name}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <a
                          style={{ color: blue[600] }}
                          target="_blank"
                          href="https://meeting.ioseries.com"
                        >
                          {app.url}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AppsPage;
