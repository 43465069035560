import { useEffect, useRef, useState } from "react";
import {
  AuthserverDepartment,
  AuthserverDepartmentGroup,
  AuthserverDepartmentGroups,
  MasterJavaBaseModel,
} from "../../masterbigsystem";
import {
  fetchDepartmentGroupProto,
  fetchDepartmentGroupsProto,
  fetchDepartments,
} from "../../helpers/fetchers";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { v4 } from "uuid";
import Select from "react-select";
import { Department } from "../../model";

const DepartmentGroupDetail = () => {
  const deptGroup = useRef(
    AuthserverDepartmentGroup.fromPartial({
      departments: [],
      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({ uuid: v4() }),
    })
  );

  const [ctx, dispatch] = useAppState() ?? [];
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };
  const depts = useRef([] as Department[]);

  const init = async () => {
    fetchDepartmentGroupData();
    fetchDepartmentsData();
  };
  const { id } = useParams() as { id: any };

  const fetchDepartmentGroupData = async () => {
    const d = await fetchDepartmentGroupProto({
      apiKey: ctx?.apiKey ?? "",
      id: id,
    });

    if (d) {
      deptGroup.current = d;
      render();
    }
  };

  const fetchDepartmentsData = async () => {
    const d = await fetchDepartments(ctx?.baseUrl ?? "", ctx?.apiKey ?? "");

    if (d) {
      depts.current = d;
      render();
    }
  };

  useEffect(() => {
    init();
  }, []);

  const saveLoading = useRef(false);
  const history = useHistory();
  const handleSave = async () => {
    saveLoading.current = true;
    render();

    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/departmentgroups-proto-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(
            AuthserverDepartmentGroups.fromPartial({
              groups: [deptGroup.current],
            })
          ),
        }
      );

      if (resp.status !== 200) {
        throw await resp.text();
      }

      history.push("/departmentgroups");
    } catch (e) {
      alert("Failed saving");
    } finally {
      saveLoading.current = false;
      render();
    }
  };

  return (
    <>
      <div className="m-3">
        <div>
          <div className="d-flex">
            <div>
              <h4>Department Group Detail</h4>
            </div>

            {saveLoading.current ? (
              <>
                <div className="spinner-border spinner-border-sm"></div>
              </>
            ) : (
              <>
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>

        <div>
          <div>
            <strong>Name</strong>
          </div>
        </div>
        <div className="my-2 shadow shadow-md border border-secondary">
          <input
            placeholder="Name..."
            defaultValue={deptGroup.current.name}
            className="form-control form-control-sm"
            onBlur={(e) => {
              deptGroup.current.name = e.target.value;
              render();
            }}
          />
        </div>

        <div>
          <hr className="border border-dark" />
        </div>

        <strong>Departments</strong>

        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              deptGroup.current.departments.push(
                AuthserverDepartment.fromPartial({
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: v4(),
                  }),
                })
              );
              render();
            }}
          >
            Add
          </button>
        </div>
        <div>
          <div
            className="overflow-auto border border-dark"
            style={{ height: "75vh", resize: "vertical" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {["Action", "#", "Dept"].map((h) => {
                  return (
                    <>
                      <th
                        className="bg-dark text-light"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  );
                })}
              </tr>
              {deptGroup.current.departments?.map(
                (d: AuthserverDepartment, i) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          <div>
                            <button className="btn btn-sm btn-danger">
                              Delete
                            </button>
                          </div>
                        </td>

                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          <Select
                            options={depts.current.map((d) => ({
                              label: d.name,
                              value: d,
                            }))}
                            value={depts.current
                              .map((d) => ({
                                label: d.name,
                                value: d,
                              }))
                              .find(
                                (dx) =>
                                  `${dx.value.id}` ===
                                  `${d.masterJavaBaseModel?.id}`
                              )}
                            onChange={(v) => {
                              const val = v as { value: Department };

                              if (!d.masterJavaBaseModel) {
                                d.masterJavaBaseModel =
                                  MasterJavaBaseModel.fromPartial({});
                              }

                              d.masterJavaBaseModel.id = `${val.value.id}`;

                              render();
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentGroupDetail;
